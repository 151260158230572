import { ContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import React, { FC, useState } from 'react';
import { Display } from '../bits/Display';
import { Modal } from '../patterns/Modal';
import { EmbeddedContentItem } from './PageTemplate/EmbeddedContentItem';

interface ItemPreviewButtonProps {
    item: ContentItem | PrepreparedContentItem;
    buttonComponent: FC<React.ButtonHTMLAttributes<HTMLButtonElement>>;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ItemPreviewButton: FC<ItemPreviewButtonProps> = ({ item, buttonComponent: ButtonComponent }) => {
    const [itemPreview, setItemPreview] = useState(false);

    return (
        <>
            <ButtonComponent onClick={() => setItemPreview(true)} />
            {!!itemPreview && (
                <Modal onModalClose={() => setItemPreview(false)} maxCardWidth={900} minCardWidth={500}>
                    <Display.HorizontalCenterVerticalCenter>
                        <EmbeddedContentItem item={item} />
                    </Display.HorizontalCenterVerticalCenter>
                </Modal>
            )}
        </>
    );
};
