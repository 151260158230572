import { useApolloClient } from '@apollo/client';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useState } from 'react';
import { Client } from '../../graphql/client';
import { getSignedPutUrlForPageDressingUpload } from '../../graphql/pages';
import { Spinner } from '../bits/Spinner';
import { DropZone } from '../patterns/DropZone';

const uploadPageDressing = async (client: Client, simulation: Simulation, file: File): Promise<string> => {
    const putUrl = await getSignedPutUrlForPageDressingUpload.promise(client, {
        simulation: simulation,
        file: {
            name: file.name,
            type: file.type,
            size: undefined,
        },
    });
    if (!putUrl.data) {
        throw new Error('Could not create signed put url for image');
    }
    const putCVRequestOptions: RequestInit = {
        method: 'PUT',
        headers: { 'content-type': file.type },
        body: file,
    };
    await fetch(putUrl.data.getSignedPutUrlForPageDressingUpload.signedPutUrl, putCVRequestOptions);
    return putUrl.data.getSignedPutUrlForPageDressingUpload.path;
};

interface PageDressingDropZoneProps {
    simulation: Simulation;
    onUploaded: (path: string) => void;
    disabled?: boolean;
}

export const PageDressingDropZone: FC<PageDressingDropZoneProps> = ({ simulation, onUploaded, disabled }) => {
    const client = useApolloClient();
    const [uploading, setUploading] = useState(false);
    return (
        <>
            {uploading && <Spinner />}
            {!uploading && (
                <DropZone
                    accept="image/*"
                    onAcceptedFiles={async (files: File[]) => {
                        if (files.length === 1) {
                            setUploading(true);
                            const imageFile = files[0];
                            const imagePath = await uploadPageDressing(client, simulation, imageFile);
                            onUploaded(imagePath);
                            setUploading(false);
                        }
                    }}
                    disabled={disabled}
                >
                    <p>Drag and drop your image here or click to select a image</p>
                </DropZone>
            )}
        </>
    );
};
