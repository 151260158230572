import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components/macro';
import { EditTriggerPage } from './EditTrigger';
import { TriggersListPage } from './TriggersList';

const ManageTriggersPageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 22px;
`;

export const ManageTriggersPage: FC = () => (
    <ManageTriggersPageContainer>
        <Switch>
            <Route path={'/admin/simulation/:simulationUUID/manage/triggers'} component={TriggersListPage} exact />
            <Route
                path={'/admin/simulation/:simulationUUID/manage/triggers/edit/:triggerUUID'}
                component={EditTriggerPage}
                exact
            />
        </Switch>
    </ManageTriggersPageContainer>
);
