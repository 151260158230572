import gql from 'graphql-tag';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapMutation from '../wrapMutation';

interface EditTriggerParams {
    trigger: Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

interface EditTriggerData {
    editTrigger: Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

const mutation = gql`
    mutation editTrigger($trigger: TriggerInput!) {
        editTrigger(trigger: $trigger) {
            uuid
            simulationUUID
            title
            isOptional
            simulatedTime
            participantPopupSettings {
                enabled
                text
                prepreparedContentItemUUID
            }
            staffPopupSettings {
                directions
                guidelines
                expectedActions
            }
            overviewChanges {
                title
                description
                headerImage
            }
            pageDressingChanges
            releasedAt
        }
    }
`;

export const editTrigger = wrapMutation<EditTriggerParams, EditTriggerData>(mutation);
