import React, { FC } from 'react';
import { SecondaryButton, PrimaryButton } from '../../../../bits/Buttons';
import { Icon } from '../../../../bits/Icon';
import { Display } from '../../../../bits/Display';
import { H2Heading } from '../../../../bits/Headers';

interface BriefingDocumentsHeaderProps {
    header: string;
    onStartEdit?: () => void;
    onSave?: () => void;
}

const BriefingDocumentsHeader: FC<BriefingDocumentsHeaderProps> = ({ header, onStartEdit, onSave }) => {
    return (
        <Display.HorizontalWithMaxSpaceBetween verticalCenter>
            <H2Heading>{header}</H2Heading>
            {onSave && (
                <PrimaryButton onClick={onSave}>
                    <Icon icon="save" size={20} />
                    Save
                </PrimaryButton>
            )}
            {onStartEdit && (
                <SecondaryButton onClick={onStartEdit}>
                    <Icon icon="edit" size={20} />
                    Edit
                </SecondaryButton>
            )}
        </Display.HorizontalWithMaxSpaceBetween>
    );
};

export default BriefingDocumentsHeader;
