import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteInitialScratchPadDocument } from '../../../../graphql/scratchPad';
import { destructiveColour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Body2 } from '../../../bits/Headers';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { DeleteModal } from '../../DeleteModal';

interface DeleteInitialScratchPadDocumentButtonButtonProps {
    document: InitialScratchPadDocument;
}
export const DeleteInitialScratchPadDocumentButton: FC<DeleteInitialScratchPadDocumentButtonButtonProps> = ({
    document,
}) => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const { setSimulation } = useContext(AdminStateContext);
    const { scratchPadDocuments } = currentSimulation;
    const currentDocs = scratchPadDocuments || [];
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);

    const [deleteInitialScratchPadDocumentMutation, { data: deleteScratchPadData, loading: deleteScratchPadLoading }] =
        deleteInitialScratchPadDocument.hook();

    useEffect(() => {
        const deletedDocument = deleteScratchPadData?.deleteInitialScratchPadDocument;
        if (deletedDocument) {
            const updatedDocs = currentDocs.filter((document) => deletedDocument.uuid !== document.uuid);

            const simulationWithUpdatedScratchpad = {
                ...currentSimulation,
                scratchPadDocuments: updatedDocs,
            };
            setSimulation(simulationWithUpdatedScratchpad);
        }
    }, [deleteScratchPadData]);

    return (
        <>
            <IconButton
                onClick={() => setShowDeleteDocumentModal(true)}
                fill={destructiveColour}
                icon="delete"
                size={15}
            />
            {showDeleteDocumentModal && (
                <DeleteModal
                    header="Delete Document"
                    onModalClose={() => setShowDeleteDocumentModal(false)}
                    confirmationText={
                        <>
                            <p>Are you sure you want to delete the document: {`'${document.name}'`}? </p>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() => {
                        deleteInitialScratchPadDocumentMutation({
                            variables: {
                                scratchPadDocument: document,
                            },
                        });
                    }}
                    showSpinner={deleteScratchPadLoading}
                />
            )}
        </>
    );
};
