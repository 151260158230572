import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import React, { FC } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { CardSection } from '../../../bits/Card';
import { Display } from '../../../bits/Display';
import { RichtextDisplay } from '../../../bits/RichtextDisplay';
import { Modal } from '../../../patterns/Modal';

interface RichTextDocumentReadOnlyModalProps {
    document: ScratchPadDocument | InitialScratchPadDocument;
    onCloseModal: () => void;
}

export const RichTextDocumentReadOnlyModal: FC<RichTextDocumentReadOnlyModalProps> = ({ document, onCloseModal }) => {
    return (
        <Modal header={document.name} onModalClose={onCloseModal} cardWidth={'80%'}>
            <CardSection>
                <RichtextDisplay value={document.notes || ''} />
            </CardSection>
            <CardSection>
                <Display.RightAlign>
                    <SecondaryButton onClick={onCloseModal}>Close</SecondaryButton>
                </Display.RightAlign>
            </CardSection>
        </Modal>
    );
};
