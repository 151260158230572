import { ScratchPadCategory, ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { includes, map, mapObjIndexed, reduce, reject, sortBy } from 'ramda';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { CardBody as CardBodyBase, CardHeader, CardSection } from '../../../bits/Card';
import { Display } from '../../../bits/Display';
import { H2Heading, Subtitle2 } from '../../../bits/Headers';
import { Collapsible } from '../../../patterns/Collapsible';
import { FABMenu, FABMenuProps } from '../../../patterns/FAB/FABMenu';
import { AddDocumentButton } from './AddDocumentButton';
import { ScratchPadDocumentItem } from './ScratchPadDocumentItem';
import { Icon } from '../../../bits/Icon';

const CardBody = styled(CardBodyBase)`
    overflow: auto;
`;

const scratchpadCategories = ['Statements', 'Strategy', 'Resources'] as const;

interface ScratchpadFABMenuProps extends FABMenuProps {
    onClose?: () => void;
    documents: ScratchPadDocument[];
    disableManageDocuments?: boolean;
}
export const ScratchpadFABMenu: FC<ScratchpadFABMenuProps> = ({
    onClose,
    documents,
    disableManageDocuments,
    menuPosition,
    tailPosition,
}) => {
    const [expandedGroups, setExpandedGroups] = useState<Array<'Statements' | 'Strategy' | 'Resources'>>([]);
    const toggleGroupCollapsed = (id: ScratchPadCategory) => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups([...expandedGroups, id]);
        }
    };

    const categorisedDocuments = useMemo(() => {
        const groupedDocs = reduce(
            (acc, doc) => (acc[doc.category] ? { ...acc, [doc.category]: [...acc[doc.category], doc] } : acc),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { Statements: [], Strategy: [], Resources: [] } as Record<string, ScratchPadDocument[]>,
            documents,
        );
        const sortedDocs = mapObjIndexed((docs) => sortBy((d) => d.name, docs), groupedDocs);
        return sortedDocs;
    }, [documents]);

    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const closeFABMenu = (e: MouseEvent) => {
            const target = e.target as Element;
            const isFileInput = target.getAttribute('type') === 'file';
            if (menuRef.current && !isFileInput && onClose) {
                const targetIsOutsideContainer = !menuRef.current.contains(target);
                if (targetIsOutsideContainer) {
                    onClose();
                }
            }
        };
        document.addEventListener('click', closeFABMenu);
        return () => {
            document.removeEventListener('click', closeFABMenu);
        };
    }, []);

    return (
        <FABMenu ref={menuRef} menuPosition={menuPosition} tailPosition={tailPosition} cardWidth={500}>
            <CardHeader>
                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                    <H2Heading>Documents</H2Heading>
                    <Display.HorizontalWithSpacing gap={5}>
                        {onClose && <IconButton icon="close" onClick={() => onClose()} />}
                    </Display.HorizontalWithSpacing>
                </Display.HorizontalWithMaxSpaceBetween>
                <p>
                    If you do not intend to edit a document please use the view icon (<Icon icon="view" size={12} />
                    ), or click the document name to open it in read-only mode.
                </p>
            </CardHeader>
            <CardBody>
                {map((category) => {
                    const documentsForThisCategory = categorisedDocuments[category] || [];
                    return (
                        <CardSection key={category}>
                            <Collapsible
                                title={`${category} (${documentsForThisCategory.length} ${
                                    documentsForThisCategory.length === 1 ? 'document' : 'documents'
                                })`}
                                toggleGroupCollapsed={() => toggleGroupCollapsed(category)}
                                open={!includes(category, expandedGroups)}
                            >
                                {map(
                                    (doc: ScratchPadDocument) => (
                                        <ScratchPadDocumentItem
                                            key={doc.uuid}
                                            document={doc}
                                            title={category}
                                            disableManageDocuments={disableManageDocuments}
                                        />
                                    ),
                                    documentsForThisCategory,
                                )}
                                {!documentsForThisCategory.length && (
                                    <Subtitle2>No documents in this section.</Subtitle2>
                                )}
                                {!disableManageDocuments && (
                                    <Display.VerticalWithSpacing horizontalAlign="start">
                                        <AddDocumentButton category={category} />
                                    </Display.VerticalWithSpacing>
                                )}
                            </Collapsible>
                        </CardSection>
                    );
                }, scratchpadCategories)}
            </CardBody>
        </FABMenu>
    );
};
