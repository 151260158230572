import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Icon } from '../../../bits/Icon';
import { CardLink } from '../../../patterns/Card';
import { Modal } from '../../../patterns/Modal';
import { H4Heading } from '../../../bits/Headers';
import styled from 'styled-components/macro';

const CardLinkHeading = styled(H4Heading)`
    text-align: center;
`;

export const NewSimulationButton: FC = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>New Simulation</SecondaryButton>
            {showModal && (
                <Modal header="New Simulation" onModalClose={() => setShowModal(false)}>
                    <Display.VerticalWithSpacing horizontalAlign="end">
                        <Display.HorizontalWithSpacing>
                            <CardLink href="/admin/simulation/upload" cardWidth={164}>
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="upload" size={55} />
                                    <CardLinkHeading>Upload a simulation file</CardLinkHeading>
                                </Display.VerticalWithSpacing>
                            </CardLink>
                            <CardLink href="/admin/simulation/new" cardWidth={164}>
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="createNew" size={55} />
                                    <CardLinkHeading>Create a new simulation</CardLinkHeading>
                                </Display.VerticalWithSpacing>
                            </CardLink>
                        </Display.HorizontalWithSpacing>
                        <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
                    </Display.VerticalWithSpacing>
                </Modal>
            )}
        </>
    );
};
