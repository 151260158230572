import React, { FC } from 'react';
import { DestructiveButton, SecondaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import { Spinner } from '../bits/Spinner';
import { Modal, ModalProps } from '../patterns/Modal';

interface DeleteModalProps extends ModalProps {
    showSpinner?: boolean;
    confirmationText: string | JSX.Element;
    deleteCTA?: string;
    onConfirmDelete: () => void;
}
export const DeleteModal: FC<DeleteModalProps> = ({
    showSpinner,
    confirmationText,
    deleteCTA,
    onConfirmDelete,
    ...modalProps
}) => {
    const { onModalClose } = modalProps;
    // Stop ability to close modal if we are showing the spinner
    const onModalCloseToUse = onModalClose && !showSpinner ? onModalClose : () => undefined;
    return (
        <Modal {...modalProps} cardWidth={800} onModalClose={onModalCloseToUse}>
            {showSpinner && (
                <Display.HorizontalCenterVerticalCenter>
                    <Spinner />
                </Display.HorizontalCenterVerticalCenter>
            )}
            {!showSpinner && (
                <Display.VerticalWithSpacing>
                    {confirmationText}
                    <Display.HorizontalWithSpacing horizontalAlign="end">
                        <SecondaryButton onClick={onModalCloseToUse}>Cancel</SecondaryButton>
                        <DestructiveButton onClick={() => onConfirmDelete()}>{deleteCTA || 'Delete'}</DestructiveButton>
                    </Display.HorizontalWithSpacing>
                </Display.VerticalWithSpacing>
            )}
        </Modal>
    );
};
