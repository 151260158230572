import * as t from 'io-ts';
import {
    DeleteScratchpadDocumentsPermissionValue,
    ListSimulationPermissionValue,
    PermissionKey,
    Permissions,
    WriteSimulationPermissionValue,
} from './types';

export enum PermissionSet {
    ADMIN = 'ADMIN',
    CONTENT_CREATOR = 'CONTENT_CREATOR',
    ROLEPLAYER = 'ROLEPLAYER',
    SIMULATION_ADMINISTRATOR = 'SIMULATION_ADMINISTRATOR',
}

export const tPermissionSet = t.keyof(PermissionSet);

export const PERMISSION_SET_MAP: Record<PermissionSet, Permissions> = {
    [PermissionSet.ADMIN]: {
        [PermissionKey.ADMINISTER_SIMULATIONS]: true,
        [PermissionKey.CREATE_SIMULATIONS]: true,
        [PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS]: DeleteScratchpadDocumentsPermissionValue.ALL,
        [PermissionKey.DOWNLOAD_SIMULATION_DATA]: true,
        [PermissionKey.GET_STAFF_USERS]: true,
        [PermissionKey.LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT]: ListSimulationPermissionValue.ALL,
        [PermissionKey.LIST_STAFF_USERS]: true,
        [PermissionKey.PARTICIPATE_IN_SIMULATIONS]: true,
        [PermissionKey.UPDATE_SIMULATION_READY_STATUS]: true,
        [PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT]: WriteSimulationPermissionValue.ALL,
        [PermissionKey.WRITE_STAFF_USERS]: true,
        [PermissionKey.EDIT_SIMULATION_STAFF]: true,
    },
    [PermissionSet.CONTENT_CREATOR]: {
        [PermissionKey.ADMINISTER_SIMULATIONS]: false,
        [PermissionKey.CREATE_SIMULATIONS]: true,
        [PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS]: false,
        [PermissionKey.DOWNLOAD_SIMULATION_DATA]: false,
        [PermissionKey.GET_STAFF_USERS]: true,
        [PermissionKey.LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT]: ListSimulationPermissionValue.CREATED,
        [PermissionKey.LIST_STAFF_USERS]: true,
        [PermissionKey.PARTICIPATE_IN_SIMULATIONS]: false,
        [PermissionKey.UPDATE_SIMULATION_READY_STATUS]: true,
        [PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT]: WriteSimulationPermissionValue.CREATED,
        [PermissionKey.WRITE_STAFF_USERS]: false,
        [PermissionKey.EDIT_SIMULATION_STAFF]: true,
    },
    [PermissionSet.ROLEPLAYER]: {
        [PermissionKey.ADMINISTER_SIMULATIONS]: false,
        [PermissionKey.CREATE_SIMULATIONS]: false,
        [PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS]: DeleteScratchpadDocumentsPermissionValue.CREATED,
        [PermissionKey.DOWNLOAD_SIMULATION_DATA]: false,
        [PermissionKey.GET_STAFF_USERS]: true,
        [PermissionKey.LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT]: ListSimulationPermissionValue.ASSIGNED,
        [PermissionKey.LIST_STAFF_USERS]: false,
        [PermissionKey.PARTICIPATE_IN_SIMULATIONS]: true,
        [PermissionKey.UPDATE_SIMULATION_READY_STATUS]: false,
        [PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT]: false,
        [PermissionKey.WRITE_STAFF_USERS]: false,
        [PermissionKey.EDIT_SIMULATION_STAFF]: false,
    },
    [PermissionSet.SIMULATION_ADMINISTRATOR]: {
        [PermissionKey.ADMINISTER_SIMULATIONS]: true,
        [PermissionKey.CREATE_SIMULATIONS]: false,
        [PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS]: DeleteScratchpadDocumentsPermissionValue.ALL,
        [PermissionKey.DOWNLOAD_SIMULATION_DATA]: true,
        [PermissionKey.GET_STAFF_USERS]: true,
        [PermissionKey.LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT]: ListSimulationPermissionValue.ASSIGNED,
        [PermissionKey.LIST_STAFF_USERS]: false,
        [PermissionKey.PARTICIPATE_IN_SIMULATIONS]: true,
        [PermissionKey.UPDATE_SIMULATION_READY_STATUS]: true,
        [PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT]: WriteSimulationPermissionValue.ASSIGNED,
        [PermissionKey.WRITE_STAFF_USERS]: false,
        [PermissionKey.EDIT_SIMULATION_STAFF]: false,
    },
};
