import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { AddStaffUserModal } from './AddStaffUserModal';

export const AddStaffUserButton: FC = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>+ New User</SecondaryButton>
            {showModal && <AddStaffUserModal onModalClose={() => setShowModal(false)} />}
        </>
    );
};
