import styled from 'styled-components/macro';

type AlignOptions = 'start' | 'end' | 'center' | 'stretch';

export interface VerticalWithSpacingProps {
    gap?: number;
    horizontalAlign?: AlignOptions;
    verticalAlign?: AlignOptions;
}
const VerticalWithSpacing = styled.div<VerticalWithSpacingProps>`
    display: grid;
    grid-auto-flow: row;
    row-gap: ${({ gap }) => (gap !== undefined ? gap : 20)}px;
    justify-items: ${({ horizontalAlign }) => horizontalAlign || 'normal'};
    align-content: ${({ verticalAlign }) => verticalAlign || 'start'};

    & > * {
        min-width: 0;
    }
`;

interface HorizontalWithSpacingProps {
    gap?: number;
    verticalCenter?: boolean;
    horizontalAlign?: AlignOptions;
    columns?: string;
}
const HorizontalWithSpacing = styled.div<HorizontalWithSpacingProps>`
    display: grid;
    grid-auto-flow: column;
    column-gap: ${({ gap }) => (gap !== undefined ? gap : 20)}px;
    justify-content: ${({ horizontalAlign }) => horizontalAlign || 'start'};
    align-items: ${({ verticalCenter }) => (verticalCenter ? 'center' : 'normal')};
    grid-template-columns: ${({ columns }) => columns || 'none'};

    & > * {
        min-width: 0;
    }
`;

interface HorizontalWithMaxSpaceBetweenProps {
    verticalCenter?: boolean;
}
const HorizontalWithMaxSpaceBetween = styled.div<HorizontalWithMaxSpaceBetweenProps>`
    display: flex;
    justify-content: space-between;
    align-items: ${({ verticalCenter }) => (verticalCenter ? 'center' : 'normal')};
`;

const RightAlign = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const HorizontalCenterVerticalCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    flex: 1 1 auto;
`;

export const Display = {
    /* eslint-disable @typescript-eslint/naming-convention */
    VerticalWithSpacing,
    HorizontalWithSpacing,
    HorizontalWithMaxSpaceBetween,
    RightAlign,
    HorizontalCenterVerticalCenter,
};
