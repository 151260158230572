import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { AddStaffUserButton } from './AddStaffUserButton';
import { StaffUsersPage } from './StaffUsersPage';
import { StaffUserDetailsPane } from './StaffUserDetailsPane';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { H1Heading } from '../../../bits/Headers';
import { Display } from '../../../bits/Display';
import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { StaffUserContext } from '../../../WithStaffUser';
import { FullPageError } from '../../../patterns/FullPageError';

const StaffUsersPageHeader = styled.div`
    margin: 15px;
`;

const Container = styled.div`
    display: flex;

    flex: 1 1 auto;
`;

const MainContent = styled.div`
    flex: 1 1 auto;
`;

export const Users = (): ReactElement => {
    const staffUser = useContext(StaffUserContext);
    const [staffUserToEdit, setStaffUserToEdit] = useState<StaffUser>();

    const canListStaff = userCan(PermissionKey.LIST_STAFF_USERS, staffUser);
    if (!canListStaff) {
        return <FullPageError errorCode="404" message="This page could not be found" />;
    }
    const canEditStaff = userCan(PermissionKey.WRITE_STAFF_USERS, staffUser);

    return (
        <>
            <Container>
                <MainContent>
                    <StaffUsersPageHeader>
                        <Display.HorizontalWithSpacing verticalCenter>
                            <H1Heading>Users</H1Heading>
                            {canEditStaff && <AddStaffUserButton />}
                        </Display.HorizontalWithSpacing>
                    </StaffUsersPageHeader>
                    <StaffUsersPage setStaffUserToEdit={setStaffUserToEdit} />
                </MainContent>
                {staffUserToEdit && (
                    <StaffUserDetailsPane
                        key={staffUserToEdit.uuid}
                        initialStaffUser={staffUserToEdit}
                        closePane={() => setStaffUserToEdit(undefined)}
                    />
                )}
            </Container>
        </>
    );
};
