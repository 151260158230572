import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey2Colour, grey5Colour } from '../../themes/colours';
import { body1 } from '../../themes/cssSnippets';

const MenuContainer = styled.div`
    position: relative;
`;

interface MenuProps {
    open?: boolean;
    direction?: 'left' | 'right';
    up?: boolean;
}
const Menu = styled.div.attrs(() => ({ ['aria-haspopup']: true }))<MenuProps>`
    background: #ffffff;
    min-width: 125px;
    box-shadow: 1px 1px 5px 1px ${grey2Colour};
    position: absolute;
    ${({ direction }) => (direction === 'left' ? 'left: 0' : 'right: 0')};
    ${({ up }) => (up ? 'bottom: 10px;' : '')}
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-wrap: wrap;
    z-index: 50;
`;

export const MenuItem = styled.button.attrs(({ type, onClick }) => ({
    role: 'option',
    type: type || 'button',
    onClick: (e) => {
        if (!type || type === 'button') {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    display: flex;
    flex-direction: row;
    padding: 18px 15px;
    flex: 1 1 auto;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;

    ${body1}
    color: ${grey5Colour};

    svg {
        flex: 0 0 auto;
    }

    :hover {
        background: ${grey1Colour};
    }
`;

interface MenuButtonProps {
    className?: string;
    direction?: 'left' | 'right';
    up?: boolean;
    buttonComponent: FC<React.ButtonHTMLAttributes<HTMLButtonElement>>;
    closeMenuRef?: MutableRefObject<(() => void) | undefined>;
}
export const MenuButton: FC<MenuButtonProps> = ({
    className,
    direction = 'left',
    up,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    buttonComponent: ButtonComponent,
    closeMenuRef,
    children,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (closeMenuRef) {
            closeMenuRef.current = () => setIsMenuOpen(false);
        }

        const closeMenu = (e: MouseEvent) => {
            if (menuRef.current) {
                const targetIsContainer = menuRef.current === e.target;
                const targetIsOutsideContainer = !menuRef.current.contains(e.target as Node);
                if (targetIsOutsideContainer || targetIsContainer) {
                    setIsMenuOpen(false);
                }
            }
        };
        document.addEventListener('click', closeMenu);
        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, []);

    return (
        <MenuContainer className={className}>
            <ButtonComponent onClick={() => setIsMenuOpen(!isMenuOpen)} />
            <Menu open={isMenuOpen} direction={direction} ref={menuRef} up={up}>
                {children}
            </Menu>
        </MenuContainer>
    );
};
