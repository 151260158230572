import { Team } from 'polpeo-go-common/types/Team';
import { includes, pluck, reduce, values } from 'ramda';

export function getPersonaList<T extends { teamUUID: string; persona: string | undefined }>(
    items: T[] | Record<string, T | undefined>,
    teams?: Team[] | string[],
): string[] {
    let teamUUIDs: undefined | string[] = undefined;
    if (teams) {
        if (typeof teams[0] === 'string') {
            teamUUIDs = teams as string[];
        } else {
            teamUUIDs = pluck('uuid', teams as Team[]);
        }
    }

    const itemList = Array.isArray(items) ? items : (values(items) as T[]);
    const personas = reduce(
        (acc, item) => {
            if (!item.persona) {
                return acc;
            }
            if (teamUUIDs && teamUUIDs.length && !includes(item.teamUUID, teamUUIDs)) {
                return acc;
            }
            if (includes(item.persona, acc)) {
                return acc;
            }
            return [...acc, item.persona];
        },
        [] as string[],
        itemList,
    );

    return personas;
}
