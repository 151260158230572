import * as t from 'io-ts';
import optionalToUndefined from '../../t/optionalToUndefined';
import { BaseThemingOptions } from './BaseThemingOptions';

export const ContentItemCreatorOptions = t.intersection([
    BaseThemingOptions,
    t.type({
        username: BaseThemingOptions,
    }),
]);
export type ContentItemCreatorOptions = t.TypeOf<typeof ContentItemCreatorOptions>;

export const ContentItemThemingOptions = t.intersection([
    t.partial({
        ...BaseThemingOptions.props,
        avatar: optionalToUndefined(
            t.intersection([
                BaseThemingOptions,
                t.type({
                    position: t.union([t.literal('inline'), t.literal('gutter')]),
                }),
            ]),
        ),
        timestamp: optionalToUndefined(t.literal('inline')),
    }),
    t.type({
        creator: ContentItemCreatorOptions,
        meta: t.partial({
            ...BaseThemingOptions.props,
            alignment: optionalToUndefined(t.literal('flex-end')),
        }),
        interactions: t.partial({
            ...BaseThemingOptions.props,
            layout: optionalToUndefined(
                t.union([
                    t.literal('center'),
                    t.literal('flex-start'),
                    t.literal('flex-end'),
                    t.literal('space-evenly'),
                    t.literal('space-between'),
                ]),
            ),
            horizontalGap: optionalToUndefined(t.number),
            iconHeight: optionalToUndefined(t.number),
        }),
        deletedPlaceholder: BaseThemingOptions,
        fields: t.record(t.string, BaseThemingOptions),
    }),
    t.partial({
        showChildItemsCount: optionalToUndefined(
            t.partial({
                ...BaseThemingOptions.props,
                position: t.union([t.literal('inline with interactions'), t.literal('use field order')]),
            }),
        ),
    }),
]);
export type ContentItemThemingOptions = t.TypeOf<typeof ContentItemThemingOptions>;
