import gql from 'graphql-tag';
import wrapSubscription from '../wrapSubscription';

interface OnLockTeamsParams {
    simulationUUID: string;
}

interface OnLockTeamsData {
    onLockTeams: {
        simulationUUID: string;
        assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
    };
}

const subscription = gql`
    subscription onLockTeams($simulationUUID: String!) {
        onLockTeams(simulationUUID: $simulationUUID) {
            simulationUUID
            assignments
        }
    }
`;

export const onLockTeams = wrapSubscription<OnLockTeamsParams, OnLockTeamsData>(subscription);
