import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Team } from 'polpeo-go-common/types/Team';
import { any, assocPath, map, prop, sortBy, values } from 'ramda';
import React, { FC, useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Display } from '../../../../bits/Display';
import { FABContainer } from '../../../../patterns/FAB/FABContainer';
import { TabItem, TabMenu } from '../../../../patterns/TabMenu';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { SimulationPlaybar } from '../../../SimulationPlaybar';
import { AdminTriggerPopup } from '../AdminTriggerPopup';
import { AllTeamsOverview } from '../AllTeamsOverview';
import { ManageSimulationMenuButton } from '../ManageSimulationMenuButton';
import { NewContentItemFAB } from '../TeamView/NewContentItemFAB';
import { SimulationTeamNav } from '../TeamView/SimulationTeamNav';
import { TeamEmailFAB } from '../TeamView/TeamEmailFAB';
import { TeamOverview } from '../TeamView/TeamOverview';
import { TeamPageContentDetails } from '../TeamView/TeamPageContentDetails';
import { TeamPageContentList } from '../TeamView/TeamPageContentList';
import { TeamScratchpadFAB } from '../TeamView/TeamScratchpadFAB';
import { GlobalStyleForPage, MainContentContainer, NavBarScrollBox, PageContentContainer, ScrollBox } from './utils';

const MenuGroup = styled(Display.HorizontalWithSpacing).attrs(() => ({
    verticalCenter: true,
}))`
    height: 100%;
`;

export const StaffUserSimulationInProgressPage: FC = () => {
    const { hash } = useLocation();
    const history = useHistory();
    const { teamUUID, pageUUID, itemUUID } = useParams<{ teamUUID?: string; pageUUID?: string; itemUUID?: string }>();
    const staffUser = useContext(StaffUserContext);
    const { simulation, teamsForCurrentStaffUser, notifications, setNotifications } =
        useContext(AdminInSimulationStateContext);

    const sortedTeams = sortBy(prop('name'), values(teamsForCurrentStaffUser));
    const isSimulationAdministrator = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
    const showPlaybar = isSimulationAdministrator;

    useLayoutEffect(() => {
        if (teamUUID) {
            const pageNotifications = notifications[teamUUID]?.pages || {};
            if (pageUUID && pageNotifications[pageUUID]) {
                setNotifications(assocPath([teamUUID, 'pages', pageUUID], false));
            }

            if (itemUUID && pageNotifications[itemUUID]) {
                setNotifications(assocPath([teamUUID, 'pages', itemUUID], false));
            }
        }
    }, [notifications, pageUUID, itemUUID]);

    useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                // There seems to be some weird rendering thing where if we try to scroll as soon as the content
                // is rendered the item does not scroll properly into view so we wait a bit after rendering to scroll
                const scrollTimeout = setTimeout(
                    () => element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }),
                    10,
                );
                return () => clearTimeout(scrollTimeout);
            }
        }
    }, [hash]);

    useEffect(() => {
        if (!isSimulationAdministrator) {
            const firstTeamUUID = sortedTeams[0].uuid;
            history.push(`/admin/simulation/${simulation.uuid}/team/${firstTeamUUID}`);
        }
    }, []);

    return (
        <>
            <GlobalStyleForPage />
            <PageContentContainer>
                <TabMenu
                    tabs={[
                        ...(isSimulationAdministrator
                            ? [
                                  {
                                      label: 'Overview',
                                      icon: 'content' as const,
                                      navLink: {
                                          to: `/admin/simulation/${simulation.uuid}`,
                                          exact: true,
                                      },
                                      route: {
                                          path: '/admin/simulation/:simulationUUID',
                                          children: <AllTeamsOverview />,
                                          exact: true,
                                      },
                                  },
                              ]
                            : []),
                        ...map<Team, TabItem>((team) => {
                            const rootUrl = `/admin/simulation/${simulation.uuid}/team/${team.uuid}`;
                            const rootPath = '/admin/simulation/:simulationUUID/team/:teamUUID';

                            return {
                                label: team.name,
                                icon: 'team',
                                notification: !!(
                                    notifications[team.uuid]?.emails ||
                                    notifications[team.uuid]?.scratchpad ||
                                    any((x) => !!x, values(notifications[team.uuid]?.pages || {}))
                                ),
                                navLink: { to: rootUrl },
                                route: {
                                    // This needs to be the URL and not the PATH otherwise the generated <Route> will
                                    // greedily match on all team uuids and you'll end up only being able to see the
                                    // first team's content!
                                    path: [rootUrl, `${rootUrl}/:pageUUID`, `${rootUrl}/:pageUUID/:itemUUID`],
                                    children: (
                                        // Redeclare the route here so that components can use useParams to get
                                        // teamUUID, pageUUID, itemUUID when they need to
                                        <Route
                                            path={[
                                                rootPath,
                                                `${rootPath}/:pageUUID`,
                                                `${rootPath}/:pageUUID/:itemUUID`,
                                            ]}
                                        >
                                            <MainContentContainer>
                                                <NavBarScrollBox>
                                                    <SimulationTeamNav team={team} rootUrl={rootUrl} />
                                                </NavBarScrollBox>
                                                <ScrollBox>
                                                    <Switch>
                                                        <Route path={rootPath} exact>
                                                            <TeamOverview team={team} />
                                                        </Route>
                                                        <Route path={`${rootPath}/:pageUUID`} exact>
                                                            <TeamPageContentList team={team} />
                                                        </Route>
                                                        <Route path={`${rootPath}/:pageUUID/:itemUUID`} exact>
                                                            <TeamPageContentDetails team={team} />
                                                        </Route>
                                                    </Switch>
                                                </ScrollBox>
                                                {simulation && (
                                                    <FABContainer bottom={showPlaybar ? 80 : 0}>
                                                        <NewContentItemFAB />
                                                        <TeamScratchpadFAB team={team} />
                                                        <TeamEmailFAB team={team} />
                                                    </FABContainer>
                                                )}
                                            </MainContentContainer>
                                        </Route>
                                    ),
                                },
                            };
                        }, sortedTeams),
                    ]}
                    appendedItems={
                        <MenuGroup>
                            <ManageSimulationMenuButton />
                        </MenuGroup>
                    }
                />
                {showPlaybar && <SimulationPlaybar />}
                <AdminTriggerPopup />
            </PageContentContainer>
        </>
    );
};
