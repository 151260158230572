import { ContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Page } from 'polpeo-go-common/types/Page';
import { ParticipantTeamAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { Team } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { createContext } from 'react';

type TeamUUID = string;
export interface SimulationContent {
    triggers: Record<string, Trigger>;
    triggerOrder: string[];
    triggerPrepreparedContentLinks: Record<string, string[]>;
    pages: Record<string, Page>;
    pageOrder: string[];
    prepreparedContents: Record<string, PrepreparedContentItem | undefined>;
    prepreparedEmails: Record<string, PrepreparedEmailItem | undefined>;
    contentItems: Record<TeamUUID, Record<string, ContentItem | undefined>>;
    emailItems: Record<TeamUUID, Record<string, EmailItem | undefined>>;
    moments: Record<TeamUUID, Record<string, Moment | undefined>>;
}

export interface AdminState {
    simulations: Record<string, Simulation>;
    addSimulation: (simulation: Simulation) => void;
    setSimulation: (simulation: Simulation) => void;
    removeSimulation: (simulation: Simulation | string) => void;

    teams: Record<string, Record<string, Team>>;
    setTeams: (teams: Record<string, Record<string, Team>>) => void;
    setTeam: (team: Team) => void;
    removeTeam: (team: Team) => void;

    staffUsers: Record<string, StaffUser | undefined>;
    setStaffUsers: (staffUsers: Record<string, StaffUser>) => void;

    participants: Record<string, Record<string, ParticipantUser>>;
    setParticipants: (simulationUUID: string, participants: Record<string, ParticipantUser>) => void;
    teamAssignments: Record<string, ParticipantTeamAssignments>;
    setTeamAssignments: (simulationUUID: string, teamAssignments: ParticipantTeamAssignments) => void;

    simulationContent: Record<string, SimulationContent>;
    setSimulationContent: (simulationUUID: string, content: SimulationContent) => void;

    setTriggers: (simulationUUID: string, triggers: Record<string, Trigger>) => void;
    setTrigger: (trigger: Trigger) => void;
    addTrigger: (trigger: Trigger) => void;
    removeTrigger: (trigger: Trigger) => void;
    setTriggerOrder: (simulationUUID: string, order: string[]) => void;
    setTriggerPrepreparedContentLinks: (simulationUUID: string, links: Record<string, string[]>) => void;

    setPages: (simulationUUID: string, pages: Record<string, Page>) => void;
    setPage: (page: Page) => void;
    addPage: (page: Page) => void;
    removePage: (page: Page) => void;
    setPageOrder: (simulationUUID: string, order: string[]) => void;

    setPrepreparedContents: (
        simulationUUID: string,
        prepreparedContents: Record<string, PrepreparedContentItem>,
    ) => void;
    setPrepreparedContent: (prepreparedContent: PrepreparedContentItem) => void;
    removePrepreparedContentItems: (simulationUUID: string, itemUUIDs: string[]) => void;

    addContentItems: (simulationUUID: string, contentItems: ContentItem[] | Record<string, ContentItem>) => void;

    setPrepreparedEmails: (simulationUUID: string, prepreparedEmails: Record<string, PrepreparedEmailItem>) => void;
    setPrepreparedEmail: (prepreparedEmail: PrepreparedEmailItem) => void;
    removePrepreparedEmailItems: (simulationUUID: string, itemUUIDs: string[]) => void;

    addEmailItems: (simulationUUID: string, contentItems: EmailItem[] | Record<string, EmailItem>) => void;

    addMoments: (simulationUUID: string, moments: Moment[] | Record<string, Moment>) => void;

    clearSimulationData: (simulationUUID: string) => void;
}

// No one should ever be accessing AdminState before it's been initialised anyways so we shouldn't
// provide any default values
export const AdminStateContext = createContext<AdminState>({} as AdminState);
