import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import WithChildren from '../../utils/WithChildren';
import WithClassname from '../../utils/WithClassname';
import { CardBody, CardContainer, CardContainerProps, CardHeader } from '../bits/Card';
import { H2Heading } from '../bits/Headers';

export interface CardProps extends CardContainerProps, WithChildren, WithClassname {
    header?: string | JSX.Element;
}

export const Card: FC<CardProps> = ({ header, children, className, cardWidth, minCardWidth, maxCardWidth }) => (
    <CardContainer className={className} cardWidth={cardWidth} minCardWidth={minCardWidth} maxCardWidth={maxCardWidth}>
        {header && (
            <CardHeader> {typeof header === 'string' ? <H2Heading>{header}</H2Heading> : <>{header}</>} </CardHeader>
        )}
        <CardBody>{children}</CardBody>
    </CardContainer>
);

interface CardLinkProps extends CardProps {
    href: string;
}
export const CardLink: FC<CardLinkProps> = ({ href, children, ...cardProps }) => (
    <Link to={href}>
        <Card {...cardProps}>{children}</Card>
    </Link>
);

interface CardButtonProps extends CardProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const CardButton: FC<CardButtonProps> = ({ onClick, children, ...cardProps }) => (
    <button onClick={onClick}>
        <Card {...cardProps}>{children}</Card>
    </button>
);
