import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { grey2Colour, grey4Colour, primaryColour } from '../../../themes/colours';
import WithChildren from '../../../utils/WithChildren';

const CheckboxDiv = styled.div`
    position: relative;
    min-height: 22px;
    margin: 0;
    display: flex;
    align-items: center;
`;

const CheckboxInput = styled.input.attrs<never>({
    type: 'checkbox',
})`
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    opacity: 0;
`;
CheckboxInput.displayName = 'CheckboxInput';

const CheckboxLabel = styled.label`
    flex: 1 0 auto;
    padding-left: 32px;
    width: 100%;
    cursor: pointer;
    user-select: none;
    word-break: break-word;
    margin-bottom: 0;
    &:before {
        content: '';
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        cursor: pointer;
        user-select: none;
        border: 1px solid ${grey2Colour};
        box-sizing: border-box;
        transition: all 0.2s ease;
        border-radius: 5px;
    }
    ${CheckboxInput}:not(:checked) + &:before {
        border: 1px solid ${grey2Colour};
    }
    ${CheckboxInput}:not(:checked) + &:hover:before {
        border: 1px solid ${grey2Colour};
    }
    ${CheckboxInput}:focus + &:before {
        border: 1px solid ${primaryColour};
    }

    /* tick */
    &:after {
        content: '';
        position: absolute;
        width: 7px;
        height: 14px;
        cursor: pointer;
        top: 3px;
        left: 9px;
        border-right: 3px solid ${grey4Colour};
        border-bottom: 3px solid ${grey4Colour};
        transition: all 0.2s ease;
    }
    ${CheckboxInput}:not(:checked) + &:after {
        opacity: 0;
        transform: rotate(45deg) scale(0);
    }
    ${CheckboxInput}:checked + &:after {
        opacity: 1;
        transform: rotate(45deg) scale(1);
    }

    ${CheckboxInput}:disabled + &, ${CheckboxInput}:disabled + &:before, ${CheckboxInput}:disabled + &:after {
        cursor: auto;
    }

    ${CheckboxInput}:disabled + &:before {
        background: #f5f5f5;
    }
`;

interface CheckboxRequiredProps extends WithChildren {
    id: string;
}
type CheckboxProps = CheckboxRequiredProps & React.InputHTMLAttributes<HTMLInputElement>;
export const Checkbox: FC<CheckboxProps> = ({ children, id, ...checkboxProps }: CheckboxProps) => (
    <CheckboxDiv key={id}>
        <CheckboxInput id={id} {...checkboxProps} />
        <CheckboxLabel htmlFor={id}>{children}</CheckboxLabel>
    </CheckboxDiv>
);
