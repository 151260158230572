import gql from 'graphql-tag';
import wrapQuery from '../wrapQuery';

interface GetTeamAssignmentsParams {
    simulationUUID: string;
}

interface GetTeamAssignmentsData {
    getTeamAssignments: {
        simulationUUID: string;
        assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
    };
}

const query = gql`
    query getTeamAssignments($simulationUUID: String!) {
        getTeamAssignments(simulationUUID: $simulationUUID) {
            simulationUUID
            assignments
        }
    }
`;

export const getTeamAssignments = wrapQuery<GetTeamAssignmentsParams, GetTeamAssignmentsData>(query);
