import { StaffUser, UserRoleEnum } from 'polpeo-go-common/types/StaffUser';
import { always, cond, values } from 'ramda';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import {
    adminDarkColour,
    adminLightColour,
    primaryColour,
    roleplayerDarkColour,
    roleplayerLightColour,
} from '../../../../themes/colours';
import { makeStaffLastLoginLabel } from '../../../../utils/makeStaffLastLoginLabel';
import { sortAlphabeticallyByName } from '../../../../utils/sortAlphabeticallyByName';
import { Icon } from '../../../bits/Icon';
import { Lozenge as BaseLozenge } from '../../../bits/Lozenge';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { AdminTable } from '../../AdminTable';
import { userCan, PermissionKey } from 'polpeo-go-common/permissions';
import { StaffUserContext } from '../../../WithStaffUser';

const StaffUserName = styled.span`
    color: ${primaryColour};
`;

const Lozenge = styled(BaseLozenge)`
    display: inline-flex;
    align-items: center;
    font-size: 11px;

    svg {
        margin-right: 6px;
    }
`;

const AdminAndSimulationAdminLozenge = styled(Lozenge)`
    background-color: ${adminLightColour};
    color: ${adminDarkColour};
`;

const RoleplayerAndContentCreatorLozenge = styled(Lozenge)`
    background-color: ${roleplayerLightColour};
    color: ${roleplayerDarkColour};
`;

interface StaffUserPagePageProps {
    setStaffUserToEdit: (staffUser: StaffUser) => void;
}
export const StaffUsersPage: FC<StaffUserPagePageProps> = ({ setStaffUserToEdit }: StaffUserPagePageProps) => {
    const { staffUsers } = useContext(AdminStateContext);
    const currentStaffUser = useContext(StaffUserContext);
    const canEditStaff = userCan(PermissionKey.WRITE_STAFF_USERS, currentStaffUser);
    return (
        <AdminTable<StaffUser>
            headers={['Name', 'Role', 'Email', 'Last Login Date', canEditStaff ? 'Edit' : '']}
            items={sortAlphabeticallyByName(values(staffUsers as Record<string, StaffUser>))}
            getItemKey={(staffUser) => staffUser.uuid}
            getItemCell={(staffUser, header) =>
                cond([
                    [() => header === 'Name', () => <StaffUserName>{staffUser.name}</StaffUserName>],
                    [
                        () => header === 'Role',
                        () =>
                            cond([
                                [
                                    always(staffUser.role === UserRoleEnum.ADMIN),
                                    always(
                                        <AdminAndSimulationAdminLozenge>
                                            <Icon icon="admin" width={16} height={16} />
                                            ADMIN
                                        </AdminAndSimulationAdminLozenge>,
                                    ),
                                ],
                                [
                                    always(staffUser.role === UserRoleEnum.SIMULATION_ADMINISTRATOR),
                                    always(
                                        <AdminAndSimulationAdminLozenge>
                                            <Icon icon="admin" width={16} height={16} />
                                            SIMULATION ADMINISTRATOR
                                        </AdminAndSimulationAdminLozenge>,
                                    ),
                                ],
                                [
                                    always(staffUser.role === UserRoleEnum.ROLEPLAYER),
                                    always(
                                        <RoleplayerAndContentCreatorLozenge>
                                            <Icon icon="roleplayer" width={16} height={16} />
                                            ROLEPLAYER
                                        </RoleplayerAndContentCreatorLozenge>,
                                    ),
                                ],
                                [
                                    always(staffUser.role === UserRoleEnum.CONTENT_CREATOR),
                                    always(
                                        <RoleplayerAndContentCreatorLozenge>
                                            <Icon icon="roleplayer" width={16} height={16} />
                                            CONTENT CREATOR
                                        </RoleplayerAndContentCreatorLozenge>,
                                    ),
                                ],
                            ])(),
                    ],
                    [() => header === 'Email', () => staffUser.email],
                    [
                        () => header === 'Last Login Date',
                        () => (staffUser.lastLogin ? makeStaffLastLoginLabel(staffUser.lastLogin) : ''),
                    ],
                    [
                        () => header === 'Edit',
                        () => (
                            <button
                                onClick={() => {
                                    setStaffUserToEdit(staffUser);
                                }}
                            >
                                <Icon icon={'edit'} width={15} height={15}></Icon>
                            </button>
                        ),
                    ],
                ])()
            }
        />
    );
};
