import { map } from 'ramda';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { grey2Colour, primaryColour } from '../../../themes/colours';
import { body1 } from '../../../themes/cssSnippets';

interface SelectProps {
    validationFailed?: boolean;
    small?: boolean;
}
const Select = styled.select<SelectProps>`
    background: #ffffff;
    width: 100%;
    ${(props) => (props.small ? 'padding: 10px 0px;' : 'padding: 20px 16px;')}

    ${body1}

    border: 1px solid ${(props) => (props.validationFailed ? 'red' : grey2Colour)};
    border-radius: 5px;

    option {
        ${body1}
    }

    &:focus {
        border: 1px solid ${primaryColour};
    }
`;

export interface OptionItem {
    value: string;
    label: string;
    disabled?: boolean;
}

interface DropdownProps extends SelectProps, React.SelectHTMLAttributes<HTMLSelectElement> {
    options: OptionItem[];
    value?: string;
    small?: boolean;
}
export const Dropdown: FC<DropdownProps> = ({ options, value, ...selectProps }) => (
    <Select {...selectProps} value={value || ''}>
        {map<OptionItem, React.ReactNode>(
            (option) => (
                <option key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                </option>
            ),
            options,
        )}
    </Select>
);
