import gql from 'graphql-tag';
import { NewTeam, Team } from 'polpeo-go-common/types/Team';
import wrapMutation from '../wrapMutation';

interface CreateTeamParams {
    team: NewTeam;
}

interface CreateTeamData {
    createTeam: Omit<Team, 'pageStats'> & { pageStats: string /* AWSJSON - Record<string, Record<string>> */ };
}

const mutation = gql`
    mutation createTeam($team: NewTeamInput!) {
        createTeam(team: $team) {
            uuid
            simulationUUID
            name
            roleplayerUUIDs
            documentUUIDs
            overview {
                title
                description
                headerImage
            }
            pageStats
        }
    }
`;

export const createTeam = wrapMutation<CreateTeamParams, CreateTeamData>(mutation);
