import styled from 'styled-components/macro';
import { Display } from '../../../../../bits/Display';

export const ManagePrepreparedContentCardGrid = styled.div`
    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        'toolbar main'
        'toolbar footer';
    column-gap: 20px;
    row-gap: 20px;
`;
export const ManagePrepreparedContentCardToolbar = styled.div`
    grid-area: toolbar;
    align-self: end;

    display: grid;
`;
export const ManagePrepreparedContentCardMain = styled(Display.VerticalWithSpacing).attrs(() => ({
    gap: 10,
}))`
    grid-area: main;
`;
export const ManagePrepreparedContentCardFooter = styled(Display.HorizontalWithSpacing).attrs(() => ({
    horizontalAlign: 'end',
    verticalCenter: true,
}))`
    grid-area: footer;
`;
