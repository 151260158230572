import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapMutation from '../wrapMutation';

interface EditSimulationParams {
    simulation: Simulation;
}

interface EditSimulationData {
    editSimulation: Simulation;
}

const mutation = gql`
    mutation editSimulation($simulation: SimulationInput!) {
        editSimulation(simulation: $simulation) {
            uuid
            name
            code
            createdAt
            createdBy
            scheduledFor
            lobbyOpenedAt
            startedAt
            completedAt
            archivedAt
            clientName
            isReady
            emailDomainWhitelist
            expectedNumberParticipants
            administratorUUIDs
            briefingDocuments {
                name
                createdAt
                path
                createdBy
            }
            briefingNotes {
                lastEditedAt
                lastEditedBy
                notes
            }
            startingOverview {
                title
                description
                headerImage
            }
            scratchPadDocuments {
                uuid
                category
                path
                createdAt
                createdBy
                lastEditedBy
                notes
                name
                simulationUUID
            }
            showParticipantsMomentsGraph
            createMomentsPermission
        }
    }
`;

export const editSimulation = wrapMutation<EditSimulationParams, EditSimulationData>(mutation);
