import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Moment, MomentRating } from 'polpeo-go-common/types/Moment';
import { fromPairs, last, map, sortBy } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Vega } from 'react-vega';
import { constructiveColour, destructiveColour } from '../../themes/colours';
import { EmbeddedContentItem } from '../templates/PageTemplate/EmbeddedContentItem';
import { AdminInSimulationStateContext } from '../WithAdminInSimulationState';
import { ParticipantStateContext } from '../WithParticipantState';
import { Modal } from './Modal';

const toNearest15 = (n: number): number => Math.floor(n / 15) * 15;
const makeXAxisValues = (start: DateTime, end: DateTime): number[] => {
    const diff = end.diff(start, ['hours', 'minutes']);
    let intervalMins: number;
    if (diff.hours < 3) {
        intervalMins = 15;
    } else if (diff.hours < 6) {
        intervalMins = 30;
    } else if (diff.hours < 12) {
        intervalMins = 60;
    } else if (diff.hours < 24) {
        intervalMins = 120;
    } else {
        intervalMins = 300;
    }

    let xAxisTicks: number[] = [start.toMillis()];
    let lastTick: DateTime | undefined = start;
    while (!!lastTick) {
        const newTick: DateTime = lastTick.plus({ hours: Math.floor(intervalMins / 60), minute: intervalMins % 60 });
        if (newTick > end) {
            lastTick = undefined;
            continue;
        }
        xAxisTicks = [...xAxisTicks, newTick.toMillis()];
        lastTick = newTick;
    }
    return xAxisTicks;
};

const makeSpecs = (startDate: Date, endDate?: Date) => {
    const startX = DateTime.fromJSDate(startDate);
    const roundedStartX = startX.set({ minute: toNearest15(startX.minute), second: 0, millisecond: 0 });
    const endX = DateTime.fromJSDate(endDate || new Date());
    const roundedEndX = endX.set({ minute: toNearest15(endX.minute), second: 0, millisecond: 0 }).plus({ minute: 15 });

    const xAxisTicks = makeXAxisValues(roundedStartX, roundedEndX);

    return {
        width: 400,
        height: 150,
        autosize: 'fit-x' as const,
        data: [
            {
                name: 'table',
                values: [],
                transform: [
                    {
                        type: 'timeunit' as const,
                        units: ['hours' as const],
                        field: 'createdAt',
                    },
                ],
            },
        ],
        signals: [
            {
                name: 'hover',
                value: {},
                on: [{ events: 'rect:click', update: 'datum', force: true }],
            },
        ],
        scales: [
            {
                name: 'x',
                type: 'time' as const,
                range: 'width' as const,
                round: true,
                domain: { data: 'table', field: 'createdAt' },
                domainMin: roundedStartX.toMillis(),
                domainMax: roundedEndX.toMillis(),
            },
            {
                name: 'y',
                type: 'linear' as const,
                range: 'height' as const,
                nice: true,
                zero: true,
                domain: { data: 'table', field: 'ratingAsNumber' },
                domainMin: -2,
                domainMax: 2,
            },
        ],
        axes: [
            {
                orient: 'left' as const,
                scale: 'y',
                grid: true,
                ticks: false,
                tickCount: 4,
                labels: false,
            },
            {
                orient: 'bottom' as const,
                scale: 'x',
                format: '%H:%M',
                grid: true,
                values: xAxisTicks,
            },
        ],
        marks: [
            {
                type: 'rect' as const,
                from: { data: 'table' },
                encode: {
                    enter: {
                        width: { value: 3 },
                        x: { scale: 'x', field: 'createdAt' },
                        y: { scale: 'y', value: 0 },
                        y2: { scale: 'y', field: 'ratingAsNumber' },
                        fill: { signal: `datum.ratingAsNumber > 0 ? "${constructiveColour}" : "${destructiveColour}"` },
                    },
                    hover: {
                        cursor: { value: 'pointer' as const },
                    },
                },
            },
            {
                type: 'rule' as const,
                encode: {
                    update: {
                        x: { field: { group: 'x' } },
                        x2: { field: { group: 'width' } },
                        y: { value: 0.5, offset: { scale: 'y', value: 0, round: true } },
                        stroke: { value: 'black' },
                        strokeWidth: { value: 1 },
                    },
                },
            },
        ],
    };
};

const ratingToNumberMap: Record<MomentRating, number> = {
    [MomentRating.Down2]: -2,
    [MomentRating.Down1]: -1,
    [MomentRating.Up1]: 1,
    [MomentRating.Up2]: 2,
};

const makeData = (moments: Moment[]) => ({
    table: map(
        (moment) => ({
            createdAt: moment.created.at,
            ratingAsNumber: moment.rating ? ratingToNumberMap[moment.rating] : 0,
            moment: moment,
        }),
        moments,
    ),
});

interface MomentItemPreviewModalProps {
    item: ContentItem;
    onModalClose: () => void;
}
const MomentItemPreviewModal: FC<MomentItemPreviewModalProps> = ({ item, onModalClose }) => {
    const adminState = useContext(AdminInSimulationStateContext);
    const participantState = useContext(ParticipantStateContext);

    const page = useMemo(() => {
        const pages =
            adminState.simulationContent?.pages ||
            fromPairs(map((page) => [page.uuid, page], participantState.pages || [])) ||
            {};
        const itemPage = pages[item.content.pageUUID];

        return itemPage;
    }, [adminState, participantState]);
    return (
        <Modal header={page.name} onModalClose={onModalClose} cardWidth={500}>
            <EmbeddedContentItem item={item} />
        </Modal>
    );
};

interface MomentsGraphProps {
    moments: Moment[];
}
export const MomentsGraph: FC<MomentsGraphProps> = ({ moments }) => {
    const { simulation: participantSimulation } = useContext(AdminInSimulationStateContext);
    const { simulation: adminSimulation } = useContext(ParticipantStateContext);
    const [itemPreview, setItemPreview] = useState<false | ContentItem>(false);

    const simulation = adminSimulation || participantSimulation;

    const spec = useMemo(() => {
        if (!simulation.startedAt) {
            return {};
        }

        if (simulation.completedAt) {
            const orderedMoments = sortBy((moment) => moment.created.at.toISOString(), moments);
            const endDate = simulation.completedAt;

            if (orderedMoments.length) {
                const latestMomentCreated = (last(orderedMoments) as Moment).created.at;
                if (latestMomentCreated >= simulation.completedAt) {
                    // Simulation ended and moments created after end datetime so use latest moment
                    return makeSpecs(simulation.startedAt, latestMomentCreated);
                }
            }
            // Simulation ended and no moments created after end datetime so just use end date
            return makeSpecs(simulation.startedAt, endDate);
        }
        // Simulation not ended so don't provide endDate so that graph renders up to latest time
        return makeSpecs(simulation.startedAt);
    }, [simulation, moments]);

    const data = useMemo(() => {
        return makeData(moments);
    }, [moments]);

    return (
        <>
            <Vega
                spec={spec}
                data={data}
                actions={false}
                signalListeners={{
                    hover: (signalName: string, data: unknown) => {
                        const dataPoint = data as { createdAt: Date; ratingAsNumber: number; moment: Moment };
                        setItemPreview(dataPoint.moment.contentItem);
                    },
                }}
            />
            {!!itemPreview && <MomentItemPreviewModal onModalClose={() => setItemPreview(false)} item={itemPreview} />}
        </>
    );
};
