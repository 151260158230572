import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { omit, pick } from 'ramda';
import { PermissionSet, tPermissionSet } from '../permissions/sets';
import { optionalToUndefined } from '../t';
import { date } from 'io-ts-types/lib/date';

export enum UserRoleEnum {
    ADMIN = 'ADMIN',
    CONTENT_CREATOR = 'CONTENT_CREATOR',
    SIMULATION_ADMINISTRATOR = 'SIMULATION_ADMINISTRATOR',
    ROLEPLAYER = 'ROLEPLAYER',
}
export const UserRole = t.keyof(UserRoleEnum);
export const ROLE_TO_PERMISSION_SET_MAP: Record<UserRoleEnum, PermissionSet> = {
    [UserRoleEnum.ADMIN]: PermissionSet.ADMIN,
    [UserRoleEnum.CONTENT_CREATOR]: PermissionSet.CONTENT_CREATOR,
    [UserRoleEnum.SIMULATION_ADMINISTRATOR]: PermissionSet.SIMULATION_ADMINISTRATOR,
    [UserRoleEnum.ROLEPLAYER]: PermissionSet.ROLEPLAYER,
};

export enum SkillLevelEnum {
    COMPLEX = 'COMPLEX',
    STANDARD = 'STANDARD',
}
export const SkillLevel = t.keyof(SkillLevelEnum);

export const StaffUser = t.type({
    uuid: t.string,
    name: t.string,
    email: t.string,
    role: UserRole,
    permissionSet: tPermissionSet,
    skillLevel: SkillLevel,
    notes: optionalToUndefined(t.string),
    lastLogin: optionalToUndefined(t.union([DateFromISOString, date])),
});
export type StaffUser = t.TypeOf<typeof StaffUser>;
// for testing purposes
export const TEST_STAFF_USER: StaffUser = {
    uuid: 'staffUserUUID',
    name: 'Staff User',
    email: 'email',
    role: 'ADMIN',
    permissionSet: 'ADMIN',
    skillLevel: 'COMPLEX',
    notes: '',
    lastLogin: undefined,
};

export const NewStaffUser = t.type(pick(['name', 'email', 'role', 'skillLevel', 'notes'], StaffUser.props));
export type NewStaffUser = t.TypeOf<typeof NewStaffUser>;

export const DynamoDbStaffUserValue = t.type(omit(['uuid'], StaffUser.props));
export type DynamoDbStaffUserValue = t.TypeOf<typeof DynamoDbStaffUserValue>;
