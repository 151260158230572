import { filter, length, values } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { lockTeams } from '../../../../../../graphql/simulations/lockTeams';
import { startSimulation } from '../../../../../../graphql/simulations/startSimulation';
import { PrimaryButton, SecondaryButton } from '../../../../../bits/Buttons';
import { Display } from '../../../../../bits/Display';
import { H2Heading, H4Heading } from '../../../../../bits/Headers';
import { Icon } from '../../../../../bits/Icon';
import { Spinner } from '../../../../../bits/Spinner';
import { Modal } from '../../../../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../../../WithStaffUser';
import { userCan, PermissionKey } from 'polpeo-go-common/permissions';

export const StartSimulationButton: FC = () => {
    const staffUser = useContext(StaffUserContext);
    const { simulation, setSimulation, participants, teamAssignments, setShowManageTeamsPage, showManageTeamsPage } =
        useContext(AdminInSimulationStateContext);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [StartSimulationMutation, { data: StartSimulationData, loading: StartSimulationLoading }] =
        startSimulation.hook();
    const [LockTeamsMutation, { data: LockTeamsData, loading: LockTeamsLoading }] = lockTeams.hook();

    const participantCount = length(values(participants));
    const assignmentCount = length(values(teamAssignments));
    const unassignedParticipantsCount = length(
        filter(
            (participant) => !teamAssignments[participant.id] || !teamAssignments[participant.id].teamUUID,
            values(participants),
        ),
    );
    const hasUnassignedParticipants = !!(participantCount !== assignmentCount || unassignedParticipantsCount);

    const showStartSimulationButton = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    useEffect(() => {
        if (StartSimulationData?.startSimulation) {
            setSimulation(StartSimulationData.startSimulation);
        }
    }, [StartSimulationData]);

    useEffect(() => {
        if (LockTeamsData?.lockTeams && showManageTeamsPage) {
            setShowManageTeamsPage(false);
        }
    }, [LockTeamsData]);

    return (
        <>
            {showStartSimulationButton && (
                <PrimaryButton disabled={StartSimulationLoading} onClick={() => setShowWarningModal(true)}>
                    <Display.HorizontalWithSpacing gap={10}>
                        {showManageTeamsPage ? 'Return to Simulation' : 'Start Simulation'}
                        {(StartSimulationLoading || LockTeamsLoading) && (
                            <Spinner stroke="#fff" width={20} height={20} />
                        )}
                    </Display.HorizontalWithSpacing>
                </PrimaryButton>
            )}
            {showWarningModal && (
                <Modal onModalClose={() => setShowWarningModal(false)} cardWidth={420}>
                    <Display.HorizontalWithSpacing>
                        <Icon icon="warning" width={45} height={45} />
                        <Display.VerticalWithSpacing>
                            {hasUnassignedParticipants ? (
                                <Display.VerticalWithSpacing gap={10}>
                                    <H2Heading>
                                        {unassignedParticipantsCount === 1
                                            ? // eslint-disable-next-line max-len
                                              'There is 1 unassigned participant. Lock in team assignments for other participants?'
                                            : // eslint-disable-next-line max-len
                                              `There are ${unassignedParticipantsCount} unassigned participants. Lock in team assignments for other participants?`}
                                    </H2Heading>
                                    <H4Heading>
                                        Participants assigned to a team cannot be moved to another after locking in.
                                        Unassigned participants can be assigned teams later from the management menu.
                                    </H4Heading>
                                    <H4Heading>
                                        Are you sure you want to lock in team assignments and continue?
                                    </H4Heading>
                                </Display.VerticalWithSpacing>
                            ) : (
                                <Display.VerticalWithSpacing gap={10}>
                                    <H2Heading>Lock in team assignments?</H2Heading>
                                    <H4Heading>
                                        Participants assigned to a team cannot be moved to another after locking in.
                                    </H4Heading>
                                    <H4Heading>
                                        Are you sure you want to lock in team assignments and continue?
                                    </H4Heading>
                                </Display.VerticalWithSpacing>
                            )}
                            <Display.RightAlign>
                                <Display.HorizontalWithSpacing>
                                    <SecondaryButton onClick={() => setShowWarningModal(false)}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        onClick={() => {
                                            if (showManageTeamsPage) {
                                                LockTeamsMutation({
                                                    variables: {
                                                        simulationUUID: simulation.uuid,
                                                    },
                                                });
                                            } else {
                                                StartSimulationMutation({
                                                    variables: { simulationUUID: simulation.uuid },
                                                });
                                            }
                                            setShowWarningModal(false);
                                        }}
                                    >
                                        Continue
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.RightAlign>
                        </Display.VerticalWithSpacing>
                    </Display.HorizontalWithSpacing>
                </Modal>
            )}
        </>
    );
};
