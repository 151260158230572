import * as t from 'io-ts';
import { last, omit, pipe, replace, split } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';

export const TeamOverviewData = t.partial({
    title: optionalToUndefined(t.string),
    description: optionalToUndefined(t.string),
    headerImage: optionalToUndefined(t.string),
});
export type TeamOverviewData = t.TypeOf<typeof TeamOverviewData>;

export const Team = t.type({
    uuid: t.string,
    simulationUUID: t.string,
    name: t.string,
    roleplayerUUIDs: t.array(t.string),
    documentUUIDs: optionalToUndefined(t.array(t.string)),
    overview: optionalToUndefined(TeamOverviewData),
    pageStats: optionalToUndefined(t.record(t.string, t.record(t.string, t.union([t.string, t.number])))),
});
export type Team = t.TypeOf<typeof Team>;
export const TEST_TEAM: Team = {
    uuid: 'teamUUID',
    simulationUUID: 'simulationUUID',
    name: 'Team Name',
    roleplayerUUIDs: [],
    documentUUIDs: ['uuid'],
    overview: undefined,
    pageStats: {},
};

export const NewTeam = t.type(omit(['uuid', 'documentUUIDs', 'overview', 'pageStats'], Team.props));
export type NewTeam = t.TypeOf<typeof NewTeam>;

export const DynamoDbTeamValue = t.type(omit(['uuid', 'simulationUUID'], Team.props));
export type DynamoDbTeamValue = t.TypeOf<typeof DynamoDbTeamValue>;

export const getTeamUUIDFromDynamoDbRow = pipe(
    (row: Record<string, string>) => row.sk,
    split('_'),
    last,
    replace('team[', ''),
    replace(']', ''),
);
