import React, { FC } from 'react';
import { PrimaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { H2Heading } from '../../bits/Headers';
import { Icon } from '../../bits/Icon';
import { Modal } from '../Modal';

interface NonInteractiveComponentHelpModalProps {
    onModalClose: () => void;
}

export const NonInteractiveComponentHelpModal: FC<NonInteractiveComponentHelpModalProps> = ({ onModalClose }) => {
    return (
        <Modal
            header={
                <Display.HorizontalWithSpacing>
                    <Icon icon="warning" size={30} />
                    <H2Heading>This part of the page is not clickable</H2Heading>
                </Display.HorizontalWithSpacing>
            }
            cardWidth={350}
            onModalClose={onModalClose}
        >
            <Display.VerticalWithSpacing>
                <div>Sorry, but this is just a simulation!</div>
                <div>Not everything is interactive like the real site.</div>
                <Display.HorizontalWithSpacing horizontalAlign="end">
                    <PrimaryButton onClick={onModalClose}>Ok</PrimaryButton>
                </Display.HorizontalWithSpacing>
            </Display.VerticalWithSpacing>
        </Modal>
    );
};
