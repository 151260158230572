import { isRight } from 'fp-ts/lib/Either';
import { Moment } from 'polpeo-go-common/types/Moment';

// Moments contain a date type which are passed to us as strings by graphql
// use 'decode' to actually turn them into Date objects!
export const decodeMoment = (trigger: unknown): Moment => {
    const maybeMoment = Moment.decode(trigger);
    if (isRight(maybeMoment)) {
        return maybeMoment.right;
    }
    throw new Error('Moment was not the right shape');
};
