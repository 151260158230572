import gql from 'graphql-tag';
import { ScratchPadDocument, NewScratchPadDocument } from '../../../../common/types/ScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface EditScratchPadDocumentParams {
    scratchPadDocument: NewScratchPadDocument;
}

interface EditScratchPadDocumentData {
    editScratchPadDocument: ScratchPadDocument;
}

const mutation = gql`
    mutation editScratchPadDocument($scratchPadDocument: ScratchPadDocumentInput!) {
        editScratchPadDocument(scratchPadDocument: $scratchPadDocument) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            currentEditor
        }
    }
`;

export const editScratchPadDocument = wrapMutation<EditScratchPadDocumentParams, EditScratchPadDocumentData>(mutation);
