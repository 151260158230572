import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { last, omit, pipe, replace, split } from 'ramda';
import { optionalToUndefined } from '../t';
import { ContentItem, TEST_CONTENT_ITEM, GraphQlContentItem } from './ContentItem';

export enum MomentRating {
    Down2 = 'Down2',
    Down1 = 'Down1',
    Up1 = 'Up1',
    Up2 = 'Up2',
}
export const tMomentRating = t.keyof(MomentRating);

export const Moment = t.type({
    uuid: t.string,
    simulationUUID: t.string,
    created: t.type({
        at: t.union([DateFromISOString, date]),
        by: t.string,
    }),
    contentItem: ContentItem,
    rating: optionalToUndefined(tMomentRating),
    comments: t.string,
});
export type Moment = t.TypeOf<typeof Moment>;
export const TEST_MOMENT: Moment = {
    uuid: 'momentUUID',
    simulationUUID: 'simulationUUID',
    created: {
        at: new Date(),
        by: 'staffUUID',
    },
    contentItem: TEST_CONTENT_ITEM,
    rating: 'Down1',
    comments: 'comments',
};
export const GraphQlMoment = t.type({
    ...Moment.props,
    created: t.type({
        at: t.string,
        by: t.string,
    }),
    contentItem: GraphQlContentItem,
});
export type GraphQlMoment = t.TypeOf<typeof GraphQlMoment>;

export const NewMoment = t.type(omit(['uuid', 'created'], Moment.props));
export type NewMoment = t.TypeOf<typeof NewMoment>;
export const GraphQlNewMoment = t.type({ ...NewMoment.props, contentItem: GraphQlContentItem });
export type GraphQlNewMoment = t.TypeOf<typeof GraphQlNewMoment>;

export const DynamoDbMomentValue = t.type(omit(['uuid'], Moment.props));
export type DynamoDbMomentValue = t.TypeOf<typeof DynamoDbMomentValue>;

export const makeSkForMoment = (item: Moment): string => {
    return `moment[${item.contentItem.teamUUID}_${item.uuid}]`;
};

export const getMomentUUIDFromSk = (sk: string): string => {
    const uuid = pipe(replace('moment[', ''), replace(']', ''), split('_'), (x) => last(x))(sk);
    if (!uuid) {
        throw new Error('Could not retrieve moment uuid from dynamnodb row sk');
    }

    return uuid;
};
