import { Simulation } from 'polpeo-go-common/types/Simulation';
import { cond } from 'ramda';
import React, { FC, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { Display } from '../../../bits/Display';
import { Body1 } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { useCountdown } from '../../../hooks/UseCountdown';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { StaffUserSimulationLobbyPage } from './Lobby';
import { StaffUserSimulationCompletedPage } from './InSimulation/SimulationCompleted';
import { StaffUserSimulationInProgressPage } from './InSimulation/SimulationInProgress';

const StartSimCountdownContainer = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StaffUserSimulationPage: FC = () => {
    const { simulation, showManageTeamsPage } = useContext(AdminInSimulationStateContext);
    const prevSimulation = useRef<Simulation>();
    const [countDown, startCountDown] = useCountdown({});

    useEffect(() => {
        if (
            prevSimulation.current &&
            simulation.startedAt &&
            simulation.startedAt !== prevSimulation.current?.startedAt
        ) {
            startCountDown({ toDate: simulation.startedAt, updateInterval: 1000 });
        }
        prevSimulation.current = simulation;
    }, [simulation]);

    return (
        <>
            {cond<Simulation, React.ReactNode>([
                [() => showManageTeamsPage, () => <StaffUserSimulationLobbyPage />],
                [(sim) => !!sim.completedAt, () => <StaffUserSimulationCompletedPage />],
                [
                    (sim) => !!sim.startedAt && new Date().toISOString() < sim.startedAt.toISOString(),
                    () => (
                        <StartSimCountdownContainer>
                            <Display.VerticalWithSpacing horizontalAlign="center">
                                <Body1>Simulation Starting in</Body1>
                                <Body1>
                                    <b>{Math.ceil(countDown / 1000)}</b>
                                </Body1>
                                <Body1>
                                    <Spinner />
                                </Body1>
                            </Display.VerticalWithSpacing>
                        </StartSimCountdownContainer>
                    ),
                ],
                [
                    (sim) => !!sim.startedAt && new Date().toISOString() > sim.startedAt.toISOString(),
                    () => <StaffUserSimulationInProgressPage />,
                ],
                [(sim) => !!sim.lobbyOpenedAt, () => <StaffUserSimulationLobbyPage />],
            ])(simulation)}
        </>
    );
};
