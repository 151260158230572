/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import logo from './logo.png';
import dummyChildImage from './dummyChildImage.png';
import dummyRootImage from './dummyRootImage.png';

const pageTemplate: PageTemplate = {
    uuid: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
    name: 'Chat',
    category: 'Messaging',
    icon: logo,
    rootItemOptions: {
        name: 'Chat',
        fields: [
            { name: 'message', type: ContentItemFieldType.LONG_TEXT },
            { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        requiredFields: [],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childItemOptions: {
        name: 'Reply',
        pluralName: 'Replies',
        fields: [
            { name: 'message', type: ContentItemFieldType.LONG_TEXT },
            { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        requiredFields: [],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 1 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.CHAT },
        childrenSettings: {},
        sortBy: 'DescendentCreatedDescending',
        rootContentFieldOrder: ['meta', 'video', 'message', 'childrenCount'],
        childContentFieldOrder: [],
        detailsLinkFields: ['all'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                leftSidebar: { margin: { right: 10 } },
                main: { width: 600 },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 600,
                verticalGap: 0,
                borders: {
                    top: {
                        width: 1,
                        color: '#dddddd',
                    },
                    right: {
                        width: 1,
                        color: '#dddddd',
                    },
                    left: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
            },
            rootItem: {
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                borders: {
                    bottom: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        margin: {
                            top: 10,
                            right: 0,
                            bottom: 10,
                            left: 0,
                        },
                    },
                    message: {
                        margin: {
                            top: 5,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        },
                    },
                },
                showChildItemsCount: {
                    position: 'use field order',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                },
            },

            childrenItemsContainer: {},
            childItemContainer: {},
            childItem: {
                margin: {
                    top: 10,
                    left: 20,
                    right: 20,
                    bottom: 10,
                },
                bgColour: '#ffffff',
                borders: { top: { width: 1, color: '#dddddd' } },
                borderRadius: 5,
                padding: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                    },
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.CHAT },
        childrenSettings: {
            location: 'append',
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['meta', 'video', 'image', 'message', 'childrenCount'],
        childContentFieldOrder: ['meta', 'video', 'image', 'message'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    layout: 'center',
                    sticky: true,
                },
                leftSidebar: { margin: { right: 10 } },
                main: {
                    width: 800,
                    bgColour: '#f6f6f6',
                    padding: { top: 20, left: 20, bottom: 20, right: 20 },
                    borders: {
                        right: {
                            width: 1,
                            color: '#e0e0e0',
                        },
                        left: {
                            width: 1,
                            color: '#e0e0e0',
                        },
                    },
                },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                verticalGap: 0,
                horizontalGap: 0,
            },
            rootItem: {
                margin: {
                    left: 10,
                    right: 10,
                    bottom: 20,
                },
                bgColour: '#ffffff',
                borders: { top: { width: 1, color: '#dddddd' } },
                borderRadius: 5,
                padding: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                        margin: { bottom: 15, top: 10 },
                    },
                },
            },
            childrenItemsContainer: {},
            childItemContainer: {},
            childItem: {
                margin: {
                    left: 10,
                    right: 10,
                    bottom: 20,
                },
                bgColour: '#ffffff',
                borders: { top: { width: 1, color: '#dddddd' } },
                borderRadius: 5,
                padding: {
                    top: 10,
                    left: 10,
                    right: 10,
                    bottom: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                    },
                },
            },
        },
    },
};
export default pageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'Hello!',
                image: dummyRootImage,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'Hi! How are you?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: "I'm doing well thankyou! The weather's been wonderful lately hasn't it?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidc',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: "Oh yes! I've enjoyed going out for walks with the dog and she's really been loving it too!",
                image: dummyChildImage,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidd',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'Are you still up for a coffee later this week?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuide',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 52 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message:
                    "Yes! I've been hankering after a good frappucinno for awhile! Wanna meet up Sunday afternoon?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidf',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 31 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message:
                    'Ahhh sorry I just realised I have plans on Sunday afternoon. Would Saturday morning be doable?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidg',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ seconds: 29 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'Yes, Saturday morning is good for me :D See you then!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'Hiyaaaaa! How have things been?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid3',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mike' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                message: 'We should get a coffee some time!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
