import gql from 'graphql-tag';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import wrapMutation from '../wrapMutation';

interface EditParticipantUserParams {
    simulationUUID: string;
    participant: ParticipantUser;
}

interface EditParticipantUserData {
    editParticipantUser: ParticipantUser;
}

const mutation = gql`
    mutation editParticipantUser($simulationUUID: String!, $participant: ParticipantUserInput!) {
        editParticipantUser(simulationUUID: $simulationUUID, participant: $participant) {
            id
            fullName
            email
            marketingConsent
            createdAt
        }
    }
`;

export const editParticipantUser = wrapMutation<EditParticipantUserParams, EditParticipantUserData>(mutation);
