import React, { FC } from 'react';
import { PrimaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Body2 } from '../../../bits/Headers';
import { Modal } from '../../../patterns/Modal';

interface DocumentUpdatedModalProps {
    onCloseModal: () => void;
}

export const DocumentUpdatedModal: FC<DocumentUpdatedModalProps> = ({ onCloseModal }) => {
    return (
        <Modal header="Document Contents Have Changed">
            <Display.VerticalWithSpacing>
                <p>Someone has updated this document while you have it checked out.</p>
                <p>The content has changed and the document has been updated with the new content</p>
                <Body2 destructive>Your changes have been overwritten!</Body2>
                <Display.RightAlign>
                    <PrimaryButton onClick={onCloseModal}>Ok</PrimaryButton>
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </Modal>
    );
};
