import gql from 'graphql-tag';
import { GraphQlMoment, GraphQlNewMoment } from 'polpeo-go-common/types/Moment';
import wrapMutation from '../wrapMutation';

interface CreateMomentParams {
    moment: GraphQlNewMoment;
}

interface CreateMomentData {
    createMoment: GraphQlMoment;
}

const mutation = gql`
    mutation createMoment($moment: NewMomentInput!) {
        createMoment(moment: $moment) {
            uuid
            simulationUUID
            created {
                at
                by
            }
            contentItem {
                type
                uuid
                simulationUUID
                teamUUID
                parentUUID
                createdAt
                persona
                content {
                    pageUUID
                    interactions {
                        positive
                        negative
                        shares
                    }
                    data
                }
                prepreparedContentUUID
                createdBy {
                    type
                    uuid
                }
                deleted {
                    at
                    by {
                        type
                        uuid
                    }
                }
            }
            rating
            comments
        }
    }
`;

export const createMoment = wrapMutation<CreateMomentParams, CreateMomentData>(mutation);
