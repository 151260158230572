import gql from 'graphql-tag';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import wrapQuery from '../wrapQuery';

interface GetStaffUserParams {
    staffUserUUID: string;
}

interface GetStaffUserData {
    getStaffUser: StaffUser;
}

const query = gql`
    query getStaffUser($staffUserUUID: String!) {
        getStaffUser(staffUserUUID: $staffUserUUID) {
            uuid
            name
            email
            role
            permissionSet
        }
    }
`;

export const getStaffUser = wrapQuery<GetStaffUserParams, GetStaffUserData>(query);
