import gql from 'graphql-tag';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import wrapSubscription from '../wrapSubscription';

interface OnParticipantJoinLobbyParams {
    simulationUUID: string;
}

interface OnParticipantJoinLobbyData {
    onParticipantJoinLobby: BasicSimulation;
}

const subscription = gql`
    subscription onParticipantJoinLobby($simulationUUID: String!) {
        onParticipantJoinLobby(uuid: $simulationUUID) {
            uuid
            name
            code
            startedAt
            completedAt
            showParticipantsMomentsGraph
        }
    }
`;

export const onParticipantJoinLobby =
    wrapSubscription<OnParticipantJoinLobbyParams, OnParticipantJoinLobbyData>(subscription);
