import React, { FC } from 'react';
import { Display } from '../bits/Display';
import { Spinner } from '../bits/Spinner';
import { SplashContainer, SplashHeroCard } from '../bits/SplashContainer';

export const FullPageSplashLoadingScreen: FC = () => (
    <SplashContainer>
        <SplashHeroCard>
            <Display.HorizontalCenterVerticalCenter>
                <Spinner />
            </Display.HorizontalCenterVerticalCenter>
        </SplashHeroCard>
    </SplashContainer>
);
