import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { userCanCreateMoments } from 'polpeo-go-common/permissions/userCanCreateMoments';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Team } from 'polpeo-go-common/types/Team';
import { descend, filter, includes, map, sort, values, without } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { primaryColour, whiteColour } from '../../../../../themes/colours';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { SecondaryButton } from '../../../../bits/Buttons';
import { CardSection } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { Body2 } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { Lozenge } from '../../../../bits/Lozenge';
import { Card } from '../../../../patterns/Card';
import { EmbeddedEmailItem } from '../../../../patterns/EmbeddedEmailItem';
import { MomentsGraph } from '../../../../patterns/MomentsGraph';
import { EmbeddedContentItem } from '../../../PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../../PageTemplate/templates';
import { TeamOverviewCard } from '../../../TeamOverviewCard';
import filterContentItemsForHiddenPages from '../../../../../utils/filterContentItemsForHiddenPages';

const TeamOverviewPageContainer = styled.div`
    flex: 1 1 auto;
    padding: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
`;

const OverviewColumns = styled(Display.HorizontalWithSpacing)`
    grid-template-columns: minmax(100px, 750px) max-content;
    align-items: start;
`;

const ActivityCardBody = styled(Display.HorizontalWithSpacing)`
    grid-template-columns: max-content 1fr;
`;

const PageTemplateIcon = styled.img`
    width: 20px;
    fill: #000000;
`;

interface TeamOverviewProps {
    team: Team;
}
export const TeamOverview: FC<TeamOverviewProps> = ({ team }) => {
    const {
        simulation,
        simulationContent,
        activityFilters,
        setActivityFilters,
        getLinkUrlForContentItem,
        getLinkUrlForEmailItem,
    } = useContext(AdminInSimulationStateContext);
    const staffUser = useContext(StaffUserContext);
    const canEdit = !simulation.completedAt && staffUser && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
    const history = useHistory();

    const moments = useMemo(() => {
        return values(simulationContent.moments[team.uuid] || {}) as Moment[];
    }, [simulationContent.moments, team]);

    const itemsToDisplay = useMemo(() => {
        const contentItemsForTeam = values(simulationContent.contentItems[team.uuid] || {}) as ContentItem[];
        const emailItemsForTeam = values(simulationContent.emailItems[team.uuid] || {}) as EmailItem[];

        const filteredContentItems = filterContentItemsForHiddenPages({
            contentItems: contentItemsForTeam,
            pages: values(simulationContent.pages),
            teamUUID: team.uuid,
        });

        const ordered = sort(
            descend((item) => item.createdAt.toISOString()),
            [...filteredContentItems, ...emailItemsForTeam],
        );
        const filtered = filter(
            (item) =>
                (includes('Participants', activityFilters) && item.createdBy.type === 'PARTICIPANT') ||
                (includes('Staff', activityFilters) && item.createdBy.type !== 'PARTICIPANT'),
            ordered,
        );
        return filtered;
    }, [simulationContent.contentItems, simulationContent.emailItems, team, activityFilters, simulationContent.pages]);

    return (
        <TeamOverviewPageContainer>
            <OverviewColumns>
                <Card header="Activity Feed">
                    <Display.VerticalWithSpacing>
                        <CardSection>
                            <Display.HorizontalWithSpacing gap={10}>
                                <Lozenge
                                    as="button"
                                    fill={includes('Participants', activityFilters) ? primaryColour : ''}
                                    color={includes('Participants', activityFilters) ? whiteColour : ''}
                                    onClick={() =>
                                        setActivityFilters(
                                            includes('Participants', activityFilters)
                                                ? without(['Participants'], activityFilters)
                                                : [...activityFilters, 'Participants'],
                                        )
                                    }
                                >
                                    Participants
                                </Lozenge>
                                <Lozenge
                                    as="button"
                                    fill={includes('Staff', activityFilters) ? primaryColour : ''}
                                    color={includes('Staff', activityFilters) ? whiteColour : ''}
                                    onClick={() =>
                                        setActivityFilters(
                                            includes('Staff', activityFilters)
                                                ? without(['Staff'], activityFilters)
                                                : [...activityFilters, 'Staff'],
                                        )
                                    }
                                >
                                    Staff
                                </Lozenge>
                            </Display.HorizontalWithSpacing>
                        </CardSection>
                        {map((item) => {
                            const page =
                                item.type === 'PAGE_CONTENT'
                                    ? (simulationContent.pages || {})[item.content.pageUUID]
                                    : undefined;
                            const template = pageTemplates[page?.templateUUID || ''];
                            return (
                                <Card key={item.uuid}>
                                    <ActivityCardBody verticalCenter>
                                        <Icon
                                            icon={
                                                item.createdBy.type === 'PARTICIPANT'
                                                    ? 'createdByParticipant'
                                                    : 'createdByPolpeo'
                                            }
                                            size={24}
                                        />
                                        <Display.VerticalWithSpacing gap={8}>
                                            <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                                                <Display.HorizontalWithSpacing gap={8}>
                                                    {template ? (
                                                        <PageTemplateIcon src={template.icon} />
                                                    ) : (
                                                        <Icon icon="email" size={20} />
                                                    )}
                                                    <Body2>{page ? page.name : 'Email'}</Body2>
                                                </Display.HorizontalWithSpacing>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        item.type === 'PAGE_CONTENT'
                                                            ? history.push(getLinkUrlForContentItem(item))
                                                            : history.push(getLinkUrlForEmailItem(item));
                                                    }}
                                                    small
                                                >
                                                    Go to item
                                                </SecondaryButton>
                                            </Display.HorizontalWithMaxSpaceBetween>
                                            {item.type === 'PAGE_CONTENT' && (
                                                <>
                                                    <EmbeddedContentItem
                                                        item={item}
                                                        small
                                                        contentItemOptions={{
                                                            useTimestampRelativeToSimulationStart:
                                                                simulation.completedAt ? simulation : undefined,
                                                        }}
                                                    />
                                                </>
                                            )}
                                            {item.type === 'EMAIL' && (
                                                <EmbeddedEmailItem
                                                    useTimestampRelativeToSimulationStart={
                                                        simulation.completedAt ? simulation : undefined
                                                    }
                                                    item={item}
                                                    small
                                                />
                                            )}
                                        </Display.VerticalWithSpacing>
                                    </ActivityCardBody>
                                </Card>
                            );
                        }, itemsToDisplay)}
                    </Display.VerticalWithSpacing>
                </Card>
                <Display.VerticalWithSpacing>
                    {userCanCreateMoments(simulation, staffUser) && (
                        <Card cardWidth={430}>
                            <MomentsGraph moments={moments} />
                        </Card>
                    )}
                    <TeamOverviewCard team={team} card={{ cardWidth: 430 }} canEdit={canEdit} />
                </Display.VerticalWithSpacing>
            </OverviewColumns>
        </TeamOverviewPageContainer>
    );
};
