import { NewPrepreparedEmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { T, cond, equals, map } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { updateTriggerPrepreparedContentLinks } from '../../../../../../graphql/triggers';
import { getTriggerForPrepreparedItem } from '../../../../../../utils/getTriggerForPrepreparedItem';
import { AdminManageSimulationContext } from '../../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { PrimaryButton, SecondaryButton } from '../../../../../bits/Buttons';
import { Display } from '../../../../../bits/Display';
import { H2Heading } from '../../../../../bits/Headers';
import { Spinner } from '../../../../../bits/Spinner';
// eslint-disable-next-line max-len
import { usePersonaMatchesExistingParticipantFullName } from '../../../../../hooks/usePersonaMatchesExistingParticipantFullName';
import { useTriggerOptionItems } from '../../../../../hooks/useTriggerOptionItems';
import { useUnsavedWorkPrompt } from '../../../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../../../patterns/Modal';
import { FormGrid } from '../../../../FormGrid';
import { SlimTabs } from '../../../../SlimTabs';
import { makeContentTabFormFields } from './makeContentTabFormFields';
import { makeInitialState } from './makeInitialState';
import { makeSetupTabFormFields } from './makeSetupTabFormFields';
import { useSavePrepreparedEmail } from './useSavePrepreparedEmail';

const FormGridSlimTabs = styled(SlimTabs)`
    width: 100%;
    margin: 16px -16px -16px -16px;
    border-bottom: none;
`;

const tabs = ['Setup', 'Content'];

interface PrepreparedEmailFormProps {
    simulation: Simulation;
    onCancel: () => void;
    onSaveComplete: () => void;
    item?: PrepreparedEmailItem;
    parent?: PrepreparedEmailItem;
    copyOf?: PrepreparedEmailItem;
}

export const PrepreparedEmailFormModal: FC<PrepreparedEmailFormProps> = ({
    simulation,
    onCancel,
    onSaveComplete,
    ...options
}) => {
    const {
        participants: allParticipants,
        setPrepreparedEmail,
        setTriggerPrepreparedContentLinks,
    } = useContext(AdminStateContext);
    const { currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { triggers, triggerPrepreparedContentLinks, triggerOrder } = currentSimulationContent;
    const participants = allParticipants[simulation.uuid];

    const initialPrepreparedEmail = makeInitialState(simulation, options);
    const [currentPrepreparedEmail, setCurrentPrepreparedEmail] =
        useState<NewPrepreparedEmailItem | PrepreparedEmailItem>(initialPrepreparedEmail);

    const maybeInitialLinkedToTrigger = getTriggerForPrepreparedItem(
        triggers,
        triggerPrepreparedContentLinks,
        options.item || options.copyOf || options.parent,
    );

    const initialLinkedToTriggerUUID = cond([
        [() => !!options.parent && !!maybeInitialLinkedToTrigger?.releasedAt, () => ''],
        [() => !!options.copyOf && !!maybeInitialLinkedToTrigger?.releasedAt, () => ''],
        [T, () => maybeInitialLinkedToTrigger?.uuid || ''],
    ])();

    const [currentLinkedToTrigger, setCurrentLinkedToTrigger] = useState<string>(initialLinkedToTriggerUUID);
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const [savePrepreparedEmailMutation, { data: savePrepreparedEmailData, loading: savePrepreparedEmailLoading }] =
        useSavePrepreparedEmail();

    const [
        updateTriggerPrepreparedContentLinksMutation,
        { data: updateTriggerPrepreparedContentLinksData, loading: updateTriggerPrepreparedContentLinksLoading },
    ] = updateTriggerPrepreparedContentLinks.hook();

    const triggerOptionItems = useTriggerOptionItems(triggers, triggerOrder);

    useEffect(() => {
        const updatedTriggerLinks = updateTriggerPrepreparedContentLinksData?.updateTriggerPrepreparedContentLinks;

        // Saved Item but not yet updated trigger links
        if (savePrepreparedEmailData && !updatedTriggerLinks && !updateTriggerPrepreparedContentLinksLoading) {
            updateTriggerPrepreparedContentLinksMutation({
                variables: {
                    simulationUUID: simulation.uuid,
                    newLinks: JSON.stringify({ [savePrepreparedEmailData.uuid]: currentLinkedToTrigger }),
                },
            });
        }

        // Saved item and updated trigger links
        if (savePrepreparedEmailData && updatedTriggerLinks) {
            setPrepreparedEmail(savePrepreparedEmailData);
            setTriggerPrepreparedContentLinks(simulation.uuid, JSON.parse(updatedTriggerLinks));
            onSaveComplete();
        }
    }, [
        savePrepreparedEmailData,
        updateTriggerPrepreparedContentLinksData,
        updateTriggerPrepreparedContentLinksLoading,
    ]);

    const personaMatchesExistingParticipantFullName = usePersonaMatchesExistingParticipantFullName(
        participants,
        currentPrepreparedEmail.persona,
    );

    const currentTabFormFields = cond([
        [
            () => currentTab === 'Setup',
            () =>
                makeSetupTabFormFields(
                    triggerOptionItems,
                    currentPrepreparedEmail,
                    setCurrentPrepreparedEmail,
                    currentLinkedToTrigger,
                    setCurrentLinkedToTrigger,
                    personaMatchesExistingParticipantFullName
                        ? { persona: "You cannot use a participant's name as a persona" }
                        : {},
                ),
        ],
        [
            () => currentTab === 'Content',
            () => makeContentTabFormFields(simulation, currentPrepreparedEmail, setCurrentPrepreparedEmail),
        ],
    ])();

    const canSave = currentPrepreparedEmail.persona && !personaMatchesExistingParticipantFullName;

    const hasUnsavedChanges =
        (!equals(currentPrepreparedEmail, initialPrepreparedEmail) ||
            currentLinkedToTrigger !== initialLinkedToTriggerUUID) &&
        !savePrepreparedEmailData;
    const { wrappedFunc: onCancelUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCancel,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onCancelUnsavedPrompt} cardWidth={800}>
                {(savePrepreparedEmailLoading || updateTriggerPrepreparedContentLinksLoading) && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!savePrepreparedEmailLoading && !updateTriggerPrepreparedContentLinksLoading && (
                    <>
                        <H2Heading>{options.item ? 'Edit' : 'Create'} Preprepared Email</H2Heading>
                        <FormGrid
                            header={
                                <FormGridSlimTabs
                                    tabs={map((tab) => [tab, undefined], tabs)}
                                    activeTab={currentTab}
                                    onChangeTab={setCurrentTab}
                                />
                            }
                            fields={currentTabFormFields}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end">
                                    {currentPrepreparedEmail.released &&
                                        // eslint-disable-next-line max-len
                                        `This item was released at: ${currentPrepreparedEmail.released.at.toISOString()}`}
                                    <SecondaryButton onClick={onCancelUnsavedPrompt}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        disabled={!canSave || !!currentPrepreparedEmail.released}
                                        onClick={() => {
                                            savePrepreparedEmailMutation(currentPrepreparedEmail);
                                        }}
                                    >
                                        Save
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    </>
                )}
            </Modal>
        </>
    );
};
