import { includes, join, map, pipe, replace } from 'ramda';

export const cleanXlsxHtml = (htmlString: string): string => {
    const basicCleanup = pipe(replace(/font-size:\d+.?\d+pt;/g, ''), replace(/ style=""/g, ''))(htmlString || '');
    const matchedSpans = basicCleanup.match(new RegExp(/<span.*?>.*?<\/span>/g)) || [basicCleanup];
    const cleanedSpans = map(
        (span) => (includes('<span>', span) ? replace(/<\/?span>/g, '', span) : span),
        matchedSpans,
    );
    return join('', cleanedSpans);
};
