import gql from 'graphql-tag';
import { Page } from 'polpeo-go-common/types/Page';
import wrapQuery from '../wrapQuery';

interface GetPagesParams {
    simulationUUID: string;
}

interface GetPagesData {
    getPages: (Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ })[];
    getPageOrder: string[];
}

const query = gql`
    query getPagesAndPageOrder($simulationUUID: String!) {
        getPages(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            templateUUID
            name
            stats
            dressing {
                header
                leftSidebar
                rightSidebar
                footer
            }
            createRootContentPermission
            showItemsAsIndividualPages
            participantDeleteContentPermission
            hiddenFromTeamUUIDs
        }
        getPageOrder(simulationUUID: $simulationUUID)
    }
`;

export const getPagesAndPageOrder = wrapQuery<GetPagesParams, GetPagesData>(query);
