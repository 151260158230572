import { difference, evolve, find, flatten, isNil, keys, map, pickAll, reject, values } from 'ramda';
import { UploadedTrigger } from '../trigger/parseTriggersSheet';

const pageValidationTransformations = {
    persona: (val: unknown) =>
        !val || typeof val !== 'string' || !val.length
            ? 'Preprepared email, persona: persona is required and should be a string'
            : undefined,
    content: (val: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !val || !(val as any).message || !(val as any).message?.length
            ? 'Preprepared email, message: message is required and should be a string'
            : undefined,
};

const getErrorsForPrepreparedEmailsSheet = (
    // Doing error checking so we can't guarantee the data is any specific shape so use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pagesData: Record<string, any>[],
    triggers: UploadedTrigger[],
): string[] => {
    const fieldsToValidate = keys(pageValidationTransformations);
    const basicErrorMap = map(
        (pageData) =>
            evolve(
                pageValidationTransformations,
                pickAll<Record<string, unknown>, Record<string, unknown[]>>(fieldsToValidate, pageData),
            ),
        pagesData,
    );

    // Check that parent exists
    const itemIds = map((pageData) => pageData.tempId, pagesData);
    const parentIds = map((pageData) => pageData.tempParentId, pagesData);
    const parentErrorMap = map(
        (missingParentId) =>
            // eslint-disable-next-line max-len
            `Preprepared Content: Found item expecting parent with ID "${missingParentId}" but item with this ID does not exist`,
        difference(parentIds, itemIds),
    );

    // Check that trigger exists
    const triggerNames = map((trigger) => trigger.title, triggers);
    const triggerErrorMap = map(
        (pageData) =>
            pageData.triggerTitle && !find((triggerName) => triggerName === pageData.triggerTitle, triggerNames)
                ? // eslint-disable-next-line max-len
                  `Preprepared Content: Found item attached to trigger "${pageData.triggerTitle}" but trigger could not be found`
                : undefined,
        pagesData,
    );
    return reject(isNil, flatten([...map(values, basicErrorMap), ...parentErrorMap, ...triggerErrorMap])) as string[];
};

export default getErrorsForPrepreparedEmailsSheet;
