import React, { FC, useContext, useMemo } from 'react';
import { Modal } from '../../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { filter, map, reverse, sortBy, values } from 'ramda';
import { Display } from '../../../bits/Display';
import { Body3, H2Heading, H3Heading } from '../../../bits/Headers';
import { DateTime } from 'luxon';
import { RichtextDisplay } from '../../../bits/RichtextDisplay';
import { Card } from '../../../patterns/Card';

interface ReleasdTriggersModalProps {
    onModalClose: () => void;
}

export const ReleasedTriggersModal: FC<ReleasdTriggersModalProps> = ({ onModalClose }) => {
    const { simulation, simulationContent } = useContext(AdminInSimulationStateContext);
    const { triggers } = simulationContent;

    const triggersToList = useMemo(() => {
        const releasedTriggers = filter((trigger) => !!trigger.releasedAt, values(triggers));
        const orderedTriggers = sortBy(
            (trigger) => (trigger.releasedAt ? trigger.releasedAt.toISOString() : '0'),
            releasedTriggers,
        );
        return reverse(orderedTriggers);
    }, [triggers]);

    return (
        <Modal header="All Released Triggers" onModalClose={onModalClose} maxCardWidth={1200}>
            {triggersToList.length === 0 && <p>No triggers have been released</p>}
            <Display.VerticalWithSpacing>
                {map((trigger) => {
                    const {
                        staffPopupSettings: { directions, guidelines, expectedActions },
                    } = trigger;
                    const releasedAt = trigger.releasedAt;

                    if (releasedAt) {
                        const formattedTime =
                            !simulation.completedAt && DateTime.fromJSDate(releasedAt).toFormat('HH:mm');
                        return (
                            <Card>
                                <Display.VerticalWithSpacing gap={10}>
                                    <H2Heading>{trigger.title}</H2Heading>
                                    <Display.HorizontalWithSpacing verticalCenter>
                                        <Body3>RELEASED AT {formattedTime}</Body3>
                                    </Display.HorizontalWithSpacing>
                                    <div>
                                        <H3Heading>Alert that goes to participants</H3Heading>
                                        <RichtextDisplay value={directions || 'None'} />
                                    </div>
                                    <div>
                                        <H3Heading>Guidance for role players</H3Heading>
                                        <RichtextDisplay value={guidelines || 'None'} />
                                    </div>
                                    <div>
                                        <H3Heading>Expected action by participants</H3Heading>
                                        <RichtextDisplay value={expectedActions || 'None'} />
                                    </div>
                                </Display.VerticalWithSpacing>
                            </Card>
                        );
                    }
                }, triggersToList)}
            </Display.VerticalWithSpacing>
        </Modal>
    );
};
