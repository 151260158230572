import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { omit } from 'ramda';
import { optionalToUndefined } from '../t';
import { PageDressings } from './Page';
import { TeamOverviewData } from './Team';

export const Trigger = t.type({
    uuid: t.string,
    simulationUUID: t.string,
    title: t.string,
    isOptional: t.boolean,
    simulatedTime: optionalToUndefined(t.string),
    participantPopupSettings: t.union([
        t.intersection([
            t.type({
                enabled: t.literal(false),
            }),
            t.partial({
                text: optionalToUndefined(t.string),
                prepreparedContentItemUUID: optionalToUndefined(t.array(t.string)),
            }),
        ]),
        t.type({
            enabled: t.literal(true),
            text: optionalToUndefined(t.string),
            prepreparedContentItemUUID: optionalToUndefined(t.array(t.string)),
        }),
    ]),
    staffPopupSettings: t.partial({
        directions: optionalToUndefined(t.string),
        guidelines: optionalToUndefined(t.string),
        expectedActions: optionalToUndefined(t.string),
    }),
    overviewChanges: TeamOverviewData,
    pageDressingChanges: t.record(t.string, PageDressings), // Record<pageUUID, PageDressings>
    releasedAt: optionalToUndefined(t.union([DateFromISOString, date])),
});
export type Trigger = t.TypeOf<typeof Trigger>;
export const TEST_TRIGGER: Trigger = {
    uuid: 'triggerUUID',
    simulationUUID: 'simulationUUID',
    title: 'Test Simulation',
    isOptional: false,
    simulatedTime: 'simulatedTime',
    participantPopupSettings: {
        enabled: false,
        text: undefined,
        prepreparedContentItemUUID: undefined,
    },
    staffPopupSettings: {},
    overviewChanges: {},
    pageDressingChanges: {},
    releasedAt: undefined,
};

export const ReleasedTrigger = t.type({ ...Trigger.props, releasedAt: t.union([DateFromISOString, date]) });
export type ReleasedTrigger = t.TypeOf<typeof ReleasedTrigger>;

export const NewTrigger = t.type(omit(['uuid'], Trigger.props));
export type NewTrigger = t.TypeOf<typeof NewTrigger>;
export const TEST_NEW_TRIGGER: NewTrigger = {
    simulationUUID: 'simulationUUID',
    title: 'Test Simulation',
    isOptional: false,
    simulatedTime: 'simulatedTime',
    participantPopupSettings: {
        enabled: false,
        text: undefined,
        prepreparedContentItemUUID: undefined,
    },
    staffPopupSettings: {},
    overviewChanges: {},
    pageDressingChanges: {},
    releasedAt: undefined,
};

export const DynamoDbTriggerValue = t.type(omit(['uuid', 'simulationUUID'], Trigger.props));
export type DynamoDbTriggerValue = t.TypeOf<typeof DynamoDbTriggerValue>;
