import { Trigger } from 'polpeo-go-common/types/Trigger';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteTrigger } from '../../../../../graphql/triggers/deleteTrigger';
import { destructiveColour } from '../../../../../themes/colours';
import WithClassname from '../../../../../utils/WithClassname';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Body2 } from '../../../../bits/Headers';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { DeleteModal } from '../../../DeleteModal';

interface DeleteTriggerButtonProps extends WithClassname {
    trigger: Trigger;
}
export const DeleteTriggerButton: FC<DeleteTriggerButtonProps> = ({ trigger }: DeleteTriggerButtonProps) => {
    const { removeTrigger } = useContext(AdminStateContext);
    const [showModal, setShowModal] = useState(false);
    const [deleteTriggerMutation, { data: deleteTriggerData, loading: deleteTriggerLoading }] = deleteTrigger.hook();

    useEffect(() => {
        if (deleteTriggerData?.deleteTrigger && deleteTriggerData.deleteTrigger === trigger.uuid) {
            removeTrigger(trigger);
            setShowModal(false);
        }
    }, [deleteTriggerData]);

    return (
        <>
            <IconButton
                icon="delete"
                width={20}
                height={20}
                fill={destructiveColour}
                onClick={() => setShowModal(true)}
            />
            {showModal && (
                <DeleteModal
                    header="Delete Trigger"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={
                        <>
                            <p>Are you sure you want to delete the trigger {`'${trigger.title}'`}? </p>
                            <p>This will unassign all the preprepared content linked to the trigger.</p>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() =>
                        deleteTriggerMutation({
                            variables: {
                                trigger: {
                                    ...trigger,
                                    pageDressingChanges: JSON.stringify(trigger.pageDressingChanges),
                                },
                            },
                        })
                    }
                    showSpinner={deleteTriggerLoading}
                />
            )}
        </>
    );
};
