import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { NewMoment } from 'polpeo-go-common/types/Moment';
import { equals, mergeDeepRight } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { createMoment } from '../../../graphql/moment';
import { decodeMoment } from '../../../utils/decodeMoment';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { Modal } from '../Modal';
import { MomentForm } from './MomentForm';

interface NewMomentModalProps {
    item: ContentItem;
    onCloseModal: () => void;
}
export const NewMomentModal: FC<NewMomentModalProps> = ({ item, onCloseModal }) => {
    const { addMoments } = useContext(AdminInSimulationStateContext);
    const initialMoment = {
        simulationUUID: item.simulationUUID,
        contentItem: item,
        rating: undefined,
        comments: '',
    } as const;
    const [currentMoment, setCurrentMoment] = useState<NewMoment>(initialMoment);

    const [createMomentMutation, { data: createMomentData, loading: createMomentsLoading }] = createMoment.hook();
    useEffect(() => {
        if (createMomentData?.createMoment) {
            const newMoment = createMomentData?.createMoment;
            addMoments([
                decodeMoment({
                    ...newMoment,
                    contentItem: {
                        ...newMoment.contentItem,
                        content: {
                            ...newMoment.contentItem.content,
                            data: JSON.parse(newMoment.contentItem.content.data),
                        },
                    },
                }),
            ]);
            onCloseModal();
        }
    }, [createMomentData]);

    const canSave = currentMoment.rating || currentMoment.comments;

    const hasUnsavedChanges = !equals(currentMoment, initialMoment);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={800} header="Create Highlight">
                {createMomentsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!createMomentsLoading && (
                    <MomentForm
                        currentMoment={currentMoment}
                        item={item}
                        onChange={(moment) => setCurrentMoment(mergeDeepRight(currentMoment, moment))}
                        footer={
                            <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    disabled={!canSave}
                                    onClick={() => {
                                        createMomentMutation({
                                            variables: {
                                                moment: {
                                                    ...currentMoment,
                                                    contentItem: {
                                                        ...currentMoment.contentItem,
                                                        createdAt: currentMoment.contentItem.createdAt.toISOString(),
                                                        content: {
                                                            ...currentMoment.contentItem.content,
                                                            data: JSON.stringify(
                                                                currentMoment.contentItem.content.data,
                                                            ),
                                                        },
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                >
                                    Create
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        }
                    />
                )}
            </Modal>
        </>
    );
};
