import { ApolloProvider } from '@apollo/client';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { FullPageSplashLoadingScreen } from '../components/patterns/FullPageSplashLoadingScreen';
import AuthContext from '../components/WithAuth/AuthContext';
import { ConfigContext } from '../components/WithConfig/config';
import WithChildren from '../utils/WithChildren';
import { Client, createClientFromCognitoUser, createClientFromCredentials } from './client';

export const WithApollo: FC<WithChildren> = ({ children }: WithChildren) => {
    const config = useContext(ConfigContext);
    const { currentCredentials, currentAuthenticatedUser } = useContext(AuthContext);
    const [client, setClient] = useState<Client>();
    const previousCurrentAuthenticatedUser = useRef(currentAuthenticatedUser);

    useEffect(() => {
        const staffSignedOut = previousCurrentAuthenticatedUser.current && !currentAuthenticatedUser;
        // Staff doesn't count as "signed in" if they need to reset their password
        const staffSignedIn =
            !previousCurrentAuthenticatedUser.current &&
            currentAuthenticatedUser &&
            currentAuthenticatedUser?.challengeName !== 'NEW_PASSWORD_REQUIRED';
        const staffSignedAfterResetPassword =
            previousCurrentAuthenticatedUser.current &&
            currentAuthenticatedUser &&
            currentAuthenticatedUser?.challengeName !== 'NEW_PASSWORD_REQUIRED';

        previousCurrentAuthenticatedUser.current = currentAuthenticatedUser;

        if (!client || staffSignedOut || staffSignedIn || staffSignedAfterResetPassword) {
            if (client) {
                // If the currentAuthenticatedUser changes (eg because of login/logout) then we want to
                // force the client to be recreated and force all children to use the new client
                // so set the client as undefined whilst we recreate the client
                // but we don't want to do this if a staff user logged in who needs to set a password
                setClient(undefined);
            }

            const getAndAssignClient = async () => {
                // We don't want to use the cognito user if they still need to set a new password!
                const useCognitoUser =
                    currentAuthenticatedUser && currentAuthenticatedUser.challengeName !== 'NEW_PASSWORD_REQUIRED';
                const newClient = useCognitoUser
                    ? await createClientFromCognitoUser(config.graphQlEndpoint)
                    : await createClientFromCredentials(config.graphQlEndpoint);
                setClient(newClient);
            };

            getAndAssignClient();
        }
    }, [currentCredentials, currentAuthenticatedUser]);

    if (!client) {
        return <FullPageSplashLoadingScreen />;
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
