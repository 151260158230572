import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface PutPageOrderParams {
    simulationUUID: string;
    pageOrder: string[];
}

interface PutPageOrderData {
    putPageOrder: string[];
}

const mutation = gql`
    mutation putPageOrder($simulationUUID: String!, $pageOrder: [String!]!) {
        putPageOrder(simulationUUID: $simulationUUID, pageOrder: $pageOrder)
    }
`;

export const putPageOrder = wrapMutation<PutPageOrderParams, PutPageOrderData>(mutation);
