import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import {
    always,
    cond,
    difference,
    evolve,
    find,
    flatten,
    isNil,
    join,
    keys,
    map,
    pickAll,
    reject,
    T,
    values,
} from 'ramda';
import { UploadedTrigger } from '../trigger/parseTriggersSheet';
import { checkRequiredFields } from '../../../../../../utils/checkRequiredFields';

const pageValidationTransformations = {
    persona: (val: unknown) =>
        !val || typeof val !== 'string' || !val.length
            ? 'Preprepared Content, persona: persona is required and should be a string'
            : undefined,
};

const getErrorsForPrepreparedContentSheet = (
    pageName: string,
    // Doing error checking so we can't guarantee the data is any specific shape so use any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pagesData: Record<string, any>[],
    template: PageTemplate,
    triggers: UploadedTrigger[],
): string[] => {
    const fieldsToValidate = keys(pageValidationTransformations);
    const basicErrorMap = map(
        (pageData) =>
            evolve(
                pageValidationTransformations,
                pickAll<Record<string, unknown>, Record<string, unknown[]>>(fieldsToValidate, pageData),
            ),
        pagesData,
    );

    // Check that fields match page template definition
    const fieldErrorMap = map((pageData) => {
        const isRootItem = !pageData.tempParentId;
        const fields = isRootItem ? template.rootItemOptions.fields : template.childItemOptions.fields;
        const requiredFields = isRootItem
            ? template.rootItemOptions.requiredFields
            : template.childItemOptions.requiredFields;
        const currentItemFields = pageData.content?.data || {};

        // Check that all required fields exist
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const missingFieldNames = checkRequiredFields(requiredFields, pageData as any).missingFieldNames;
        const missingFieldNamesToDisplay = missingFieldNames.map((fieldName) => {
            if (Array.isArray(fieldName)) return `Only one of ${fieldName.join(' or ')} is required`;
            else return fieldName;
        });
        if (!!missingFieldNamesToDisplay.length) {
            return `Preprepared Content: Found item for page "${
                pageData.content?.pageTitle
            }" with missing fields "${join(', ', missingFieldNamesToDisplay)}"`;
        }
        // Some pages have no required fields. Need to check if there is some data in there
        const isContentDataEmpty = values(pageData.content.data).length < 1;
        if (isContentDataEmpty) {
            return `Preprepared Content: Found item for page "${pageData.content.pageTitle}"
            with no populated fields.
            `;
        }

        // Check that all fields are the right shape
        return map((fieldDef) => {
            const fieldData = currentItemFields[fieldDef.name];
            if (fieldData === undefined) {
                return undefined;
            }

            const maxLength =
                fieldDef.options && 'maxLength' in fieldDef.options ? fieldDef.options.maxLength : undefined;

            const getErrorMessage = cond([
                [
                    () => !!maxLength && fieldData.length > maxLength,
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was to long for field (Max Length : ${maxLength})`,
                ],
                [
                    () => fieldDef.type === 'TEXT' && typeof fieldData !== 'string',
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was not plain text`,
                ],
                [
                    () => fieldDef.type === 'LONG_TEXT' && typeof fieldData !== 'string',
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was not plain text`,
                ],
                [
                    () => fieldDef.type === 'RICHTEXT' && typeof fieldData !== 'string',
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was not plain text`,
                ],
                [
                    () => fieldDef.type === 'IMAGE' && typeof fieldData !== 'string',
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was not plain text`,
                ],
                [
                    () => fieldDef.type === 'VIDEO' && typeof fieldData !== 'string',
                    () =>
                        // eslint-disable-next-line max-len
                        `Preprepared Content: Found item for page "${pageData.content?.pageTitle}" with field "${fieldDef.name}" where value was not plain text`,
                ],
                [T, always(undefined)],
            ]);
            return getErrorMessage();
        }, fields);
    }, pagesData);

    // Check that parent exists
    const itemIds = map((pageData) => pageData.tempId, pagesData);
    const parentIds = reject(
        (id) => !id,
        map((pageData) => pageData.tempParentId, pagesData) as Array<string | undefined>,
    );
    const parentErrorMap = map(
        (missingParentId) =>
            // eslint-disable-next-line max-len
            `Preprepared Content: Found item expecting parent with ID "${missingParentId}" but item with this ID does not exist`,
        difference(parentIds, itemIds),
    );

    // Check that trigger exists
    const triggerNames = map((trigger) => trigger.title, triggers);
    const triggerErrorMap = map(
        (pageData) =>
            pageData.triggerTitle && !find((triggerName) => triggerName === pageData.triggerTitle, triggerNames)
                ? // eslint-disable-next-line max-len
                  `Preprepared Content: Found item attached to trigger "${pageData.triggerTitle}" but trigger could not be found`
                : undefined,
        pagesData,
    );
    return map(
        (error) => `${pageName} - ${error}`,
        reject(
            isNil,
            flatten([...map(values, basicErrorMap), ...fieldErrorMap, ...parentErrorMap, ...triggerErrorMap]),
        ) as string[],
    );
};

export default getErrorsForPrepreparedContentSheet;
