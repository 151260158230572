import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { map, sortBy, tail } from 'ramda';

type EmailUUID = string;
type ThreadIndex = number;

const MAX_RETRIES = 3;

export const sortEmailItemsIntoThreads = (
    emails: EmailItem[],
): { threads: Array<EmailItem[]>; emailUUIDToThreadMap: Record<EmailUUID, ThreadIndex> } => {
    let emailToThreadMap: Record<EmailUUID, ThreadIndex> = {};
    let queue: EmailItem[] = [...emails];
    // Keep a record of how many times we fail to place an email so we can stop if we have
    // retried too many times.
    let retries: Record<EmailUUID, number> = {};

    // We mutate this in place :(
    const threads: Array<EmailItem[]> = [];

    const processStartOfEmailThread = (email: EmailItem) => {
        const newIndex = threads.length;
        threads[newIndex] = [email];
        emailToThreadMap = { ...emailToThreadMap, [email.uuid]: newIndex };
        queue = tail(queue);
    };

    while (queue.length) {
        const email = queue[0];
        // Has no parent (is first email of thread)
        if (!email.parentUUID) {
            processStartOfEmailThread(email);
            continue;
        }

        const parentsExistingThread = emailToThreadMap[email.parentUUID];
        if (parentsExistingThread === undefined) {
            const currentRetries = retries[email.uuid] || 0;
            // We haven't done the max number of retries yet so put it at the end of the queue
            if (currentRetries < MAX_RETRIES) {
                queue = [...tail(queue), email];
                retries = { ...retries, [email.uuid]: currentRetries + 1 };
                continue;
            }
            // We have done the max number of retries so lets treat this like the first email of a thread
            // as this is possible if a user is added to a thread partway through!
            processStartOfEmailThread(email);
            continue;
        }

        // Parent email assigned to thread so assign current email to the same thread
        threads[parentsExistingThread] = [...threads[parentsExistingThread], email];
        emailToThreadMap = { ...emailToThreadMap, [email.uuid]: parentsExistingThread };
        queue = tail(queue);
    }

    const threadsWithSortedEmails = map(
        (thread) => sortBy((email) => email.createdAt.toISOString(), thread),
        threads,
    ) as Array<EmailItem[]>;
    return { threads: threadsWithSortedEmails, emailUUIDToThreadMap: emailToThreadMap };
};
