import gql from 'graphql-tag';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapMutation from '../wrapMutation';

interface DeleteTriggerParams {
    trigger: Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

interface DeleteTriggerData {
    deleteTrigger: string;
}

const mutation = gql`
    mutation deleteTrigger($trigger: TriggerInput!) {
        deleteTrigger(trigger: $trigger)
    }
`;

export const deleteTrigger = wrapMutation<DeleteTriggerParams, DeleteTriggerData>(mutation);
