import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { ContentItemsDetailsPageLayoutType, PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { map } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { getChildrenForItem } from '../../../utils/getChildrenForItem';
import { getDescendentsForItem } from '../../../utils/getDescendentsForItem';
import { sortContentItems } from '../../../utils/sortContentItems';
import { NewChildContentItemButton } from '../../patterns/ContentItem';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { getChatPosition, LeftChatBubbleCssSnippet, RightChatBubbleCssSnippet } from './ChatBubbleUtils';
import { ChildrenItemsList, ContentItemsList, PageContainer } from './components';
import { ContentItemComponent, ContentItemOptions } from './ContentItem';
import { pageTemplates } from './templates';
import { BaseDiv } from './utils';
import { cssChildContentIndentation } from './ContentListPage';

interface ContentItemChildrenProps {
    page?: Page;
    template: PageTemplate;
    rootItem: ContentItem;
    parent: ContentItem;
    items: ContentItem[];
    wrapInContainer?: boolean;
    depth: number;
    contentItemOptions?: ContentItemOptions;
    disableCreateContentItems?: boolean;
}
const ContentItemChildren: FC<ContentItemChildrenProps> = (props) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    // Destructure this here as ContentItemChildren is a recursive function and we
    // want to pass the same props down to the next call
    const { page, template, parent, items, wrapInContainer, depth, contentItemOptions, disableCreateContentItems } =
        props;
    const theme = template.detailsPage.theme;
    const useChatLayout = template.detailsPage.layout.type === ContentItemsDetailsPageLayoutType.CHAT;
    const maxDepth = template.childrenSettings.enabled ? template.childrenSettings.maxDepth : 0;
    const renderNestedChildren = maxDepth ? depth < maxDepth : true;

    const children = useMemo(() => {
        const itemsToDisplay =
            depth === maxDepth ? getDescendentsForItem(items, parent) : getChildrenForItem(items, parent);
        return sortContentItems(template.detailsPage.sortBy, itemsToDisplay, items);
    }, [parent, items, depth, maxDepth]);
    if (!children.length) {
        return wrapInContainer ? (
            <ChildrenItemsList theme={theme.childrenItemsContainer}>
                {page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}
            </ChildrenItemsList>
        ) : (
            <>{page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}</>
        );
    }

    const childrenElements: JSX.Element[] = map((child) => {
        const chatBubblePosition = getChatPosition(child, { items, staffUser, participantUser });
        const chatBubbleCss = chatBubblePosition === 'RIGHT' ? RightChatBubbleCssSnippet : LeftChatBubbleCssSnippet;
        return (
            <BaseDiv
                key={child.uuid}
                theme={theme.childItemContainer}
                extraCSS={useChatLayout ? { display: 'block' } : cssChildContentIndentation}
            >
                <ContentItemComponent
                    item={child}
                    template={template}
                    theme={theme.childItem}
                    fields={template.childItemOptions.fields}
                    fieldOrder={template.detailsPage.childContentFieldOrder}
                    options={contentItemOptions}
                    extraCSS={useChatLayout ? chatBubbleCss : undefined}
                >
                    {renderNestedChildren && template.detailsPage.childrenSettings.location === 'inline' && (
                        <ContentItemChildren {...props} parent={child} wrapInContainer={false} depth={depth + 1} />
                    )}
                </ContentItemComponent>
                {renderNestedChildren && template.detailsPage.childrenSettings.location === 'append' && (
                    <ContentItemChildren {...props} parent={child} wrapInContainer={false} depth={depth + 1} />
                )}
            </BaseDiv>
        );
    }, children);
    return wrapInContainer ? (
        <ChildrenItemsList theme={theme.childrenItemsContainer}>
            {page && !disableCreateContentItems && !useChatLayout && (
                <NewChildContentItemButton page={page} parent={parent} />
            )}
            {childrenElements}
            {page && !disableCreateContentItems && useChatLayout && (
                <NewChildContentItemButton page={page} parent={parent} />
            )}
        </ChildrenItemsList>
    ) : (
        <>
            {page && !disableCreateContentItems && !useChatLayout && (
                <NewChildContentItemButton page={page} parent={parent} />
            )}
            {childrenElements}
            {page && !disableCreateContentItems && useChatLayout && (
                <NewChildContentItemButton page={page} parent={parent} />
            )}
        </>
    );
};

type ContentDetailsPageProps = {
    items: ContentItem[];
    item: ContentItem;
    trigger?: Trigger;
    contentItemOptions?: ContentItemOptions;
    disableCreateContentItems?: boolean;
} & (
    | {
          page: Page;
      }
    | {
          template: PageTemplate;
      }
);
export const ContentDetailsPage: FC<ContentDetailsPageProps> = ({
    items,
    item,
    trigger,
    contentItemOptions,
    disableCreateContentItems,
    ...props
}) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);

    const template = 'template' in props ? props.template : pageTemplates[props.page.templateUUID];
    const page = 'page' in props ? props.page : undefined;

    const theme = template.detailsPage.theme;
    const useChatLayout = template.detailsPage.layout.type === ContentItemsDetailsPageLayoutType.CHAT;

    const chatBubblePosition = getChatPosition(item, { items, staffUser, participantUser });
    const chatBubbleCss = chatBubblePosition === 'RIGHT' ? RightChatBubbleCssSnippet : LeftChatBubbleCssSnippet;

    return (
        <PageContainer key={template.uuid} pageTheme={theme.page} page={page} trigger={trigger}>
            <ContentItemsList
                theme={template.detailsPage.theme.rootItemsContainer}
                extraCSS={useChatLayout ? { display: 'block' } : {}}
            >
                <ContentItemComponent
                    item={item}
                    template={template}
                    theme={theme.rootItem}
                    fields={template.rootItemOptions.fields}
                    fieldOrder={template.detailsPage.rootContentFieldOrder}
                    options={contentItemOptions}
                    extraCSS={useChatLayout ? chatBubbleCss : undefined}
                >
                    {template.detailsPage.childrenSettings.location === 'inline' && (
                        <ContentItemChildren
                            page={page}
                            template={template}
                            rootItem={item}
                            parent={item}
                            items={items}
                            wrapInContainer
                            depth={1}
                            contentItemOptions={contentItemOptions}
                            disableCreateContentItems={disableCreateContentItems}
                        />
                    )}
                </ContentItemComponent>
            </ContentItemsList>
            {template.detailsPage.childrenSettings.location === 'append' && (
                <ContentItemChildren
                    page={page}
                    template={template}
                    rootItem={item}
                    parent={item}
                    items={items}
                    wrapInContainer
                    depth={1}
                    contentItemOptions={contentItemOptions}
                    disableCreateContentItems={disableCreateContentItems}
                />
            )}
        </PageContainer>
    );
};
