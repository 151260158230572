import gql from 'graphql-tag';
import { NewParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import wrapMutation from '../wrapMutation';

interface JoinLobbyParams {
    simulationCode: string;
    participant: NewParticipantUser;
}

interface JoinLobbyData {
    joinLobby: boolean;
}

const mutation = gql`
    mutation joinLobby($simulationCode: String!, $participant: NewParticipantUserInput!) {
        joinLobby(simulationCode: $simulationCode, participant: $participant) {
            uuid
            name
            code
            startedAt
            completedAt
            clientName
            showParticipantsMomentsGraph
        }
    }
`;

export const joinLobby = wrapMutation<JoinLobbyParams, JoinLobbyData>(mutation);
