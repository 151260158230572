import { DateTime } from 'luxon';
import { Creator, personaToCreator } from 'polpeo-go-common/types/Creator';
import {
    EmailItem,
    participantsAllTeamIdentifierToDisplay,
    staffAllTeamIdentifierToDisplay,
} from 'polpeo-go-common/types/EmailItem';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { descend, join, map, sort } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey4Colour, grey6Colour } from '../../../themes/colours';
import { dateTimestampRelativeToSimStart } from '../../../utils/dateTimestampRelativeToSimStart';
import { getEmailItemTeamName } from '../../../utils/getEmailItemTeamName';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { StaffUserContext } from '../../WithStaffUser';
import { Display } from '../../bits/Display';
import { Body1, Body3, H2Heading } from '../../bits/Headers';
import { Icon } from '../../bits/Icon';
import { RichtextDisplay } from '../../bits/RichtextDisplay';
import { Identicon } from '../../patterns/Identicon';
import { ForwardItemButton } from './ForwardEmailItemButton';
import { ReplyToItemButton } from './ReplyToEmailItemButton';
import { ManageEmailButton } from './ManageEmailButton';
import { BaseDiv } from '../PageTemplate/utils';
import { deletedPlaceholderTheme } from '../PageTemplate/utils/deletedPlaceholderTheme';

const EmailDetailsContainer = styled.div`
    padding: 16px;
    flex: 1 1 auto;
    display: grid;
    grid-template-rows: max-content max-content 1fr auto-fit;
    grid-template-columns: max-content 1fr;
    grid-template-areas:
        'userAvatar emailMeta'
        '. emailBody'
        '. emailActions';
    column-gap: 10px;
    row-gap: 15px;

    border-top: 1px solid ${grey1Colour};

    & > * {
        min-width: 0;
    }
`;

const EmailSubject = styled(H2Heading)`
    grid-area: emailSubject;
    padding-right: 16px;
`;
const SenderAvatar = styled.div`
    grid-area: userAvatar;
`;
const EmailMeta = styled(Display.HorizontalWithMaxSpaceBetween)`
    grid-area: emailMeta;
`;
const EmailMetaFrom = styled(Body1)`
    align-items: center;
    display: flex;
    gap: 5px;

    color: ${grey6Colour};
    font-weight: 500;
    text-align: left;
`;
const EmailMetaTo = styled(Body1)`
    color: ${grey4Colour};
    text-align: left;
`;
const EmailBody = styled.div`
    grid-area: emailBody;
`;
const EllipsisButton = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e.target === e.currentTarget && (!type || type === 'button')) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    display: flex;
    grid-area: emailBody;
`;

const EmailActions = styled(Display.HorizontalWithSpacing)`
    grid-area: emailActions;
`;

const EmailTimestamp = styled(Body3)`
    margin: 4px 0 0 16px;
    white-space: nowrap;
`;

export interface EmailItemOptions {
    hideCreatedByIcon?: boolean;
    useTimestampRelativeToSimulationStart?: Simulation;
}

interface EmailDetailsProps {
    email: EmailItem;
    participants: Record<string, ParticipantUser>;
    onToggleExpand?: (email: EmailItem) => void;
    emailItemOptions?: EmailItemOptions & {
        expanded?: boolean;
    };
    disableCreateEmailItems?: boolean;
}
export const EmailDetails: FC<EmailDetailsProps> = ({
    email,
    participants,
    onToggleExpand,
    emailItemOptions: { expanded, hideCreatedByIcon, useTimestampRelativeToSimulationStart } = {},
    disableCreateEmailItems,
}) => {
    const { simulation } = useContext(AdminInSimulationStateContext);
    const { teams } = useContext(AdminStateContext);
    const staffUser = useContext(StaffUserContext);
    let creator: Creator | undefined = undefined;
    if (email.createdBy.type === 'PARTICIPANT') {
        creator = { ...participants[email.createdBy.uuid], type: 'PARTICIPANT' };
    } else {
        creator = personaToCreator(email);
    }

    const emailRecipients = map(
        (recipient) =>
            participants[recipient]?.fullName ||
            (staffUser
                ? staffAllTeamIdentifierToDisplay(
                      recipient,
                      getEmailItemTeamName(teams[simulation.uuid], email) || 'All Teams',
                  )
                : participantsAllTeamIdentifierToDisplay(recipient)) ||
            recipient,
        email.content.recipients,
    );

    const createdAtTimestamp = useMemo(() => {
        const createdAt = DateTime.fromJSDate(email.createdAt);
        if (!useTimestampRelativeToSimulationStart || !useTimestampRelativeToSimulationStart.startedAt) {
            return createdAt.toRelative();
        }
        return dateTimestampRelativeToSimStart(useTimestampRelativeToSimulationStart, email.createdAt);
    }, [email, useTimestampRelativeToSimulationStart]);

    const isDeleted = 'deleted' in email && email.deleted;

    return (
        <EmailDetailsContainer id={email.uuid}>
            <SenderAvatar as="button" onClick={onToggleExpand ? () => onToggleExpand(email) : undefined}>
                <Identicon creator={creator} />
            </SenderAvatar>
            <EmailMeta as="button" onClick={onToggleExpand ? () => onToggleExpand(email) : undefined}>
                <Display.VerticalWithSpacing gap={0} horizontalAlign="start">
                    <EmailMetaFrom>
                        <span>
                            <b>{creator.fullName}</b>
                        </span>
                        {!hideCreatedByIcon && (
                            <Icon
                                icon={
                                    email.createdBy.type === 'PARTICIPANT' ? 'createdByParticipant' : 'createdByPolpeo'
                                }
                            />
                        )}
                        <ManageEmailButton item={email} />
                    </EmailMetaFrom>
                    <EmailMetaTo>to {join(', ', emailRecipients)}</EmailMetaTo>
                </Display.VerticalWithSpacing>
                <EmailTimestamp>{createdAtTimestamp}</EmailTimestamp>
            </EmailMeta>
            {!!expanded && (
                <>
                    <EmailBody>
                        {isDeleted ? (
                            <BaseDiv theme={deletedPlaceholderTheme}>This email has been deleted</BaseDiv>
                        ) : (
                            <RichtextDisplay value={email.content.message} />
                        )}
                    </EmailBody>
                    {!disableCreateEmailItems && !isDeleted && (
                        <EmailActions>
                            <ReplyToItemButton replyTo={email} />
                            <ForwardItemButton forward={email} />
                        </EmailActions>
                    )}
                </>
            )}
            {!expanded && (
                <EllipsisButton onClick={onToggleExpand ? () => onToggleExpand(email) : undefined}>...</EllipsisButton>
            )}
        </EmailDetailsContainer>
    );
};

const EmailDetailsPane = styled.div`
    padding-top: 16px;
    flex: 1 1 auto;
    display: grid;
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    grid-template-columns: 56px 1fr;
    grid-template-areas:
        '. emailSubject'
        'emailThread emailThread';
    column-gap: 10px;
    row-gap: 15px;

    border-left: 1px solid ${grey1Colour};
`;

const EmailThreadScollPane = styled.div`
    grid-area: emailThread;
    width: 100%;
`;

interface EmailDetailsCardBodyProps {
    thread: EmailItem[];
    emailItemOptions?: EmailItemOptions;
    disableCreateEmailItems?: boolean;
    selectedEmail?: EmailItem;
}
export const EmailDetailsCardBody: FC<EmailDetailsCardBodyProps> = ({
    thread: emailThread,
    emailItemOptions,
    disableCreateEmailItems,
    selectedEmail,
}) => {
    const adminData = useContext(AdminInSimulationStateContext);
    const participantData = useContext(ParticipantStateContext);
    const [expanded, setExpanded] = useState<Record<string, boolean | undefined>>({});

    const participants = adminData.participants || participantData.participants || {};

    const sortedEmailThread = useMemo(() => {
        // newest emails first
        return sort(
            descend((email) => email.createdAt.toISOString()),
            emailThread,
        );
    }, [emailThread]);

    useEffect(() => {
        const latestEmail = sortedEmailThread[0];

        if (selectedEmail?.uuid && selectedEmail?.uuid !== latestEmail.uuid) {
            setExpanded({ ...expanded, [selectedEmail.uuid]: true });
            const element = document.getElementById(selectedEmail.uuid);
            if (element) {
                const scrollTimeout = setTimeout(
                    () => element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }),
                    10,
                );
                return () => clearTimeout(scrollTimeout);
            }
        } else setExpanded({ ...expanded, [latestEmail.uuid]: true });
    }, [sortedEmailThread]);

    return (
        <EmailDetailsPane>
            <EmailSubject>{sortedEmailThread[0].content.subject || '(no subject)'}</EmailSubject>
            <EmailThreadScollPane>
                {map(
                    (currentEmail) => (
                        <EmailDetails
                            key={currentEmail.uuid}
                            email={currentEmail}
                            participants={participants}
                            onToggleExpand={(toggleEmail) => {
                                setExpanded({ ...expanded, [toggleEmail.uuid]: !expanded[toggleEmail.uuid] });
                            }}
                            emailItemOptions={{ expanded: expanded[currentEmail.uuid], ...emailItemOptions }}
                            disableCreateEmailItems={disableCreateEmailItems}
                        />
                    ),
                    sortedEmailThread,
                )}
            </EmailThreadScollPane>
        </EmailDetailsPane>
    );
};
