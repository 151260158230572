import { EmailItem } from 'polpeo-go-common/types';
import React, { FC, useContext, useMemo } from 'react';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { Dropdown } from '../../bits/FormFields';

interface ParticipantsEmailsDropdownProps {
    currentParticipantInbox: string;
    setCurrentParticipantInbox: React.Dispatch<React.SetStateAction<string>>;
    setSelectedEmail: React.Dispatch<React.SetStateAction<EmailItem | undefined>>;
}

export const ParticipantsEmailsDropdown: FC<ParticipantsEmailsDropdownProps> = ({
    currentParticipantInbox,
    setCurrentParticipantInbox,
    setSelectedEmail,
}) => {
    const { teamParticipants } = useContext(AdminInSimulationStateContext);

    const participantOptionItems = useMemo(() => {
        const mappedTeamParticipants = teamParticipants.map((participant) => ({
            value: participant.id,
            label: participant.fullName,
        }));
        return [{ value: '', label: 'Staff emails' }, ...mappedTeamParticipants];
    }, [teamParticipants]);

    return (
        <Dropdown
            key="Participants Emails"
            id="Participants Emails"
            options={participantOptionItems}
            onChange={(e) => {
                setCurrentParticipantInbox(e.target.value);
                setSelectedEmail(undefined);
            }}
            value={currentParticipantInbox}
            small
        />
    );
};
