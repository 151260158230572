import styled from 'styled-components/macro';

export const StaffAppContentContainer = styled.div.attrs(() => ({ id: 'modal-root' }))`
    position: relative;
    width: 100%;

    display: flex;

    flex: 1 1 auto;
`;
