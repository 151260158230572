import gql from 'graphql-tag';
import { NewStaffUser, StaffUser } from 'polpeo-go-common/types/StaffUser';
import wrapMutation from '../wrapMutation';

interface CreateStaffUserParams {
    staffUser: NewStaffUser;
}

interface CreateStaffUserData {
    createStaffUser: StaffUser;
}

const mutation = gql`
    mutation createStaffUser($staffUser: NewStaffUserInput!) {
        createStaffUser(staffUser: $staffUser) {
            uuid
            name
            email
            role
            permissionSet
            skillLevel
            notes
            lastLogin
        }
    }
`;

export const createStaffUser = wrapMutation<CreateStaffUserParams, CreateStaffUserData>(mutation);
