import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { omit } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';
import { ScratchPadCategory } from './ScratchPadDocument';

export const InitialScratchPadDocument = t.type({
    uuid: t.string,
    category: ScratchPadCategory,
    path: optionalToUndefined(t.string),
    createdAt: t.union([DateFromISOString, date]),
    createdBy: t.string,
    lastEditedBy: optionalToUndefined(t.string),
    notes: optionalToUndefined(t.string),
    name: t.string,
    simulationUUID: t.string,
});
export type InitialScratchPadDocument = t.TypeOf<typeof InitialScratchPadDocument>;

export const NewInitialScratchpadDocument = t.type(
    omit(['uuid', 'createdAt', 'createdBy', 'lastEditedBy'], InitialScratchPadDocument.props),
);
export type NewInitialScratchpadDocument = t.TypeOf<typeof NewInitialScratchpadDocument>;

export const TEST_RICH_TEXT_SCRATCHPAD_DOCUMENT: InitialScratchPadDocument = {
    uuid: 'uuid',
    name: 'name',
    category: 'Strategy',
    path: undefined,
    notes: '<p>Test</p>',
    createdAt: new Date(),
    createdBy: 'admin',
    lastEditedBy: 'admin',
    simulationUUID: 'simulationUUID',
};
