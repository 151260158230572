import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { append, groupBy, includes, isNil, map, reduce, reject, toPairs } from 'ramda';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey4Colour } from '../../../../../../themes/colours';
import { formLabel } from '../../../../../../themes/cssSnippets';
import { IconButton } from '../../../../../bits/Buttons/IconButton';
import { Display } from '../../../../../bits/Display';
import { Icon } from '../../../../../bits/Icon';
import { NotificationBanner } from '../../../../../bits/NotificationBanner';
import { Card } from '../../../../../patterns/Card';
import { EmbeddedEmailItem } from '../../../../../patterns/EmbeddedEmailItem';
import { SimulationContent } from '../../../../../WithAdminState/adminState';
import { EmbeddedContentItem } from '../../../../PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../../../PageTemplate/templates';
import { sortItemsByParentChildRelation } from '../../../../../../utils/sortItemsByParentChildRelation';

const CardSection = styled.div`
    margin: 0 -15px;
    padding: 15px;

    border-bottom: 1px solid ${grey1Colour};

    &:first-child {
        margin-top: -15px;
    }

    &:last-child {
        margin-bottom: -15px;
        border-bottom: none;
    }
`;

const CardSectionHeader = styled.div`
    ${formLabel}
    padding-bottom: 10px;
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 20px 1fr 35px;
    column-gap: 8px;
    align-items: center;
`;

const PageTemplateIcon = styled.img`
    width: 20px;
    fill: ${grey4Colour};
`;

interface EditTriggerContentProps {
    trigger: Trigger;
    simulationContent: SimulationContent;
}
export const EditTriggerContent: FC<EditTriggerContentProps> = ({ trigger, simulationContent }) => {
    const { triggerPrepreparedContentLinks, prepreparedContents, prepreparedEmails, pages, pageOrder } =
        simulationContent;

    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    const linkedPrepreparedContentUUIDs = triggerPrepreparedContentLinks[trigger.uuid] || [];
    const groupedPrepreparedContent = useMemo(() => {
        const linkedPrepreparedContent = reject(
            isNil,
            map((uuid) => prepreparedContents[uuid], linkedPrepreparedContentUUIDs),
        ) as PrepreparedContentItem[];
        const groupedItems = groupBy((item) => item.content.pageUUID, linkedPrepreparedContent);
        const sortedGroupedItems = reduce(
            (acc, [key, items]) => ({ ...acc, [key]: sortItemsByParentChildRelation(items) }),
            {} as Record<string, PrepreparedContentItem[]>,
            toPairs(groupedItems),
        );
        return sortedGroupedItems;
    }, [linkedPrepreparedContentUUIDs]);

    const linkedPrepreparedEmails = reject(
        isNil,
        map((uuid) => prepreparedEmails[uuid], linkedPrepreparedContentUUIDs),
    ) as PrepreparedEmailItem[];

    const toggleGroupCollapsed = (id: string) => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups(append(id, expandedGroups));
        }
    };

    return (
        <Card header="Content">
            {map((pageUUID) => {
                const page = pages[pageUUID];
                const template = pageTemplates[page.templateUUID];
                const items = groupedPrepreparedContent[page.uuid] || [];

                const open = includes(pageUUID, expandedGroups);

                return (
                    <CardSection>
                        <CardSectionHeader>
                            <PageTemplateIcon src={template.icon} />
                            <span>
                                {page.name} ({items.length} {items.length === 1 ? 'item' : 'items'})
                            </span>
                            <IconButton
                                icon={open ? 'collapse' : 'accordianOpen'}
                                onClick={() => toggleGroupCollapsed(pageUUID)}
                            />
                        </CardSectionHeader>
                        {open && (
                            <Display.VerticalWithSpacing>
                                {!items.length && <>No items of this type linked to this trigger</>}
                                {map((item) => {
                                    const parent = prepreparedContents[item.parentUUID || ''];
                                    return (
                                        <Card>
                                            {parent && (
                                                <NotificationBanner>
                                                    Replying to <b>{parent.persona}</b>
                                                </NotificationBanner>
                                            )}
                                            <EmbeddedContentItem key={item.uuid} item={item} />
                                        </Card>
                                    );
                                }, items)}
                            </Display.VerticalWithSpacing>
                        )}
                    </CardSection>
                );
            }, pageOrder)}
            <CardSection>
                <CardSectionHeader>
                    <Icon icon="email" size={20} />
                    <span>
                        Email ({linkedPrepreparedEmails.length}{' '}
                        {linkedPrepreparedEmails.length === 1 ? 'item' : 'items'})
                    </span>
                    <IconButton
                        icon={includes('Email', expandedGroups) ? 'collapse' : 'accordianOpen'}
                        onClick={() => toggleGroupCollapsed('Email')}
                    />
                </CardSectionHeader>
                {includes('Email', expandedGroups) && (
                    <Display.VerticalWithSpacing>
                        {!linkedPrepreparedEmails.length && <>No items of this type linked to this trigger</>}
                        {map((item) => {
                            const parent = prepreparedEmails[item.parentUUID || ''];
                            return (
                                <Card>
                                    {parent && (
                                        <NotificationBanner>
                                            Replying to <b>{parent.persona}</b>
                                        </NotificationBanner>
                                    )}
                                    <EmbeddedEmailItem key={item.uuid} item={item} />
                                </Card>
                            );
                        }, linkedPrepreparedEmails)}
                    </Display.VerticalWithSpacing>
                )}
            </CardSection>
        </Card>
    );
};
