import styled from 'styled-components/macro';
import { BaseDiv } from '../utils';

export const Main = styled(BaseDiv).attrs(({ theme }) => ({ theme: theme || {} }))`
    grid-area: main;
    justify-self: ${({ theme }) => theme.position || 'center'};

    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
`;
