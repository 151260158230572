import { Page } from 'polpeo-go-common/types/Page';
import React, { FC, useContext, useState } from 'react';
import { PrimaryButton } from '../../bits/Buttons';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { NewParticipantContentItemModal } from './NewParticipantContentItemModal';
import { NewStaffContentItemModal } from './NewStaffContentItemModal';

interface NewContentItemButtonProps {
    page: Page;
}
export const NewContentItemButton: FC<NewContentItemButtonProps> = ({ page }) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const [showModal, setShowModal] = useState(false);

    const template = pageTemplates[page.templateUUID];

    return (
        <>
            <PrimaryButton onClick={() => setShowModal(true)}>Add {template.rootItemOptions.name}</PrimaryButton>
            {showModal && staffUser && (
                <NewStaffContentItemModal page={page} onCloseModal={() => setShowModal(false)} />
            )}
            {showModal && participantUser && (
                <NewParticipantContentItemModal page={page} onCloseModal={() => setShowModal(false)} />
            )}
        </>
    );
};
