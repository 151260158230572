export enum ListSimulationPermissionValue {
    ALL = 'ALL',
    ASSIGNED = 'ASSIGNED',
    CREATED = 'CREATED',
}

export enum WriteSimulationPermissionValue {
    ALL = 'ALL',
    ASSIGNED = 'ASSIGNED',
    CREATED = 'CREATED',
}

export enum DeleteScratchpadDocumentsPermissionValue {
    ALL = 'ALL',
    CREATED = 'CREATED',
}

export enum PermissionKey {
    ADMINISTER_SIMULATIONS = 'administerSimulations',
    CREATE_SIMULATIONS = 'createSimulations',
    DELETE_SCRATCHPAD_DOCUMENTS = 'deleteScratchpadDocuments',
    DOWNLOAD_SIMULATION_DATA = 'downloadSimulationData',
    GET_STAFF_USERS = 'getStaffUsers',
    LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT = 'listAndGetSimulationsAndPrepreparedContent',
    LIST_STAFF_USERS = 'listStaffUsers',
    PARTICIPATE_IN_SIMULATIONS = 'participateInSimulations',
    UPDATE_SIMULATION_READY_STATUS = 'updateSimulationReadyStatus',
    WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT = 'writeSimulationsAndPrepreparedContent',
    WRITE_STAFF_USERS = 'writeStaffUsers',
    EDIT_SIMULATION_STAFF = 'editSimulationAdministrators',
}

export type Permissions = {
    [PermissionKey.ADMINISTER_SIMULATIONS]: boolean;
    [PermissionKey.CREATE_SIMULATIONS]: boolean;
    [PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS]: DeleteScratchpadDocumentsPermissionValue | false;
    [PermissionKey.DOWNLOAD_SIMULATION_DATA]: boolean;
    [PermissionKey.GET_STAFF_USERS]: boolean;
    [PermissionKey.LIST_AND_GET_SIMULATIONS_AND_PREPREPARED_CONTENT]: ListSimulationPermissionValue | false;
    [PermissionKey.LIST_STAFF_USERS]: boolean;
    [PermissionKey.PARTICIPATE_IN_SIMULATIONS]: boolean;
    [PermissionKey.UPDATE_SIMULATION_READY_STATUS]: boolean;
    [PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT]: WriteSimulationPermissionValue | false;
    [PermissionKey.WRITE_STAFF_USERS]: boolean;
    [PermissionKey.EDIT_SIMULATION_STAFF]: boolean;
};
