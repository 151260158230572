import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import { MENU_BOTTOM_SCREEN_GAP, TAIL_HEIGHT, TAIL_WIDTH } from '../patterns/FAB/FABMenu';

interface UseFabButtonPositionerOutput {
    rightPosition: number;
    bottomPosition: number;
    fabButtonRef: RefObject<HTMLButtonElement>;
}

const TAIL_FAB_GAP = 15;

export function useFabButtonPositioner(): UseFabButtonPositionerOutput {
    const fabButtonRef = useRef<HTMLButtonElement>(null);
    const [rightPosition, setRightPosition] = useState(0);
    const [bottomPosition, setBottomPosition] = useState(0);
    useLayoutEffect(() => {
        if (fabButtonRef.current) {
            const fabButton = fabButtonRef.current;
            const htmlElement = document.getElementsByTagName('html')[0];

            const distanceOfLeftEdgeButtonToRightScreen =
                htmlElement.clientWidth - fabButton.getBoundingClientRect().left;
            setRightPosition(distanceOfLeftEdgeButtonToRightScreen + TAIL_WIDTH + TAIL_FAB_GAP);

            const distanceOfBottomEdgeButtonToBottomScreen =
                window.innerHeight - fabButton.getBoundingClientRect().bottom;
            setBottomPosition(distanceOfBottomEdgeButtonToBottomScreen - MENU_BOTTOM_SCREEN_GAP + TAIL_HEIGHT / 2);
        }
    }, []);
    return { rightPosition, bottomPosition, fabButtonRef };
}
