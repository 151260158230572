import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { filter, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { grey4Colour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Display } from '../../../bits/Display';
import { Modal } from '../../../patterns/Modal';
import { NewMomentButton } from '../../../patterns/Moment/NewMomentButton';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../WithStaffUser';
import { MomentsList } from './MomentsList';

interface ContentItemMomentsButtonProps {
    item: ContentItem;
}
export const ContentItemMomentsButton: FC<ContentItemMomentsButtonProps> = ({ item }) => {
    const staffUser = useContext(StaffUserContext);
    const { simulationContent: { moments = {} } = {} } = useContext(AdminInSimulationStateContext);
    const [openModal, setOpenModal] = useState(false);

    const momentsForItem = useMemo(() => {
        const momentsForTeam = values(moments[item.teamUUID] || {}) as Moment[];

        return filter((moment) => moment.contentItem.uuid === item.uuid, momentsForTeam);
    }, [item, moments]);

    if (!staffUser || !momentsForItem.length) {
        return <></>;
    }

    return (
        <>
            <IconButton icon="moment" fill={grey4Colour} width={20} height={20} onClick={() => setOpenModal(true)} />
            {openModal && (
                <Modal header="Highlights for Item" cardWidth={'80%'} onModalClose={() => setOpenModal(false)}>
                    <Display.VerticalWithSpacing>
                        <MomentsList moments={momentsForItem} />
                        <Display.RightAlign>
                            <NewMomentButton item={item} />
                        </Display.RightAlign>
                    </Display.VerticalWithSpacing>
                </Modal>
            )}
        </>
    );
};
