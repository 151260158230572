import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { optionalToUndefined } from 'polpeo-go-common/t';
import { ALL_TEAM_PARTICIPANTS_IDENTIFIER, EmailItemData } from 'polpeo-go-common/types/EmailItem';
import { find, reduce, values } from 'ramda';
import XLSX from 'xlsx';
import { cleanXlsxHtml } from '../cleanXlsxHtml';
import { UploadedTrigger } from '../trigger/parseTriggersSheet';
import getErrorsForPrepreparedEmailsSheet from './getErrorsForPrepreparedEmailSheet';

const UploadedPrepreparedEmail = t.type({
    type: t.literal('EMAIL'),
    tempId: optionalToUndefined(t.literal('')),
    triggerTitle: optionalToUndefined(t.string),
    persona: t.string,
    tempParentId: optionalToUndefined(t.literal('')),
    content: EmailItemData,
});
export type UploadedPrepreparedEmail = t.TypeOf<typeof UploadedPrepreparedEmail>;
interface ParsePrepreparedEmailsSuccessfulResults {
    prepreparedEmails: UploadedPrepreparedEmail[];
    errors: undefined;
}
interface ParsePrepreparedEmailsUnsuccessfulResults {
    prepreparedEmails: undefined;
    errors: string[];
}
export type ParsePrepreparedEmailsResults =
    | ParsePrepreparedEmailsSuccessfulResults
    | ParsePrepreparedEmailsUnsuccessfulResults;

const PrepreparedEmailsUpload = t.array(UploadedPrepreparedEmail);

export const parseEmailsSheet = (
    sheet: XLSX.WorkSheet,
    uploadedTriggers: UploadedTrigger[],
): ParsePrepreparedEmailsResults => {
    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet);
    const sheetData = reduce<string[], Record<string, unknown>[]>(
        // Will do error checking later so just try to put everything in a roughshape for now
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc, rowData: any) => {
            const dataCell = find((cell) => cell.v === rowData.message, values(sheet));
            // html || formatted text || empty string
            const cleanedHtmlRichText = cleanXlsxHtml(dataCell.h || dataCell.w || '');

            const prepreparedEmail = {
                type: 'EMAIL',
                tempId: undefined,
                triggerTitle: rowData.triggerTitle,
                persona: rowData.persona,
                tempParentId: undefined,
                content: {
                    recipients: [ALL_TEAM_PARTICIPANTS_IDENTIFIER],
                    subject: rowData.subject,
                    message: cleanedHtmlRichText,
                },
            };
            return [...acc, prepreparedEmail];
        },
        [],
        sheetRows,
    );
    const maybePrepreparedEmails = PrepreparedEmailsUpload.decode(sheetData);

    // check for data shape
    if (isLeft(maybePrepreparedEmails)) {
        console.error('sheet was not the correct prepreparedEmails shape', sheetData, maybePrepreparedEmails.left);
        return {
            prepreparedEmails: undefined,
            errors: getErrorsForPrepreparedEmailsSheet(sheetData, uploadedTriggers),
        };
    }
    const prepreparedEmails = maybePrepreparedEmails.right;
    // Extra validation
    const errors = getErrorsForPrepreparedEmailsSheet(prepreparedEmails, uploadedTriggers);
    if (errors.length) {
        return {
            prepreparedEmails: undefined,
            errors: errors,
        };
    }

    return {
        prepreparedEmails: prepreparedEmails,
        errors: undefined,
    };
};
