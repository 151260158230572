import { toValidEmail } from 'polpeo-go-common/toValidEmail';
import { NewStaffUser, SkillLevelEnum, StaffUser, UserRoleEnum } from 'polpeo-go-common/types/StaffUser';
import { all, assoc, dissoc, equals, map, pick, pipe, values } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { createStaffUser } from '../../../../graphql/staffusers/createStaffUser';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Dropdown, TextArea, TextInput } from '../../../bits/FormFields';
import { Body2, StyledErrorMessage } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../patterns/Modal';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { FormGrid } from '../../FormGrid';
import { roleOptions } from './roleOptions';
import { skillOptions } from './skillOptions';

interface AddStaffUserModalProps {
    onModalClose: () => void;
}
export const AddStaffUserModal: FC<AddStaffUserModalProps> = ({ onModalClose }) => {
    const { staffUsers, setStaffUsers } = useContext(AdminStateContext);
    const [createStaffUserMutation, { data: CreateStaffUserData, loading, error: CreateStaffUserError }] =
        createStaffUser.hook();
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const initialStaffUser = {
        name: '',
        email: '',
        notes: '',
        role: UserRoleEnum.ROLEPLAYER,
        skillLevel: SkillLevelEnum.STANDARD,
    };
    const [newStaffUser, setNewStaffUser] = useState<NewStaffUser>(initialStaffUser);

    const requiredNameAndEmailFieldsCompleted = pipe(
        pick(['name', 'email']),
        (details) => values(details),
        all((value) => !!value),
    )(newStaffUser);
    const canCreateUser = requiredNameAndEmailFieldsCompleted && !values(errors).length;

    useEffect(() => {
        if (newStaffUser.email) {
            try {
                toValidEmail(newStaffUser.email);
                setErrors(dissoc('email', errors));
            } catch {
                setErrors({ email: ['Please enter a valid email address'] });
            }
        }
    }, [newStaffUser]);

    useEffect(() => {
        if (CreateStaffUserData?.createStaffUser) {
            const staffUser = CreateStaffUserData.createStaffUser;
            setStaffUsers({ ...(staffUsers as Record<string, StaffUser>), [staffUser.uuid]: staffUser });
            onModalClose();
        }
    }, [CreateStaffUserData]);

    const hasUnsavedChanges = !equals(newStaffUser, initialStaffUser) && !CreateStaffUserData?.createStaffUser;
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onModalClose,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal header="Add User" onModalClose={onModalCloseUnsavedPrompt} cardWidth={800}>
                {loading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!loading && (
                    <>
                        <FormGrid
                            onSubmit={() => {
                                createStaffUserMutation({
                                    variables: { staffUser: newStaffUser },
                                });
                            }}
                            fields={[
                                [
                                    'Full Name',
                                    <TextInput
                                        key="Full Name"
                                        placeholder="Full Name"
                                        id="name"
                                        value={newStaffUser.name}
                                        onChange={(e) => setNewStaffUser(assoc('name', e.target.value))}
                                    />,
                                ],
                                [
                                    'Email Address',
                                    <>
                                        <TextInput
                                            placeholder="Email Address"
                                            id="email"
                                            value={newStaffUser.email}
                                            onChange={(e) => setNewStaffUser(assoc('email', e.target.value))}
                                        />
                                        {errors.email &&
                                            map(
                                                (error) => <StyledErrorMessage key={error}>{error}</StyledErrorMessage>,
                                                errors.email,
                                            )}
                                    </>,
                                ],
                                [
                                    'Role',
                                    <Dropdown
                                        key="Role"
                                        id="role"
                                        value={newStaffUser.role}
                                        onChange={(e) => setNewStaffUser(assoc('role', e.target.value))}
                                        options={roleOptions}
                                    />,
                                ],
                                [
                                    'Skill Level',
                                    <Dropdown
                                        key="Skill level"
                                        id="skillLevel"
                                        onChange={(e) => setNewStaffUser(assoc('skillLevel', e.target.value))}
                                        options={skillOptions}
                                        value={newStaffUser.skillLevel}
                                    />,
                                ],
                                [
                                    'Notes',
                                    <TextArea
                                        key="Notes"
                                        placeholder="Notes"
                                        id="notes"
                                        value={newStaffUser.notes}
                                        onChange={(e) => {
                                            const newNotes = e.target.value;
                                            if (newNotes.length <= 1000) {
                                                setNewStaffUser(assoc('notes', e.target.value));
                                            }
                                        }}
                                    />,
                                ],
                            ]}
                            footer={
                                <Display.VerticalWithSpacing>
                                    <Body2>
                                        When you create a user, they will immediately get an email to create a password
                                        for their account. This code will expire after 7 days but they can contact an
                                        admin to reset their account and receive a new code.
                                    </Body2>
                                    {CreateStaffUserError && (
                                        <StyledErrorMessage>
                                            Something went wrong. Please try again or contact an admin user
                                        </StyledErrorMessage>
                                    )}
                                    <Display.HorizontalWithSpacing horizontalAlign="end">
                                        <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                        <PrimaryButton disabled={!canCreateUser} type="submit">
                                            Add
                                        </PrimaryButton>
                                    </Display.HorizontalWithSpacing>
                                </Display.VerticalWithSpacing>
                            }
                        />
                    </>
                )}
            </Modal>
        </>
    );
};
