import gql from 'graphql-tag';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapQuery from '../wrapQuery';

interface GetEmailItemsParams {
    simulationUUID: string;
    newerThan?: Date | string;
    startFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
}

interface GetEmailItemsData {
    getEmailItems: {
        emailItems: EmailItem[];
        continueFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
    };
}

const query = gql`
    query getEmailItems($simulationUUID: String!, $newerThan: String, $startFrom: AWSJSON) {
        getEmailItems(simulationUUID: $simulationUUID, newerThan: $newerThan, startFrom: $startFrom) {
            emailItems {
                type
                uuid
                simulationUUID
                teamUUID
                parentUUID
                createdAt
                persona
                content {
                    recipients
                    subject
                    message
                }
                prepreparedContentUUID
                createdBy {
                    type
                    uuid
                }
                deleted {
                    at
                    by {
                        type
                        uuid
                    }
                }
            }
            continueFrom
        }
    }
`;

export const getEmailItems = wrapQuery<GetEmailItemsParams, GetEmailItemsData>(query);
