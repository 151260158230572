import { ContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PageTheme } from 'polpeo-go-common/types/PageTemplate';
import { find, values } from 'ramda';
import React, { FC, useContext } from 'react';
import { getContentItemFields } from '../../../utils/getContentItemFields';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { ContentItemComponent, ContentItemOptions } from './ContentItem';
import { pageTemplates } from './templates';

const emptyPageTheme: PageTheme = {
    page: { header: {}, leftSidebar: {}, rightSidebar: {}, footer: {} },
    rootItemsContainer: {},
    rootItem: {
        creator: { username: {} },
        meta: {},
        interactions: {},
        deletedPlaceholder: {},
        fields: {},
    },
    childrenItemsContainer: {},
    childItemContainer: {},
    childItem: {
        creator: { username: {} },
        meta: {},
        interactions: {},
        deletedPlaceholder: {},
        fields: {},
    },
};

interface EmbeddedContentItemProps {
    item: ContentItem | PrepreparedContentItem;
    small?: boolean;
    mode?: 'DETAILS' | 'LISTING';
    contentItemOptions?: ContentItemOptions;
}
export const EmbeddedContentItem: FC<EmbeddedContentItemProps> = ({
    item,
    small,
    contentItemOptions = {},
    mode = 'LISTING',
}) => {
    const adminState = useContext(AdminStateContext);
    const participantState = useContext(ParticipantStateContext);

    const pages =
        participantState.pages || values(adminState?.simulationContent[item.simulationUUID].pages || {}) || [];

    const page = find((p) => p.uuid === item.content.pageUUID, pages);
    if (!page) {
        throw new Error(`Page could not be found to embed content item '${item.uuid}'`);
    }
    const template = pageTemplates[page.templateUUID];

    let fieldOrder;
    if (mode === 'DETAILS') {
        if (item.parentUUID) {
            fieldOrder = template.detailsPage.childContentFieldOrder;
        } else {
            fieldOrder = template.detailsPage.rootContentFieldOrder;
        }
    } else {
        if (item.parentUUID) {
            fieldOrder = template.listingPage.childrenSettings.location
                ? template.listingPage.childContentFieldOrder
                : template.detailsPage.childContentFieldOrder;
        } else {
            fieldOrder = template.listingPage.rootContentFieldOrder;
        }
    }

    let pageTheme;
    if (small) pageTheme = emptyPageTheme;
    else pageTheme = mode === 'DETAILS' ? template.detailsPage.theme : template.listingPage.theme;

    return (
        <ContentItemComponent
            item={item}
            template={template}
            theme={item.parentUUID ? pageTheme.childItem : pageTheme.rootItem}
            fields={getContentItemFields(template, item)}
            fieldOrder={fieldOrder}
            options={{
                disableManageMenu: true,
                disableDetailsLink: true,
                hideCreatedByIcon: !!participantState.simulation || !!small,
                hideInteractions: !!small,
                noAvatar: !!small,
                forceInlineMeta: !!small,
                imagePlaceholder: !!small,
                videoPlaceholder: !!small,
                maxCharacterLimit: !!small ? 200 : undefined,
                ...contentItemOptions,
            }}
        />
    );
};
