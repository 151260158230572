import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../../../bits/Buttons';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { ManageSimulationAdimistratorsModal } from './ManageSimulationAdimistratorsModal';

export const EditSimulationAdministratorsButton: FC = () => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>Edit Simulation Administrators</SecondaryButton>
            {showModal && (
                <ManageSimulationAdimistratorsModal
                    onModalClose={() => setShowModal(false)}
                    simulation={currentSimulation}
                />
            )}
        </>
    );
};
