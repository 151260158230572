import styled from 'styled-components/macro';
import { Button } from '../Buttons';

export interface IconSvgProps {
    fill?: string;
    stroke?: string;
    width?: number;
    height?: number;
    size?: number;
}
export const IconSvg = styled.svg.attrs<IconSvgProps>(({ width, height, size }) => ({
    width: size || width || 24,
    height: size || height || 24,
}))`
    flex: 0 0 auto;
    fill: ${({ fill }) => (fill ? fill : 'currentColor')};
    stroke: ${({ stroke }) => (stroke ? stroke : 'inherit')};

    ${Button} > &:first-child {
        margin-right: 6px;
    }

    ${Button} > &:last-child:not(:only-child) {
        margin-left: 6px;
    }
`;
