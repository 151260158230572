import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { optionalToUndefined } from '../t';

export const ParticipantTeamAssignment = t.type({
    teamUUID: t.string,
    lockedOn: optionalToUndefined(t.union([DateFromISOString, date])),
});
export type ParticipantTeamAssignment = t.TypeOf<typeof ParticipantTeamAssignment>;

export const ParticipantTeamAssignments = t.record(t.string, ParticipantTeamAssignment);
export type ParticipantTeamAssignments = t.TypeOf<typeof ParticipantTeamAssignments>;

export const SimulationParticipantAssignments = t.type({
    simulationUUID: t.string, // This is sadly necessary as we have subscriptions looking for this value
    assignments: ParticipantTeamAssignments,
});
export type SimulationParticipantAssignments = t.TypeOf<typeof SimulationParticipantAssignments>;
export const decodeSimulationParticipantAssignments = (parsedData: unknown): SimulationParticipantAssignments => {
    const maybeTeamAssignment = SimulationParticipantAssignments.decode(parsedData);
    if (isLeft(maybeTeamAssignment)) {
        throw new Error('Team Assignments was not the right shape');
    }
    return maybeTeamAssignment.right;
};

export const TEST_PARTICIPANT_TEAM_ASSIGNMENTS: SimulationParticipantAssignments = {
    simulationUUID: 'simulationUUID',
    assignments: {
        participantID: {
            teamUUID: 'teamUUID',
            lockedOn: undefined,
        },
    },
};
