import { Team } from 'polpeo-go-common/types/Team';
import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../../bits/Buttons';
import { Icon } from '../../../../bits/Icon';
import { ManageTeamModal } from './ManageTeamModal';

interface EditSimulationTeamButtonProps {
    team: Team;
}
export const EditSimulationTeamButton: FC<EditSimulationTeamButtonProps> = ({
    team,
}: EditSimulationTeamButtonProps) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <SecondaryButton
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
            >
                <Icon icon="edit" width={12} height={12} fill="currentColor" /> Edit details
            </SecondaryButton>
            {showModal && <ManageTeamModal team={team} onModalClose={() => setShowModal(false)} />}
        </>
    );
};
