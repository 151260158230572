import { Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useContext, useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Modal } from '../../../patterns/Modal';
import { Body2 } from '../../../bits/Headers';
import { revertSimulation } from '../../../../graphql/simulations/revertSimulation';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { Spinner } from '../../../bits/Spinner';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { grey3Colour } from '../../../../themes/colours';

interface RevertSimulationModalProps {
    simulation: Simulation;
}

export const RevertSimulationButton: FC<RevertSimulationModalProps> = ({ simulation }) => {
    const [showModal, setShowModal] = useState(false);
    const [revertSimulationMutation, { data: revertSimulationData, loading: revertSimulationLoading }] =
        revertSimulation.hook({
            variables: {
                simulationUUID: simulation?.uuid || '',
            },
        });
    const { setSimulation, clearSimulationData } = useContext(AdminStateContext);

    useEffect(() => {
        if (revertSimulationData?.revertSimulation) {
            const simulationToUpdate = revertSimulationData.revertSimulation;
            setSimulation(simulationToUpdate);
            clearSimulationData(simulationToUpdate.uuid);
            setShowModal(false);
        }
    }, [revertSimulationData]);

    return (
        <>
            <IconButton
                icon="revert"
                fill={grey3Colour}
                onClick={() => {
                    setShowModal(true);
                }}
            />
            {showModal && (
                <Modal header="Revert Simulation">
                    {revertSimulationLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!revertSimulationLoading && (
                        <div>
                            <Display.VerticalWithSpacing>
                                <div>
                                    Are you sure that you want to revert this simulation:{' '}
                                    <b>{simulation?.name || ''}?</b>
                                </div>
                                <div>
                                    This will delete all emails and content items created during the simulation and all
                                    participants.
                                </div>
                                <Body2 destructive>
                                    <b>This action cannot be undone.</b>
                                </Body2>
                            </Display.VerticalWithSpacing>
                            <Display.RightAlign>
                                <Display.HorizontalWithSpacing>
                                    <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
                                    <PrimaryButton onClick={() => revertSimulationMutation()}>Ok</PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.RightAlign>
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};
