import { ContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { Creator, personaToCreator } from 'polpeo-go-common/types/Creator';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';

export const getCreatorForItem = (
    item: PrepreparedContentItem | ContentItem,
    context: { participants: Record<string, ParticipantUser> },
): Creator | undefined => {
    const { participants } = context;
    if ('createdBy' in item) {
        if (item.createdBy.type === 'PARTICIPANT') {
            return { ...participants[item.createdBy.uuid], type: 'PARTICIPANT' };
        } else {
            // TODO fix contentItem typing so that we know when the creator is not
            // a participant then the persona field should always be filled
            return personaToCreator(item);
        }
    } else {
        return personaToCreator(item);
    }
};
