import gql from 'graphql-tag';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapQuery from '../wrapQuery';

interface GetTriggersParams {
    simulationUUID: string;
}

interface GetTriggersData {
    getTriggers: (Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    })[];
}

const query = gql`
    query getTriggers($simulationUUID: String!) {
        getTriggers(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            title
            isOptional
            simulatedTime
            participantPopupSettings {
                enabled
                text
                prepreparedContentItemUUID
            }
            staffPopupSettings {
                directions
                guidelines
                expectedActions
            }
            overviewChanges {
                title
                description
                headerImage
            }
            pageDressingChanges
            releasedAt
        }
    }
`;

export const getTriggers = wrapQuery<GetTriggersParams, GetTriggersData>(query);
