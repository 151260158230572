import Image from '@tiptap/extension-image';
import Underline from '@tiptap/extension-underline';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { DependencyList, useEffect, useRef } from 'react';
import CustomLink from './CustomLink';
import EmbededVideo from './EmbeddedVideo';

interface PolpeoRichtextEditorOptions {
    editable?: boolean;
    content?: string;
    onChange?: (richtext: string) => void;
}
const usePolpeoRichtextEditor = (
    options?: Partial<PolpeoRichtextEditorOptions>,
    deps?: DependencyList,
): Editor | null => {
    const onUpdateRef = useRef<(richtext: string) => void>();
    const editor = useEditor(
        {
            extensions: [
                StarterKit.configure({
                    code: false,
                    codeBlock: false,
                    heading: false,
                    horizontalRule: false,
                    strike: false,
                }),
                Underline,
                Image,
                CustomLink,
                EmbededVideo,
            ],
            content: options?.content,
            editorProps: { attributes: { class: 'polpeo-richtext-input' } },
            editable: options?.editable,
            onUpdate: ({ editor }) => {
                if (onUpdateRef.current) {
                    onUpdateRef.current(editor.getHTML());
                }
            },
        },
        deps,
    );

    // We need to manually set editable as it does not update
    // dynamically
    useEffect(() => {
        editor?.setEditable(!!options?.editable);
    }, [options?.editable]);

    // The editor will not refresh with the updated value where the onChange is not being called.
    // We need to use the 'setContent' to make this happen.
    // Using setContent in this way causes an issue where the editor does not recognise spaces
    // properly. This is fixed by using '!editor?.isFocused' from https://github.com/ueberdosis/tiptap/issues/776.
    // It is not clear why this fixes the issue without much deeper investigation

    useEffect(() => {
        // checking for typeof string to make sure updated for empty string
        if (editor && !editor.isDestroyed && options && typeof options.content === 'string') {
            const incomingContent = options.content;
            if (editor.getHTML() !== incomingContent) {
                if (editor.isFocused) {
                    editor.commands.blur();
                }
                if (!editor.isDestroyed) {
                    editor.commands.setContent(incomingContent);
                }
            }
        }
    }, [options?.content]);

    // We need to do this because if we pass the onUpdate function into the editor when it
    // is created you cannot then change it later. The way we work with react we end up
    // passing in new "versions" of functions when props update in parent components so we
    // need the onUpdate function to update accordingly
    useEffect(() => {
        onUpdateRef.current = options?.onChange;
    }, [options?.onChange]);

    return editor;
};

export default usePolpeoRichtextEditor;
