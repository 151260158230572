// We have alot fo indenting that makes this file very wide so increase the line length here instead of disabling the rule line by line
/* eslint max-len: ["warn", 135] */
import { Moment } from 'polpeo-go-common/types/Moment';
import { Team } from 'polpeo-go-common/types/Team';
import { includes, map, prop, sortBy, values } from 'ramda';
import React, { FC, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { TabItem, TabMenu } from '../../../../patterns/TabMenu';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { ContentItemOptions } from '../../../PageTemplate/ContentItem';
import { SlimTabNav as DefaultSlimTabs } from '../../../SlimTabs';
import { AllTeamsOverview } from '../AllTeamsOverview';
import { MomentsList } from '../MomentsList';
import { SimulationTeamNav } from '../TeamView/SimulationTeamNav';
import { TeamEmailFAB } from '../TeamView/TeamEmailFAB';
import { TeamOverview } from '../TeamView/TeamOverview';
import { TeamPageContentDetails } from '../TeamView/TeamPageContentDetails';
import { TeamPageContentList } from '../TeamView/TeamPageContentList';
import { TeamScratchpadFAB } from '../TeamView/TeamScratchpadFAB';
import { GlobalStyleForPage, MainContentContainer, NavBarScrollBox, PageContentContainer, ScrollBox } from './utils';
import { FABContainer } from '../../../../patterns/FAB/FABContainer';

const PAGE_UUID_PATH_PARAM =
    ':pageUUID([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89AB][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})';

const SimulationMomentsContainer = styled.div`
    padding: 24px;
    justify-self: center;

    flex: 1 1 auto;
`;

const SlimTabs = styled(DefaultSlimTabs)`
    padding: 10px 20px 0;
`;

const MomentsListContainer = styled.div`
    width: 70%;
    min-width: 800px;
    max-width: 1000px;
    margin: 0 auto;
`;
interface MomentsListForTeamProps {
    team: Team;
    contentItemOptions?: ContentItemOptions;
}
const MomentsListForTeam: FC<MomentsListForTeamProps> = ({ team, contentItemOptions }) => {
    const {
        simulationContent: { moments: allMoments },
    } = useContext(AdminInSimulationStateContext);
    const teamMoments = values(allMoments[team.uuid] || {}) as Moment[];
    return (
        <MomentsListContainer>
            <MomentsList moments={teamMoments} contentItemOptions={contentItemOptions} />
        </MomentsListContainer>
    );
};

export const StaffUserSimulationCompletedPage: FC = () => {
    const { simulation, teamsForCurrentStaffUser } = useContext(AdminInSimulationStateContext);
    const sortedTeams = sortBy<Team>(prop('name'), values(teamsForCurrentStaffUser));
    return (
        <>
            <GlobalStyleForPage />
            <PageContentContainer>
                <TabMenu
                    tabs={[
                        {
                            label: 'Overview',
                            icon: 'content',
                            navLink: {
                                to: `/admin/simulation/${simulation.uuid}`,
                                exact: true,
                            },
                            route: {
                                path: '/admin/simulation/:simulationUUID',
                                children: <AllTeamsOverview useTimestampRelativeToSimulationStart={simulation} />,
                                exact: true,
                            },
                        },
                        ...map<Team, TabItem>((team) => {
                            const rootUrl = `/admin/simulation/${simulation.uuid}/team/${team.uuid}`;
                            const rootPath = '/admin/simulation/:simulationUUID/team/:teamUUID';

                            return {
                                label: team.name,
                                icon: 'team',
                                navLink: { to: rootUrl },
                                route: {
                                    // This needs to be the URL and not the PATH otherwise the generated <Route>
                                    // will greedily match on all team uuids and you'll end up only being able to
                                    // see the first team's content!
                                    path: [
                                        rootUrl,
                                        `${rootUrl}/${PAGE_UUID_PATH_PARAM}`,
                                        `${rootUrl}/${PAGE_UUID_PATH_PARAM}/:itemUUID`,
                                        `${rootUrl}/moments`,
                                    ],
                                    exact: true,
                                    children: (
                                        // Redeclare the route here so that components can use useParams to get
                                        // teamUUID, pageUUID, itemUUID when they need to
                                        <Route
                                            path={[
                                                rootPath,
                                                `${rootPath}/${PAGE_UUID_PATH_PARAM}`,
                                                `${rootPath}/${PAGE_UUID_PATH_PARAM}/:itemUUID`,
                                                `${rootUrl}/moments`,
                                            ]}
                                        >
                                            <SlimTabs
                                                tabs={[
                                                    {
                                                        label: 'Review',
                                                        navLink: {
                                                            to: rootUrl,
                                                            isActive: (match, location) =>
                                                                !!(match && !includes('moments', location.pathname)),
                                                        },
                                                        route: {
                                                            path: [
                                                                rootPath,
                                                                `${rootPath}/${PAGE_UUID_PATH_PARAM}`,
                                                                `${rootPath}/${PAGE_UUID_PATH_PARAM}/:itemUUID`,
                                                            ],
                                                            exact: true,
                                                            children: (
                                                                <MainContentContainer>
                                                                    <NavBarScrollBox>
                                                                        <SimulationTeamNav
                                                                            team={team}
                                                                            rootUrl={rootUrl}
                                                                        />
                                                                    </NavBarScrollBox>
                                                                    <ScrollBox>
                                                                        <Switch>
                                                                            <Route path={rootPath} exact>
                                                                                <TeamOverview team={team} />
                                                                            </Route>
                                                                            <Route
                                                                                path={`${rootPath}/${PAGE_UUID_PATH_PARAM}`}
                                                                                exact
                                                                            >
                                                                                <TeamPageContentList
                                                                                    team={team}
                                                                                    contentItemOptions={{
                                                                                        useTimestampRelativeToSimulationStart:
                                                                                            simulation,
                                                                                    }}
                                                                                    disableCreateContentItems
                                                                                />
                                                                            </Route>
                                                                            <Route
                                                                                path={`${rootPath}/${PAGE_UUID_PATH_PARAM}/:itemUUID`}
                                                                                exact
                                                                            >
                                                                                <TeamPageContentDetails
                                                                                    team={team}
                                                                                    contentItemOptions={{
                                                                                        useTimestampRelativeToSimulationStart:
                                                                                            simulation,
                                                                                    }}
                                                                                    disableCreateContentItems
                                                                                />
                                                                            </Route>
                                                                        </Switch>
                                                                    </ScrollBox>
                                                                    {simulation && (
                                                                        <FABContainer>
                                                                            <TeamScratchpadFAB
                                                                                team={team}
                                                                                disableManageDocuments
                                                                            />
                                                                            <TeamEmailFAB
                                                                                team={team}
                                                                                emailItemOptions={{
                                                                                    useTimestampRelativeToSimulationStart:
                                                                                        simulation,
                                                                                }}
                                                                                disableCreateEmailItems
                                                                            />
                                                                        </FABContainer>
                                                                    )}
                                                                </MainContentContainer>
                                                            ),
                                                        },
                                                    },
                                                    {
                                                        label: 'Moments',
                                                        navLink: {
                                                            to: `${rootUrl}/moments`,
                                                            exact: true,
                                                        },
                                                        route: {
                                                            path: `${rootPath}/moments`,
                                                            exact: true,
                                                            children: (
                                                                <MainContentContainer>
                                                                    <ScrollBox>
                                                                        <SimulationMomentsContainer>
                                                                            <MomentsListForTeam
                                                                                team={team}
                                                                                contentItemOptions={{
                                                                                    useTimestampRelativeToSimulationStart:
                                                                                        simulation,
                                                                                }}
                                                                            />
                                                                        </SimulationMomentsContainer>
                                                                    </ScrollBox>
                                                                </MainContentContainer>
                                                            ),
                                                        },
                                                    },
                                                ]}
                                            />
                                        </Route>
                                    ),
                                },
                            };
                        }, sortedTeams),
                    ]}
                />
            </PageContentContainer>
        </>
    );
};
