import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { cond, values } from 'ramda';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { H1Heading } from '../../../../bits/Headers';
import { Link } from '../../../../bits/Link';
import { AdminTable } from '../../../AdminTable';
import { pageTemplates } from '../../../PageTemplate/templates';

const PageTemplatesListHeader = styled.div`
    margin: 15px;
`;

const PageTemplateIcon = styled.img`
    width: 25px;
`;

export const PageTemplatesListPage: FC = () => {
    return (
        <>
            <PageTemplatesListHeader>
                <H1Heading>Page Templates</H1Heading>
            </PageTemplatesListHeader>
            <AdminTable<PageTemplate>
                headers={['Name', 'Icon', 'Category', 'Preview Listing Page', 'Preview Details Page']}
                items={values(pageTemplates)}
                getItemKey={(template) => template.uuid}
                getItemCell={(template, header) =>
                    cond([
                        [() => header === 'Name', () => template.name],
                        [() => header === 'Icon', () => <PageTemplateIcon src={template.icon} />],
                        [() => header === 'Category', () => template.category],
                        [
                            () => header === 'Preview Listing Page',
                            () => <Link to={`/admin/page-templates/${template.uuid}/list`}>{template.name}</Link>,
                        ],
                        [
                            () => header === 'Preview Details Page',
                            () => <Link to={`/admin/page-templates/${template.uuid}/item`}>{template.name}</Link>,
                        ],
                    ])()
                }
            />
        </>
    );
};
