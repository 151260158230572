import gql from 'graphql-tag';
import { GraphQlContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface EditContentItemParams {
    contentItem: GraphQlContentItem;
}

interface EditContentItemData {
    editContentItem: GraphQlContentItem;
}

const mutation = gql`
    mutation editContentItem($contentItem: ContentItemInput!) {
        editContentItem(contentItem: $contentItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                pageUUID
                interactions {
                    positive
                    negative
                    shares
                }
                data
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
            deleted {
                at
                by {
                    type
                    uuid
                }
            }
        }
    }
`;

export const editContentItem = wrapMutation<EditContentItemParams, EditContentItemData>(mutation);
