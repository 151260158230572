import { ALL_TEAM_PARTICIPANTS_IDENTIFIER, EmailItem, ParticipantUser } from 'polpeo-go-common/types';
import { filter, values, map, findLast, sort, descend, any } from 'ramda';
import { sortEmailItemsIntoThreads } from '../../utils/sortEmailItemsIntoThreads';
import { useMemo } from 'react';

type EmailUUID = string;
type ThreadIndex = number;

interface UseThreadBuilderOutput {
    threads: Array<EmailItem[]>;
    emailUUIDToThreadMap: Record<EmailUUID, ThreadIndex>;
    inboxEmails: EmailItem[];
    outboxEmails: EmailItem[];
}

export function useThreadBuilder(
    emailItems: Record<string, EmailItem | undefined>,
    currentParticipantInbox: string,
    participants?: Record<string, ParticipantUser>,
): UseThreadBuilderOutput {
    return useMemo(
        () => {
            const emailsForUser = filter(
                (email) =>
                    currentParticipantInbox
                        ? email.createdBy.uuid === currentParticipantInbox ||
                          any(
                              //  Any recipient is not a participant and not ALL_TEAM_PARTICIPANTS
                              (recipient) =>
                                  recipient === currentParticipantInbox ||
                                  recipient === ALL_TEAM_PARTICIPANTS_IDENTIFIER,
                              email.content.recipients,
                          )
                        : email.createdBy.type !== 'PARTICIPANT' ||
                          any(
                              //  Any recipient is not a participant and not ALL_TEAM_PARTICIPANTS
                              (recipient) =>
                                  !participants?.[recipient] && recipient !== ALL_TEAM_PARTICIPANTS_IDENTIFIER,
                              email.content.recipients,
                          ),
                values(emailItems || {}) as EmailItem[],
            );

            const { threads, emailUUIDToThreadMap } = sortEmailItemsIntoThreads(emailsForUser);

            const inboxThreads = filter(
                (thread) =>
                    any(
                        // Any email where...
                        (email) =>
                            any(
                                //  Any recipient is not a participant and not ALL_TEAM_PARTICIPANTS
                                (recipient) =>
                                    currentParticipantInbox
                                        ? recipient === currentParticipantInbox ||
                                          recipient === ALL_TEAM_PARTICIPANTS_IDENTIFIER
                                        : !participants?.[recipient] && recipient !== ALL_TEAM_PARTICIPANTS_IDENTIFIER,
                                email.content.recipients,
                            ),
                        thread,
                    ),
                threads,
            );
            const emailToShowPerThreadIncoming = map((thread) => {
                const latestIncomingEmail = findLast((email) => email.createdBy.type === 'PARTICIPANT', thread);
                return latestIncomingEmail || thread[0];
            }, inboxThreads);

            const outboxThreads = filter(
                (thread) =>
                    any(
                        (email) =>
                            currentParticipantInbox
                                ? email.createdBy.uuid === currentParticipantInbox
                                : email.createdBy.type !== 'PARTICIPANT',
                        thread,
                    ),
                threads,
            );
            const emailToShowPerThreadOutgoing = map((thread) => {
                const latestOutgoingEmail = findLast((email) => email.createdBy.type !== 'PARTICIPANT', thread);
                return latestOutgoingEmail || thread[0];
            }, outboxThreads);

            return {
                threads,
                emailUUIDToThreadMap,

                inboxEmails: sort(
                    descend((email) => email.createdAt.toISOString()),
                    emailToShowPerThreadIncoming,
                ),
                // reverse so newest sent appear at the top of sent box
                outboxEmails: sort(
                    descend((email) => email.createdAt.toISOString()),
                    emailToShowPerThreadOutgoing,
                ),
            };
        },
        // the dependency array needs to be created here instead of being passed in otherwise
        // the array would be recreated each render breaking the memoisation
        [currentParticipantInbox, emailItems],
    );
}
