import styled from 'styled-components/macro';
import { Display, VerticalWithSpacingProps } from '../../bits/Display';

interface FABContainerProps extends VerticalWithSpacingProps {
    bottom?: number;
}
export const FABContainer = styled(Display.VerticalWithSpacing)<FABContainerProps>`
    position: fixed;
    bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '40px')};
    right: 30px;
    z-index: 100;
`;
