import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import {
    CreateRootContentPermissionTypeEnum,
    ParticipantDeleteContentPermissionTypeEnum,
} from 'polpeo-go-common/types/Page';
import {
    evolve,
    find,
    flatten,
    isNil,
    keys,
    map,
    pickAll,
    pluck,
    reduce,
    reject,
    toLower,
    trim,
    uniq,
    values,
} from 'ramda';

const pageValidationTransformations = {
    templateName: (val: unknown) => {
        if (!val) {
            return 'Pages, templateName: a template name is required';
        }
        return undefined;
    },
    sheetName: (val: unknown) => {
        if (val && typeof val === 'string' && val.length > 31) {
            return `Pages, sheetName: a sheet name '${val}' cannot be longer than 31 characters`;
        }
        return undefined;
    },
    createRootContentPermission: (val: unknown) => {
        const enumValues = values(CreateRootContentPermissionTypeEnum);
        if (
            !find(
                (item) => item === true,
                map((type) => type === val, enumValues),
            )
        ) {
            // eslint-disable-next-line max-len
            return 'Pages, createRootContentPermission: value must be either "STAFF", "PARTICIPANTS", "BOTH" or "ADMINS_ONLY"';
        }
    },
    participantDeleteContentPermission: (val: unknown) => {
        const enumValues = values(ParticipantDeleteContentPermissionTypeEnum);
        if (
            !find(
                (item) => item === true,
                map((type) => type === val, enumValues),
            )
        ) {
            // eslint-disable-next-line max-len
            return 'Pages, participantDeleteContentPermission: value must be either "ALL", "CREATED" or "NONE"';
        }
    },
    showItemsAsIndividualPages: (val: unknown) => {
        if (typeof val !== 'boolean') {
            return 'Pages, showItemsAsIndividualPages: value must be either "YES" or empty';
        }
        return undefined;
    },
};

const checkPageTemplateExists = (templateNames: string[], templates: PageTemplate[]): string[] =>
    reduce<string, string[]>(
        (acc, templateName) => {
            if (!find((template) => toLower(trim(template.name)) === toLower(trim(templateName)), templates)) {
                return [
                    ...acc,
                    // eslint-disable-next-line max-len
                    `Pages, templateName: page template with name '${templateName}' could not be found. Please make sure this page template already exists in the application`,
                ];
            }
            return acc;
        },
        [],
        templateNames,
    );

const getErrorsForPagesSheet = (
    pagesData: Record<string, unknown>[],
    templates: Record<string, PageTemplate>,
): string[] => {
    const fieldsToValidate = keys(pageValidationTransformations);
    const errorMap = map(
        (pageData) =>
            evolve(
                pageValidationTransformations,
                pickAll<Record<string, unknown>, Record<string, unknown[]>>(fieldsToValidate, pageData),
            ),
        pagesData,
    );
    const errorMessages = reject(isNil, flatten(map(values, errorMap))) as string[];
    const templateNamesAggregation = uniq(
        pluck<string, Record<string, unknown>>('templateName', pagesData),
    ) as string[];
    return [...errorMessages, ...checkPageTemplateExists(templateNamesAggregation, values(templates))];
};

export default getErrorsForPagesSheet;
