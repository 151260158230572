import { Team } from 'polpeo-go-common/types';
import { values } from 'ramda';
import { OptionItem } from '../components/bits/FormFields';

interface GetTeamOptionItemsProps {
    currentSimulationTeams: Record<string, Team>;
    allowAll?: boolean;
    allowNone?: boolean;
}

export const makeTeamOptionItems = (teams: Team[]): OptionItem[] => {
    return [...teams]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((team) => ({ value: team.uuid, label: team.name }));
};

const getTeamOptionItems = ({
    currentSimulationTeams,
    allowAll = false,
    allowNone = false,
}: GetTeamOptionItemsProps): OptionItem[] => {
    const teamOptionItems = makeTeamOptionItems(values(currentSimulationTeams));

    if (allowAll) {
        teamOptionItems.unshift({ value: 'ALL', label: 'All' });
    }

    if (allowNone) {
        teamOptionItems.unshift({ value: 'NONE', label: 'None' });
    }

    return teamOptionItems;
};

export default getTeamOptionItems;
