import gql from 'graphql-tag';
import wrapQuery from '../wrapQuery';

interface IsLobbyOpenParams {
    simulationCode: string;
}

interface IsLobbyOpenData {
    isLobbyOpen: boolean;
}

const query = gql`
    query isLobbyOpen($simulationCode: String!) {
        isLobbyOpen(simulationCode: $simulationCode)
    }
`;

export const isLobbyOpen = wrapQuery<IsLobbyOpenParams, IsLobbyOpenData>(query);
