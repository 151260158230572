import React, { FC, useContext } from 'react';
import AuthContext from '../WithAuth/AuthContext';
import { StaffUserContext } from '../WithStaffUser';
import { UserMenu } from './UserMenu';
import styled from 'styled-components/macro';
import { MenuItem } from './MenuButton';
import { notificationColour } from '../../themes/colours';

const NotificationMenuItem = styled(MenuItem)`
    color: ${notificationColour};
`;

export const StaffUserMenu: FC = () => {
    const { signOutAuthenticatedUser } = useContext(AuthContext);
    const staffUser = useContext(StaffUserContext);

    return (
        <>
            <UserMenu name={staffUser.name}>
                <NotificationMenuItem onClick={() => signOutAuthenticatedUser()}>Sign Out</NotificationMenuItem>
            </UserMenu>
        </>
    );
};
