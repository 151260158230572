import blogTemplate, { dummyContentItems as blogItems } from './blog';
import chatTemplate, { dummyContentItems as chatItems } from './chat';
import facebookTemplate, { dummyContentItems as facebookItems } from './facebook';
import {
    pageTemplateWithInteractions as forumWithInteractionsTemplate,
    pageTemplateWithoutInteractions as forumWithoutInteractionsTemplate,
    dummyContentItems as forumItems,
} from './forum';
import instagramTemplate, { dummyContentItems as instagramItems } from './instagram';
import linkedInTemplate, { dummyContentItems as linkedInItems } from './linkedIn';
import {
    pageTemplateWithChildren as newsWithChildrenTemplate,
    pageTemplateWithoutChildren as newsWithoutChildrenTemplate,
    dummyContentItems as newsItems,
} from './news';
import reviewTemplate, { dummyContentItems as reviewItems } from './review';
import {
    pageTemplateWithChildren as webpageWithChildrenTemplate,
    pageTemplateWithoutChildren as webpageWithoutChildrenTemplate,
    dummyContentItems as webpageItems,
} from './webpage';
import videoSharingTemplate, { dummyContentItems as videoSharingItems } from './youtube';
import {
    dummyContentItems as twitterItems,
    pageTemplateWithoutMaxLength as weiboTemplate,
    pageTemplateWithMaxLength as twitterTemplate,
} from './twitter';

export const pageTemplates = {
    [blogTemplate.uuid]: blogTemplate,
    [chatTemplate.uuid]: chatTemplate,
    [facebookTemplate.uuid]: facebookTemplate,
    [forumWithInteractionsTemplate.uuid]: forumWithInteractionsTemplate,
    [forumWithoutInteractionsTemplate.uuid]: forumWithoutInteractionsTemplate,
    [instagramTemplate.uuid]: instagramTemplate,
    [linkedInTemplate.uuid]: linkedInTemplate,
    [newsWithChildrenTemplate.uuid]: newsWithChildrenTemplate,
    [newsWithoutChildrenTemplate.uuid]: newsWithoutChildrenTemplate,
    [reviewTemplate.uuid]: reviewTemplate,
    [twitterTemplate.uuid]: twitterTemplate,
    [weiboTemplate.uuid]: weiboTemplate,
    [webpageWithChildrenTemplate.uuid]: webpageWithChildrenTemplate,
    [webpageWithoutChildrenTemplate.uuid]: webpageWithoutChildrenTemplate,
    [videoSharingTemplate.uuid]: videoSharingTemplate,
};

export const dummyData = {
    [blogTemplate.uuid]: blogItems,
    [chatTemplate.uuid]: chatItems,
    [facebookTemplate.uuid]: facebookItems,
    [forumWithInteractionsTemplate.uuid]: forumItems,
    [forumWithoutInteractionsTemplate.uuid]: forumItems,
    [instagramTemplate.uuid]: instagramItems,
    [linkedInTemplate.uuid]: linkedInItems,
    [newsWithChildrenTemplate.uuid]: newsItems,
    [newsWithoutChildrenTemplate.uuid]: newsItems,
    [reviewTemplate.uuid]: reviewItems,
    [twitterTemplate.uuid]: twitterItems,
    [weiboTemplate.uuid]: twitterItems,
    [webpageWithChildrenTemplate.uuid]: webpageItems,
    [webpageWithoutChildrenTemplate.uuid]: webpageItems,
    [videoSharingTemplate.uuid]: videoSharingItems,
};

export const dummyUsers: Record<string, Record<string, string>> = {
    mary: { fullName: 'Mary Jane', email: 'maryJane@hotmail.com' },
    marie: { fullName: 'Marie Bond', email: 'bon1980@gmail.com' },
    donald: { fullName: 'Donald paster', email: 'donald@hotmail.com' },
    mike: { fullName: 'Mike Chez', email: 'chezman@gmail.com' },
};
