import { Page } from 'polpeo-go-common/types/Page';
import React, { FC, useState } from 'react';
import { PrimaryButton } from '../../../../bits/Buttons';
import { Modal } from '../../../../patterns/Modal';
import { ManagePageStatsForm } from '../../../PageStats/ManagePageStatsForm';

type PageFormFields = Pick<Page, 'stats'>;
interface EditPageStatsButtonProps {
    page: PageFormFields;
    onChange: (page: PageFormFields) => void;
    disabled?: boolean;
}
export const EditPageStatsButton: FC<EditPageStatsButtonProps> = ({ page, onChange, disabled }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <PrimaryButton disabled={disabled} onClick={() => setIsModalOpen(true)}>
                Edit Page Stats
            </PrimaryButton>
            {isModalOpen && (
                <Modal header="Edit Page Stats" onModalClose={() => setIsModalOpen(false)}>
                    <ManagePageStatsForm
                        onCancel={() => setIsModalOpen(false)}
                        stats={page.stats}
                        onSave={(newStats) => {
                            onChange({ ...page, stats: newStats });
                            setIsModalOpen(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
};
