import { BasicSimulation, Simulation } from 'polpeo-go-common/types/Simulation';
import { fromPairs, map, toPairs } from 'ramda';
import { Client } from '../../../../../../graphql/client';
import { getSignedPutUrlForPageDressingUpload } from '../../../../../../graphql/pages';
import { getSignedPutUrlForContentUpload } from '../../../../../../graphql/prepreparedContent';
import { getSignedPutUrlForTeamOverviewUpload } from '../../../../../../graphql/teams';
import { decodeBasicSimulation } from '../../../../../../utils/decodeBasicSimulation';

export const doUploadTeamOverviewImages = async (
    client: Client,
    context: { simulation: Simulation; images: Record<string, File> },
): Promise<Record<string, string>> => {
    const { simulation, images } = context;
    const allUploadImagesResponse = await Promise.all(
        map(async ([image, imageFile]): Promise<[string, string]> => {
            const putUrl = await getSignedPutUrlForTeamOverviewUpload.promise(client, {
                simulation,
                file: { name: imageFile.name, type: imageFile.type, size: undefined },
            });
            if (!putUrl.data) {
                throw new Error('Could not create signed put url for image');
            }
            const putCVRequestOptions: RequestInit = {
                method: 'PUT',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': imageFile.type,
                },
                body: imageFile,
            };
            const resultData = putUrl.data.getSignedPutUrlForTeamOverviewUpload;
            await fetch(resultData.signedPutUrl, putCVRequestOptions);
            return [image, resultData.path];
        }, toPairs(images)),
    );

    return fromPairs(allUploadImagesResponse);
};

export const doUploadPrepreparedContentImages = async (
    client: Client,
    context: { simulation: BasicSimulation | Simulation; images: Record<string, File> },
): Promise<Record<string, string>> => {
    const { simulation, images } = context;
    const allUploadImagesResponse = await Promise.all(
        map(async ([image, imageFile]): Promise<[string, string]> => {
            const putUrl = await getSignedPutUrlForContentUpload.promise(client, {
                simulation: decodeBasicSimulation(simulation),
                file: { name: imageFile.name, type: imageFile.type, size: undefined },
            });
            if (!putUrl.data) {
                throw new Error('Could not create signed put url for image');
            }
            const putCVRequestOptions: RequestInit = {
                method: 'PUT',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': imageFile.type,
                },
                body: imageFile,
            };
            const resultData = putUrl.data.getSignedPutUrlForContentUpload;
            await fetch(resultData.signedPutUrl, putCVRequestOptions);
            return [image, resultData.path];
        }, toPairs(images)),
    );

    return fromPairs(allUploadImagesResponse);
};

export const doUploadPagesImages = async (
    client: Client,
    context: { simulation: Simulation; images: Record<string, File> },
): Promise<Record<string, string>> => {
    const { simulation, images } = context;
    const allUploadImagesResponse = await Promise.all(
        map(async ([image, imageFile]): Promise<[string, string]> => {
            const putUrl = await getSignedPutUrlForPageDressingUpload.promise(client, {
                simulation,
                file: { name: imageFile.name, type: imageFile.type, size: undefined },
            });
            if (!putUrl.data) {
                throw new Error('Could not create signed put url for image');
            }
            const putCVRequestOptions: RequestInit = {
                method: 'PUT',
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': imageFile.type,
                },
                body: imageFile,
            };
            const resultData = putUrl.data.getSignedPutUrlForPageDressingUpload;
            await fetch(resultData.signedPutUrl, putCVRequestOptions);
            return [image, resultData.path];
        }, toPairs(images)),
    );

    return fromPairs(allUploadImagesResponse);
};
