import { DateTime } from 'luxon';
import {
    EmailItem,
    participantsAllTeamIdentifierToDisplay,
    staffAllTeamIdentifierToDisplay,
} from 'polpeo-go-common/types/EmailItem';
import { descend, join, map, pipe, replace, sort } from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, primaryColour, whiteColour } from '../../../themes/colours';
import { dateTimestampRelativeToSimStart } from '../../../utils/dateTimestampRelativeToSimStart';
import { getEmailItemTeamName } from '../../../utils/getEmailItemTeamName';
import { richtextToString } from '../../../utils/richtextToString';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { CardBody, CardSection } from '../../bits/Card';
import { Display } from '../../bits/Display';
import { Body1, Body3 } from '../../bits/Headers';
import { Lozenge } from '../../bits/Lozenge';
import { FABMenu, FABMenuProps } from '../../patterns/FAB/FABMenu';
import { EmailDetailsCardBody, EmailItemOptions } from './EmailDetailsCardBody';

const EmailMenuBody = styled.div`
    display: flex;
    flex-direction: row;
    height: 50%;

    flex: 1 1 auto;
`;

const EmailListScrollPane = styled.div`
    width: 500px;

    flex: 0 0 auto;

    overflow: auto;
`;
const EmailListPane = styled(CardBody)`
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
`;
const EmailFilters = styled(CardSection)`
    position: sticky;
    top: 0;
`;
const EmailItemButton = styled(CardSection).attrs(() => ({ as: 'button' }))<{ selected?: boolean }>`
    ${(props) => (props.selected ? `background: ${grey1Colour};` : '')}
    :hover {
        box-shadow: 0 0 7px rgb(0 0 0 / 0.1);
    }
`;

const EmailSubjectLine = styled(Body1)`
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
`;
const EmailMessageExcerpt = EmailSubjectLine;

const EmailBodyScrollPane = styled.div`
    display: flex;
    flex: 1 1 auto;

    overflow: auto;
`;

const EmailAddressLine = styled(Body1)`
    width: 100%;
    overflow-x: hidden;
    overflow-wrap: normal;
    text-overflow: ellipsis;
    text-align: left;
`;

const EmailTimestamp = styled(Body3)`
    white-space: nowrap;
`;

// strip out all tags and put everything on one line (the UI can truncate when it feels like it)
const makeEmailMessagePreview = pipe(richtextToString, replace('\n', ' '));

interface EmailListItemProps {
    isSelected?: boolean;
    email: EmailItem;
    onClick: (email: EmailItem) => void;
    emailFilter: string;
    emailItemOptions?: EmailItemOptions;
}
const EmailListItem: FC<EmailListItemProps> = ({
    isSelected,
    email,
    onClick,
    emailFilter,
    emailItemOptions: { useTimestampRelativeToSimulationStart } = {},
}) => {
    const adminInSimulationData = useContext(AdminInSimulationStateContext);
    const participantInSimulationData = useContext(ParticipantStateContext);
    const { teams } = useContext(AdminStateContext);
    const participants = adminInSimulationData?.participants || participantInSimulationData?.participants;
    const createdAtTimestamp = useMemo(() => {
        const createdAt = DateTime.fromJSDate(email.createdAt);
        if (!useTimestampRelativeToSimulationStart || !useTimestampRelativeToSimulationStart.startedAt) {
            return createdAt.toRelative();
        }
        return dateTimestampRelativeToSimStart(useTimestampRelativeToSimulationStart, email.createdAt);
    }, [email, useTimestampRelativeToSimulationStart]);

    const isDeleted = 'deleted' in email && email.deleted;

    return (
        <EmailItemButton selected={isSelected} onClick={() => onClick(email)}>
            <Display.VerticalWithSpacing gap={4}>
                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                    {emailFilter === 'Inbox' ? (
                        <EmailAddressLine>
                            {email.createdBy.type === 'PARTICIPANT'
                                ? participants[email.createdBy.uuid]?.fullName
                                : email.persona}
                        </EmailAddressLine>
                    ) : (
                        <EmailAddressLine>
                            {join(
                                ', ',
                                map(
                                    (recipientId) =>
                                        participants[recipientId]?.fullName ||
                                        (adminInSimulationData.simulation
                                            ? staffAllTeamIdentifierToDisplay(
                                                  recipientId,
                                                  getEmailItemTeamName(
                                                      teams[adminInSimulationData.simulation.uuid],
                                                      email,
                                                  ) || 'All Teams',
                                              )
                                            : participantsAllTeamIdentifierToDisplay(recipientId)) ||
                                        recipientId,
                                    email.content.recipients,
                                ),
                            )}
                        </EmailAddressLine>
                    )}
                    <EmailTimestamp>{createdAtTimestamp}</EmailTimestamp>
                </Display.HorizontalWithMaxSpaceBetween>
                {!isDeleted && <EmailSubjectLine>{email.content.subject || '(no subject)'}</EmailSubjectLine>}
                <EmailMessageExcerpt>
                    {!isDeleted ? makeEmailMessagePreview(email.content.message) : 'This email has been deleted'}
                </EmailMessageExcerpt>
            </Display.VerticalWithSpacing>
        </EmailItemButton>
    );
};

interface EmailFABMenuProps extends FABMenuProps {
    onClose?: () => void;
    threads: Array<EmailItem[]>;
    emailUUIDToThreadMap: Record<string, number>;
    inboxEmails: EmailItem[];
    outboxEmails: EmailItem[];
    emailItemOptions?: EmailItemOptions;
    disableCreateEmailItems?: boolean;
    selectedEmail?: EmailItem;
    setSelectedEmail: React.Dispatch<React.SetStateAction<EmailItem | undefined>>;
    currentParticipantInbox?: string;
}
export const EmailFABMenu: FC<EmailFABMenuProps> = ({
    menuPosition,
    tailPosition,
    onClose,
    threads,
    emailUUIDToThreadMap,
    inboxEmails,
    selectedEmail,
    setSelectedEmail,
    outboxEmails,
    emailItemOptions,
    disableCreateEmailItems,
    currentParticipantInbox,
    children,
}) => {
    const adminInSimulationData = useContext(AdminInSimulationStateContext);
    const [emailFilter, setEmailFilter] = useState<'Inbox' | 'Sent'>(!!selectedEmail ? 'Sent' : 'Inbox');

    const userEmailItems = emailFilter === 'Inbox' ? inboxEmails : outboxEmails;

    const selectedThread = useMemo(() => {
        if (!selectedEmail) {
            return undefined;
        }
        const threadIndex = emailUUIDToThreadMap[selectedEmail.uuid];
        return threads[threadIndex];
    }, [selectedEmail, threads]);

    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const closeFABMenu = (e: MouseEvent) => {
            const target = e.target as Element;
            const isFileInput = target.getAttribute('type') === 'file';
            if (menuRef.current && !isFileInput && onClose) {
                const targetIsOutsideContainer = !menuRef.current.contains(target);
                if (targetIsOutsideContainer) {
                    onClose();
                }
            }
        };
        document.addEventListener('click', closeFABMenu);
        return () => {
            document.removeEventListener('click', closeFABMenu);
        };
    }, []);

    const setSelectedEmailWithEmailFilterAndCurrentParticipantInbox = useCallback(
        (email: EmailItem, currentParticipantInbox?: string) => {
            const threadIndex = emailUUIDToThreadMap[email.uuid];
            const sortedThread = sort(
                descend((email) => email.createdAt.toISOString()),
                threads[threadIndex],
            );
            if (emailFilter === 'Sent') {
                const latestEmail = currentParticipantInbox
                    ? sortedThread.find((email) => email.createdBy.uuid === currentParticipantInbox)
                    : sortedThread[0];
                setSelectedEmail(latestEmail);
            } else {
                setSelectedEmail(sortedThread[0]);
            }
        },
        [threads, emailUUIDToThreadMap],
    );

    const emailListItemIsSelected = useCallback(
        (email: EmailItem) => {
            const thread = threads[emailUUIDToThreadMap[email.uuid]];
            return !!thread.find((email: EmailItem) => selectedEmail?.uuid === email.uuid);
        },
        [threads, emailUUIDToThreadMap, selectedEmail],
    );

    return (
        <FABMenu
            ref={menuRef}
            menuPosition={menuPosition}
            tailPosition={tailPosition}
            cardWidth={!selectedEmail ? 500 : '85%'}
        >
            {children}
            <EmailMenuBody>
                <EmailListScrollPane>
                    <EmailListPane>
                        <EmailFilters>
                            <Display.HorizontalWithSpacing gap={10}>
                                <Lozenge
                                    as="button"
                                    onClick={() => setEmailFilter('Inbox')}
                                    fill={emailFilter === 'Inbox' ? primaryColour : ''}
                                    color={emailFilter === 'Inbox' ? whiteColour : ''}
                                >
                                    Inbox
                                </Lozenge>
                                <Lozenge
                                    as="button"
                                    onClick={() => setEmailFilter('Sent')}
                                    fill={emailFilter === 'Sent' ? primaryColour : ''}
                                    color={emailFilter === 'Sent' ? whiteColour : ''}
                                >
                                    Sent
                                </Lozenge>
                            </Display.HorizontalWithSpacing>
                        </EmailFilters>
                        {map(
                            (email) => (
                                <EmailListItem
                                    isSelected={emailListItemIsSelected(email)}
                                    key={email.uuid}
                                    email={email}
                                    emailItemOptions={emailItemOptions}
                                    emailFilter={emailFilter}
                                    onClick={(email) => {
                                        setSelectedEmailWithEmailFilterAndCurrentParticipantInbox(
                                            email,
                                            currentParticipantInbox,
                                        );
                                    }}
                                />
                            ),
                            userEmailItems,
                        )}
                        {!userEmailItems.length && (
                            <CardBody>
                                <Body1>No emails found</Body1>
                            </CardBody>
                        )}
                        <CardSection />
                    </EmailListPane>
                </EmailListScrollPane>
                {selectedThread && (
                    <EmailBodyScrollPane>
                        <EmailDetailsCardBody
                            selectedEmail={selectedEmail}
                            thread={selectedThread}
                            emailItemOptions={{
                                ...emailItemOptions,
                                hideCreatedByIcon: !adminInSimulationData.simulation,
                            }}
                            disableCreateEmailItems={disableCreateEmailItems}
                        />
                    </EmailBodyScrollPane>
                )}
            </EmailMenuBody>
        </FABMenu>
    );
};
