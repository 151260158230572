import { NewPrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { any, assocPath, includes, map, toPairs, without } from 'ramda';
import React from 'react';
import { Checkbox, Dropdown, OptionItem, TextInput } from '../../../../../bits/FormFields';
import { StyledErrorMessage } from '../../../../../bits/Headers';
import { InteractionsField } from '../../../../../patterns/ContentItem/InteractionsField';
import { FormGridField } from '../../../../FormGrid';
import { Display } from '../../../../../bits/Display';

export function makeSetupTabFormFields<T extends NewPrepreparedContentItem | PrepreparedContentItem>(
    pageOptionItems: OptionItem[],
    triggerOptionItems: OptionItem[],
    teamOptionItems: OptionItem[],
    currentPrepreparedContent: T,
    setCurrentPrepreparedContent: (value: T | ((item: T) => T)) => void,
    currentLinkedToTrigger: string,
    setCurrentLinkedToTrigger: (triggerUUID: string) => void,
    currentTemplate: undefined | PageTemplate,
    errors: Record<string, string>,
): FormGridField[] {
    const currentTeamUUIDs = currentPrepreparedContent.teamUUIDs || [];

    const baseFields: FormGridField[] = [
        [
            'Content Type',
            <Dropdown
                key="Content Type"
                id="Content Type"
                options={pageOptionItems}
                onChange={(e) => {
                    const itemWithUpdatedType = assocPath(
                        ['content', 'pageUUID'],
                        e.target.value,
                        currentPrepreparedContent,
                    );
                    const itemWithCleanData = assocPath(['content', 'data'], {}, itemWithUpdatedType);
                    setCurrentPrepreparedContent(itemWithCleanData);
                }}
                value={currentPrepreparedContent.content.pageUUID}
                disabled={!!currentPrepreparedContent.parentUUID || 'uuid' in currentPrepreparedContent}
            />,
        ],
        [
            'Trigger',
            <Dropdown
                key="Content Trigger"
                id="Content Trigger"
                options={triggerOptionItems}
                onChange={(e) => setCurrentLinkedToTrigger(e.target.value)}
                value={currentLinkedToTrigger}
            />,
        ],
        [
            'Persona',
            <>
                <TextInput
                    key="Persona"
                    onChange={(e) =>
                        setCurrentPrepreparedContent(assocPath(['persona'], e.target.value, currentPrepreparedContent))
                    }
                    value={currentPrepreparedContent.persona}
                />
                {errors['persona'] && <StyledErrorMessage>{errors['persona']}</StyledErrorMessage>}
            </>,
        ],
    ];

    if (!currentPrepreparedContent.parentUUID) {
        baseFields.push([
            'Team Assignment',
            <Display.VerticalWithSpacing key="Team Assignment" gap={10} horizontalAlign="start">
                {map(
                    (team) => (
                        <Checkbox
                            id={team.value}
                            key={team.value}
                            checked={
                                includes(team.value, currentTeamUUIDs) ||
                                (team.value === 'ALL' && !currentTeamUUIDs.length)
                            }
                            onChange={(e) => {
                                const nowChecked = e.target.checked;
                                let teamUUIDs = [];
                                if (nowChecked) {
                                    teamUUIDs = team.value === 'ALL' ? [] : [...currentTeamUUIDs, team.value];
                                } else {
                                    teamUUIDs = without([team.value], currentTeamUUIDs);
                                }
                                setCurrentPrepreparedContent(
                                    assocPath(['teamUUIDs'], teamUUIDs, currentPrepreparedContent),
                                );
                            }}
                            disabled={!!currentPrepreparedContent.parentUUID}
                        >
                            {team.label}
                        </Checkbox>
                    ),
                    teamOptionItems,
                )}
            </Display.VerticalWithSpacing>,
        ]);
    }

    const interactions = currentPrepreparedContent.parentUUID
        ? currentTemplate?.childItemOptions.interactions
        : currentTemplate?.rootItemOptions.interactions;
    if (
        !currentTemplate ||
        !interactions ||
        !any(([, interactionSettings]) => interactionSettings.enabled, toPairs(interactions))
    ) {
        return baseFields;
    }

    return [
        ...baseFields,
        [
            'Interactions',
            <InteractionsField
                key="Interactions"
                interactionFields={interactions}
                value={currentPrepreparedContent.content.interactions}
                onChange={(newInteractions) =>
                    setCurrentPrepreparedContent(assocPath(['content', 'interactions'], newInteractions))
                }
            />,
        ],
    ];
}
