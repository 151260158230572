import { DateTime } from 'luxon';
import { ContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { ContentItemThemingOptions } from 'polpeo-go-common/types/PageTemplate/ContentItemThemingOptions';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { find, includes, map, omit, toLower, uniq, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components/macro';
import { body2 } from '../../../themes/cssSnippets';
import { dateTimestampRelativeToSimStart } from '../../../utils/dateTimestampRelativeToSimStart';
import { getCreatorForItem } from '../../../utils/getCreatorForItem';
import { getDescendentsForItem } from '../../../utils/getDescendentsForItem';
import { Display } from '../../bits/Display';
import { Icon } from '../../bits/Icon';
import { RichtextDisplay } from '../../bits/RichtextDisplay';
import { NonInteractiveComponentHelpModal } from '../../patterns/ContentItem/NonInteractiveComponentHelpModal';
import { Identicon } from '../../patterns/Identicon';
import { VideoEmbed } from '../../patterns/VideoEmbed';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { StaffUserContext } from '../../WithStaffUser';
import { ContentItemMomentsButton } from '../pages/StaffUserSimulation/ContentItemMomentsButton';
import { ManageContentItemButton } from './ManageContentItemButton';
import { MetadataRowPositioner } from './MetadataRowPositioner';
import { dummyUsers } from './templates';
import { UserDisplayName } from './UserDisplayName';
import { BaseDiv, BaseDivProps, baseThemingSnippet, BaseThemingSnippetProps } from './utils';
import AssignedTeamsLabel from '../../patterns/ContentItem/AssignedTeamsLabel';

const FieldList = styled.div`
    max-width: 100%;
    min-width: 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    img {
        object-fit: cover;
    }
`;

const ContentItemContainer = styled(BaseDiv)<BaseDivProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ContentItemInteractionsContainer = styled(BaseDiv)`
    display: grid;
    grid-auto-flow: column;
    justify-content: ${({ theme: { layout } }) => layout || 'start'};
    align-items: center;
    column-gap: ${({ theme: { horizontalGap } }) => (horizontalGap ? `${horizontalGap}px` : 0)};

    > div {
        display: flex;
        align-items: center;
        font-weight: inherit;
        font-size: inherit;
    }
    img {
        height: ${({ theme: { iconHeight } }) => (iconHeight ? `${iconHeight}px` : '20px')};
        width: auto;
        padding-right: 5px;
    }
`;

const Gutter = styled.div`
    margin-right: 10px;
`;

const ThemedRichText = styled(RichtextDisplay)<BaseThemingSnippetProps>`
    ${baseThemingSnippet}
`;

const ThemedVideoEmbed = styled(VideoEmbed).attrs(({ theme, ...props }) => ({
    ...props,
    theme: omit(['width', 'height'], theme),
}))<BaseThemingSnippetProps>`
    ${baseThemingSnippet}
`;

const TimeStamp = styled.div`
    display: flex;
    ${body2}
    font-size: 13px;
    gap: 5px;
    align-items: center;
    white-space: nowrap;
`;

const Avatar = styled(Identicon).attrs<BaseThemingSnippetProps>(({ theme, ...props }) => ({
    ...props,
    theme,
    size: theme?.width || theme?.height,
}))`
    ${baseThemingSnippet}
    flex: 0 0 auto;
`;
const MetadataRow = styled(BaseDiv)`
    max-width: 100%;
    display: flex;
    column-gap: 8px;
    align-items: center;
    padding: ${({ theme: { creator: { padding } = { padding: undefined } } }) =>
        padding ? `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px` : 0};

    > ${Avatar} {
        margin-right: 5px;
    }

    > ${Display.HorizontalWithSpacing} {
        flex: 0 0 auto;
    }
`;

const ContentField = styled(BaseDiv)`
    white-space: pre-line;
    overflow-wrap: anywhere;
`;

interface ContentItemDetailsLinkWrapperProps {
    item: ContentItem | PrepreparedContentItem;
    detailsLink?: boolean;
    alignSelf?: 'stretch' | 'start' | 'center' | 'end' | undefined;
}

const ThemedLink = styled(Link)`
    ${baseThemingSnippet}
`;

const ContentItemDetailsLinkWrapper: FC<ContentItemDetailsLinkWrapperProps> = ({
    item,
    detailsLink,
    children,
    alignSelf,
}) => {
    if (detailsLink) {
        return (
            <ThemedLink theme={{ alignSelf }} to={({ pathname }) => `${pathname}/${item.uuid}`}>
                {children}
            </ThemedLink>
        );
    } else return <>{children}</>;
};
export interface ContentItemOptions {
    disableDetailsLink?: boolean;
    disableManageMenu?: boolean;
    hideCreatedByIcon?: boolean;
    hideInteractions?: boolean;
    noAvatar?: boolean;
    forceInlineMeta?: boolean;
    imagePlaceholder?: boolean;
    videoPlaceholder?: boolean;
    maxCharacterLimit?: number;
    useTimestampRelativeToSimulationStart?: Simulation;
    showAssignedTeams?: boolean;
}
interface ContentItemProps {
    item: ContentItem | PrepreparedContentItem;
    template: PageTemplate;
    theme: ContentItemThemingOptions;
    fields: ContentItemField[];
    fieldOrder: string[];
    options?: ContentItemOptions;
    extraCSS?: CSSProp;
    isGrid?: boolean;
}
export const ContentItemComponent: FC<ContentItemProps> = ({
    item,
    template,
    theme,
    fields,
    fieldOrder,
    options: {
        disableDetailsLink,
        disableManageMenu,
        hideCreatedByIcon,
        hideInteractions,
        noAvatar,
        forceInlineMeta,
        imagePlaceholder,
        videoPlaceholder,
        maxCharacterLimit,
        useTimestampRelativeToSimulationStart,
        showAssignedTeams,
    } = {},
    extraCSS,
    children,
    isGrid,
}) => {
    const staffUser = useContext(StaffUserContext);
    const adminData = useContext(AdminInSimulationStateContext);
    const participantData = useContext(ParticipantStateContext);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const { simulationContent } = adminData;
    const { contentItems } = participantData;

    const teamUUID = 'teamUUID' in item ? item.teamUUID : '';
    const contentData = simulationContent?.contentItems[teamUUID] || contentItems;

    const creator = getCreatorForItem(item, {
        participants: adminData.participants || participantData.participants || dummyUsers,
    });

    const createdAtTimestamp = useMemo(() => {
        const createdAt = DateTime.fromJSDate('createdAt' in item ? item.createdAt : new Date());
        if (
            !useTimestampRelativeToSimulationStart ||
            !useTimestampRelativeToSimulationStart.startedAt ||
            !('createdAt' in item)
        ) {
            return createdAt.toRelative();
        }
        return dateTimestampRelativeToSimStart(useTimestampRelativeToSimulationStart, item.createdAt);
    }, [item, useTimestampRelativeToSimulationStart]);

    const isDeleted = 'deleted' in item && item.deleted;
    const metaFieldsToDisplay = fieldOrder.filter((fieldName) => fieldName === 'meta');
    const fieldsToDisplay = !isDeleted
        ? fieldOrder.filter((fieldName) => fieldName !== 'meta')
        : uniq(
              fieldOrder
                  .filter((fieldName) => fieldName !== 'meta')
                  .map((fieldName) => (fieldName === 'interactions' ? fieldName : 'deletedPlaceholder')),
          );

    const contentItemChildrenCount = useMemo(() => {
        // We display content items in the page templates `Preview Listing Page` links, so
        // we don't always have access to the simulation data. Hence we need a check to make
        // sure it exists.
        if (contentData) {
            const descendants = getDescendentsForItem(values(contentData) as ContentItem[], item);
            return descendants.length;
        }
        return 0;
    }, [item, contentData]);

    const contentItemChildrenCountPosition = theme.showChildItemsCount?.position;

    const interactionFields = item.parentUUID
        ? template.childItemOptions.interactions
        : template.rootItemOptions.interactions;

    if (!creator) {
        return null;
    }

    const MetaAvatar = () => (
        <>
            {!noAvatar && theme.avatar?.position === 'gutter' && (
                <Gutter>
                    <Avatar creator={creator} theme={theme.avatar} />
                </Gutter>
            )}
        </>
    );

    const MetaContent = () => (
        <ContentItemDetailsLinkWrapper
            item={item}
            detailsLink={!disableDetailsLink && includes('meta', template.listingPage.detailsLinkFields)}
        >
            {showAssignedTeams && <AssignedTeamsLabel item={item as PrepreparedContentItem} />}
            <MetadataRowPositioner alignment={theme.meta.alignment}>
                <MetadataRow theme={theme.meta}>
                    {!noAvatar && theme.avatar?.position === 'inline' && (
                        <Avatar creator={creator} theme={theme.avatar} />
                    )}
                    <Display.VerticalWithSpacing gap={0}>
                        <UserDisplayName creator={creator} theme={theme.creator} />
                        {!forceInlineMeta && theme.timestamp !== 'inline' && (
                            <TimeStamp>
                                {createdAtTimestamp}
                                {!hideCreatedByIcon && 'createdBy' in item && (
                                    <Icon
                                        icon={
                                            item.createdBy.type === 'PARTICIPANT'
                                                ? 'createdByParticipant'
                                                : 'createdByPolpeo'
                                        }
                                    />
                                )}
                            </TimeStamp>
                        )}
                    </Display.VerticalWithSpacing>
                    {(forceInlineMeta || theme.timestamp === 'inline') && (
                        <TimeStamp>
                            {createdAtTimestamp}
                            {!hideCreatedByIcon && 'createdBy' in item && (
                                <Icon
                                    icon={
                                        item.createdBy.type === 'PARTICIPANT'
                                            ? 'createdByParticipant'
                                            : 'createdByPolpeo'
                                    }
                                />
                            )}
                        </TimeStamp>
                    )}
                    {!disableManageMenu && 'createdBy' in item && (
                        <Display.HorizontalWithSpacing gap={0}>
                            <ManageContentItemButton item={item} />
                            {staffUser && <ContentItemMomentsButton item={item} />}
                        </Display.HorizontalWithSpacing>
                    )}
                </MetadataRow>
            </MetadataRowPositioner>
        </ContentItemDetailsLinkWrapper>
    );

    return (
        <ContentItemDetailsLinkWrapper
            item={item}
            detailsLink={!disableDetailsLink && includes('all', template.listingPage.detailsLinkFields)}
        >
            <BaseDiv theme={theme} extraCSS={extraCSS}>
                {!isGrid && (
                    <ContentItemContainer id={`meta_${item.uuid}`}>
                        <MetaAvatar />
                        <FieldList>
                            {map((fieldName) => {
                                if (!creator) {
                                    return null;
                                }

                                return <MetaContent key={fieldName} />;
                            }, metaFieldsToDisplay)}
                        </FieldList>
                    </ContentItemContainer>
                )}
                <ContentItemContainer id={item.uuid}>
                    {isGrid && <MetaAvatar />}
                    <FieldList>
                        {map(
                            (fieldName) => {
                                if (fieldName === 'meta' && isGrid) {
                                    return <MetaContent />;
                                }
                                if (fieldName === 'interactions' && !hideInteractions) {
                                    return (
                                        <ContentItemDetailsLinkWrapper
                                            key={fieldName}
                                            item={item}
                                            detailsLink={
                                                !disableDetailsLink &&
                                                includes('interactions', template.listingPage.detailsLinkFields)
                                            }
                                        >
                                            <ContentItemInteractionsContainer
                                                onClick={() => setShowHelpModal(true)}
                                                theme={theme.interactions}
                                            >
                                                {interactionFields.positive.enabled && (
                                                    <div>
                                                        {!!interactionFields.positive.img && (
                                                            <img src={interactionFields.positive.img} />
                                                        )}
                                                        {item.content.interactions.positive || 0}{' '}
                                                        {interactionFields.positive.displayName}
                                                    </div>
                                                )}
                                                {interactionFields.negative.enabled && (
                                                    <div>
                                                        {!!interactionFields.negative.img && (
                                                            <img src={interactionFields.negative.img} />
                                                        )}
                                                        {item.content.interactions.negative || 0}{' '}
                                                        {interactionFields.negative.displayName}
                                                    </div>
                                                )}
                                                {interactionFields.shares.enabled && (
                                                    <div>
                                                        {!!interactionFields.shares.img && (
                                                            <img src={interactionFields.shares.img} />
                                                        )}
                                                        {item.content.interactions.shares || 0}{' '}
                                                        {interactionFields.shares.displayName}
                                                    </div>
                                                )}
                                                {contentItemChildrenCountPosition === 'inline with interactions' &&
                                                    theme.showChildItemsCount && (
                                                        <BaseDiv as="span" theme={theme.showChildItemsCount}>
                                                            {contentItemChildrenCount +
                                                                ' ' +
                                                                (contentItemChildrenCount === 1
                                                                    ? template.childItemOptions.name
                                                                    : template.childItemOptions.pluralName)}
                                                        </BaseDiv>
                                                    )}
                                            </ContentItemInteractionsContainer>
                                        </ContentItemDetailsLinkWrapper>
                                    );
                                }
                                if (
                                    fieldName === 'childrenCount' &&
                                    contentItemChildrenCountPosition === 'use field order'
                                ) {
                                    // TODO - this is a bit sad, ideally we only have one link / container
                                    // this whole map should probably get looked at / refactored
                                    return (
                                        <ContentItemDetailsLinkWrapper
                                            key={fieldName}
                                            item={item}
                                            alignSelf={theme.showChildItemsCount?.alignSelf}
                                            detailsLink={
                                                !disableDetailsLink &&
                                                includes(fieldName, template.listingPage.detailsLinkFields)
                                            }
                                        >
                                            <BaseDiv key={fieldName} theme={theme.showChildItemsCount}>
                                                {contentItemChildrenCount +
                                                    ' ' +
                                                    (contentItemChildrenCount === 1
                                                        ? template.childItemOptions.name
                                                        : template.childItemOptions.pluralName)}
                                            </BaseDiv>
                                        </ContentItemDetailsLinkWrapper>
                                    );
                                }
                                if (fieldName === 'deletedPlaceholder') {
                                    return (
                                        <BaseDiv key={fieldName} theme={theme.deletedPlaceholder}>{`This ${toLower(
                                            !item.parentUUID
                                                ? template.rootItemOptions.name
                                                : template.childItemOptions.name,
                                        )} has been deleted`}</BaseDiv>
                                    );
                                }
                                const fieldData = find((field) => field.name === fieldName, fields);
                                if (!fieldData || !item.content.data[fieldName]) {
                                    return null;
                                }

                                if (fieldData.type === 'IMAGE') {
                                    if (imagePlaceholder) {
                                        return <p key={fieldName}>[...image]</p>;
                                    }

                                    return (
                                        <ContentItemDetailsLinkWrapper
                                            key={fieldName}
                                            item={item}
                                            detailsLink={
                                                !disableDetailsLink &&
                                                includes(fieldName, template.listingPage.detailsLinkFields)
                                            }
                                        >
                                            <BaseDiv
                                                as="img"
                                                src={item.content.data[fieldName]}
                                                theme={theme.fields?.[fieldName] || {}}
                                            />
                                        </ContentItemDetailsLinkWrapper>
                                    );
                                }

                                if (fieldData.type === 'RICHTEXT') {
                                    return (
                                        <ContentItemDetailsLinkWrapper
                                            key={fieldName}
                                            item={item}
                                            detailsLink={
                                                !disableDetailsLink &&
                                                includes(fieldName, template.listingPage.detailsLinkFields)
                                            }
                                        >
                                            <ThemedRichText
                                                theme={theme.fields?.[fieldName] || {}}
                                                value={item.content.data[fieldName]}
                                            />
                                        </ContentItemDetailsLinkWrapper>
                                    );
                                }

                                if (fieldData.type === 'VIDEO') {
                                    if (videoPlaceholder) {
                                        return <p key={fieldName}>[...video]</p>;
                                    }
                                    return (
                                        <ThemedVideoEmbed
                                            key={fieldName}
                                            video={item.content.data[fieldName]}
                                            width={theme.fields?.[fieldName]?.width}
                                            theme={theme.fields?.[fieldName] || {}}
                                            square={theme.fields?.[fieldName]?.square}
                                        />
                                    );
                                }

                                const originalContentData = item.content.data[fieldName];
                                const contentData =
                                    maxCharacterLimit && originalContentData.length > maxCharacterLimit
                                        ? `${originalContentData?.substring(0, maxCharacterLimit)}...`
                                        : originalContentData;
                                return (
                                    <ContentItemDetailsLinkWrapper
                                        key={fieldName}
                                        item={item}
                                        detailsLink={
                                            !disableDetailsLink &&
                                            includes(fieldName, template.listingPage.detailsLinkFields)
                                        }
                                    >
                                        <ContentField theme={theme.fields?.[fieldName] || {}}>
                                            {contentData}
                                        </ContentField>
                                    </ContentItemDetailsLinkWrapper>
                                );
                            },
                            isGrid ? [...metaFieldsToDisplay, ...fieldsToDisplay] : fieldsToDisplay,
                        )}
                        {children}
                    </FieldList>
                </ContentItemContainer>
            </BaseDiv>
            {showHelpModal && <NonInteractiveComponentHelpModal onModalClose={() => setShowHelpModal(false)} />}
        </ContentItemDetailsLinkWrapper>
    );
};
