import React, { FC } from 'react';
import { ICON_MAP } from './iconMap';
import { IconSvgProps } from './IconSvg';

export type IconOptions = keyof typeof ICON_MAP;
export interface IconProps extends IconSvgProps {
    icon: IconOptions;
}
export const Icon: FC<IconProps> = ({ icon, ...svgProps }: IconProps) => {
    const IconSvg = ICON_MAP[icon];

    return <IconSvg {...svgProps} />;
};
