import { useApolloClient } from '@apollo/client';
import {
    InitialScratchPadDocument,
    NewInitialScratchpadDocument,
} from 'polpeo-go-common/types/InitialScratchPadDocument';
import { acceptedFileTypes, ScratchPadCategory } from 'polpeo-go-common/types/ScratchPadDocument';
import { difference, map, propEq, filter, findIndex } from 'ramda';
import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { createInitialScratchPadDocument } from '../../../../graphql/scratchPad';
import { getSignedPutUrlforInitialScratchPadDocumentUpload } from '../../../../graphql/scratchPad/';
import { SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { H4Heading } from '../../../bits/Headers';
import { Icon } from '../../../bits/Icon';
import { Spinner } from '../../../bits/Spinner';
import { CardButton } from '../../../patterns/Card';
import { Modal } from '../../../patterns/Modal';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { MultiLineError } from './AddDocumentModal';

const CardLinkHeading = styled(H4Heading)`
    text-align: center;
`;

interface AddInitialDocumentModalProps {
    onCloseModal: () => void;
    onNewRichtextScratchpadDocument: () => void;
    category: ScratchPadCategory;
}

export const AddInitialDocumentModal: FC<AddInitialDocumentModalProps> = ({
    onCloseModal,
    onNewRichtextScratchpadDocument,
    category,
}) => {
    const client = useApolloClient();
    const handleUploadFilesButtonClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };
    const { currentSimulation: initialSimulation } = useContext(AdminManageSimulationContext);
    const { setSimulation } = useContext(AdminStateContext);

    const [isFileUploading, setIsFileUploading] = useState(false);
    const [fileUploadError, setFileUploadError] = useState<string>();
    const [duplicateFiles, setDuplicateFiles] = useState<File[]>([]);
    const [uniqueDocuments, setUniqueDocuments] = useState<File[]>([]);

    useEffect(() => {
        if (fileUploadError && !duplicateFiles.length && uniqueDocuments.length === 1) {
            setIsFileUploading(false);
        }
        if (!fileUploadError && duplicateFiles.length > 0 && !uniqueDocuments.length) {
            setIsFileUploading(false);
        }
        if (fileUploadError && duplicateFiles.length === 1 && uniqueDocuments.length === 1) {
            setIsFileUploading(false);
        }
    }, [fileUploadError]);

    const resetErrorsDuplicates = () => {
        setDuplicateFiles([]);
        setFileUploadError('');
    };
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const uploadFilesAndCreateScratchPadDocuments = async (e: ChangeEvent) => {
        e.preventDefault();
        setIsFileUploading(true);
        const target = e.target as HTMLInputElement;
        const filesList = target.files as FileList;

        if (!filesList) {
            return;
        }
        const { scratchPadDocuments } = initialSimulation;

        const scratchPadDocumentsForCurrentModal = filter(
            (document: InitialScratchPadDocument) => document.category === category,
            scratchPadDocuments || [],
        );
        const filesArray = Array.from(filesList);
        const duplicateArray = filter(
            (doc: File) => findIndex(propEq('name', `${doc.name}`))(scratchPadDocumentsForCurrentModal) >= 0,
            filesArray,
        );

        setDuplicateFiles(duplicateArray);

        const uniqueDocuments = difference(filesArray, duplicateArray);

        if (!uniqueDocuments.length) {
            setIsFileUploading(false);
        } else {
            setUniqueDocuments(uniqueDocuments);
        }
        const documentsToSave: NewInitialScratchpadDocument[] = [];
        for (const fileToUpload of uniqueDocuments) {
            const putUrl = await getSignedPutUrlforInitialScratchPadDocumentUpload.promise(client, {
                simulation: initialSimulation,
                file: {
                    name: fileToUpload.name,
                    type: fileToUpload.type,
                    size: undefined,
                },
            });
            if (!putUrl) {
                throw new Error('Failed to create a signed url for this document');
            } else if (!putUrl.data) {
                throw new Error('No data for this request');
            }
            const putCVRequestOptions: RequestInit = {
                method: 'PUT',
                headers: {
                    'content-type': fileToUpload.type,
                },
                body: fileToUpload,
            };
            await fetch(
                putUrl.data.getSignedPutUrlforInitialScratchPadDocumentUpload.signedPutUrl,
                putCVRequestOptions,
            );
            const documentToSave = {
                name: fileToUpload.name,
                path: putUrl.data.getSignedPutUrlforInitialScratchPadDocumentUpload.path,
                notes: undefined,
                category: category,
                simulationUUID: initialSimulation.uuid,
            };
            documentsToSave.push(documentToSave);
        }
        if (uniqueDocuments.length) {
            const createInitialScratchPadDocumentData = await createInitialScratchPadDocument.promise(client, {
                scratchPadDocuments: documentsToSave,
            });
            if (!createInitialScratchPadDocumentData.data) throw new Error('Failed to create scratch pad document');
            const newInitialScratchPadDocuments =
                createInitialScratchPadDocumentData.data.createInitialScratchPadDocument;
            const simulationWithUpdatedScratchpad = {
                ...initialSimulation,
                scratchPadDocuments: newInitialScratchPadDocuments,
            };
            setSimulation(simulationWithUpdatedScratchpad);
            setIsFileUploading(false);
            if (!duplicateArray.length) {
                onCloseModal();
            }
        }
    };
    const showOkButton = fileUploadError || duplicateFiles.length > 0;

    return (
        <>
            <Modal header="Add Document" onModalClose={onCloseModal}>
                <Display.VerticalWithSpacing>
                    {isFileUploading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {fileUploadError && (
                        <Display.VerticalWithSpacing gap={10}>
                            <MultiLineError>{fileUploadError}</MultiLineError>
                            <div>
                                This may be due to an incorrect file type, max file name/size being exceeded or special
                                character restrictions
                            </div>
                            <div>Please make any necessary changes before trying to upload the document.</div>
                        </Display.VerticalWithSpacing>
                    )}
                    {duplicateFiles.length > 0 && (
                        <Display.VerticalWithSpacing>
                            {duplicateFiles.length === 1 ? (
                                <Display.VerticalWithSpacing>
                                    <b>
                                        {/* eslint-disable-next-line max-len */}
                                        {`File '${duplicateFiles[0].name}' already exists in '${category}'`}
                                    </b>
                                    <p>And has not been uploaded</p>
                                    <p>Please choose a different file</p>
                                    <p>Non-duplicate files (if any) have been uploaded</p>
                                </Display.VerticalWithSpacing>
                            ) : (
                                duplicateFiles.length > 1 && (
                                    <Display.VerticalWithSpacing>
                                        <p>{`The following files already exist in '${category}':`}</p>
                                        {map((file) => {
                                            return (
                                                <div key={file.name}>
                                                    <b>{file.name}</b>
                                                </div>
                                            );
                                        }, duplicateFiles)}
                                        <p>and have not been uploaded.</p>
                                        <p>Non-duplicate files (if any) have been uploaded</p>
                                    </Display.VerticalWithSpacing>
                                )
                            )}
                        </Display.VerticalWithSpacing>
                    )}
                    {!isFileUploading && duplicateFiles.length === 0 && !fileUploadError && (
                        <Display.HorizontalWithSpacing>
                            <CardButton onClick={() => handleUploadFilesButtonClick()} cardWidth={164}>
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="upload" size={55} />
                                    <CardLinkHeading>Upload a document</CardLinkHeading>
                                    <input
                                        style={{ display: 'none' }}
                                        onChange={(e) => uploadFilesAndCreateScratchPadDocuments(e)}
                                        type="file"
                                        ref={hiddenFileInput}
                                        multiple
                                        accept={acceptedFileTypes.join(',')}
                                    />
                                </Display.VerticalWithSpacing>
                            </CardButton>
                            <CardButton
                                onClick={() => {
                                    onNewRichtextScratchpadDocument();
                                    onCloseModal();
                                }}
                                cardWidth={164}
                            >
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="createNew" size={55} />
                                    <CardLinkHeading>Create a new document</CardLinkHeading>
                                </Display.VerticalWithSpacing>
                            </CardButton>
                        </Display.HorizontalWithSpacing>
                    )}
                    {!isFileUploading && duplicateFiles.length === 0 && !fileUploadError && (
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
                        </Display.HorizontalWithSpacing>
                    )}
                    {showOkButton && (
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={resetErrorsDuplicates}>Ok</SecondaryButton>
                        </Display.HorizontalWithSpacing>
                    )}
                </Display.VerticalWithSpacing>
            </Modal>
        </>
    );
};
