import styled from 'styled-components/macro';
import { Card } from '../../patterns/Card';
import splash from './Polpeo-Wave@3x.png';

export const SplashContainer = styled.div`
    background: url('${splash}');
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SplashHeroCard = styled(Card)`
    margin: 50px;
    width: 100%;
    max-width: 425px;

    padding: 20px 18px 10px;
`;
