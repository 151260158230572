/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { grey1Colour, grey2Colour } from '../../../../../themes/colours';
import image from './image.png';
import logo from './logo.png';

const pageTemplate: PageTemplate = {
    uuid: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
    name: 'Image Network',
    category: 'Social Media',
    icon: logo,
    rootItemOptions: {
        name: 'Post',
        requiredFields: ['post', ['image', 'video']],
        fields: [
            { name: 'post', type: ContentItemFieldType.LONG_TEXT },
            { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childItemOptions: {
        name: 'Comment',
        pluralName: 'Comments',
        requiredFields: ['comment'],
        fields: [{ name: 'comment', type: ContentItemFieldType.LONG_TEXT }],
        interactions: {
            positive: {
                enabled: true,
                displayName: 'likes',
                img: undefined,
            },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 2 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.GRID, columns: 3 },
        childrenSettings: {},
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['image', 'video', 'childrenCount'],
        childContentFieldOrder: [],
        detailsLinkFields: ['image', 'childrenCount'],
        theme: {
            page: {
                bgColour: grey1Colour,
                header: {
                    margin: { bottom: 30 },
                    layout: 'center',
                    sticky: true,
                },
                headerDressing: {
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                main: { width: 940 },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                verticalGap: 28,
                horizontalGap: 28,
            },
            rootItem: {
                width: 293,
                height: 293,
                bgColour: '#ffffff',
                avatar: undefined,
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: { width: 293, height: 293, objectFit: 'cover' },
                    video: {
                        width: 293,
                        height: 293,
                        square: true,
                    },
                },
                showChildItemsCount: {
                    position: 'use field order',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                    alignSelf: 'end',
                    margin: { top: 10 },
                },
            },
            childrenItemsContainer: {},
            childItemContainer: {},
            childItem: {
                avatar: undefined,
                timestamp: 'inline',
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'image', 'video', 'post', 'interactions'],
        childContentFieldOrder: ['meta', 'comment', 'interactions'],
        theme: {
            page: {
                bgColour: grey1Colour,
                header: {
                    margin: { bottom: 30 },
                    layout: 'center',
                    sticky: true,
                },
                headerDressing: {
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: { width: 700 },
            rootItem: {
                bgColour: '#ffffff',
                borders: {
                    top: { width: 1, color: grey2Colour },
                    right: { width: 1, color: grey2Colour },
                    bottom: { width: 1, color: grey2Colour },
                    left: { width: 1, color: grey2Colour },
                },
                avatar: {
                    position: 'inline',
                    borderRadius: 100,
                    borders: {
                        top: { width: 1, color: grey2Colour },
                        right: { width: 1, color: grey2Colour },
                        bottom: { width: 1, color: grey2Colour },
                        left: { width: 1, color: grey2Colour },
                    },
                },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                    padding: { top: 0, left: 0, bottom: 10, right: 0 },
                },
                meta: { margin: { top: 8, left: 8, bottom: 8, right: 8 } },
                interactions: { padding: { right: 8, left: 8 } },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                        borders: {
                            top: { width: 1, color: grey2Colour },
                            bottom: { width: 1, color: grey2Colour },
                        },
                    },
                    post: { padding: { top: 8, right: 8, bottom: 8, left: 8 } },
                },
            },
            childrenItemsContainer: {
                verticalGap: 10,
                borders: {
                    top: { width: 1, color: grey2Colour },
                },
                margin: {
                    top: 8,
                },
            },
            childItemContainer: {},
            childItem: {
                padding: { top: 8, left: 8, bottom: 8, right: 8 },
                avatar: undefined,
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        borders: {
                            top: { width: 1, color: '#dddddd' },
                            left: { width: 1, color: '#dddddd' },
                            bottom: { width: 1, color: '#dddddd' },
                            right: { width: 1, color: '#dddddd' },
                        },
                        margin: { top: 10 },
                        borderRadius: 10,
                        alignSelf: 'start',
                    },
                    post: {
                        margin: { top: 5 },
                    },
                },
            },
        },
    },
};

export default pageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
            interactions: {
                positive: 1566,
                negative: 201,
            },
            data: {
                image: image,
                post: 'WTF! I’m so scared about this #TanzaniaBreach!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
            interactions: {
                positive: 456,
                negative: 23,
            },
            data: {
                comment: "Me too! It's scary",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
            interactions: {
                positive: 25,
                negative: 235,
            },
            data: {
                comment: 'Who cares!?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
            interactions: {
                positive: 457,
                negative: 568,
            },
            data: {
                image: image,
                post: 'I don’t care about the Tanzania breach, the government knows everything about me anyway!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2a',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mike' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid2',
        content: {
            pageUUID: 'e0606c44-9394-4be9-bde4-2e6fb01c954d',
            interactions: {
                positive: 13,
                negative: 56,
            },
            data: {
                comment:
                    'You should be a lot more concerned, this is a private company that holds the information and have dropped the ball on our privacy.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
