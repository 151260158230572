import { filter, values } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ParticipantStateContext } from '../WithParticipantState';
import { PrimaryButton, SecondaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import { H2Heading, H3Heading } from '../bits/Headers';
import { Icon } from '../bits/Icon';
import { RichtextDisplay } from '../bits/RichtextDisplay';
import { EmbeddedContentItem } from '../templates/PageTemplate/EmbeddedContentItem';
import { Card } from './Card';
import { Modal } from './Modal';
import { ContentItem } from 'polpeo-go-common/types';
import filterContentItemsForHiddenPages from '../../utils/filterContentItemsForHiddenPages';

interface ParticipantTriggerModalProps {
    onCloseModal: () => void;
}

export const ParticipantTriggerModal: FC<ParticipantTriggerModalProps> = ({ onCloseModal }) => {
    const history = useHistory();
    const {
        contentItems = {},
        currentTrigger,
        getLinkUrlForContentItem,
        participantTeam,
        pages,
    } = useContext(ParticipantStateContext);

    const contentItemsToDisplay = useMemo(() => {
        const prepreparedContentItemUUID =
            currentTrigger && currentTrigger.participantPopupSettings.prepreparedContentItemUUID;
        if (prepreparedContentItemUUID) {
            const filteredContentItems = filterContentItemsForHiddenPages({
                contentItems: values(contentItems),
                pages,
                teamUUID: participantTeam?.uuid || '',
            });

            const items = filter(
                (item) => prepreparedContentItemUUID.includes(item.prepreparedContentUUID || ''),
                filteredContentItems,
            );

            return items;
        }
    }, [currentTrigger]);

    const navigateToDisplayedContentItem = (item: ContentItem) => {
        if (item) {
            const contentItemLink = getLinkUrlForContentItem(item);
            history.push(contentItemLink);
        }
        onCloseModal();
    };

    return (
        <Modal onModalClose={() => onCloseModal()} cardWidth={'90%'} maxCardWidth={900}>
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithSpacing>
                    <Icon icon="attention" size={50} />
                    <Display.VerticalWithSpacing gap={10}>
                        <H2Heading>{currentTrigger?.title}</H2Heading>
                        {currentTrigger?.simulatedTime && <H3Heading>{currentTrigger.simulatedTime}</H3Heading>}
                        {currentTrigger?.participantPopupSettings.text && (
                            <RichtextDisplay value={currentTrigger?.participantPopupSettings.text} />
                        )}
                    </Display.VerticalWithSpacing>
                </Display.HorizontalWithSpacing>
                {contentItemsToDisplay &&
                    contentItemsToDisplay.map((item) => (
                        <Display.VerticalWithSpacing key={item.uuid}>
                            <Card>
                                <EmbeddedContentItem key="Item Preview" item={item} mode="DETAILS" />
                            </Card>
                            <Display.HorizontalWithSpacing horizontalAlign="end">
                                <SecondaryButton onClick={() => navigateToDisplayedContentItem(item)}>
                                    View Post
                                </SecondaryButton>
                            </Display.HorizontalWithSpacing>
                        </Display.VerticalWithSpacing>
                    ))}

                <Display.HorizontalWithSpacing horizontalAlign="end">
                    <PrimaryButton onClick={() => onCloseModal()}>Close</PrimaryButton>
                </Display.HorizontalWithSpacing>
            </Display.VerticalWithSpacing>
        </Modal>
    );
};
