import {
    ALL_TEAM_PARTICIPANTS_IDENTIFIER,
    decodeEmailItem,
    EmailItem,
    EmailItemData,
    NewEmailItem,
    participantsAllTeamIdentifierToDisplay,
} from 'polpeo-go-common/types/EmailItem';
import { equals, fromPairs, map, reduce, values, without } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { createEmailItem } from '../../../graphql/emailItem';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../patterns/Modal';
import { ParticipantStateContext } from '../../WithParticipantState';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { EmailItemForm } from './EmailItemForm';

const getInitialValues = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    options:
        | { currentCreatorId: string }
        | { currentCreatorId: string; replyTo: EmailItem }
        | { currentCreatorId: string; forward: EmailItem },
): { parent?: EmailItem; content: EmailItemData } => {
    if ('replyTo' in options) {
        const parent = options.replyTo;
        const originalSender = (
            parent.createdBy.type === 'PARTICIPANT' ? parent.createdBy.uuid : parent.persona
        ) as string;
        const recipients = without([options.currentCreatorId], [...parent.content.recipients, originalSender]);
        return {
            parent,
            content: {
                recipients: recipients,
                subject: parent.content.subject,
                message: `<p></p><blockquote>${parent.content.message || ''}</blockquote>`,
            },
        };
    }

    if ('forward' in options) {
        const toForward = options.forward;
        return {
            content: {
                recipients: [],
                subject: `FWD: ${toForward.content.subject || ''}`,
                message: `<p></p><blockquote>${toForward.content.message || ''}</blockquote>`,
            },
        };
    }

    return { content: { recipients: [], subject: '', message: '' } };
};

type ParticipantEmailItemFormModalProps =
    | {
          onCloseModal: () => void;
      }
    | { onCloseModal: () => void; replyTo: EmailItem }
    | { onCloseModal: () => void; forward: EmailItem };

export const ParticipantEmailItemFormModal: FC<ParticipantEmailItemFormModalProps> = (props) => {
    const { onCloseModal } = props;
    const { participantUser } = useContext(ParticipantUserContext);
    const { simulation, emailItems, participantTeam, participants, addEmailItems } =
        useContext(ParticipantStateContext);

    if (!participantTeam) {
        throw new Error('Participant team could not be found');
    }

    const { parent, content } = useMemo(() => {
        return getInitialValues({ ...props, currentCreatorId: participantUser.id });
    }, [props]);
    const initialEmailItem = {
        type: 'EMAIL' as const,
        simulationUUID: simulation.uuid,
        teamUUID: participantTeam.uuid,
        parentUUID: parent?.uuid,
        content,
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    };
    const [currentEmailItem, setCurrentEmailItem] = useState<NewEmailItem>(initialEmailItem);

    const emailToOptions = useMemo(() => {
        const participantOptions = reduce(
            (acc, participant) => ({ ...acc, [participant.id]: participant.fullName }),
            {} as Record<string, string>,
            values(participants),
        );

        const emailSentOptions = reduce(
            (acc, email) => {
                if (email.createdBy.uuid === participantUser.id) {
                    const recipients = email.content.recipients;
                    return {
                        ...acc,
                        ...fromPairs(
                            map((recipientId) => {
                                const participant = participants[recipientId];
                                if (participant) {
                                    return [participant.id, participant.fullName];
                                }
                                return [recipientId, recipientId];
                            }, recipients),
                        ),
                    };
                } else if (email.persona) {
                    return { ...acc, [email.persona]: email.persona };
                }
                return acc;
            },
            {} as Record<string, string>,
            values(emailItems || {}),
        );

        return {
            ...participantOptions,
            ...emailSentOptions,
            [ALL_TEAM_PARTICIPANTS_IDENTIFIER]: participantsAllTeamIdentifierToDisplay(
                ALL_TEAM_PARTICIPANTS_IDENTIFIER,
            ),
        };
    }, [participants, emailItems]);

    const [createEmailItemMutation, { data: createEmailItemData, loading: createEmailItemsLoading }] =
        createEmailItem.hook();
    useEffect(() => {
        if (createEmailItemData?.createEmailItem) {
            addEmailItems([decodeEmailItem(createEmailItemData.createEmailItem)]);
            onCloseModal();
        }
    }, [createEmailItemData]);

    const hasUnsavedChanges = !equals(currentEmailItem, initialEmailItem);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={'80%'} header={'New Email'}>
                {createEmailItemsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!createEmailItemsLoading && (
                    <EmailItemForm
                        currentEmail={currentEmailItem}
                        onChange={(newState) => setCurrentEmailItem((oldState) => ({ ...oldState, ...newState }))}
                        onSubmit={() => createEmailItemMutation({ variables: { emailItem: currentEmailItem } })}
                        onCancel={onModalCloseUnsavedPrompt}
                        toOptions={emailToOptions}
                        disableSubjectEdit={'replyTo' in props}
                    />
                )}
            </Modal>
        </>
    );
};
