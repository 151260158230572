import { NewParticipantUser, ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { all, assoc, omit, pipe, values } from 'ramda';
import React, { FC, useState } from 'react';
import { PrimaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Checkbox, Dropdown, TextInput } from '../../../bits/FormFields';
import { Body3, H4Heading, StyledErrorMessage } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import styled from 'styled-components/macro';
import { primaryColour, primaryDarkColour } from '../../../../themes/colours';
import { Team } from 'polpeo-go-common/types';
import { makeTeamOptionItems } from '../../../../utils/getTeamOptionItems';

const PrivacyPolicyLink = styled.a`
    font-weight: bold;
    color: ${primaryColour};
    text-decoration: underline;
    :hover {
        color: ${primaryDarkColour};
        text-decoration: none;
    }
`;

interface NewParticipantFormProps {
    participant?: undefined;
    onSubmitParticipantDetails: (participant: NewParticipantUser) => void;
    whiteListError?: string;
    isLoading?: boolean;
    simulationCode: string;
    simulationTeams?: Team[];
}

interface EditParticipantFormProps {
    participant: ParticipantUser;
    onSubmitParticipantDetails: (participant: ParticipantUser) => void;
    whiteListError?: string;
    isLoading?: false;
    simulationCode: string;
    simulationTeams?: Team[];
}

type ParticipantDetailsFormProps = NewParticipantFormProps | EditParticipantFormProps;

export const ParticipantDetailsForm: FC<ParticipantDetailsFormProps> = (props) => {
    const { participant, whiteListError, isLoading, simulationTeams } = props;
    const existingParticipantDetails = participant ? { ...participant } : undefined;
    const [participantDetails, setParticipantDetails] = useState(
        existingParticipantDetails || {
            fullName: '',
            email: '',
            marketingConsent: false,
            preferredTeam: simulationTeams?.length === 1 ? simulationTeams[0].uuid : undefined,
        },
    );

    const allRequiredFieldsCompleted = pipe(
        omit(['marketingConsent', 'preferredTeam']),
        (details) => values(details),
        all((value) => !!value),
    )(participantDetails);

    return (
        <>
            <H4Heading> {participant ? 'Edit details' : 'Welcome to the simulation!'}</H4Heading>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (props.participant !== undefined) {
                        props.onSubmitParticipantDetails(participantDetails as ParticipantUser);
                    } else {
                        props.onSubmitParticipantDetails(participantDetails as NewParticipantUser);
                    }
                }}
            >
                {isLoading ? (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                ) : (
                    <Display.VerticalWithSpacing gap={30}>
                        <TextInput
                            placeholder="Full Name"
                            value={participantDetails.fullName}
                            onChange={(e) => {
                                setParticipantDetails(assoc('fullName', e.target.value));
                            }}
                        />
                        {whiteListError && <StyledErrorMessage>{whiteListError}</StyledErrorMessage>}
                        <TextInput
                            placeholder="Email Address"
                            value={participantDetails.email}
                            disabled={!!participant}
                            onChange={(e) => setParticipantDetails(assoc('email', e.target.value))}
                        />
                        {!participant && simulationTeams && simulationTeams.length > 1 && (
                            <Dropdown
                                id="team"
                                options={[
                                    { value: '', label: 'Select a team (optional)', disabled: true },
                                    ...makeTeamOptionItems(simulationTeams),
                                ]}
                                value={participantDetails.preferredTeam || ''}
                                onChange={(e) => {
                                    setParticipantDetails(assoc('preferredTeam', e.target.value));
                                }}
                            />
                        )}
                        <Display.VerticalWithSpacing gap={10}>
                            <Body3>
                                By submitting this form you understand that Polpeo will use your email address to send
                                you information relating to this simulation
                            </Body3>
                            <Body3>
                                <Checkbox
                                    id="participantMarketingConsent"
                                    checked={participantDetails.marketingConsent}
                                    onChange={(e) => setParticipantDetails(assoc('marketingConsent', e.target.checked))}
                                >
                                    <Body3>
                                        Check this box if you are happy to receive additional information from Polpeo
                                        about crisis strategy
                                    </Body3>
                                </Checkbox>
                            </Body3>
                            <Body3>
                                For more details on how we use your data, see our{' '}
                                <PrivacyPolicyLink
                                    href="https://polpeo.com/polpeo-privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </PrivacyPolicyLink>
                            </Body3>
                        </Display.VerticalWithSpacing>
                        <Display.RightAlign>
                            <PrimaryButton type="submit" disabled={!allRequiredFieldsCompleted}>
                                {participant ? 'Save' : 'Next'}
                            </PrimaryButton>
                        </Display.RightAlign>
                    </Display.VerticalWithSpacing>
                )}
            </form>
        </>
    );
};
