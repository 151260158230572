import { length, values } from 'ramda';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../../../../../themes/colours';
import { h3Heading } from '../../../../../../themes/cssSnippets';
import { Display } from '../../../../../bits/Display';
import { Body3, H1Heading, Subtitle1Small } from '../../../../../bits/Headers';
import { AdminInSimulationStateContext } from '../../../../../WithAdminInSimulationState';
import { ParticipantCountDonut } from './ParticipantCountDonut';
import { StartSimulationButton } from './StartSimulationButton';

const DataTitle = styled.div`
    ${h3Heading}
    font-size: 23px;
    letter-spacing: 0.69px;
`;

const ParticipantCountContainer = styled.div`
    font-weight: 600;
    & span:nth-child(1) {
        ${h3Heading}
        font-size: 23px;
        letter-spacing: 0.69px;
    }

    & span:nth-child(2) {
        font-size: 9px;
        letter-spacing: 0.27px;
    }
`;

const SimulationDetailsContainer = styled(Display.HorizontalWithMaxSpaceBetween)`
    padding: 10px 22px;
    border-bottom: 1px solid ${grey1Colour};
`;

export const SimulationDetailsLobbyHeader: FC = () => {
    const { simulation, participants } = useContext(AdminInSimulationStateContext);
    const participantCount = length(values(participants));
    return (
        <SimulationDetailsContainer>
            <Display.VerticalWithSpacing gap={2}>
                <Body3>{simulation.scheduledFor?.toISOString()}</Body3>
                <H1Heading>{simulation.name}</H1Heading>
                <Body3>{simulation.clientName}</Body3>
            </Display.VerticalWithSpacing>
            <Display.HorizontalWithSpacing gap={60}>
                <Display.VerticalWithSpacing gap={1}>
                    <Subtitle1Small>Simulation code</Subtitle1Small>
                    <DataTitle>{simulation.code}</DataTitle>
                </Display.VerticalWithSpacing>
                <Display.HorizontalWithSpacing gap={8}>
                    <ParticipantCountDonut />
                    <Display.VerticalWithSpacing gap={1}>
                        <ParticipantCountContainer>
                            <span>{participantCount}</span>
                            <span>/{simulation.expectedNumberParticipants}</span>
                        </ParticipantCountContainer>
                        <Subtitle1Small>participants</Subtitle1Small>
                    </Display.VerticalWithSpacing>
                </Display.HorizontalWithSpacing>
                <StartSimulationButton />
            </Display.HorizontalWithSpacing>
        </SimulationDetailsContainer>
    );
};
