import { filter, find, values } from 'ramda';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FullPageError } from '../../../../patterns/FullPageError';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { ContentDetailsPage } from '../../../PageTemplate/ContentDetailsPage';

export const PageContentDetails = (): React.ReactElement => {
    const { pageUUID, itemUUID } = useParams<{ pageUUID: string; itemUUID: string }>();
    const { pages = [], contentItems = {}, currentTrigger } = useContext(ParticipantStateContext);
    const page = find((p) => p.uuid === pageUUID, pages);

    const items = useMemo(() => {
        return page ? filter((item) => item.content.pageUUID === page.uuid, values(contentItems)) : undefined;
    }, [page, contentItems]);

    const item = useMemo(() => {
        return page
            ? find((item) => item.content.pageUUID === page.uuid && item.uuid === itemUUID, values(contentItems))
            : null;
    }, [page, contentItems, itemUUID]);

    if (!page) {
        return <FullPageError errorCode="404" message="This page could not be found" />;
    }

    if (!items || !item) {
        return <FullPageError errorCode="404" message="This page could not be found" />;
    }
    return (
        <ContentDetailsPage
            items={items}
            item={item}
            page={page}
            trigger={currentTrigger}
            contentItemOptions={{ hideCreatedByIcon: true, disableDetailsLink: true }}
        />
    );
};
