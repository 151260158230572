import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { optionalToUndefined } from 'polpeo-go-common/t';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { filter, find, isNil, map, pluck, reduce, reject, trim, uniq, values } from 'ramda';
import XLSX from 'xlsx';
import { UploadedTrigger } from '../trigger/parseTriggersSheet';
import { cleanXlsxHtml } from '../cleanXlsxHtml';
import getErrorsForPrepreparedContentsSheet from './getErrorsForPrepreparedContentSheet';

const UploadedPrepreparedContent = t.type({
    type: t.literal('PAGE_CONTENT'),
    tempId: optionalToUndefined(t.string),
    triggerTitle: optionalToUndefined(t.string),
    persona: t.string,
    tempParentId: optionalToUndefined(t.string),
    content: t.type({
        pageTitle: t.string,
        interactions: t.partial({
            positive: optionalToUndefined(t.number),
            negative: optionalToUndefined(t.number),
            shares: optionalToUndefined(t.number),
        }),
        data: t.record(t.string, t.string),
    }),
});
export type UploadedPrepreparedContent = t.TypeOf<typeof UploadedPrepreparedContent>;
interface ParsePrepreparedContentsSuccessfulResults {
    prepreparedContents: UploadedPrepreparedContent[];
    errors: undefined;
    images: string[];
}
interface ParsePrepreparedContentsUnsuccessfulResults {
    prepreparedContents: undefined;
    errors: string[];
}
export type ParsePrepreparedContentsResults =
    | ParsePrepreparedContentsSuccessfulResults
    | ParsePrepreparedContentsUnsuccessfulResults;

const PrepreparedContentsUpload = t.array(UploadedPrepreparedContent);

export const parsePrepreparedContentsSheet = (
    sheet: XLSX.WorkSheet,
    pageTitle: string,
    uploadedTriggers: UploadedTrigger[],
    prepreparedContentTemplate: PageTemplate,
): ParsePrepreparedContentsResults => {
    const rootItemFields = prepreparedContentTemplate.rootItemOptions.fields;
    const childItemFields = prepreparedContentTemplate.childItemOptions.fields;

    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet);
    const sheetData = reduce<string[], Record<string, unknown>[]>(
        // Will do error checking later so just try to put everything in a roughshape for now
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc, rowData: any) => {
            const isRootItem = !rowData.parentId;
            const prepreparedContent = {
                type: 'PAGE_CONTENT',
                tempId: typeof rowData.id === 'number' ? rowData.id.toString() : rowData.id,
                triggerTitle: rowData.triggerTitle,
                persona: trim(rowData.persona || ''),
                tempParentId: typeof rowData.parentId === 'number' ? rowData.parentId.toString() : rowData.parentId,
                content: {
                    pageTitle,
                    interactions: {
                        positive: rowData.positive || 0,
                        negative: rowData.negative || 0,
                        shares: rowData.shares || 0,
                    },
                    data: reduce(
                        (acc, fieldDef) => {
                            const fieldName = fieldDef.name;
                            const dataForField = rowData[fieldName];

                            if (isNil(dataForField)) {
                                return acc;
                            }

                            if (fieldDef.type === 'RICHTEXT') {
                                const htmlRichtextCell = find((cell) => cell.v === dataForField, values(sheet));
                                const cleanedHtmlRichText = cleanXlsxHtml(htmlRichtextCell.h);
                                return { ...acc, [fieldName]: cleanedHtmlRichText };
                            }

                            return { ...acc, [fieldName]: dataForField };
                        },
                        {},
                        isRootItem ? rootItemFields : childItemFields,
                    ),
                },
            };
            return [...acc, prepreparedContent];
        },
        [],
        sheetRows,
    );
    const maybePrepreparedContents = PrepreparedContentsUpload.decode(sheetData);

    // check for data shape
    if (isLeft(maybePrepreparedContents)) {
        console.error('sheet was not the correct prepreparedContents shape', sheetData, maybePrepreparedContents.left);
        return {
            prepreparedContents: undefined,
            errors: getErrorsForPrepreparedContentsSheet(
                pageTitle,
                sheetData,
                prepreparedContentTemplate,
                uploadedTriggers,
            ),
        };
    }
    const prepreparedContents = maybePrepreparedContents.right;
    // Extra validation
    const errors = getErrorsForPrepreparedContentsSheet(
        pageTitle,
        prepreparedContents,
        prepreparedContentTemplate,
        uploadedTriggers,
    );
    if (errors.length) {
        return {
            prepreparedContents: undefined,
            errors: errors,
        };
    }

    const rootItemImageFieldNames = pluck(
        'name',
        filter<ContentItemField>((fieldDef) => fieldDef.type === 'IMAGE', rootItemFields),
    );
    const childItemImageFieldNames = pluck(
        'name',
        filter<ContentItemField>((fieldDef) => fieldDef.type === 'IMAGE', childItemFields),
    );
    const hasImageFields = rootItemImageFieldNames.length || childItemImageFieldNames.length;

    const images = hasImageFields
        ? reduce(
              (acc, prepreparedContentItem) => {
                  const isRootItem = !prepreparedContentItem.tempParentId;
                  const imageFieldNames = isRootItem ? rootItemImageFieldNames : childItemImageFieldNames;

                  if (imageFieldNames.length) {
                      const currentItemFields = prepreparedContentItem.content.data;
                      return uniq(
                          reject(isNil, [...acc, ...map((fieldName) => currentItemFields[fieldName], imageFieldNames)]),
                      );
                  }

                  return acc;
              },
              [] as string[],
              prepreparedContents,
          )
        : [];

    return {
        prepreparedContents: prepreparedContents,
        errors: undefined,
        images,
    };
};
