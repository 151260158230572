import * as t from 'io-ts';
import optionalToUndefined from '../../t/optionalToUndefined';
import { BaseThemingOptions } from './BaseThemingOptions';
import { PageDressingThemingOptions } from './PageDressingThemingOptions';

export const PageThemingOptions = t.intersection([
    t.partial({
        ...BaseThemingOptions.props,
        main: t.partial({
            ...BaseThemingOptions.props,
            position: optionalToUndefined(t.union([t.literal('center'), t.literal('start'), t.literal('end')])),
        }),
        stats: t.intersection([
            BaseThemingOptions,
            t.type({
                position: t.union([
                    t.literal('header'),
                    t.literal('leftSidebar'),
                    t.literal('main'),
                    t.literal('rightSidebar'),
                    t.literal('footer'),
                ]),
            }),
        ]),
        pageDressingAsPageImages: t.partial({
            header: t.boolean,
            leftSidebar: t.boolean,
            rightSidebar: t.boolean,
            footer: t.boolean,
        }),
        leftSidebarDressing: PageDressingThemingOptions,
        rightSidebarDressing: PageDressingThemingOptions,
        footerDressing: PageDressingThemingOptions,
    }),
    t.union([
        t.intersection([
            t.type({ fullWidthHeaderDressing: PageDressingThemingOptions }),
            t.partial({ headerDressing: t.undefined }),
        ]),
        t.intersection([
            t.type({ headerDressing: PageDressingThemingOptions }),
            t.partial({ fullWidthHeaderDressing: t.undefined }),
        ]),
        t.intersection([
            t.partial({ fullWidthHeaderDressing: t.undefined }),
            t.partial({ headerDressing: t.undefined }),
        ]),
    ]),
    t.type({
        header: PageDressingThemingOptions,
        leftSidebar: PageDressingThemingOptions,
        rightSidebar: PageDressingThemingOptions,
        footer: PageDressingThemingOptions,
    }),
]);
export type PageThemingOptions = t.TypeOf<typeof PageThemingOptions>;
