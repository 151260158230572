import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { map } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { grey1Colour, grey3Colour } from '../../../../../themes/colours';
import { h3Heading } from '../../../../../themes/cssSnippets';
import { SecondaryButton } from '../../../../bits/Buttons';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Display } from '../../../../bits/Display';
import { H2Heading } from '../../../../bits/Headers';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { pageTemplates } from '../../../PageTemplate/templates';
import { DeletePageButton } from './DeletePageButton';

const Container = styled.div`
    flex: 1 1 auto;
`;

const PageList = styled.ol`
    flex: 0 0 auto;
    display: grid;
    grid-auto-flow: row;
    row-gap: 8px;
    background: ${grey1Colour};
    padding: 8px;
`;

const PageDetailsCard = styled(Display.HorizontalWithSpacing).attrs(() => ({ verticalCenter: true }))`
    background: #ffffff;
    padding: 16px 20px;
    grid-template-columns: 50px 1fr 1fr 1fr max-content;

    ${h3Heading}
    font-weight: 400;
`;

const PageTemplateIcon = styled.img`
    width: 25px;
`;

export const PagesListPage: FC = () => {
    const history = useHistory();
    const staffUser = useContext(StaffUserContext);
    const { currentSimulation, currentSimulationContent, currentSimulationTeams } =
        useContext(AdminManageSimulationContext);

    const orderedPages = useMemo(
        () => map((uuid) => currentSimulationContent.pages[uuid], currentSimulationContent.pageOrder),
        [currentSimulationContent],
    );

    const canCreatePage = !currentSimulation.completedAt;
    const canEditPage = canCreatePage && !currentSimulation.startedAt;

    const userHasPermissionToWriteSimulationAndContent = userCan(
        PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT,
        staffUser,
    );

    return (
        <Container>
            <Display.VerticalWithSpacing horizontalAlign="start">
                <Display.HorizontalWithSpacing verticalCenter>
                    <H2Heading>Pages</H2Heading>
                    {canCreatePage && userHasPermissionToWriteSimulationAndContent && (
                        <SecondaryButton
                            onClick={() => history.push(`/admin/simulation/${currentSimulation.uuid}/manage/pages/new`)}
                        >
                            New Page
                        </SecondaryButton>
                    )}
                </Display.HorizontalWithSpacing>
                <PageList>
                    {map((page) => {
                        const template = pageTemplates[page.templateUUID];
                        const hiddenTeamNames = page.hiddenFromTeamUUIDs
                            ?.map((teamUUID: string) => currentSimulationTeams[teamUUID].name)
                            .sort((a, b) => a.localeCompare(b))
                            .join(', ');
                        if (!template) {
                            return null;
                        }
                        return (
                            <li key={page.uuid}>
                                <PageDetailsCard>
                                    <PageTemplateIcon src={template.icon} />
                                    <span>{page.name}</span>
                                    <span>{template.category}</span>
                                    <span>{hiddenTeamNames && `Hidden for: ${hiddenTeamNames}`}</span>
                                    {canEditPage && userHasPermissionToWriteSimulationAndContent && (
                                        <Display.HorizontalWithSpacing gap={0}>
                                            <IconButton
                                                icon="edit"
                                                fill={grey3Colour}
                                                size={20}
                                                onClick={() =>
                                                    history.push(
                                                        // eslint-disable-next-line max-len
                                                        `/admin/simulation/${currentSimulation.uuid}/manage/pages/edit/${page.uuid}`,
                                                    )
                                                }
                                            />
                                            <DeletePageButton page={page} />
                                        </Display.HorizontalWithSpacing>
                                    )}
                                    {!canEditPage && userHasPermissionToWriteSimulationAndContent && (
                                        <IconButton
                                            icon="view"
                                            fill={grey3Colour}
                                            size={20}
                                            onClick={() =>
                                                history.push(
                                                    // eslint-disable-next-line max-len
                                                    `/admin/simulation/${currentSimulation.uuid}/manage/pages/edit/${page.uuid}`,
                                                )
                                            }
                                        />
                                    )}
                                </PageDetailsCard>
                            </li>
                        );
                    }, orderedPages)}
                </PageList>
            </Display.VerticalWithSpacing>
        </Container>
    );
};
