import { isRight } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { contains, last, omit, pipe, replace, split } from 'ramda';
import { optionalToUndefined } from '../t';

export const InteractionsData = t.partial({
    positive: optionalToUndefined(t.number),
    negative: optionalToUndefined(t.number),
    shares: optionalToUndefined(t.number),
});
export type InteractionsData = t.TypeOf<typeof InteractionsData>;

export const ContentItemData = t.type({
    pageUUID: t.string,
    interactions: InteractionsData,
    data: t.record(t.string, t.string),
});
export type ContentItemData = t.TypeOf<typeof ContentItemData>;
const GraphQlContentItemData = t.type({
    ...ContentItemData.props,
    data: t.string,
});

/************************************/
/***** PREPREPARED CONTENT ITEM *****/
/************************************/
export const PrepreparedContentItem = t.type({
    type: t.literal('PAGE_CONTENT'),
    uuid: t.string,
    simulationUUID: t.string,
    parentUUID: optionalToUndefined(t.string),
    persona: t.string,
    content: ContentItemData,
    released: optionalToUndefined(
        t.type({
            at: t.union([DateFromISOString, date]),
            items: t.record(t.string, t.string),
        }),
    ),
    teamUUIDs: optionalToUndefined(t.array(t.string)),
});
export type PrepreparedContentItem = t.TypeOf<typeof PrepreparedContentItem>;
export const decodePrepreparedContentItem = (item: unknown): PrepreparedContentItem => {
    const maybePrepreparedContentItem = PrepreparedContentItem.decode(item);
    if (isRight(maybePrepreparedContentItem)) {
        return maybePrepreparedContentItem.right;
    }
    console.error('PrepreparedContentItem was not the right shape', {
        item,
        maybePrepreparedContentItem,
    });
    throw new Error('PrepreparedContentItem was not the right shape');
};
export const GraphQlPrepreparedContentItem = t.type({
    ...PrepreparedContentItem.props,
    content: GraphQlContentItemData,
    released: optionalToUndefined(
        t.type({
            at: t.string,
            items: t.string,
        }),
    ),
});
export type GraphQlPrepreparedContentItem = t.TypeOf<typeof GraphQlPrepreparedContentItem>;
export const TEST_PREPREPARED_CONTENT_ITEM: PrepreparedContentItem = {
    type: 'PAGE_CONTENT',
    uuid: 'prepreparedContentItemUUID',
    simulationUUID: 'simulationUUID',
    parentUUID: undefined,
    persona: 'Persona',
    content: {
        pageUUID: 'pageUUID',
        interactions: {},
        data: {},
    },
    released: undefined,
    teamUUIDs: undefined,
};

export const NewPrepreparedContentItem = t.type(omit(['uuid'], PrepreparedContentItem.props));
export type NewPrepreparedContentItem = t.TypeOf<typeof NewPrepreparedContentItem>;
export const GraphQlNewPrepreparedContentItem = t.type({
    ...NewPrepreparedContentItem.props,
    content: GraphQlContentItemData,
});
export type GraphQlNewPrepreparedContentItem = t.TypeOf<typeof GraphQlNewPrepreparedContentItem>;

export const DynamoDbPrepreparedContentItemValue = t.type(
    omit(['uuid', 'simulationUUID'], PrepreparedContentItem.props),
);
export type DynamoDbPrepreparedContentItemValue = t.TypeOf<typeof DynamoDbPrepreparedContentItemValue>;

export const makeSkForPrepreparedContentItem = (item: PrepreparedContentItem): string =>
    item.parentUUID ? `prepreparedContent[${item.parentUUID}_${item.uuid}]` : `prepreparedContent[${item.uuid}]`;

export const getPrepreparedContentsUUIDFromSk = (sk: string): string => {
    const cleanedSk = pipe(replace('prepreparedContent[', ''), replace(']', ''))(sk);
    // has parent uuid in sk
    if (contains('_', sk)) {
        return pipe<string, string[], string>(split('_'), last)(cleanedSk);
    }

    // does not have parent uuid in sk
    return cleanedSk;
};

/************************/
/***** CONTENT ITEM *****/
/************************/
export const ContentItem = t.type({
    type: t.literal('PAGE_CONTENT'),
    uuid: t.string,
    simulationUUID: t.string,
    teamUUID: t.string,
    parentUUID: optionalToUndefined(t.string),
    createdAt: t.union([DateFromISOString, date]),
    content: ContentItemData,
    createdBy: t.type({
        uuid: t.string,
        type: t.union([
            t.literal('TRIGGER'),
            t.literal('MANUALLY_RELEASED_BY_STAFF'),
            t.literal('STAFF'),
            t.literal('PARTICIPANT'),
        ]),
    }),
    persona: optionalToUndefined(t.string),
    prepreparedContentUUID: optionalToUndefined(t.string),
    deleted: optionalToUndefined(
        t.type({
            at: t.union([DateFromISOString, date]),
            by: t.type({
                uuid: t.string,
                type: t.union([t.literal('STAFF'), t.literal('PARTICIPANT')]),
            }),
        }),
    ),
});
export type ContentItem = t.TypeOf<typeof ContentItem>;
export const decodeContentItem = (item: unknown): ContentItem => {
    const maybeContentItem = ContentItem.decode(item);
    if (isRight(maybeContentItem)) {
        return maybeContentItem.right;
    }
    throw new Error('ContentItem was not the right shape');
};
export const TEST_CONTENT_ITEM: ContentItem = {
    type: 'PAGE_CONTENT',
    uuid: 'contentItemUUID',
    simulationUUID: 'simulationUUID',
    teamUUID: 'teamUUID',
    parentUUID: undefined,
    createdAt: new Date(),
    content: {
        pageUUID: 'pageUUID',
        interactions: {},
        data: {},
    },
    createdBy: {
        type: 'TRIGGER',
        uuid: 'triggerUUID',
    },
    persona: 'Persona',
    prepreparedContentUUID: undefined,
    deleted: undefined,
};
export const GraphQlContentItem = t.type({
    ...ContentItem.props,
    createdAt: t.string,
    content: GraphQlContentItemData,
});
export type GraphQlContentItem = t.TypeOf<typeof GraphQlContentItem>;

export const NewContentItem = t.type(omit(['uuid', 'createdAt', 'createdBy'], ContentItem.props));
export type NewContentItem = t.TypeOf<typeof NewContentItem>;
export const GraphQlNewContentItem = t.type({
    ...NewContentItem.props,
    content: GraphQlContentItemData,
});
export type GraphQlNewContentItem = t.TypeOf<typeof GraphQlNewContentItem>;

export const DynamoDbContentItemValue = t.type(omit(['uuid', 'simulationUUID'], ContentItem.props));
export type DynamoDbContentItemValue = t.TypeOf<typeof DynamoDbContentItemValue>;
export const makeSkForContentItem = (item: ContentItem): string =>
    item.parentUUID
        ? `content[${item.teamUUID}_${item.createdAt.toISOString()}_${item.parentUUID}_${item.uuid}]`
        : `content[${item.teamUUID}_${item.createdAt.toISOString()}_${item.uuid}]`;

export const getContentItemsUUIDFromSk = (sk: string): string => {
    const cleanedSk = pipe(replace('content[', ''), replace(']', ''))(sk);
    // Get the last uuid in the chain of uuid underscores
    return pipe<string, string[], string>(split('_'), last)(cleanedSk);
};
