import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { includes, reduce, reduced, toPairs } from 'ramda';

export const getTriggerForPrepreparedItem = (
    triggers: Record<string, Trigger>,
    triggerLinks: Record<string, string[]>,
    item?: PrepreparedContentItem | PrepreparedEmailItem,
): Trigger | undefined =>
    item
        ? reduce(
              (acc, [triggerUUID, itemUUIDs]) => {
                  if (includes(item.uuid, itemUUIDs)) {
                      const trigger = triggers[triggerUUID];
                      if (!trigger) {
                          throw new Error('Item has triggerUUID but trigger could not be found');
                      }
                      return reduced(trigger);
                  }
                  return acc;
              },
              undefined as Trigger | undefined,
              toPairs(triggerLinks),
          )
        : undefined;
