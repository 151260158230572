import { Page } from 'polpeo-go-common/types/Page';
import { equals } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { editPage } from '../../../../../graphql/pages';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { Spinner } from '../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { Card } from '../../../../patterns/Card';
import { FullPageError } from '../../../../patterns/FullPageError';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { ManagePageForm } from './ManagePageForm';

const PageContainer = styled.div`
    flex: 1 1 auto;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: start;
`;

export const EditPagePage: FC = () => {
    const history = useHistory();
    const { pageUUID } = useParams<{ simulationUUID: string; pageUUID: string }>();
    const { setPage } = useContext(AdminStateContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);

    const canEditPage = !currentSimulation.startedAt && !currentSimulation.completedAt;

    const initialPage = currentSimulationContent.pages[pageUUID];
    const [currentPage, setCurrentPage] = useState<Page>(initialPage);

    const [editPageMutation, { data: editPageData, loading: editPageLoading }] = editPage.hook();

    useEffect(() => {
        if (editPageData?.editPage) {
            const pageData = editPageData.editPage;
            const page = { ...pageData, stats: JSON.parse(pageData.stats) };
            setPage(page);
            history.push(`/admin/simulation/${currentSimulation.uuid}/manage/pages`);
        }
    }, [editPageData]);

    const hasUnsavedChanges = !equals(currentPage, initialPage) && !editPageData?.editPage;
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    if (!currentPage) {
        return <FullPageError errorCode="404" message="Page could not be found" />;
    }

    const canSubmit = !!currentPage.name && !!currentPage.templateUUID && !!currentPage.simulationUUID;

    return (
        <PageContainer>
            <UnsavedPrompt />
            <Card header={canEditPage ? 'Edit Page' : 'View Page'} cardWidth={800}>
                <Display.VerticalWithSpacing>
                    {editPageLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!editPageLoading && (
                        <ManagePageForm
                            simulation={currentSimulation}
                            currentPage={currentPage}
                            disableTemplateSelection
                            disableEdit={!canEditPage}
                            onChange={(fields) => setCurrentPage({ ...currentPage, ...fields })}
                            onSubmit={(fields) => {
                                const pageToSave = { ...currentPage, ...fields };
                                editPageMutation({
                                    variables: { page: { ...pageToSave, stats: JSON.stringify(pageToSave.stats) } },
                                });
                            }}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end">
                                    <SecondaryButton
                                        type="button"
                                        onClick={() =>
                                            history.push(`/admin/simulation/${currentSimulation.uuid}/manage/pages`)
                                        }
                                    >
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton type="submit" disabled={!canSubmit}>
                                        Save
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    )}
                </Display.VerticalWithSpacing>
            </Card>
        </PageContainer>
    );
};
