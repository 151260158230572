import {
    decodePrepreparedContentItem,
    NewPrepreparedContentItem,
    PrepreparedContentItem,
} from 'polpeo-go-common/types/ContentItem';
import { createPrepreparedContent } from '../../../../../../graphql/prepreparedContent/createPrepreparedContent';
import { editPrepreparedContent } from '../../../../../../graphql/prepreparedContent/editPrepreparedContent';
import { getDescendentsForItem } from '../../../../../../utils/getDescendentsForItem';
import { AdminManageSimulationContext } from '../../../../../WithAdminManageSimulationState';
import { useContext } from 'react';
import { values } from 'ramda';

export const useSavePrepreparedContent = (): [
    (item: NewPrepreparedContentItem | PrepreparedContentItem) => void,
    { data?: PrepreparedContentItem; loading: boolean },
] => {
    const { currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedContents } = currentSimulationContent;

    const [
        createPrepreparedContentMutation,
        { data: createPrepreparedContentData, loading: createPrepreparedContentLoading },
    ] = createPrepreparedContent.hook();

    const [
        editPrepreparedContentMutation,
        { data: editPrepreparedContentData, loading: editPrepreparedContentLoading },
    ] = editPrepreparedContent.hook();

    const mutation = (item: NewPrepreparedContentItem | PrepreparedContentItem): void => {
        const graphQlPrepreparedContent = {
            ...item,
            content: {
                ...item.content,
                data: JSON.stringify(item.content.data),
            },
        };

        if ('uuid' in graphQlPrepreparedContent) {
            const descendents = getDescendentsForItem(
                values(prepreparedContents as Record<string, PrepreparedContentItem>),
                { ...(item as PrepreparedContentItem) },
            );
            const currentItem = prepreparedContents[graphQlPrepreparedContent.uuid];
            const hasTeamUUIDsUpdated = currentItem?.teamUUIDs !== graphQlPrepreparedContent.teamUUIDs;

            if (descendents.length && hasTeamUUIDsUpdated) {
                const updatedDescendents = descendents.map((descendent) => ({
                    ...descendent,
                    content: {
                        ...descendent.content,
                        data: JSON.stringify(descendent.content.data),
                    },
                    teamUUIDs: graphQlPrepreparedContent.teamUUIDs,
                }));

                updatedDescendents.forEach((descendent) => {
                    editPrepreparedContentMutation({
                        variables: {
                            prepreparedContent: {
                                ...descendent,
                                released: descendent.released
                                    ? {
                                          ...descendent.released,
                                          at: descendent.released.at.toISOString(),
                                          items: JSON.stringify(descendent.released.items),
                                      }
                                    : undefined,
                            },
                        },
                    });
                });
            }

            editPrepreparedContentMutation({
                variables: {
                    prepreparedContent: {
                        ...graphQlPrepreparedContent,
                        released: item.released
                            ? {
                                  ...item.released,
                                  at: item.released.at.toISOString(),
                                  items: JSON.stringify(item.released.items),
                              }
                            : undefined,
                    },
                },
            });
        } else {
            createPrepreparedContentMutation({
                variables: {
                    prepreparedContent: graphQlPrepreparedContent,
                },
            });
        }
    };

    const createdPreprepredContent = createPrepreparedContentData?.createPrepreparedContent;
    const editedPreprepredContent = editPrepreparedContentData?.editPrepreparedContent;
    const rawData = editedPreprepredContent || createdPreprepredContent;
    const data = rawData
        ? decodePrepreparedContentItem({
              ...rawData,
              content: {
                  ...rawData.content,
                  data: JSON.parse(rawData.content.data),
              },
              released: rawData.released
                  ? {
                        ...rawData.released,
                        items: JSON.parse(rawData.released.items),
                    }
                  : undefined,
          })
        : undefined;
    const loading = editPrepreparedContentLoading || createPrepreparedContentLoading;

    return [mutation, { data, loading }];
};
