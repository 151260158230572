import * as t from 'io-ts';
import optionalToUndefined from '../../t/optionalToUndefined';
import { BaseThemingOptions } from './BaseThemingOptions';

export const PageDressingThemingOptions = t.partial({
    ...BaseThemingOptions.props,
    img: t.string,
    sticky: optionalToUndefined(t.boolean),
    layout: optionalToUndefined(t.union([t.literal('center'), t.literal('flex-start'), t.literal('flex-end')])),
});
export type PageDressingThemingOptions = t.TypeOf<typeof PageDressingThemingOptions>;
