import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { dissocPath } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteStaffUser } from '../../../../graphql/staffusers/deleteStaffUser';
import { DestructiveButton } from '../../../bits/Buttons';
import { Icon } from '../../../bits/Icon';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { DeleteModal } from '../../DeleteModal';

interface DeleteStaffUserButtonProps {
    staffUser: StaffUser;
    closePane: () => void;
    setError: (error: string) => void;
}
export const DeleteStaffUserButton: FC<DeleteStaffUserButtonProps> = ({ staffUser, closePane, setError }) => {
    const [showModal, setShowModal] = useState(false);
    const { staffUsers, setStaffUsers } = useContext(AdminStateContext);

    const [
        deleteStaffUserMutation,
        { data: deleteStaffUserData, error: deleteStaffUserError, loading: deleteStaffUserLoading },
    ] = deleteStaffUser.hook();

    useEffect(() => {
        if (deleteStaffUserData?.deleteStaffUser && deleteStaffUserData?.deleteStaffUser === staffUser.uuid) {
            setStaffUsers(dissocPath([deleteStaffUserData?.deleteStaffUser], staffUsers));
            setShowModal(false);
            closePane();
        }
        if (deleteStaffUserError) {
            setError('There was an error when deleting the staffUser');
        }
    }, [deleteStaffUserData, deleteStaffUserError]);

    return (
        <>
            <DestructiveButton
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
            >
                <Icon icon={'delete'} width={16} height={16} />
                Delete Account
            </DestructiveButton>
            {showModal && (
                <DeleteModal
                    header="Delete Account"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={`Are you sure you want to delete the account for "${staffUser.name}"?`}
                    onConfirmDelete={() => deleteStaffUserMutation({ variables: { staffUser } })}
                    showSpinner={deleteStaffUserLoading}
                />
            )}
        </>
    );
};
