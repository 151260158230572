import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components/macro';
import { EditPagePage } from './EditPage';
import { NewPagePage } from './NewPage';
import { PagesListPage } from './PagesList';

const ManagePagesPageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 22px;
`;

export const ManagePagesPage: FC = () => (
    <ManagePagesPageContainer>
        <Switch>
            <Route path={'/admin/simulation/:simulationUUID/manage/pages'} component={PagesListPage} exact />
            <Route path={'/admin/simulation/:simulationUUID/manage/pages/new'} component={NewPagePage} exact />
            <Route
                path={'/admin/simulation/:simulationUUID/manage/pages/edit/:pageUUID'}
                component={EditPagePage}
                exact
            />
        </Switch>
    </ManagePagesPageContainer>
);
