import {
    decodePrepreparedEmailItem,
    NewPrepreparedEmailItem,
    PrepreparedEmailItem,
} from 'polpeo-go-common/types/EmailItem';
import { createPrepreparedEmail } from '../../../../../../graphql/prepreparedEmail/createPrepreparedEmail';
import { editPrepreparedEmail } from '../../../../../../graphql/prepreparedEmail/editPrepreparedEmail';

export const useSavePrepreparedEmail = (): [
    (item: NewPrepreparedEmailItem | PrepreparedEmailItem) => void,
    { data?: PrepreparedEmailItem; loading: boolean },
] => {
    const [
        createPrepreparedEmailMutation,
        { data: createPrepreparedEmailData, loading: createPrepreparedEmailLoading },
    ] = createPrepreparedEmail.hook();

    const [editPrepreparedEmailMutation, { data: editPrepreparedEmailData, loading: editPrepreparedEmailLoading }] =
        editPrepreparedEmail.hook();

    const mutation = (item: NewPrepreparedEmailItem | PrepreparedEmailItem): void => {
        if ('uuid' in item) {
            editPrepreparedEmailMutation({
                variables: {
                    prepreparedEmail: {
                        ...item,
                        released: item.released
                            ? {
                                  ...item.released,
                                  at: item.released.at.toISOString(),
                                  items: JSON.stringify(item.released.items),
                              }
                            : undefined,
                    },
                },
            });
        } else {
            createPrepreparedEmailMutation({
                variables: {
                    prepreparedEmail: item,
                },
            });
        }
    };

    const createdPreprepredEmail = createPrepreparedEmailData?.createPrepreparedEmail;
    const editedPreprepredEmail = editPrepreparedEmailData?.editPrepreparedEmail;
    const rawData = editedPreprepredEmail || createdPreprepredEmail;
    const data = rawData
        ? decodePrepreparedEmailItem({
              ...rawData,
              released: rawData.released
                  ? {
                        ...rawData.released,
                        items: JSON.parse(rawData.released.items),
                    }
                  : undefined,
          })
        : undefined;

    const loading = editPrepreparedEmailLoading || createPrepreparedEmailLoading;

    return [mutation, { data, loading }];
};
