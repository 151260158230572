import gql from 'graphql-tag';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapQuery from '../wrapQuery';

interface GetTriggersParams {
    simulationUUID: string;
}

interface GetTriggersData {
    getTriggers: (Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    })[];
    getTriggerOrder: string[];
    getTriggerPrepreparedContentLinks: string; // AWSJSON Record<string, string[]>
}

const query = gql`
    query getTriggersAndRelatedData($simulationUUID: String!) {
        getTriggers(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            title
            isOptional
            simulatedTime
            participantPopupSettings {
                enabled
                text
                prepreparedContentItemUUID
            }
            staffPopupSettings {
                directions
                guidelines
                expectedActions
            }
            overviewChanges {
                title
                description
                headerImage
            }
            pageDressingChanges
            releasedAt
        }
        getTriggerOrder(simulationUUID: $simulationUUID)
        getTriggerPrepreparedContentLinks(simulationUUID: $simulationUUID)
    }
`;

export const getTriggersAndRelatedData = wrapQuery<GetTriggersParams, GetTriggersData>(query);
