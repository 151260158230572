import { Simulation } from 'polpeo-go-common/types/Simulation';
import { equals, reduce } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { editSimulation } from '../../../../../graphql/simulations/editSimulation';
import { filterAdministrators } from '../../../../../utils/filterStaff';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { Spinner } from '../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../../patterns/Modal';
import { MultiSearchSelection } from '../../../../patterns/MultiSearchSelection';
import { AdminStateContext } from '../../../../WithAdminState/adminState';

interface ManageSimulationAdimistratorsModalProps {
    simulation: Simulation;
    onModalClose: () => void;
}

export const ManageSimulationAdimistratorsModal: FC<ManageSimulationAdimistratorsModalProps> = ({
    simulation,
    onModalClose,
}) => {
    const { setSimulation, staffUsers } = useContext(AdminStateContext);
    const [editSimulationMutation, { data: editSimulationData, loading: editSimulationLoading }] =
        editSimulation.hook();

    const [administratorUUIDs, setAdministratorUUIDs] = useState<string[]>(simulation.administratorUUIDs);

    const administratorStaffUsers = useMemo(() => filterAdministrators(staffUsers), [staffUsers]);
    const multiSearchSelectionOptions = useMemo(
        () =>
            reduce(
                (acc, administrator) => ({ ...acc, [administrator.uuid]: administrator.name }),
                {},
                administratorStaffUsers,
            ),
        [administratorStaffUsers],
    );

    useEffect(() => {
        if (editSimulationData) {
            setSimulation(editSimulationData.editSimulation);
            onModalClose();
        }
    }, [editSimulationData]);

    const hasUnsavedChanges =
        !equals(administratorUUIDs, simulation.administratorUUIDs) && !editSimulationData?.editSimulation;
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onModalClose,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal header="Edit Simulation Administrators" onModalClose={onModalCloseUnsavedPrompt}>
                {editSimulationLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editSimulationLoading && (
                    <Display.VerticalWithSpacing>
                        <div>
                            <MultiSearchSelection
                                options={multiSearchSelectionOptions}
                                value={administratorUUIDs}
                                onChange={setAdministratorUUIDs}
                            />
                        </div>
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                            <PrimaryButton
                                onClick={async () => {
                                    editSimulationMutation({
                                        variables: {
                                            simulation: { ...simulation, administratorUUIDs: administratorUUIDs },
                                        },
                                    });
                                }}
                            >
                                Save
                            </PrimaryButton>
                        </Display.HorizontalWithSpacing>
                    </Display.VerticalWithSpacing>
                )}
            </Modal>
        </>
    );
};
