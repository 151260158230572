import { NewPrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { assocPath, cond, map } from 'ramda';
import React from 'react';
import { TextArea, TextInput } from '../../../../../bits/FormFields';
import { VideoEmbedInput } from '../../../../../bits/FormFields/VideoEmbedInput';
import RichtextInput from '../../../../../patterns/RichtextInput';
import { ContentItemImageInput } from '../../../../ContentItemImageDropZone';
import { FormGridField } from '../../../../FormGrid';

export function makeContentTabFormFields<T extends NewPrepreparedContentItem | PrepreparedContentItem>(
    simulation: Simulation,
    fields: ContentItemField[],
    currentPrepreparedContent: T,
    setCurrentPrepreparedContent: (value: T | ((item: T) => T)) => void,
): FormGridField[] {
    return map(
        (field) => [
            field.displayName || field.name,
            cond([
                [
                    (fieldType) => fieldType === 'TEXT',
                    () => (
                        <TextInput
                            maxLength={
                                field.options && 'maxLength' in field.options ? field.options.maxLength : undefined
                            }
                            key={field.name}
                            onChange={(e) =>
                                setCurrentPrepreparedContent(
                                    assocPath(
                                        ['content', 'data', field.name],
                                        e.target.value,
                                        currentPrepreparedContent,
                                    ),
                                )
                            }
                            value={currentPrepreparedContent.content.data[field.name] || ''}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'LONG_TEXT',
                    () => (
                        <TextArea
                            maxLength={
                                field.options && 'maxLength' in field.options ? field.options.maxLength : undefined
                            }
                            key={field.name}
                            onChange={(e) =>
                                setCurrentPrepreparedContent(
                                    assocPath(
                                        ['content', 'data', field.name],
                                        e.target.value,
                                        currentPrepreparedContent,
                                    ),
                                )
                            }
                            value={currentPrepreparedContent.content.data[field.name] || ''}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'IMAGE',
                    () => (
                        <ContentItemImageInput
                            key={field.name}
                            simulation={simulation}
                            value={currentPrepreparedContent.content.data[field.name] || ''}
                            onChange={(imagePath) =>
                                setCurrentPrepreparedContent(assocPath(['content', 'data', field.name], imagePath))
                            }
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'RICHTEXT',
                    () => (
                        <RichtextInput
                            value={currentPrepreparedContent.content.data[field.name] || ''}
                            imageUploadSimulation={simulation}
                            onChange={(richtext) =>
                                setCurrentPrepreparedContent(assocPath(['content', 'data', field.name], richtext))
                            }
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'VIDEO',
                    () => (
                        <VideoEmbedInput
                            key={field.name}
                            onChange={(e) =>
                                setCurrentPrepreparedContent(
                                    assocPath(
                                        ['content', 'data', field.name],
                                        e.target.value,
                                        currentPrepreparedContent,
                                    ),
                                )
                            }
                            value={currentPrepreparedContent.content.data[field.name] || ''}
                        />
                    ),
                ],
            ])(field.type),
        ],
        fields,
    );
}
