import { useApolloClient } from '@apollo/client';
import { DateTime } from 'luxon';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { assoc, assocPath, map, split, trim } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { uploadTeamOverviewImage } from '../../utils/uploadTeamOverviewImage';
import { SecondaryButton } from '../bits/Buttons';
import { Checkbox, NumberInput, TextArea, TextInput } from '../bits/FormFields';
import { Body3 } from '../bits/Headers';
import { Spinner } from '../bits/Spinner';
import { DatePickerInput } from '../patterns/DatePicker';
import { FormGrid } from '../templates/FormGrid';
import { DropZone } from './DropZone';

interface CalculatePermissableDateTimeData {
    minTime: Date;
    maxTime: Date;
    minDate: Date;
}

export const getScheduledTimeDatepickerConstraints = (selected: Date | undefined): CalculatePermissableDateTimeData => {
    const currentSimulationStartDate = selected && DateTime.fromJSDate(selected);
    const defaultSelectedDate = DateTime.now();

    const isSelectedDateToday = defaultSelectedDate.hasSame(currentSimulationStartDate || defaultSelectedDate, 'day');

    const isDateHistoric =
        currentSimulationStartDate && currentSimulationStartDate?.toISODate() < defaultSelectedDate.toISODate();

    const currentHour = defaultSelectedDate.hour;
    const currentMins = defaultSelectedDate.minute;

    let minTimeHours = 0;
    let minTimeMins = 0;
    let maxTimeHours = 23;
    let maxTimeMins = 59;

    /* 
    If the selected date is today, then we need to restrict the time options to some
    time after the present time. i.e. if the time is 16:01 we don't want to be able to 
    select any time prior to this.
    If this is not resrtricted to today, then it prevents the same times on all subsequent
    dates in the future.
    */

    if (isSelectedDateToday) {
        minTimeHours = currentHour;
        minTimeMins = currentMins;
    }

    /*
    If the currently selected date is earlier than today's date, then we need to 
    set the permitted days and hours to 0, so there are no acceptable times
    for the historic days.
    */

    if (isDateHistoric) {
        maxTimeHours = 0;
        maxTimeMins = 0;
    }

    const minTimeObj = defaultSelectedDate.set({
        hour: minTimeHours,
        minute: minTimeMins,
    });
    const maxTimeObj = defaultSelectedDate.set({
        hour: maxTimeHours,
        minute: maxTimeMins,
    });

    const maxMinTimeData = {
        minTime: minTimeObj.toJSDate(),
        maxTime: maxTimeObj.toJSDate(),
        minDate: defaultSelectedDate.toJSDate(),
    };

    return maxMinTimeData;
};

type SimulationFormFields =
    | 'name'
    | 'clientName'
    | 'emailDomainWhitelist'
    | 'expectedNumberParticipants'
    | 'scheduledFor'
    | 'showParticipantsMomentsGraph'
    | 'createMomentsPermission'
    | 'startingOverview';
type SimulationFormData = Pick<Simulation, SimulationFormFields>;

interface SimulationFormProps {
    currentSimulation: SimulationFormData;
    onChange: (sim: SimulationFormData) => void;
    onSubmit?: (sim: SimulationFormData) => void;
    footer?: JSX.Element;
    uploadOptions:
        | {
              simulation: Simulation;
          }
        | {
              onStartingImageUpload: (file: File) => void;
          };
    disableInputs?: Record<SimulationFormFields, boolean | undefined>;
}

export const SimulationForm: FC<SimulationFormProps> = ({
    currentSimulation,
    onChange,
    onSubmit,
    footer,
    uploadOptions,
    disableInputs,
}) => {
    const client = useApolloClient();
    const [startingOverviewImageUploading, setStartingOverviewImageUploading] = useState(false);
    const { minDate, minTime, maxTime } = getScheduledTimeDatepickerConstraints(currentSimulation.scheduledFor);

    useEffect(() => {
        if (!currentSimulation.createMomentsPermission.length) {
            onChange(assoc('showParticipantsMomentsGraph', false, currentSimulation));
        }
    }, [currentSimulation.createMomentsPermission]);

    return (
        <FormGrid
            onSubmit={async () => {
                if (onSubmit) onSubmit(currentSimulation);
            }}
            fields={[
                [
                    'Simulation Name',
                    <>
                        <TextInput
                            placeholder="Simulation Name"
                            value={currentSimulation.name}
                            onChange={(e) => onChange(assoc('name', e.target.value, currentSimulation))}
                            disabled={disableInputs?.name}
                        />
                        <Body3>The simulation&apos;s name, visible to all participants</Body3>
                    </>,
                ],
                [
                    'Client Name',
                    <>
                        <TextInput
                            key="Client Name"
                            placeholder="Client Name"
                            value={currentSimulation.clientName}
                            onChange={(e) => onChange(assoc('clientName', e.target.value, currentSimulation))}
                            disabled={disableInputs?.clientName}
                        />
                        <Body3>The client name will be attached to participants names in the simulation</Body3>
                    </>,
                ],
                [
                    'Whitelist',
                    <>
                        <TextInput
                            placeholder="Whitelist domains"
                            value={currentSimulation.emailDomainWhitelist.join(',')}
                            onChange={(e) => {
                                const emailList = map(trim, split(',', e.target.value));
                                onChange(assoc('emailDomainWhitelist', emailList, currentSimulation));
                            }}
                            disabled={disableInputs?.emailDomainWhitelist}
                        />
                        <Body3>
                            Enter a comma after each domain <br /> The second part of the email address e.g. polpeo.com,
                            polpeo.co.uk
                        </Body3>
                    </>,
                ],
                [
                    'Expected Participants',
                    <NumberInput
                        key="ExpectedParticipants"
                        placeholder="Approximate number of participants"
                        value={currentSimulation.expectedNumberParticipants}
                        onChange={(e) =>
                            onChange(
                                assoc(
                                    'expectedNumberParticipants',
                                    e.target.value ? e.target.value : undefined,
                                    currentSimulation,
                                ),
                            )
                        }
                        disabled={disableInputs?.expectedNumberParticipants}
                    />,
                ],
                [
                    'Scheduled for',
                    <>
                        <DatePickerInput
                            selected={currentSimulation.scheduledFor}
                            minDate={minDate}
                            minTime={minTime}
                            maxTime={maxTime}
                            placeholderText="Scheduled for"
                            onChange={(date: Date) => {
                                onChange(assoc('scheduledFor', date || undefined, currentSimulation));
                            }}
                            disabled={disableInputs?.scheduledFor}
                            isClearable={!disableInputs?.scheduledFor}
                        />
                        <Body3>Always scheduled in relation to the current timezone of this device</Body3>
                    </>,
                ],
                [
                    'Allow Staff to Create Highlights',
                    <>
                        <Checkbox
                            id="createHighlights"
                            checked={!!currentSimulation.createMomentsPermission.length}
                            onChange={() =>
                                onChange(
                                    assoc(
                                        'createMomentsPermission',
                                        currentSimulation.createMomentsPermission.length ? [] : ['ADMIN', 'ROLEPLAYER'],
                                        currentSimulation,
                                    ),
                                )
                            }
                            disabled={disableInputs?.createMomentsPermission}
                        />
                    </>,
                ],
                ...(currentSimulation.createMomentsPermission.length
                    ? [
                          [
                              'Show Highlights Graph to Participants',
                              <>
                                  <Checkbox
                                      id="participantsGraph"
                                      checked={currentSimulation.showParticipantsMomentsGraph}
                                      onChange={() =>
                                          onChange(
                                              assoc(
                                                  'showParticipantsMomentsGraph',
                                                  !currentSimulation.showParticipantsMomentsGraph,
                                                  currentSimulation,
                                              ),
                                          )
                                      }
                                      disabled={
                                          disableInputs?.showParticipantsMomentsGraph ||
                                          !currentSimulation.createMomentsPermission.length
                                      }
                                  />
                                  <Body3>This is disabled automatically when highlights cannot be created</Body3>
                              </>,
                          ] as const,
                      ]
                    : []),
                [
                    'Starting Overview Image',
                    <>
                        {startingOverviewImageUploading && <Spinner />}
                        {!startingOverviewImageUploading && uploadOptions && (
                            <>
                                {currentSimulation.startingOverview?.headerImage && (
                                    <>
                                        <SecondaryButton
                                            onClick={() =>
                                                onChange(
                                                    assocPath(
                                                        ['startingOverview', 'headerImage'],
                                                        undefined,
                                                        currentSimulation,
                                                    ),
                                                )
                                            }
                                            disabled={disableInputs?.startingOverview}
                                        >
                                            Remove
                                        </SecondaryButton>
                                        <img src={currentSimulation.startingOverview?.headerImage} />
                                    </>
                                )}
                                <DropZone
                                    accept="image/*"
                                    disabled={disableInputs?.startingOverview}
                                    onAcceptedFiles={async (files: File[]) => {
                                        if (files.length === 1) {
                                            if ('onStartingImageUpload' in uploadOptions) {
                                                uploadOptions.onStartingImageUpload(files[0]);
                                                return;
                                            }

                                            setStartingOverviewImageUploading(true);
                                            const imageFile = files[0];
                                            const imagePath = await uploadTeamOverviewImage(
                                                client,
                                                uploadOptions.simulation,
                                                imageFile,
                                            );
                                            onChange(
                                                assocPath(
                                                    ['startingOverview', 'headerImage'],
                                                    imagePath,
                                                    currentSimulation,
                                                ),
                                            );
                                            setStartingOverviewImageUploading(false);
                                        }
                                    }}
                                >
                                    <p>Drag and drop your image here or click to select a image</p>
                                </DropZone>
                            </>
                        )}
                    </>,
                ],
                [
                    'Starting Overview Title',
                    <>
                        <TextInput
                            value={currentSimulation.startingOverview?.title || ''}
                            onChange={(e) => {
                                onChange(assocPath(['startingOverview', 'title'], e.target.value, currentSimulation));
                            }}
                            disabled={disableInputs?.startingOverview}
                        />
                    </>,
                ],
                [
                    'Starting Overview Description',
                    <>
                        <TextArea
                            value={currentSimulation.startingOverview?.description || ''}
                            onChange={(e) => {
                                onChange(
                                    assocPath(['startingOverview', 'description'], e.target.value, currentSimulation),
                                );
                            }}
                            disabled={disableInputs?.startingOverview}
                        />
                    </>,
                ],
            ]}
            footer={footer}
        />
    );
};
