import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { ContentItemsListingPageLayoutType, PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { Team } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { anyPass, filter, last, map, sortBy } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { getChildrenForItem } from '../../../utils/getChildrenForItem';
import { getCreatorForItem } from '../../../utils/getCreatorForItem';
import { getDescendentsForItem } from '../../../utils/getDescendentsForItem';
import { sortContentItems } from '../../../utils/sortContentItems';
import { Display } from '../../bits/Display';
import { NewChildContentItemButton, NewContentItemButton } from '../../patterns/ContentItem';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { ChildrenItemsList, ContentItemsList as BaseContentItemsList, PageContainer } from './components';
import { ContentItemComponent, ContentItemOptions } from './ContentItem';
import { getChatPosition, LeftChatBubbleCssSnippet, RightChatBubbleCssSnippet } from './ChatBubbleUtils';
import { dummyUsers, pageTemplates } from './templates';
import { UserDisplayName } from './UserDisplayName';
import { BaseDiv } from './utils';

export const cssChildContentIndentation = css`
    margin-top: 5px;

    border-left: solid 1px #dddddd;
    padding-left: 10px;
`;

interface ContentItemsListProps {
    layout:
        | { type: ContentItemsListingPageLayoutType.LIST | ContentItemsListingPageLayoutType.CHAT }
        | {
              type: ContentItemsListingPageLayoutType.GRID;
              columns?: number;
          }
        | {
              type: ContentItemsListingPageLayoutType.GRID;
              columnWidth?: number;
          };
}
const ContentItemsList = styled(BaseContentItemsList)<ContentItemsListProps>`
    ${({ layout }) => (layout.type !== ContentItemsListingPageLayoutType.GRID ? 'grid-template-columns: auto;' : '')}
    ${({ layout }) =>
        layout.type === ContentItemsListingPageLayoutType.GRID && 'columns' in layout
            ? `grid-template-columns: repeat(${layout.columns}, min-content);`
            : ''}
    ${({ layout }) =>
        layout.type === ContentItemsListingPageLayoutType.GRID && 'columnWidth' in layout
            ? `grid-template-columns: repeat(auto-fit, ${layout.columnWidth}px);`
            : ''}
`;

interface ContentItemChildrenProps {
    page?: Page;
    template: PageTemplate;
    parent: ContentItem;
    items: ContentItem[];
    wrapInContainer?: boolean;
    depth: number;
    contentItemOptions?: ContentItemOptions;
    disableCreateContentItems?: boolean;
}
const ContentItemChildren: FC<ContentItemChildrenProps> = (props) => {
    // Destructure this here as ContentItemChildren is a recursive function and we
    // want to pass the same props down to the next call
    const { page, template, parent, items, wrapInContainer, depth, contentItemOptions, disableCreateContentItems } =
        props;
    const theme = template.listingPage.theme;
    const maxReplies =
        template.childrenSettings.enabled && template.listingPage.childrenSettings.location
            ? template.listingPage.childrenSettings.max
            : 0;
    const maxDepth = template.childrenSettings.enabled ? template.childrenSettings.maxDepth : 0;
    const renderNestedChildren = maxDepth ? depth < maxDepth : true;

    const children = useMemo(() => {
        const itemsToDisplay =
            depth === maxDepth
                ? getDescendentsForItem(items, parent, maxReplies)
                : getChildrenForItem(items, parent, maxReplies);
        return sortContentItems(template.listingPage.sortBy, itemsToDisplay, items);
    }, [parent, items, depth, maxDepth]);

    if (!children.length) {
        return wrapInContainer ? (
            <ChildrenItemsList theme={theme.childrenItemsContainer}>
                {page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}
            </ChildrenItemsList>
        ) : (
            <>{page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}</>
        );
    }

    const childrenElements: JSX.Element[] = map(
        (child) => (
            <BaseDiv
                key={child.uuid}
                id={child.uuid}
                theme={template.listingPage.theme.childItemContainer}
                extraCSS={cssChildContentIndentation}
            >
                <ContentItemComponent
                    item={child}
                    template={template}
                    theme={theme.childItem}
                    fields={template.childItemOptions.fields}
                    fieldOrder={template.listingPage.childContentFieldOrder}
                    options={{ ...contentItemOptions, disableDetailsLink: true }}
                >
                    {renderNestedChildren && template.listingPage.childrenSettings.location === 'inline' && (
                        <ContentItemChildren {...props} parent={child} wrapInContainer={false} depth={depth + 1} />
                    )}
                </ContentItemComponent>
                {renderNestedChildren && template.listingPage.childrenSettings.location === 'append' && (
                    <ContentItemChildren {...props} parent={child} wrapInContainer={false} depth={depth + 1} />
                )}
            </BaseDiv>
        ),
        children,
    );
    return wrapInContainer ? (
        <ChildrenItemsList theme={theme.childrenItemsContainer}>
            {page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}
            {childrenElements}
        </ChildrenItemsList>
    ) : (
        <>
            {page && !disableCreateContentItems && <NewChildContentItemButton page={page} parent={parent} />}
            {childrenElements}
        </>
    );
};

type ContentListPageProps = {
    items: ContentItem[];
    trigger?: Trigger;
    team?: Team;
    contentItemOptions?: ContentItemOptions;
    disableCreateContentItems?: boolean;
} & (
    | {
          page: Page;
      }
    | {
          template: PageTemplate;
      }
);
export const ContentListPage: FC<ContentListPageProps> = ({
    items,
    trigger,
    team,
    contentItemOptions,
    disableCreateContentItems,
    ...props
}) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const adminData = useContext(AdminInSimulationStateContext);
    const participantData = useContext(ParticipantStateContext);

    const template = 'template' in props ? props.template : pageTemplates[props.page.templateUUID];
    const page = 'page' in props ? props.page : undefined;
    const theme = template.listingPage.theme;

    const rootContent = useMemo(() => {
        const itemsToDisplay = filter((item) => !item.parentUUID, items);
        return sortContentItems(template.listingPage.sortBy, itemsToDisplay, items);
    }, [items]);

    const createRootContentPermission = page?.createRootContentPermission;
    const userCanCreateRootContent = anyPass([
        () => createRootContentPermission === 'BOTH',
        () =>
            createRootContentPermission === 'ADMINS_ONLY' &&
            !!staffUser &&
            userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser),
        () =>
            createRootContentPermission === 'STAFF' &&
            !!staffUser &&
            userCan(PermissionKey.PARTICIPATE_IN_SIMULATIONS, staffUser),
        () => createRootContentPermission === 'PARTICIPANTS' && !!participantUser,
    ])();
    const useChatLayout = template.listingPage.layout.type === ContentItemsListingPageLayoutType.CHAT;

    return (
        <PageContainer key={template.uuid} pageTheme={theme.page} page={page} team={team} trigger={trigger}>
            {/* horizontalAlign = start to keep add content buttons from stretching */}
            <Display.VerticalWithSpacing horizontalAlign="start">
                {!disableCreateContentItems && userCanCreateRootContent && page && <NewContentItemButton page={page} />}
                <ContentItemsList layout={template.listingPage.layout} theme={theme.rootItemsContainer}>
                    {!useChatLayout &&
                        map((item) => {
                            return (
                                <React.Fragment key={item.uuid}>
                                    <ContentItemComponent
                                        item={item}
                                        template={template}
                                        theme={theme.rootItem}
                                        fields={template.rootItemOptions.fields}
                                        fieldOrder={template.listingPage.rootContentFieldOrder}
                                        options={contentItemOptions}
                                        isGrid={
                                            template.listingPage.layout.type === ContentItemsListingPageLayoutType.GRID
                                        }
                                    >
                                        {template.listingPage.childrenSettings.location === 'inline' && (
                                            <ContentItemChildren
                                                page={page}
                                                template={template}
                                                parent={item}
                                                items={items}
                                                wrapInContainer
                                                depth={1}
                                                contentItemOptions={contentItemOptions}
                                                disableCreateContentItems={disableCreateContentItems}
                                            />
                                        )}
                                    </ContentItemComponent>
                                    {template.listingPage.childrenSettings.location === 'append' && (
                                        <ContentItemChildren
                                            page={page}
                                            template={template}
                                            parent={item}
                                            items={items}
                                            wrapInContainer
                                            depth={1}
                                            contentItemOptions={contentItemOptions}
                                            disableCreateContentItems={disableCreateContentItems}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        }, rootContent)}
                    {useChatLayout &&
                        map((item) => {
                            const rootItemCreator = getCreatorForItem(item, {
                                participants: adminData.participants || participantData.participants || dummyUsers,
                            });
                            if (!rootItemCreator) {
                                return null;
                            }
                            const children = sortBy((i) => i.createdAt.toISOString(), getChildrenForItem(items, item));
                            const latestChild = last(children) || item;

                            const chatBubblePosition = getChatPosition(latestChild, {
                                items,
                                staffUser,
                                participantUser,
                            });

                            const contentItemChildrenCount = (() => {
                                const descendants = getDescendentsForItem(items, item);
                                return descendants.length;
                            })();

                            return (
                                <Link
                                    key={item.uuid}
                                    to={({ pathname }) => `${pathname}/${item.uuid}#${latestChild.uuid}`}
                                >
                                    <BaseDiv theme={theme.rootItem}>
                                        Chat started by{' '}
                                        <UserDisplayName
                                            as={'span'}
                                            creator={rootItemCreator}
                                            theme={theme.rootItem.creator}
                                        />
                                        {theme.rootItem.showChildItemsCount && (
                                            <BaseDiv theme={theme.rootItem.showChildItemsCount}>
                                                {contentItemChildrenCount +
                                                    ' ' +
                                                    (contentItemChildrenCount === 1
                                                        ? template.childItemOptions.name
                                                        : template.childItemOptions.pluralName)}
                                            </BaseDiv>
                                        )}
                                        <ContentItemComponent
                                            item={latestChild}
                                            template={template}
                                            theme={theme.childItem}
                                            fields={template.childItemOptions.fields}
                                            fieldOrder={template.detailsPage.childContentFieldOrder}
                                            options={{ disableDetailsLink: true, disableManageMenu: true }}
                                            extraCSS={
                                                chatBubblePosition === 'RIGHT'
                                                    ? RightChatBubbleCssSnippet
                                                    : LeftChatBubbleCssSnippet
                                            }
                                        />
                                    </BaseDiv>
                                </Link>
                            );
                        }, rootContent)}
                </ContentItemsList>
            </Display.VerticalWithSpacing>
        </PageContainer>
    );
};
