import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { reduce, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { deletePrepreparedContent } from '../../../../../../../graphql/prepreparedContent/deletePrepreparedContent';
import { updateTriggerPrepreparedContentLinks } from '../../../../../../../graphql/triggers';
import { destructiveColour } from '../../../../../../../themes/colours';
import { getDescendentsForItem } from '../../../../../../../utils/getDescendentsForItem';
import { IconButton } from '../../../../../../bits/Buttons/IconButton';
import { Body2 } from '../../../../../../bits/Headers';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../../../../WithAdminState/adminState';
import { DeleteModal } from '../../../../../DeleteModal';
import { EmbeddedContentItem } from '../../../../../PageTemplate/EmbeddedContentItem';

interface DeletePrepreparedContentButtonProps {
    item: PrepreparedContentItem;
}
export const DeletePrepreparedContentButton: FC<DeletePrepreparedContentButtonProps> = ({ item }) => {
    const [showModal, setShowModal] = useState(false);
    const { removePrepreparedContentItems, setTriggerPrepreparedContentLinks } = useContext(AdminStateContext);
    const { currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedContents } = currentSimulationContent;
    const descendants = useMemo(
        () =>
            showModal
                ? getDescendentsForItem(values(prepreparedContents as Record<string, PrepreparedContentItem>), item)
                : [],
        [showModal, prepreparedContents],
    );

    const [
        deletePrepreparedContentMutation,
        { data: deletePrepreparedContentData, loading: deletePrepreparedContentLoading },
    ] = deletePrepreparedContent.hook();

    const [
        updateTriggerPrepreparedContentLinksMutation,
        { data: updateTriggerPrepreparedContentLinksData, loading: updateTriggerPrepreparedContentLinksLoading },
    ] = updateTriggerPrepreparedContentLinks.hook();

    useEffect(() => {
        const deletedItemAndDescendentUUIDs = deletePrepreparedContentData?.deletePrepreparedContent;
        const updatedTriggerLinks = updateTriggerPrepreparedContentLinksData?.updateTriggerPrepreparedContentLinks;

        // Deleted Item but not yet updated trigger links
        if (deletedItemAndDescendentUUIDs && !updatedTriggerLinks && !updateTriggerPrepreparedContentLinksLoading) {
            const UUIDsToUpdate = reduce(
                (acc, id: string) => {
                    return { ...acc, [id]: '' };
                },
                {},
                deletedItemAndDescendentUUIDs,
            );
            updateTriggerPrepreparedContentLinksMutation({
                variables: {
                    simulationUUID: item.simulationUUID,
                    newLinks: JSON.stringify(UUIDsToUpdate),
                },
            });
        }

        // Deleted Item and trigger links updated
        if (deletedItemAndDescendentUUIDs && updatedTriggerLinks) {
            removePrepreparedContentItems(item.simulationUUID, deletedItemAndDescendentUUIDs);
            setTriggerPrepreparedContentLinks(item.simulationUUID, JSON.parse(updatedTriggerLinks));
            setShowModal(false);
        }
    }, [
        deletePrepreparedContentData,
        updateTriggerPrepreparedContentLinksData,
        updateTriggerPrepreparedContentLinksLoading,
    ]);

    return (
        <>
            <IconButton icon="delete" width={20} fill={destructiveColour} onClick={() => setShowModal(true)} />
            {showModal && (
                <DeleteModal
                    header="Delete Preprepared Content"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={
                        <>
                            Are you sure you want to delete this preprepared content item?
                            <EmbeddedContentItem item={item} />
                            {!!descendants.length && (
                                <Body2 destructive>
                                    This content item has {descendants.length} descendants. All descendants items will
                                    also be deleted.
                                </Body2>
                            )}
                            {!!item.released && (
                                <Body2 destructive>
                                    This content item was released at {item.released.at.toISOString()}. The preprepared
                                    item can be deleted but any &quot;spawned&quot; children will not be deleted
                                </Body2>
                            )}
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() =>
                        deletePrepreparedContentMutation({
                            variables: {
                                prepreparedContent: {
                                    ...item,
                                    content: { ...item.content, data: JSON.stringify(item.content.data) },
                                    released: item.released
                                        ? {
                                              ...item.released,
                                              at: item.released.at.toISOString(),
                                              items: JSON.stringify(item.released.items),
                                          }
                                        : undefined,
                                },
                            },
                        })
                    }
                    showSpinner={deletePrepreparedContentLoading || updateTriggerPrepreparedContentLinksLoading}
                />
            )}
        </>
    );
};
