import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import React, { FC, useContext, useState } from 'react';
import { IconButton } from '../../../../../../bits/Buttons/IconButton';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { PrepreparedEmailFormModal } from '../../PrepreparedEmailFormModal';

interface DuplicatePrepreparedEmailButtonProps {
    copyOf: PrepreparedEmailItem;
}
export const DuplicatePrepreparedEmailButton: FC<DuplicatePrepreparedEmailButtonProps> = ({ copyOf }) => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <IconButton icon="duplicate" width={20} onClick={() => setShowModal(true)} />
            {showModal && (
                <PrepreparedEmailFormModal
                    simulation={currentSimulation}
                    onCancel={() => setShowModal(false)}
                    onSaveComplete={() => setShowModal(false)}
                    copyOf={copyOf}
                />
            )}
        </>
    );
};
