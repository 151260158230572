// Note that this is based on what AWS SES and Cognito can handle and not
// based on what is "correct"
// https://docs.aws.amazon.com/ses/latest/APIReference/API_Destination.html

// At the time of writing it says:
// Amazon SES does not support the SMTPUTF8 extension, as described in RFC6531.
// For this reason, the local part of a destination email address (the part of the
// email address that precedes the @ sign) may only contain 7-bit ASCII characters.
// If the domain part of an address (the part after the @ sign) contains non-ASCII
// characters, they must be encoded using Punycode, as described in RFC3492.

import { head, join, split, tail, toLower } from 'ramda';
import * as punycode from 'punycode';

const isASCII = (str: string): boolean => {
    return /^[\x00-\x7F]*$/.test(str);
};

export const toValidEmail = (email: string): string => {
    const lowerEmail = toLower(email);
    const splitEmail = split('@', lowerEmail);

    if (splitEmail.length < 2) {
        throw new Error('Email did not contain an @ symbol');
    }

    const local = head(splitEmail) as string;
    const domain = join('', tail(splitEmail));

    if (!local.length) {
        throw new Error('Local part of email does not exist');
    }

    if (!domain.length) {
        throw new Error('Domain part of email does not exist');
    }

    if (!isASCII(local)) {
        throw new Error('Local part of email is not ASCII only');
    }

    const punycodeDomain = punycode.toASCII(domain);

    return join('@', [local, punycodeDomain]);
};
