import { reduceWhile, tail } from 'ramda';
import { getChildrenForItem } from './getChildrenForItem';

export function getDescendentsForItem<T extends { uuid: string; parentUUID?: string }>(
    items: T[],
    item: T,
    max?: number,
): T[] {
    return reduceWhile(
        (acc) => (max ? acc.length <= max : true),
        (acc, childItem) => {
            let descendantsToAdd = [childItem];
            let getChildrenFor = [childItem];
            do {
                const newDescendants = getChildrenForItem(items, getChildrenFor[0]);
                descendantsToAdd = [...descendantsToAdd, ...newDescendants];
                getChildrenFor = [...tail(getChildrenFor), ...newDescendants];
            } while (getChildrenFor.length);
            return [...acc, ...descendantsToAdd];
        },
        [] as T[],
        getChildrenForItem(items, item),
    );
}
