import { DateTime } from 'luxon';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { join } from 'ramda';

export const dateTimestampRelativeToSimStart = (simulation: Simulation, date: Date): string => {
    const eventDate = DateTime.fromJSDate(date);
    if (!simulation.startedAt) {
        return '';
    }
    const simulationStartedAt = DateTime.fromJSDate(simulation.startedAt);
    const diffFromSimulationStart = eventDate.diff(simulationStartedAt, ['seconds', 'minutes', 'hours']);
    return `${join(':', [
        Math.max(diffFromSimulationStart.hours, 0).toString().padStart(2, '0'),
        Math.max(diffFromSimulationStart.minutes, 0).toString().padStart(2, '0'),
        Math.max(Math.round(diffFromSimulationStart.seconds), 0).toString().padStart(2, '0'),
    ])} from start`;
};
