import gql from 'graphql-tag';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import wrapMutation from '../wrapMutation';

interface DeleteStaffUserParams {
    staffUser: StaffUser;
}

interface DeleteStaffUserData {
    deleteStaffUser: string;
}

const mutation = gql`
    mutation deleteStaffUser($staffUser: StaffUserInput!) {
        deleteStaffUser(staffUser: $staffUser)
    }
`;

export const deleteStaffUser = wrapMutation<DeleteStaffUserParams, DeleteStaffUserData>(mutation);
