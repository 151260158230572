import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { evolve, flatten, includes, isNil, keys, map, pickAll, pluck, reduce, reject, trim, uniq, values } from 'ramda';
import { filterRoleplayers } from '../../../../../../utils/filterStaff';
import { findStaffWithEmail } from '../../../../../../utils/findStaff';

const teamValidationTransformations = {
    roleplayerEmails: (val: unknown) => {
        if (!val) {
            return undefined;
        }
        if (!Array.isArray(val)) {
            return 'Teams, roleplayerEmails: Supplied list of roleplayers was not an array';
        }
        const roleplayerEmails = map(trim, val);
        return map(
            (rolePlayerEmail) => `Teams, roleplayerEmails: '${rolePlayerEmail}' is not a valid email address`,
            reject((rolePlayerEmail) => includes('@', rolePlayerEmail), roleplayerEmails),
        );
    },
};

const checkRoleplayerExistsForEmail = (emails: string[], staffUsers: StaffUser[]): string[] =>
    reduce<string, string[]>(
        (acc, email) => {
            if (!findStaffWithEmail(filterRoleplayers(staffUsers), email)) {
                return [
                    ...acc,
                    // eslint-disable-next-line max-len
                    `Teams, roleplayerEmails: roleplayer with email '${email}' could not be found. Please make sure this roleplayer user already exists in the application`,
                ];
            }
            return acc;
        },
        [],
        emails,
    );

const getErrorsForTeamsSheet = (teamsData: Record<string, unknown>[], staffUsers: StaffUser[]): string[] => {
    const fieldsToValidate = keys(teamValidationTransformations);
    const errorMap = map(
        (teamData) =>
            evolve(
                teamValidationTransformations,
                pickAll<Record<string, unknown>, Record<string, unknown[]>>(fieldsToValidate, teamData),
            ),
        teamsData,
    );
    const errorMessages = reject(isNil, flatten(map(values, errorMap))) as string[];
    const roleplayerEmailsAggregation = uniq(
        flatten(pluck<string, Record<string, unknown>>('roleplayerEmails', teamsData)),
    ) as string[];
    return [...errorMessages, ...checkRoleplayerExistsForEmail(roleplayerEmailsAggregation, staffUsers)];
};

export default getErrorsForTeamsSheet;
