import React, { forwardRef, ForwardRefExoticComponent } from 'react';
import styled from 'styled-components/macro';
import { Icon, IconOptions } from '../../bits/Icon';

const FABButton = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e) => {
        if (e.target === e.currentTarget && (!type || type === 'button')) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    position: relative;
    width: 75px;
    height: 75px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: -2px 3px 7px rgb(0 0 0 / 0.2);
`;

const NotificationDot = styled.div`
    width: 10px;
    height: 10px;

    position: absolute;
    top: 5px;
    right: 5px;

    background-color: #c51b32;
    border-radius: 50%;
`;

interface FloatingActionButtonProps {
    icon: IconOptions;
    onClick: () => void;
    notification?: boolean;
    ref?: React.Ref<HTMLButtonElement>;
}
export const FloatingActionButton: ForwardRefExoticComponent<FloatingActionButtonProps> = forwardRef(
    ({ icon, onClick, notification }, ref) => {
        return (
            // Workaround https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28884
            <FABButton onClick={onClick} ref={ref as never}>
                {notification && <NotificationDot />}
                <Icon icon={icon} size={25} />
            </FABButton>
        );
    },
);
FloatingActionButton.displayName = 'FloatingActionButton';
