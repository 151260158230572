import styled from 'styled-components/macro';

export const DocumentLabel = styled.span`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;

    overflow-wrap: anywhere;
`;
