import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../bits/Buttons';
import { Icon } from '../../bits/Icon';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { StaffEmailItemFormModal } from './StaffEmailItemFormModal';
import { ParticipantEmailItemFormModal } from './ParticipantEmailItemFormModal';

export const NewEmailItemButton: FC = () => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton small onClick={() => setShowModal(true)}>
                <Icon icon="add" />
                New Email
            </SecondaryButton>
            {showModal && staffUser && <StaffEmailItemFormModal onCloseModal={() => setShowModal(false)} />}
            {showModal && participantUser && <ParticipantEmailItemFormModal onCloseModal={() => setShowModal(false)} />}
        </>
    );
};
