import gql from 'graphql-tag';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapQuery from '../wrapQuery';

interface GetPrepreparedEmailsParams {
    simulationUUID: string;
    startFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
}

interface GetPrepreparedEmailsData {
    getPrepreparedEmails: {
        prepreparedEmails: GraphQlPrepreparedEmailItem[];
        continueFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
    };
}

const query = gql`
    query getPrepreparedEmails($simulationUUID: String!, $startFrom: AWSJSON) {
        getPrepreparedEmails(simulationUUID: $simulationUUID, startFrom: $startFrom) {
            prepreparedEmails {
                type
                uuid
                simulationUUID
                parentUUID
                persona
                content {
                    recipients
                    subject
                    message
                }
                released {
                    at
                    items
                }
            }
            continueFrom
        }
    }
`;

export const getPrepreparedEmails = wrapQuery<GetPrepreparedEmailsParams, GetPrepreparedEmailsData>(query);
