import { Moment } from 'polpeo-go-common/types/Moment';
import { ParticipantTeamAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { Team } from 'polpeo-go-common/types/Team';
import { forEach, map, reduce, sortBy, toPairs, values } from 'ramda';
import XLSX from 'xlsx';
import { SimulationContent } from '../../../../WithAdminState/adminState';
import { formatDateForExcel } from '../../../../../utils/formatDateForExcel';

export const makeMomentsWorkbook = (
    simulation: Simulation,
    users: { staffUsers: Record<string, StaffUser | undefined>; participants: Record<string, ParticipantUser> },
    teams: Team[],
    teamAssignments: ParticipantTeamAssignments,
    simulationContent: SimulationContent,
): XLSX.WorkBook => {
    const { staffUsers, participants } = users;

    const workbook = XLSX.utils.book_new();

    // Teams Sheet
    const administratorRows = map<string, string[]>((uuid) => {
        const staffUser = staffUsers[uuid];
        return staffUser ? [staffUser.role, staffUser.name, staffUser.email] : ['deleted user'];
    }, simulation.administratorUUIDs);
    const teamsRows = reduce(
        (rows, team) => {
            const teamStaffRows = map((uuid) => {
                const staffUser = staffUsers[uuid];
                return staffUser ? [staffUser.role, staffUser.name, staffUser.email] : ['deleted user'];
            }, team.roleplayerUUIDs);

            const teamParticipantRows = reduce(
                (acc, [participantID, assignment]) => {
                    if (assignment.teamUUID !== team.uuid) {
                        return acc;
                    }
                    const participant = participants[participantID];
                    if (!participant) {
                        return acc;
                    }
                    return [
                        ...acc,
                        [
                            'PARTICIPANT',
                            participant.fullName,
                            participant.email,
                            participant.marketingConsent ? 'YES MARKETING' : 'NO MARKETING',
                        ],
                    ];
                },
                [] as string[][],
                toPairs(teamAssignments),
            );
            return [...rows, [team.name], ...teamStaffRows, ...teamParticipantRows, ['']];
        },
        [] as string[][],
        teams,
    );
    const teamsSheet = XLSX.utils.aoa_to_sheet([['administrators'], ...administratorRows, [''], ...teamsRows]);
    XLSX.utils.book_append_sheet(workbook, teamsSheet, 'Teams');

    // Team Contents Sheet
    forEach((team) => {
        const momentsMap = simulationContent.moments[team.uuid] || {};
        const momentsList = sortBy((item) => item.created.at.toISOString(), values(momentsMap) as Moment[]);

        const momentsSheet = XLSX.utils.json_to_sheet(
            map((moment) => {
                return {
                    createdAt: formatDateForExcel(moment.created.at),
                    createdBy: staffUsers[moment.created.by]?.email,
                    rating: moment.rating,
                    comments: moment.comments,
                    itemUUID: moment.contentItem.uuid,
                };
            }, momentsList),
        );
        XLSX.utils.book_append_sheet(workbook, momentsSheet, `${team.name} - Highlights`);
    }, teams);

    return workbook;
};
