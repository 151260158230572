import gql from 'graphql-tag';
import { Team } from 'polpeo-go-common/types/Team';
import wrapSubscription from '../wrapSubscription';

interface OnEditTeamParams {
    simulationUUID: string;
}

interface OnEditTeamData {
    onEditTeam: Omit<Team, 'pageStats'> & {
        pageStats: string /* AWSJSON - Record<pageUUID, Record<statKey, statValue>> */;
    };
}

const subscription = gql`
    subscription onEditTeam($simulationUUID: String!) {
        onEditTeam(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            name
            roleplayerUUIDs
            documentUUIDs
            overview {
                title
                description
                headerImage
            }
            pageStats
        }
    }
`;

export const onEditTeam = wrapSubscription<OnEditTeamParams, OnEditTeamData>(subscription);
