import gql from 'graphql-tag';
import { Page } from 'polpeo-go-common/types/Page';
import wrapMutation from '../wrapMutation';

interface DeletePageParams {
    page: Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

interface DeletePageData {
    deletePage: {
        pageUUID: string;
        prepreparedContentUUIDs: string[];
    };
}

const mutation = gql`
    mutation deletePage($page: PageInput!) {
        deletePage(page: $page) {
            pageUUID
            prepreparedContentUUIDs
        }
    }
`;

export const deletePage = wrapMutation<DeletePageParams, DeletePageData>(mutation);
