import gql from 'graphql-tag';
import { NewTrigger, Trigger } from 'polpeo-go-common/types/Trigger';
import wrapMutation from '../wrapMutation';

interface CreateTriggerParams {
    trigger: Omit<NewTrigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

interface CreateTriggerData {
    createTrigger: Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

const mutation = gql`
    mutation createTrigger($trigger: NewTriggerInput!) {
        createTrigger(trigger: $trigger) {
            uuid
            simulationUUID
            title
            isOptional
            simulatedTime
            participantPopupSettings {
                enabled
                text
                prepreparedContentItemUUID
            }
            staffPopupSettings {
                directions
                guidelines
                expectedActions
            }
            overviewChanges {
                title
                description
                headerImage
            }
            pageDressingChanges
            releasedAt
        }
    }
`;

export const createTrigger = wrapMutation<CreateTriggerParams, CreateTriggerData>(mutation);
