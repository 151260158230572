import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { append, includes, isNil, map, reject } from 'ramda';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { PrimaryButton } from '../../../../../bits/Buttons';
import { IconButton } from '../../../../../bits/Buttons/IconButton';
import { Display } from '../../../../../bits/Display';
import { Card } from '../../../../../patterns/Card';
import { EmbeddedEmailItem } from '../../../../../patterns/EmbeddedEmailItem';
import { Modal } from '../../../../../patterns/Modal';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { EmbeddedContentItem } from '../../../../PageTemplate/EmbeddedContentItem';
import { Collapsible } from '../../../../../patterns/Collapsible';
import { H5Heading } from '../../../../../bits/Headers';
import { pageTemplates } from '../../../../PageTemplate/templates';
import { sortItemsByParentChildRelation } from '../../../../../../utils/sortItemsByParentChildRelation';
import AssignedTeamsLabel from '../../../../../patterns/ContentItem/AssignedTeamsLabel';

const ContentColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
`;

const PageTemplateIcon = styled.img`
    width: 20px;
`;

interface ContentPickerModalProps {
    simulation: Simulation;
    onPrepreparedItemSelected: (item: PrepreparedContentItem | PrepreparedEmailItem) => void;
    onCancel: () => void;
    defaultOpenTriggerGroups?: string[];
    selectedItemUUIDs?: string[];
}
export const PrepreparedContentPickerModal: FC<ContentPickerModalProps> = ({
    simulation,
    onPrepreparedItemSelected,
    onCancel,
    defaultOpenTriggerGroups,
    selectedItemUUIDs,
}) => {
    const { simulationContent } = useContext(AdminStateContext);
    const { triggers, triggerOrder, prepreparedContents, prepreparedEmails, triggerPrepreparedContentLinks, pages } =
        simulationContent[simulation.uuid] || {};
    const [expandedGroups, setExpandedGroups] = useState<string[]>(['Unassigned', ...(defaultOpenTriggerGroups || [])]);

    const makeToggleGroupCollapsedFunc = (id: string) => () => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups(append(id, expandedGroups));
        }
    };

    return (
        <Modal
            header={
                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                    Select Content <IconButton icon="close" onClick={onCancel} />
                </Display.HorizontalWithMaxSpaceBetween>
            }
            onModalClose={onCancel}
            cardWidth={1400}
        >
            <ContentColumns>
                <Display.VerticalWithSpacing>
                    {map((triggerUUID) => {
                        const trigger = triggers[triggerUUID];
                        const itemUUIDs = triggerPrepreparedContentLinks[triggerUUID] || [];
                        const items = sortItemsByParentChildRelation(
                            reject(
                                isNil,
                                map((uuid) => {
                                    const item = prepreparedContents[uuid] || prepreparedEmails[uuid];
                                    return item;
                                }, itemUUIDs),
                            ) as Array<PrepreparedContentItem | PrepreparedEmailItem>,
                        );

                        const groupTitle = `${trigger.title} (${items.length} ${
                            items.length === 1 ? 'item' : 'items'
                        })`;
                        return (
                            <Collapsible
                                key={trigger.uuid}
                                title={groupTitle}
                                toggleGroupCollapsed={makeToggleGroupCollapsedFunc(trigger.uuid)}
                                open={includes(trigger.uuid, expandedGroups)}
                            >
                                {map((item) => {
                                    if (includes(item.uuid, selectedItemUUIDs || [])) {
                                        return null;
                                    }

                                    if (item.type === 'EMAIL') {
                                        return (
                                            <Card key={item.uuid}>
                                                <Display.VerticalWithSpacing horizontalAlign="start">
                                                    <EmbeddedEmailItem item={item} />
                                                    <PrimaryButton onClick={() => onPrepreparedItemSelected(item)}>
                                                        Select As Linked Content
                                                    </PrimaryButton>
                                                </Display.VerticalWithSpacing>
                                            </Card>
                                        );
                                    }

                                    const page = pages[item.content.pageUUID];
                                    const template = pageTemplates[page.templateUUID];
                                    return (
                                        <Card key={item.uuid}>
                                            <Display.VerticalWithSpacing horizontalAlign="start">
                                                <AssignedTeamsLabel item={item} />
                                                <H5Heading>
                                                    <Display.HorizontalWithSpacing gap={5}>
                                                        <PageTemplateIcon src={template.icon} />
                                                        {page.name}
                                                    </Display.HorizontalWithSpacing>
                                                </H5Heading>
                                                <EmbeddedContentItem item={item} />
                                                <PrimaryButton onClick={() => onPrepreparedItemSelected(item)}>
                                                    Select As Linked Content
                                                </PrimaryButton>
                                            </Display.VerticalWithSpacing>
                                        </Card>
                                    );
                                }, items)}
                            </Collapsible>
                        );
                    }, triggerOrder)}
                </Display.VerticalWithSpacing>
                <Collapsible
                    title="Unassigned"
                    toggleGroupCollapsed={makeToggleGroupCollapsedFunc('Unassigned')}
                    open={includes('Unassigned', expandedGroups)}
                />
            </ContentColumns>
        </Modal>
    );
};
