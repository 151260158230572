import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { CardContainer, CardContainerProps } from '../bits/Card';
import { Card } from '../patterns/Card';

interface ModalShadeProps {
    shadeTopOffset: number;
    headerHeight: number;
}
const ModalShade = styled.div<ModalShadeProps>`
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    position: absolute;
    top: ${({ shadeTopOffset }) => shadeTopOffset}px;
    left: 0;
    width: 100vw;
    height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
    z-index: 999;

    flex: 0 1 auto;

    display: flex;
    flex-direction: column;

    overflow: auto;
`;

const CardScroller = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1 0 auto;

    & > ${CardContainer} {
        display: inline-block;
        margin: 10px;
    }
`;

const GlobalStylesWhenModalOpen = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

export interface ModalProps extends CardContainerProps {
    header?: string | JSX.Element;
    onModalClose?: () => void;
}
export const Modal: FC<ModalProps> = ({ header, children, onModalClose, cardWidth, minCardWidth, maxCardWidth }) => {
    const [shadeTopOffset, setShadeTopOffset] = useState(0);
    const shadeRef = useRef<HTMLDivElement>(null);
    const clickTargetRef = useRef<EventTarget>();
    const [shadeHeightOffset, setShadeHeightOffset] = useState(0);
    const [loadedGlobalStyles, setLoadedGlobalStyles] = useState(false);

    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
        // We shouldn't ever get here but just incase we do lets throw an error
        throw new Error('HTML Element with id "modal-root" could not be found');
    }

    const saveClickTarget: MouseEventHandler<HTMLDivElement> = (e) => {
        clickTargetRef.current = e.target;
    };

    useEffect(() => {
        setLoadedGlobalStyles(true);
    }, []);

    useEffect(() => {
        if (shadeRef.current && loadedGlobalStyles) {
            const modalRootTopOffsetFromViewport = modalRoot.getBoundingClientRect().top;
            const viewportTopOffset = document.documentElement.scrollTop;
            const modalRootTopOffsetFromDocument = modalRootTopOffsetFromViewport + viewportTopOffset;
            setShadeHeightOffset(modalRootTopOffsetFromDocument);

            const offsetFromDocTop = shadeRef.current.getBoundingClientRect().top;
            setShadeTopOffset(offsetFromDocTop > 0 ? 0 : offsetFromDocTop * -1 + modalRootTopOffsetFromDocument);
        }
    }, [loadedGlobalStyles]);

    const ModalComponentToMount = (
        <>
            <GlobalStylesWhenModalOpen />
            {loadedGlobalStyles && (
                <ModalShade ref={shadeRef} shadeTopOffset={shadeTopOffset} headerHeight={shadeHeightOffset}>
                    <CardScroller
                        onMouseDown={saveClickTarget}
                        onClick={(e) => {
                            // Prevent clicks originating inside modals from bubbling out of the modal.
                            // Remember that React captures all events and bubbles up the REACT DOM and not
                            // the html hierarchy so because we use portals our REACT DOM can vary alot from
                            // our HTML DOM
                            const target = e.target as Element;

                            // There are some cases where we don't want to prevent events from bubbling:
                            // Don't stop bubbling for buttons incase they are submit buttons or going to
                            // trigger a file input
                            const isButton = target.localName === 'button';
                            // We manually trigger file inputs with a .click() so we need to make sure we don't
                            // prevent these
                            const isFileInput = target.getAttribute('type') === 'file';
                            // Our checkboxes are actually labels pretending to be checkboxes so don't prevent either
                            const isLabel = target.localName === 'label';
                            const isCheckbox = target.getAttribute('type') === 'checkbox';
                            // for links in RichText
                            const isLink = target.localName === 'a';

                            if (!isButton && !isFileInput && !isLabel && !isCheckbox && !isLink) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                            // Check that the element we clicked on is the currentElement
                            // If we don't do this then clicking on children elements (eg Card)
                            // will result in events bubbling up and triggering this onModalClose
                            if (
                                target === e.currentTarget &&
                                onModalClose &&
                                clickTargetRef.current === e.currentTarget
                            ) {
                                onModalClose();
                            }
                        }}
                    >
                        <Card
                            header={header}
                            cardWidth={cardWidth}
                            minCardWidth={minCardWidth}
                            maxCardWidth={maxCardWidth || 1400}
                        >
                            {children}
                        </Card>
                    </CardScroller>
                </ModalShade>
            )}
        </>
    );

    return ReactDOM.createPortal(ModalComponentToMount, modalRoot);
};
