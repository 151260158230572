import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { optionalToUndefined } from 'polpeo-go-common/t';
import { NewPage } from 'polpeo-go-common/types/Page';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { find, isNil, map, omit, reduce, reject, toLower, trim, values } from 'ramda';
import XLSX from 'xlsx';
import getErrorsForPagesSheet from './getErrorsForPagesSheet';

const UploadedPage = t.type({ ...omit(['simulationUUID'], NewPage.props), sheetName: optionalToUndefined(t.string) });
export type UploadedPage = t.TypeOf<typeof UploadedPage>;
interface ParsePagesSuccessfulResults {
    pages: UploadedPage[];
    errors: undefined;
    images: string[];
}
interface ParsePagesUnsuccessfulResults {
    pages: undefined;
    errors: string[];
}
type ParsePagesResults = ParsePagesSuccessfulResults | ParsePagesUnsuccessfulResults;

const PagesUpload = t.array(
    t.type({
        ...omit(['simulationUUID', 'templateUUID'], NewPage.props),
        templateName: t.string,
        sheetName: optionalToUndefined(t.string),
    }),
);
const RowData = t.type({
    pageName: t.string,
    templateName: t.string,
    sheetName: optionalToUndefined(t.string),
    createRootContentPermission: t.string,
    participantDeleteContentPermission: t.string,
    showItemsAsIndividualPages: optionalToUndefined(t.literal('YES')),
    headerImage: optionalToUndefined(t.string),
    leftSidebarImage: optionalToUndefined(t.string),
    rightSidebarImage: optionalToUndefined(t.string),
    footerImage: optionalToUndefined(t.string),
});
export const parsePagesSheet = (
    sheet: XLSX.WorkSheet,
    pageTemplates: Record<string, PageTemplate>,
): ParsePagesResults => {
    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet);
    const sheetData = reduce<string[], Record<string, unknown>[]>(
        (acc, row) => {
            const maybeRowData = RowData.decode(row);
            if (isLeft(maybeRowData)) {
                console.error('Row was not the correct page shape', { row, maybeRowData });
                // We don't filter out the incorrect rows so we can pass error messages
                // for incorrect rows to the user.
                return [...acc, { name: '', showItemsAsIndividualPages: '', ...row }];
            }
            const rowData = maybeRowData.right;
            const page = {
                name: rowData.pageName,
                sheetName: rowData.sheetName,
                templateName: rowData.templateName,
                dressing: {
                    header: rowData.headerImage,
                    leftSidebar: rowData.leftSidebarImage,
                    rightSidebar: rowData.rightSidebarImage,
                    footer: rowData.footerImage,
                },
                createRootContentPermission: rowData.createRootContentPermission,
                showItemsAsIndividualPages: rowData.showItemsAsIndividualPages === 'YES',
                participantDeleteContentPermission: rowData.participantDeleteContentPermission,
                stats: omit(
                    [
                        'pageName',
                        'sheetName',
                        'templateName',
                        'headerImage',
                        'leftSidebarImage',
                        'rightSidebarImage',
                        'footerImage',
                        'createRootContentPermission',
                        'showItemsAsIndividualPages',
                        'participantDeleteContentPermission',
                    ],
                    rowData,
                ),
            };
            return [...acc, page];
        },
        [],
        sheetRows,
    );
    const maybePages = PagesUpload.decode(sheetData);
    // check for data shape
    if (isLeft(maybePages)) {
        console.error('sheet was not the correct pages shape', sheetData);
        return {
            pages: undefined,
            errors: getErrorsForPagesSheet(sheetData, pageTemplates),
        };
    }
    const pages = maybePages.right;

    // Extra validation
    const errors = getErrorsForPagesSheet(pages, pageTemplates);
    if (errors.length) {
        return {
            pages: undefined,
            errors: errors,
        };
    }

    const images = reduce(
        (acc, page) => {
            const imagesForPage = reject(isNil, values(page.dressing)) as string[];
            return [...acc, ...imagesForPage];
        },
        [] as string[],
        pages,
    );

    return {
        pages: map(
            (page) => ({
                ...page,
                templateName: undefined,
                templateUUID: find(
                    (template) => toLower(trim(template.name)) === toLower(trim(page.templateName)),
                    values(pageTemplates),
                    // The template exists at this point because the error checking would have caught it otherwise
                )?.uuid as string,
            }),
            pages,
        ),
        errors: undefined,
        images,
    };
};
