import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface ResetStaffUserMfaParams {
    staffUserUUID: string;
}

interface ResetStaffUserMfaData {
    staffUserUUID: string;
}

const mutation = gql`
    mutation resetStaffUserMfa($staffUserUUID: String!) {
        resetStaffUserMfa(staffUserUUID: $staffUserUUID)
    }
`;

export const resetStaffUserMfa = wrapMutation<ResetStaffUserMfaParams, ResetStaffUserMfaData>(mutation);
