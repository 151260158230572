import gql from 'graphql-tag';
import { ScratchPadDocument } from '../../../../common/types/ScratchPadDocument';
import wrapQuery from '../wrapQuery';

interface GetScratchPadDocumentsParams {
    simulationUUID: string;
}

interface GetScratchPadDocumentsData {
    getScratchPadDocuments: ScratchPadDocument[];
}

const query = gql`
    query getScratchPadDocuments($simulationUUID: String!) {
        getScratchPadDocuments(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            currentEditor
        }
    }
`;

export const getScratchPadDocuments = wrapQuery<GetScratchPadDocumentsParams, GetScratchPadDocumentsData>(query);
