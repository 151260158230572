import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../../themes/colours';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { Logo } from '../../patterns/Logo';
import { StaffUserMenu } from '../../patterns/StaffUserMenu';
import { SideBarStateContext } from '../../WithSideBarState';

const Header = styled.header`
    position: sticky;
    top: 0;
    z-index: 900;

    background: #ffffff;
    padding: 10px 30px;
    border-bottom: 1px solid ${grey1Colour};
`;

export const AdminHeader = (): React.ReactElement => {
    const { collapsed, updateSidebarState } = useContext(SideBarStateContext);
    return (
        <Header>
            <Display.HorizontalWithMaxSpaceBetween>
                <Display.HorizontalWithSpacing verticalCenter>
                    <IconButton icon="menu" onClick={() => updateSidebarState({ collapsed: !collapsed })} />
                    <Logo width={125} />
                </Display.HorizontalWithSpacing>
                <StaffUserMenu />
            </Display.HorizontalWithMaxSpaceBetween>
        </Header>
    );
};
