import { PrepreparedContentItem } from 'polpeo-go-common/types';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { AdminManageSimulationContext } from '../../WithAdminManageSimulationState';
import { grey1Colour } from '../../../themes/colours';

interface AssignedTeamsLabelProps {
    item: PrepreparedContentItem;
}

const AssignedTeams = styled.div`
    border-bottom: 1px solid ${grey1Colour};
    padding-bottom: 16px;
    width: 100%;
`;

const AssignedTeamsLabel: FC<AssignedTeamsLabelProps> = ({ item }) => {
    const { currentSimulationTeams } = useContext(AdminManageSimulationContext);
    const teamNames = item.teamUUIDs?.map((teamUUID: string) => currentSimulationTeams[teamUUID].name).join(', ');

    if (!item.teamUUIDs?.length) return null;

    return (
        <AssignedTeams>
            <b>To:</b> {teamNames}
        </AssignedTeams>
    );
};

export default AssignedTeamsLabel;
