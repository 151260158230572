import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { createContext } from 'react';
import { SimulationContent } from '../WithAdminState/adminState';

export interface AdminManageSimulationState {
    currentSimulation: Simulation;
    currentSimulationContent: SimulationContent;
    currentSimulationTeams: Record<string, Team>;
}

// No one should ever be accessing AdminState before it's been initialised anyways so we shouldn't
// provide any default values
export const AdminManageSimulationContext = createContext<AdminManageSimulationState>({} as AdminManageSimulationState);
