import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { TextButton } from '../../../../../../bits/Buttons';
import { Display } from '../../../../../../bits/Display';
import { H5Heading } from '../../../../../../bits/Headers';
import { NotificationBanner } from '../../../../../../bits/NotificationBanner';
import { Card } from '../../../../../../patterns/Card';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { StaffUserContext } from '../../../../../../WithStaffUser';
import { ItemPreviewButton } from '../../../../../ContentItemPreviewButton';
import { EmbeddedContentItem } from '../../../../../PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../../../../PageTemplate/templates';
import {
    ManagePrepreparedContentCardFooter,
    ManagePrepreparedContentCardGrid,
    ManagePrepreparedContentCardMain,
    ManagePrepreparedContentCardToolbar,
} from '../utils';
import { DeletePrepreparedContentButton } from './DeletePrepreparedContentButton';
import { DuplicatePrepreparedContentButton } from './DuplicatePrepreparedContentButton';
import { EditPrepreparedContentButton } from './EditPrepreparedContentButton';
import { NewPrepreparedContentButton } from './NewPrepreparedContentButton';
import { ReleasePrepreparedContentButton } from './ReleasePrepreparedContentButton';
import AssignedTeamsLabel from '../../../../../../patterns/ContentItem/AssignedTeamsLabel';

export * from './DeletePrepreparedContentButton';
export * from './DuplicatePrepreparedContentButton';
export * from './EditPrepreparedContentButton';
export * from './NewPrepreparedContentButton';

const PageTemplateIcon = styled.img`
    width: 20px;
`;

interface ManagePrepreparedContentCardProps {
    item: PrepreparedContentItem;
}
export const ManagePrepreparedContentCard: FC<ManagePrepreparedContentCardProps> = ({ item }) => {
    const staffUser = useContext(StaffUserContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedContents, pages } = currentSimulationContent;

    const parent = prepreparedContents[item.parentUUID || ''];
    const page = pages[item.content.pageUUID];
    const template = pageTemplates[page.templateUUID];

    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);
    const userCanAdministerSimulation =
        !currentSimulation.completedAt && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    return (
        <Card>
            <ManagePrepreparedContentCardGrid>
                {userCanWriteSimulationAndContent && (
                    <ManagePrepreparedContentCardToolbar>
                        <EditPrepreparedContentButton item={item} />
                        <DuplicatePrepreparedContentButton copyOf={item} />
                        <DeletePrepreparedContentButton item={item} />
                    </ManagePrepreparedContentCardToolbar>
                )}
                <ManagePrepreparedContentCardMain>
                    <AssignedTeamsLabel item={item} />
                    <H5Heading>
                        <Display.HorizontalWithSpacing gap={5}>
                            <PageTemplateIcon src={template.icon} />
                            {page.name}
                        </Display.HorizontalWithSpacing>
                    </H5Heading>
                    {parent && (
                        <NotificationBanner>
                            Child of existing item •{' '}
                            <ItemPreviewButton
                                item={parent}
                                buttonComponent={(props) => (
                                    <TextButton small onClick={(e) => props.onClick && props.onClick(e)}>
                                        Preview parent
                                    </TextButton>
                                )}
                            />
                        </NotificationBanner>
                    )}
                    <EmbeddedContentItem item={item} />
                </ManagePrepreparedContentCardMain>
                <ManagePrepreparedContentCardFooter>
                    {item.released && <span>This item was released at: {item.released.at.toISOString()}</span>}
                    {!currentSimulation.completedAt && (
                        <>
                            {userCanWriteSimulationAndContent && (
                                <NewPrepreparedContentButton parent={item} label="Add Child" />
                            )}
                            {!item.released && userCanAdministerSimulation && (
                                <ReleasePrepreparedContentButton item={item} />
                            )}
                        </>
                    )}
                </ManagePrepreparedContentCardFooter>
            </ManagePrepreparedContentCardGrid>
        </Card>
    );
};
