import gql from 'graphql-tag';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface DeletePrepreparedEmailParams {
    prepreparedEmail: GraphQlPrepreparedEmailItem;
}

interface DeletePrepreparedEmailData {
    deletePrepreparedEmail: string[];
}

const mutation = gql`
    mutation deletePrepreparedEmail($prepreparedEmail: PrepreparedEmailInput!) {
        deletePrepreparedEmail(prepreparedEmail: $prepreparedEmail)
    }
`;

export const deletePrepreparedEmail = wrapMutation<DeletePrepreparedEmailParams, DeletePrepreparedEmailData>(mutation);
