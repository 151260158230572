import { ContentItem, Page } from 'polpeo-go-common/types';
import { filter } from 'ramda';

interface FilterContentItemsForHiddenPagesProps {
    contentItems?: ContentItem[];
    pages?: Page[];
    teamUUID: string;
}

const filterContentItemsForHiddenPages = ({
    contentItems,
    pages,
    teamUUID,
}: FilterContentItemsForHiddenPagesProps): ContentItem[] => {
    if (!contentItems) return [];

    const hiddenPages = pages
        ? filter((page) => !!page.hiddenFromTeamUUIDs && page.hiddenFromTeamUUIDs.includes(teamUUID), pages)
        : [];

    const filteredContentItems = contentItems.filter(
        (contentItem) =>
            !hiddenPages
                .find((page) => page.uuid === contentItem.content.pageUUID)
                ?.hiddenFromTeamUUIDs?.includes(teamUUID),
    );

    return filteredContentItems;
};

export default filterContentItemsForHiddenPages;
