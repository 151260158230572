import React, { FC, useState } from 'react';
import { NewStaffContentItemModal } from '../../../../patterns/ContentItem/NewStaffContentItemModal';
import { FloatingActionButton } from '../../../../patterns/FAB/FloatingActionButton';

export const NewContentItemFAB: FC = () => {
    const [openForm, setOpenForm] = useState(false);

    return (
        <>
            <FloatingActionButton icon="content" onClick={() => setOpenForm(!openForm)} />
            {openForm && <NewStaffContentItemModal onCloseModal={() => setOpenForm(false)} />}
        </>
    );
};
