import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { map, sortBy, prop } from 'ramda';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { grey6Colour } from '../../../../../themes/colours';
import { findStaffWithUUID } from '../../../../../utils/findStaff';
import { CardContainer } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { Body3, H4Heading } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { Card } from '../../../../patterns/Card';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { SimulationOverviewCard } from '../SimulationOverviewCard';
import { CreateSimulationTeamButton } from './CreateSimulationTeamButton';
import { DeleteSimulationTeamButton } from './DeleteSimulationTeamButton';
import { EditSimulationTeamButton } from './EditSimulationTeamButton';

const SimulationTeamPageContainer = styled.div`
    display: flex;
    padding: 22px;

    & > *:first-child {
        flex: 1 1 auto;
        margin-right: 22px;
    }

    & > *:last-child {
        width: 33%;
        min-width: 300px;
        max-width: 450px;
        flex: 0 1 auto;
    }
`;

const PositionedDeleteSimulationTeamButton = styled(DeleteSimulationTeamButton)``;
const TeamCardGrid = styled(Display.HorizontalWithSpacing)`
    grid-template-columns: repeat(auto-fit, 200px);
    grid-auto-flow: row;
    gap: 15px;

    & > ${CardContainer} {
        position: relative;
        height: 100%;
        min-height: 125px;
    }

    button > ${CardContainer} {
        height: 100%;
        min-height: 125px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${PositionedDeleteSimulationTeamButton} {
        position: absolute;
        top: 0;
        right: 0;
    }

    ${H4Heading}, ul, li, ${Body3} {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;

        text-align: center;
    }
`;

interface SimulationTeamPageProps {
    simulation: Simulation;
    teams: Team[];
}
export const SimulationTeamPage: FC<SimulationTeamPageProps> = ({ simulation, teams }: SimulationTeamPageProps) => {
    const staffUser = useContext(StaffUserContext);
    const { staffUsers } = useContext(AdminStateContext);

    const userCanWriteSimulationAndContent =
        !simulation.completedAt &&
        !simulation.startedAt &&
        userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);

    const canUserAddRoleplayerToTeam =
        !simulation.completedAt && userCan(PermissionKey.EDIT_SIMULATION_STAFF, staffUser);

    return (
        <SimulationTeamPageContainer>
            <Card header="Teams">
                <TeamCardGrid>
                    {map(
                        (team) => (
                            <Card key={team.uuid}>
                                {userCanWriteSimulationAndContent && (
                                    <PositionedDeleteSimulationTeamButton team={team} />
                                )}
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="team" width={32} height={32} fill={grey6Colour} />
                                    <H4Heading>{team.name}</H4Heading>
                                    <Body3>
                                        <b>Roleplayers</b>
                                    </Body3>
                                    <ul>
                                        {team.roleplayerUUIDs.length ? (
                                            map((uuid: string) => {
                                                const staff = findStaffWithUUID(staffUsers, uuid);
                                                return (
                                                    <li key={uuid}>
                                                        <Body3>{staff?.name || 'Deleted user'}</Body3>
                                                    </li>
                                                );
                                            }, team.roleplayerUUIDs)
                                        ) : (
                                            <Body3 destructive>
                                                <b>None Assigned</b>
                                            </Body3>
                                        )}
                                    </ul>
                                    {canUserAddRoleplayerToTeam && <EditSimulationTeamButton team={team} />}
                                </Display.VerticalWithSpacing>
                            </Card>
                        ),
                        sortBy(prop('name'), teams),
                    )}
                    {userCanWriteSimulationAndContent && <CreateSimulationTeamButton />}
                </TeamCardGrid>
            </Card>
            <SimulationOverviewCard />
        </SimulationTeamPageContainer>
    );
};
