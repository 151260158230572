import styled from 'styled-components/macro';
import { CardContainer } from '../../bits/Card';

export const TAIL_WIDTH = 20;
export const TAIL_HEIGHT = 40;
export const MENU_BOTTOM_SCREEN_GAP = 10;

export interface FABMenuProps {
    menuPosition?: {
        right: number;
    };
    tailPosition?: {
        bottom: number;
    };
}
export const FABMenu = styled(CardContainer)<FABMenuProps>`
    position: fixed;
    height: calc(100vh - 125px);
    right: ${({ menuPosition: position }) => (position?.right ? position.right : 10)}px;
    bottom: ${MENU_BOTTOM_SCREEN_GAP}px;

    display: flex;
    flex-direction: column;

    filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.2));

    :after {
        content: '';
        position: fixed;

        width: 0;
        height: 0;
        border-top: ${TAIL_HEIGHT / 2}px solid transparent;
        border-left: ${TAIL_WIDTH}px solid white;
        border-bottom: ${TAIL_HEIGHT / 2}px solid transparent;

        left: 100%;
        bottom: ${({ tailPosition: position }) => (position?.bottom ? position.bottom : 10)}px;

        filter: drop-shadow(3px 0 2px rgba(0, 0, 0, 0.05));
    }
`;
