import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapQuery from '../wrapQuery';

interface GetPrepreparedContentsParams {
    simulationUUID: string;
    startFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
}

interface GetPrepreparedContentsData {
    getPrepreparedContents: {
        prepreparedContents: GraphQlPrepreparedContentItem[];
        continueFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
    };
}

const query = gql`
    query getPrepreparedContents($simulationUUID: String!, $startFrom: AWSJSON) {
        getPrepreparedContents(simulationUUID: $simulationUUID, startFrom: $startFrom) {
            prepreparedContents {
                type
                uuid
                simulationUUID
                parentUUID
                persona
                content {
                    pageUUID
                    interactions {
                        positive
                        negative
                        shares
                    }
                    data
                }
                released {
                    at
                    items
                }
                teamUUIDs
            }
            continueFrom
        }
    }
`;

export const getPrepreparedContents = wrapQuery<GetPrepreparedContentsParams, GetPrepreparedContentsData>(query);
