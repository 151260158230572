import { cond, T } from 'ramda';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { getVimeoVideoId, getYoutubeVideoId } from '../../utils/getVideoId';
import WithClassname from '../../utils/WithClassname';

// Inspired by https://blog.theodo.com/2018/01/responsive-iframes-css-trick/
const IframeContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
`;

const IframeContainerSquare = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 50%;
    padding-bottom: 50%;
`;

// Inspired by https://blog.theodo.com/2018/01/responsive-iframes-css-trick/
const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

interface VideoEmbedProps extends WithClassname {
    video: string;
    width?: number;
    square?: boolean;
}
export const VideoEmbed: FC<VideoEmbedProps> = ({ className, video, square, width }) => {
    let height: number | undefined = undefined;
    if (width) {
        if (square) height = width;
        else height = (width / 560) * 315;
    }

    const Container = square ? IframeContainerSquare : IframeContainer;

    return (
        <Container>
            {cond<string, React.ReactElement>([
                [
                    (url) => !!getYoutubeVideoId(url),
                    (url) => {
                        const videoId = getYoutubeVideoId(url);
                        return (
                            <Iframe
                                className={className}
                                width={width}
                                height={height}
                                src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                                title="YouTube video player"
                                frameBorder="0"
                                // eslint-disable-next-line max-len
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        );
                    },
                ],
                [
                    (url) => !!getVimeoVideoId(url),
                    (url) => {
                        const videoId = getVimeoVideoId(url);
                        return (
                            <Iframe
                                className={className}
                                src={`https://player.vimeo.com/video/${videoId}`}
                                width={width}
                                height={height}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            />
                        );
                    },
                ],
                [T, () => <></>],
            ])(video)}
        </Container>
    );
};
