import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { assocPath } from 'ramda';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { CardSection } from '../../bits/Card';
import { Display } from '../../bits/Display';
import { TextInput } from '../../bits/FormFields';
import { Body1, Body3 } from '../../bits/Headers';
import { usePersonaMatchesExistingParticipantFullName } from '../../hooks/usePersonaMatchesExistingParticipantFullName';
import { MultiSearchSelection } from '../../patterns/MultiSearchSelection';
import RichtextInput from '../../patterns/RichtextInput';
import { SingleSearchOrCreate } from '../../patterns/SingleSearchOrCreate';

const FormField = styled(Display.HorizontalWithSpacing)`
    grid-template-columns: auto 1fr;
`;

const BulletPointedListWithMargin = styled.ul`
    list-style: circle;
    margin-left: 16px;
`;

type EmailItemFormFields = Pick<EmailItem, 'content'> &
    Partial<Pick<EmailItem, 'parentUUID' | 'teamUUID' | 'persona' | 'createdBy'>>;
interface EmailItemFormProps {
    currentEmail: EmailItemFormFields;
    onChange: (value: EmailItemFormFields) => void;
    onSubmit: () => void;
    onCancel: () => void;
    fromOptions?: Record<string, string>;
    toOptions?: Record<string, string>;
    disableSubjectEdit?: boolean;
    submitLabel?: string;
}

export const EmailItemForm: FC<EmailItemFormProps> = ({
    onChange,
    onSubmit,
    onCancel,
    currentEmail,
    fromOptions,
    toOptions,
    disableSubjectEdit,
    submitLabel = 'Send',
}) => {
    const adminInSimulationData = useContext(AdminInSimulationStateContext);
    const participantInSimulationData = useContext(ParticipantStateContext);

    const simulation = adminInSimulationData?.simulation || participantInSimulationData?.simulation;
    const { participants } = adminInSimulationData;

    const personaMatchesExistingParticipantFullName = usePersonaMatchesExistingParticipantFullName(
        participants,
        currentEmail.persona,
    );
    let validPersona = true;

    if (fromOptions) {
        if (!currentEmail.persona) {
            validPersona = false;
        }
        if (personaMatchesExistingParticipantFullName) {
            validPersona = false;
        }
    }

    const canSend = currentEmail.content.recipients.length && validPersona;

    return (
        <>
            {fromOptions && (
                <CardSection>
                    <FormField verticalCenter>
                        <Body1>From </Body1>
                        <SingleSearchOrCreate
                            options={fromOptions}
                            onChange={(persona) => onChange(assocPath(['persona'], persona, currentEmail))}
                            value={currentEmail.persona || ''}
                            small
                            error={
                                !validPersona && currentEmail.persona
                                    ? "You cannot use a participant's name as a persona"
                                    : ''
                            }
                        />
                    </FormField>
                </CardSection>
            )}
            {toOptions && (
                <CardSection>
                    <Display.VerticalWithSpacing gap={8}>
                        <FormField verticalCenter>
                            <Body1>To </Body1>
                            <MultiSearchSelection
                                options={toOptions}
                                onChange={(recipients) =>
                                    onChange(assocPath(['content', 'recipients'], recipients, currentEmail))
                                }
                                value={currentEmail.content.recipients}
                                small
                                createNewOptions
                            />
                        </FormField>
                        <Body3>
                            <BulletPointedListWithMargin>
                                To send to...
                                {!!adminInSimulationData?.simulation && (
                                    <li>
                                        everyone in a team - start writing the team name and select the team e.g.
                                        &quot;Team 1&quot;
                                    </li>
                                )}
                                {!!participantInSimulationData?.simulation && (
                                    <li>
                                        everyone in my team - start writing &quot;my team&quot; and select Everyone on
                                        my team
                                    </li>
                                )}
                                <li>
                                    an individual member of the team - start writing their name and select their name
                                    e.g. &quot;Maria Lee&quot;. Only that particular participant will see the email
                                </li>
                                <li>
                                    an &quot;external&quot; email address. - write their email address e.g.
                                    &quot;legal@email.com&quot; These emails won&apos;t be sent to the external address
                                    but will be seen by the back office team.
                                </li>
                            </BulletPointedListWithMargin>
                        </Body3>
                    </Display.VerticalWithSpacing>
                </CardSection>
            )}
            <CardSection>
                <FormField verticalCenter>
                    <Body1>Subject </Body1>
                    <TextInput
                        value={currentEmail.content.subject || ''}
                        onChange={(e) => onChange(assocPath(['content', 'subject'], e.target.value, currentEmail))}
                        small
                        disabled={disableSubjectEdit}
                    />
                </FormField>
            </CardSection>
            <CardSection>
                <RichtextInput
                    value={currentEmail.content.message}
                    imageUploadSimulation={simulation}
                    onChange={(richtext) => {
                        onChange(assocPath(['content', 'message'], richtext, currentEmail));
                    }}
                />
            </CardSection>
            <CardSection>
                <Display.HorizontalWithSpacing horizontalAlign="end">
                    <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={onSubmit} disabled={!canSend}>
                        {submitLabel}
                    </PrimaryButton>
                </Display.HorizontalWithSpacing>
            </CardSection>
        </>
    );
};
