import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { Team } from 'polpeo-go-common/types/Team';
import { any, assocPath, difference, includes, map, reduce, toPairs, without } from 'ramda';
import React from 'react';
import { Display } from '../../../bits/Display';
import { Checkbox, Dropdown, OptionItem } from '../../../bits/FormFields';
import { FormGridField } from '../../../templates/FormGrid';
import { SingleSearchOrCreate } from '../../SingleSearchOrCreate';
import { InteractionsField } from '../InteractionsField';

type MakeSetupTabFormFieldsBaseItem = Pick<ContentItem, 'content'> &
    Partial<Pick<ContentItem, 'persona' | 'createdBy' | 'deleted' | 'parentUUID'>>;

export function makeSetupTabFormFields<T extends MakeSetupTabFormFieldsBaseItem>(
    currentContentItem: T,
    onChange: (item: T) => void,
    personas: string[],
    errors: Record<string, string>,
    currentTemplate: undefined | PageTemplate,
    allTeams: Team[],
    currentTeams: string[],
    setCurrentTeams: (teamUUIDs: string[]) => void,
    pageOptionItems?: OptionItem[],
): FormGridField[];
export function makeSetupTabFormFields<T extends MakeSetupTabFormFieldsBaseItem>(
    currentContentItem: T,
    onChange: (item: T) => void,
    personas: string[],
    errors: Record<string, string>,
    currentTemplate: undefined | PageTemplate,
    allTeams?: undefined,
    currentTeams?: undefined,
    setCurrentTeams?: undefined,
    pageOptionItems?: OptionItem[],
): FormGridField[];
export function makeSetupTabFormFields<T extends MakeSetupTabFormFieldsBaseItem>(
    currentContentItem: T,
    onChange: (item: T) => void,
    personas: string[],
    errors: Record<string, string>,
    currentTemplate: undefined | PageTemplate,
    allTeams?: Team[],
    currentTeams?: string[],
    setCurrentTeams?: (teamUUIDs: string[]) => void,
    pageOptionItems?: OptionItem[],
): FormGridField[] {
    const contentType: FormGridField[] = pageOptionItems
        ? [
              [
                  'Content Type',
                  <Dropdown
                      key="Content Type"
                      id="Content Type"
                      options={pageOptionItems}
                      onChange={(e) => {
                          const itemWithUpdatedType = assocPath(
                              ['content', 'pageUUID'],
                              e.target.value,
                              currentContentItem,
                          );
                          const itemWithCleanData = assocPath(['content', 'data'], {}, itemWithUpdatedType);
                          onChange(itemWithCleanData);
                      }}
                      value={currentContentItem.content.pageUUID}
                      disabled={!!currentContentItem.parentUUID || 'uuid' in currentContentItem}
                  />,
              ],
          ]
        : [];
    const personaField: FormGridField[] =
        currentContentItem.createdBy?.type !== 'PARTICIPANT'
            ? [
                  [
                      'Persona',
                      <SingleSearchOrCreate
                          key="Persona"
                          options={reduce((acc, persona) => ({ ...acc, [persona]: persona }), {}, personas)}
                          onChange={(persona) => onChange(assocPath(['persona'], persona, currentContentItem))}
                          value={currentContentItem.persona || ''}
                          disabled={!!currentContentItem.deleted}
                          error={errors['persona']}
                      />,
                  ],
              ]
            : [];
    const teamsField: FormGridField[] =
        allTeams && allTeams.length > 1 && currentTeams && setCurrentTeams
            ? [
                  [
                      'Team Assignment',
                      <Display.VerticalWithSpacing key="Team Assignment" gap={10} horizontalAlign="start">
                          <Checkbox
                              id="all_teams"
                              key="all_teams"
                              checked={
                                  difference(
                                      map((team) => team.uuid, allTeams),
                                      currentTeams,
                                  ).length === 0
                              }
                              onChange={(e) => {
                                  const nowChecked = e.target.checked;
                                  if (nowChecked) {
                                      setCurrentTeams(map((team) => team.uuid, allTeams));
                                  } else {
                                      setCurrentTeams([]);
                                  }
                              }}
                          >
                              All Listed Teams
                          </Checkbox>
                          {map(
                              (team) => (
                                  <Checkbox
                                      id={team.uuid}
                                      key={team.uuid}
                                      checked={includes(team.uuid, currentTeams)}
                                      onChange={(e) => {
                                          const nowChecked = e.target.checked;
                                          if (nowChecked) {
                                              setCurrentTeams([...currentTeams, team.uuid]);
                                          } else {
                                              setCurrentTeams(without([team.uuid], currentTeams));
                                          }
                                      }}
                                  >
                                      {team.name}
                                  </Checkbox>
                              ),
                              allTeams,
                          )}
                      </Display.VerticalWithSpacing>,
                  ],
              ]
            : [];

    const interactions = currentContentItem.parentUUID
        ? currentTemplate?.childItemOptions.interactions
        : currentTemplate?.rootItemOptions.interactions;
    if (
        !currentTemplate ||
        !interactions ||
        !any(([, interactionSettings]) => interactionSettings.enabled, toPairs(interactions))
    ) {
        return [...contentType, ...personaField, ...teamsField];
    }

    return [
        ...contentType,
        ...personaField,
        [
            'Interactions',
            <InteractionsField
                key="Interactions"
                interactionFields={interactions}
                value={currentContentItem.content.interactions}
                onChange={(newInteractions) =>
                    onChange(assocPath(['content', 'interactions'], newInteractions, currentContentItem))
                }
            />,
        ],
        ...teamsField,
    ];
}
