export class MissingAncestorError extends Error {
    constructor(message: string | undefined) {
        super(message);
        this.name = 'MissingAncestorError';
    }
}

export class MaxSubscriptionsReachedError extends Error {
    constructor(message: string | undefined) {
        super(message);
        this.name = 'MaxSubscriptionsReachedError';
    }
}
