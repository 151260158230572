import gql from 'graphql-tag';
import { NewPage, Page } from 'polpeo-go-common/types/Page';
import wrapMutation from '../wrapMutation';

interface CreatePageParams {
    page: Omit<NewPage, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

interface CreatePageData {
    createPage: Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

const mutation = gql`
    mutation createPage($page: NewPageInput!) {
        createPage(page: $page) {
            uuid
            simulationUUID
            templateUUID
            name
            stats
            dressing {
                header
                leftSidebar
                rightSidebar
                footer
            }
            createRootContentPermission
            showItemsAsIndividualPages
            participantDeleteContentPermission
            hiddenFromTeamUUIDs
        }
    }
`;

export const createPage = wrapMutation<CreatePageParams, CreatePageData>(mutation);
