import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface EditTeamAssignmentsParams {
    simulationUUID: string;
    assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
}

interface EditTeamAssignmentsData {
    editTeamAssignments: {
        simulationUUID: string;
        assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
    };
}

const mutation = gql`
    mutation editTeamAssignments($simulationUUID: String!, $assignments: AWSJSON!) {
        editTeamAssignments(simulationUUID: $simulationUUID, assignments: $assignments) {
            simulationUUID
            assignments
        }
    }
`;

export const editTeamAssignments = wrapMutation<EditTeamAssignmentsParams, EditTeamAssignmentsData>(mutation);
