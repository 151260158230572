import * as t from 'io-ts';

const fromNullable = <A, O>(typ: t.Type<A, O, unknown>, defaultValue: A): t.Type<A, O, unknown> => {
    return new t.Type<A, O, unknown>(
        `fromNullable(${typ.name})`,
        (u): u is A => typ.is(u),
        (u, c) => {
            if (u == null) {
                return t.success(defaultValue);
            }
            return typ.validate(u, c);
        },
        (a) => typ.encode(a),
    );
};

const optionalToUndefined = <A, O>(typ: t.Type<A, O, unknown>): t.Type<A | undefined, O | undefined, unknown> => {
    const unioned = t.union([typ, t.undefined]);
    return fromNullable<A | undefined, O | undefined>(unioned, undefined);
};

export default optionalToUndefined;
