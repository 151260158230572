import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { find, groupBy, length, map, reduce, values, without } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { editTeamAssignments } from '../../../../../graphql/simulations/editTeamAssignments';
import { grey1Colour, grey3Colour, whiteColour } from '../../../../../themes/colours';
import { h2Heading } from '../../../../../themes/cssSnippets';
import { sortAlphabeticallyByName } from '../../../../../utils/sortAlphabeticallyByName';
import { Display } from '../../../../bits/Display';
import { H7Heading } from '../../../../bits/Headers';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { DroppableTeamList } from './DroppableTeamList';

interface TeamsGridProps {
    columns: number;
}
const TeamsGrid = styled.div<TeamsGridProps>`
    flex: 1 0 auto;
    overflow: auto;
    margin: 22px;

    display: grid;
    align-content: start;

    grid-template-columns: repeat(${({ columns }) => columns}, 340px);
    grid-template-rows: auto 1fr;
    thead {
    }
`;
const TeamsGridHeaderCell = styled.div`
    border-bottom: 1px solid ${grey1Colour};
    padding: 10px;
    &:first-child {
        position: sticky;
        left: 0;
        background: ${whiteColour};
        z-index: 3;
    }
`;
const TeamNameHeader = styled(H7Heading)`
    overflow: hidden;
    text-overflow: ellipsis;
`;
const MemberCount = styled.div`
    ${h2Heading}
`;
const MemberCountSubtitle = styled.div`
    color: ${grey3Colour};
    font-size: 10px;
    letter-spacing: 0.1px;
    font-weight: 500;
`;
const TeamsGridCell = styled.div`
    padding: 10px;
    ${TeamsGridHeaderCell} + & {
        position: sticky;
        left: 0;
        background: ${whiteColour};
        z-index: 3;
    }
`;

export const TeamAssignmentTable: FC = () => {
    const { simulation, teamsForCurrentStaffUser, participants, teamAssignments, setTeamAssignments } =
        useContext(AdminInSimulationStateContext);
    const [selectedParticipants, setSelectedParticipants] = useState<ParticipantUser[]>([]);
    const [EditTeamAssignmentsMutation] = editTeamAssignments.hook();

    const teamAssignmentColumns: { name: string; uuid: string }[] = useMemo(
        () => [{ name: 'Unassigned', uuid: '' }, ...sortAlphabeticallyByName(values(teamsForCurrentStaffUser))],
        [teamsForCurrentStaffUser],
    );
    const groupedParticipants = useMemo(
        () => groupBy((participant) => teamAssignments[participant.id]?.teamUUID || '', values(participants)),
        [teamAssignments, participants],
    );

    const updateTeamAssignment = (teamUUID: string) => (participantsToUpdate: ParticipantUser[]) => {
        const newAssignments = reduce(
            (acc, participant) => ({ ...acc, [participant.id]: { teamUUID, lockedOn: undefined } }),
            {},
            participantsToUpdate,
        );
        setTeamAssignments({ ...teamAssignments, ...newAssignments });
        EditTeamAssignmentsMutation({
            variables: {
                simulationUUID: simulation.uuid,
                assignments: JSON.stringify({ ...teamAssignments, ...newAssignments }),
            },
        });
    };
    const toggleParticipantSelection = (participant: ParticipantUser) => {
        const currentlySelected = !!find(
            (selectedParticipant) => participant.id === selectedParticipant.id,
            selectedParticipants,
        );
        if (currentlySelected) {
            setSelectedParticipants(without([participant], selectedParticipants));
        } else {
            setSelectedParticipants([...selectedParticipants, participant]);
        }
    };

    return (
        <>
            <TeamsGrid columns={length(teamAssignmentColumns)}>
                {map(
                    (team) => (
                        <TeamsGridHeaderCell key={team.uuid || 'fakeUUIDForUnassignedColumn'}>
                            <Display.HorizontalWithMaxSpaceBetween>
                                <TeamNameHeader>{team.name}</TeamNameHeader>
                                <Display.VerticalWithSpacing gap={0} horizontalAlign="end">
                                    <MemberCount>{length(groupedParticipants[team.uuid] || [])}</MemberCount>
                                    <MemberCountSubtitle>members</MemberCountSubtitle>
                                </Display.VerticalWithSpacing>
                            </Display.HorizontalWithMaxSpaceBetween>
                        </TeamsGridHeaderCell>
                    ),
                    teamAssignmentColumns,
                )}
                {map(
                    (team) => (
                        <TeamsGridCell key={team.uuid || 'fakeUUIDForUnassignedColumn'}>
                            <DroppableTeamList
                                participants={groupedParticipants[team.uuid] || []}
                                selectedParticipants={selectedParticipants}
                                toggleParticipantSelection={toggleParticipantSelection}
                                updateTeamAssignment={updateTeamAssignment(team.uuid)}
                                setSelectedParticipants={setSelectedParticipants}
                            />
                        </TeamsGridCell>
                    ),
                    teamAssignmentColumns,
                )}
            </TeamsGrid>
        </>
    );
};
