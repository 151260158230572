import { NewPage, Page } from 'polpeo-go-common/types/Page';
import { NewSimulation, Simulation } from 'polpeo-go-common/types/Simulation';
import { NewTeam, Team } from 'polpeo-go-common/types/Team';
import { NewTrigger, Trigger } from 'polpeo-go-common/types/Trigger';
import { dissoc, find, fromPairs, isNil, map, mapObjIndexed, pick, pluck, reduce, reject, toPairs } from 'ramda';
import { Client } from '../../../../../../graphql/client';
import { createPage, putPageOrder } from '../../../../../../graphql/pages';
import { createSimulation } from '../../../../../../graphql/simulations/createSimulation';
import { editSimulation } from '../../../../../../graphql/simulations/editSimulation';
import { createTeam } from '../../../../../../graphql/teams/createTeam';
import {
    createTrigger,
    putTriggerOrder,
    updateTriggerPrepreparedContentLinks,
} from '../../../../../../graphql/triggers';
import { AdminState } from '../../../../../WithAdminState/adminState';
import { UploadedSimulationAndAssociatedData } from '../UploadedSimulationAndAssociatedData';
import { doCreatePrepreparedContents } from './doCreatePrepreparedContents';
import { doCreatePrepreparedEmails } from './doCreatePrepreparedEmails';
import { doUploadPagesImages, doUploadPrepreparedContentImages, doUploadTeamOverviewImages } from './doUploadImages';
import { transformPreparedContentItemUUID } from '../../../../../../utils/transformPreparedContentItemUUID';

const doCreateSimulation = async (
    client: Client,
    context: {
        imageFiles: Record<string, File>;
    },
    simulation: NewSimulation,
): Promise<Simulation> => {
    const { data: createSimulationData } = await createSimulation.promise(client, {
        simulation,
    });
    const newSimulation = createSimulationData && createSimulationData.createSimulation;
    if (!newSimulation) {
        throw new Error('New simulation could not be created');
    }

    if (!newSimulation.startingOverview?.headerImage) {
        return newSimulation;
    }
    const uploadedImages = await doUploadTeamOverviewImages(client, {
        simulation: newSimulation,
        images: pick([newSimulation.startingOverview?.headerImage], context.imageFiles),
    });
    const simWithHeaderImage = newSimulation.startingOverview?.headerImage
        ? {
              ...newSimulation,
              startingOverview: {
                  ...newSimulation.startingOverview,
                  headerImage: uploadedImages[newSimulation.startingOverview?.headerImage],
              },
          }
        : newSimulation;
    const { data: editSimulationData } = await editSimulation.promise(client, {
        simulation: simWithHeaderImage,
    });
    const editedSimulation = editSimulationData && editSimulationData.editSimulation;
    if (!editedSimulation) {
        throw new Error('New simulation could not be updated with overview image');
    }

    return editedSimulation;
};

const doCreateTeams = async (client: Client, newTeams: NewTeam[]): Promise<Team[]> => {
    const allCreateTeamsResponse = await Promise.all(map((team) => createTeam.promise(client, { team }), newTeams));
    const teams = reject(
        isNil,
        map(
            ({ data: createTeamData }) =>
                createTeamData && {
                    ...createTeamData.createTeam,
                    pageStats: JSON.parse(createTeamData.createTeam.pageStats),
                },
            allCreateTeamsResponse,
        ),
    ) as Team[]; // typescript doesn't pick up that reject removed all nulls/undefined

    return teams;
};

const doCreateTriggers = async (
    client: Client,
    context: {
        pages: Page[];
        uploadedImages: Record<string, string>;
        prepreparedContentTempIdMap: Record<string, string>;
    },
    newTriggers: NewTrigger[],
): Promise<Trigger[]> => {
    const { pages, uploadedImages, prepreparedContentTempIdMap } = context;
    const allCreateTriggersResponse = await Promise.all(
        map((trigger) => {
            const listOfPagesAndDressings = toPairs(trigger.pageDressingChanges);
            const pageDressingsWithReplacedValues = reduce(
                (acc, [pageTitle, pageDressingImages]) => {
                    const page = find((p) => p.name === pageTitle, pages);
                    if (!page) {
                        throw new Error('page could not be found');
                    }
                    return {
                        ...acc,
                        [page.uuid]: mapObjIndexed((imageName) => uploadedImages[imageName], pageDressingImages),
                    };
                },
                {},
                listOfPagesAndDressings,
            );

            const linkedPreparedContentUUIDs = transformPreparedContentItemUUID(
                trigger.participantPopupSettings.prepreparedContentItemUUID,
            );
            const linkedPrepreparedContentUUID = linkedPreparedContentUUIDs[0];
            const linkedPrepreparedContent = linkedPrepreparedContentUUID
                ? prepreparedContentTempIdMap[linkedPrepreparedContentUUID]
                : undefined;

            if (linkedPrepreparedContentUUID && !linkedPrepreparedContent) {
                throw new Error('Trigger has linked preprepared content item but could not find item matching tempId');
            }

            return createTrigger.promise(client, {
                trigger: {
                    ...trigger,
                    pageDressingChanges: JSON.stringify(pageDressingsWithReplacedValues),
                    participantPopupSettings: {
                        ...trigger.participantPopupSettings,
                        prepreparedContentItemUUID: linkedPreparedContentUUIDs ? linkedPreparedContentUUIDs : undefined,
                    },
                    overviewChanges: {
                        ...trigger.overviewChanges,
                        headerImage: uploadedImages[trigger.overviewChanges.headerImage || ''],
                    },
                },
            });
        }, newTriggers),
    );
    const triggers = reject(
        isNil,
        map(
            ({ data: createTriggerData }) =>
                createTriggerData && {
                    ...createTriggerData.createTrigger,
                    pageDressingChanges: JSON.parse(createTriggerData.createTrigger.pageDressingChanges),
                },
            allCreateTriggersResponse,
        ),
    ) as Trigger[]; // typescript doesn't pick up that reject removed all nulls/undefined

    return triggers;
};
const doPutTriggerOrder = async (
    client: Client,
    context: { simulation: Simulation },
    triggers: Trigger[],
): Promise<string[]> => {
    const { simulation } = context;
    const { data: putTriggerOrderData } = await putTriggerOrder.promise(client, {
        simulationUUID: simulation.uuid,
        triggerOrder: pluck('uuid', triggers),
    });
    const triggerOrder = putTriggerOrderData ? putTriggerOrderData.putTriggerOrder : [];

    return triggerOrder;
};
const doPutTriggerPrepreparedContentLinks = async (
    client: Client,
    context: { simulation: Simulation; triggers: Trigger[] },
    linksMap: Record<string, string>,
): Promise<Record<string, string[]>> => {
    const { simulation, triggers } = context;

    const dataWithTriggerUUID = reduce(
        (acc, [itemUUID, triggerTitle]) => {
            const trigger = find((t) => t.title === triggerTitle, triggers);
            if (!trigger) {
                throw new Error('trigger could not be found');
            }
            return { ...acc, [itemUUID]: trigger.uuid };
        },
        {},
        toPairs(linksMap),
    );

    const { data: updateTriggerPrepreparedContentLinksData } = await updateTriggerPrepreparedContentLinks.promise(
        client,
        {
            simulationUUID: simulation.uuid,
            newLinks: JSON.stringify(dataWithTriggerUUID),
        },
    );
    const triggerPrepreparedContentItemLinksMap = updateTriggerPrepreparedContentLinksData
        ? JSON.parse(updateTriggerPrepreparedContentLinksData.updateTriggerPrepreparedContentLinks)
        : {};

    return triggerPrepreparedContentItemLinksMap;
};

const doCreatePages = async (
    client: Client,
    context: { uploadedImages: Record<string, string> },
    newPages: NewPage[],
): Promise<Page[]> => {
    const { uploadedImages } = context;
    const allCreatePagesResponse = await Promise.all(
        map(
            (page) =>
                createPage.promise(client, {
                    page: {
                        ...page,
                        stats: JSON.stringify(page.stats),
                        dressing: {
                            header: page.dressing.header && uploadedImages[page.dressing.header],
                            leftSidebar: page.dressing.leftSidebar && uploadedImages[page.dressing.leftSidebar],
                            rightSidebar: page.dressing.rightSidebar && uploadedImages[page.dressing.rightSidebar],
                            footer: page.dressing.footer && uploadedImages[page.dressing.footer],
                        },
                    },
                }),
            newPages,
        ),
    );
    const pages = reject(
        isNil,
        map(
            ({ data: createPageData }) =>
                createPageData && { ...createPageData.createPage, stats: JSON.parse(createPageData.createPage.stats) },
            allCreatePagesResponse,
        ),
    ) as Page[]; // typescript doesn't pick up that reject removed all nulls/undefined

    return pages;
};
const doPutPageOrder = async (
    client: Client,
    context: { simulation: Simulation },
    pages: Page[],
): Promise<string[]> => {
    const { simulation } = context;
    const { data: putPageOrderData } = await putPageOrder.promise(client, {
        simulationUUID: simulation.uuid,
        pageOrder: pluck('uuid', pages),
    });
    const pageOrder = putPageOrderData ? putPageOrderData.putPageOrder : [];

    return pageOrder;
};

export const createSimulationAndAssociatedData = async (
    client: Client,
    adminState: AdminState,
    data: UploadedSimulationAndAssociatedData,
    imageFiles: Record<string, File>,
): Promise<Simulation> => {
    /***** Create Simulation *****/
    // Upload images for pages
    const simulation = await doCreateSimulation(client, { imageFiles }, dissoc('administratorEmails', data.simulation));
    adminState.setSimulation(simulation);

    /***** Create Teams *****/
    const teams = await doCreateTeams(
        client,
        map((team) => ({ ...team, simulationUUID: simulation.uuid }), data.teams),
    );
    adminState.setTeams({
        ...adminState.teams,
        [simulation.uuid]: fromPairs(map((team) => [team.uuid, team], teams)),
    });

    /***** Create Pages *****/
    // Upload images for pages
    const uploadedPageImages = await doUploadPagesImages(client, {
        simulation,
        images: pick(data.pages.images, imageFiles),
    });
    // Create actual pages
    const pages = await doCreatePages(
        client,
        { uploadedImages: uploadedPageImages },
        map((page) => ({ ...page, simulationUUID: simulation.uuid, sheetName: undefined }), data.pages.data),
    );
    adminState.setPages(simulation.uuid, fromPairs(map((page) => [page.uuid, page], pages)));
    // Save page order with new uuids
    const pageOrder = await doPutPageOrder(client, { simulation }, pages);
    adminState.setPageOrder(simulation.uuid, pageOrder);

    /***** Create Preprepared Email *****/
    // Create preprepared content
    const allCreatePrepreparedEmailResults = await doCreatePrepreparedEmails(client, {
        simulation,
        uploadedContent: data.prepreparedEmails,
    });
    adminState.setPrepreparedEmails(
        simulation.uuid,
        fromPairs(map((item) => [item.uuid, item], allCreatePrepreparedEmailResults.prepreparedEmailItems)),
    );

    /***** Create Preprepared Content *****/
    // Upload images for prepreparedContent
    const uploadedPrepreparedImages = await doUploadPrepreparedContentImages(client, {
        simulation,
        images: pick(data.prepreparedContent.images, imageFiles),
    });
    // Create preprepared content
    const allCreatePrepreparedContentResults = await doCreatePrepreparedContents(client, {
        simulation,
        pages,
        uploadedContent: data.prepreparedContent.data,
        uploadedImages: uploadedPrepreparedImages,
    });
    adminState.setPrepreparedContents(
        simulation.uuid,
        fromPairs(map((item) => [item.uuid, item], allCreatePrepreparedContentResults.prepreparedContentItems)),
    );

    /***** Create Triggers *****/
    // Upload images for pages dressing changes
    const uploadedPageDressingImages = await doUploadPagesImages(client, {
        simulation,
        images: pick(data.triggers.images, imageFiles),
    });
    // Create actual triggers
    const triggers = await doCreateTriggers(
        client,
        {
            pages,
            uploadedImages: uploadedPageDressingImages,
            prepreparedContentTempIdMap: allCreatePrepreparedContentResults.prepreparedContentTempIdMap,
        },
        map((trigger) => ({ ...trigger, simulationUUID: simulation.uuid }), data.triggers.data),
    );
    adminState.setTriggers(simulation.uuid, fromPairs(map((trigger) => [trigger.uuid, trigger], triggers)));
    // Save trigger order with new uuids
    const triggerOrder = await doPutTriggerOrder(client, { simulation }, triggers);
    adminState.setTriggerOrder(simulation.uuid, triggerOrder);

    /***** Misc *****/
    // Create links between triggers and preprepared content
    const triggerAndContentLinks = await doPutTriggerPrepreparedContentLinks(
        client,
        { simulation, triggers },
        {
            ...allCreatePrepreparedContentResults.prepreparedContentTriggerLinks,
            ...allCreatePrepreparedEmailResults.prepreparedEmailTriggerLinks,
        },
    );
    adminState.setTriggerPrepreparedContentLinks(simulation.uuid, triggerAndContentLinks);

    return simulation;
};
