import gql from 'graphql-tag';
import { NewTeam, Team } from 'polpeo-go-common/types/Team';
import wrapMutation from '../wrapMutation';

interface EditTeamParams {
    team: Omit<NewTeam, 'pageStats'> & { pageStats: string /* AWSJSON - Record<string, Record<string>> */ };
}

interface EditTeamData {
    editTeam: Omit<Team, 'pageStats'> & { pageStats: string /* AWSJSON - Record<string, Record<string>> */ };
}

const mutation = gql`
    mutation editTeam($team: TeamInput!) {
        editTeam(team: $team) {
            uuid
            simulationUUID
            name
            roleplayerUUIDs
            documentUUIDs
            overview {
                title
                description
                headerImage
            }
            pageStats
        }
    }
`;

export const editTeam = wrapMutation<EditTeamParams, EditTeamData>(mutation);
