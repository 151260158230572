import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { cond, map, mergeDeepRight, reduce, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { getContentItemFields } from '../../../../utils/getContentItemFields';
import { getPersonaList } from '../../../../utils/getPersonaList';
import { FormGrid } from '../../../templates/FormGrid';
import { pageTemplates } from '../../../templates/PageTemplate/templates';
import { SlimTabs } from '../../../templates/SlimTabs';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { makeContentTabFormFields } from './makeContentTabFormFields';
import { makeSetupTabFormFields } from './makeSetupTabFormFields';
import { OptionItem } from '../../../bits/FormFields';

const tabs = ['Setup', 'Content'];

type ContentItemFormFields = Pick<ContentItem, 'content'> &
    Partial<Pick<ContentItem, 'parentUUID' | 'teamUUID' | 'persona' | 'createdBy' | 'deleted'>>;

type ContentItemFormProps =
    | {
          currentContentItem: ContentItemFormFields;
          onChange: (item: ContentItemFormFields) => void;
          currentTeams?: undefined;
          setCurrentTeams?: undefined;
          onSubmit?: (item: ContentItemFormFields) => void;
          footer?: JSX.Element;
          errors: Record<string, string>;
          pageOptionItems?: OptionItem[];
      }
    | {
          currentContentItem: ContentItemFormFields;
          onChange: (item: ContentItemFormFields) => void;
          currentTeams: string[];
          setCurrentTeams: (teams: string[]) => void;
          onSubmit?: (item: ContentItemFormFields) => void;
          footer?: JSX.Element;
          errors: Record<string, string>;
          pageOptionItems?: OptionItem[];
      };

export const StaffContentItemForm: FC<ContentItemFormProps> = ({
    currentContentItem,
    onSubmit,
    onChange,
    currentTeams,
    setCurrentTeams,
    footer,
    errors,
    pageOptionItems,
}) => {
    const {
        simulation,
        simulationContent: { contentItems, pages },
        teamsForCurrentStaffUser,
    } = useContext(AdminInSimulationStateContext);
    const startingTab = currentContentItem.persona ? tabs[1] : tabs[0];
    const [currentTab, setCurrentTab] = useState(startingTab);

    const { currentFields, currentTemplate } = useMemo(() => {
        const currentPage = pages[currentContentItem.content.pageUUID];
        const currentTemplate = currentPage ? pageTemplates[currentPage.templateUUID] : undefined;
        const currentFields = currentTemplate ? getContentItemFields(currentTemplate, currentContentItem) : [];

        return { currentPage, currentTemplate, currentFields };
    }, [currentContentItem.content.pageUUID]);

    const onFieldsChange = (
        contentOrFunction: ContentItemFormFields | ((item: ContentItemFormFields) => ContentItemFormFields),
    ) => {
        if (typeof contentOrFunction === 'function') {
            onChange(contentOrFunction(currentContentItem));
        } else {
            onChange(mergeDeepRight(currentContentItem, contentOrFunction));
        }
    };

    const personas = useMemo(() => {
        if (currentContentItem.createdBy?.type === 'PARTICIPANT') {
            return [];
        }

        const contentItemListPerTeam = values(contentItems);
        const contentItemList = reduce(
            (acc, itemsForTeam) => ({ ...acc, ...(itemsForTeam as Record<string, ContentItem>) }),
            {} as Record<string, ContentItem>,
            contentItemListPerTeam,
        );
        return getPersonaList(
            contentItemList,
            currentContentItem.teamUUID ? [currentContentItem.teamUUID] : currentTeams,
        );
    }, [currentTeams, contentItems]);

    const currentTabFormFields = cond([
        [
            () => currentTab === 'Setup',
            () =>
                currentTeams && setCurrentTeams && !currentContentItem.parentUUID
                    ? makeSetupTabFormFields(
                          currentContentItem,
                          onFieldsChange,
                          personas,
                          errors,
                          currentTemplate,
                          values(teamsForCurrentStaffUser),
                          currentTeams,
                          setCurrentTeams,
                          pageOptionItems,
                      )
                    : makeSetupTabFormFields(currentContentItem, onFieldsChange, personas, errors, currentTemplate),
        ],
        [
            () => currentTab === 'Content',
            () => makeContentTabFormFields(simulation, currentFields, currentContentItem, onFieldsChange),
        ],
    ])();

    return (
        <FormGrid
            header={
                <SlimTabs
                    tabs={map((tab) => [tab, undefined], tabs)}
                    activeTab={currentTab}
                    onChangeTab={setCurrentTab}
                    disabledTabs={currentContentItem.content.pageUUID ? [] : ['Content']}
                />
            }
            onSubmit={async () => {
                if (onSubmit) onSubmit(currentContentItem);
            }}
            fields={currentTabFormFields}
            footer={footer}
        />
    );
};
