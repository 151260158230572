import { Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { createTrigger } from '../../../../../graphql/triggers/createTrigger';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { TextInput } from '../../../../bits/FormFields';
import { Spinner } from '../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../../patterns/Modal';
import { AdminStateContext } from '../../../../WithAdminState/adminState';

interface NewTriggerModalProps {
    simulation: Simulation;
    onModalClose: () => void;
}

export const NewTriggerModal: FC<NewTriggerModalProps> = ({ simulation, onModalClose }) => {
    const history = useHistory();
    const { addTrigger } = useContext(AdminStateContext);
    const [triggerTitle, setTriggerTitle] = useState('');

    const [createTriggerMutation, { data: createTriggerData, loading: createTriggerLoading }] = createTrigger.hook();

    useEffect(() => {
        if (createTriggerData) {
            const trigger = createTriggerData.createTrigger;
            addTrigger({ ...trigger, pageDressingChanges: JSON.parse(trigger.pageDressingChanges) });
            history.push(`/admin/simulation/${simulation.uuid}/manage/triggers/edit/${trigger.uuid}`);
        }
    }, [createTriggerData]);

    const hasUnsavedChanges = !!triggerTitle && !createTriggerData?.createTrigger;
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onModalClose,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal header="Create Trigger" onModalClose={onModalCloseUnsavedPrompt} cardWidth={500}>
                {createTriggerLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!createTriggerLoading && (
                    <Display.VerticalWithSpacing>
                        <div>
                            <TextInput value={triggerTitle} onChange={(e) => setTriggerTitle(e.target.value)} />
                        </div>
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                            <PrimaryButton
                                disabled={!triggerTitle}
                                onClick={async () => {
                                    createTriggerMutation({
                                        variables: {
                                            trigger: {
                                                simulationUUID: simulation.uuid,
                                                title: triggerTitle,
                                                simulatedTime: '',
                                                isOptional: false,
                                                participantPopupSettings: { enabled: false },
                                                staffPopupSettings: {},
                                                overviewChanges: {},
                                                pageDressingChanges: JSON.stringify({}),
                                                releasedAt: undefined,
                                            },
                                        },
                                    });
                                }}
                            >
                                Save
                            </PrimaryButton>
                        </Display.HorizontalWithSpacing>
                    </Display.VerticalWithSpacing>
                )}
            </Modal>
        </>
    );
};
