import styled from 'styled-components/macro';
import { BaseDiv } from '../utils';

export const ChildrenItemsList = styled(BaseDiv)`
    width: ${({ theme }) => (theme.width ? `${theme.width}px` : '100%')};
    flex: 0 0 auto;

    display: grid;
    grid-template-columns: auto;

    row-gap: ${({ theme: { verticalGap } }) => (verticalGap ? `${verticalGap}px` : 0)};
    column-gap: ${({ theme: { horizontalGap } }) => (horizontalGap ? `${horizontalGap}px` : 0)};

    align-content: start;

    & > * {
        min-width: 0;
    }
`;
