/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { primaryColour } from '../../../../../themes/colours';
import negativeSocial from './negative-social.svg';
import newsHero from './newsHero.png';
import logo from './logo.png';
import positiveSocial from './positive-social.svg';
import { assocPath, pipe } from 'ramda';

const basePageTemplate: PageTemplate = {
    uuid: '',
    name: '',
    category: 'News',
    icon: logo,
    rootItemOptions: {
        name: 'Article',
        requiredFields: ['header', 'hero', 'summary', 'content'],
        fields: [
            { name: 'header', type: ContentItemFieldType.TEXT },
            { name: 'hero', type: ContentItemFieldType.IMAGE },
            { name: 'summary', type: ContentItemFieldType.LONG_TEXT },
            { name: 'content', type: ContentItemFieldType.RICHTEXT },
        ],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    showItemsAsIndividualPages: { pageTitleField: 'header' },
    childItemOptions: {
        name: 'Comment',
        pluralName: 'Comments',
        requiredFields: ['comment'],
        fields: [{ name: 'comment', type: ContentItemFieldType.LONG_TEXT }],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: {
                enabled: true,
                displayName: undefined,
                img: negativeSocial,
            },
            shares: {
                enabled: false,
            },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 5 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.GRID, columns: 3 },
        childrenSettings: {},
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['hero', 'header', 'summary', 'meta', 'childrenCount'],
        childContentFieldOrder: [],
        detailsLinkFields: ['all'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: {
                    margin: { right: 10 },
                },
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
                main: { width: 810 },
            },
            rootItemsContainer: {
                verticalGap: 30,
                horizontalGap: 30,
            },
            rootItem: {
                width: 250,
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                    padding: { top: 0, left: 0, bottom: 10, right: 0 },
                },
                meta: {
                    alignment: 'flex-end',
                },
                interactions: {
                    horizontalGap: 25,
                    padding: { top: 25, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    hero: {
                        margin: { top: 0, left: 0, bottom: 4, right: 0 },
                        height: 200,
                    },
                    header: {
                        font: {
                            size: 'LARGE',
                            weight: 'BOLD',
                        },
                        margin: { top: 10, left: 0, bottom: 10, right: 0 },
                    },
                    summary: {
                        margin: { top: 0, left: 0, bottom: 10, right: 0 },
                    },
                },
                showChildItemsCount: {
                    position: 'use field order',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                    alignSelf: 'end',
                },
            },
            childrenItemsContainer: {},
            childItemContainer: {},
            childItem: {
                margin: { top: 10, left: 15, bottom: 10, right: 15 },
                avatar: { position: 'gutter' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 10,
                    iconHeight: 12,
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    comment: {
                        margin: { top: 10, left: 0, bottom: 10, right: 0 },
                    },
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'append',
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['header', 'hero', 'meta', 'content'],
        childContentFieldOrder: ['meta', 'comment', 'interactions'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                padding: { top: 0, left: 0, bottom: 100, right: 0 },
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: {
                    margin: { right: 10 },
                },
                main: { width: 800 },
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                verticalGap: 0,
                horizontalGap: 0,
                margin: { top: 0, left: 0, bottom: 20, right: 0 },
            },
            rootItem: {
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                    padding: { top: 0, left: 0, bottom: 10, right: 0 },
                },
                meta: { margin: { top: 0, left: 0, bottom: 12, right: 0 } },
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    hero: {
                        margin: { top: 0, left: 0, bottom: 4, right: 0 },
                    },
                    header: {
                        font: {
                            size: 'EXTRA_LARGE',
                            weight: 'BOLD',
                        },
                        margin: { top: 0, left: 0, bottom: 4, right: 0 },
                    },
                },
            },
            childrenItemsContainer: {},
            childItemContainer: {
                padding: { top: 0, left: 10, bottom: 0, right: 0 },
                borders: {
                    left: {
                        width: 2,
                        color: primaryColour,
                    },
                },
            },
            childItem: {
                margin: { top: 10, left: 0, bottom: 10, right: 0 },
                padding: { top: 8, left: 8, bottom: 8, right: 8 },
                shadow: true,
                borderRadius: 5,
                borders: {
                    top: {
                        width: 1,
                        color: '#dddddd',
                    },
                    right: {
                        width: 1,
                        color: '#dddddd',
                    },
                    bottom: {
                        width: 1,
                        color: '#dddddd',
                    },
                    left: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                avatar: { position: 'gutter' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    layout: 'flex-end',
                    horizontalGap: 10,
                    iconHeight: 12,
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
        },
    },
};

export const pageTemplateWithChildren: PageTemplate = {
    ...basePageTemplate,
    uuid: '854d376c-327a-4d1f-8119-e68c817374d7',
    name: 'News with Comments',
};

export const pageTemplateWithoutChildren: PageTemplate = pipe(
    assocPath(['uuid'], 'fc4ae3a0-8876-4cd7-b765-c8b7af6640fb'),
    assocPath(['name'], 'News without Comments'),
    assocPath(['childItemOptions', 'interactions'], {
        positive: { enabled: false },
        negative: { enabled: false },
        shares: { enabled: false },
    }),
    assocPath(['listingPage', 'childrenSettings'], {}),
    assocPath(['listingPage', 'rootContentFieldOrder'], ['hero', 'header', 'summary', 'meta']),
    assocPath(['detailsPage', 'childrenSettings'], {}),
)(basePageTemplate) as PageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                header: 'Lorem ipsum dolor sit amet',
                summary: 'Nam euismod metus at tellus ultrices luctus',
                content: `<b>Lorem ipsum</b> dolor sit amet, consectetur adipiscing elit. Pellentesque purus ipsum, egestas ut gravida non, porta id purus. Aliquam at bibendum nibh. Proin eget est fringilla, iaculis ante non, posuere lorem. Nullam at porta dui. Cras tincidunt vestibulum cursus. Suspendisse consequat finibus ipsum ut bibendum. Suspendisse quis malesuada lectus, ut vehicula lacus. Nullam porttitor eros a blandit rutrum.
                <br/>
                <b>Morbi venenatis</b> turpis mi, non fermentum sapien condimentum ultricies. Phasellus a odio feugiat, tempor turpis et, fermentum neque. Vestibulum efficitur accumsan semper. Nunc rhoncus vitae leo vel ornare. Duis nulla metus, volutpat non purus eget, congue mattis metus. Fusce nec tortor eu enim sollicitudin tristique vel id turpis. Suspendisse nec dui vitae diam maximus efficitur sed id orci. Pellentesque rutrum ligula sit amet quam consectetur laoreet.
                <br/>
                <b>Curabitur vel</b> commodo massa, quis faucibus erat. Aliquam varius pretium accumsan. Curabitur eget libero sodales, mattis dui vel, porta nunc. Ut vel lorem tempus, egestas risus sit amet, pellentesque lacus. Aliquam consequat dolor nec pellentesque dictum. Pellentesque viverra id turpis quis pellentesque. Sed a ullamcorper nibh, vitae fringilla lorem. Donec vestibulum elementum velit. Donec vitae auctor arcu. Aenean euismod libero metus, ac elementum risus laoreet vitae. Donec at felis placerat, ultrices nunc quis, interdum lorem. Nam mattis commodo magna, a mattis nunc aliquam sit amet. Aliquam eu sapien sit amet enim rhoncus laoreet eu eget neque.
                <br/>
                <b>Sed tincidunt</b> dapibus odio vel pellentesque. Cras tincidunt dapibus felis, id feugiat metus finibus at. Fusce scelerisque tortor sit amet ex tincidunt pharetra. Nullam eu ante turpis. Mauris eu diam sapien. Cras ac neque iaculis, volutpat nunc non, rhoncus sem. Nulla et ultricies lorem, nec sodales elit. Vestibulum at leo magna. Nunc dictum ut ante vitae vestibulum. Mauris mauris ligula, cursus non venenatis convallis, tempor ut lectus. Ut eget efficitur lacus. Duis at egestas neque. Nulla ut condimentum purus. Curabitur porta efficitur odio, sed suscipit dolor mollis vitae.
                <br/>
                <b>Nam feugiat</b> quam sed tortor iaculis, congue elementum nisi pharetra. Curabitur mollis sapien vitae sem sodales laoreet. Phasellus ultrices, nisi feugiat aliquam pulvinar, sapien elit ultrices turpis, volutpat posuere neque turpis pellentesque neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tortor neque, consequat id risus a, egestas tristique mi. Integer faucibus lacus vel venenatis aliquet. Nulla ac ligula cursus, lobortis lorem sit amet, lacinia neque. Phasellus dictum odio non magna placerat commodo. Maecenas ante dui, venenatis et diam vitae, consequat bibendum ligula. Quisque porttitor erat ante, id dictum nibh eleifend vel.
                <br/>
                <b>In hac</b> habitasse platea dictumst. Sed laoreet dolor a vulputate blandit. Integer dictum ultrices lacus. Fusce tellus elit, vehicula ut dignissim non, luctus in libero. Nulla porta lectus a lacus sodales scelerisque. Vivamus fermentum est justo, accumsan egestas purus hendrerit quis. Sed ac est nec nulla dapibus gravida in in purus.
                <br/>
                <b>Donec dapibus</b> nisi ac dui placerat cursus. Vivamus sed sem non quam sagittis tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur diam metus, pulvinar eget euismod at, scelerisque et sem. Nulla odio nisi, varius non nunc sit amet, sagittis mattis felis. Maecenas quis mi quis dui maximus rhoncus. Etiam imperdiet, arcu in rhoncus dapibus, massa neque gravida felis, id interdum magna mauris at quam. Duis aliquet est vitae lectus egestas interdum.
                <br/>
                <b>Donec erat</b> sem, lobortis ut sem sed, iaculis egestas lectus. Fusce ante ex, malesuada non venenatis vel, dignissim id tellus. Duis bibendum rutrum nunc, at gravida dolor malesuada at. Mauris dapibus dui arcu, a lacinia nunc eleifend vel. Donec sit amet felis eget metus iaculis tempor. Sed id ante tempor, consequat leo id, facilisis quam. Suspendisse vehicula facilisis enim, ut ultrices erat tempus sit amet. Donec aliquam tristique risus, facilisis finibus quam elementum vel. Phasellus sit amet purus sit amet quam consectetur vulputate et ut orci. Phasellus metus elit, dignissim accumsan egestas eget, auctor nec massa. Integer iaculis turpis felis, et luctus risus imperdiet in. Integer elit mi, gravida sit amet nisi suscipit, cursus viverra nisl. Ut purus mauris, malesuada vel mollis at, eleifend pulvinar tortor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                <br/>
                <b>Vivamus aliquam</b> mi at fringilla sagittis. In vehicula lorem eget lorem pretium ultrices. Nam tellus dolor, convallis ut semper a, aliquet ac tortor. Cras auctor vel ligula eu consequat. Vivamus venenatis convallis nulla, ut porta nisi vestibulum nec. Nunc nisl nisi, pulvinar in imperdiet sit amet, hendrerit et eros. Curabitur et semper lectus, vel bibendum nisi. Nulla vitae ultrices diam. Pellentesque varius consequat ultrices. Suspendisse vel euismod metus. Nunc tristique erat urna.
                <br/>
                <b>Vestibulum eleifend</b> eget est in fringilla. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur vel ligula at augue tempor volutpat nec ac leo. Nullam ex ipsum, rutrum et dictum ut, scelerisque quis mi. Quisque ultrices mattis arcu, in pulvinar felis dictum quis. Etiam mollis odio justo, sed varius nulla congue ullamcorper. Sed tristique scelerisque justo, eu rutrum mauris molestie vel. Donec elementum eros id dolor rutrum aliquam. Sed pretium feugiat erat, eu accumsan mauris sagittis in. Ut quis vehicula metus, sit amet tempor libero. Aliquam erat volutpat. Ut tincidunt ac tortor sit amet vehicula. Nam condimentum nisi risus, vel malesuada quam tristique et.
                `,
                hero: newsHero,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 93,
                negative: 72,
            },
            data: {
                comment: 'Morbi venenatis turpis mi, non fermentum sapien condimentum ultricies',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 21,
                negative: 3,
            },
            data: {
                comment: 'Phasellus a odio feugiat, tempor turpis et, fermentum neque',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidba',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 39,
                negative: 19,
            },
            data: {
                comment: 'Vestibulum eleifend eget est in fringilla',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 45,
                negative: 23,
            },
            data: {
                comment:
                    'Suspendisse vehicula facilisis enim, ut ultrices erat tempus sit amet. Donec aliquam tristique risus, facilisis finibus quam elementum vel.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbaa',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidba',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 20,
                negative: 26,
            },
            data: {
                comment:
                    'Vivamus aliquam mi at fringilla sagittis. In vehicula lorem eget lorem pretium ultrices. Nam tellus dolor, convallis ut semper a, aliquet ac tortor. Cras auctor vel ligula eu consequat. Vivamus venenatis convallis nulla, ut porta nisi vestibulum nec. Nunc nisl nisi, pulvinar in imperdiet sit amet, hendrerit et eros.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                header: 'Bupa dup dup dop',
                summary: 'Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
                hero: newsHero,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid3',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                header: 'In vehicula lorem eget lorem pretium ultrices.',
                summary:
                    'Donec erat sem, lobortis ut sem sed, iaculis egestas lectus. Fusce ante ex, malesuada non venenatis vel, dignissim id tellus. Duis bibendum rutrum nunc, at gravida dolor malesuada at.',
                hero: newsHero,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid4',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3, hours: 11 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                header: 'In hac habitasse platea dictumst',
                summary:
                    'Nulla porta lectus a lacus sodales scelerisque. Vivamus fermentum est justo, accumsan egestas purus hendrerit quis. Sed ac est nec nulla dapibus gravida in in purus.',
                hero: newsHero,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid5',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 4 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                header: 'Sed tincidunt dapibus odio vel pellentesque',
                summary: 'Nam feugiat quam sed tortor iaculis, congue elementum nisi pharetra',
                hero: newsHero,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
