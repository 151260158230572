import { ContentItem, decodeContentItem, NewContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { ContentItemsListingPageLayoutType } from 'polpeo-go-common/types/PageTemplate';
import { all, equals, last, mergeDeepRight, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { createContentItem } from '../../../graphql/contentItem';
import { checkRequiredFields } from '../../../utils/checkRequiredFields';
import { getChildrenForItem } from '../../../utils/getChildrenForItem';
import { getContentItemRequiredFields } from '../../../utils/getContentItemRequiredFields';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { H2Heading } from '../../bits/Headers';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { EmbeddedContentItem } from '../../templates/PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { ParticipantStateContext } from '../../WithParticipantState';
import { Card } from '../Card';
import { Modal } from '../Modal';
import { ParticipantContentItemForm } from './ParticipantContentItemForm';

interface NewParticipantContentItemModalProps {
    page: Page;
    parent?: ContentItem;
    onCloseModal: () => void;
}
export const NewParticipantContentItemModal: FC<NewParticipantContentItemModalProps> = ({
    page,
    parent,
    onCloseModal,
}) => {
    const { simulation, participantTeam, addContentItems, contentItems } = useContext(ParticipantStateContext);

    if (!participantTeam) {
        throw new Error('Participant team could not be found');
    }

    const initialContentItem = {
        type: 'PAGE_CONTENT',
        simulationUUID: simulation.uuid,
        teamUUID: participantTeam.uuid,
        parentUUID: parent?.uuid,
        content: {
            pageUUID: page.uuid,
            interactions: { positive: 0, negative: 0, shares: 0 },
            data: {},
        },
        persona: '',
        prepreparedContentUUID: undefined,
        deleted: undefined,
    } as const;
    const [currentContentItem, setCurrentContentItem] = useState<NewContentItem>(initialContentItem);

    const [createContentItemMutation, { data: createContentItemData, loading: createContentItemsLoading }] =
        createContentItem.hook();
    useEffect(() => {
        if (createContentItemData?.createContentItem) {
            addContentItems([
                decodeContentItem({
                    ...createContentItemData?.createContentItem,
                    content: {
                        ...createContentItemData?.createContentItem.content,
                        data: JSON.parse(createContentItemData?.createContentItem.content.data),
                    },
                }),
            ]);
            onCloseModal();
        }
    }, [createContentItemData]);

    const template = pageTemplates[page.templateUUID];
    const requiredFields = template ? getContentItemRequiredFields(template, currentContentItem) : [];

    const isAllContentDataEmpty = all((value) => !value, values(currentContentItem.content.data));
    const canSave = checkRequiredFields(requiredFields, currentContentItem).valid && !isAllContentDataEmpty;

    const hasUnsavedChanges = !equals(currentContentItem, initialContentItem);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    const itemToDisplay = useMemo(() => {
        const isChatPageType = template.listingPage.layout.type === ContentItemsListingPageLayoutType.CHAT;
        const childItems = !!parent && !!contentItems ? getChildrenForItem(contentItems, parent) : [];
        const lastChildItem = last(childItems);
        return isChatPageType && lastChildItem ? lastChildItem : parent;
    }, [parent, currentContentItem]);

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={800}>
                <Display.VerticalWithSpacing>
                    {!!itemToDisplay && (
                        <Card maxCardWidth={760}>
                            <EmbeddedContentItem
                                item={itemToDisplay}
                                contentItemOptions={{
                                    disableDetailsLink: true,
                                    disableManageMenu: true,
                                }}
                            />
                        </Card>
                    )}
                    <H2Heading>{`Create ${
                        !parent ? template.rootItemOptions.name : template.childItemOptions.name
                    }`}</H2Heading>
                    {createContentItemsLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!createContentItemsLoading && (
                        <ParticipantContentItemForm
                            currentContentItem={currentContentItem}
                            onChange={(fields) => setCurrentContentItem(mergeDeepRight(currentContentItem, fields))}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                    <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        disabled={!canSave}
                                        onClick={() => {
                                            createContentItemMutation({
                                                variables: {
                                                    contentItem: {
                                                        ...currentContentItem,
                                                        content: {
                                                            ...currentContentItem.content,
                                                            data: JSON.stringify(currentContentItem.content.data),
                                                        },
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        Create
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    )}
                </Display.VerticalWithSpacing>
            </Modal>
        </>
    );
};
