import * as t from 'io-ts';
import optionalToUndefined from '../../t/optionalToUndefined';

const BorderOptions = t.partial({
    width: t.number,
    style: t.union([t.literal('solid'), t.literal('dotted'), t.literal('dash')]),
    color: t.string,
});

export const BaseThemingOptions = t.partial({
    width: t.number,
    maxWidth: t.number,
    height: t.number,
    square: t.boolean,
    bgColour: t.string,
    padding: t.partial({
        top: optionalToUndefined(t.number),
        right: optionalToUndefined(t.number),
        bottom: optionalToUndefined(t.number),
        left: optionalToUndefined(t.number),
    }),
    margin: t.partial({
        top: optionalToUndefined(t.number),
        right: optionalToUndefined(t.number),
        bottom: optionalToUndefined(t.number),
        left: optionalToUndefined(t.number),
    }),
    font: t.partial({
        color: optionalToUndefined(t.string),
        size: optionalToUndefined(
            t.union([t.literal('SMALL'), t.literal('MEDIUM'), t.literal('LARGE'), t.literal('EXTRA_LARGE')]),
        ),
        weight: optionalToUndefined(t.union([t.literal('NORMAL'), t.literal('BOLD')])),
    }),
    shadow: t.boolean,
    borderRadius: t.number,
    borders: t.partial({
        top: optionalToUndefined(BorderOptions),
        right: optionalToUndefined(BorderOptions),
        bottom: optionalToUndefined(BorderOptions),
        left: optionalToUndefined(BorderOptions),
    }),
    alignSelf: optionalToUndefined(
        t.union([t.literal('stretch'), t.literal('start'), t.literal('center'), t.literal('end')]),
    ),
    objectFit: optionalToUndefined(
        t.union([
            t.literal('contain'),
            t.literal('cover'),
            t.literal('fill'),
            t.literal('none'),
            t.literal('scale-down'),
        ]),
    ),
});
export type BaseThemingOptions = t.TypeOf<typeof BaseThemingOptions>;
