import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface PutTriggerOrderParams {
    simulationUUID: string;
    triggerOrder: string[];
}

interface PutTriggerOrderData {
    putTriggerOrder: string[];
}

const mutation = gql`
    mutation putTriggerOrder($simulationUUID: String!, $triggerOrder: [String!]!) {
        putTriggerOrder(simulationUUID: $simulationUUID, triggerOrder: $triggerOrder)
    }
`;

export const putTriggerOrder = wrapMutation<PutTriggerOrderParams, PutTriggerOrderData>(mutation);
