import gql from 'graphql-tag';
import { Team } from 'polpeo-go-common/types/Team';
import wrapQuery from '../wrapQuery';

interface GetTeamForParticipantParams {
    simulationUUID: string;
}

interface GetTeamForParticipantData {
    getTeamForParticipant: {
        team?:
            | Omit<Team, 'pageStats'> & {
                  pageStats: string /* AWSJSON - Record<string, Record<string>> */;
              };
        lockedOn?: string;
    };
}

const query = gql`
    query getTeamForParticipant($simulationUUID: String!) {
        getTeamForParticipant(simulationUUID: $simulationUUID) {
            team {
                uuid
                simulationUUID
                name
                roleplayerUUIDs
                documentUUIDs
                overview {
                    title
                    description
                    headerImage
                }
                pageStats
            }
            lockedOn
        }
    }
`;

export const getTeamForParticipant = wrapQuery<GetTeamForParticipantParams, GetTeamForParticipantData>(query);
