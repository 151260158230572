import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { filter, reduce } from 'ramda';
import XLSX from 'xlsx';
import { pageTemplates } from '../../../PageTemplate/templates';
import { parseEmailsSheet } from './email/parseEmailsSheet';
import { parsePagesSheet, UploadedPage } from './page/parsePagesSheet';
import {
    ParsePrepreparedContentsResults,
    parsePrepreparedContentsSheet,
} from './prepreparedContent/parsePrepreparedContentsSheet';
import { parseSimulationSheet } from './simulation/parseSimulationSheet';
import { parseTeamsSheet } from './team/parseTeamsSheet';
import { parseTriggersSheet } from './trigger/parseTriggersSheet';
import { UploadedSimulationAndAssociatedData } from './UploadedSimulationAndAssociatedData';

export const parseXlsx = async (file: File, staffUsers: StaffUser[]): Promise<UploadedSimulationAndAssociatedData> => {
    return new Promise((resolvePromise, rejectPromise) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            if (e.target?.result) {
                const data = new Uint8Array(e.target.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array', cellDates: true });
                const sheets = workbook.Sheets;

                const simulationSheet = sheets.Simulation;
                const simulationResults = parseSimulationSheet(simulationSheet, staffUsers);

                const teamsSheet = sheets.Teams;
                const teamsResults = parseTeamsSheet(teamsSheet, staffUsers);

                const pagesSheet = sheets.Pages;
                const pagesResults = parsePagesSheet(pagesSheet, pageTemplates);

                const triggersSheet = sheets.Triggers;
                const triggersResults = parseTriggersSheet(triggersSheet, pagesResults.pages || []);

                const emailsSheet = sheets.Emails;
                const emailsResults = parseEmailsSheet(emailsSheet, triggersResults.triggers || []);

                if (
                    simulationResults.errors ||
                    teamsResults.errors ||
                    triggersResults.errors ||
                    pagesResults.errors ||
                    emailsResults.errors
                ) {
                    return rejectPromise([
                        ...(simulationResults.errors || []),
                        ...(teamsResults.errors || []),
                        ...(triggersResults.errors || []),
                        ...(pagesResults.errors || []),
                        ...(emailsResults.errors || []),
                    ]);
                }

                const prepreparedContentParseResults = reduce<UploadedPage, ParsePrepreparedContentsResults>(
                    (acc, page) => {
                        const contentSheet = sheets[page.sheetName || page.name] || sheets[page.name.substring(0, 31)];
                        if (!contentSheet) {
                            return acc;
                        }
                        const parseResults = parsePrepreparedContentsSheet(
                            contentSheet,
                            page.name,
                            triggersResults.triggers,
                            pageTemplates[page.templateUUID],
                        );
                        if (parseResults.errors || acc.errors) {
                            return {
                                errors: acc.errors
                                    ? [...acc.errors, ...(parseResults.errors || [])]
                                    : parseResults.errors || [],
                            };
                        }
                        return {
                            prepreparedContents: acc.prepreparedContents
                                ? [...acc.prepreparedContents, ...parseResults.prepreparedContents]
                                : parseResults.prepreparedContents,
                            images: acc.images ? [...acc.images, ...parseResults.images] : parseResults.images,
                        };
                    },
                    {} as ParsePrepreparedContentsResults,
                    pagesResults.pages,
                );
                if (prepreparedContentParseResults.errors) {
                    return rejectPromise(prepreparedContentParseResults.errors);
                }

                // Check that all trigger linked content items exist
                const triggerLinkErrors = reduce(
                    (acc, trigger) => {
                        const linkedItemIDs = trigger.participantPopupSettings.prepreparedContentItemUUID;
                        if (!linkedItemIDs) {
                            return acc;
                        }
                        const linkedItem = filter(
                            (item) =>
                                item.type === 'PAGE_CONTENT' && !!item.tempId && linkedItemIDs.includes(item.tempId),
                            prepreparedContentParseResults.prepreparedContents,
                        );

                        if (!linkedItem) {
                            // eslint-disable-next-line max-len
                            const errorMsg = `Trigger: Found trigger with linked prepared content with id '${trigger.participantPopupSettings.prepreparedContentItemUUID}' but preprepared content with this ID does not exist`;
                            if (acc) {
                                return [...acc, errorMsg];
                            } else {
                                return [errorMsg];
                            }
                        }
                        return acc;
                    },
                    undefined as undefined | string[],
                    triggersResults.triggers,
                );
                if (triggerLinkErrors) {
                    return rejectPromise(triggerLinkErrors);
                }

                resolvePromise({
                    simulation: simulationResults.simulation,
                    teams: teamsResults.teams,
                    triggers: {
                        data: triggersResults.triggers,
                        images: triggersResults.images,
                    },
                    pages: {
                        data: pagesResults.pages,
                        images: pagesResults.images,
                    },
                    prepreparedContent: {
                        data: prepreparedContentParseResults.prepreparedContents,
                        images: prepreparedContentParseResults.images,
                    },
                    prepreparedEmails: emailsResults.prepreparedEmails,
                });
            }
        };
        reader.readAsArrayBuffer(file);
    });
};
