import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { PrepreparedContentReleasedMeta } from './prepreparedContent';
import { PrepreparedEmailReleasedMeta } from './prepreparedEmail';
import wrapSubscription from './wrapSubscription';

interface OnReleasePrepreparedItemParams {
    simulationUUID: string;
}

export type PrepreparedItemReleasedMeta = PrepreparedContentReleasedMeta | PrepreparedEmailReleasedMeta;
interface OnReleasePrepreparedItemData {
    onReleasePrepreparedItem: {
        simulationUUID: string;
        item: GraphQlPrepreparedContentItem | GraphQlPrepreparedEmailItem;
        items: Array<PrepreparedItemReleasedMeta>;
    };
}

const subscription = gql`
    subscription onReleasePrepreparedItem($simulationUUID: String!) {
        onReleasePrepreparedItem(simulationUUID: $simulationUUID) {
            simulationUUID
            item {
                __typename
                ... on PrepreparedContent {
                    type
                    uuid
                    simulationUUID
                    parentUUID
                    persona
                    content {
                        pageUUID
                        interactions {
                            positive
                            negative
                            shares
                        }
                        data
                    }
                    released {
                        at
                        items
                    }
                    teamUUIDs
                }
                ... on PrepreparedEmail {
                    type
                    uuid
                    simulationUUID
                    parentUUID
                    persona
                    content {
                        recipients
                        subject
                        message
                    }
                    released {
                        at
                        items
                    }
                }
            }
            items {
                __typename
                ... on PrepreparedContent {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
                ... on PrepreparedEmail {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
            }
        }
    }
`;

export const onReleasePrepreparedItem =
    wrapSubscription<OnReleasePrepreparedItemParams, OnReleasePrepreparedItemData>(subscription);
