import styled, { css } from 'styled-components/macro';
import { grey1Colour, grey6Colour } from '../../themes/colours';

interface LozengeProps {
    small?: boolean;
    fill?: string;
    color?: string;
}

export const lozengeSnippet = css<LozengeProps>`
    background: ${({ fill }) => (fill ? fill : grey1Colour)};
    color: ${({ color }) => (color ? color : grey6Colour)};
    padding: ${({ small }) => (small ? '4px 12px' : '8px 14px')};
    border-radius: ${({ small }) => (small ? '7px' : '100px')};
    font-size: ${({ small }) => (small ? '11px' : '14px')};

    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Lozenge = styled.div<LozengeProps>`
    ${lozengeSnippet}
`;
