import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapMutation from '../wrapMutation';

interface EndSimulationParams {
    simulationUUID: string;
}

interface EndSimulationData {
    endSimulation: Simulation;
}

const mutation = gql`
    mutation endSimulation($simulationUUID: String!) {
        endSimulation(simulationUUID: $simulationUUID) {
            uuid
            name
            code
            createdAt
            createdBy
            scheduledFor
            lobbyOpenedAt
            startedAt
            completedAt
            archivedAt
            clientName
            isReady
            emailDomainWhitelist
            expectedNumberParticipants
            administratorUUIDs
            briefingDocuments {
                name
                createdAt
                path
                createdBy
            }
            briefingNotes {
                lastEditedAt
                lastEditedBy
                notes
            }
            startingOverview {
                title
                description
                headerImage
            }
            showParticipantsMomentsGraph
            createMomentsPermission
        }
    }
`;

export const endSimulation = wrapMutation<EndSimulationParams, EndSimulationData>(mutation);
