import { mergeAttributes, Node, NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';
import { includes, pipe, replace } from 'ramda';
import React, { FC } from 'react';
import { getVimeoVideoId, getYoutubeVideoId } from '../../../utils/getVideoId';
import { Link } from '../../bits/Link';
import { localhostRegex, pasteRegexExact } from './regexPatterns';

const RichtextCustomLink: FC<NodeViewProps> = ({ node: { attrs } }) => {
    const { href } = attrs;
    if (!href) {
        return <NodeViewWrapper as="span"></NodeViewWrapper>;
    }

    const self = includes(window.location.host, href);
    if (!self) {
        const cleanHref = !includes('https://', href) && !includes('http://', href) ? `https://${href}` : href;
        return (
            <NodeViewWrapper as="span">
                <a href={cleanHref} target="_blank" rel="noopener noreferrer nofollow">
                    {href}
                </a>
            </NodeViewWrapper>
        );
    }

    const cleanHref = pipe(
        replace(window.location.host, ''),
        replace('www.', ''),
        replace('https://', ''),
        replace('http://', ''),
    )(href);
    return (
        <NodeViewWrapper as="span">
            <Link to={cleanHref}>{href}</Link>
        </NodeViewWrapper>
    );
};

export interface CustomLinkOptions {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HTMLAttributes: Record<string, string | number>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        customLink: {
            setCustomLink: (href: string) => ReturnType;
            toggleCustomLink: (href: string) => ReturnType;
        };
    }
}

const CustomLink = Node.create<CustomLinkOptions>({
    name: 'customLink',

    priority: 1000,

    addOptions() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            HTMLAttributes: {},
        };
    },

    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,

    parseHTML() {
        return [{ tag: 'custom-link' }];
    },

    // eslint-disable-next-line @typescript-eslint/naming-convention
    renderHTML({ HTMLAttributes }) {
        return ['custom-link', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },

    addAttributes() {
        return {
            href: {
                default: null,
                renderHTML: (attributes) => attributes,
                parseHTML: (element) => element.getAttribute('href'),
            },
        };
    },

    addCommands() {
        return {
            setCustomLink:
                (href) =>
                ({ commands }) =>
                    commands.setNode('customLink', { href }),
            toggleCustomLink:
                (href) =>
                ({ commands }) =>
                    commands.toggleNode('customLink', 'paragraph', { href }),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(RichtextCustomLink);
    },

    addProseMirrorPlugins() {
        const plugins = [];

        // adapted from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts#L135
        plugins.push(
            new Plugin({
                key: new PluginKey('handlePasteLink'),
                props: {
                    handlePaste: (view, event, slice) => {
                        let textContent = '';

                        slice.content.forEach((node) => {
                            textContent += node.textContent;
                        });

                        if (
                            !textContent ||
                            (!textContent.match(pasteRegexExact) && !textContent.match(localhostRegex))
                        ) {
                            return false;
                        }

                        // if its a video link do nothing since we want to embed a video instead
                        if (getYoutubeVideoId(textContent) || getVimeoVideoId(textContent)) {
                            return false;
                        }

                        this.editor
                            .chain()
                            .focus()
                            .insertContent(`<custom-link href="${textContent}">${textContent}</custom-link>`)
                            .run();

                        return true;
                    },
                },
            }),
        );

        return plugins;
    },
});

export default CustomLink;
