import { Trigger } from 'polpeo-go-common/types/Trigger';
import { map } from 'ramda';
import { useMemo } from 'react';
import { OptionItem } from '../bits/FormFields';

export const useTriggerOptionItems = (triggers: Record<string, Trigger>, triggerOrder: string[]): OptionItem[] => {
    const triggerOptionItems = useMemo(
        () => [
            { value: '', label: '' },
            ...map((triggerUUID) => {
                const trigger = triggers[triggerUUID];
                return {
                    value: trigger.uuid,
                    label: trigger.releasedAt ? `${trigger.title} (Released)` : trigger.title,
                    disabled: !!trigger.releasedAt,
                };
            }, triggerOrder || []),
        ],
        [triggers, triggerOrder],
    );

    return triggerOptionItems;
};
