import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';
import { primaryColour } from '../../themes/colours';
import { TextInput } from '../bits/FormFields';

const DatePickerContainer = styled.div`
    .date-picker.full-width {
        display: block;
        width: 100%;
    }
    .react-datepicker__close-icon::after {
        background-color: ${primaryColour};
    }
`;

export const DatePickerInput: React.FC<ReactDatePickerProps> = (reactDatePickerProps) => {
    return (
        <DatePickerContainer>
            <DatePicker
                wrapperClassName="date-picker full-width"
                showTimeSelect={true}
                isClearable={true}
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
                customInput={<TextInput />}
                {...reactDatePickerProps}
            />
        </DatePickerContainer>
    );
};
