import styled, { createGlobalStyle } from 'styled-components/macro';
import { StaffAppContentContainer } from '../../../../../StaffAppContentContainer';
import { TabContentsContainer } from '../../../../patterns/TabMenu';

export const GlobalStyleForPage = createGlobalStyle`
    #root {
        max-height: 100vh;
        overflow-y: hidden;
    }

    ${StaffAppContentContainer} {
        overflow-y: hidden;
    }

    ${TabContentsContainer} {
        flex: 1 1 auto;
        max-height: 100%;
        overflow-y: hidden;
    }
`;

export const PageContentContainer = styled.div`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
`;

export const MainContentContainer = styled.div`
    position: relative;
    width: 100%;
    overflow-y: hidden;

    display: flex;

    flex: 1 1 auto;
`;

export const NavBarScrollBox = styled.div`
    overflow-y: auto;
    flex: 0 0 auto;

    & > * {
        min-height: 100%;
    }
`;

export const ScrollBox = styled.div`
    min-height: 100%;
    overflow-y: hidden;

    display: flex;

    flex: 1 1 auto;

    & > * {
        overflow-y: auto;
    }
`;
