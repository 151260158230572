import * as t from 'io-ts';

export const InteractionSettings = t.union([
    t.type({
        enabled: t.literal(true),
        displayName: t.undefined,
        img: t.string,
    }),
    t.type({
        enabled: t.literal(true),
        displayName: t.string,
        img: t.undefined,
    }),
    t.type({
        enabled: t.literal(false),
    }),
]);
export type InteractionSettings = t.TypeOf<typeof InteractionSettings>;
