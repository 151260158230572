import { map } from 'ramda';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../../themes/colours';
import { formLabel } from '../../../themes/cssSnippets';
import { CardContainer } from '../../bits/Card';

export const FormGridContainer = styled.form`
    display: grid;
    grid-template-columns: 200px 1fr;

    ${CardContainer} & {
        margin: -16px;
    }
`;

const FormGridCell = styled.div`
    label {
        text-transform: uppercase;
        ${formLabel}
    }

    border-bottom: 1px solid ${grey1Colour};
    padding: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child,
    &:nth-last-child(2) {
        border-bottom: none;
    }
`;

const FormGridHeader = styled(FormGridCell)`
    grid-column: 1 / span 2;
    grid-row: 1;
`;

const FormGridFooter = styled(FormGridCell)`
    grid-column: 1 / span 2;
    grid-row: 10000;

    border-bottom: none;

    ~ ${FormGridCell}:last-child, ~ ${FormGridCell}:nth-last-child(2) {
        border-bottom: 1px solid ${grey1Colour};
    }
`;

export type FormGridField = readonly [string, JSX.Element];
interface FormGridProps {
    onSubmit?: () => void;
    header?: JSX.Element;
    fields: FormGridField[];
    footer?: JSX.Element;
}
export const FormGrid: FC<FormGridProps> = ({ header, fields = [], onSubmit, footer }) => {
    return (
        <FormGridContainer
            onSubmit={async (event) => {
                event.preventDefault();
                event.stopPropagation();

                onSubmit && (await onSubmit());
            }}
        >
            {header && <FormGridHeader>{header}</FormGridHeader>}
            {footer && <FormGridFooter>{footer}</FormGridFooter>}
            {map(
                ([key, field]) => (
                    <React.Fragment key={key}>
                        <FormGridCell>{typeof key === 'string' ? <label>{key}</label> : key}</FormGridCell>
                        <FormGridCell>{field}</FormGridCell>
                    </React.Fragment>
                ),
                fields,
            )}
        </FormGridContainer>
    );
};
