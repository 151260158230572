import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { filter, map, reduce, values } from 'ramda';
import React, { FC, useContext, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Display } from '../../../../bits/Display';
import { H1Heading } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { NavDivider, NavItem, NavSidebar } from '../../../../patterns/NavSidebar';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { NotificationContainer as BaseNotificationContainer } from '../../../NotificationContainer';
import { pageTemplates } from '../../../PageTemplate/templates';
import { ToolTips } from '../../../Tooltips';

const NotificationContainer = styled(BaseNotificationContainer)`
    &:before {
        width: 14px;
        height: 14px;

        top: ${({ position }) => (position?.top ? `${position?.top}px` : '-5px')};
        right: ${({ position }) => (position?.right ? `${position?.right}px` : '-7px')};

        border: 2px solid #ffffff;
    }
`;

const PageTemplateIcon = styled.img`
    width: 25px;
`;

export const ParticipantInSimulationNav: FC = () => {
    const {
        simulation,
        pages = [],
        contentItems,
        sidebar: { collapsed, toggle: toggleSidebar },
        notifications: { pages: pageNotifications = {} },
    } = useContext(ParticipantStateContext);
    const tooltipParent = useRef<HTMLDivElement>(null);
    const { participantTeam } = useContext(ParticipantStateContext);

    const linksToDisplay = useMemo(() => {
        const items = values(contentItems || {});
        return reduce(
            (acc, page) => {
                if (
                    page.hiddenFromTeamUUIDs &&
                    participantTeam &&
                    page.hiddenFromTeamUUIDs.includes(participantTeam?.uuid)
                ) {
                    return acc;
                }

                const pageRootItems = filter((item) => !item.parentUUID && item.content.pageUUID === page.uuid, items);
                if (!pageRootItems.length) {
                    return acc;
                }

                const dontUseListingPage =
                    !!page.showItemsAsIndividualPages && !!pageTemplates[page.templateUUID].showItemsAsIndividualPages;
                if (dontUseListingPage) {
                    return [...acc, ...map((item) => ({ page, item }), pageRootItems)];
                }
                return [...acc, { page }];
            },
            [] as { page: Page; item?: ContentItem }[],
            pages,
        );
    }, [contentItems, pages]);

    return (
        <NavSidebar ref={tooltipParent} collapsed={collapsed} width={365}>
            <ToolTips parent={tooltipParent.current} />
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithSpacing>
                    <IconButton icon="menu" onClick={toggleSidebar} />
                    {!collapsed && <H1Heading>{simulation.name}</H1Heading>}
                </Display.HorizontalWithSpacing>
                <NavItem to={`/simulation/${simulation.code}`} exact>
                    <Display.HorizontalWithSpacing gap={20} verticalCenter>
                        <Icon icon="overview" /> <span>Overview</span>
                    </Display.HorizontalWithSpacing>
                </NavItem>
                <NavDivider />
                {map((link) => {
                    const { page, item } = link;
                    const template = pageTemplates[page.templateUUID];
                    const linkName =
                        item && template.showItemsAsIndividualPages?.pageTitleField
                            ? `${page.name} - ${item.content.data[template.showItemsAsIndividualPages?.pageTitleField]}`
                            : page.name;
                    return (
                        <NavItem
                            key={item?.uuid || page.uuid}
                            to={
                                !item
                                    ? `/simulation/${simulation.code}/${page.uuid}`
                                    : `/simulation/${simulation.code}/${page.uuid}/${item.uuid}`
                            }
                            {...(collapsed ? { ['data-tooltip']: linkName } : {})}
                        >
                            <Display.HorizontalWithSpacing gap={20} verticalCenter>
                                <NotificationContainer notification={pageNotifications[item?.uuid || page.uuid]}>
                                    <PageTemplateIcon src={template.icon} />
                                </NotificationContainer>{' '}
                                <span>{linkName}</span>
                            </Display.HorizontalWithSpacing>
                        </NavItem>
                    );
                }, linksToDisplay)}
            </Display.VerticalWithSpacing>
        </NavSidebar>
    );
};
