import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../../../../bits/Icon';
import { grey1Colour, destructiveColour } from '../../../../../themes/colours';
import { BriefingDocument } from '../../../../../../../common/types/Simulation';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Display } from '../../../../bits/Display';

const DocumentCard = styled(Display.HorizontalWithMaxSpaceBetween)`
    width: auto;
    border-radius: 4px;
    border: 1px solid ${grey1Colour};
    padding: 15px 15px 15px 15px;
    width: auto;
    a {
        padding: 5px 5px;
        cursor: pointer;
    }
`;
interface DocumentInfoCardProps {
    doc: BriefingDocument;
    onDeleteDocument?: (document: BriefingDocument) => void;
}

export const DocumentInfoCard: FC<DocumentInfoCardProps> = ({ doc, onDeleteDocument }) => {
    const { name, path } = doc;

    return (
        <div>
            <DocumentCard verticalCenter>
                {onDeleteDocument && (
                    <IconButton
                        icon="delete"
                        size={15}
                        fill={destructiveColour}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onDeleteDocument(doc);
                        }}
                    />
                )}
                <Icon icon="scratchPadLive" />
                <a href={path} download>
                    {name}
                </a>
            </DocumentCard>
        </div>
    );
};
