import {
    ALL_TEAM_PARTICIPANTS_IDENTIFIER,
    decodeEmailItem,
    EmailItem,
    EmailItemData,
    NewEmailItem,
    staffAllTeamIdentifierToDisplay,
} from 'polpeo-go-common/types/EmailItem';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { equals, filter, reduce, uniq, values, without } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createEmailItem } from '../../../graphql/emailItem';
import { getPersonaList } from '../../../utils/getPersonaList';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { EmailItemForm } from './EmailItemForm';

const getInitialPersona = (participants: Record<string, ParticipantUser>, parent: EmailItem): string => {
    const personas = filter(
        (recipient) => !participants[recipient] && recipient !== ALL_TEAM_PARTICIPANTS_IDENTIFIER,
        parent.content.recipients,
    );
    if (personas.length === 1) {
        return personas[0];
    }
    // If there's more than 1 persona somehow then return an empty string
    return '';
};

const getInitialValues = (
    participants: Record<string, ParticipantUser>,
    // Really want to be able to pass in an empty object so I've disabled the sad eslint for it!
    // eslint-disable-next-line @typescript-eslint/ban-types
    options: {} | { replyTo: EmailItem } | { forward: EmailItem },
): { parent?: EmailItem; content: EmailItemData; persona?: string } => {
    if ('replyTo' in options) {
        const parent = options.replyTo;

        // If replying to participant email then get persona from recipient list
        // If replying to email sent by staff then assume replying as previous persona
        const personaToReplyAs =
            parent.createdBy.type === 'PARTICIPANT' ? getInitialPersona(participants, parent) : parent.persona;
        const originalSender = (
            parent.createdBy.type === 'PARTICIPANT' ? parent.createdBy.uuid : parent.persona
        ) as string;
        const recipients = without([personaToReplyAs || ''], [...parent.content.recipients, originalSender]);
        return {
            parent,
            content: {
                recipients,
                subject: parent.content.subject,
                message: `<p></p><blockquote>${parent.content.message || ''}</blockquote>`,
            },
            persona: personaToReplyAs,
        };
    }

    if ('forward' in options) {
        const toForward = options.forward;
        return {
            content: {
                recipients: [],
                subject: `FWD: ${toForward.content.subject || ''}`,
                message: `<p></p><blockquote>${toForward.content.message || ''}</blockquote>`,
            },
            persona: undefined,
        };
    }

    return { content: { recipients: [], subject: '', message: '' }, persona: '' };
};

type StaffEmailItemFormModalProps =
    | {
          onCloseModal: () => void;
      }
    | { onCloseModal: () => void; replyTo: EmailItem }
    | { onCloseModal: () => void; forward: EmailItem };

export const StaffEmailItemFormModal: FC<StaffEmailItemFormModalProps> = (props) => {
    const { onCloseModal } = props;
    const { teamUUID } = useParams<{ teamUUID: string }>();
    const {
        simulation,
        simulationContent: { emailItems },
        participants,
        teamAssignments,
        addEmailItems,
        teamsForCurrentStaffUser,
    } = useContext(AdminInSimulationStateContext);

    const { parent, content, persona } = useMemo(() => {
        return getInitialValues(participants, props);
    }, [props]);
    const initialEmailItem = {
        type: 'EMAIL' as const,
        simulationUUID: simulation.uuid,
        teamUUID: teamUUID,
        parentUUID: parent?.uuid,
        content: content,
        persona: persona,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    };
    const [currentEmailItem, setCurrentEmailItem] = useState<NewEmailItem>(initialEmailItem);

    const personas = useMemo(() => {
        const emailItemMapsForTeams = values(emailItems || {});
        const emailItemMap = reduce(
            (acc, itemsForTeam) => ({ ...acc, ...(itemsForTeam as Record<string, EmailItem>) }),
            {} as Record<string, EmailItem>,
            emailItemMapsForTeams,
        );
        const sentAsPersonas = getPersonaList(emailItemMap, [currentEmailItem.teamUUID]);
        const receivedAsPersonas = reduce(
            (acc, email) => [
                ...acc,
                ...filter(
                    (recipient) => recipient !== ALL_TEAM_PARTICIPANTS_IDENTIFIER && !participants[recipient],
                    email.content.recipients,
                ),
            ],
            [] as string[],
            values(emailItemMap),
        );

        return uniq([...sentAsPersonas, ...receivedAsPersonas]);
    }, [emailItems]);

    const emailFromOptions = useMemo(() => {
        const personaOptions = reduce(
            (acc, persona) => ({ ...acc, [persona]: persona }),
            {} as Record<string, string>,
            personas,
        );

        return personaOptions;
    }, [personas]);

    const emailToOptions = useMemo(() => {
        const participantTeamAssignments = teamAssignments;
        const participantOptions = reduce(
            (acc, participant) => {
                const participantTeam = participantTeamAssignments[participant.id]?.teamUUID;
                return participantTeam === teamUUID ? { ...acc, [participant.id]: participant.fullName } : acc;
            },
            {} as Record<string, string>,
            values(participants),
        );

        return {
            ...participantOptions,
            [ALL_TEAM_PARTICIPANTS_IDENTIFIER]: staffAllTeamIdentifierToDisplay(
                ALL_TEAM_PARTICIPANTS_IDENTIFIER,
                teamsForCurrentStaffUser[teamUUID].name,
            ),
        };
    }, [participants, teamAssignments]);

    const [createEmailItemMutation, { data: createEmailItemData, loading: createEmailItemsLoading }] =
        createEmailItem.hook();
    useEffect(() => {
        if (createEmailItemData?.createEmailItem) {
            addEmailItems([decodeEmailItem(createEmailItemData.createEmailItem)]);
            onCloseModal();
        }
    }, [createEmailItemData]);

    const hasUnsavedChanges = !equals(currentEmailItem, initialEmailItem);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={'80%'} header={'New Email'}>
                {createEmailItemsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!createEmailItemsLoading && (
                    <EmailItemForm
                        currentEmail={currentEmailItem}
                        onChange={(newState) => setCurrentEmailItem((oldState) => ({ ...oldState, ...newState }))}
                        onSubmit={() => createEmailItemMutation({ variables: { emailItem: currentEmailItem } })}
                        onCancel={onModalCloseUnsavedPrompt}
                        fromOptions={emailFromOptions}
                        toOptions={emailToOptions}
                        disableSubjectEdit={'replyTo' in props}
                    />
                )}
            </Modal>
        </>
    );
};
