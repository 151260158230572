import { DateTime } from 'luxon';
import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { append, flatten, includes, isNil, map, reduce, reject, sortBy, toPairs, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { sortItemsByParentChildRelation } from '../../../../../utils/sortItemsByParentChildRelation';
import { Display } from '../../../../bits/Display';
import { H2Heading } from '../../../../bits/Headers';
import { Collapsible } from '../../../../patterns/Collapsible';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';
import {
    ManagePrepreparedContentCard,
    ManagePrepreparedEmailCard,
    NewPrepreparedContentButton,
    NewPrepreparedEmailButton,
} from './ManagePrepreparedItemCards';

const ManagePrepreparedContentPageContainer = styled.div`
    padding: 22px;
`;

const ContentColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
`;

interface ManagePrepreparedItemsGroupProps {
    title: string;
    id: string;
    items: Array<PrepreparedContentItem | PrepreparedEmailItem>;
    open?: boolean;
    toggleGroupCollapsed: (id: string) => void;
}
export const ManagePrepreparedItemsGroup: FC<ManagePrepreparedItemsGroupProps> = ({
    title,
    id,
    items,
    toggleGroupCollapsed,
    open,
}) => {
    return (
        <Collapsible
            title={`${title} (${items.length} ${items.length === 1 ? 'item' : 'items'})`}
            open={open}
            toggleGroupCollapsed={() => toggleGroupCollapsed(id)}
        >
            {map(
                (item) =>
                    item.type === 'PAGE_CONTENT' ? (
                        <ManagePrepreparedContentCard key={item.uuid} item={item} />
                    ) : (
                        <ManagePrepreparedEmailCard key={item.uuid} item={item} />
                    ),
                items,
            )}
        </Collapsible>
    );
};

export const ManagePrepreparedContentPage: FC = () => {
    const staffUser = useContext(StaffUserContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { triggers, triggerOrder, triggerPrepreparedContentLinks, prepreparedContents, prepreparedEmails, pages } =
        currentSimulationContent;
    const [expandedGroups, setExpandedGroups] = useState<string[]>(['Unassigned']);

    const orderedTriggers = useMemo(
        () => map((triggerUUID) => triggers[triggerUUID], triggerOrder),
        [triggers, triggerOrder],
    );

    const groupedPrepreparedContent: Record<string, Array<PrepreparedContentItem | PrepreparedEmailItem>> =
        useMemo(() => {
            const itemsWithTriggers = flatten(values(triggerPrepreparedContentLinks));
            const ungroupedItems = sortItemsByParentChildRelation(
                reduce(
                    (acc, item) => (includes(item.uuid, itemsWithTriggers) ? acc : [...acc, item]),
                    [] as Array<PrepreparedContentItem | PrepreparedEmailItem>,
                    [
                        ...values(prepreparedContents as Record<string, PrepreparedContentItem>),
                        ...values(prepreparedEmails as Record<string, PrepreparedEmailItem>),
                    ],
                ),
            );
            const groupedItemMap = reduce(
                (acc, [triggerUUID, itemUUIDs]) => ({
                    ...acc,
                    [triggerUUID]: sortBy(
                        (item) => ('pageUUID' in item.content ? pages[item.content.pageUUID].name : '0email'),
                        sortItemsByParentChildRelation(
                            reject(
                                isNil,
                                map((itemUUID) => {
                                    const item = prepreparedContents[itemUUID] || prepreparedEmails[itemUUID];
                                    return item;
                                }, itemUUIDs),
                            ) as Array<PrepreparedContentItem | PrepreparedEmailItem>,
                        ),
                    ),
                }),
                {},
                toPairs(triggerPrepreparedContentLinks),
            );

            return { ...groupedItemMap, '': ungroupedItems };
        }, [prepreparedContents, prepreparedEmails, triggerPrepreparedContentLinks]);

    const toggleGroupCollapsed = (id: string) => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups(append(id, expandedGroups));
        }
    };

    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);

    return (
        <ManagePrepreparedContentPageContainer>
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithSpacing verticalCenter>
                    <H2Heading>Preprepared Content</H2Heading>
                    {userCanWriteSimulationAndContent && (
                        <>
                            <NewPrepreparedContentButton />
                            <NewPrepreparedEmailButton />
                        </>
                    )}
                </Display.HorizontalWithSpacing>
                <ContentColumns>
                    <Display.VerticalWithSpacing gap={10}>
                        {map((trigger) => {
                            if (!trigger) {
                                return null;
                            }
                            const items = groupedPrepreparedContent[trigger.uuid] || [];
                            return (
                                <ManagePrepreparedItemsGroup
                                    key={trigger.uuid}
                                    title={`${trigger.title} ${
                                        trigger.releasedAt
                                            ? `- Released at: ${DateTime.fromJSDate(trigger.releasedAt).toFormat(
                                                  'HH:mm',
                                              )}`
                                            : ''
                                    }`}
                                    id={trigger.uuid}
                                    items={items}
                                    toggleGroupCollapsed={toggleGroupCollapsed}
                                    open={includes(trigger.uuid, expandedGroups)}
                                />
                            );
                        }, orderedTriggers)}
                    </Display.VerticalWithSpacing>
                    <Display.VerticalWithSpacing gap={10}>
                        <ManagePrepreparedItemsGroup
                            title={'Unassigned'}
                            id={'Unassigned'}
                            items={groupedPrepreparedContent[''] || []}
                            toggleGroupCollapsed={toggleGroupCollapsed}
                            open={includes('Unassigned', expandedGroups)}
                        />
                    </Display.VerticalWithSpacing>
                </ContentColumns>
            </Display.VerticalWithSpacing>
        </ManagePrepreparedContentPageContainer>
    );
};
