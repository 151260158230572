import { Team } from 'polpeo-go-common/types/Team';
import { equals, reduce } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { createTeam } from '../../../../../graphql/teams/createTeam';
import { editTeam } from '../../../../../graphql/teams/editTeam';
import { filterRoleplayers } from '../../../../../utils/filterStaff';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { TextInput } from '../../../../bits/FormFields';
import { Spinner } from '../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../../patterns/Modal';
import { MultiSearchSelection } from '../../../../patterns/MultiSearchSelection';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { FormGrid } from '../../../FormGrid';

interface CreateTeamModalProps {
    team?: undefined;
    onModalClose: () => void;
}

interface EditTeamModalProps {
    team: Team;
    onModalClose: () => void;
}

type ManageTeamModalProps = CreateTeamModalProps | EditTeamModalProps;
export const ManageTeamModal: FC<ManageTeamModalProps> = ({ team, onModalClose }: ManageTeamModalProps) => {
    const { setTeam, staffUsers } = useContext(AdminStateContext);
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [createTeamMutation, { data: createTeamData, loading: createTeamLoading }] = createTeam.hook();
    const [editTeamMutation, { data: editTeamData, loading: editTeamLoading }] = editTeam.hook();

    const [name, setName] = useState<string>(team?.name || '');
    const [roleplayerUUIDs, setRolePlayerUUIDs] = useState<string[]>(team?.roleplayerUUIDs || []);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const roleplayerStaffUsers = useMemo(() => filterRoleplayers(staffUsers), [staffUsers]);
    const multiSearchSelectionOptions = useMemo(
        () => reduce((acc, roleplayer) => ({ ...acc, [roleplayer.uuid]: roleplayer.name }), {}, roleplayerStaffUsers),
        [roleplayerStaffUsers],
    );

    useEffect(() => {
        const teamToSet = createTeamData?.createTeam || editTeamData?.editTeam;
        if (teamToSet) {
            setTeam({ ...teamToSet, pageStats: JSON.parse(teamToSet.pageStats) });
            onModalClose();
        }
    }, [createTeamData, editTeamData]);

    const initialTeam = team || { name: '', roleplayerUUIDs: [] };
    const currentTeam = { ...(team || {}), name, roleplayerUUIDs };
    const hasUnsavedChanges =
        !equals(currentTeam, initialTeam) && !(createTeamData?.createTeam || editTeamData?.editTeam);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onModalClose,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal header={team ? 'Edit Team' : 'New Team'} onModalClose={onModalCloseUnsavedPrompt}>
                {(createTeamLoading || editTeamLoading) && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!createTeamLoading && !editTeamLoading && (
                    <FormGrid
                        fields={[
                            [
                                'Team Name',
                                <>
                                    <TextInput
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        validationFailed={!!errors.name}
                                        disabled={!!currentSimulation.startedAt}
                                    />
                                    {errors.name && <span>{errors.name}</span>}
                                </>,
                            ],
                            [
                                'Roleplayers',
                                <MultiSearchSelection
                                    key="Roleplayers"
                                    options={multiSearchSelectionOptions}
                                    value={roleplayerUUIDs}
                                    onChange={setRolePlayerUUIDs}
                                    disableRemoveItem={!!currentSimulation?.startedAt}
                                />,
                            ],
                        ]}
                        footer={
                            <Display.HorizontalWithSpacing horizontalAlign="end">
                                <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    onClick={async () => {
                                        if (!name) {
                                            setErrors({ name: 'A team must have a name' });
                                            return;
                                        }
                                        const simulationUUID = currentSimulation.uuid;
                                        if (!team) {
                                            createTeamMutation({
                                                variables: {
                                                    team: {
                                                        simulationUUID,
                                                        name,
                                                        roleplayerUUIDs,
                                                    },
                                                },
                                            });
                                        } else if (team) {
                                            editTeamMutation({
                                                variables: {
                                                    team: {
                                                        ...team,
                                                        name,
                                                        roleplayerUUIDs,
                                                        pageStats: JSON.stringify(team.pageStats),
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                >
                                    {team ? 'Save' : 'Create'}
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        }
                    />
                )}
            </Modal>
        </>
    );
};
