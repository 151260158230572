import React, { FC } from 'react';
import styled from 'styled-components/macro';
import logo from './Polpeo_logo.png';
import whiteLogo from './Polpeo_logo_inverse.png';

interface LogoContainerProps {
    width?: number;
}
const LogoContainer = styled.img<LogoContainerProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const Logo: FC<LogoContainerProps> = ({ width }: LogoContainerProps) => (
    <LogoContainer width={width} src={logo} />
);

export const WhiteLogo: FC<LogoContainerProps> = ({ width }: LogoContainerProps) => (
    <LogoContainer width={width} src={whiteLogo} />
);
