import styled, { css } from 'styled-components/macro';
import { BaseDiv, baseThemingSnippet } from '../utils';
import { Link } from 'react-router-dom';

export const basePageDressingSnippet = css`
    display: flex;
    justify-content: ${({ theme }) => theme.layout || 'normal'};

    img {
        width: auto;
    }
`;

const BasePageDressing = styled(BaseDiv)`
    ${basePageDressingSnippet}
`;
interface BaseSidebarProps {
    top: number;
}

export const Header = styled(Link).attrs(({ theme }) => ({ theme: theme || {} }))`
    grid-area: header;
    flex-direction: row;

    ${({ theme: { sticky } }) => (sticky ? `position: sticky; top: 0px` : '')};
    z-index: 1;

    max-height: 750px;
    img {
        object-fit: scale-down;
    }

    ${baseThemingSnippet}
    ${basePageDressingSnippet}
`;

export const LeftSidebar = styled(BasePageDressing).attrs(({ theme }) => ({ theme: theme || {} }))<BaseSidebarProps>`
    grid-area: leftSidebar;
    flex-direction: column;
    align-items: flex-end;
    ${({ top, theme: { sticky } }) => (sticky ? `img {position: sticky; top: ${top ? `${top}px` : 0}};} ` : '')};
`;

export const RightSidebar = styled(BasePageDressing).attrs(({ theme }) => ({ theme: theme || {} }))<BaseSidebarProps>`
    grid-area: rightSidebar;
    flex-direction: column;
    align-items: flex-start;
    ${({ top, theme: { sticky } }) => (sticky ? `img {position: sticky; top: ${top ? `${top}px` : 0};} ` : '')};
`;

export const Footer = styled(BasePageDressing).attrs(({ theme }) => ({ theme: theme || {} }))`
    grid-area: footer;
    flex-direction: row;
    ${({ theme: { sticky } }) => (sticky ? 'position: sticky; bottom: 0;' : '')};
`;

export const PageStats = styled(BaseDiv).attrs(({ theme }) => ({ theme: theme || {} }))``;
