import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import React, { FC, useState } from 'react';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Icon } from '../../../bits/Icon';
import { DeleteScratchPadDocumentButton } from './DeleteScratchPadDocumentButton';
import { DocumentCard } from './components/DocumentCard';
import { DocumentLabel } from './components/DocumentLabel';
import { DownloadIconContainer } from './components/DownloadIconContainer';
import { RichTextDocumentFormModal } from './RichTextDocumentFormModal';
import { RichTextDocumentReadOnlyModal } from './RichTextDocumentReadOnlyModal';

interface ScratchPadDocumentItemProps {
    document: ScratchPadDocument;
    title: 'Strategy' | 'Statements' | 'Resources';
    disableManageDocuments?: boolean;
}

export const ScratchPadDocumentItem: FC<ScratchPadDocumentItemProps> = ({
    document,
    title,
    disableManageDocuments,
}) => {
    const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);
    const [openEditDocumentModal, setOpenEditDocumentModal] = useState(false);
    const isRichTextDocument = document.notes || document.notes === '';

    return (
        <>
            <DocumentCard key={document.uuid}>
                {isRichTextDocument && (
                    <>
                        <Icon icon="scratchPadLive" size={22} />
                        <DocumentLabel onClick={() => setOpenViewDocumentModal(true)}>{document.name}</DocumentLabel>
                        <IconButton onClick={() => setOpenViewDocumentModal(true)} icon="view" size={16} />
                        {!disableManageDocuments && (
                            <IconButton onClick={() => setOpenEditDocumentModal(true)} icon="edit" size={16} />
                        )}
                    </>
                )}
                {!isRichTextDocument && (
                    <>
                        <Icon icon="scratchPadDoc" size={22} />
                        <DocumentLabel as="a" href={document.path} download>
                            {document.name}
                        </DocumentLabel>
                        <DownloadIconContainer href={document.path} download>
                            <Icon icon="download" size={20} />
                        </DownloadIconContainer>
                    </>
                )}
                {!disableManageDocuments && <DeleteScratchPadDocumentButton document={document} />}
            </DocumentCard>
            {openEditDocumentModal && (
                <RichTextDocumentFormModal
                    onCloseModal={() => setOpenEditDocumentModal(false)}
                    category={title}
                    documentToEdit={document}
                />
            )}
            {openViewDocumentModal && (
                <RichTextDocumentReadOnlyModal
                    document={document}
                    onCloseModal={() => setOpenViewDocumentModal(false)}
                />
            )}
        </>
    );
};
