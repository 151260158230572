import gql from 'graphql-tag';
import wrapSubscription from '../wrapSubscription';

interface OnEditTeamAssignmentsParams {
    simulationUUID: string;
}

interface OnEditTeamAssignmentsData {
    onEditTeamAssignments: {
        simulationUUID: string;
        assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
    };
}

const subscription = gql`
    subscription onEditTeamAssignments($simulationUUID: String!) {
        onEditTeamAssignments(simulationUUID: $simulationUUID) {
            simulationUUID
            assignments
        }
    }
`;

export const onEditTeamAssignments =
    wrapSubscription<OnEditTeamAssignmentsParams, OnEditTeamAssignmentsData>(subscription);
