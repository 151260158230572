import * as t from 'io-ts';
import { createContext } from 'react';

export const Config = t.type({
    graphQlEndpoint: t.string,
    userPoolId: t.string,
    userPoolClientId: t.string,
    identityPoolId: t.string,
});
export type Config = t.TypeOf<typeof Config>;

const defaultConfig: Config = {
    graphQlEndpoint: 'If you are seeing this then the config never got loaded',
    userPoolId: 'If you are seeing this then the config never got loaded',
    userPoolClientId: 'If you are seeing this then the config never got loaded',
    identityPoolId: 'If you are seeing this then the config never got loaded',
};
export const ConfigContext = createContext<Config>(defaultConfig);
