import gql from 'graphql-tag';
import { NewScratchPadDocument, ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface CreateScratchPadDocumentParams {
    scratchPadDocument: NewScratchPadDocument;
}

interface CreateScratchPadDocumentData {
    createScratchPadDocument: ScratchPadDocument;
}

const mutation = gql`
    mutation createScratchPadDocument($scratchPadDocument: NewScratchPadDocumentInput!) {
        createScratchPadDocument(scratchPadDocument: $scratchPadDocument) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            currentEditor
        }
    }
`;

export const createScratchPadDocument =
    wrapMutation<CreateScratchPadDocumentParams, CreateScratchPadDocumentData>(mutation);
