import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { find, head, sortBy, tail } from 'ramda';

export function sortItemsByParentChildRelation<T extends PrepreparedContentItem | PrepreparedEmailItem>(
    items: T[],
): T[] {
    let sortMap: Record<string, string> = {};
    let rootItemCounter = 0;

    let sortQueue = [...items];
    while (sortQueue.length) {
        const item = head(sortQueue) as T;

        // If root item
        if (!item.parentUUID) {
            rootItemCounter += 1;
            sortMap = { ...sortMap, [item.uuid]: rootItemCounter.toString() };
            sortQueue = tail(sortQueue);
            continue;
        }

        // If parent item does not exist in this current list of items then add the missing parent
        // to the sortMap
        const parentExistsInItemsList = find((x) => x.uuid === item.parentUUID, items);
        if (!parentExistsInItemsList) {
            rootItemCounter += 1;
            sortMap = { ...sortMap, [item.parentUUID]: rootItemCounter.toString() };
        }

        const parentSortId = sortMap[item.parentUUID];
        if (parentSortId) {
            sortMap = { ...sortMap, [item.uuid]: `${parentSortId}a` };
            sortQueue = tail(sortQueue);
        } else {
            // if parent exists in item list but has not yet been processed then put child at end of queue
            // to process again later
            sortQueue = [...tail(sortQueue), item];
        }
    }

    return sortBy((item) => sortMap[item.uuid] || '', items);
}
