import React, { FC, useContext } from 'react';
import { SimulationDocumentsCard } from '../SimulationDocumentsCard';
import { SimulationOverviewCard } from '../SimulationOverviewCard';
import styled from 'styled-components/macro';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';

const SimulationOverviewPageContainer = styled.div`
    display: flex;
    padding: 22px;

    & > *:first-child {
        flex: 1 1 auto;
        margin-right: 22px;
        width: 66%;
    }

    & > *:last-child {
        width: 33%;
        min-width: 300px;
        max-width: 450px;
        flex: 0 1 auto;
    }
`;

export const SimulationOverviewPage: FC = () => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);

    if (!currentSimulation) {
        return <span>404</span>;
    }

    return (
        <SimulationOverviewPageContainer>
            <SimulationDocumentsCard simulation={currentSimulation} />
            <SimulationOverviewCard />
        </SimulationOverviewPageContainer>
    );
};
