import { Trigger } from 'polpeo-go-common/types/Trigger';
import { always, cond, equals, map } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editTrigger } from '../../../../../../graphql/triggers';
import { PrimaryButton } from '../../../../../bits/Buttons';
import { IconButton } from '../../../../../bits/Buttons/IconButton';
import { Display } from '../../../../../bits/Display';
import { H2Heading } from '../../../../../bits/Headers';
import { Spinner } from '../../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../../hooks/useUnsavedWorkPrompt';
import { FullPageError } from '../../../../../patterns/FullPageError';
import { AdminInSimulationStateContext } from '../../../../../WithAdminInSimulationState';
import { AdminManageSimulationContext } from '../../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { SlimTabs } from '../../../../SlimTabs';
import { EditTriggerContent } from './EditTriggerContent';
import { EditTriggerMisc } from './EditTriggerMisc';
import { EditTriggerSetup } from './EditTriggerSetup';

const sections = ['Setup', 'Content', 'Overview and Page Changes'] as const;

interface EditTriggerFormProps {
    trigger: Trigger;
    disableInputs?: boolean;
    onBackButton: () => void;
    onTriggerSaved?: (trigger: Trigger) => void;
}
export const EditTriggerForm: FC<EditTriggerFormProps> = ({ trigger, disableInputs, onBackButton, onTriggerSaved }) => {
    const adminManageState = useContext(AdminManageSimulationContext);
    const adminInSimulationState = useContext(AdminInSimulationStateContext);

    const currentSimulation = adminManageState.currentSimulation || adminInSimulationState.simulation;
    const currentSimulationContent =
        adminManageState.currentSimulationContent || adminInSimulationState.simulationContent;

    const [currentSection, setCurrentSection] = useState('Setup');
    const [currentTrigger, setCurrentTrigger] = useState(trigger);

    const [editTriggerMutation, { data: editTriggerData, loading: editTriggerLoading }] = editTrigger.hook();

    useEffect(() => {
        if (editTriggerData) {
            const trigger = editTriggerData.editTrigger;
            if (onTriggerSaved) {
                onTriggerSaved({ ...trigger, pageDressingChanges: JSON.parse(trigger.pageDressingChanges) });
            }
        }
    }, [editTriggerData]);

    if (!currentTrigger) {
        return <FullPageError errorCode="404" message="Trigger could not be found" />;
    }

    const hasTitle = currentTrigger.title;

    const SaveButton = () => (
        <PrimaryButton
            onClick={() =>
                editTriggerMutation({
                    variables: {
                        trigger: {
                            ...currentTrigger,
                            pageDressingChanges: JSON.stringify(currentTrigger.pageDressingChanges),
                        },
                    },
                })
            }
            disabled={!hasTitle || editTriggerLoading || !!currentTrigger.releasedAt}
        >
            Save
        </PrimaryButton>
    );

    const hasUnsavedChanges = !equals(currentTrigger, trigger) && !editTriggerData?.editTrigger;
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    return (
        <>
            <UnsavedPrompt />
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithMaxSpaceBetween>
                    <Display.HorizontalWithSpacing verticalCenter gap={4}>
                        <IconButton icon="back" onClick={onBackButton} disabled={editTriggerLoading} />
                        <H2Heading>{currentTrigger.title}</H2Heading>
                    </Display.HorizontalWithSpacing>
                    {onTriggerSaved && !editTriggerLoading && !disableInputs && <SaveButton />}
                </Display.HorizontalWithMaxSpaceBetween>
                {currentTrigger.releasedAt && `This trigger was released at: ${currentTrigger.releasedAt}`}

                {editTriggerLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editTriggerLoading && (
                    <>
                        <SlimTabs
                            tabs={map((tab) => [tab, undefined], sections)}
                            activeTab={currentSection}
                            onChangeTab={(section) => setCurrentSection(section)}
                        />
                        {cond([
                            [
                                (section) => section === 'Setup',
                                always(
                                    <EditTriggerSetup
                                        trigger={currentTrigger}
                                        simulation={currentSimulation}
                                        simulationContent={currentSimulationContent}
                                        disableInputs={!!disableInputs}
                                        onChange={setCurrentTrigger}
                                    />,
                                ),
                            ],
                            [
                                (section) => section === 'Content',
                                always(
                                    <EditTriggerContent
                                        trigger={currentTrigger}
                                        simulationContent={currentSimulationContent}
                                    />,
                                ),
                            ],
                            [
                                (section) => section === 'Overview and Page Changes',
                                always(
                                    <EditTriggerMisc
                                        trigger={currentTrigger}
                                        onChange={setCurrentTrigger}
                                        disableInputs={!!disableInputs}
                                    />,
                                ),
                            ],
                        ])(currentSection)}
                    </>
                )}
                <Display.RightAlign>
                    {onTriggerSaved && !editTriggerLoading && !disableInputs && <SaveButton />}
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </>
    );
};
