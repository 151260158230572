import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { ALL_TEAM_PARTICIPANTS_IDENTIFIER, EmailItem } from 'polpeo-go-common/types/EmailItem';
import { ParticipantTeamAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { Team } from 'polpeo-go-common/types/Team';
import { cond, forEach, join, map, reduce, sortBy, toPairs, values } from 'ramda';
import XLSX from 'xlsx';
import { SimulationContent } from '../../../../WithAdminState/adminState';
import { formatDateForExcel } from '../../../../../utils/formatDateForExcel';
import { htmlToText, HtmlToTextOptions } from 'html-to-text';

const htmlToTextOptions: HtmlToTextOptions = {
    formatters: {
        customLinkFormatter: function (elem, walk, builder, formatOptions) {
            const anchorFormatter = builder.options.formatters['anchor'];
            anchorFormatter(elem, walk, builder, formatOptions);
        },
        videoFormatter: function (elem, walk, builder, formatOptions) {
            const imageFormatter = builder.options.formatters['image'];
            imageFormatter(elem, walk, builder, formatOptions);
        },
    },
    tags: {
        'custom-link': {
            format: 'customLinkFormatter',
            options: { leadingLineBreaks: 1, trailingLineBreaks: 1 },
        },
        'embedded-video': {
            format: 'videoFormatter',
            options: { leadingLineBreaks: 1, trailingLineBreaks: 1 },
        },
    },
    preserveNewlines: true,
};

export const makeSimulationWorkbook = (
    simulation: Simulation,
    users: { staffUsers: Record<string, StaffUser | undefined>; participants: Record<string, ParticipantUser> },
    teams: Team[],
    teamAssignments: ParticipantTeamAssignments,
    simulationContent: SimulationContent,
): XLSX.WorkBook => {
    const { staffUsers, participants } = users;

    const workbook = XLSX.utils.book_new();

    // Simulation Sheet
    const simulationSheet = XLSX.utils.aoa_to_sheet([
        ['name', simulation.name],
        ['client name', simulation.clientName],
        ['lobby opened at', simulation.lobbyOpenedAt ? formatDateForExcel(simulation.lobbyOpenedAt) : ''],
        ['started at', simulation.startedAt ? formatDateForExcel(simulation.startedAt) : ''],
        ['ended at', simulation.completedAt ? formatDateForExcel(simulation.completedAt) : ''],
    ]);
    XLSX.utils.book_append_sheet(workbook, simulationSheet, 'Simulation');

    // Teams Sheet
    const administratorRows = map<string, string[]>((uuid) => {
        const staffUser = staffUsers[uuid];
        return staffUser ? [staffUser.role, staffUser.name, staffUser.email] : ['deleted user'];
    }, simulation.administratorUUIDs);
    const teamsRows = reduce(
        (rows, team) => {
            const teamStaffRows = map((uuid) => {
                const staffUser = staffUsers[uuid];
                return staffUser ? [staffUser.role, staffUser.name, staffUser.email] : ['deleted user'];
            }, team.roleplayerUUIDs);

            const teamParticipantRows = reduce(
                (acc, [participantID, assignment]) => {
                    if (assignment.teamUUID !== team.uuid) {
                        return acc;
                    }
                    const participant = participants[participantID];
                    if (!participant) {
                        return acc;
                    }
                    return [
                        ...acc,
                        [
                            'PARTICIPANT',
                            participant.fullName,
                            participant.email,
                            participant.marketingConsent ? 'YES MARKETING' : 'NO MARKETING',
                        ],
                    ];
                },
                [] as string[][],
                toPairs(teamAssignments),
            );
            return [...rows, [team.name], ...teamStaffRows, ...teamParticipantRows, ['']];
        },
        [] as string[][],
        teams,
    );
    const teamsSheet = XLSX.utils.aoa_to_sheet([['administrators'], ...administratorRows, [''], ...teamsRows]);
    XLSX.utils.book_append_sheet(workbook, teamsSheet, 'Teams');
    const pages = simulationContent.pages;
    const triggersMap = simulationContent.triggers;
    // Team Contents Sheet
    forEach((team) => {
        const contentItemsMap = simulationContent.contentItems[team.uuid];
        const contentItemsList = sortBy(
            (item) => item.createdAt.toISOString(),
            values(contentItemsMap) as ContentItem[],
        );
        const contentSheet = XLSX.utils.json_to_sheet(
            map((item) => {
                const pageUUID = item.content.pageUUID;
                const itemPage = pages[pageUUID];
                if (!itemPage) {
                    throw new Error(`No page for this content item: ${item}`);
                }

                const parsedContent = map<{ [key: string]: string }, { [key: string]: string }>(
                    (content) => htmlToText(content, htmlToTextOptions),
                    item.content.data,
                );

                return {
                    uuid: item.uuid,
                    parent: item.parentUUID || '',
                    createdAt: formatDateForExcel(item.createdAt),
                    createdBy: cond([
                        [
                            (creatorType) => creatorType === 'TRIGGER',
                            () => `TRIGGER - ${triggersMap[item.createdBy.uuid].title || ''}`,
                        ],
                        [
                            (creatorType) => creatorType === 'STAFF',
                            () => `STAFF - ${staffUsers[item.createdBy.uuid]?.email || ''}`,
                        ],
                        [
                            (creatorType) => creatorType === 'PARTICIPANT',
                            () => `PARTICIPANT - ${participants[item.createdBy.uuid]?.email || ''}`,
                        ],
                    ])(item.createdBy.type),
                    persona: item.persona || '',
                    ...(item.deleted
                        ? {
                              deletedAt: formatDateForExcel(item.deleted.at),
                              deletedBy: cond([
                                  [
                                      (creatorType) => creatorType === 'TRIGGER',
                                      () => `TRIGGER - ${triggersMap[item.createdBy.uuid].title || ''}`,
                                  ],
                                  [
                                      (creatorType) => creatorType === 'STAFF',
                                      () => `STAFF - ${staffUsers[item.createdBy.uuid]?.email || ''}`,
                                  ],
                                  [
                                      (creatorType) => creatorType === 'PARTICIPANT',
                                      () => `PARTICIPANT - ${participants[item.createdBy.uuid]?.email || ''}`,
                                  ],
                              ])(item.deleted.by.type),
                          }
                        : { deletedAt: '', deletedBy: '' }),
                    pageName: itemPage.name,
                    ...parsedContent,
                };
            }, contentItemsList),
        );
        XLSX.utils.book_append_sheet(workbook, contentSheet, `${team.name} - Content`);

        const emailItemsMap = simulationContent.emailItems[team.uuid];
        const emailItemsList = sortBy((item) => item.createdAt.toISOString(), values(emailItemsMap) as EmailItem[]);

        const emailSheet = XLSX.utils.json_to_sheet(
            map((item) => {
                return {
                    uuid: item.uuid,
                    parent: item.parentUUID || '',
                    createdAt: formatDateForExcel(item.createdAt),
                    createdBy: cond([
                        [
                            (creatorType) => creatorType === 'TRIGGER',
                            () => `TRIGGER - ${triggersMap[item.createdBy.uuid].title || ''}`,
                        ],
                        [
                            (creatorType) => creatorType === 'STAFF',
                            () => `STAFF - ${staffUsers[item.createdBy.uuid]?.email || ''}`,
                        ],
                        [
                            (creatorType) => creatorType === 'PARTICIPANT',
                            () => `PARTICIPANT - ${participants[item.createdBy.uuid]?.email || ''}`,
                        ],
                    ])(item.createdBy.type),
                    persona: item.persona || '',
                    recipients: join(
                        ', ',
                        map((recipient) => {
                            if (recipient === ALL_TEAM_PARTICIPANTS_IDENTIFIER) return 'Everyone in the team';
                            return staffUsers[recipient]?.email || participants[recipient]?.email || recipient;
                        }, item.content.recipients),
                    ),
                    subject: item.content.subject,
                    message: htmlToText(item.content.message, htmlToTextOptions),
                };
            }, emailItemsList),
        );
        XLSX.utils.book_append_sheet(workbook, emailSheet, `${team.name} - Email`);
    }, teams);

    return workbook;
};
