import * as t from 'io-ts';
import optionalToUndefined from '../../t/optionalToUndefined';
import { ContentItemField } from './ContentItemField';
import { BaseThemingOptions } from './BaseThemingOptions';
import { ContentItemThemingOptions } from './ContentItemThemingOptions';
import { InteractionSettings } from './InteractionSettings';
import { PageThemingOptions } from './PageThemingOptions';

export enum ContentItemsListingPageLayoutType {
    LIST = 'LIST',
    GRID = 'GRID',
    CHAT = 'CHAT',
}
export const tContentItemsListingPageLayoutType = t.keyof(ContentItemsListingPageLayoutType);

export enum ContentItemsDetailsPageLayoutType {
    LIST = 'LIST',
    CHAT = 'CHAT',
}
export const tContentItemsDetailsPageLayoutType = t.keyof(ContentItemsDetailsPageLayoutType);

const PageTheme = t.type({
    page: PageThemingOptions,
    rootItemsContainer: t.partial({
        ...BaseThemingOptions.props,
        verticalGap: optionalToUndefined(t.number),
        horizontalGap: optionalToUndefined(t.number),
    }),
    rootItem: ContentItemThemingOptions,
    childrenItemsContainer: t.partial({
        ...BaseThemingOptions.props,
        verticalGap: optionalToUndefined(t.number),
        horizontalGap: optionalToUndefined(t.number),
    }),
    childItemContainer: BaseThemingOptions,
    childItem: ContentItemThemingOptions,
});
export type PageTheme = t.TypeOf<typeof PageTheme>;

export const ContentSortOptions = t.union([
    t.literal('ItemCreatedAscending'),
    t.literal('ItemCreatedDescending'),
    t.literal('DescendentCreatedAscending'),
    t.literal('DescendentCreatedDescending'),
]);
export type ContentSortOptions = t.TypeOf<typeof ContentSortOptions>;

const ContentInteractionSettings = t.type({
    positive: InteractionSettings,
    negative: InteractionSettings,
    shares: InteractionSettings,
});
export type ContentInteractionSettings = t.TypeOf<typeof ContentInteractionSettings>;

export const PageTemplate = t.intersection([
    t.type({
        uuid: t.string,
        name: t.string,
        category: t.string,
        icon: t.string,
        rootItemOptions: t.type({
            name: t.string,
            fields: t.array(ContentItemField),
            // strings in this array are always required
            // arrays of stings in this array require exactly
            // one fields to have data in
            requiredFields: t.array(t.union([t.string, t.array(t.string)])),
            interactions: ContentInteractionSettings,
        }),
        childItemOptions: t.type({
            name: t.string,
            pluralName: t.string,
            fields: t.array(ContentItemField),
            // strings in this array are always required
            // arrays of stings in this array require exactly
            // one fields to have data in
            requiredFields: t.array(t.union([t.string, t.array(t.string)])),
            interactions: ContentInteractionSettings,
        }),
        childrenSettings: t.union([
            t.type({
                enabled: t.literal(false),
            }),
            t.intersection([
                t.type({
                    enabled: t.literal(true),
                }),
                t.partial({
                    maxDepth: t.union([t.number, t.undefined]),
                }),
            ]),
        ]),
        listingPage: t.type({
            layout: t.union([
                t.type({
                    type: t.literal(tContentItemsListingPageLayoutType.keys.LIST),
                }),
                t.type({
                    type: t.literal(tContentItemsListingPageLayoutType.keys.GRID),
                    columnWidth: t.number,
                }),
                t.type({
                    type: t.literal(tContentItemsListingPageLayoutType.keys.GRID),
                    columns: t.number,
                }),
                t.type({
                    type: t.literal(tContentItemsListingPageLayoutType.keys.CHAT),
                }),
            ]),
            sortBy: ContentSortOptions,
            rootContentFieldOrder: t.array(t.string),
            childContentFieldOrder: t.array(t.string),
            detailsLinkFields: t.array(t.string),
            childrenSettings: t.union([
                t.intersection([
                    t.type({ location: t.union([t.literal('inline'), t.literal('append')]) }),
                    t.partial({ max: t.number }),
                ]),
                t.partial({
                    location: optionalToUndefined(t.undefined),
                }),
            ]),
            theme: PageTheme,
        }),
        detailsPage: t.type({
            layout: t.union([
                t.type({
                    type: t.literal(tContentItemsDetailsPageLayoutType.keys.LIST),
                }),
                t.type({
                    type: t.literal(tContentItemsDetailsPageLayoutType.keys.CHAT),
                }),
            ]),
            sortBy: ContentSortOptions,
            rootContentFieldOrder: t.array(t.string),
            childContentFieldOrder: t.array(t.string),
            childrenSettings: t.type({
                location: t.union([t.literal('inline'), t.literal('append')]),
            }),
            theme: PageTheme,
        }),
    }),
    t.partial({
        showItemsAsIndividualPages: t.type({
            pageTitleField: t.string,
        }),
    }),
]);
export type PageTemplate = t.TypeOf<typeof PageTemplate>;
