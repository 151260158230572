import gql from 'graphql-tag';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface EditPrepreparedEmailParams {
    prepreparedEmail: GraphQlPrepreparedEmailItem;
}

interface EditPrepreparedEmailData {
    editPrepreparedEmail: GraphQlPrepreparedEmailItem;
}

const mutation = gql`
    mutation editPrepreparedEmail($prepreparedEmail: PrepreparedEmailInput!) {
        editPrepreparedEmail(prepreparedEmail: $prepreparedEmail) {
            type
            uuid
            simulationUUID
            parentUUID
            persona
            content {
                recipients
                subject
                message
            }
            released {
                at
                items
            }
        }
    }
`;

export const editPrepreparedEmail = wrapMutation<EditPrepreparedEmailParams, EditPrepreparedEmailData>(mutation);
