import { useApolloClient } from '@apollo/client';
import { BasicSimulation, Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useState } from 'react';
import { Client } from '../../graphql/client';
import { getSignedPutUrlForContentUpload } from '../../graphql/prepreparedContent';
import { decodeBasicSimulation } from '../../utils/decodeBasicSimulation';
import { SecondaryButton } from '../bits/Buttons';
import { Spinner } from '../bits/Spinner';
import { DropZone } from '../patterns/DropZone';

export const uploadContentItemImage = async (
    client: Client,
    simulation: Simulation | BasicSimulation,
    file: File,
): Promise<string> => {
    const putUrl = await getSignedPutUrlForContentUpload.promise(client, {
        simulation: decodeBasicSimulation(simulation),
        file: {
            name: file.name,
            type: file.type,
            size: undefined,
        },
    });
    if (!putUrl.data) {
        throw new Error('Could not create signed put url for image');
    }
    const putCVRequestOptions: RequestInit = {
        method: 'PUT',
        headers: { 'content-type': file.type },
        body: file,
    };
    await fetch(putUrl.data.getSignedPutUrlForContentUpload.signedPutUrl, putCVRequestOptions);
    return putUrl.data.getSignedPutUrlForContentUpload.path;
};

interface ContentItemImageDropZoneProps {
    simulation: Simulation | BasicSimulation;
    onUploaded: (path: string) => void;
}
export const ContentItemImageDropZone: FC<ContentItemImageDropZoneProps> = ({ simulation, onUploaded }) => {
    const client = useApolloClient();
    const [uploading, setUploading] = useState(false);
    return (
        <>
            {uploading && <Spinner />}
            {!uploading && (
                <DropZone
                    accept="image/*"
                    onAcceptedFiles={async (files: File[]) => {
                        if (files.length === 1) {
                            setUploading(true);
                            const imageFile = files[0];
                            const imagePath = await uploadContentItemImage(client, simulation, imageFile);
                            onUploaded(imagePath);
                            setUploading(false);
                        }
                    }}
                >
                    <p>Drag and drop your image here or click to select a image</p>
                </DropZone>
            )}
        </>
    );
};

interface ContentItemImageInputProps {
    simulation: Simulation | BasicSimulation;
    value?: string;
    onChange: (imagePath: string | undefined) => void;
    disabled?: boolean;
}
export const ContentItemImageInput: FC<ContentItemImageInputProps> = ({ simulation, value, onChange, disabled }) => {
    return (
        <>
            {!disabled && value && <SecondaryButton onClick={() => onChange(undefined)}>Remove</SecondaryButton>}
            {value && <img src={value} />}
            {disabled && !value && 'No image'}
            {!disabled && <ContentItemImageDropZone simulation={simulation} onUploaded={onChange} />}
        </>
    );
};
