import { mergeAttributes, Node, NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';
import { includes } from 'ramda';
import React, { FC } from 'react';
import { getVimeoVideoId, getYoutubeVideoId } from '../../../utils/getVideoId';
import { VideoEmbed } from '../../patterns/VideoEmbed';
import { localhostRegex, pasteRegexExact } from './regexPatterns';

const RichtextVideo: FC<NodeViewProps> = ({ node: { attrs } }) => {
    const { src } = attrs;
    if (!src) {
        return <NodeViewWrapper as="div"></NodeViewWrapper>;
    }

    const cleanHref = !includes('https://', src) && !includes('http://', src) ? `https://${src}` : src;
    return (
        <NodeViewWrapper as="div">
            <VideoEmbed video={cleanHref}></VideoEmbed>
        </NodeViewWrapper>
    );
};

const EmbeddedVideo = Node.create({
    name: 'embeddedVideo', // unique name for the Node
    group: 'block', // belongs to the 'block' group of extensions
    selectable: true, // so we can select the video
    atom: true, // is a single unit

    addAttributes() {
        return {
            src: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'embedded-video',
            },
        ];
    },

    // eslint-disable-next-line @typescript-eslint/naming-convention
    renderHTML({ HTMLAttributes }) {
        return ['embedded-video', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(RichtextVideo);
    },

    addProseMirrorPlugins() {
        const plugins = [];

        // adapted from https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts#L135
        plugins.push(
            new Plugin({
                key: new PluginKey('handlePasteLink'),
                props: {
                    handlePaste: (view, event, slice) => {
                        let textContent = '';

                        slice.content.forEach((node) => {
                            textContent += node.textContent;
                        });

                        if (
                            !textContent ||
                            (!textContent.match(pasteRegexExact) && !textContent.match(localhostRegex))
                        ) {
                            return false;
                        }

                        if (getYoutubeVideoId(textContent) || getVimeoVideoId(textContent)) {
                            this.editor
                                .chain()
                                .focus()
                                .insertContent(`<embedded-video src="${textContent}"></embedded-video>`)
                                .run();
                            return true;
                        }

                        return false;
                    },
                },
            }),
        );
        return plugins;
    },
});

export default EmbeddedVideo;
