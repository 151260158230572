import * as t from 'io-ts';
import { PrepreparedContentItem, ContentItem } from './ContentItem';
import optionalToUndefined from '../t/optionalToUndefined';
import { EmailItem } from './EmailItem';

export const Creator = t.type({
    fullName: t.string,
    email: t.string,
    type: optionalToUndefined(
        t.union([
            t.literal('TRIGGER'),
            t.literal('MANUALLY_RELEASED_BY_STAFF'),
            t.literal('STAFF'),
            t.literal('PARTICIPANT'),
        ]),
    ),
});
export type Creator = t.TypeOf<typeof Creator>;

export const personaToCreator = (item: PrepreparedContentItem | ContentItem | EmailItem): Creator => ({
    fullName: item.persona || '',
    email: item.persona || '',
    type: 'createdBy' in item ? item.createdBy.type : undefined,
});
