import { assoc } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FloatingActionButton } from '../../../../patterns/FAB/FloatingActionButton';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { ScratchpadFABMenu } from '../../ScratchPad/ScratchpadFABMenu';
import { useFabButtonPositioner } from '../../../../hooks/useFabButtonPositioner';

export const ParticipantScratchpadFAB: FC = () => {
    const {
        scratchPadDocuments = [],
        notifications: { scratchpad: scratchpadNotification },
        setNotifications,
    } = useContext(ParticipantStateContext);

    const [openMenu, setOpenMenu] = useState(false);
    const { rightPosition, bottomPosition, fabButtonRef } = useFabButtonPositioner();

    useEffect(() => {
        if (scratchpadNotification && openMenu) {
            setNotifications(assoc('scratchpad', false));
        }
    }, [scratchpadNotification, openMenu]);

    return (
        <>
            <FloatingActionButton
                ref={fabButtonRef}
                icon="scratchPad"
                onClick={() => setOpenMenu(!openMenu)}
                notification={scratchpadNotification}
            />
            {openMenu && (
                <ScratchpadFABMenu
                    onClose={() => setOpenMenu(false)}
                    documents={scratchPadDocuments}
                    menuPosition={{ right: rightPosition }}
                    tailPosition={{ bottom: bottomPosition }}
                />
            )}
        </>
    );
};
