import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { grey3Colour, primaryColour } from '../../../../themes/colours';
import { Icon, IconProps } from '../../Icon';

interface ButtonProps {
    fill?: string;
}

const Button = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e) => {
        if (!type || type === 'button') {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))<ButtonProps>`
    background: none;
    border-radius: 5px;
    display: inline-flex;

    padding: 5px 5px;

    &[disabled] {
        border-color: ${grey3Colour};
        color: ${grey3Colour};
    }

    &:hover:not([disabled]) svg,
    &:focus:not([disabled]) svg {
        fill: ${({ fill }) => (fill ? fill : `${primaryColour}`)};
    }
`;

type IconButtonProps = IconProps & React.ButtonHTMLAttributes<HTMLButtonElement>;
export const IconButton: FC<IconButtonProps> = ({
    icon,
    fill,
    stroke,
    width,
    height,
    size,
    ...buttonAttributes
}: IconButtonProps) => {
    const buttonProps = { ...buttonAttributes, fill };
    const iconProps = { icon, fill, stroke, width, height, size };
    return (
        <Button {...buttonProps}>
            <Icon {...iconProps} />
        </Button>
    );
};
