import React, { useContext, FC } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import AuthContext from '../WithAuth/AuthContext';

const REDIRECT_PATH = '/admin/login';

const StaffRoute: FC<RouteProps> = ({ children, component, render, ...props }: RouteProps) => {
    const authContext = useContext(AuthContext);
    // if a user without MFA logs in then changes their url to e.g /admin
    // they could skip MFA without this check
    const isStaffUser = authContext.currentAuthenticatedUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA';

    const RouteComponent = isStaffUser ? component : () => <Redirect to={REDIRECT_PATH} />;

    const isStaffRenderFunction = (routeProps: RouteComponentProps) => (render ? render(routeProps) : children);
    const isNotStaffRenderFunction = ({ location }: RouteComponentProps) => (
        <Redirect to={{ pathname: REDIRECT_PATH, state: { from: location } }} />
    );
    const renderFunction = isStaffUser ? isStaffRenderFunction : isNotStaffRenderFunction;
    return <Route {...props} component={RouteComponent} render={RouteComponent ? undefined : renderFunction} />;
};

export default StaffRoute;
