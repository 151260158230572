/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import logo from './logo.png';
import { append, assocPath, pipe } from 'ramda';
import { grey1Colour } from '../../../../../themes/colours';

const basePageTemplate: PageTemplate = {
    uuid: '',
    name: '',
    category: 'Forum',
    icon: logo,
    rootItemOptions: {
        name: 'Thread',
        requiredFields: ['title', 'post'],
        fields: [
            { name: 'title', type: ContentItemFieldType.TEXT },
            { name: 'post', type: ContentItemFieldType.RICHTEXT },
        ],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childItemOptions: {
        name: 'Post',
        pluralName: 'Posts',
        requiredFields: ['post'],
        fields: [{ name: 'post', type: ContentItemFieldType.RICHTEXT }],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 1 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.LIST },
        childrenSettings: {
            location: 'append',
            max: 100,
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['title', 'meta', 'post', 'childrenCount'],
        childContentFieldOrder: ['meta', 'post'],
        detailsLinkFields: ['title'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: { margin: { right: 10 } },
                main: { width: 800, bgColour: '#eeeeee' },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                bgColour: '#ffffff',
                width: 800,
                verticalGap: 0,
                borders: {
                    top: {
                        width: 2,
                        color: '#dddddd',
                    },
                    right: {
                        width: 2,
                        color: '#dddddd',
                    },
                    left: {
                        width: 2,
                        color: '#dddddd',
                    },
                    bottom: {
                        width: 5,
                        color: '#dddddd',
                    },
                },
            },
            rootItem: {
                avatar: {
                    position: 'gutter',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                    },
                },
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'NORMAL' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 50,
                    borders: { top: { width: 1, color: grey1Colour } },
                    margin: { top: 4 },
                    padding: { top: 6 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    title: {
                        font: { weight: 'BOLD' },
                    },
                },
                showChildItemsCount: {
                    position: 'use field order',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                    alignSelf: 'end',
                },
            },
            childrenItemsContainer: { padding: { top: 20, right: 10, left: 10 } },
            childItemContainer: {},
            childItem: {
                bgColour: '#ffffff',
                avatar: {
                    position: 'gutter',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                    },
                },
                creator: {
                    username: {},
                },
                borders: {
                    top: {
                        width: 1,
                        color: '#dddddd',
                    },
                    right: {
                        width: 1,
                        color: '#dddddd',
                    },
                    bottom: {
                        width: 1,
                        color: '#dddddd',
                    },
                    left: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                margin: { top: 6 },
                meta: {},
                interactions: {
                    horizontalGap: 50,
                    borders: { top: { width: 1, color: grey1Colour } },
                    margin: { top: 4 },
                    padding: { top: 6 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        bottom: 5,
                        top: 6,
                    },
                },
                fields: {},
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'append',
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['title', 'meta', 'post'],
        childContentFieldOrder: ['meta', 'post'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: { margin: { right: 10 } },
                main: { width: 800, bgColour: '#eeeeee' },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                bgColour: '#ffffff',
                width: 800,
                verticalGap: 0,
                borders: {
                    top: {
                        width: 2,
                        color: '#dddddd',
                    },
                    right: {
                        width: 2,
                        color: '#dddddd',
                    },
                    bottom: {
                        width: 5,
                        color: '#dddddd',
                    },
                    left: {
                        width: 2,
                        color: '#dddddd',
                    },
                },
            },
            rootItem: {
                avatar: {
                    position: 'gutter',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                    },
                },
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'NORMAL' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 50,
                    borders: { top: { width: 1, color: grey1Colour } },
                    margin: { top: 4 },
                    padding: { top: 6 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    title: {
                        font: { weight: 'BOLD' },
                    },
                },
            },
            childrenItemsContainer: { padding: { top: 20, right: 10, left: 10 } },
            childItemContainer: {},
            childItem: {
                bgColour: '#ffffff',
                avatar: {
                    position: 'gutter',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                    },
                },
                creator: {
                    username: {},
                },
                borders: {
                    top: {
                        width: 1,
                        color: '#dddddd',
                    },
                    right: {
                        width: 1,
                        color: '#dddddd',
                    },
                    bottom: {
                        width: 1,
                        color: '#dddddd',
                    },
                    left: {
                        width: 1,
                        color: '#dddddd',
                    },
                },
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                margin: { top: 6 },
                meta: {},
                interactions: {
                    horizontalGap: 50,
                    borders: { top: { width: 1, color: grey1Colour } },
                    margin: { top: 4 },
                    padding: { top: 6 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
        },
    },
};

export const pageTemplateWithoutInteractions: PageTemplate = pipe(
    assocPath(['uuid'], '4cef0440-6b98-42da-be02-79d9b8c4d485'),
    assocPath(['name'], 'Forum without Interactions'),
    assocPath(['rootItemOptions', 'interactions'], {
        positive: { enabled: false },
        negative: { enabled: false },
        shares: { enabled: false },
    }),
    assocPath(['childItemOptions', 'interactions'], {
        positive: { enabled: false },
        negative: { enabled: false },
        shares: { enabled: false },
    }),
)(basePageTemplate) as PageTemplate;

export const pageTemplateWithInteractions: PageTemplate = pipe(
    assocPath(['uuid'], '96f1c8e8-40bc-4372-9fb0-3f9dd2d956b9'),
    assocPath(['name'], 'Forum with Interactions'),
    assocPath(['rootItemOptions', 'interactions'], {
        positive: { enabled: true, img: undefined, displayName: 'Likes' },
        negative: { enabled: true, img: undefined, displayName: 'Dislikes' },
        shares: { enabled: false },
    }),
    assocPath(['childItemOptions', 'interactions'], {
        positive: { enabled: true, img: undefined, displayName: 'Likes' },
        negative: { enabled: true, img: undefined, displayName: 'Dislikes' },
        shares: { enabled: false },
    }),
    assocPath(
        ['listingPage', 'rootContentFieldOrder'],
        append('interactions', basePageTemplate.listingPage.rootContentFieldOrder),
    ),
    assocPath(['listingPage', 'theme', 'rootItem', 'showChildItemsCount'], {
        position: 'inline with interactions',
        font: {
            size: 'SMALL',
            weight: 'BOLD',
        },
    }),
    assocPath(
        ['detailsPage', 'rootContentFieldOrder'],
        append('interactions', basePageTemplate.detailsPage.rootContentFieldOrder),
    ),
    assocPath(
        ['detailsPage', 'childContentFieldOrder'],
        append('interactions', basePageTemplate.detailsPage.childContentFieldOrder),
    ),
)(basePageTemplate) as PageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                title: 'The best topic ever',
                post: 'Hello!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: 'Hi! How are you?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: "I'm doing well thankyou! The weather's been wonderful lately hasn't it?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidc',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: "Oh yes! I've enjoyed going out for walks with the dog and she's really been loving it too!",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidd',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: 'Are you still up for a coffee later this week?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuide',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 52 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: "Yes! I've been hankering after a good frappucinno for awhile! Wanna meet up Sunday afternoon?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidf',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 31 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: 'Ahhh sorry I just realised I have plans on Sunday afternoon. Would Saturday morning be doable?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidg',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ seconds: 29 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                post: 'Yes, Saturday morning is good for me :D See you then!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                title: 'A topic of utmost interest',
                post: 'Hiyaaaaa! How have things been?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid3',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mike' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                title: 'Topic with a great title',
                post: 'We should get a coffee some time!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
