import { filter, includes, length, map, toLower, toPairs } from 'ramda';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../themes/colours';
import { Display } from '../bits/Display';
import { TextInput } from '../bits/FormFields';
import { StyledErrorMessage } from '../bits/Headers';

const SingleSearchContainer = styled.div.attrs(() => ({
    role: 'combobox',
}))`
    position: relative;

    ${Display.HorizontalWithSpacing} {
        padding-bottom: 10px;
    }
`;
const SearchResultsMenu = styled.div.attrs(() => ({ role: 'listbox' }))`
    position: absolute;
    display: flex;
    margin-top: -4px;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 3px 6px 0 #00000029;
    z-index: 1;
`;
const SearchResultsMenuItem = styled.div.attrs(() => ({
    role: 'option',
}))`
    padding: 15px;
    flex: 1 0 auto;
    text-align: left;
    cursor: pointer;

    :hover {
        background: ${grey1Colour};
    }
`;
const SearchResultsMenuNoMatchItem = styled(SearchResultsMenuItem)`
    cursor: inherit;

    :hover {
        background: none;
    }
`;

interface SingleSearchOrCreateProps {
    options: Record<string, string>;
    value: string;
    onChange: (newValue: string) => void;
    disabled?: boolean;
    small?: boolean;
    error?: string;
}
export const SingleSearchOrCreate: FC<SingleSearchOrCreateProps> = ({
    options,
    value,
    onChange,
    disabled,
    small,
    error,
}: SingleSearchOrCreateProps) => {
    const [searchValue, setSearchValue] = useState('');
    // Refs to elements to check against when closing the menu on mouse click
    const containerRef = useRef<HTMLDivElement>(null);

    const matchingOptions = filter(
        ([optionKey, optionValue]) =>
            !!searchValue && value !== optionKey && includes(toLower(searchValue), toLower(optionValue)),
        toPairs(options),
    );

    useEffect(() => {
        const clearSearchValue = (e: MouseEvent) => {
            if (containerRef.current) {
                const targetIsContainer = containerRef.current === e.target;
                const targetIsOutsideContainer = !containerRef.current.contains(e.target as Node);
                if (targetIsOutsideContainer || targetIsContainer) {
                    setSearchValue('');
                }
            }
        };
        document.addEventListener('mousedown', clearSearchValue);
        return () => {
            document.removeEventListener('mousedown', clearSearchValue);
        };
    }, []);

    return (
        <SingleSearchContainer aria-expanded={!!length(matchingOptions)} ref={containerRef}>
            <TextInput
                autoComplete="off"
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                    setSearchValue(e.target.value);
                }}
                disabled={disabled}
                small={small}
            />
            <SearchResultsMenu>
                {!!length(matchingOptions) &&
                    map(
                        ([optionKey, optionValue]) => (
                            <SearchResultsMenuItem
                                key={optionKey}
                                onClick={() => {
                                    onChange(optionKey);
                                    setSearchValue('');
                                }}
                            >
                                {optionValue}
                            </SearchResultsMenuItem>
                        ),
                        matchingOptions,
                    )}
                {searchValue && !error && !length(matchingOptions) && (
                    <SearchResultsMenuNoMatchItem>No matches found</SearchResultsMenuNoMatchItem>
                )}
            </SearchResultsMenu>
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </SingleSearchContainer>
    );
};
