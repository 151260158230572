import { PermissionKey, getPermission } from 'polpeo-go-common/permissions';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { anyPass, filter } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteScratchPadDocument } from '../../../../graphql/scratchPad';
import { destructiveColour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Body2 } from '../../../bits/Headers';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../../WithParticipantState';
import { ParticipantUserContext } from '../../../WithParticipantUser';
import { StaffUserContext } from '../../../WithStaffUser';
import { DeleteModal } from '../../DeleteModal';

interface DeleteScratchPadDocumentButtonProps {
    document: ScratchPadDocument;
}
export const DeleteScratchPadDocumentButton: FC<DeleteScratchPadDocumentButtonProps> = ({ document }) => {
    const adminData = useContext(AdminInSimulationStateContext);
    const participantData = useContext(ParticipantStateContext);
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);

    const scratchPadDocuments = adminData.scratchPadDocuments || participantData.scratchPadDocuments || [];
    const setScratchPadDocuments =
        adminData.setScratchPadDocuments || participantData.setScratchPadDocuments || (() => undefined);

    const [deleteScratchPadDocumentMutation, { data: deleteScratchPadData, loading: deleteScratchPadLoading }] =
        deleteScratchPadDocument.hook();
    useEffect(() => {
        if (deleteScratchPadData?.deleteScratchPadDocument) {
            const filteredScratchPadDocuments = filter(
                (doc) => doc.uuid !== deleteScratchPadData.deleteScratchPadDocument.uuid,
                scratchPadDocuments,
            );
            setScratchPadDocuments(filteredScratchPadDocuments);
        }
    }, [deleteScratchPadData]);

    const userCanDeleteUploadedContent = anyPass([
        () => !!staffUser && getPermission(PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS, staffUser) === 'ALL',
        () => !!participantUser,
        () =>
            !!staffUser &&
            getPermission(PermissionKey.DELETE_SCRATCHPAD_DOCUMENTS, staffUser) === 'CREATED' &&
            document.createdBy === staffUser.uuid,
    ])();

    return (
        <>
            {userCanDeleteUploadedContent && (
                <IconButton
                    onClick={() => setShowDeleteDocumentModal(true)}
                    fill={destructiveColour}
                    icon="delete"
                    size={15}
                />
            )}
            {showDeleteDocumentModal && (
                <DeleteModal
                    header="Delete Document"
                    onModalClose={() => setShowDeleteDocumentModal(false)}
                    confirmationText={
                        <>
                            <p>Are you sure you want to delete the document: {`'${document.name}'`}? </p>
                            <p>This will delete the document for all users with access to your team.</p>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() => {
                        deleteScratchPadDocumentMutation({
                            variables: {
                                scratchPadDocument: document,
                            },
                        });
                    }}
                    showSpinner={deleteScratchPadLoading}
                />
            )}
        </>
    );
};
