import { evolve, flatten, isNil, keys, map, pickAll, reject, values } from 'ramda';

const teamValidationTransformations = {
    simulatedTime: (val: unknown) => {
        if (typeof val === 'string' || typeof val === 'number' || val === undefined) {
            return undefined;
        }
        return `SimulatedTime: found simulated time which was not plain text or number: ${val}`;
    },
    // TODO make sure that the linked prepared content actually exists
    prepreparedContentItemUUID: () => {
        return undefined;
    },
};

const getErrorsForTriggersSheet = (triggersData: Record<string, unknown>[]): string[] => {
    const fieldsToValidate = keys(teamValidationTransformations);
    const errorMap = map(
        (triggerData) =>
            evolve(
                teamValidationTransformations,
                pickAll<Record<string, unknown>, Record<string, unknown[]>>(fieldsToValidate, triggerData),
            ),
        triggersData,
    );
    return reject(isNil, flatten(map(values, errorMap))) as string[];
};

export default getErrorsForTriggersSheet;
