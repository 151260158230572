import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { getParticipantUser } from '../../graphql/participantUsers';
import { clearSimulationCodeCookie } from '../../utils/simulationCodeCookie';
import WithChildren from '../../utils/WithChildren';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import AuthContext from '../WithAuth/AuthContext';

export interface ParticipantUserState {
    participantUser: ParticipantUser;
    setParticipantUser: (participantUser: ParticipantUser) => void;
}
// No one should ever be accessing Auth before it's been initialised anyways so we shouldn't
// provide any default values (as there are no correct default values if Auth isn't initialised!!!)
export const ParticipantUserContext = createContext<ParticipantUserState>({} as ParticipantUserState);

export const WithParticipantUser: FC<WithChildren> = ({ children }: WithChildren) => {
    const locationParams = useParams<{ simulationCode: string }>();
    const authContext = useContext(AuthContext);
    const [participantUser, setParticipantUser] = useState<ParticipantUser>();

    const [GetParticipantUserQuery, { data: GetParticipantUserData, error: GetParticipantUserError }] =
        getParticipantUser.lazyHook({
            simulationCode: locationParams.simulationCode,
            participantId: authContext.currentCredentials.identityId,
        });
    useEffect(() => {
        setParticipantUser(GetParticipantUserData?.getParticipantUser);
    }, [GetParticipantUserData]);

    useEffect(() => {
        if (authContext.currentCredentials && !authContext.currentAuthenticatedUser) {
            const { currentCredentials } = authContext;
            GetParticipantUserQuery({
                variables: {
                    simulationCode: locationParams.simulationCode,
                    participantId: currentCredentials.identityId,
                },
            });
        } else {
            setParticipantUser(undefined);
        }
    }, [authContext]);

    if (GetParticipantUserError) {
        clearSimulationCodeCookie();
        return <Redirect to={{ pathname: '/', state: { simulationCode: locationParams.simulationCode } }} push />;
    }

    if (!participantUser) {
        return <FullPageSplashLoadingScreen />;
    }

    return (
        <ParticipantUserContext.Provider value={{ participantUser, setParticipantUser }}>
            {children}
        </ParticipantUserContext.Provider>
    );
};
