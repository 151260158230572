import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import React, { FC, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { FullPageError } from '../../../../../patterns/FullPageError';
import { AdminManageSimulationContext } from '../../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../../../WithStaffUser';
import { EditTriggerForm } from './EditTriggerForm';

const Container = styled.div`
    width: 800px;

    align-self: center;
`;

export const EditTriggerPage: FC = () => {
    const history = useHistory();
    const { triggerUUID } = useParams<{ simulationUUID: string; triggerUUID: string }>();
    const staffUser = useContext(StaffUserContext);
    const { setTrigger } = useContext(AdminStateContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);

    const trigger = currentSimulationContent.triggers[triggerUUID];

    if (!trigger) {
        return <FullPageError errorCode="404" message="Trigger could not be found" />;
    }

    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt &&
        !trigger.releasedAt &&
        userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);

    return (
        <Container>
            <EditTriggerForm
                trigger={trigger}
                disableInputs={!userCanWriteSimulationAndContent}
                onBackButton={() => history.push(`/admin/simulation/${trigger.simulationUUID}/manage/triggers`)}
                onTriggerSaved={(savedTrigger) => {
                    setTrigger(savedTrigger);
                    history.push(`/admin/simulation/${savedTrigger.simulationUUID}/manage/triggers`);
                }}
            />
        </Container>
    );
};
