import { DateTime } from 'luxon';
import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { sortBy, values } from 'ramda';
import React, { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import XLSX from 'xlsx';
import { editSimulation } from '../../../../../graphql/simulations/editSimulation';
import { openLobby } from '../../../../../graphql/simulations/openLobby';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { H1Heading, Subtitle1 } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { Spinner } from '../../../../bits/Spinner';
import { SimulationStatusLozenge } from '../../../../patterns/SimulationStatusLozenge';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { userCanCreateMoments } from 'polpeo-go-common/permissions/userCanCreateMoments';
import { makeMomentsWorkbook } from './makeMomentsWorkbook';
import { makeSimulationWorkbook } from './makeSimulationWorkbook';

const SimulationDetailsBannerContainer = styled(Display.HorizontalWithMaxSpaceBetween)`
    padding: 20px;
`;
interface SimulationDetailsBannerProps {
    simulation: Simulation;
}
export const SimulationDetailsBanner: FC<SimulationDetailsBannerProps> = ({
    simulation,
}: SimulationDetailsBannerProps) => {
    const history = useHistory();
    const staffUser = useContext(StaffUserContext);
    const {
        staffUsers,
        participants: allParticipants,
        teams: allTeams,
        teamAssignments: allTeamAssignments,
        simulationContent: allSimulationContent,
        setSimulation,
    } = useContext(AdminStateContext);

    const teams = sortBy((team) => team.name, values(allTeams[simulation.uuid] || {}));
    const participants = allParticipants[simulation.uuid];
    const teamAssignments = allTeamAssignments[simulation.uuid];
    const simulationContent = allSimulationContent[simulation.uuid];

    const [openLobbyMutation, { data: openLobbyData, loading: openLobbyLoading }] = openLobby.hook();
    useEffect(() => {
        if (openLobbyData?.openLobby) {
            setSimulation(openLobbyData.openLobby);
            history.push(`/admin/simulation/${simulation.uuid}`);
        }
    }, [openLobbyData]);

    const [editSimulationMutation, { data: editSimulationData, loading: editSimulationLoading }] =
        editSimulation.hook();
    useEffect(() => {
        if (editSimulationData) {
            setSimulation(editSimulationData.editSimulation);
        }
    }, [editSimulationData]);

    const scheduledForDisplayString = simulation.scheduledFor
        ? DateTime.fromJSDate(simulation.scheduledFor).toFormat('HH:mm ZZZZ - EEE dd MMM y')
        : '';
    const scheduledForDiff = simulation.scheduledFor
        ? DateTime.fromJSDate(simulation.scheduledFor).diff(DateTime.now(), ['days', 'hours', 'minutes', 'seconds'])
        : undefined;

    const userCanUpdateSimulationReadyStatus = userCan(PermissionKey.UPDATE_SIMULATION_READY_STATUS, staffUser);
    const userCanWriteSimulationAndContent = userCan(
        PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT,
        staffUser,
    );
    const userCanAdministerSimulations = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
    const userCanDownloadData = userCan(PermissionKey.DOWNLOAD_SIMULATION_DATA, staffUser);

    return (
        <SimulationDetailsBannerContainer>
            <Display.VerticalWithSpacing gap={7} horizontalAlign="start">
                <Display.HorizontalWithSpacing>
                    <H1Heading>{simulation.name}</H1Heading>
                    <SimulationStatusLozenge simulation={simulation} />
                </Display.HorizontalWithSpacing>
                <Subtitle1>{simulation.clientName}</Subtitle1>
                {simulation.expectedNumberParticipants && (
                    <span>
                        Expecting <b>{simulation.expectedNumberParticipants}</b> participants assigned to teams
                    </span>
                )}
                <Display.HorizontalWithSpacing>
                    {userCanAdministerSimulations && !simulation.lobbyOpenedAt && simulation.isReady && (
                        <PrimaryButton
                            onClick={() => openLobbyMutation({ variables: { simulationUUID: simulation.uuid } })}
                            disabled={openLobbyLoading}
                        >
                            Open Lobby
                        </PrimaryButton>
                    )}
                    {simulation.lobbyOpenedAt && !simulation.completedAt && (
                        <PrimaryButton onClick={() => history.push(`/admin/simulation/${simulation.uuid}`)}>
                            Re-join Simulation
                        </PrimaryButton>
                    )}
                    {simulation.completedAt && (
                        <>
                            <PrimaryButton onClick={() => history.push(`/admin/simulation/${simulation.uuid}`)}>
                                View simulation
                            </PrimaryButton>
                            {userCanDownloadData && (
                                <>
                                    <SecondaryButton
                                        onClick={() => {
                                            const workbook = makeSimulationWorkbook(
                                                simulation,
                                                {
                                                    staffUsers,
                                                    participants,
                                                },
                                                teams,
                                                teamAssignments,
                                                simulationContent,
                                            );
                                            XLSX.writeFile(workbook, `${simulation.name}_data.xlsx`);
                                        }}
                                    >
                                        Download simulation data
                                    </SecondaryButton>
                                    {userCanCreateMoments(simulation, staffUser) && (
                                        <SecondaryButton
                                            onClick={() => {
                                                const workbook = makeMomentsWorkbook(
                                                    simulation,
                                                    {
                                                        staffUsers,
                                                        participants,
                                                    },
                                                    teams,
                                                    teamAssignments,
                                                    simulationContent,
                                                );
                                                XLSX.writeFile(workbook, `${simulation.name}_highlights.xlsx`);
                                            }}
                                        >
                                            Download highlights data
                                        </SecondaryButton>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {userCanUpdateSimulationReadyStatus && (
                        <>
                            {!simulation.isReady && (
                                <PrimaryButton
                                    onClick={() => {
                                        editSimulationMutation({
                                            variables: {
                                                simulation: {
                                                    ...simulation,
                                                    isReady: true,
                                                },
                                            },
                                        });
                                    }}
                                    disabled={editSimulationLoading}
                                >
                                    Mark As Ready
                                </PrimaryButton>
                            )}
                            {simulation.isReady && !simulation.lobbyOpenedAt && (
                                <SecondaryButton
                                    onClick={() => {
                                        editSimulationMutation({
                                            variables: {
                                                simulation: {
                                                    ...simulation,
                                                    isReady: false,
                                                },
                                            },
                                        });
                                    }}
                                    disabled={editSimulationLoading}
                                >
                                    Mark as Draft
                                </SecondaryButton>
                            )}
                        </>
                    )}
                    {(editSimulationLoading || openLobbyLoading) && <Spinner size={40} />}
                </Display.HorizontalWithSpacing>
            </Display.VerticalWithSpacing>
            <Display.VerticalWithSpacing gap={7} horizontalAlign="end">
                {!!simulation.scheduledFor && (
                    <>
                        <H1Heading>{scheduledForDisplayString}</H1Heading>
                        <span>
                            Scheduled for {scheduledForDiff?.days} days {scheduledForDiff?.hours} hours{' '}
                            {scheduledForDiff?.minutes} mins
                        </span>
                    </>
                )}
                {userCanWriteSimulationAndContent && (
                    <SecondaryButton onClick={() => history.push(`/admin/simulation/${simulation.uuid}/edit`)}>
                        <Icon icon="simulationSetup" /> Simulation Setup
                    </SecondaryButton>
                )}
            </Display.VerticalWithSpacing>
        </SimulationDetailsBannerContainer>
    );
};
