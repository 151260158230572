/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import dummyChildImage from './dummyChildImage.png';
import dummyRootImage from './dummyRootImage.jpg';
import facebookHeader from './facebookHeader.png';
import negativeSocial from './negative-social.svg';
import positiveSocial from './positive-social.svg';
import logo from './logo.png';

const pageTemplate: PageTemplate = {
    uuid: '651f0773-fd95-4f63-84f2-9527b38de562',
    name: 'Social Network',
    category: 'Social Media',
    icon: logo,
    rootItemOptions: {
        name: 'Post',
        fields: [
            { name: 'post', type: ContentItemFieldType.LONG_TEXT },
            { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        requiredFields: [],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: {
                enabled: true,
                displayName: undefined,
                img: negativeSocial,
            },
            shares: {
                enabled: false,
            },
        },
    },
    childItemOptions: {
        name: 'Comment',
        pluralName: 'Comments',
        requiredFields: [],
        fields: [
            { name: 'post', type: ContentItemFieldType.LONG_TEXT },
            { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: {
                enabled: true,
                displayName: undefined,
                img: negativeSocial,
            },
            shares: {
                enabled: false,
            },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 10 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
            max: 100,
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'post', 'video', 'image', 'interactions'],
        childContentFieldOrder: ['meta', 'post', 'video', 'image', 'interactions'],
        detailsLinkFields: ['meta'],
        theme: {
            page: {
                bgColour: '#f0f2f5',
                stats: {
                    width: 300,
                    position: 'leftSidebar',
                    bgColour: '#ffffff',
                    margin: { top: 10, left: 10, right: 10, bottom: 10 },
                    padding: { top: 15, right: 15, bottom: 15, left: 15 },
                    borderRadius: 10,
                    shadow: true,
                },
                header: {
                    img: facebookHeader,
                    bgColour: '#ffffff',
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                fullWidthHeaderDressing: {
                    bgColour: '#ffffff',
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
                main: { width: 680 },
            },
            rootItemsContainer: {
                width: 680,
                verticalGap: 12,
                horizontalGap: 0,
            },
            rootItem: {
                bgColour: '#ffffff',
                borderRadius: 9,
                padding: { top: 15, left: 15, bottom: 15, right: 15 },
                shadow: true,
                avatar: { position: 'inline' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                    padding: { top: 0, left: 0, bottom: 10, right: 0 },
                },
                meta: {},
                interactions: {
                    layout: 'space-between',
                    padding: { top: 25, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                        margin: { top: 10 },
                    },
                    post: {
                        margin: { top: 10 },
                    },
                },
                showChildItemsCount: {
                    position: 'inline with interactions',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                },
            },
            childrenItemsContainer: {
                borders: { top: { width: 1, color: '#dddddd' } },
                margin: { top: 15 },
                padding: { top: 15 },
                verticalGap: 12,
            },
            childItemContainer: {},
            childItem: {
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 10,
                    iconHeight: 12,
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                    margin: { bottom: 10 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        borders: {
                            top: { width: 1, color: '#dddddd' },
                            left: { width: 1, color: '#dddddd' },
                            bottom: { width: 1, color: '#dddddd' },
                            right: { width: 1, color: '#dddddd' },
                        },
                        margin: { top: 10 },
                        borderRadius: 10,
                        alignSelf: 'start',
                    },
                    post: {
                        margin: { top: 5 },
                    },
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'post', 'video', 'image', 'interactions'],
        childContentFieldOrder: ['meta', 'post', 'video', 'image', 'interactions'],
        theme: {
            page: {
                bgColour: '#f0f2f5',
                header: {
                    img: facebookHeader,
                    bgColour: '#ffffff',
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                fullWidthHeaderDressing: {
                    bgColour: '#ffffff',
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                main: { padding: { top: 20, bottom: 20 }, width: 680 },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 680,
                verticalGap: 12,
                horizontalGap: 0,
            },
            rootItem: {
                bgColour: '#ffffff',
                borderRadius: 9,
                padding: { top: 15, left: 15, bottom: 15, right: 15 },
                shadow: true,
                avatar: { position: 'inline' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                    padding: { top: 0, left: 0, bottom: 10, right: 0 },
                },
                meta: {},
                interactions: {
                    layout: 'space-between',
                    padding: { top: 25, left: 0, bottom: 0, right: 0 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        alignSelf: 'center',
                        margin: { top: 10 },
                    },
                    post: {
                        margin: { top: 10 },
                    },
                },
                showChildItemsCount: {
                    position: 'inline with interactions',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                },
            },
            childrenItemsContainer: {
                borders: { top: { width: 1, color: '#dddddd' } },
                margin: { top: 15 },
                padding: { top: 15 },
                verticalGap: 12,
            },
            childItemContainer: {},
            childItem: {
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 10,
                    iconHeight: 12,
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                    margin: { bottom: 10 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    image: {
                        borders: {
                            top: { width: 1, color: '#dddddd' },
                            left: { width: 1, color: '#dddddd' },
                            bottom: { width: 1, color: '#dddddd' },
                            right: { width: 1, color: '#dddddd' },
                        },
                        margin: { top: 10 },
                        borderRadius: 10,
                        alignSelf: 'start',
                    },
                    post: {
                        margin: { top: 5 },
                    },
                },
            },
        },
    },
};

export default pageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '651f0773-fd95-4f63-84f2-9527b38de562',
            interactions: {
                positive: 1566,
                negative: 201,
            },
            data: {
                post: 'WTF! I’m so scared about this #TanzaniaBreach!',
                image: dummyRootImage,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '651f0773-fd95-4f63-84f2-9527b38de562',
            interactions: {
                positive: 456,
                negative: 23,
            },
            data: {
                post: "Me too! It's scary",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '651f0773-fd95-4f63-84f2-9527b38de562',
            interactions: {
                positive: 25,
                negative: 235,
            },
            data: {
                post: 'Who cares!?',
                image: dummyChildImage,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '651f0773-fd95-4f63-84f2-9527b38de562',
            interactions: {
                positive: 457,
                negative: 568,
            },
            data: {
                post: 'I don’t care about the Tanzania breach, the government knows everything about me anyway!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2a',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mike' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid2',
        content: {
            pageUUID: '651f0773-fd95-4f63-84f2-9527b38de562',
            interactions: {
                positive: 13,
                negative: 56,
            },
            data: {
                post: 'You should be a lot more concerned, this is a private company that holds the information and have dropped the ball on our privacy.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
