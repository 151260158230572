import React, { FC, useState } from 'react';
import { CardButton } from '../../../../patterns/Card';
import { ManageTeamModal } from './ManageTeamModal';

export const CreateSimulationTeamButton: FC = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <CardButton
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
            >
                + Add Team
            </CardButton>
            {showModal && <ManageTeamModal onModalClose={() => setShowModal(false)} />}
        </>
    );
};
