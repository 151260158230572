import * as t from 'io-ts';
import { omit } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';

export const PageDressings = t.partial({
    header: optionalToUndefined(t.string),
    leftSidebar: optionalToUndefined(t.string),
    rightSidebar: optionalToUndefined(t.string),
    footer: optionalToUndefined(t.string),
});
export type PageDressings = t.TypeOf<typeof PageDressings>;

export enum CreateRootContentPermissionTypeEnum {
    STAFF = 'STAFF',
    PARTICIPANTS = 'PARTICIPANTS',
    BOTH = 'BOTH',
    ADMINS_ONLY = 'ADMINS_ONLY',
}
export const CreateRootContentPermissionType = t.keyof(CreateRootContentPermissionTypeEnum);

export enum ParticipantDeleteContentPermissionTypeEnum {
    ALL = 'ALL',
    CREATED = 'CREATED',
    NONE = 'NONE',
}

export const ParticipantDeleteContentPermissionType = t.keyof(ParticipantDeleteContentPermissionTypeEnum);

export const Page = t.type({
    uuid: t.string,
    simulationUUID: t.string,
    templateUUID: t.string,
    name: t.string,
    dressing: PageDressings,
    stats: t.record(t.string, t.union([t.string, t.number])),
    // Should match CreateRootContentPermissionTypeEnum
    createRootContentPermission: t.union([
        t.literal('STAFF'),
        t.literal('PARTICIPANTS'),
        t.literal('BOTH'),
        t.literal('ADMINS_ONLY'),
    ]),
    // I want to be able to use Page.props in the below deifnitions so can't pull this out into a t.partial :(
    showItemsAsIndividualPages: optionalToUndefined(t.boolean),
    // Should match ParticipantDeleteContentPermissionTypeEnum
    participantDeleteContentPermission: t.union([t.literal('ALL'), t.literal('CREATED'), t.literal('NONE')]),
    hiddenFromTeamUUIDs: optionalToUndefined(t.array(t.string)),
});
export type Page = t.TypeOf<typeof Page>;
export const TEST_PAGE: Page = {
    uuid: 'pageUUID',
    simulationUUID: 'simulationUUID',
    templateUUID: 'templateUUID',
    name: 'Page Name',
    dressing: {},
    stats: {},
    createRootContentPermission: 'STAFF',
    showItemsAsIndividualPages: undefined,
    participantDeleteContentPermission: 'NONE',
    hiddenFromTeamUUIDs: undefined,
};

export const NewPage = t.type(omit(['uuid'], Page.props));
export type NewPage = t.TypeOf<typeof NewPage>;

export const DynamoDbPageValue = t.type(omit(['uuid', 'simulationUUID'], Page.props));
export type DynamoDbPageValue = t.TypeOf<typeof DynamoDbPageValue>;
