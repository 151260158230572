import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { GraphQlPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapMutation from '../wrapMutation';

interface ReleaseTriggerParams {
    trigger: Omit<Trigger, 'pageDressingChanges'> & {
        pageDressingChanges: string /* AWSJSON - Record<string, string> */;
    };
}

type ItemReleasedMeta = Pick<GraphQlPrepreparedContentItem | GraphQlPrepreparedEmailItem, 'type' | 'uuid' | 'released'>;
interface ReleaseTriggerData {
    releaseTrigger: {
        simulationUUID: string;
        trigger: Omit<Trigger, 'pageDressingChanges'> & {
            pageDressingChanges: string /* AWSJSON - Record<string, string> */;
        };
        items: Array<ItemReleasedMeta>;
    };
}

const mutation = gql`
    mutation releaseTrigger($trigger: TriggerInput!) {
        releaseTrigger(trigger: $trigger) {
            simulationUUID
            trigger {
                uuid
                simulationUUID
                title
                isOptional
                simulatedTime
                participantPopupSettings {
                    enabled
                    text
                    prepreparedContentItemUUID
                }
                staffPopupSettings {
                    directions
                    guidelines
                    expectedActions
                }
                overviewChanges {
                    title
                    description
                    headerImage
                }
                pageDressingChanges
                releasedAt
            }
            items {
                __typename
                ... on PrepreparedContent {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
                ... on PrepreparedEmail {
                    type
                    uuid
                    released {
                        at
                        items
                    }
                }
            }
        }
    }
`;

export const releaseTrigger = wrapMutation<ReleaseTriggerParams, ReleaseTriggerData>(mutation);
