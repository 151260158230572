import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { WithAuth } from './components/WithAuth';
import { WithConfig } from './components/WithConfig';
import { WithApollo } from './graphql/WithApollo';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './themes/GlobalStyle';
import * as Sentry from '@sentry/react';
import { version as taggedVersion } from '../package.json';

const version = `${taggedVersion}${process.env.NODE_ENV === 'development' ? 'dev' : ''}`;
// intentionally left in to aid in figuring out what is deployed where without needing access to github
console.log(`Version ${version}`);
if (process.env.REACT_APP_STACK_IDENTIFIER) {
    Sentry.init({
        dsn: 'https://f82fe8ca0f424ba599b3466b5fd88579@o102901.ingest.sentry.io/5988282',
        environment: process.env.REACT_APP_STACK_IDENTIFIER,
        release: version,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <GlobalStyle />
            <WithConfig>
                <WithAuth>
                    <WithApollo>
                        <App />
                    </WithApollo>
                </WithAuth>
            </WithConfig>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
