import gql from 'graphql-tag';
import { GetSignedPutUrlQueryData, GetSignedPutUrlQueryParams } from '../utils/GetSignedUrlQueryParams';
import wrapQuery from '../wrapQuery';

type GetSignedPutUrlForBriefingDocumentUploadParams = GetSignedPutUrlQueryParams;

interface GetSignedPutUrlForBriefingDocumentUploadData {
    getSignedPutUrlForBriefingDocumentUpload: GetSignedPutUrlQueryData;
}

const query = gql`
    query getSignedPutUrlForBriefingDocumentUpload($simulation: SimulationInput!, $file: FileDetailsInput!) {
        getSignedPutUrlForBriefingDocumentUpload(simulation: $simulation, file: $file) {
            signedPutUrl
            path
        }
    }
`;

export const getSignedPutUrlForBriefingDocumentUpload =
    wrapQuery<GetSignedPutUrlForBriefingDocumentUploadParams, GetSignedPutUrlForBriefingDocumentUploadData>(query);
