import { CognitoUser } from '@aws-amplify/auth';
import { ICredentials } from '@aws-amplify/core';
import { createContext } from 'react';

export interface CognitoUserAttributes {
    email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    email_verified?: boolean;
    sub: string;
}

// Types are currently wrong for Cognito user (missing attribute field and challengeName field)
// so lets make our own for now
// github.com/aws-amplify/amplify-js/issues/4927
export interface AppUser extends CognitoUser {
    attributes?: CognitoUserAttributes;
    preferredMFA: 'NOMFA' | 'SOFTWARE_TOKEN_MFA';
    challengeName?: string;
}

export type AuthContextValue = {
    signIn: (email: string, password: string) => Promise<AppUser>;
    setupTOTP: (user: AppUser) => Promise<string>;
    confirmSignInWithMFA: (user: AppUser, code: string) => Promise<void>;
    submitTOTPCode: (user: AppUser, code: string) => Promise<AppUser>;
    getPreferredMFA: (user: AppUser) => Promise<string>;
    signOutAuthenticatedUser: () => void;
    signOutUnauthenticatedUser: () => void;
    sendCode: (email: string) => void;
    resetPassword: (email: string, code: string, newPassword: string) => void;
    currentCredentials: ICredentials;
    currentAuthenticatedUser?: AppUser;
    completeNewPassword: (user: AppUser, newPassword: string) => Promise<void>;
};

// No one should ever be accessing Auth before it's been initialised anyways so we shouldn't
// provide any default values (as there are no correct default values if Auth isn't initialised!!!)
const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export default AuthContext;
