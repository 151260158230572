import styled from 'styled-components/macro';

interface NotificationContainerProps {
    notification?: boolean;
    position?: { top: number; right: number };
}
export const NotificationContainer = styled.div<NotificationContainerProps>`
    flex: 0 0 auto;
    position: relative;

    &:before {
        display: ${({ notification }) => (notification ? 'block' : 'none')};
        content: ' ';
        width: 10px;
        height: 10px;

        position: absolute;
        top: ${({ position }) => (position?.top ? `${position?.top}px` : 0)};
        right: ${({ position }) => (position?.right ? `${position?.right}px` : 0)};

        background-color: #c51b32;
        border-radius: 50%;
    }
`;
