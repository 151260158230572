import { Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteSimulation } from '../../../../graphql/simulations/deleteSimulation';
import { destructiveColour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Body2 } from '../../../bits/Headers';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { DeleteModal } from '../../DeleteModal';

interface DeleteSimulationButtonProps {
    simulation: Simulation;
}
export const DeleteSimulationButton: FC<DeleteSimulationButtonProps> = ({ simulation }) => {
    const { removeSimulation } = useContext(AdminStateContext);
    const [showModal, setShowModal] = useState(false);
    const [deleteSimulationMutation, { data: deleteSimulationData, loading: deleteSimulationLoading }] =
        deleteSimulation.hook();

    useEffect(() => {
        if (deleteSimulationData?.deleteSimulation) {
            removeSimulation(simulation);
            setShowModal(false);
        }
    }, [deleteSimulationData]);
    return (
        <>
            <IconButton icon="delete" fill={destructiveColour} onClick={() => setShowModal(true)} />
            {showModal && (
                <DeleteModal
                    header="Delete Simulation"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={
                        <>
                            <p>Are you sure you want to delete the simulation {`'${simulation.name}'`}? </p>
                            <p>
                                This will also delete associated data including triggers, pages, preprepared content and
                                any live content
                            </p>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() => deleteSimulationMutation({ variables: { simulation } })}
                    showSpinner={deleteSimulationLoading}
                />
            )}
        </>
    );
};
