import { useApolloClient } from '@apollo/client';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { assocPath, map } from 'ramda';
import React, { FC, useContext, useState } from 'react';
import { Client } from '../../../../../../graphql/client';
import { getSignedPutUrlForTeamOverviewUpload } from '../../../../../../graphql/teams';
import { SecondaryButton } from '../../../../../bits/Buttons';
import { TextArea, TextInput } from '../../../../../bits/FormFields';
import { Spinner } from '../../../../../bits/Spinner';
import { Card } from '../../../../../patterns/Card';
import { DropZone } from '../../../../../patterns/DropZone';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { FormGrid } from '../../../../FormGrid';
import { PageDressingDropZone } from '../../../../PageDressingDropZone';

const uploadTeamOverviewImage = async (client: Client, simulation: Simulation, file: File): Promise<string> => {
    const putUrl = await getSignedPutUrlForTeamOverviewUpload.promise(client, {
        simulation: simulation,
        file: {
            name: file.name,
            type: file.type,
            size: undefined,
        },
    });
    if (!putUrl.data) {
        throw new Error('Could not create signed put url for image');
    }
    const putFileRequestOptions: RequestInit = {
        method: 'PUT',
        headers: { 'content-type': file.type },
        body: file,
    };
    await fetch(putUrl.data.getSignedPutUrlForTeamOverviewUpload.signedPutUrl, putFileRequestOptions);
    return putUrl.data.getSignedPutUrlForTeamOverviewUpload.path;
};

interface EditTriggerMiscProps {
    trigger: Trigger;
    onChange: (triggerOrFunc: Trigger | ((trigger: Trigger) => Trigger)) => void;
    disableInputs: boolean;
}
export const EditTriggerMisc: FC<EditTriggerMiscProps> = ({ trigger, onChange, disableInputs }) => {
    const client = useApolloClient();
    const { simulations, simulationContent } = useContext(AdminStateContext);
    const simulation = simulations[trigger.simulationUUID];
    const { pages, pageOrder } = simulationContent[simulation.uuid] || {};

    const [uploadingTeamOverviewImage, setUploadingTeamOverviewImage] = useState(false);
    return (
        <>
            <Card header="Overview">
                <FormGrid
                    fields={[
                        [
                            'Title',
                            <TextInput
                                key="title"
                                value={trigger.overviewChanges.title || ''}
                                onChange={(e) => onChange(assocPath(['overviewChanges', 'title'], e.target.value))}
                                disabled={disableInputs}
                            />,
                        ],
                        [
                            'Description',
                            <TextArea
                                key="description"
                                value={trigger.overviewChanges.description || ''}
                                onChange={(e) =>
                                    onChange(assocPath(['overviewChanges', 'description'], e.target.value))
                                }
                                disabled={disableInputs}
                            />,
                        ],
                        [
                            'Image header',
                            <>
                                {uploadingTeamOverviewImage && <Spinner />}
                                {!uploadingTeamOverviewImage && (
                                    <>
                                        {trigger.overviewChanges.headerImage && (
                                            <>
                                                <SecondaryButton
                                                    onClick={() =>
                                                        onChange(
                                                            assocPath(['overviewChanges', 'headerImage'], undefined),
                                                        )
                                                    }
                                                    disabled={disableInputs}
                                                >
                                                    Remove
                                                </SecondaryButton>
                                                <img src={trigger.overviewChanges.headerImage} />
                                            </>
                                        )}
                                        <DropZone
                                            accept="image/*"
                                            onAcceptedFiles={async (files: File[]) => {
                                                if (files.length === 1) {
                                                    setUploadingTeamOverviewImage(true);
                                                    const imageFile = files[0];
                                                    const imagePath = await uploadTeamOverviewImage(
                                                        client,
                                                        simulation,
                                                        imageFile,
                                                    );
                                                    onChange(assocPath(['overviewChanges', 'headerImage'], imagePath));
                                                    setUploadingTeamOverviewImage(false);
                                                }
                                            }}
                                            disabled={disableInputs}
                                        >
                                            <p>Drag and drop your image here or click to select a image</p>
                                        </DropZone>
                                    </>
                                )}
                            </>,
                        ],
                    ]}
                />
            </Card>
            <Card header="Page Changes">
                <FormGrid
                    fields={map((pageUUID) => {
                        const page = pages[pageUUID];
                        const dressings = trigger.pageDressingChanges[pageUUID] || {};
                        return [
                            page.name,
                            <>
                                <FormGrid
                                    fields={[
                                        [
                                            'Header',
                                            <>
                                                {dressings.header && (
                                                    <>
                                                        <SecondaryButton
                                                            onClick={() =>
                                                                onChange(
                                                                    assocPath(
                                                                        ['pageDressingChanges', pageUUID, 'header'],
                                                                        undefined,
                                                                    ),
                                                                )
                                                            }
                                                            disabled={disableInputs}
                                                        >
                                                            Remove
                                                        </SecondaryButton>
                                                        <img src={dressings.header} />
                                                    </>
                                                )}
                                                <PageDressingDropZone
                                                    simulation={simulation}
                                                    onUploaded={(path) =>
                                                        onChange(
                                                            assocPath(
                                                                ['pageDressingChanges', pageUUID, 'header'],
                                                                path,
                                                            ),
                                                        )
                                                    }
                                                    disabled={disableInputs}
                                                />
                                            </>,
                                        ],
                                        [
                                            'Left Sidebar',
                                            <>
                                                {dressings.leftSidebar && (
                                                    <>
                                                        <SecondaryButton
                                                            onClick={() =>
                                                                onChange(
                                                                    assocPath(
                                                                        [
                                                                            'pageDressingChanges',
                                                                            pageUUID,
                                                                            'leftSidebar',
                                                                        ],
                                                                        undefined,
                                                                    ),
                                                                )
                                                            }
                                                            disabled={disableInputs}
                                                        >
                                                            Remove
                                                        </SecondaryButton>
                                                        <img src={dressings.leftSidebar} />
                                                    </>
                                                )}
                                                <PageDressingDropZone
                                                    simulation={simulation}
                                                    onUploaded={(path) =>
                                                        onChange(
                                                            assocPath(
                                                                ['pageDressingChanges', pageUUID, 'leftSidebar'],
                                                                path,
                                                            ),
                                                        )
                                                    }
                                                    disabled={disableInputs}
                                                />
                                            </>,
                                        ],
                                        [
                                            'Right Sidebar',
                                            <>
                                                {dressings.rightSidebar && (
                                                    <>
                                                        <SecondaryButton
                                                            onClick={() =>
                                                                onChange(
                                                                    assocPath(
                                                                        [
                                                                            'pageDressingChanges',
                                                                            pageUUID,
                                                                            'rightSidebar',
                                                                        ],
                                                                        undefined,
                                                                    ),
                                                                )
                                                            }
                                                            disabled={disableInputs}
                                                        >
                                                            Remove
                                                        </SecondaryButton>
                                                        <img src={dressings.rightSidebar} />
                                                    </>
                                                )}
                                                <PageDressingDropZone
                                                    simulation={simulation}
                                                    onUploaded={(path) =>
                                                        onChange(
                                                            assocPath(
                                                                ['pageDressingChanges', pageUUID, 'rightSidebar'],
                                                                path,
                                                            ),
                                                        )
                                                    }
                                                    disabled={disableInputs}
                                                />
                                            </>,
                                        ],
                                        [
                                            'Footer',
                                            <>
                                                {dressings.footer && (
                                                    <>
                                                        <SecondaryButton
                                                            onClick={() =>
                                                                onChange(
                                                                    assocPath(
                                                                        ['pageDressingChanges', pageUUID, 'footer'],
                                                                        undefined,
                                                                    ),
                                                                )
                                                            }
                                                            disabled={disableInputs}
                                                        >
                                                            Remove
                                                        </SecondaryButton>
                                                        <img src={dressings.footer} />
                                                    </>
                                                )}
                                                <PageDressingDropZone
                                                    simulation={simulation}
                                                    onUploaded={(path) =>
                                                        onChange(
                                                            assocPath(
                                                                ['pageDressingChanges', pageUUID, 'footer'],
                                                                path,
                                                            ),
                                                        )
                                                    }
                                                    disabled={disableInputs}
                                                />
                                            </>,
                                        ],
                                    ]}
                                />
                            </>,
                        ];
                    }, pageOrder)}
                />
            </Card>
        </>
    );
};
