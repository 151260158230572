import { Moment } from 'polpeo-go-common/types/Moment';
import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../bits/Buttons';
import { EditMomentModal } from './EditMomentModal';

type EditMomentButtonProps = {
    moment: Moment;
};
export const EditMomentButton: FC<EditMomentButtonProps> = ({ moment }) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <SecondaryButton small onClick={() => setOpenModal(true)}>
                Edit Highlight
            </SecondaryButton>
            {!!openModal && <EditMomentModal onCloseModal={() => setOpenModal(false)} moment={moment} />}
        </>
    );
};
