import gql from 'graphql-tag';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import wrapQuery from '../wrapQuery';

interface GetParticipantUserParams {
    simulationCode: string;
    participantId: string;
}

interface GetParticipantUserData {
    getParticipantUser: ParticipantUser;
}

const query = gql`
    query getParticipantUser($simulationCode: String!, $participantId: String!) {
        getParticipantUser(simulationCode: $simulationCode, participantId: $participantId) {
            id
            fullName
            email
            marketingConsent
            createdAt
        }
    }
`;

export const getParticipantUser = wrapQuery<GetParticipantUserParams, GetParticipantUserData>(query);
