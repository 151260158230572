import { ParticipantUser } from 'polpeo-go-common/types';
import { map, values, includes } from 'ramda';
import { useMemo } from 'react';

export const usePersonaMatchesExistingParticipantFullName = (
    participants: Record<string, ParticipantUser>,
    persona?: string,
): boolean => {
    const personaMatchesExistingParticipantFullName = useMemo(() => {
        const participantFullNames = map((value) => value.fullName, values(participants));
        return includes(persona, participantFullNames);
    }, [participants, persona]);
    return personaMatchesExistingParticipantFullName;
};
