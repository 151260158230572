import * as t from 'io-ts';
import { optionalToUndefined } from '../../t';

enum ContentItemFieldTypeMaxLength {
    TEXT = 'TEXT',
    LONG_TEXT = 'LONG_TEXT',
}

enum ContentItemFieldTypeNoMaxLength {
    RICHTEXT = 'RICHTEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export const ContentItemFieldType = { ...ContentItemFieldTypeMaxLength, ...ContentItemFieldTypeNoMaxLength };
export type ContentItemFieldType = ContentItemFieldTypeMaxLength | ContentItemFieldTypeNoMaxLength;

const tContentItemFieldTypeMaxLength = t.keyof(ContentItemFieldTypeMaxLength);
const tContentItemFieldTypeNoMaxLength = t.keyof(ContentItemFieldTypeNoMaxLength);
export const tContentItemFieldType = t.keyof(ContentItemFieldType);

export const ContentItemField = t.union([
    t.intersection([
        t.type({
            name: t.string,
            type: tContentItemFieldTypeMaxLength,
        }),
        t.partial({
            displayName: optionalToUndefined(t.string),
            options: optionalToUndefined(
                t.type({
                    maxLength: t.number,
                }),
            ),
        }),
    ]),
    t.intersection([
        t.type({
            name: t.string,
            type: tContentItemFieldTypeNoMaxLength,
        }),
        t.partial({
            displayName: optionalToUndefined(t.string),
            options: optionalToUndefined(t.type({})),
        }),
    ]),
]);

export type ContentItemField = t.TypeOf<typeof ContentItemField>;
