import gql from 'graphql-tag';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import wrapMutation from '../wrapMutation';

interface EditStaffUserParams {
    staffUser: StaffUser;
}

interface EditStaffUserData {
    editStaffUser: StaffUser;
}

const mutation = gql`
    mutation editStaffUser($staffUser: StaffUserInput!) {
        editStaffUser(staffUser: $staffUser) {
            uuid
            name
            email
            role
            skillLevel
            permissionSet
            notes
            lastLogin
        }
    }
`;

export const editStaffUser = wrapMutation<EditStaffUserParams, EditStaffUserData>(mutation);
