import styled from 'styled-components/macro';

export const DocumentCard = styled.div`
    display: flex;
    align-items: center;

    button,
    svg {
        flex: 0 0 auto;
    }
`;
