import { reduce, last, forEach, assocPath, values, includes } from 'ramda';
import { pageTemplates } from '../components/templates/PageTemplate/templates';
import { getAncestorsForItem } from './getAncestorsForContentItem';
import { ContentItem, Team } from 'polpeo-go-common/types';
import { SimulationContent } from '../components/WithAdminState/adminState';
import { TeamNotifications } from './TeamNotifications';

export function setNotificationsForNewAdminItems(
    currentSimulationContent: SimulationContent,
    newContentItems: Record<string, ContentItem>,
    setNotifications: (
        value: (prevState: Record<string, TeamNotifications>) => Record<string, TeamNotifications>,
    ) => void,
    teamsToSetNotificationsFor: Record<string, Team>,
): void {
    const newContentItemsList = values(newContentItems);
    const addedContentItems: string[] = [];
    const contentItemListPerTeam = values(currentSimulationContent.contentItems);
    const contentItemList = reduce(
        (acc, itemsForTeam) => ({ ...acc, ...(itemsForTeam as Record<string, ContentItem>) }),
        {} as Record<string, ContentItem>,
        contentItemListPerTeam,
    );
    const allContentItems = { ...contentItemList, ...newContentItems };
    forEach((item) => {
        const page = currentSimulationContent.pages[item.content.pageUUID];
        const template = pageTemplates[page.templateUUID];
        if (page.showItemsAsIndividualPages && template.showItemsAsIndividualPages) {
            const ancestors = getAncestorsForItem(allContentItems, item);
            const itemToNotifyFor = last(ancestors) || item;
            setNotifications(assocPath([item.teamUUID, 'pages', itemToNotifyFor.uuid], true));
        } else if (!includes(page.uuid, addedContentItems)) {
            forEach((team) => {
                setNotifications(assocPath([team.uuid, 'pages', page.uuid], true));
            }, values(teamsToSetNotificationsFor));
            addedContentItems.push(page.uuid);
        }
    }, newContentItemsList);
}
