import gql from 'graphql-tag';
import { GraphQlPrepreparedEmailItem, GraphQlNewPrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface CreatePrepreparedEmailParams {
    prepreparedEmail: GraphQlNewPrepreparedEmailItem;
}

interface CreatePrepreparedEmailData {
    createPrepreparedEmail: GraphQlPrepreparedEmailItem;
}

const mutation = gql`
    mutation createPrepreparedEmail($prepreparedEmail: NewPrepreparedEmailInput!) {
        createPrepreparedEmail(prepreparedEmail: $prepreparedEmail) {
            type
            uuid
            simulationUUID
            parentUUID
            persona
            content {
                recipients
                subject
                message
            }
            released {
                at
                items
            }
        }
    }
`;

export const createPrepreparedEmail = wrapMutation<CreatePrepreparedEmailParams, CreatePrepreparedEmailData>(mutation);
