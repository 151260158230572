import gql from 'graphql-tag';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import wrapQuery from '../wrapQuery';

interface ListStaffUserData {
    listStaffUsers: StaffUser[];
}

const query = gql`
    query listStaffUsers {
        listStaffUsers {
            email
            name
            uuid
            notes
            role
            skillLevel
            permissionSet
            lastLogin
        }
    }
`;

export const listStaffUsers = wrapQuery<void, ListStaffUserData>(query);
