import React, { FC } from 'react';
import { useParams } from 'react-router';
import { ContentDetailsPage } from '../../../PageTemplate/ContentDetailsPage';
import { ContentListPage } from '../../../PageTemplate/ContentListPage';
import { pageTemplates, dummyData } from '../../../PageTemplate/templates';

export const PageTemplatePreviewPage: FC = () => {
    const { templateUUID, type } = useParams<{ templateUUID: string; type: string }>();
    const template = pageTemplates[templateUUID];
    const items = dummyData[templateUUID];
    const item = type === 'item' && items[0];

    return item ? (
        <ContentDetailsPage
            template={template}
            items={items}
            item={item}
            contentItemOptions={{ disableDetailsLink: true, disableManageMenu: true }}
        />
    ) : (
        <ContentListPage
            template={template}
            items={items}
            contentItemOptions={{ disableDetailsLink: true, disableManageMenu: true }}
        />
    );
};
