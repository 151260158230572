import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../../../bits/Buttons';
import { PrepreparedContentPickerModal } from './PrepreparedContentPickerModal';

interface PrepreparedContentPickerButtonProps {
    simulation: Simulation;
    onPrepreparedItemSelected: (item: PrepreparedContentItem | PrepreparedEmailItem) => void;
    defaultOpenTriggerGroups?: string[];
    disabled?: boolean;
    selectedItemUUIDs?: string[];
}
export const PrepreparedContentPickerButton: FC<PrepreparedContentPickerButtonProps> = ({
    simulation,
    onPrepreparedItemSelected,
    defaultOpenTriggerGroups,
    disabled,
    selectedItemUUIDs,
}) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <SecondaryButton disabled={disabled} onClick={() => setShowModal(true)}>
                Select Item
            </SecondaryButton>
            {showModal && (
                <PrepreparedContentPickerModal
                    simulation={simulation}
                    onPrepreparedItemSelected={(item) => {
                        onPrepreparedItemSelected(item);
                        setShowModal(false);
                    }}
                    onCancel={() => setShowModal(false)}
                    defaultOpenTriggerGroups={defaultOpenTriggerGroups}
                    selectedItemUUIDs={selectedItemUUIDs}
                />
            )}
        </>
    );
};
