import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../../../../../bits/Buttons';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { PrepreparedEmailFormModal } from '../../PrepreparedEmailFormModal';

interface NewPrepreparedEmailButtonProps {
    label?: string;
    parent?: PrepreparedEmailItem;
}
export const NewPrepreparedEmailButton: FC<NewPrepreparedEmailButtonProps> = ({ label, parent }) => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>
                {label || 'Add Preprepared Email Item'}
            </SecondaryButton>
            {showModal && (
                <PrepreparedEmailFormModal
                    simulation={currentSimulation}
                    onCancel={() => setShowModal(false)}
                    onSaveComplete={() => setShowModal(false)}
                    parent={parent}
                />
            )}
        </>
    );
};
