import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { includes } from 'ramda';
import React, { FC, useContext } from 'react';
import { useDrag } from 'react-dnd';
import styled from 'styled-components/macro';
import { grey6Colour, primaryColour } from '../../../../../themes/colours';
import { body1 } from '../../../../../themes/cssSnippets';
import { Display } from '../../../../bits/Display';
import { Checkbox } from '../../../../bits/FormFields';
import { Body3 } from '../../../../bits/Headers';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';

interface ParticipantCardProps {
    highlight?: boolean;
    disabled?: boolean;
}
const ParticipantCard = styled(Display.HorizontalWithSpacing).attrs(() => ({
    gap: 0,
    verticalCenter: true,
}))<ParticipantCardProps>`
    background: #ffffff;
    padding: 14px 20px;

    ${body1}
    color: ${grey6Colour};

    outline: ${({ highlight }) => (highlight ? `solid 2px ${primaryColour}` : 'none')};
    &&:hover {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
    }

    ${Display.VerticalWithSpacing} > * {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

interface DraggableParticipantCardProps {
    participant: ParticipantUser;
    selectedParticipants: ParticipantUser[];
    toggleParticipantSelection: (participant: ParticipantUser) => void;
    setSelectedParticipants: (participants: ParticipantUser[]) => void;
}
export const DraggableParticipantCard: FC<DraggableParticipantCardProps> = ({
    participant,
    selectedParticipants,
    toggleParticipantSelection,
    setSelectedParticipants,
}: DraggableParticipantCardProps) => {
    const { teamAssignments, currentSimulationTeams } = useContext(AdminInSimulationStateContext);
    const staffUser = useContext(StaffUserContext);
    const canUserEditTeamAssignments = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    const canDrag = !teamAssignments[participant.id]?.lockedOn && canUserEditTeamAssignments;
    const [, drag] = useDrag(
        () => ({
            type: 'PARTICIPANT_CARD',
            item: () => {
                if (!selected) {
                    setSelectedParticipants([participant]);
                    return [participant];
                }
                return selectedParticipants;
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
            canDrag: () => {
                return canDrag;
            },
        }),
        [selectedParticipants, participant],
    );

    const selected = canDrag && includes(participant, selectedParticipants);
    const preferredTeamName = participant.preferredTeam ? currentSimulationTeams[participant.preferredTeam]?.name : '';

    return (
        <>
            <ParticipantCard
                ref={drag}
                onClick={(e) => {
                    // We are reusing this onClick for the checkbox being clicked as well so
                    // preventDefault and stopPropagation so we don't call this multiple times
                    e.preventDefault();
                    e.stopPropagation();
                    if (canDrag) {
                        toggleParticipantSelection(participant);
                    }
                }}
                highlight={selected}
                disabled={!canDrag}
            >
                <Checkbox id={`${participant.id}_card`} checked={selected} readOnly />
                <Display.VerticalWithSpacing gap={0}>
                    <span>{participant.fullName}</span>
                    <Body3>{participant.email}</Body3>
                    {preferredTeamName && <Body3>Preferred team: {preferredTeamName}</Body3>}
                </Display.VerticalWithSpacing>
            </ParticipantCard>
        </>
    );
};
