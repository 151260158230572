import React, { FC } from 'react';
import { TextInput } from '.';
import { Body3 } from '../Headers';

type VideoEmbedInput = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

// Could make this better if we did some url validation but not sure how far we should take this
// Just check url shape? Also somehow check the url goes to a valid video?
export const VideoEmbedInput: FC<VideoEmbedInput> = (inputProps) => (
    <>
        <TextInput {...inputProps} />
        <Body3>
            Enter the url for the video eg: <br />
            https://www.youtube.com/watch?v=dQw4w9WgXcQ <br />
            https://vimeo.com/110607247 <br />
        </Body3>
    </>
);
