import { Moment } from 'polpeo-go-common/types/Moment';
import { append, ascend, includes, map, reduce, reject, sort, toPairs, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Collapsible } from '../../../patterns/Collapsible';
import { Modal, ModalProps } from '../../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { MomentsList } from './MomentsList';

type StaffMomentsModalProps = ModalProps;
export const StaffMomentsModal: FC<StaffMomentsModalProps> = (modalProps) => {
    const {
        teamsForCurrentStaffUser,
        simulationContent: { moments },
    } = useContext(AdminInSimulationStateContext);
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    const simMomentsByTeam = reduce(
        (acc, [teamUUID, momentsMap]) => ({ ...acc, [teamUUID]: values(momentsMap) as Moment[] }),
        {} as Record<string, Moment[]>,
        toPairs(moments || {}),
    );

    const makeToggleGroupCollapsedFunc = (id: string) => () => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups(append(id, expandedGroups));
        }
    };

    const sortedTeams = useMemo(() => {
        const alphabeticalTeams = sort(
            ascend((team) => team.name),
            values(teamsForCurrentStaffUser),
        );
        return alphabeticalTeams;
    }, [teamsForCurrentStaffUser]);

    return (
        <>
            <Modal header={'Highlights'} cardWidth={'80%'} {...modalProps}>
                {map((team) => {
                    const teamUUID = team.uuid;
                    const moments = simMomentsByTeam[teamUUID] || [];
                    return (
                        <Collapsible
                            title={`${team.name} (${moments.length} ${
                                moments.length === 1 ? 'highlight' : 'highlights'
                            })`}
                            open={includes(teamUUID, expandedGroups)}
                            toggleGroupCollapsed={makeToggleGroupCollapsedFunc(teamUUID)}
                        >
                            <MomentsList moments={moments} />
                        </Collapsible>
                    );
                }, sortedTeams)}
            </Modal>
        </>
    );
};
