import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { AmplifyConfig, ICredentials } from '@aws-amplify/core/lib-esm/types';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { clearSimulationCodeCookie } from '../../utils/simulationCodeCookie';
import WithChildren from '../../utils/WithChildren';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import { ConfigContext } from '../WithConfig/config';
import AuthContext, { AppUser, AuthContextValue } from './AuthContext';

export const WithAuth = (props: WithChildren): ReactElement => {
    const history = useHistory();
    const config = useContext(ConfigContext);
    const [currentCredentials, setCurrentCredentials] = useState<ICredentials>();
    const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState<AppUser>();

    const getCurrentUser = async (): Promise<AppUser | undefined> => {
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            return user;
        } catch (error) {
            if (error === 'The user is not authenticated') {
                return;
            } else {
                throw error;
            }
        }
    };

    const updateCurrentUserDetails = async () => {
        // if the users has set up MFA but has yet to put in their code
        // this will return that they aren't authenticated. Don't call
        // this function when the current user is signed in but their MFA is
        // not set up yet as it will put their "MFA less" credentials into context
        const user = await getCurrentUser();
        setCurrentAuthenticatedUser(user);

        const credentials = await Auth.currentUserCredentials();
        setCurrentCredentials(credentials);
    };

    useEffect(() => {
        const amplifyConfig: AmplifyConfig = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Auth: {
                region: 'eu-west-2',
                userPoolId: config.userPoolId,
                userPoolWebClientId: config.userPoolClientId,
                identityPoolId: config.identityPoolId,
            },
        };
        Amplify.configure(amplifyConfig);

        updateCurrentUserDetails();
    }, []);

    const signIn = async (email: string, password: string): Promise<AppUser> => {
        // With optional MFA a user can be signed in but not properly logged in
        // to the app due to not completing their MFA. If we have a user in this
        // state we want to log them out before logging another user in
        await Auth.signOut();
        const user = await Auth.signIn(email, password);
        // if challengeName is SOFTWARE_TOKEN_MFA then the user has set up MFA
        // if they haven't don't attempt to update the user details, this will be
        // done by submitTOTPCode
        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
            await updateCurrentUserDetails();
        }
        return user;
    };

    const setupTOTP = async (user: AppUser): Promise<string> => {
        return await Auth.setupTOTP(user);
    };

    const confirmSignInWithMFA = async (user: AppUser, code: string) => {
        await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
        await updateCurrentUserDetails();
    };

    const submitTOTPCode = async (user: AppUser, code: string) => {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, 'TOTP');
        await updateCurrentUserDetails();
        return user;
    };

    const getPreferredMFA = async (user: AppUser) => {
        return await Auth.getPreferredMFA(user, {
            bypassCache: false,
        });
    };

    const completeNewPassword = async (user: AppUser, newPassword: string) => {
        await Auth.completeNewPassword(user, newPassword);
        await updateCurrentUserDetails();
    };

    const signOutAuthenticatedUser = async () => {
        await Auth.signOut();
        await updateCurrentUserDetails();
        history.push('/');
    };

    const signOutUnauthenticatedUser = async () => {
        clearSimulationCodeCookie();
        window.localStorage.removeItem(`CognitoIdentityId-${config.identityPoolId}`);
        await updateCurrentUserDetails();
        history.push('/');
    };

    const sendCode = async (email: string) => {
        await Auth.forgotPassword(email);
    };

    const resetPassword = async (email: string, code: string, newPassword: string) => {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
    };

    if (!currentCredentials) {
        return <FullPageSplashLoadingScreen />;
    }

    const providerValue: AuthContextValue = {
        signOutAuthenticatedUser,
        signOutUnauthenticatedUser,
        setupTOTP,
        confirmSignInWithMFA,
        submitTOTPCode,
        getPreferredMFA,
        signIn,
        sendCode,
        resetPassword,
        currentCredentials,
        currentAuthenticatedUser,
        completeNewPassword,
    };

    return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>;
};
