import React, { FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';
import WithChildren from '../../utils/WithChildren';

interface DropZoneContainerProps {
    isDragAccept?: boolean;
    isDragReject?: boolean;
    isDragActive?: boolean;
}
const getColor = (props: DropZoneContainerProps) => {
    if (props.isDragAccept) {
        return 'green';
    }
    if (props.isDragReject) {
        return 'red';
    }
    if (props.isDragActive) {
        return 'blue';
    }
    return '#eeeeee';
};
const Container = styled.div<DropZoneContainerProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

interface DropZoneProps extends WithChildren {
    accept?: string | string[];
    disabled?: boolean;
    maxFiles?: number;
    maxSize?: number;
    minSize?: number;
    multiple?: boolean;
    onAcceptedFiles: (files: File[]) => void;
}
export const DropZone: FC<DropZoneProps> = ({ onAcceptedFiles, children, ...props }: DropZoneProps) => {
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone(props);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length) {
            onAcceptedFiles(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
            <input {...getInputProps()} />
            {children}
        </Container>
    );
};
