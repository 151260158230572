import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { evolve, flatten, includes, isNil, keys, map, pickAll, reduce, reject, trim, values } from 'ramda';
import { filterAdministrators } from '../../../../../../utils/filterStaff';
import { findStaffWithEmail } from '../../../../../../utils/findStaff';
import { DateTime } from 'luxon';

const isSimulationScheduledForDateInFuture = (simulationDate: Date | undefined) => {
    const currentSimulationStartDate = simulationDate && DateTime.fromJSDate(simulationDate);
    const defaultSelectedDate = DateTime.now();
    const isDateHistoric = currentSimulationStartDate && currentSimulationStartDate < defaultSelectedDate;
    return !isDateHistoric;
};

const simulationValidationTransformations = {
    name: (val: unknown) =>
        !val || typeof val !== 'string' || !val.length ? 'Simulation, name: name is required' : undefined,
    scheduledFor: (val: unknown) => {
        if (val && (!(val instanceof Date) || !val.toISOString)) {
            return 'Simulation, scheduledFor: date could not be parsed';
        }
        if (!isSimulationScheduledForDateInFuture(val as Date)) {
            return 'Simulation, scheduledFor: date is in the past';
        }
    },
    clientName: (val: unknown) =>
        !val || typeof val !== 'string' || !val.length ? 'Simulation, clientName: clientName is required' : undefined,
    emailDomainWhitelist: (val: unknown) => {
        if (!val) {
            return undefined;
        }
        if (!Array.isArray(val)) {
            return 'Simulation, administratorEmails: Supplied list of email domains was not an array';
        }
        return map(
            (x) =>
                // eslint-disable-next-line max-len
                `Simulation, emailDomainWhitelist: '${x}' is not a valid email domain, make sure the domain starts with the @ character`,
            reject((x) => includes('@', x), val),
        );
    },
    expectedNumberParticipants: (val: unknown) => {
        if (val && (typeof val !== 'number' || val < 0 || Math.round(val) !== val)) {
            // eslint-disable-next-line max-len
            return 'Simulation, expectedNumberParticipants: expected number of participants should be a positive, whole number';
        }

        return undefined;
    },
    administratorEmails: (val: unknown) => {
        if (!val) {
            return undefined;
        }
        if (!Array.isArray(val)) {
            return 'Simulation, administratorEmails: Supplied list of administrators was not an array';
        }
        const administratorEmails = map(trim, val);
        return map(
            (administratorEmail) =>
                `Simulation, administratorEmails: '${administratorEmail}' is not a valid email address`,
            reject((administratorEmail) => includes('@', administratorEmail), administratorEmails),
        );
    },
    showParticipantsHighlightsGraph: (val: unknown) => {
        if (typeof val !== 'boolean') {
            //eslint-disable-next-line max-len
            return 'Simulation, showParticipantsHighlightsGraph: value must be either "TRUE" or "FALSE"';
        }
    },
    createHighlightsPermission: (val: unknown) => {
        if (typeof val !== 'boolean') {
            //eslint-disable-next-line max-len
            return 'Simulation, createHighlightsPermission: value must be either "TRUE" or "FALSE"';
        }
    },
};

const checkAdministratorExistsForEmail = (emails: string[], staffUsers: StaffUser[]): string[] =>
    reduce<string, string[]>(
        (acc, email) => {
            if (!findStaffWithEmail(filterAdministrators(staffUsers), email)) {
                return [
                    ...acc,
                    // eslint-disable-next-line max-len
                    `Teams, administratorEmails: simulation administrator with email '${email}' could not be found. Please make sure this simulation administrator user already exists in the application`,
                ];
            }
            return acc;
        },
        [],
        emails,
    );

const checkMomentsPermissionCompatibility = (
    createHighlightsPermission: unknown,
    showParticipantsHighlightsGraph: unknown,
) => {
    if (!createHighlightsPermission && showParticipantsHighlightsGraph) {
        return ['Simulation: showParticipantsHighlightsGraph cannot be TRUE if createHighlightsPermission is FALSE'];
    }
    return [];
};

const getErrorsForSimulationSheet = (simulationData: Record<string, unknown>, staffUsers: StaffUser[]): string[] => {
    const fieldsToValidate = keys(simulationValidationTransformations);
    const errorMap = evolve(
        simulationValidationTransformations,
        pickAll<Record<string, unknown>, Record<string, string | string[]>>(fieldsToValidate, simulationData),
    );
    const errorMessages = reject(isNil, flatten(values(errorMap)));
    const administratorEmailList = (simulationData.administratorEmails as string[]) || [];
    return [
        ...errorMessages,
        ...checkAdministratorExistsForEmail(administratorEmailList, staffUsers),
        ...checkMomentsPermissionCompatibility(
            simulationData.createHighlightsPermission,
            simulationData.showParticipantsHighlightsGraph,
        ),
    ];
};

export default getErrorsForSimulationSheet;
