import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { grey1Colour, grey5Colour, whiteColour } from '../../../themes/colours';
import { SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { H1Heading } from '../../bits/Headers';
import { Icon } from '../../bits/Icon';
import { WhiteLogo } from '../../patterns/Logo';
import { StaffUserMenu } from '../../patterns/StaffUserMenu';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';

const Header = styled.header`
    position: sticky;
    top: 0;
    z-index: 900;

    background: ${grey5Colour};
    padding: 10px 30px;
    border-bottom: 1px solid ${grey1Colour};
`;

const InSimulationTitle = styled(H1Heading)`
    color: ${whiteColour};
`;

const ExitSimulationButton = styled(SecondaryButton)`
    color: ${whiteColour};
    background: none;

    &:hover {
        color: ${grey5Colour};
    }
`;

export const AdminInSimulationHeader = (): React.ReactElement => {
    const history = useHistory();
    const { simulation } = useContext(AdminInSimulationStateContext);
    return (
        <Header>
            <Display.HorizontalWithMaxSpaceBetween>
                <Display.HorizontalWithSpacing verticalCenter>
                    <WhiteLogo width={125} />
                    <InSimulationTitle>{simulation.name}</InSimulationTitle>
                    <ExitSimulationButton
                        onClick={() => {
                            history.push(`/admin/simulation/${simulation.uuid}/manage`);
                        }}
                    >
                        <Icon icon="close" height={15} width={15} /> Exit Simulation
                    </ExitSimulationButton>
                </Display.HorizontalWithSpacing>
                <StaffUserMenu />
            </Display.HorizontalWithMaxSpaceBetween>
        </Header>
    );
};
