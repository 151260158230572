import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import React, { FC, useState } from 'react';
import { PrimaryButton } from '../../bits/Buttons';
import { NewMomentModal } from './NewMomentModal';

type NewMomentButtonProps = {
    item: ContentItem;
};
export const NewMomentButton: FC<NewMomentButtonProps> = ({ item }) => {
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <PrimaryButton small onClick={() => setOpenModal(true)}>
                New Highlight
            </PrimaryButton>
            {!!openModal && <NewMomentModal onCloseModal={() => setOpenModal(false)} item={item} />}
        </>
    );
};
