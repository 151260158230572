import styled from 'styled-components/macro';
import { grey1Colour } from '../../themes/colours';

const makeFixedCardWidthValue = (value: string | number) => (typeof value === 'string' ? value : `${value}px`);

export interface CardContainerProps {
    cardWidth?: number | string;
    minCardWidth?: number | string;
    maxCardWidth?: number | string;
}
export const CardContainer = styled.div<CardContainerProps>`
    background: #ffffff;
    border: 1px solid ${grey1Colour};
    border-radius: 5px;

    width: ${({ cardWidth }) => (cardWidth ? makeFixedCardWidthValue(cardWidth) : 'auto')};
    ${({ cardWidth }) => (cardWidth ? 'flex: 0 1 auto;' : '')}
    ${({ minCardWidth }) => (minCardWidth ? `min-width: ${makeFixedCardWidthValue(minCardWidth)};` : '')}
    ${({ maxCardWidth }) => (maxCardWidth ? `max-width: ${makeFixedCardWidthValue(maxCardWidth)};` : '')}
`;

export const CardHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid ${grey1Colour};
`;

interface CardSectionProps {
    collapsed?: boolean;
}
export const CardSection = styled.div<CardSectionProps>`
    padding: ${({ collapsed }) => (collapsed ? 0 : '16px')};
    margin: 0 -16px;
    border-bottom: 1px solid ${grey1Colour};
`;

export const CardBody = styled.div`
    padding: 16px;

    ${CardSection}:first-child {
        margin-top: -16px;
        border-radius: 5px 5px 0 0;
    }

    ${CardSection}:last-child {
        margin-bottom: -16px;
        border-bottom: none;
        border-radius: 0 0 5px 5px;
    }
`;
