import { useApolloClient } from '@apollo/client';
import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { ContentItem, decodeContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem, decodeEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import {
    ParticipantTeamAssignments,
    decodeSimulationParticipantAssignments,
} from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { Team, TeamOverviewData } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import {
    any,
    assocPath,
    filter,
    findIndex,
    forEach,
    includes,
    isEmpty,
    keys,
    last,
    map,
    mapObjIndexed,
    pickBy,
    propEq,
    reduce,
    remove,
    update,
    values,
} from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { onWriteContentItem } from '../../graphql/contentItem';
import { onEmailItem } from '../../graphql/emailItem';
import { onMoment } from '../../graphql/moment/onMoment';
import { PrepreparedItemReleasedMeta, onReleasePrepreparedItem } from '../../graphql/onReleasePrepreparedItem';
import { onWritePage } from '../../graphql/pages/onWritePage';
import {
    getParticipantsForSimulation,
    onEditParticipantDetails,
    onParticipantJoinLobby,
} from '../../graphql/participantUsers';
import {
    getScratchPadDocuments,
    onDeleteScratchPadDocument,
    onWriteScratchPadDocument,
} from '../../graphql/scratchPad';
import {
    getTeamAssignments,
    onEditTeamAssignments,
    onEndSimulation,
    onLockTeams,
    onStartSimulation,
} from '../../graphql/simulations';
import { onEditTeam } from '../../graphql/teams';
import { onReleaseTrigger } from '../../graphql/triggers/onReleaseTrigger';
import { TeamNotifications } from '../../utils/TeamNotifications';
import WithChildren from '../../utils/WithChildren';
import { decodeMoment } from '../../utils/decodeMoment';
import { decodeTrigger } from '../../utils/decodeTrigger';
import { fetchContentItems } from '../../utils/fetchContentItems';
import { fetchEmailItems } from '../../utils/fetchEmailItems';
import { fetchSimulationContent, fetchedPagesAndPageOrder } from '../../utils/fetchSimulationContent';
import { getAncestorsForItem } from '../../utils/getAncestorsForContentItem';
import { getCreatorForItem } from '../../utils/getCreatorForItem';
import { getCurrentTrigger } from '../../utils/getCurrentTrigger';
import { setNotificationsForNewAdminItems } from '../../utils/setNotificationsForNewAdminItems';
import { AdminStateContext } from '../WithAdminState/adminState';
import { StaffUserContext } from '../WithStaffUser';
import { FullPageError } from '../patterns/FullPageError';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import { pageTemplates } from '../templates/PageTemplate/templates';
import { AdminInSimulationStateContext } from './adminInSimulationState';

export * from './adminInSimulationState';

export const WithAdminInSimulationState: FC<WithChildren> = ({ children }: WithChildren) => {
    const urlPathParams = useParams<{ simulationUUID: string; teamUUID: string }>();
    const { simulationUUID, teamUUID } = urlPathParams;
    const client = useApolloClient();
    const staffUser = useContext(StaffUserContext);
    const {
        simulations,
        setSimulation,
        teams: allTeams,
        setTeam,
        setTeams,
        participants: allParticipants,
        setParticipants: _setParticipants,
        teamAssignments: allTeamAssignments,
        setTeamAssignments: _setTeamAssignments,
        simulationContent,
        setSimulationContent,
        setTrigger,
        addContentItems: _addContentItems,
        addEmailItems: _addEmailItems,
        addMoments: _addMoments,
        setPages: _setPages,
        setPageOrder: _setPageOrder,
    } = useContext(AdminStateContext);
    const [scratchPadDocuments, setScratchPadDocuments] = useState<ScratchPadDocument[]>();

    // Misc app behaviour related state
    const [showManageTeamsPage, setShowManageTeamsPage] = useState(false);
    const [activityFilters, setActivityFilters] = useState<Array<'Participants' | 'Staff'>>(['Participants', 'Staff']);
    const [notifications, setNotifications] = useState<Record<string, TeamNotifications>>({});

    const currentSimulation = useMemo(() => simulations[simulationUUID], [simulations, simulationUUID]);
    const currentSimulationContent = useMemo(
        () => simulationContent[simulationUUID],
        [simulationContent, simulationUUID],
    );
    const currentSimulationTeams = useMemo(() => allTeams[simulationUUID], [allTeams, simulationUUID]);
    const currentParticipants = useMemo(
        () => allParticipants[currentSimulation.uuid],
        [allParticipants, currentSimulation],
    );
    const currentTeamAssignments = useMemo(
        () => allTeamAssignments[simulationUUID],
        [allTeamAssignments, simulationUUID],
    );
    const currentTrigger = getCurrentTrigger(currentSimulationContent?.triggers);
    const teamsForCurrentStaffUser = useMemo(() => {
        const isAdministrator = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
        if (isAdministrator) {
            return currentSimulationTeams;
        }
        const filtered = pickBy<Record<string, Team>, Record<string, Team>>(
            (team) => includes(staffUser.uuid, team.roleplayerUUIDs),
            currentSimulationTeams,
        );
        return filtered;
    }, [currentSimulationTeams]);
    const currentTeam = teamsForCurrentStaffUser[teamUUID];

    // Utils
    const setParticipants = useCallback(
        (participants: Record<string, ParticipantUser>) =>
            _setParticipants(currentSimulation.uuid, { ...currentParticipants, ...participants }),
        [currentSimulation, currentParticipants],
    );
    const updateTeamsWithNewTeamOverviewData = useCallback(
        (overviewData: TeamOverviewData) => {
            const { title, description, headerImage } = overviewData;
            if (title || description || headerImage) {
                const updatedTeams = reduce(
                    (acc, team) => {
                        return {
                            ...acc,
                            [team.uuid]: {
                                ...team,
                                overview: {
                                    title: title || team.overview?.title,
                                    description: description || team.overview?.description,
                                    headerImage: headerImage || team.overview?.headerImage,
                                },
                            },
                        };
                    },
                    {} as Record<string, Team>,
                    values(currentSimulationTeams),
                );
                setTeams({ ...allTeams, [currentSimulation.uuid]: updatedTeams });
            }
        },
        [currentSimulationTeams, allTeams, currentSimulation],
    );
    const fetchAndProcessItems = useCallback(
        async (
            newerThan?: string | Date,
            options?: { dontFetchContentItems?: boolean; dontFetchEmailItems?: boolean },
        ) => {
            const newContentItems = options?.dontFetchContentItems
                ? {}
                : await fetchContentItems(client, currentSimulation.uuid, newerThan);
            const newEmailItems = options?.dontFetchEmailItems
                ? {}
                : await fetchEmailItems(client, currentSimulation.uuid, newerThan);

            if (!isEmpty(newContentItems)) {
                addContentItems(newContentItems);

                const newContentItemsList = values(newContentItems);
                const hasUnknownCreator = any(
                    (newItem) => !getCreatorForItem(newItem, { participants: currentParticipants || {} }),
                    newContentItemsList,
                );
                if (hasUnknownCreator) {
                    refetchGetParticipantData();
                }
                setNotificationsForNewAdminItems(
                    currentSimulationContent,
                    newContentItems,
                    setNotifications,
                    teamsForCurrentStaffUser,
                );
            }
            if (!isEmpty(newEmailItems)) {
                _addEmailItems(currentSimulation.uuid, newEmailItems);
                forEach((team) => {
                    setNotifications(assocPath([team.uuid, 'emails'], true));
                }, values(teamsForCurrentStaffUser));
            }
        },
        [currentSimulation, currentParticipants, currentSimulationContent, pageTemplates, teamsForCurrentStaffUser],
    );
    const upateTeamsWithNewPageStatsData = useCallback(() => {
        const currentSimulationPages = currentSimulationContent.pages;

        const pageStats = mapObjIndexed((page) => page.stats, currentSimulationPages);
        const updatedTeams = mapObjIndexed((team) => ({ ...team, pageStats: pageStats }), currentSimulationTeams);
        setTeams({ ...allTeams, [currentSimulation.uuid]: updatedTeams });
    }, [currentSimulationContent, currentSimulationTeams, allTeams, currentSimulation]);

    // ScratchPadDocuments
    // this works slightly differently to the rest since on simulation start
    // these get updated in the backend, hence the need for them to be refetchable
    const [getScratchPadDocumentsQuery, { data: getScratchPadDocumentsData }] = getScratchPadDocuments.lazyHook({
        simulationUUID: simulationUUID,
    });
    useEffect(() => {
        if (getScratchPadDocumentsData) {
            setScratchPadDocuments(getScratchPadDocumentsData.getScratchPadDocuments);
        }
    }, [getScratchPadDocumentsData]);

    const { data: onWriteScratchPadDocumentData } = onWriteScratchPadDocument.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (onWriteScratchPadDocumentData?.onWriteScratchPadDocument) {
            const scratchPadDocumentData = onWriteScratchPadDocumentData.onWriteScratchPadDocument;
            if (teamsForCurrentStaffUser[scratchPadDocumentData.teamUUID]) {
                const existingDocIndex = findIndex(
                    propEq('uuid', scratchPadDocumentData.uuid),
                    scratchPadDocuments || [],
                );
                if (existingDocIndex >= 0) {
                    const newArr = update(existingDocIndex, scratchPadDocumentData, scratchPadDocuments || []);
                    setScratchPadDocuments(newArr);
                } else {
                    setScratchPadDocuments([...(scratchPadDocuments || []), scratchPadDocumentData]);
                }
                setNotifications(assocPath([scratchPadDocumentData.teamUUID, 'scratchpad'], true));
            }
        }
    }, [onWriteScratchPadDocumentData]);

    const { data: onDeleteScratchPadDocumentData } = onDeleteScratchPadDocument.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (onDeleteScratchPadDocumentData?.onDeleteScratchPadDocument) {
            const scratchPadDocumentData = onDeleteScratchPadDocumentData.onDeleteScratchPadDocument;
            if (teamsForCurrentStaffUser[scratchPadDocumentData.teamUUID] && scratchPadDocuments) {
                const existingDocIndex = findIndex(propEq('uuid', scratchPadDocumentData.uuid), scratchPadDocuments);
                if (existingDocIndex >= 0) {
                    const newArr = remove(existingDocIndex, 1, scratchPadDocuments);
                    setScratchPadDocuments(newArr);
                    setNotifications(assocPath([scratchPadDocumentData.teamUUID, 'scratchpad'], true));
                }
            }
        }
    }, [onDeleteScratchPadDocumentData]);

    // Participants
    const { data: GetParticipantsData, refetch: refetchGetParticipantData } = getParticipantsForSimulation.hook({
        simulationUUID: currentSimulation?.uuid,
    });
    useEffect(() => {
        if (GetParticipantsData?.getParticipantsForSimulation) {
            const incomingParticipantList = GetParticipantsData.getParticipantsForSimulation;
            const updatedParticipants = reduce<ParticipantUser, Record<string, ParticipantUser>>(
                (acc, participant) => {
                    return { ...acc, [participant.id]: participant };
                },
                currentParticipants || {},
                incomingParticipantList,
            );
            setParticipants(updatedParticipants);
        }
    }, [GetParticipantsData]);

    const { data: OnParticipantJoinLobbyData } = onParticipantJoinLobby.hook({
        variables: { simulationUUID: currentSimulation?.uuid },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnParticipantJoinLobbyData?.onParticipantJoinLobby) {
            refetchGetParticipantData();
            if (!showManageTeamsPage && currentSimulation.startedAt) {
                const canAdministerTeamAssignments = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
                if (canAdministerTeamAssignments) {
                    setNotifications(assocPath(['teamManagement'], true));
                }
            }
        }
    }, [OnParticipantJoinLobbyData]);
    useEffect(() => {
        if (showManageTeamsPage) {
            setNotifications(assocPath(['teamManagement'], false));
        }
    }, [showManageTeamsPage]);

    const { data: OnEditParticipantDetailsData } = onEditParticipantDetails.hook({});
    useEffect(() => {
        const updatedParticipant = OnEditParticipantDetailsData?.onEditParticipantDetails;
        if (updatedParticipant) {
            const isParticipantInCurrentSimulation = currentParticipants[updatedParticipant.id];
            if (isParticipantInCurrentSimulation) {
                setParticipants({ ...currentParticipants, [updatedParticipant.id]: updatedParticipant });
            }
        }
    }, [OnEditParticipantDetailsData]);

    // Team assignments
    const { data: GetTeamAssignmentsData, refetch: refetchGetTeamAssignmentsData } = getTeamAssignments.hook({
        simulationUUID: currentSimulation?.uuid,
    });
    const { data: onLockTeamsData } = onLockTeams.hook({
        variables: { simulationUUID: currentSimulation?.uuid },
        skip: !currentSimulation,
    });
    useEffect(() => {
        const data = onLockTeamsData?.onLockTeams || GetTeamAssignmentsData?.getTeamAssignments;
        if (data) {
            const teamAssignments = decodeSimulationParticipantAssignments({
                ...data,
                assignments: JSON.parse(data.assignments),
            });
            _setTeamAssignments(currentSimulation.uuid, teamAssignments.assignments);
        }
    }, [GetTeamAssignmentsData, onLockTeamsData]);
    const { data: OnEditTeamAssignmentsData } = onEditTeamAssignments.hook({
        variables: { simulationUUID: currentSimulation?.uuid },
        skip: !currentSimulation,
    });
    useEffect(() => {
        const data = OnEditTeamAssignmentsData?.onEditTeamAssignments;
        if (data) {
            const teamAssignments = decodeSimulationParticipantAssignments({
                ...data,
                assignments: JSON.parse(data.assignments),
            });
            _setTeamAssignments(currentSimulation.uuid, teamAssignments.assignments);
        }
    }, [OnEditTeamAssignmentsData]);

    // Simulation
    const { data: OnStartSimulationData } = onStartSimulation.hook({
        variables: { uuid: currentSimulation?.uuid },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnStartSimulationData?.onStartSimulation) {
            setSimulation({
                ...currentSimulation,
                startedAt: OnStartSimulationData.onStartSimulation.startedAt,
            });
            refetchGetTeamAssignmentsData();
            const { startingOverview } = currentSimulation || {};
            const { overviewChanges } = currentTrigger || {};
            const changesToUpdate = {
                title: overviewChanges?.title || startingOverview?.title,
                description: overviewChanges?.description || startingOverview?.description,
                headerImage: overviewChanges?.headerImage || startingOverview?.headerImage,
            };
            updateTeamsWithNewTeamOverviewData(changesToUpdate);
            upateTeamsWithNewPageStatsData();
        }
    }, [OnStartSimulationData]);
    const { data: onEndSimulationData } = onEndSimulation.hook({
        variables: { uuid: currentSimulation.uuid },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (onEndSimulationData?.onEndSimulation) {
            const { completedAt } = onEndSimulationData.onEndSimulation;
            setSimulation({ ...currentSimulation, completedAt });
            setNotifications({});
        }
    }, [onEndSimulationData]);

    // When trigger released
    const { data: OnReleaseTriggerData } = onReleaseTrigger.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (currentSimulation && OnReleaseTriggerData?.onReleaseTrigger) {
            const releasedTrigger = OnReleaseTriggerData.onReleaseTrigger.trigger;
            const processReleasedTriggerAndContentItems = async () => {
                await fetchAndProcessItems(releasedTrigger.releasedAt);
                updateTeamsWithNewTeamOverviewData(releasedTrigger.overviewChanges);
                setTrigger(
                    decodeTrigger({
                        ...releasedTrigger,
                        pageDressingChanges: JSON.parse(releasedTrigger.pageDressingChanges),
                    }),
                );
            };

            processReleasedTriggerAndContentItems();
        }
    }, [OnReleaseTriggerData]);
    // When individual preprepared item released
    const { data: OnReleasePrepreparedItemData } = onReleasePrepreparedItem.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (currentSimulation && OnReleasePrepreparedItemData?.onReleasePrepreparedItem) {
            // Don't just look at the item we released because parents of the item might have been released before it!
            const earliestReleasedItem = reduce(
                (acc, item) => {
                    const itemReleasedAt = item.released?.at || '0';
                    const accReleasedAt = acc.released?.at || '0';
                    return itemReleasedAt >= accReleasedAt ? acc : item;
                },
                OnReleasePrepreparedItemData.onReleasePrepreparedItem.item as PrepreparedItemReleasedMeta,
                OnReleasePrepreparedItemData.onReleasePrepreparedItem.items,
            );
            const processReleasedContentItems = async () => {
                const fetchContentItems = earliestReleasedItem.type === 'PAGE_CONTENT';
                const fetchEmailItems = earliestReleasedItem.type === 'EMAIL';

                await fetchAndProcessItems(earliestReleasedItem.released?.at, {
                    dontFetchContentItems: !fetchContentItems,
                    dontFetchEmailItems: !fetchEmailItems,
                });
            };

            processReleasedContentItems();
        }
    }, [OnReleasePrepreparedItemData]);
    const { data: OnWriteContentItemData } = onWriteContentItem.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnWriteContentItemData?.onWriteContentItem) {
            const data = OnWriteContentItemData.onWriteContentItem;
            const incomingContentItem = decodeContentItem({
                ...data,
                content: { ...data.content, data: JSON.parse(data.content.data) },
            });
            if (teamsForCurrentStaffUser[incomingContentItem.teamUUID]) {
                _addContentItems(currentSimulation.uuid, [incomingContentItem]);
                setNotificationsForNewAdminItems(
                    currentSimulationContent,
                    { [incomingContentItem.uuid]: incomingContentItem },
                    setNotifications,
                    teamsForCurrentStaffUser,
                );
            }
        }
    }, [OnWriteContentItemData]);
    const { data: OnEmailItemData } = onEmailItem.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnEmailItemData?.onEmailItem) {
            const data = OnEmailItemData.onEmailItem;
            const incomingEmailItem = decodeEmailItem(data);
            if (teamsForCurrentStaffUser[incomingEmailItem.teamUUID]) {
                _addEmailItems(currentSimulation.uuid, [incomingEmailItem]);
                setNotifications(assocPath([incomingEmailItem.teamUUID, 'emails'], true));
            }
        }
    }, [OnEmailItemData]);
    const { data: OnMomentData } = onMoment.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnMomentData?.onMoment) {
            const data = OnMomentData.onMoment;
            const incomingMoment = decodeMoment({
                ...data,
                contentItem: {
                    ...data.contentItem,
                    content: {
                        ...data.contentItem.content,
                        data: JSON.parse(data.contentItem.content.data),
                    },
                },
            });
            addMoments([incomingMoment]);
        }
    }, [OnMomentData]);
    const { data: OnEditTeamData } = onEditTeam.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnEditTeamData?.onEditTeam) {
            const incomingTeam = OnEditTeamData.onEditTeam;
            if (teamsForCurrentStaffUser[incomingTeam.uuid]) {
                setTeam({ ...incomingTeam, pageStats: JSON.parse(incomingTeam.pageStats) });
            }
        }
    }, [OnEditTeamData]);
    const { data: OnWritePageData } = onWritePage.hook({
        variables: { simulationUUID: currentSimulation?.uuid || '' },
        skip: !currentSimulation,
    });
    useEffect(() => {
        if (OnWritePageData?.onWritePage) {
            // asynchronously get and set the pages and page orders
            const fetchAndSetPages = async () => {
                const { pages, pageOrder } = await fetchedPagesAndPageOrder(client, simulationUUID);
                _setPages(currentSimulation.uuid, pages);
                _setPageOrder(currentSimulation.uuid, pageOrder);
            };
            fetchAndSetPages();
        }
    }, [OnWritePageData]);

    useEffect(() => {
        if (currentSimulation) {
            // asynchronously get all the content for a simulation
            // Main reason for this is because we need to be able to loop over getPrepreparedContents
            // for as long as needed to get all the items. This isn't done easily with the apollo hook
            const fetchAndSetSimulationContent = async () => {
                const content = await fetchSimulationContent(client, simulationUUID);
                setSimulationContent(simulationUUID, content);
            };

            fetchAndSetSimulationContent();
            getScratchPadDocumentsQuery();
        }
    }, [currentSimulation]);

    const getLinkUrlForContentItem = useCallback(
        (item: ContentItem) => {
            const contentItems = currentSimulationContent.contentItems[item.teamUUID];
            const rootItem = contentItems ? last(getAncestorsForItem(contentItems, item)) : undefined;
            const adminUrl = `/admin/simulation/${item.simulationUUID}/team/${item.teamUUID}/${item.content.pageUUID}/${
                rootItem?.uuid || item.uuid
            }${rootItem ? '#' + item.uuid : ''}`;
            return adminUrl;
        },
        [currentSimulationContent],
    );

    const getLinkUrlForEmailItem = useCallback((item: EmailItem) => {
        return `/admin/simulation/${item.simulationUUID}/team/${item.teamUUID}/?email_id=${item.uuid}`;
    }, []);

    const setTeamAssignments = useCallback(
        (assignments: ParticipantTeamAssignments) =>
            _setTeamAssignments(currentSimulation.uuid, { ...currentTeamAssignments, ...assignments }),
        [currentSimulation, currentTeamAssignments],
    );

    const addContentItems = useCallback(
        (contentItems: ContentItem[] | Record<string, ContentItem>) =>
            _addContentItems(currentSimulation.uuid, contentItems),
        [currentSimulation],
    );

    const addEmailItems = useCallback(
        (emailItems: EmailItem[] | Record<string, EmailItem>) => _addEmailItems(currentSimulation.uuid, emailItems),
        [currentSimulation],
    );

    const addMoments = useCallback(
        (moments: Moment[] | Record<string, Moment>) => _addMoments(currentSimulation.uuid, moments),
        [currentSimulation],
    );

    const updateTrigger = useCallback((trigger: Trigger) => setTrigger(trigger), [setTrigger]);

    const teamParticipants = useMemo(() => {
        // the types say these will always exist but they are undefined when the app is initialized
        if (currentTeamAssignments && currentParticipants && currentTeam) {
            const teamAssignmentsForSelectedTeam = filter((teamAssignment) => {
                return teamAssignment.teamUUID === currentTeam.uuid;
            }, currentTeamAssignments);
            const teamParticipants = keys(teamAssignmentsForSelectedTeam);
            return map((teamParticipant) => currentParticipants[teamParticipant], teamParticipants);
        }
        return [];
    }, [currentTeamAssignments, currentParticipants, currentTeam]);

    if (!currentSimulation) {
        return <FullPageError errorCode="404" message="Simulation could not be found" />;
    }

    if (!currentParticipants || !currentTeamAssignments || !currentSimulationContent) {
        return <FullPageSplashLoadingScreen />;
    }

    return (
        <AdminInSimulationStateContext.Provider
            value={{
                simulation: currentSimulation,
                setSimulation,
                simulationContent: currentSimulationContent,
                currentTrigger,
                updateTrigger,
                addContentItems,
                addEmailItems,
                addMoments,
                teamsForCurrentStaffUser,
                currentSimulationTeams,
                team: currentTeam,
                teamParticipants,
                setTeam,
                participants: currentParticipants,
                teamAssignments: currentTeamAssignments,
                setTeamAssignments,
                getLinkUrlForContentItem,
                getLinkUrlForEmailItem,
                urlPathParams,
                scratchPadDocuments,
                setScratchPadDocuments,
                showManageTeamsPage,
                setShowManageTeamsPage,
                activityFilters,
                setActivityFilters,
                notifications,
                setNotifications,
            }}
        >
            {children}
        </AdminInSimulationStateContext.Provider>
    );
};
