import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { optionalToUndefined } from 'polpeo-go-common/t';
import { NewTrigger } from 'polpeo-go-common/types/Trigger';
import {
    any,
    filter,
    find,
    flatten,
    fromPairs,
    KeyValuePair,
    map,
    omit,
    reduce,
    replace,
    toLower,
    toPairs,
    uniq,
    values,
} from 'ramda';
import XLSX from 'xlsx';
import { UploadedPage } from '../page/parsePagesSheet';
import getErrorsForTriggersSheet from './getErrorsForTriggersSheet';
import { cleanXlsxHtml } from '../cleanXlsxHtml';

export type UploadedTrigger = Omit<NewTrigger, 'simulationUUID'>;
interface ParseTriggersSuccessfulResults {
    triggers: UploadedTrigger[];
    errors: undefined;
    images: string[];
}
interface ParseTriggersUnsuccessfulResults {
    triggers: undefined;
    errors: string[];
}
type ParseTriggersResults = ParseTriggersSuccessfulResults | ParseTriggersUnsuccessfulResults;

const TriggersUpload = t.array(t.type(omit(['simulationUUID'], NewTrigger.props)));
const RowData = t.type({
    title: t.string,
    simulatedTime: optionalToUndefined(t.union([t.string, date, t.number])),
    optional: optionalToUndefined(t.string),
    showParticipantPopup: optionalToUndefined(t.string),
    participantPopupAlertText: optionalToUndefined(t.string),
    participantPopupContentLink: optionalToUndefined(t.union([t.string, t.number])),
    roleplayerAlertThatGoesToParticipants: optionalToUndefined(t.string),
    roleplayerGuidanceForRolePlayers: optionalToUndefined(t.string),
    roleplayerExpectedActionByParticipants: optionalToUndefined(t.string),
    overviewImage: optionalToUndefined(t.string),
    overviewTitle: optionalToUndefined(t.string),
    overviewDescription: optionalToUndefined(t.string),
});
export const parseTriggersSheet = (sheet: XLSX.WorkSheet, pages: UploadedPage[]): ParseTriggersResults => {
    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet);
    const sheetData = reduce<string[], Record<string, unknown>[]>(
        (acc, row) => {
            const maybeRowData = RowData.decode(row);
            if (isLeft(maybeRowData)) {
                console.error('row was not the correct trigger shape', row);
                return acc;
            }
            const rowData = maybeRowData.right;
            const pageDressingChanges = reduce(
                (acc, page) => {
                    // pairs look like [pageName_suffixImage, imageName]
                    const currentPageDressingPairs = filter<[string, string]>(
                        ([key]) =>
                            any(
                                (suffix) => `${page.name}_${suffix}` === key,
                                ['headerImage', 'leftSidebarImage', 'rightSidebarImage', 'footerImage'],
                            ),
                        toPairs(rowData),
                    );
                    if (currentPageDressingPairs.length) {
                        // pairs should look like [suffix, imageName]
                        const cleanedPairs = map(
                            ([key, value]) =>
                                [replace('Image', '', replace(`${page.name}_`, '', key)), value] as KeyValuePair<
                                    string,
                                    string
                                >,
                            currentPageDressingPairs,
                        );
                        return { ...acc, [page.name]: fromPairs(cleanedPairs) };
                    }
                    return acc;
                },
                {},
                pages,
            );
            const participantPopupAlertTextCell = find(
                (cell) => cell.v === rowData.participantPopupAlertText,
                values(sheet),
            );
            const staffPopupAlertTextCell = find(
                (cell) => cell.v === rowData.roleplayerAlertThatGoesToParticipants,
                values(sheet),
            );
            const staffPopupGuidanceCell = find(
                (cell) => cell.v === rowData.roleplayerGuidanceForRolePlayers,
                values(sheet),
            );
            const staffPopupExpectedActionsCell = find(
                (cell) => cell.v === rowData.roleplayerExpectedActionByParticipants,
                values(sheet),
            );

            // html || formatted text || empty string
            const cleanedHtmlParticipantPopupAlertText = cleanXlsxHtml(
                participantPopupAlertTextCell.h || participantPopupAlertTextCell.w || '',
            );
            const cleanedHtmlStaffPopupAlertText = cleanXlsxHtml(
                staffPopupAlertTextCell.h || staffPopupAlertTextCell.w || '',
            );
            const cleanedHtmlStaffPopupGuidance = cleanXlsxHtml(
                staffPopupGuidanceCell.h || staffPopupGuidanceCell.w || '',
            );
            const cleanedHtmlStaffPopupExpectedActions = cleanXlsxHtml(
                staffPopupExpectedActionsCell.h || staffPopupExpectedActionsCell.w || '',
            );
            const participantPopupContentLink = (participantPopupContentLink: number | string | undefined) => {
                if (participantPopupContentLink === undefined) {
                    return undefined;
                }
                return [
                    typeof participantPopupContentLink === 'number'
                        ? participantPopupContentLink.toString()
                        : participantPopupContentLink,
                ];
            };

            return [
                ...acc,
                {
                    title: rowData.title,
                    isOptional:
                        !!rowData.optional &&
                        (toLower(rowData.optional) === 'yes' || toLower(rowData.optional) === 'true'),
                    simulatedTime:
                        typeof rowData.simulatedTime === 'number'
                            ? rowData.simulatedTime.toString()
                            : rowData.simulatedTime,
                    participantPopupSettings: {
                        enabled: !!rowData.participantPopupAlertText || !!rowData.participantPopupContentLink,
                        text: cleanedHtmlParticipantPopupAlertText,

                        prepreparedContentItemUUID: participantPopupContentLink(rowData.participantPopupContentLink),
                    },
                    staffPopupSettings: {
                        directions: cleanedHtmlStaffPopupAlertText,
                        guidelines: cleanedHtmlStaffPopupGuidance,
                        expectedActions: cleanedHtmlStaffPopupExpectedActions,
                    },
                    overviewChanges: {
                        title: rowData.overviewTitle,
                        description: rowData.overviewDescription,
                        headerImage: rowData.overviewImage,
                    },
                    pageDressingChanges,
                },
            ];
        },
        [],
        sheetRows,
    );
    const maybeTriggers = TriggersUpload.decode(sheetData);

    // check for data shape
    if (isLeft(maybeTriggers)) {
        console.error('sheet was not the correct trigger shape', sheetData, maybeTriggers);
        return {
            triggers: undefined,
            errors: getErrorsForTriggersSheet(sheetData),
        };
    }
    const triggers = maybeTriggers.right;

    // Extra validation
    const errors = getErrorsForTriggersSheet(triggers);
    if (errors.length) {
        return {
            triggers: undefined,
            errors: errors,
        };
    }

    const images = reduce(
        (acc, trigger) => {
            const hasPageDressings = values(trigger.pageDressingChanges).length;
            const overviewImage = trigger.overviewChanges.headerImage;

            if (!hasPageDressings && !overviewImage) {
                return acc;
            }
            const pageDressings = map(
                (dressingsForOnePage) => values(dressingsForOnePage),
                values(trigger.pageDressingChanges),
            ) as unknown as string[];
            return uniq(flatten([...acc, ...pageDressings, ...(overviewImage ? [overviewImage] : [])]));
        },
        [] as string[],
        triggers,
    );

    return {
        triggers,
        errors: undefined,
        images,
    };
};
