import { isNil } from 'ramda';
import { StaffUser } from '../types/StaffUser';
import { PERMISSION_SET_MAP } from './sets';
import { PermissionKey, Permissions } from './types';

const getPermissionSet = (staffUser: StaffUser): Permissions => {
    const permissionSetKey = staffUser.permissionSet;
    const permissions = PERMISSION_SET_MAP[permissionSetKey];

    if (!permissions) {
        throw new Error(`No permission set found for key ${permissionSetKey}`);
    }

    return permissions;
};

export function getPermission<K extends PermissionKey>(permissionKey: K, staffUser: StaffUser): Permissions[K] {
    const permissions = getPermissionSet(staffUser);
    const permissionValue = permissions[permissionKey];

    if (isNil(permissionValue)) {
        throw new Error(`No permission value found for key "${permissionKey}"`);
    }

    return permissionValue;
}

export const userCan = (permissionKey: PermissionKey, staffUser: StaffUser): boolean => {
    const permission = getPermission(permissionKey, staffUser);
    return !!permission;
};

export * from './types';
