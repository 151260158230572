import gql from 'graphql-tag';
import { Team } from 'polpeo-go-common/types/Team';
import wrapMutation from '../wrapMutation';

interface DeleteTeamParams {
    team: Omit<Team, 'pageStats'> & { pageStats: string /* AWSJSON - Record<string, Record<string>> */ };
}

interface DeleteTeamData {
    deleteTeam: string;
}

const mutation = gql`
    mutation deleteTeam($team: TeamInput!) {
        deleteTeam(team: $team)
    }
`;

export const deleteTeam = wrapMutation<DeleteTeamParams, DeleteTeamData>(mutation);
