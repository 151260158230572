import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface ResendStaffUserTempPasswordParams {
    staffUserUUID: string;
}

interface ResendStaffUserTempPasswordData {
    staffUserUUID: string;
}

const mutation = gql`
    mutation resendStaffUserTempPassword($staffUserUUID: String!) {
        resendStaffUserTempPassword(staffUserUUID: $staffUserUUID)
    }
`;

export const resendStaffUserTempPassword =
    wrapMutation<ResendStaffUserTempPasswordParams, ResendStaffUserTempPasswordData>(mutation);
