import { NewParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import React, { FC, useEffect, useState } from 'react';
import { joinLobby } from '../../../../graphql/participantUsers';
import { Display } from '../../../bits/Display';
import { Logo } from '../../../patterns/Logo';
import { ParticipantDetailsForm } from '../ParticipantSimulation/ParticipantDetailsForm';
import { listTeamsBySimulationCode } from '../../../../graphql/simulations';

interface NewParticipantLobbyProps {
    simulationCode: string;
    onParticipantJoinedLobby: () => void;
}
export const NewParticipantLobby: FC<NewParticipantLobbyProps> = ({
    simulationCode,
    onParticipantJoinedLobby,
}: NewParticipantLobbyProps): React.ReactElement => {
    const [whiteListError, setWhiteListError] = useState<string>('');
    const [joinLobbyMutation, { data: joinLobbyData, loading: joinLobbyIsLoading, error: err }] = joinLobby.hook();
    const { data: listTeamsBySimulationData } = listTeamsBySimulationCode.hook({
        simulationCode: simulationCode,
    });
    const teams = listTeamsBySimulationData?.listTeamsBySimulationCode || [];

    useEffect(() => {
        if (err?.message === 'Participant email domain is not on the whitelist') {
            setWhiteListError(
                `The email entered has not been authorised to enter this simulation. 
                Please enter another or contact an admin for help`,
            );
        }
    });
    useEffect(() => {
        if (joinLobbyData?.joinLobby) {
            onParticipantJoinedLobby();
        }
    }, [joinLobbyData]);

    return (
        <Display.VerticalWithSpacing>
            <Logo width={125} />
            <ParticipantDetailsForm
                onSubmitParticipantDetails={(participantDetails: NewParticipantUser) =>
                    joinLobbyMutation({ variables: { simulationCode, participant: participantDetails } })
                }
                whiteListError={whiteListError}
                isLoading={joinLobbyIsLoading || joinLobbyData?.joinLobby}
                simulationCode={simulationCode}
                simulationTeams={teams}
            />
        </Display.VerticalWithSpacing>
    );
};
