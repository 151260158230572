import gql from 'graphql-tag';
import { NewEmailItem, EmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface CreateEmailItemParams {
    emailItem: NewEmailItem;
}

interface CreateEmailItemData {
    createEmailItem: EmailItem;
}

const mutation = gql`
    mutation createEmailItem($emailItem: NewEmailItemInput!) {
        createEmailItem(emailItem: $emailItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                recipients
                subject
                message
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
        }
    }
`;

export const createEmailItem = wrapMutation<CreateEmailItemParams, CreateEmailItemData>(mutation);
