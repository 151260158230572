import { useApolloClient } from '@apollo/client';
import { ContentItem, decodeContentItem, NewContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { ContentItemsListingPageLayoutType } from 'polpeo-go-common/types/PageTemplate';
import { all, cond, equals, last, map, mergeDeepRight, reduce, T, values } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createContentItem } from '../../../graphql/contentItem/createContentItem';
import { checkRequiredFields } from '../../../utils/checkRequiredFields';
import { getChildrenForItem } from '../../../utils/getChildrenForItem';
import { getContentItemRequiredFields } from '../../../utils/getContentItemRequiredFields';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { H2Heading } from '../../bits/Headers';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { EmbeddedContentItem } from '../../templates/PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { Card } from '../Card';
import { Modal } from '../Modal';
import { StaffContentItemForm } from './StaffContentItemForm';
import { usePersonaMatchesExistingParticipantFullName } from '../../hooks/usePersonaMatchesExistingParticipantFullName';

type NewStaffContentItemModalProps = {
    parent?: ContentItem;
    onCloseModal: () => void;
} & (
    | {
          page?: Page;
      }
    | {
          copyOf?: ContentItem;
      }
);

export const NewStaffContentItemModal: FC<NewStaffContentItemModalProps> = ({ parent, onCloseModal, ...props }) => {
    const client = useApolloClient();
    const page = 'page' in props ? props.page : undefined;
    const copyOf = 'copyOf' in props ? props.copyOf : undefined;
    const { teamUUID } = useParams<{ teamUUID: string }>();
    const { simulation, simulationContent, addContentItems, participants } = useContext(AdminInSimulationStateContext);
    const { pages } = simulationContent;

    const initialContentItem = {
        type: 'PAGE_CONTENT',
        simulationUUID: simulation.uuid,
        teamUUID: copyOf?.teamUUID || '',
        parentUUID: copyOf?.parentUUID || parent?.uuid,
        content: {
            pageUUID: copyOf?.content.pageUUID || page?.uuid || '',
            interactions: copyOf?.content.interactions || { positive: 0, negative: 0, shares: 0 },
            data: copyOf?.content.data || {},
        },
        persona: '',
        prepreparedContentUUID: copyOf?.prepreparedContentUUID || undefined,
        deleted: undefined,
    } as const;

    const [currentContentItem, setCurrentContentItem] = useState<NewContentItem>(initialContentItem);
    const initialTeams = parent ? [parent.teamUUID] : [teamUUID];
    const [currentTeams, setCurrentTeams] = useState(initialTeams);
    const [creatingContentItemsLoading, setCreatingContentItemsLoading] = useState(false);
    const currentPage = page || pages[currentContentItem.content.pageUUID] || '';
    const template = pageTemplates[currentPage.templateUUID];
    const requiredFields = template ? getContentItemRequiredFields(template, currentContentItem) : [];

    const personaMatchesExistingParticipantFullName = usePersonaMatchesExistingParticipantFullName(
        participants,
        currentContentItem.persona,
    );

    const isAllContentDataEmpty = all((value) => !value, values(currentContentItem.content.data));
    const canSave =
        currentContentItem.persona &&
        currentTeams.length &&
        !personaMatchesExistingParticipantFullName &&
        checkRequiredFields(requiredFields, currentContentItem).valid &&
        !isAllContentDataEmpty;
    const hasUnsavedChanges = !equals(currentContentItem, initialContentItem) || !equals(currentTeams, initialTeams);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    const canEditTeamsProps = !parent ? { currentTeams, setCurrentTeams } : {};

    const pageOptionItems = useMemo(
        () => [{ value: '', label: '' }, ...map((page) => ({ value: page.uuid, label: page.name }), values(pages))],
        [pages],
    );

    const contentItemType = cond([
        [() => !!template && !currentContentItem.parentUUID, () => template.rootItemOptions.name],
        [() => !!template && !!currentContentItem.parentUUID, () => template.childItemOptions.name],
        [T, () => 'Content Item'],
    ])();

    const itemToDisplay = useMemo(() => {
        const isChatPageType = template?.listingPage.layout.type === ContentItemsListingPageLayoutType.CHAT;
        const childItems =
            (parent && getChildrenForItem(simulationContent.contentItems[parent.teamUUID], parent)) || [];
        const lastChildItem = last(childItems);
        return isChatPageType && lastChildItem ? lastChildItem : parent;
    }, [parent, currentContentItem]);

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={800}>
                <Display.VerticalWithSpacing>
                    {!!itemToDisplay && (
                        <Card maxCardWidth={760}>
                            <EmbeddedContentItem
                                item={itemToDisplay}
                                contentItemOptions={{
                                    disableDetailsLink: true,
                                    disableManageMenu: true,
                                }}
                            />
                        </Card>
                    )}
                    <H2Heading>{`Create ${contentItemType}`}</H2Heading>
                    {creatingContentItemsLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!creatingContentItemsLoading && (
                        <StaffContentItemForm
                            pageOptionItems={!copyOf && !page ? pageOptionItems : undefined}
                            currentContentItem={currentContentItem}
                            errors={
                                personaMatchesExistingParticipantFullName
                                    ? { persona: "You cannot use a participant's name as a persona" }
                                    : {}
                            }
                            onChange={(fields) =>
                                setCurrentContentItem((oldValues) => mergeDeepRight(oldValues, fields))
                            }
                            {...canEditTeamsProps}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                    <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        disabled={!canSave}
                                        onClick={async () => {
                                            setCreatingContentItemsLoading(true);
                                            const newContentItemsResults = await Promise.all(
                                                map(
                                                    (teamUUID) =>
                                                        createContentItem.promise(client, {
                                                            contentItem: {
                                                                ...currentContentItem,
                                                                teamUUID,
                                                                content: {
                                                                    ...currentContentItem.content,
                                                                    data: JSON.stringify(
                                                                        currentContentItem.content.data,
                                                                    ),
                                                                },
                                                            },
                                                        }),
                                                    currentTeams,
                                                ),
                                            );
                                            const newContentItems = reduce(
                                                (acc, result) => {
                                                    if (result.data?.createContentItem) {
                                                        const itemData = result.data.createContentItem;
                                                        return [
                                                            ...acc,
                                                            decodeContentItem({
                                                                ...itemData,
                                                                content: {
                                                                    ...itemData.content,
                                                                    data: JSON.parse(itemData.content.data),
                                                                },
                                                            }),
                                                        ];
                                                    }
                                                    return acc;
                                                },
                                                [] as ContentItem[],
                                                newContentItemsResults,
                                            );
                                            addContentItems(newContentItems);

                                            setCreatingContentItemsLoading(false);
                                            onCloseModal();
                                        }}
                                    >
                                        Create
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    )}
                </Display.VerticalWithSpacing>
            </Modal>
        </>
    );
};
