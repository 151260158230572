import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Page } from 'polpeo-go-common/types/Page';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { ReleasedTrigger, Trigger } from 'polpeo-go-common/types/Trigger';
import { createContext } from 'react';
import { TeamNotifications } from '../../utils/TeamNotifications';

export interface ParticipantState {
    simulation: BasicSimulation;
    setSimulation: (simulation: BasicSimulation) => void;
    sidebar: { collapsed: boolean; toggle: () => void };
    participants: Record<string, ParticipantUser>;

    participantTeam?: Team;
    preliminaryParticipantTeam?: Team;
    triggers?: Trigger[];
    currentTrigger?: ReleasedTrigger;
    pages?: Page[];
    contentItems?: Record<string, ContentItem>;
    addContentItems: (contentItems: ContentItem[] | Record<string, ContentItem>) => void;
    emailItems?: Record<string, EmailItem>;
    addEmailItems: (emailItems: EmailItem[] | Record<string, EmailItem>) => void;
    getLinkUrlForContentItem: (item: ContentItem) => string;
    moments?: Record<string, Moment>;
    scratchPadDocuments?: ScratchPadDocument[];
    setScratchPadDocuments: (scratchPadDocuments: ScratchPadDocument[]) => void;

    notifications: TeamNotifications;
    setNotifications: (value: React.SetStateAction<TeamNotifications>) => void;
}

// No one should ever be accessing ParticipantState before it's been initialised anyways so we shouldn't
// provide any default values
export const ParticipantStateContext = createContext<ParticipantState>({} as ParticipantState);
