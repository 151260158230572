import React, { FC } from 'react';
import { Display } from '../bits/Display';
import { Body1, H1Heading } from '../bits/Headers';
import styled from 'styled-components/macro';

const ErrorMessage = styled(Body1)`
    text-align: center;
`;

type FullPageErrorProps = {
    errorCode?: string;
    message?: string;
};

export const FullPageError: FC<FullPageErrorProps> = ({ errorCode, message, children }) => (
    <Display.HorizontalCenterVerticalCenter>
        <Display.VerticalWithSpacing horizontalAlign="center">
            {errorCode && <H1Heading>{errorCode}</H1Heading>}
            {message && <ErrorMessage>{message}</ErrorMessage>}
            {children}
        </Display.VerticalWithSpacing>
    </Display.HorizontalCenterVerticalCenter>
);
