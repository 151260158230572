import gql from 'graphql-tag';
import { Page } from 'polpeo-go-common/types/Page';
import wrapMutation from '../wrapMutation';

interface EditPageParams {
    page: Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

interface EditPageData {
    editPage: Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

const mutation = gql`
    mutation editPage($page: PageInput!) {
        editPage(page: $page) {
            uuid
            simulationUUID
            templateUUID
            name
            stats
            dressing {
                header
                leftSidebar
                rightSidebar
                footer
            }
            createRootContentPermission
            showItemsAsIndividualPages
            participantDeleteContentPermission
            hiddenFromTeamUUIDs
        }
    }
`;

export const editPage = wrapMutation<EditPageParams, EditPageData>(mutation);
