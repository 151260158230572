import {
    NewPage,
    CreateRootContentPermissionTypeEnum,
    ParticipantDeleteContentPermissionTypeEnum,
} from 'polpeo-go-common/types/Page';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { equals } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { createPage } from '../../../../../graphql/pages';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { Spinner } from '../../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';
import { Card } from '../../../../patterns/Card';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { ManagePageForm } from './ManagePageForm';

const makeNewPage: (sim: Simulation) => NewPage = (sim) => ({
    simulationUUID: sim.uuid,
    templateUUID: '',
    name: '',
    dressing: {},
    stats: {},
    createRootContentPermission: CreateRootContentPermissionTypeEnum.STAFF,
    showItemsAsIndividualPages: false,
    participantDeleteContentPermission: ParticipantDeleteContentPermissionTypeEnum.CREATED,
    hiddenFromTeamUUIDs: [],
});

const PageContainer = styled.div`
    flex: 1 1 auto;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: start;
`;

export const NewPagePage: FC = () => {
    const history = useHistory();
    const { addPage } = useContext(AdminStateContext);
    const { currentSimulation } = useContext(AdminManageSimulationContext);

    const initialPage = makeNewPage(currentSimulation);
    const [currentPage, setCurrentPage] = useState<NewPage>(initialPage);

    const [createPageMutation, { data: createPageData, loading: createPageLoading }] = createPage.hook();

    useEffect(() => {
        if (createPageData) {
            const pageData = createPageData.createPage;
            const page = { ...pageData, stats: JSON.parse(pageData.stats) };
            addPage(page);
            history.push(`/admin/simulation/${currentSimulation.uuid}/manage/pages`);
        }
    }, [createPageData]);

    const canSubmit = !!currentPage.name && !!currentPage.templateUUID && !!currentPage.simulationUUID;
    const hasUnsavedChanges = !equals(currentPage, initialPage) && !createPageData?.createPage;
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    return (
        <PageContainer>
            <UnsavedPrompt />
            <Card header="New Page" cardWidth={800}>
                <Display.VerticalWithSpacing>
                    {createPageLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!createPageLoading && (
                        <ManagePageForm
                            simulation={currentSimulation}
                            currentPage={currentPage}
                            onSubmit={(fields) => {
                                const pageToSave = { ...currentPage, ...fields };
                                createPageMutation({
                                    variables: { page: { ...pageToSave, stats: JSON.stringify(pageToSave.stats) } },
                                });
                            }}
                            onChange={(fields) => setCurrentPage({ ...currentPage, ...fields })}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end">
                                    <SecondaryButton
                                        type="button"
                                        onClick={() =>
                                            history.push(`/admin/simulation/${currentSimulation.uuid}/manage/pages`)
                                        }
                                    >
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton type="submit" disabled={!canSubmit}>
                                        Create
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    )}
                </Display.VerticalWithSpacing>
            </Card>
        </PageContainer>
    );
};
