import gql from 'graphql-tag';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface DeleteEmailItemParams {
    emailItem: EmailItem;
}

interface DeleteEmailItemData {
    deleteEmailItem: EmailItem;
}

const mutation = gql`
    mutation deleteEmailItem($emailItem: EmailItemInput!) {
        deleteEmailItem(emailItem: $emailItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                recipients
                subject
                message
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
            deleted {
                at
                by {
                    uuid
                    type
                }
            }
        }
    }
`;

export const deleteEmailItem = wrapMutation<DeleteEmailItemParams, DeleteEmailItemData>(mutation);
