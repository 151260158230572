import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { IntFromString } from 'io-ts-types/lib/IntFromString';
import { optionalToUndefined } from 'polpeo-go-common/t';
import { NewSimulation } from 'polpeo-go-common/types/Simulation';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { flatten, head, map, reduce, split, tail, trim, uniq } from 'ramda';
import XLSX from 'xlsx';
import { findStaffWithEmail } from '../../../../../../utils/findStaff';
import getErrorsForSimulationSheet from './getErrorsForSimulationSheet';

export type UploadedSimulation = NewSimulation & { administratorEmails: string[] };
interface ParseSimulationSuccessfulResults {
    simulation: UploadedSimulation;
    errors: undefined;
}
interface ParseSimulationUnsuccessfulResults {
    simulation: undefined;
    errors: string[];
}
type ParseSimulationResults = ParseSimulationSuccessfulResults | ParseSimulationUnsuccessfulResults;

const SimulationUpload = t.exact(
    t.type({
        name: t.string,
        scheduledFor: optionalToUndefined(t.union([DateFromISOString, date])),
        clientName: t.string,
        emailDomainWhitelist: t.array(t.string),
        expectedNumberParticipants: optionalToUndefined(t.union([t.Int, IntFromString])),
        administratorEmails: t.array(t.string),
        startingOverviewImage: optionalToUndefined(t.string),
        startingOverviewTitle: optionalToUndefined(t.string),
        startingOverviewDescription: optionalToUndefined(t.string),
        createHighlightsPermission: t.boolean,
        showParticipantsHighlightsGraph: t.boolean,
    }),
);
export const parseSimulationSheet = (sheet: XLSX.WorkSheet, staffUsers: StaffUser[]): ParseSimulationResults => {
    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet, { header: 1 });
    const sheetData = reduce(
        (acc, row) => {
            const key = head(row);
            const value = tail(row);

            if (key && value) {
                if (key === 'emailDomainWhitelist' || key === 'administratorEmails') {
                    return { ...acc, [key]: map(trim, flatten(map((v) => split(',', v), value || []))) };
                }
                if (key === 'createHighlightsPermission' || key === 'showParticipantsHighlightsGraph') {
                    if (typeof value[0] === 'string') {
                        const val = value[0].toUpperCase();
                        if (val === 'TRUE' || val === 'FALSE') {
                            return { ...acc, [key]: val === 'TRUE' };
                        }
                    }
                }

                return { ...acc, [key]: head(value) };
            }

            return acc;
        },
        {
            administratorEmails: [],
            emailDomainWhitelist: [],
            expectedNumberParticipants: undefined,
        },
        sheetRows,
    );

    const maybeSimulation = SimulationUpload.decode(sheetData);

    // check for data shape
    if (isLeft(maybeSimulation)) {
        console.error('sheet was not the correct simulation shape', sheetData);
        return {
            simulation: undefined,
            errors: getErrorsForSimulationSheet(sheetData, staffUsers),
        };
    }
    const simRows = maybeSimulation.right;

    // Extra validation
    const errors = getErrorsForSimulationSheet(simRows, staffUsers);
    if (errors.length) {
        return {
            simulation: undefined,
            errors: errors,
        };
    }

    const administratorUUIDs = map<string, string>((email) => {
        const staff = findStaffWithEmail(staffUsers, email);
        if (staff) {
            return staff.uuid;
        }
        throw new Error(`Staff user with email '${email}' could not be found during spreadsheet parsing`);
    }, simRows.administratorEmails);

    return {
        simulation: {
            name: simRows.name,
            clientName: simRows.clientName,
            expectedNumberParticipants: simRows.expectedNumberParticipants,
            scheduledFor: simRows.scheduledFor ? new Date(simRows.scheduledFor) : undefined,
            emailDomainWhitelist: uniq(simRows.emailDomainWhitelist),
            administratorUUIDs,
            administratorEmails: simRows.administratorEmails,
            startingOverview: {
                ...(simRows.startingOverviewImage ? { headerImage: simRows.startingOverviewImage } : {}),
                ...(simRows.startingOverviewTitle ? { title: simRows.startingOverviewTitle } : {}),
                ...(simRows.startingOverviewDescription ? { description: simRows.startingOverviewDescription } : {}),
            },
            createMomentsPermission: simRows.createHighlightsPermission ? ['ADMIN', 'ROLEPLAYER'] : [],
            showParticipantsMomentsGraph: simRows.showParticipantsHighlightsGraph,
        },
        errors: undefined,
    };
};
