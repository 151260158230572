import { NewPrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { T, all, cond, equals, map, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { updateTriggerPrepreparedContentLinks } from '../../../../../../graphql/triggers';
import { checkRequiredFields } from '../../../../../../utils/checkRequiredFields';
import { getContentItemFields } from '../../../../../../utils/getContentItemFields';
import { getContentItemRequiredFields } from '../../../../../../utils/getContentItemRequiredFields';
import { getTriggerForPrepreparedItem } from '../../../../../../utils/getTriggerForPrepreparedItem';
import { AdminManageSimulationContext } from '../../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { PrimaryButton, SecondaryButton } from '../../../../../bits/Buttons';
import { Display } from '../../../../../bits/Display';
import { H2Heading } from '../../../../../bits/Headers';
import { Spinner } from '../../../../../bits/Spinner';
// eslint-disable-next-line max-len
import { usePersonaMatchesExistingParticipantFullName } from '../../../../../hooks/usePersonaMatchesExistingParticipantFullName';
import { useTriggerOptionItems } from '../../../../../hooks/useTriggerOptionItems';
import { useUnsavedWorkPrompt } from '../../../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../../../patterns/Modal';
import { FormGrid } from '../../../../FormGrid';
import { pageTemplates } from '../../../../PageTemplate/templates';
import { SlimTabs } from '../../../../SlimTabs';
import { makeContentTabFormFields } from './makeContentTabFormFields';
import { makeInitialState } from './makeInitialState';
import { makeSetupTabFormFields } from './makeSetupTabFormFields';
import { useSavePrepreparedContent } from './useSavePrepreparedContent';
import getTeamOptionItems from '../../../../../../utils/getTeamOptionItems';

const FormGridSlimTabs = styled(SlimTabs)`
    width: 100%;
    margin: 16px -16px -16px -16px;
    border-bottom: none;
`;

const tabs = ['Setup', 'Content'];

interface PrepreparedContentFormProps {
    simulation: Simulation;
    onCancel: () => void;
    onSaveComplete: () => void;
    item?: PrepreparedContentItem;
    parent?: PrepreparedContentItem;
    copyOf?: PrepreparedContentItem;
}

export const PrepreparedContentFormModal: FC<PrepreparedContentFormProps> = ({
    simulation,
    onCancel,
    onSaveComplete,
    ...options
}) => {
    const {
        participants: allParticipants,
        setPrepreparedContent,
        setTriggerPrepreparedContentLinks,
    } = useContext(AdminStateContext);
    const { currentSimulationContent, currentSimulationTeams } = useContext(AdminManageSimulationContext);
    const { triggers, triggerPrepreparedContentLinks, pages, triggerOrder } = currentSimulationContent;
    const participants = allParticipants[simulation.uuid];

    const initialPrepreparedContent = makeInitialState(simulation, options);
    // eslint-disable-next-line prettier/prettier, max-len
    const [currentPrepreparedContent, setCurrentPrepreparedContent] = useState<
        NewPrepreparedContentItem | PrepreparedContentItem
    >(initialPrepreparedContent);

    const maybeInitialLinkedToTrigger = getTriggerForPrepreparedItem(
        triggers,
        triggerPrepreparedContentLinks,
        options.item || options.copyOf || options.parent,
    );

    const initialLinkedToTriggerUUID = cond([
        [() => !!options.parent && !!maybeInitialLinkedToTrigger?.releasedAt, () => ''],
        [() => !!options.copyOf && !!maybeInitialLinkedToTrigger?.releasedAt, () => ''],
        [T, () => maybeInitialLinkedToTrigger?.uuid || ''],
    ])();

    const [currentLinkedToTrigger, setCurrentLinkedToTrigger] = useState<string>(initialLinkedToTriggerUUID);
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const [
        savePrepreparedContentMutation,
        { data: savePrepreparedContentData, loading: savePrepreparedContentLoading },
    ] = useSavePrepreparedContent();

    const [
        updateTriggerPrepreparedContentLinksMutation,
        { data: updateTriggerPrepreparedContentLinksData, loading: updateTriggerPrepreparedContentLinksLoading },
    ] = updateTriggerPrepreparedContentLinks.hook();

    const pageOptionItems = useMemo(
        () => [{ value: '', label: '' }, ...map((page) => ({ value: page.uuid, label: page.name }), values(pages))],
        [pages],
    );
    const triggerOptionItems = useTriggerOptionItems(triggers, triggerOrder);

    const teamOptionItems = useMemo(
        () => getTeamOptionItems({ currentSimulationTeams, allowAll: true }),
        [currentSimulationTeams],
    );

    const { currentTemplate, currentFields, requiredFields } = useMemo(() => {
        const currentPage = currentPrepreparedContent.content.pageUUID
            ? pages[currentPrepreparedContent.content.pageUUID]
            : undefined;
        const currentTemplate = currentPage ? pageTemplates[currentPage.templateUUID] : undefined;
        const currentFields = currentTemplate ? getContentItemFields(currentTemplate, currentPrepreparedContent) : [];
        const requiredFields = currentTemplate
            ? getContentItemRequiredFields(currentTemplate, currentPrepreparedContent)
            : [];
        return { currentPage, currentTemplate, currentFields, requiredFields };
    }, [currentPrepreparedContent.content.pageUUID]);

    useEffect(() => {
        const updatedTriggerLinks = updateTriggerPrepreparedContentLinksData?.updateTriggerPrepreparedContentLinks;

        // Saved Item but not yet updated trigger links
        if (savePrepreparedContentData && !updatedTriggerLinks && !updateTriggerPrepreparedContentLinksLoading) {
            updateTriggerPrepreparedContentLinksMutation({
                variables: {
                    simulationUUID: simulation.uuid,
                    newLinks: JSON.stringify({ [savePrepreparedContentData.uuid]: currentLinkedToTrigger }),
                },
            });
        }

        // Saved item and updated trigger links
        if (savePrepreparedContentData && updatedTriggerLinks) {
            setPrepreparedContent(savePrepreparedContentData);
            setTriggerPrepreparedContentLinks(simulation.uuid, JSON.parse(updatedTriggerLinks));
            onSaveComplete();
        }
    }, [
        savePrepreparedContentData,
        updateTriggerPrepreparedContentLinksData,
        updateTriggerPrepreparedContentLinksLoading,
    ]);

    const personaMatchesExistingParticipantFullName = usePersonaMatchesExistingParticipantFullName(
        participants,
        currentPrepreparedContent.persona,
    );

    const currentTabFormFields = cond([
        [
            () => currentTab === 'Setup',
            () =>
                makeSetupTabFormFields(
                    pageOptionItems,
                    triggerOptionItems,
                    teamOptionItems,
                    currentPrepreparedContent,
                    setCurrentPrepreparedContent,
                    currentLinkedToTrigger,
                    setCurrentLinkedToTrigger,
                    currentTemplate,
                    personaMatchesExistingParticipantFullName
                        ? { persona: "You cannot use a participant's name as a persona" }
                        : {},
                ),
        ],
        [
            () => currentTab === 'Content',
            () =>
                makeContentTabFormFields(
                    simulation,
                    currentFields,
                    currentPrepreparedContent,
                    setCurrentPrepreparedContent,
                ),
        ],
    ])();

    const isAllContentDataEmpty = all((value) => !value, values(currentPrepreparedContent.content.data));
    const canSave =
        currentPrepreparedContent.content.pageUUID &&
        currentPrepreparedContent.persona &&
        !personaMatchesExistingParticipantFullName &&
        checkRequiredFields(requiredFields, currentPrepreparedContent).valid &&
        !isAllContentDataEmpty;

    const hasUnsavedChanges =
        (!equals(currentPrepreparedContent, initialPrepreparedContent) ||
            currentLinkedToTrigger !== initialLinkedToTriggerUUID) &&
        !savePrepreparedContentData;
    const { wrappedFunc: onCancelUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCancel,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onCancelUnsavedPrompt} cardWidth={800}>
                {(savePrepreparedContentLoading || updateTriggerPrepreparedContentLinksLoading) && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!savePrepreparedContentLoading && !updateTriggerPrepreparedContentLinksLoading && (
                    <>
                        <H2Heading>{options.item ? 'Edit' : 'Create'} Preprepared Content</H2Heading>
                        <FormGrid
                            header={
                                <FormGridSlimTabs
                                    tabs={map((tab) => [tab, undefined], tabs)}
                                    activeTab={currentTab}
                                    onChangeTab={setCurrentTab}
                                    disabledTabs={currentPrepreparedContent.content.pageUUID ? [] : ['Content']}
                                />
                            }
                            fields={currentTabFormFields}
                            footer={
                                <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                    {currentPrepreparedContent.released &&
                                        // eslint-disable-next-line max-len
                                        `This item was released at: ${currentPrepreparedContent.released.at.toISOString()}`}
                                    <SecondaryButton onClick={onCancelUnsavedPrompt}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        disabled={!canSave || !!currentPrepreparedContent.released}
                                        onClick={() => {
                                            savePrepreparedContentMutation(currentPrepreparedContent);
                                        }}
                                    >
                                        Save
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            }
                        />
                    </>
                )}
            </Modal>
        </>
    );
};
