import styled from 'styled-components/macro';
import { grey1Colour } from '../../themes/colours';
import { formLabel } from '../../themes/cssSnippets';

export const NotificationBanner = styled.div`
    background: ${grey1Colour};
    text-transform: uppercase;

    padding: 10px;

    ${formLabel}
`;
