import styled from 'styled-components/macro';
import { primaryColour, grey2Colour } from '../../../themes/colours';
import { body1 } from '../../../themes/cssSnippets';

interface TextAreaProps {
    validationFailed?: boolean;
}
export const TextArea = styled.textarea`
    width: 100%;
    height: 100%;
    padding: 20px 16px;
    resize: none;

    ${body1}

    border: 1px solid ${(props: TextAreaProps) => (props.validationFailed ? 'red' : grey2Colour)};
    border-radius: 5px;

    &:focus {
        border: 1px solid ${primaryColour};
    }
`;
