import { filter, find, values } from 'ramda';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FullPageError } from '../../../../patterns/FullPageError';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { ContentListPage } from '../../../PageTemplate/ContentListPage';

export const PageContentList = (): React.ReactElement => {
    const { pageUUID } = useParams<{ pageUUID: string }>();
    const { pages = [], contentItems = {}, currentTrigger, participantTeam } = useContext(ParticipantStateContext);
    const page = find((p) => p.uuid === pageUUID, pages);

    const items = useMemo(() => {
        return page ? filter((item) => item.content.pageUUID === page.uuid, values(contentItems)) : null;
    }, [page, contentItems]);

    if (!page || !items) {
        return <FullPageError errorCode="404" message="This page could not be found" />;
    }

    return (
        <ContentListPage
            page={page}
            team={participantTeam}
            items={items}
            trigger={currentTrigger}
            contentItemOptions={{ hideCreatedByIcon: true }}
        />
    );
};
