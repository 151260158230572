import { EmailItem, decodeEmailItem } from 'polpeo-go-common/types/EmailItem';
import React, { FC, useContext, useEffect } from 'react';
import { deleteEmailItem } from '../../../graphql/emailItem';
import { Body2 } from '../../bits/Headers';
import { DeleteModal } from '../../templates/DeleteModal';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { EmbeddedEmailItem } from '../../patterns/EmbeddedEmailItem';

interface DeleteEmailItemModalProps {
    item: EmailItem;
    onCloseModal: () => void;
}
export const DeleteEmailItemModal: FC<DeleteEmailItemModalProps> = ({ item, onCloseModal }) => {
    const adminState = useContext(AdminInSimulationStateContext);
    const participantState = useContext(ParticipantStateContext);

    const [deleteEmailItemMutation, { data: deleteEmailItemData, loading: deleteEmailItemsLoading }] =
        deleteEmailItem.hook();
    useEffect(() => {
        if (deleteEmailItemData?.deleteEmailItem) {
            const addEmailItems = adminState.addEmailItems || participantState.addEmailItems;

            if (!addEmailItems) {
                throw new Error(`Could not fund function addEmailItems for staff or participant state`);
            }
            addEmailItems([decodeEmailItem(deleteEmailItemData?.deleteEmailItem)]);
            onCloseModal();
        }
    }, [deleteEmailItemData]);

    return (
        <DeleteModal
            header="Delete Email"
            onModalClose={onCloseModal}
            confirmationText={
                <>
                    Are you sure you want to delete this item?
                    <EmbeddedEmailItem item={item} />
                    <Body2 destructive>
                        <b>This action cannot be undone.</b>
                    </Body2>
                </>
            }
            onConfirmDelete={() =>
                deleteEmailItemMutation({
                    variables: {
                        emailItem: item,
                    },
                })
            }
            showSpinner={deleteEmailItemsLoading}
        />
    );
};
