import { ScratchPadCategory } from 'polpeo-go-common/types/ScratchPadDocument';
import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { Icon } from '../../../bits/Icon';
import { AddInitialDocumentModal } from './AddInitialDocumentModal';
import { InitialRichTextDocumentModal } from './InitialRichTextDocumentModal';

interface AddInitialDocumentButtonProps {
    category: ScratchPadCategory;
}

export const AddInitialDocumentButton: FC<AddInitialDocumentButtonProps> = ({ category }) => {
    const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
    const [showRichTextDocumentEditorModal, setShowRichTextDocumentEditorModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowAddDocumentModal(true)} small>
                <Icon icon="add" size={15} />
                Add Document
            </SecondaryButton>
            {showAddDocumentModal && (
                <AddInitialDocumentModal
                    onCloseModal={() => setShowAddDocumentModal(false)}
                    onNewRichtextScratchpadDocument={() => setShowRichTextDocumentEditorModal(true)}
                    category={category}
                />
            )}
            {showRichTextDocumentEditorModal && (
                <InitialRichTextDocumentModal
                    onCloseModal={() => setShowRichTextDocumentEditorModal(false)}
                    category={category}
                />
            )}
        </>
    );
};
