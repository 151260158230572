import React, { FC } from 'react';
import { IconSvg, IconSvgProps } from './Icon/IconSvg';

// Adapted from https://samherbert.net/svg-loaders/
export const Spinner: FC<IconSvgProps> = ({ stroke = '#000000', width, height, size }) => (
    <IconSvg width={size || width || 50} height={size || height || 50} viewBox="0 0 42 42" stroke={stroke}>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth="3">
                <circle strokeOpacity=".5" cx="19" cy="19" r="18" />
                <path d="M37 19c0-9.94-8.06-18-18-18">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 19 19"
                        to="360 19 19"
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </path>
            </g>
        </g>
    </IconSvg>
);
