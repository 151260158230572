import {
    NewScratchPadDocument,
    ScratchPadCategory,
    ScratchPadDocument,
} from 'polpeo-go-common/types/ScratchPadDocument';
import { assocPath, equals, filter, find } from 'ramda';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { createScratchPadDocument } from '../../../../graphql/scratchPad/createScratchPadDocument';
import { editScratchPadDocument } from '../../../../graphql/scratchPad/editScratchPadDocument';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { CardSection } from '../../../bits/Card';
import { Display } from '../../../bits/Display';
import { TextInput } from '../../../bits/FormFields';
import { Body1, Body2 } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../patterns/Modal';
import RichtextInput from '../../../patterns/RichtextInput';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../../WithParticipantState';
import { ParticipantUserContext } from '../../../WithParticipantUser';
import { StaffUserContext } from '../../../WithStaffUser';
import { DocumentUpdatedModal } from './DocumentUpdatedModal';

interface RichTextDocumentFormModalProps {
    onCloseModal: () => void;
    category: ScratchPadCategory;
    documentToEdit?: ScratchPadDocument;
}

export const RichTextDocumentFormModal: FC<RichTextDocumentFormModalProps> = ({
    onCloseModal,
    category,
    documentToEdit,
}) => {
    const {
        simulation: adminSimulation,
        participants: adminParticipants,
        setScratchPadDocuments: setAdminScratchPadDocuments,
        scratchPadDocuments: adminScratchPadDocuments,
        urlPathParams,
    } = useContext(AdminInSimulationStateContext);
    const staffUser = useContext(StaffUserContext);

    const {
        simulation: participantSimulation,
        participantTeam,
        participants: participantParticipants,
        scratchPadDocuments: participantScratchPadDocuments,
        setScratchPadDocuments: setParticipantScratchPadDocuments,
    } = useContext(ParticipantStateContext);
    const { participantUser } = useContext(ParticipantUserContext);

    const adminTeam = urlPathParams?.teamUUID;
    const teamUUID = adminTeam || participantTeam?.uuid;
    const imageUploadSimulation = adminSimulation || participantSimulation;
    const simulationUUID = imageUploadSimulation.uuid;
    const participants = adminParticipants || participantParticipants;

    const currentUserId = staffUser?.uuid || participantUser.id;

    if (!simulationUUID || !teamUUID) {
        throw new Error('Participant team or simulation could not be found');
    }

    const initialDoc = documentToEdit || {
        simulationUUID: simulationUUID,
        teamUUID: teamUUID,
        category: category,
        path: undefined,
        name: '',
        notes: '',
        currentEditor: undefined,
    };

    const initialDocumentRef = useRef(initialDoc);

    const [currentDocument, setCurrentDocument] = useState<ScratchPadDocument | NewScratchPadDocument>(
        initialDocumentRef.current,
    );
    const [closeAfterSave, setCloseAfterSave] = useState(false);
    const [showDocUpdatedModal, setShowDocUpdatedModal] = useState(false);

    const currentDocsForTeam = filter(
        (doc: ScratchPadDocument) => doc.teamUUID === teamUUID,
        adminScratchPadDocuments || [],
    );

    const currentDocs = participantScratchPadDocuments || currentDocsForTeam || [];
    const documentUUID = 'uuid' in currentDocument ? currentDocument.uuid : '';
    const documentNotes = currentDocument.notes || '';

    const doesFileNameAlreadyExist = find(
        (doc) => doc.category === category && doc.name === currentDocument?.name && doc.uuid !== documentUUID,
        currentDocs || [],
    );

    const canSaveDocument = currentDocument?.name.length > 0 && documentNotes.length > 0 && !doesFileNameAlreadyExist;

    const [editScratchPadDocumentMutation, { data: editScratchPadData, loading: editScratchPadLoading }] =
        editScratchPadDocument.hook();

    const [createScratchPadDocumentMutation, { data: createScratchPadData, loading: createScratchPadLoading }] =
        createScratchPadDocument.hook();

    useEffect(() => {
        const setScratchPadDocuments = setAdminScratchPadDocuments || setParticipantScratchPadDocuments;
        // We need adminScratchPadDocuments here and not just the currentDocs as we want all teams
        // documents to be maintained when the context is updated and not just currentDocsForTeam
        const scratchPadDocumentsToUpdate = adminScratchPadDocuments || participantScratchPadDocuments || [];
        if (createScratchPadData?.createScratchPadDocument) {
            const newScratchPadDocument = createScratchPadData?.createScratchPadDocument;
            const updateDocuments = [...scratchPadDocumentsToUpdate, newScratchPadDocument];
            setScratchPadDocuments(updateDocuments);
            setCurrentDocument(newScratchPadDocument);
            if (closeAfterSave) {
                onCloseModal();
            }
        }
    }, [createScratchPadData]);

    useEffect(() => {
        const setScratchPadDocuments = setAdminScratchPadDocuments || setParticipantScratchPadDocuments;
        // We need adminScratchPadDocuments here and not just the currentDocs as we want all teams
        // documents to be maintained when the context is updated and not just currentDocsForTeam
        const scratchPadDocumentsToUpdate = adminScratchPadDocuments || participantScratchPadDocuments || [];
        if (editScratchPadData) {
            const editedDocument = editScratchPadData?.editScratchPadDocument;
            const filteredDocumentArray = filter(
                (doc: ScratchPadDocument) => doc.uuid !== editedDocument.uuid,
                scratchPadDocumentsToUpdate,
            );
            const updateDocs = [...filteredDocumentArray, editedDocument];
            setScratchPadDocuments(updateDocs);
            setCurrentDocument(editedDocument);
            if (closeAfterSave) {
                onCloseModal();
            }
        }
    }, [editScratchPadData]);

    useEffect(() => {
        const currentOpenDocument = find((doc) => doc.uuid === documentUUID, currentDocs);
        const isCurrentOpenDocEdited =
            initialDocumentRef?.current.notes !== currentOpenDocument?.notes ||
            initialDocumentRef?.current.name !== currentOpenDocument?.name;
        if (currentOpenDocument && isCurrentOpenDocEdited) {
            const isCurrentUserDocumentEditor = currentOpenDocument?.lastEditedBy === currentUserId;
            const isCurrentDocEditedLocally =
                initialDocumentRef?.current.notes !== currentDocument.notes ||
                initialDocumentRef?.current.name !== currentDocument.name;
            setCurrentDocument(currentOpenDocument);
            initialDocumentRef.current = currentOpenDocument;
            if (!isCurrentUserDocumentEditor && isCurrentDocEditedLocally) {
                setShowDocUpdatedModal(true);
            }
        }
    }, [documentToEdit, adminScratchPadDocuments, participantScratchPadDocuments]);

    useEffect(() => {
        const currentOpenDocument = find((doc) => doc.uuid === documentUUID, currentDocs);
        if (currentOpenDocument && !currentOpenDocument?.currentEditor) {
            editScratchPadDocumentMutation({
                variables: {
                    scratchPadDocument: {
                        ...currentDocument,
                        currentEditor: currentUserId,
                    },
                },
            });
        }
    }, [currentDocument]);

    const saveRichTextDocument = (clearLock?: boolean) => {
        const isCurrentUserDocumentEditor = currentDocument?.currentEditor === currentUserId;
        const editedDocumentWithUpdatedEditor =
            clearLock && isCurrentUserDocumentEditor
                ? {
                      ...currentDocument,
                      currentEditor: undefined,
                  }
                : currentDocument;
        if (documentUUID) {
            editScratchPadDocumentMutation({
                variables: {
                    scratchPadDocument: editedDocumentWithUpdatedEditor,
                },
            });
        } else {
            createScratchPadDocumentMutation({
                variables: {
                    scratchPadDocument: currentDocument,
                },
            });
        }
    };

    const isDataLoading = createScratchPadLoading || editScratchPadLoading;

    const hasUnsavedChanges = !equals(initialDoc, currentDocument);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    const documentIsLocked = !!currentDocument.currentEditor && currentDocument.currentEditor !== currentUserId;
    const editorName = currentDocument.currentEditor
        ? participants[currentDocument.currentEditor]?.fullName || 'admin'
        : '';

    const closeModal = () => {
        const isCurrentUserDocumentEditor = currentDocument?.currentEditor === currentUserId;
        if (isCurrentUserDocumentEditor && documentUUID) {
            editScratchPadDocumentMutation({
                variables: {
                    scratchPadDocument: { ...currentDocument, currentEditor: undefined },
                },
                onCompleted: () => onModalCloseUnsavedPrompt(),
            });
        } else {
            onModalCloseUnsavedPrompt();
        }
    };

    return (
        <>
            <UnsavedPrompt />
            <Modal
                header={documentUUID ? 'Edit Document' : 'New Document'}
                onModalClose={closeModal}
                cardWidth={isDataLoading ? '35%' : '80%'}
            >
                {isDataLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!isDataLoading && (
                    <>
                        <CardSection>
                            <Display.VerticalWithSpacing>
                                <TextInput
                                    value={currentDocument.name}
                                    placeholder="Document Name"
                                    onChange={(e) =>
                                        setCurrentDocument(assocPath(['name'], e.target.value, currentDocument))
                                    }
                                    validationFailed={!!doesFileNameAlreadyExist}
                                />
                                {doesFileNameAlreadyExist && (
                                    <Body2 destructive>
                                        The document name you have chosen already exists. Please try another one!
                                    </Body2>
                                )}
                            </Display.VerticalWithSpacing>
                        </CardSection>
                        <CardSection>
                            <RichtextInput
                                value={documentNotes}
                                imageUploadSimulation={imageUploadSimulation}
                                onChange={(richtext) =>
                                    setCurrentDocument(assocPath(['notes'], richtext, currentDocument))
                                }
                            />
                        </CardSection>
                        <CardSection>
                            <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                                <Body1 destructive={documentIsLocked}>
                                    {documentUUID && (
                                        <>
                                            {documentIsLocked &&
                                                `This document is currently being edited by ${editorName}. `}
                                            Saving changes {documentIsLocked ? 'will' : 'may'} overwrite the work of
                                            your team mates.
                                        </>
                                    )}
                                </Body1>
                                <Display.HorizontalWithSpacing gap={5}>
                                    <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                                    <PrimaryButton
                                        disabled={!canSaveDocument}
                                        onClick={() => {
                                            setCloseAfterSave(false);
                                            saveRichTextDocument();
                                        }}
                                    >
                                        Save
                                    </PrimaryButton>
                                    <PrimaryButton
                                        disabled={!canSaveDocument}
                                        onClick={() => {
                                            setCloseAfterSave(true);
                                            saveRichTextDocument(true);
                                        }}
                                    >
                                        Save & Close
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.HorizontalWithMaxSpaceBetween>
                        </CardSection>
                    </>
                )}
                {!isDataLoading && showDocUpdatedModal && (
                    <DocumentUpdatedModal onCloseModal={() => setShowDocUpdatedModal(false)} />
                )}
            </Modal>
        </>
    );
};
