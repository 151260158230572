import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import React, { FC, useState } from 'react';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Icon } from '../../../bits/Icon';
import { DocumentCard } from './components/DocumentCard';
import { DocumentLabel } from './components/DocumentLabel';
import { DownloadIconContainer } from './components/DownloadIconContainer';
import { DeleteInitialScratchPadDocumentButton } from './DeleteInitialScratchPadDocumentButton';
import { InitialRichTextDocumentModal } from './InitialRichTextDocumentModal';
import { RichTextDocumentReadOnlyModal } from './RichTextDocumentReadOnlyModal';

interface InitialScratchPadDocumentItemProps {
    document: InitialScratchPadDocument;
    title: 'Strategy' | 'Statements' | 'Resources';
    disableManageDocuments?: boolean;
}
export const InitialScratchPadDocumentItem: FC<InitialScratchPadDocumentItemProps> = ({
    document,
    title,
    disableManageDocuments,
}) => {
    const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);
    const isRichTextDocument = document.notes || document.notes === '';

    return (
        <>
            <DocumentCard key={document.uuid}>
                {isRichTextDocument && (
                    <>
                        <Icon icon="scratchPadLive" size={22} />
                        <DocumentLabel onClick={() => setOpenViewDocumentModal(true)}>{document.name}</DocumentLabel>
                        <IconButton
                            onClick={() => setOpenViewDocumentModal(true)}
                            icon={disableManageDocuments ? 'view' : 'edit'}
                            size={16}
                        />
                    </>
                )}
                {!isRichTextDocument && (
                    <>
                        <Icon icon="scratchPadDoc" size={22} />
                        <DocumentLabel as="a" href={document.path} download>
                            {document.name}
                        </DocumentLabel>
                        <DownloadIconContainer href={document.path} download>
                            <IconButton icon="download" size={20} />
                        </DownloadIconContainer>
                    </>
                )}
                {!disableManageDocuments && <DeleteInitialScratchPadDocumentButton document={document} />}
            </DocumentCard>
            {openViewDocumentModal && (
                <>
                    {disableManageDocuments ? (
                        <RichTextDocumentReadOnlyModal
                            document={document}
                            onCloseModal={() => setOpenViewDocumentModal(false)}
                        />
                    ) : (
                        <InitialRichTextDocumentModal
                            onCloseModal={() => setOpenViewDocumentModal(false)}
                            category={title}
                            documentToEdit={document}
                        />
                    )}
                </>
            )}
        </>
    );
};
