import gql from 'graphql-tag';
import { GraphQlMoment } from 'polpeo-go-common/types/Moment';
import wrapMutation from '../wrapMutation';

interface EditMomentParams {
    moment: GraphQlMoment;
}

interface EditMomentData {
    editMoment: GraphQlMoment;
}

const mutation = gql`
    mutation editMoment($moment: MomentInput!) {
        editMoment(moment: $moment) {
            uuid
            simulationUUID
            created {
                at
                by
            }
            contentItem {
                type
                uuid
                simulationUUID
                teamUUID
                parentUUID
                createdAt
                persona
                content {
                    pageUUID
                    interactions {
                        positive
                        negative
                        shares
                    }
                    data
                }
                prepreparedContentUUID
                createdBy {
                    type
                    uuid
                }
                deleted {
                    at
                    by {
                        type
                        uuid
                    }
                }
            }
            rating
            comments
        }
    }
`;

export const editMoment = wrapMutation<EditMomentParams, EditMomentData>(mutation);
