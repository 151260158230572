import { decodeEmailItem, EmailItem } from 'polpeo-go-common/types/EmailItem';
import { fromPairs, map } from 'ramda';
import { Client } from '../graphql/client';
import { getEmailItems } from '../graphql/emailItem';

export const fetchEmailItems = async (
    client: Client,
    simulationUUID: string,
    newerThan?: Date | string,
): Promise<Record<string, EmailItem>> => {
    let emailItems: EmailItem[] = [];
    let emailItemsContinueFrom: undefined | string;

    do {
        const getEmailItemsResponse = await getEmailItems.promise(client, {
            simulationUUID: simulationUUID,
            newerThan: newerThan,
            startFrom: emailItemsContinueFrom,
        });
        if (!getEmailItemsResponse.data) {
            return {};
        }
        const cleanedEmails = map((item) => decodeEmailItem(item), getEmailItemsResponse.data.getEmailItems.emailItems);
        emailItems = [...emailItems, ...cleanedEmails];
        emailItemsContinueFrom = getEmailItemsResponse.data.getEmailItems.continueFrom;
    } while (emailItemsContinueFrom);
    return fromPairs(map((emailItem) => [emailItem.uuid, emailItem], emailItems));
};
