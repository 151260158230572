import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { Team } from 'polpeo-go-common/types/Team';
import { assocPath, values } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { CardHeader } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { H2Heading } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { useFabButtonPositioner } from '../../../../hooks/useFabButtonPositioner';
import { useQuery } from '../../../../hooks/useQuery';
import { useThreadBuilder } from '../../../../hooks/useThreadBuilder';
import { FloatingActionButton } from '../../../../patterns/FAB/FloatingActionButton';
import { EmailFABMenu, EmailItemOptions } from '../../../Email';
import { NewEmailItemButton } from '../../../Email/NewEmailItemButton';
import { ParticipantsEmailsDropdown } from '../../../Email/ParticipantsEmailsDropdown';

interface TeamEmailFABProps {
    team: Team;
    emailItemOptions?: EmailItemOptions;
    disableCreateEmailItems?: boolean;
}

const TeamEmailFABCardHeader = styled(CardHeader)<{ currentParticipantInbox: string }>`
    ${(props) => (props.currentParticipantInbox ? 'background-color: #d9dde3' : '')};
`;

export const TeamEmailFAB: FC<TeamEmailFABProps> = ({ team, emailItemOptions, disableCreateEmailItems }) => {
    const {
        simulationContent: { emailItems },
        participants,
        notifications,
        setNotifications,
    } = useContext(AdminInSimulationStateContext);

    const {
        location: { pathname },
        push,
    } = useHistory();

    const query = useQuery();
    const emailId = query.get('email_id');
    const [selectedEmail, setSelectedEmail] = useState<EmailItem | undefined>();
    const [currentParticipantInbox, setCurrentParticipantInbox] = useState<string>('');
    const [openMenu, setOpenMenu] = useState(false);

    const emailNotification = notifications[team.uuid]?.emails;
    const { rightPosition, bottomPosition, fabButtonRef } = useFabButtonPositioner();

    const { threads, emailUUIDToThreadMap, inboxEmails, outboxEmails } = useThreadBuilder(
        emailItems[team.uuid],
        currentParticipantInbox,
        participants,
    );

    // allows email ids added to the query to open the modal
    // the id and the participant who created it are then stored
    // in state. The query is then cleared
    useEffect(() => {
        if (emailId) {
            if (!openMenu) setOpenMenu(true);
            const emailInQuery = (values(emailItems[team.uuid] || {}) as EmailItem[]).find(
                (email) => email.uuid === emailId,
            );
            if (emailInQuery) {
                if (participants[emailInQuery.createdBy.uuid]) setCurrentParticipantInbox(emailInQuery.createdBy.uuid);
                setSelectedEmail(emailInQuery);
            }
            push(pathname);
        }
    }, [emailId]);

    useEffect(() => {
        if (emailNotification && openMenu) {
            setNotifications(assocPath([team.uuid, 'emails'], false));
        }
    }, [emailNotification, openMenu]);

    useEffect(() => {
        if (!openMenu) {
            setCurrentParticipantInbox('');
            setSelectedEmail(undefined);
        }
    }, [openMenu]);

    return (
        <>
            <FloatingActionButton
                ref={fabButtonRef}
                icon="email"
                onClick={() => setOpenMenu(!openMenu)}
                notification={emailNotification}
            />
            {openMenu && (
                <EmailFABMenu
                    onClose={() => setOpenMenu(false)}
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                    threads={threads}
                    emailUUIDToThreadMap={emailUUIDToThreadMap}
                    inboxEmails={inboxEmails}
                    outboxEmails={outboxEmails}
                    menuPosition={{ right: rightPosition }}
                    tailPosition={{ bottom: bottomPosition }}
                    emailItemOptions={emailItemOptions}
                    currentParticipantInbox={currentParticipantInbox}
                    disableCreateEmailItems={disableCreateEmailItems || !!currentParticipantInbox}
                >
                    <TeamEmailFABCardHeader currentParticipantInbox={currentParticipantInbox}>
                        <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                            <Display.HorizontalWithSpacing verticalCenter gap={5}>
                                <H2Heading>{`Viewing`}</H2Heading>
                                <ParticipantsEmailsDropdown
                                    setSelectedEmail={setSelectedEmail}
                                    setCurrentParticipantInbox={setCurrentParticipantInbox}
                                    currentParticipantInbox={currentParticipantInbox}
                                />
                                {currentParticipantInbox && (
                                    <H2Heading>
                                        Emails <Icon icon={'createdByParticipant'} size={16} />
                                    </H2Heading>
                                )}
                            </Display.HorizontalWithSpacing>
                            <Display.HorizontalWithSpacing gap={5}>
                                {!(disableCreateEmailItems || !!currentParticipantInbox) && <NewEmailItemButton />}
                                <IconButton icon="close" onClick={() => setOpenMenu(false)} />
                            </Display.HorizontalWithSpacing>
                        </Display.HorizontalWithMaxSpaceBetween>
                    </TeamEmailFABCardHeader>
                </EmailFABMenu>
            )}
        </>
    );
};
