import { assocPath } from 'ramda';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Display } from '../../../../bits/Display';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { ParticipantInSimulationHeader } from '../../../ParticipantInSimulationHeader';
import { ParticipantSimulationOverview } from './Overview';
import { PageContentDetails } from './PageContentDetails';
import { PageContentList } from './PageContentList';
import { ParticipantEmailFAB } from './ParticipantEmailFAB';
import { ParticipantInSimulationNav } from './ParticipantNav';
import { ParticipantScratchpadFAB } from './ParticipantScratchpadFAB';
import { ParticipantTriggerPopup } from './ParticipantTriggerPopup';

const GlobalStyleForPage = createGlobalStyle`
    #root {
        max-height: 100vh;
        overflow-y: hidden;
    }
`;

const ParticipantAppContentContainer = styled.div.attrs(() => ({ id: 'modal-root' }))`
    position: relative;
    width: 100%;
    overflow-y: hidden;

    display: flex;

    flex: 1 1 auto;
`;

const NavBarScrollBox = styled.div`
    overflow-y: auto;
    flex: 0 0 auto;

    & > * {
        min-height: 100%;
    }
`;

const ScrollBox = styled.div`
    min-height: 100%;
    overflow-y: hidden;

    display: flex;

    flex: 1 1 auto;

    & > * {
        overflow-y: auto;
    }
`;

const MediaButtonsContainer = styled(Display.VerticalWithSpacing)`
    position: fixed;
    bottom: 40px;
    right: 30px;
    z-index: 100;
`;

export const ParticipantSimulationInProgress = (): React.ReactElement => {
    const { hash } = useLocation();
    const { pageUUID, itemUUID } = useParams<{ pageUUID?: string; itemUUID?: string }>();
    const {
        notifications: { pages: pageNotifications = {} },
        setNotifications,
    } = useContext(ParticipantStateContext);

    useLayoutEffect(() => {
        if (pageUUID && pageNotifications[pageUUID]) {
            setNotifications(assocPath(['pages', pageUUID], false));
        }
        if (itemUUID && pageNotifications[itemUUID]) {
            setNotifications(assocPath(['pages', itemUUID], false));
        }
    }, [pageNotifications, pageUUID, itemUUID]);

    useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                // There seems to be some weird rendering thing where if we try to scroll as soon as the content
                // is rendered the item does not scroll properly into view so we wait a bit after rendering to scroll
                const scrollTimeout = setTimeout(
                    () => element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }),
                    10,
                );
                return () => clearTimeout(scrollTimeout);
            }
        }
    }, [hash]);

    return (
        <>
            <GlobalStyleForPage />
            <ParticipantInSimulationHeader />
            <ParticipantAppContentContainer>
                <NavBarScrollBox>
                    <ParticipantInSimulationNav />
                </NavBarScrollBox>
                <ScrollBox>
                    <Switch>
                        <Route path="/simulation/:simulationCode" exact>
                            <ParticipantSimulationOverview />
                        </Route>
                        <Route path="/simulation/:simulationCode/:pageUUID" exact>
                            <PageContentList />
                        </Route>
                        <Route path="/simulation/:simulationCode/:pageUUID/:itemUUID" exact>
                            <PageContentDetails />
                        </Route>
                    </Switch>
                </ScrollBox>
            </ParticipantAppContentContainer>
            <ParticipantTriggerPopup />
            <MediaButtonsContainer>
                <ParticipantScratchpadFAB />
                <ParticipantEmailFAB />
            </MediaButtonsContainer>
        </>
    );
};
