import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../bits/Buttons';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { ParticipantEmailItemFormModal } from './ParticipantEmailItemFormModal';
import { StaffEmailItemFormModal } from './StaffEmailItemFormModal';

type ReplyToItemButtonProps = { replyTo: EmailItem };
export const ReplyToItemButton: FC<ReplyToItemButtonProps> = ({ replyTo }) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton small onClick={() => setShowModal(true)}>
                Reply
            </SecondaryButton>
            {showModal && staffUser && (
                <StaffEmailItemFormModal onCloseModal={() => setShowModal(false)} replyTo={replyTo} />
            )}
            {showModal && participantUser && (
                <ParticipantEmailItemFormModal onCloseModal={() => setShowModal(false)} replyTo={replyTo} />
            )}
        </>
    );
};
