import { configure as configureJdenticon, toSvg } from 'jdenticon';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import WithClassname from '../../utils/WithClassname';
import { Creator } from 'polpeo-go-common/types/Creator';

configureJdenticon({
    lightness: {
        color: [0.51, 0.71],
        grayscale: [0.33, 0.54],
    },
    saturation: {
        color: 0.7,
        grayscale: 0.29,
    },
    backColor: '#0000',
});

const IdenticonContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100px !important;
    background: #ffffff !important;
    overflow: hidden;

    svg {
        position: relative;
        top: -2px;
        left: -2px;
    }
`;

interface IdenticonProps extends WithClassname {
    creator: Creator;
    size?: number;
}
export const Identicon: FC<IdenticonProps> = ({ className, creator, size = 40 }) => {
    return (
        <IdenticonContainer
            className={className}
            /* eslint-disable-next-line @typescript-eslint/naming-convention*/
            dangerouslySetInnerHTML={{ __html: toSvg(creator.email, size + 2) }}
        />
    );
};
