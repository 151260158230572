/* eslint-disable max-len */
// workaround for typescript for css prop in styled components
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31245#issuecomment-701684920
import type {} from 'styled-components/cssprop';
import { createGlobalStyle } from 'styled-components/macro';
import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoItalic from './fonts/Roboto-Italic.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import RobotoMediumItalic from './fonts/Roboto-MediumItalic.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import RobotoBoldItalic from './fonts/Roboto-BoldItalic.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoRegular}) format("truetype");
        font-weight: 400;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoItalic}) format("truetype");
        font-weight: 400;
        font-style: italic;
        font-display: fallback;
    }

    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoMedium}) format("truetype");
        font-weight: 500;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoMediumItalic}) format("truetype");
        font-weight: 500;
        font-style: italic;
        font-display: fallback;
    }
    
    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoBold}) format("truetype");
        font-weight: 700;
        font-display: fallback;
    }
    @font-face {
        font-family: 'Roboto';
        font-display: swap;
        src: url(${RobotoBoldItalic}) format("truetype");
        font-weight: 700;
        font-style: italic;
        font-display: fallback;
    }

    a, abbr, acronym, address, applet, article, aside, audio, big, blockquote, body, button, canvas, caption, center,
    cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form,
    h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, input, ins, kbd, label, legend, li, mark, menu, nav,
    object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table,
    tbody, td, textarea, tfoot, th, thead, time, tr, tt, u, ul, var, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-size: 100%;
        font-weight: 400;
        vertical-align: baseline;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:after, blockquote:before, q:after, q:before {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    html {
        font-size: 14px;
        scroll-behavior: smooth;
    }

    *, *:after, *:before {
        box-sizing: border-box;
    }

    img {
        max-width: 100%;
        vertical-align: middle;
        width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 14px;
        font-weight: 400;
    }

    a {
        color: currentColor;
        text-decoration: none;
        display: inline-block;
    }

    button {
        background: none;
        cursor: pointer;
    }

    body {
        display: flex;
        flex-direction: column;
    }

    #root {
        flex: 1 1 auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
`;

export default GlobalStyle;
