import gql from 'graphql-tag';
import { GraphQlContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface DeleteContentItemParams {
    contentItem: GraphQlContentItem;
}

interface DeleteContentItemData {
    deleteContentItem: GraphQlContentItem;
}

const mutation = gql`
    mutation deleteContentItem($contentItem: ContentItemInput!) {
        deleteContentItem(contentItem: $contentItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                pageUUID
                interactions {
                    positive
                    negative
                    shares
                }
                data
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
            deleted {
                at
                by {
                    type
                    uuid
                }
            }
        }
    }
`;

export const deleteContentItem = wrapMutation<DeleteContentItemParams, DeleteContentItemData>(mutation);
