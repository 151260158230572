// From https://xd.adobe.com/view/72ddd817-46d5-4cf6-b671-f467953a33da-278e/

// Key colours
export const primaryColour = '#005670';
export const primaryDarkColour = '#002F3D';
export const secondaryColour = '#C3DC93';
export const notificationColour = '#EB6E7E';

// Action colours
export const destructiveColour = '#960200';
export const destructiveDarkColour = '#6b0100';
export const constructiveColour = '#157F1F';

// UI shades
export const grey6Colour = '#393939';
export const grey5Colour = '#434343';
export const grey4Colour = '#606060';
export const grey3Colour = '#8B8B8B';
export const grey2Colour = '#D5D7DC';
export const grey1Colour = '#EFEFEF';
export const whiteColour = '#FFFFFF';

// Role shades
export const adminDarkColour = '#5A0C17';
export const adminLightColour = '#FADBDF';
export const roleplayerDarkColour = '#102E25';
export const roleplayerLightColour = '#D1EFE6';

// Extra button colours
export const secondaryButtonAlternateColour = '#E5EEF0';
