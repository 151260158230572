import { Page } from 'polpeo-go-common/types/Page';
import { Team } from 'polpeo-go-common/types/Team';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editTeam } from '../../../../../graphql/teams';
import { PrimaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { Spinner } from '../../../../bits/Spinner';
import { Modal } from '../../../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { ManagePageStatsForm } from '../../../PageStats/ManagePageStatsForm';

interface EditPageStatsButtonProps {
    page: Page;
    team: Team;
    disabled?: boolean;
}
export const EditPageStatsButton: FC<EditPageStatsButtonProps> = ({ page, team, disabled }) => {
    const { setTeam } = useContext(AdminInSimulationStateContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [editTeamMutation, { data: editTeamMutationData, loading: editTeamMutationLoading }] = editTeam.hook();
    useEffect(() => {
        const updatedTeam = editTeamMutationData?.editTeam;
        if (updatedTeam) {
            setTeam({ ...updatedTeam, pageStats: JSON.parse(updatedTeam.pageStats || '{}') });
            setIsModalOpen(false);
        }
    }, [editTeamMutationData]);

    return (
        <>
            <PrimaryButton small disabled={disabled} onClick={() => setIsModalOpen(true)}>
                Edit Page Stats
            </PrimaryButton>
            {isModalOpen && (
                <Modal header="Edit Page Stats" onModalClose={() => setIsModalOpen(false)}>
                    {editTeamMutationLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!editTeamMutationLoading && (
                        <ManagePageStatsForm
                            onCancel={() => setIsModalOpen(false)}
                            stats={team.pageStats?.[page.uuid] || {}}
                            onSave={(newStats) => {
                                editTeamMutation({
                                    variables: {
                                        team: {
                                            ...team,
                                            pageStats: JSON.stringify({
                                                ...(team.pageStats || {}),
                                                [page.uuid]: newStats,
                                            }),
                                        },
                                    },
                                });
                            }}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};
