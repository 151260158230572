import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { filter, length, map, prop, reduce, sortBy, tail, values } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../../../themes/colours';
import { body2 } from '../../../../themes/cssSnippets';
import { dateTimestampRelativeToSimStart } from '../../../../utils/dateTimestampRelativeToSimStart';
import { SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Body3 } from '../../../bits/Headers';
import { Card } from '../../../patterns/Card';
import { MomentsGraph } from '../../../patterns/MomentsGraph';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../WithStaffUser';
import { userCanCreateMoments } from 'polpeo-go-common/permissions/userCanCreateMoments';

const AllTeamsOverviewPageContainer = styled.div`
    flex: 0 1 auto;
    margin: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    overflow: auto;
`;

const ActivityTable = styled.table`
    width: 100%;

    ${body2}

    th,
    td {
        width: 33%;
        text-align: left;
    }

    td {
        padding: 4px 0;
    }

    tr:first-child th {
        font-weight: 700;
    }
    tr:not(:last-child) {
        border-bottom: 1px solid ${grey1Colour};
    }
`;

const getMostRecentItem = (staffItems: (ContentItem | EmailItem)[]) => {
    const staffItemsWithoutFirst = tail(staffItems);
    return reduce<ContentItem | EmailItem, ContentItem | EmailItem>(
        (a, b) => {
            return a.createdAt > b.createdAt ? a : b;
        },
        staffItems[0],
        staffItemsWithoutFirst,
    );
};

interface TeamActivityCardProps {
    team: Team;
    useTimestampRelativeToSimulationStart?: Simulation;
}
const TeamActivityCard: FC<TeamActivityCardProps> = ({ team, useTimestampRelativeToSimulationStart }) => {
    const { staffUsers } = useContext(AdminStateContext);
    const staffUser = useContext(StaffUserContext);
    const { simulation, teamAssignments, simulationContent } = useContext(AdminInSimulationStateContext);

    const participantCount = useMemo(
        () => length(filter((assignment) => assignment.teamUUID === team.uuid, values(teamAssignments))),
        [teamAssignments],
    );
    const teamMoments = useMemo(
        () => values(simulationContent.moments[team.uuid] || {}) as Moment[],
        [simulationContent.moments],
    );
    const { staffItems, participantItems } = useMemo(() => {
        const teamContentItems = values(simulationContent.contentItems[team.uuid] || {}) as ContentItem[];
        const teamEmailItems = values(simulationContent.emailItems[team.uuid] || {}) as EmailItem[];
        const teamItems = [...teamContentItems, ...teamEmailItems];
        const staffItems = filter((item) => item.createdBy.type === 'STAFF', teamItems);
        const participantItems = filter((item) => item.createdBy.type === 'PARTICIPANT', teamItems);

        return { staffItems, participantItems };
    }, [simulationContent.contentItems, simulationContent.emailItems]);

    let lastStaffActivity = '';
    if (staffItems.length) {
        const mostRecentItem = getMostRecentItem(staffItems);
        const createdAt = DateTime.fromJSDate(mostRecentItem.createdAt);
        if (!useTimestampRelativeToSimulationStart) {
            lastStaffActivity = createdAt.toRelative() || '';
        } else {
            lastStaffActivity = dateTimestampRelativeToSimStart(
                useTimestampRelativeToSimulationStart,
                mostRecentItem.createdAt,
            );
        }
    }

    let lastParticipantActivity = '';
    if (participantItems.length) {
        const mostRecentItem = getMostRecentItem(participantItems);
        const createdAt = DateTime.fromJSDate(mostRecentItem.createdAt);
        if (!useTimestampRelativeToSimulationStart) {
            lastParticipantActivity = createdAt.toRelative() || '';
        } else {
            lastParticipantActivity = dateTimestampRelativeToSimStart(
                useTimestampRelativeToSimulationStart,
                mostRecentItem.createdAt,
            );
        }
    }

    return (
        <Card header={team.name} cardWidth={430}>
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithMaxSpaceBetween>
                    <Display.VerticalWithSpacing>
                        <ul>
                            {team.roleplayerUUIDs.length ? (
                                map(
                                    (roleplayerUUID) => (
                                        <li key={roleplayerUUID}>
                                            <Body3>{staffUsers[roleplayerUUID]?.name || '(deleted user)'}</Body3>
                                        </li>
                                    ),
                                    team.roleplayerUUIDs,
                                )
                            ) : (
                                <Body3>No assigned roleplayers</Body3>
                            )}
                        </ul>
                    </Display.VerticalWithSpacing>
                    <Body3>{participantCount} participants</Body3>
                </Display.HorizontalWithMaxSpaceBetween>
                {userCanCreateMoments(simulation, staffUser) && <MomentsGraph moments={teamMoments} />}
                <ActivityTable>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Team</th>
                            <th>Staff</th>
                        </tr>
                        <tr>
                            <th>Last activity</th>
                            <td>{lastParticipantActivity || '-'}</td>
                            <td>{lastStaffActivity || '-'}</td>
                        </tr>
                        <tr>
                            <th>Activity count</th>
                            <td>{participantItems.length}</td>
                            <td>{staffItems.length}</td>
                        </tr>
                    </tbody>
                </ActivityTable>
                <Display.RightAlign>
                    <SecondaryButton as="div" small>
                        <Link to={`/admin/simulation/${simulation.uuid}/team/${team.uuid}`}>View Team</Link>
                    </SecondaryButton>
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </Card>
    );
};

interface AllTeamsOverviewProps {
    useTimestampRelativeToSimulationStart?: Simulation;
}
export const AllTeamsOverview: FC<AllTeamsOverviewProps> = ({ useTimestampRelativeToSimulationStart }) => {
    const { teamsForCurrentStaffUser } = useContext(AdminInSimulationStateContext);
    const sortedTeams = useMemo(
        () => sortBy(prop('name'), values(teamsForCurrentStaffUser)),
        [teamsForCurrentStaffUser],
    );

    return (
        <AllTeamsOverviewPageContainer>
            {map(
                (team) => (
                    <TeamActivityCard
                        key={team.uuid}
                        team={team}
                        useTimestampRelativeToSimulationStart={useTimestampRelativeToSimulationStart}
                    />
                ),
                sortedTeams,
            )}
        </AllTeamsOverviewPageContainer>
    );
};
