import React, { FC } from 'react';
import { IconButton } from '../bits/Buttons/IconButton';
import { Display } from '../bits/Display';
import { Subtitle2 } from '../bits/Headers';

interface CollapsibleProps {
    title: string;
    open?: boolean;
    toggleGroupCollapsed: () => void;
}
export const Collapsible: FC<CollapsibleProps> = ({ title, open, toggleGroupCollapsed, children }) => {
    return (
        <Display.VerticalWithSpacing gap={8}>
            <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                <Subtitle2>{title}</Subtitle2>
                <IconButton icon={open ? 'collapse' : 'accordianOpen'} onClick={() => toggleGroupCollapsed()} />
            </Display.HorizontalWithMaxSpaceBetween>
            {open && children}
        </Display.VerticalWithSpacing>
    );
};
