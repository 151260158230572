import React, { FC, useContext, useRef, useState } from 'react';
import { grey4Colour } from '../../../themes/colours';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { StaffUserContext } from '../../WithStaffUser';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { Icon } from '../../bits/Icon';
import { MenuButton, MenuItem } from '../../patterns/MenuButton';
import { EditEmailItemModal } from './EditEmailItemModal';
import { EmailItem } from 'polpeo-go-common/types';
import { DeleteEmailItemModal } from './DeleteEmailItemModal';

interface ManageEmailButtonProps {
    className?: string;
    item: EmailItem;
}
export const ManageEmailButton: FC<ManageEmailButtonProps> = ({ className, item }) => {
    const staffUser = useContext(StaffUserContext);
    const { simulation: adminSimulation } = useContext(AdminInSimulationStateContext);
    const { simulation: participantSimulation } = useContext(ParticipantStateContext);
    const simulation = adminSimulation || participantSimulation || {};

    const [currentModal, setCurrentModal] = useState<'Edit' | 'Delete' | undefined>();
    const closeMenuRef = useRef<(() => void) | undefined>();

    if (!staffUser || !!simulation.completedAt) {
        return null;
    }

    return (
        <>
            <MenuButton
                className={className}
                direction="right"
                closeMenuRef={closeMenuRef}
                buttonComponent={(props) => (
                    <IconButton
                        icon="accordianOpen"
                        fill={grey4Colour}
                        size={20}
                        onClick={(e) => {
                            if (props.onClick) {
                                props.onClick(e);
                            }
                        }}
                    />
                )}
            >
                <MenuItem
                    onClick={() => {
                        setCurrentModal('Edit');
                        if (closeMenuRef && closeMenuRef.current) {
                            closeMenuRef.current();
                        }
                    }}
                >
                    <Display.HorizontalWithSpacing>
                        <Icon icon="edit" size={18} />
                        Edit
                    </Display.HorizontalWithSpacing>
                </MenuItem>

                {!item.deleted && (
                    <MenuItem
                        onClick={() => {
                            setCurrentModal('Delete');
                            if (closeMenuRef && closeMenuRef.current) {
                                closeMenuRef.current();
                            }
                        }}
                    >
                        <Display.HorizontalWithSpacing>
                            <Icon icon="delete" size={18} />
                            Delete
                        </Display.HorizontalWithSpacing>
                    </MenuItem>
                )}
            </MenuButton>
            {currentModal === 'Edit' && (
                <EditEmailItemModal item={item} onCloseModal={() => setCurrentModal(undefined)} />
            )}
            {currentModal === 'Delete' && (
                <DeleteEmailItemModal item={item} onCloseModal={() => setCurrentModal(undefined)} />
            )}
        </>
    );
};
