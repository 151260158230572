import React, { FC, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components/macro';
import { TabMenu } from '../../../../patterns/TabMenu';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { SimulationDetailsLobbyHeader } from './SimulationDetailsLobbyHeader';
import { TeamAssignmentTable } from './TeamAssignmentGrid';

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

export const StaffUserSimulationLobbyPage: FC = () => {
    const { simulation } = useContext(AdminInSimulationStateContext);

    return (
        <>
            <TabMenu
                tabs={[
                    {
                        label: 'Team Assignment',
                        icon: 'content',
                        navLink: { to: `/admin/simulation/${simulation.uuid}` },
                        route: {
                            path: '/admin/simulation/:simulationUUID',
                            children: (
                                <PageContainer>
                                    <SimulationDetailsLobbyHeader />
                                    <DndProvider backend={HTML5Backend}>
                                        <TeamAssignmentTable />
                                    </DndProvider>
                                </PageContainer>
                            ),
                        },
                    },
                ]}
            />
        </>
    );
};
