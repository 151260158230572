import { NewPrepreparedEmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { assocPath } from 'ramda';
import React from 'react';
import { TextInput } from '../../../../../bits/FormFields';
import RichtextInput from '../../../../../patterns/RichtextInput';
import { FormGridField } from '../../../../FormGrid';

export function makeContentTabFormFields<T extends NewPrepreparedEmailItem | PrepreparedEmailItem>(
    simulation: Simulation,
    currentPrepreparedEmail: T,
    setCurrentPrepreparedEmail: (value: T | ((item: T) => T)) => void,
): FormGridField[] {
    return [
        [
            'Subject',
            <>
                <TextInput
                    value={currentPrepreparedEmail.content.subject || ''}
                    onChange={(e) =>
                        setCurrentPrepreparedEmail(assocPath<string, T>(['content', 'subject'], e.target.value))
                    }
                />
            </>,
        ],
        [
            'Message',
            <>
                <RichtextInput
                    value={currentPrepreparedEmail.content.message || ''}
                    imageUploadSimulation={simulation}
                    onChange={(richtext) =>
                        setCurrentPrepreparedEmail(assocPath<string, T>(['content', 'message'], richtext))
                    }
                />
            </>,
        ],
    ];
}
