import { EditorContent } from '@tiptap/react';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { richtextStyling } from '../../themes/cssSnippets';
import WithClassname from '../../utils/WithClassname';
import usePolpeoRichtextEditor from '../hooks/usePolpeoRichTextEditor';

const RichTextContainer = styled.div`
    ${richtextStyling}
`;

interface RichtextDisplayProps extends WithClassname {
    value: string;
}
export const RichtextDisplay: FC<RichtextDisplayProps> = ({ value, className }) => {
    const editor = usePolpeoRichtextEditor({ content: value, editable: false }, [value]);

    return (
        <RichTextContainer className={className}>
            <EditorContent editor={editor} />
        </RichTextContainer>
    );
};

// stops the rich text overflowing out of the bottom
// of the footer since it can be multiline
export const RichtextDisplayOverflowStyling = styled(RichtextDisplay)`
    max-height: 1.25em;
    overflow: hidden;

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;
