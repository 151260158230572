import gql from 'graphql-tag';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import wrapMutation from '../wrapMutation';

interface EditEmailItemParams {
    emailItem: EmailItem;
}

interface EditEmailItemData {
    editEmailItem: EmailItem;
}

const mutation = gql`
    mutation editEmailItem($emailItem: EmailItemInput!) {
        editEmailItem(emailItem: $emailItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                recipients
                subject
                message
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
        }
    }
`;

export const editEmailItem = wrapMutation<EditEmailItemParams, EditEmailItemData>(mutation);
