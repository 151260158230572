import React, { FC, useState, useContext, useEffect } from 'react';
import { CardSection } from '../../../../bits/Card';
import { Card } from '../../../../patterns/Card';
import styled from 'styled-components/macro';
import UploadDocumentsButton from './UploadDocumentsButton';
import { Simulation, BriefingDocument, BriefingNotes } from '../../../../../../../common/types/Simulation';
import { equals, filter } from 'ramda';
import { editSimulation } from '../../../../../graphql/simulations/editSimulation';
import { StaffUserContext } from '../../../../WithStaffUser';
import { PermissionKey } from 'polpeo-go-common/permissions/types';
import { userCan } from 'polpeo-go-common/permissions';
import { DocumentInfoCard } from './DocumentInfoCard';
import { Spinner } from '../../../../bits/Spinner';
import { Display } from '../../../../bits/Display';
import BriefingDocumentsHeader from './BriefingDocumentsHeader';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import RichtextInput from '../../../../patterns/RichtextInput';
import { RichtextDisplay } from '../../../../bits/RichtextDisplay';
import { useUnsavedWorkPrompt } from '../../../../hooks/useUnsavedWorkPrompt';

const BriefingDocumentCardList = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`;

interface SimulationDocumentsCardProps {
    simulation: Simulation;
}

export const SimulationDocumentsCard: FC<SimulationDocumentsCardProps> = ({ simulation }) => {
    const staffUser = useContext(StaffUserContext);
    const { setSimulation } = useContext(AdminStateContext);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const initialBriefingDocuments = simulation.briefingDocuments || [];
    const [currentBriefingDocuments, setCurrentBriefingDocuments] =
        useState<BriefingDocument[]>(initialBriefingDocuments);
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
    const initialBriefingNotes = simulation.briefingNotes || { lastEditedAt: new Date(), lastEditedBy: '', notes: '' };
    const [briefingNotes, setBriefingNotes] = useState<BriefingNotes>(initialBriefingNotes);

    const [editSimulationMutation, { data: editSimulationData, loading: editSimulationLoading }] =
        editSimulation.hook();

    useEffect(() => {
        if (editSimulationData) {
            setSimulation(editSimulationData.editSimulation);
            setCurrentBriefingDocuments(editSimulationData.editSimulation.briefingDocuments || []);
            setIsEditing(false);
        }
    }, [editSimulationData]);

    const userCanWriteSimulationAndContent =
        !simulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);

    const updateBriefingDocuments = (documents: BriefingDocument[]) => {
        setCurrentBriefingDocuments((uploadedDocs: BriefingDocument[]) => [...uploadedDocs, ...documents]);
        setIsUploadingFile(false);
    };
    const deleteDocument = (document: BriefingDocument) => {
        setCurrentBriefingDocuments(filter((item) => item !== document, currentBriefingDocuments));
    };
    const updateSimulation = () => {
        editSimulationMutation({
            variables: {
                simulation: {
                    ...simulation,
                    briefingDocuments: currentBriefingDocuments,
                    briefingNotes: briefingNotes,
                },
            },
        });
    };

    const hasUnsavedChanges =
        (!equals(currentBriefingDocuments, initialBriefingDocuments) ||
            !equals(briefingNotes.notes, initialBriefingNotes.notes)) &&
        !editSimulationData?.editSimulation;
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    return (
        <>
            <UnsavedPrompt />
            <Card
                header={
                    <BriefingDocumentsHeader
                        header="Briefing Notes"
                        onStartEdit={
                            userCanWriteSimulationAndContent && !isEditing ? () => setIsEditing(true) : undefined
                        }
                        onSave={isEditing ? () => updateSimulation() : undefined}
                    />
                }
            >
                {!editSimulationLoading && (
                    <CardSection>
                        <Display.VerticalWithSpacing>
                            <BriefingDocumentCardList>
                                {isUploadingFile && (
                                    <Display.HorizontalCenterVerticalCenter>
                                        <Spinner />
                                    </Display.HorizontalCenterVerticalCenter>
                                )}
                                {currentBriefingDocuments.length > 0 &&
                                    !isUploadingFile &&
                                    currentBriefingDocuments.map((document: BriefingDocument, index: number) => {
                                        return (
                                            <DocumentInfoCard
                                                key={index}
                                                doc={document}
                                                onDeleteDocument={isEditing ? deleteDocument : undefined}
                                            />
                                        );
                                    })}
                                {isEditing && !isUploadingFile && (
                                    <UploadDocumentsButton
                                        simulation={simulation}
                                        onDocumentsUploaded={updateBriefingDocuments}
                                        onUploadStart={() => setIsUploadingFile(true)}
                                    />
                                )}
                            </BriefingDocumentCardList>
                            {!isEditing && <RichtextDisplay value={briefingNotes.notes} />}
                        </Display.VerticalWithSpacing>
                    </CardSection>
                )}
                {editSimulationLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {isEditing && !editSimulationLoading && (
                    <CardSection>
                        <RichtextInput
                            value={briefingNotes.notes}
                            onChange={(richtext) =>
                                setBriefingNotes({
                                    notes: richtext,
                                    lastEditedBy: staffUser.uuid,
                                    lastEditedAt: new Date(),
                                })
                            }
                            imageUploadSimulation={simulation}
                        />
                    </CardSection>
                )}
            </Card>
        </>
    );
};
