import { Trigger } from 'polpeo-go-common/types/Trigger';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { ParticipantTriggerModal } from '../../../../patterns/ParticipantTriggerModal';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { equals } from 'ramda';

export const ParticipantTriggerPopup: FC = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentTrigger } = useContext(ParticipantStateContext);
    const prevReleasedTrigger = useRef<Trigger | undefined>(currentTrigger);

    useEffect(() => {
        if (!equals(prevReleasedTrigger.current, currentTrigger) && currentTrigger) {
            setShowModal(currentTrigger.participantPopupSettings.enabled);
        }
        prevReleasedTrigger.current = currentTrigger;
    }, [currentTrigger]);

    return <>{showModal && <ParticipantTriggerModal onCloseModal={() => setShowModal(false)} />}</>;
};
