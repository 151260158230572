import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../bits/Buttons';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { NewParticipantContentItemModal } from './NewParticipantContentItemModal';
import { NewStaffContentItemModal } from './NewStaffContentItemModal';

interface NewChildContentItemButtonProps {
    page: Page;
    parent: ContentItem;
}
export const NewChildContentItemButton: FC<NewChildContentItemButtonProps> = ({ page, parent }) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const [showModal, setShowModal] = useState(false);

    const template = pageTemplates[page.templateUUID];

    return (
        <div>
            <SecondaryButton onClick={() => setShowModal(true)} small>
                Add {template.childItemOptions.name}
            </SecondaryButton>
            {showModal && staffUser && (
                <NewStaffContentItemModal page={page} parent={parent} onCloseModal={() => setShowModal(false)} />
            )}
            {showModal && participantUser && (
                <NewParticipantContentItemModal page={page} parent={parent} onCloseModal={() => setShowModal(false)} />
            )}
        </div>
    );
};
