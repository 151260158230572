import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { CardContainer } from '../bits/Card';
import { Display } from '../bits/Display';
import { Icon } from '../bits/Icon';

const ProfileCard = styled(CardContainer)`
    padding: 10px;
`;

interface UserCardProps {
    name: string;
    teamName?: string;
}
export const UserCard: FC<UserCardProps> = ({ name, teamName }: UserCardProps) => (
    <ProfileCard>
        <Display.HorizontalWithSpacing verticalCenter>
            <Display.VerticalWithSpacing gap={5}>
                <span>{name}</span>
                {teamName && <span>{teamName}</span>}
            </Display.VerticalWithSpacing>
            <Icon icon="userNoPic"></Icon>
        </Display.HorizontalWithSpacing>
    </ProfileCard>
);
