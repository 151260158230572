/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { grey3Colour } from '../../../../../themes/colours';
import leftSidebar from './leftSidebar.png';
import negativeSocial from './negative-social.svg';
import videoThumbnail from './videoThumbnail.png';
import logo from './logo.png';
import positiveSocial from './positive-social.svg';

const pageTemplate: PageTemplate = {
    uuid: 'ce5fc711-45c3-446c-ae3e-b6de00776c8b',
    name: 'Video Network',
    category: 'Video Sharing',
    icon: logo,

    rootItemOptions: {
        name: 'Video',
        requiredFields: ['title', 'video', 'thumbnail', 'description'],
        fields: [
            { name: 'title', type: ContentItemFieldType.TEXT },
            { name: 'video', type: ContentItemFieldType.VIDEO },
            { name: 'thumbnail', type: ContentItemFieldType.IMAGE },
            { name: 'description', type: ContentItemFieldType.LONG_TEXT },
        ],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: {
                enabled: true,
                displayName: undefined,
                img: negativeSocial,
            },
            shares: {
                enabled: false,
            },
        },
    },
    showItemsAsIndividualPages: { pageTitleField: 'title' },
    childItemOptions: {
        name: 'Comment',
        pluralName: 'Comments',
        requiredFields: ['comment'],
        fields: [{ name: 'comment', type: ContentItemFieldType.LONG_TEXT }],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: {
                enabled: true,
                displayName: undefined,
                img: negativeSocial,
            },
            shares: {
                enabled: false,
            },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 2 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.GRID, columnWidth: 250 },
        childrenSettings: {},
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['thumbnail', 'title', 'meta', 'childrenCount'],
        childContentFieldOrder: [],
        detailsLinkFields: ['all'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: false,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: {
                    img: leftSidebar,
                    margin: { right: 10 },
                },
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                verticalGap: 30,
                horizontalGap: 30,
                maxWidth: 810,
            },
            rootItem: {
                maxWidth: 250,
                avatar: { position: 'inline', width: 20, height: 20 },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {
                    font: {
                        color: grey3Colour,
                    },
                    margin: {
                        top: 6,
                    },
                },
                interactions: {
                    horizontalGap: 100,
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    title: {
                        font: {
                            size: 'LARGE',
                            weight: 'BOLD',
                        },
                        margin: {
                            top: 4,
                        },
                    },
                },
                showChildItemsCount: {
                    position: 'use field order',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                    alignSelf: 'end',
                    margin: { top: 10 },
                },
            },
            childrenItemsContainer: {},
            childItemContainer: {},
            childItem: {
                avatar: {
                    position: 'gutter',
                },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 10,
                    iconHeight: 12,
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    comment: {},
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'append',
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['video', 'title', 'meta', 'description', 'interactions'],
        childContentFieldOrder: ['meta', 'comment', 'interactions'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                },
                leftSidebar: {
                    margin: { right: 10 },
                },
                main: { width: 800 },
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                verticalGap: 0,
                horizontalGap: 0,
            },
            rootItem: {
                avatar: { position: 'inline' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: { layout: 'flex-end', horizontalGap: 24 },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    video: {
                        width: 800,
                    },
                    title: {
                        font: {
                            size: 'LARGE',
                            weight: 'BOLD',
                        },
                        margin: {
                            top: 8,
                        },
                    },
                    description: {
                        borders: { top: { width: 1, color: '#dddddd' }, bottom: { width: 1, color: '#dddddd' } },
                        padding: { top: 10, bottom: 10 },
                        margin: { top: 10, bottom: 10 },
                    },
                },
            },
            childrenItemsContainer: {},
            childItemContainer: { margin: { left: 18 } },
            childItem: {
                margin: { bottom: 6, top: 20 },
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 24,
                    iconHeight: 14,
                    padding: { top: 6, bottom: 12 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {},
            },
        },
    },
};

export default pageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                title: 'Lorem ipsum dolor sit amet',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                description: 'Nam euismod metus at tellus ultrices luctus',
                thumbnail: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 93,
                negative: 72,
            },
            data: {
                comment: 'Morbi venenatis turpis mi, non fermentum sapien condimentum ultricies',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 21,
                negative: 3,
            },
            data: {
                comment: 'Phasellus a odio feugiat, tempor turpis et, fermentum neque',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidba',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 39,
                negative: 19,
            },
            data: {
                comment: 'Vestibulum eleifend eget est in fringilla',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 45,
                negative: 23,
            },
            data: {
                comment:
                    'Suspendisse vehicula facilisis enim, ut ultrices erat tempus sit amet. Donec aliquam tristique risus, facilisis finibus quam elementum vel.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbaa',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidba',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 20,
                negative: 26,
            },
            data: {
                comment:
                    'Vivamus aliquam mi at fringilla sagittis. In vehicula lorem eget lorem pretium ultrices. Nam tellus dolor, convallis ut semper a, aliquet ac tortor. Cras auctor vel ligula eu consequat. Vivamus venenatis convallis nulla, ut porta nisi vestibulum nec. Nunc nisl nisi, pulvinar in imperdiet sit amet, hendrerit et eros.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                title: 'Bupa dup dup dop',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                description: 'Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
                thumbnail: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid3',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                title: 'In vehicula lorem eget lorem pretium ultrices.',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                description:
                    'Donec erat sem, lobortis ut sem sed, iaculis egestas lectus. Fusce ante ex, malesuada non venenatis vel, dignissim id tellus. Duis bibendum rutrum nunc, at gravida dolor malesuada at.',
                thumbnail: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid4',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3, hours: 11 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                title: 'In hac habitasse platea dictumst',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                description:
                    'Nulla porta lectus a lacus sodales scelerisque. Vivamus fermentum est justo, accumsan egestas purus hendrerit quis. Sed ac est nec nulla dapibus gravida in in purus.',
                thumbnail: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid5',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 4 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                title: 'Sed tincidunt dapibus odio vel pellentesque',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                description: 'Nam feugiat quam sed tortor iaculis, congue elementum nisi pharetra',
                thumbnail: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
