import { Moment } from 'polpeo-go-common/types/Moment';
import { fromPairs, map } from 'ramda';
import { Client } from '../graphql/client';
import { getMoments } from '../graphql/moment';
import { decodeMoment } from './decodeMoment';

export const fetchMoments = async (client: Client, simulationUUID: string): Promise<Record<string, Moment>> => {
    let moments: Moment[] = [];
    let momentsContinueFrom: undefined | string;

    do {
        const getMomentsResponse = await getMoments.promise(client, {
            simulationUUID: simulationUUID,
            startFrom: momentsContinueFrom,
        });
        if (!getMomentsResponse.data) {
            return {};
        }
        const cleanedContents = map(
            (moment) =>
                decodeMoment({
                    ...moment,
                    contentItem: {
                        ...moment.contentItem,
                        content: {
                            ...moment.contentItem.content,
                            data: JSON.parse(moment.contentItem.content.data),
                        },
                    },
                }),
            getMomentsResponse.data.getMoments.moments,
        );
        moments = [...moments, ...cleanedContents];
        momentsContinueFrom = getMomentsResponse.data.getMoments.continueFrom;
    } while (momentsContinueFrom);
    return fromPairs(map((moment) => [moment.uuid, moment], moments));
};
