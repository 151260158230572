import { always, mergeDeepRight } from 'ramda';
import React, { createContext, FC, useState } from 'react';
import WithChildren from '../../utils/WithChildren';

interface SideBarState {
    collapsed?: boolean;
}
interface SidebarStateContextValue extends SideBarState {
    updateSidebarState: (state: Partial<SideBarState>) => void;
}
const defaultSidebarState: SidebarStateContextValue = {
    collapsed: false,
    updateSidebarState: always(undefined),
};
export const SideBarStateContext = createContext<SidebarStateContextValue>(defaultSidebarState);

export const WithSideBarState: FC<WithChildren> = ({ children }: WithChildren) => {
    const [sidebarState, setSidebarState] = useState<SideBarState>({});

    const updateSidebarState = (newState: Partial<SideBarState>) => {
        setSidebarState(mergeDeepRight(sidebarState, newState));
    };

    return (
        <SideBarStateContext.Provider value={{ ...sidebarState, updateSidebarState }}>
            {children}
        </SideBarStateContext.Provider>
    );
};
