import * as t from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { omit, pick } from 'ramda';
import { optionalToUndefined } from '../t';
import { TeamOverviewData } from './Team';
import { InitialScratchPadDocument, TEST_RICH_TEXT_SCRATCHPAD_DOCUMENT } from './InitialScratchPadDocument';
import { UserRole } from './StaffUser';

export const BriefingDocument = t.type({
    name: t.string,
    createdAt: t.union([DateFromISOString, date]),
    path: t.string,
    createdBy: t.string,
});
export type BriefingDocument = t.TypeOf<typeof BriefingDocument>;

export const BriefingNotes = t.type({
    lastEditedAt: t.union([DateFromISOString, date]),
    lastEditedBy: t.string,
    notes: t.string,
});
export type BriefingNotes = t.TypeOf<typeof BriefingNotes>;

export const Simulation = t.type({
    uuid: t.string,
    name: t.string,
    code: t.string,
    createdAt: t.union([DateFromISOString, date]),
    createdBy: t.string,
    scheduledFor: optionalToUndefined(t.union([DateFromISOString, date])),
    lobbyOpenedAt: optionalToUndefined(t.union([DateFromISOString, date])),
    startedAt: optionalToUndefined(t.union([DateFromISOString, date])),
    completedAt: optionalToUndefined(t.union([DateFromISOString, date])),
    archivedAt: optionalToUndefined(t.union([DateFromISOString, date])),
    clientName: t.string,
    isReady: t.boolean,
    emailDomainWhitelist: t.array(t.string),
    expectedNumberParticipants: optionalToUndefined(t.number),
    administratorUUIDs: t.array(t.string),
    briefingDocuments: optionalToUndefined(t.array(BriefingDocument)),
    briefingNotes: optionalToUndefined(BriefingNotes),
    startingOverview: optionalToUndefined(TeamOverviewData),
    scratchPadDocuments: optionalToUndefined(t.array(InitialScratchPadDocument)),
    showParticipantsMomentsGraph: t.boolean,
    createMomentsPermission: t.array(UserRole),
});
export type Simulation = t.TypeOf<typeof Simulation>;
export const TEST_SIMULATION: Simulation = {
    uuid: 'simulationUUID',
    name: 'Test Simulation',
    code: 'CODE',
    createdAt: new Date(),
    createdBy: 'sub',
    scheduledFor: undefined,
    lobbyOpenedAt: undefined,
    startedAt: undefined,
    completedAt: undefined,
    archivedAt: undefined,
    clientName: 'Client',
    isReady: false,
    emailDomainWhitelist: [],
    expectedNumberParticipants: 100,
    administratorUUIDs: [],
    briefingDocuments: [
        {
            name: 'briefing_doc.docx',
            createdAt: new Date(),
            path: '/uploads/testurl',
            createdBy: 'userUUID',
        },
    ],
    briefingNotes: {
        lastEditedAt: new Date(),
        lastEditedBy: 'admin',
        notes: '<p>Notes Here<p>',
    },
    startingOverview: undefined,
    scratchPadDocuments: [TEST_RICH_TEXT_SCRATCHPAD_DOCUMENT],
    showParticipantsMomentsGraph: true,
    createMomentsPermission: ['ADMIN'],
};

export const NewSimulation = t.type(
    pick(
        [
            'name',
            'scheduledFor',
            'clientName',
            'emailDomainWhitelist',
            'expectedNumberParticipants',
            'administratorUUIDs',
            'startingOverview',
            'showParticipantsMomentsGraph',
            'createMomentsPermission',
        ],
        Simulation.props,
    ),
);
export type NewSimulation = t.TypeOf<typeof NewSimulation>;
export const TEST_NEW_SIMULATION: NewSimulation = {
    name: 'Test Simulation',
    clientName: 'Client',
    emailDomainWhitelist: [],
    expectedNumberParticipants: 100,
    scheduledFor: undefined,
    administratorUUIDs: [],
    startingOverview: {},
    createMomentsPermission: ['ADMIN'],
    showParticipantsMomentsGraph: true,
};

export const BasicSimulation = t.exact(
    t.type(
        pick(
            ['uuid', 'name', 'code', 'startedAt', 'completedAt', 'clientName', 'showParticipantsMomentsGraph'],
            Simulation.props,
        ),
    ),
);
export type BasicSimulation = t.TypeOf<typeof BasicSimulation>;

export const DynamoDbSimulationValue = t.type(omit(['uuid'], Simulation.props));
export type DynamoDbSimulationValue = t.TypeOf<typeof DynamoDbSimulationValue>;
