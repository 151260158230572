import gql from 'graphql-tag';
import { GraphQlMoment } from 'polpeo-go-common/types/Moment';
import wrapSubscription from '../wrapSubscription';

interface OnMomentParams {
    simulationUUID: string;
}

interface OnMomentData {
    onMoment: GraphQlMoment;
}

const subscription = gql`
    subscription onMoment($simulationUUID: String!) {
        onMoment(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            created {
                at
                by
            }
            contentItem {
                type
                uuid
                simulationUUID
                teamUUID
                parentUUID
                createdAt
                persona
                content {
                    pageUUID
                    interactions {
                        positive
                        negative
                        shares
                    }
                    data
                }
                prepreparedContentUUID
                createdBy {
                    type
                    uuid
                }
                deleted {
                    at
                    by {
                        type
                        uuid
                    }
                }
            }
            rating
            comments
        }
    }
`;

export const onMoment = wrapSubscription<OnMomentParams, OnMomentData>(subscription);
