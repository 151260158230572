import { decodeEmailItem, EmailItem } from 'polpeo-go-common/types/EmailItem';
import { equals } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editEmailItem } from '../../../graphql/emailItem';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../patterns/Modal';
import { ParticipantStateContext } from '../../WithParticipantState';
import { EmailItemForm } from './EmailItemForm';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';

type EditEmailItemModalProps = {
    onCloseModal: () => void;
    item: EmailItem;
};

export const EditEmailItemModal: FC<EditEmailItemModalProps> = (props) => {
    const { onCloseModal, item } = props;
    const { addEmailItems: adminAddEmailItems } = useContext(AdminInSimulationStateContext);
    const { addEmailItems: participantAddEmailItems } = useContext(ParticipantStateContext);
    const addEmailItems = adminAddEmailItems || participantAddEmailItems || {};
    const [currentEmailItem, setCurrentEmailItem] = useState<EmailItem>(item);

    const [editEmailItemMutation, { data: editEmailItemData, loading: editEmailItemsLoading }] = editEmailItem.hook();
    useEffect(() => {
        if (editEmailItemData?.editEmailItem) {
            addEmailItems([decodeEmailItem(editEmailItemData.editEmailItem)]);
            onCloseModal();
        }
    }, [editEmailItemData]);

    const hasUnsavedChanges = !equals(currentEmailItem, item);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={'80%'} header={'Edit Email'}>
                {editEmailItemsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editEmailItemsLoading && (
                    <EmailItemForm
                        currentEmail={currentEmailItem}
                        onChange={(newState) => setCurrentEmailItem((oldState) => ({ ...oldState, ...newState }))}
                        onSubmit={() => editEmailItemMutation({ variables: { emailItem: currentEmailItem } })}
                        onCancel={onModalCloseUnsavedPrompt}
                        submitLabel="Save"
                    />
                )}
            </Modal>
        </>
    );
};
