import { Simulation, StaffUser } from '../types';
import { anyPass } from 'ramda';

export const userCanCreateMoments = (simulation: Simulation, staffUser: StaffUser): boolean => {
    const { createMomentsPermission } = simulation;
    const userCanCreateMoments = anyPass([
        () => staffUser.role === 'ADMIN' && createMomentsPermission.includes('ADMIN'),
        () => staffUser.role === 'ROLEPLAYER' && createMomentsPermission.includes('ROLEPLAYER'),
    ])();
    return userCanCreateMoments;
};
