export const deletedPlaceholderTheme = {
    bgColour: '#eeeeee',
    borderRadius: 10,
    borders: {
        top: { width: 1, color: '#dddddd' },
        left: { width: 1, color: '#dddddd' },
        bottom: { width: 1, color: '#dddddd' },
        right: { width: 1, color: '#dddddd' },
    },
    padding: {
        top: 10,
        left: 10,
        bottom: 10,
        right: 10,
    },
    margin: {
        top: 5,
        bottom: 5,
    },
};
