import gql from 'graphql-tag';
import wrapMutation from '../wrapMutation';

interface LockTeamsParams {
    simulationUUID: string;
}

interface LockTeamsData {
    lockTeams: {
        simulationUUID: string;
        assignments: string; // AWSJSON - Record<string, ParticipantTeamAssignment>
    };
}

const mutation = gql`
    mutation lockTeams($simulationUUID: String!) {
        lockTeams(simulationUUID: $simulationUUID) {
            simulationUUID
            assignments
        }
    }
`;

export const lockTeams = wrapMutation<LockTeamsParams, LockTeamsData>(mutation);
