import { useApolloClient } from '@apollo/client';
import { decodeSimulationParticipantAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { reduce } from 'ramda';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { getParticipantsForSimulation } from '../../graphql/participantUsers';
import { getTeamAssignments } from '../../graphql/simulations';
import { fetchSimulationContent } from '../../utils/fetchSimulationContent';
import WithChildren from '../../utils/WithChildren';
import { FullPageError } from '../patterns/FullPageError';
import { FullPageLoadingScreen } from '../patterns/FullPageLoadingScreen';
import { AdminStateContext } from '../WithAdminState/adminState';
import { AdminManageSimulationContext } from './adminManageSimulationState';

export * from './adminManageSimulationState';

export const WithAdminManageSimulationState: FC<WithChildren> = ({ children }: WithChildren) => {
    const client = useApolloClient();
    const { simulationUUID } = useParams<{ simulationUUID: string }>();

    const {
        simulations,
        simulationContent,
        teams: simulationTeams,
        participants: allParticipants,
        setParticipants,
        setTeamAssignments,
        setSimulationContent,
    } = useContext(AdminStateContext);

    const currentSimulation = useMemo(() => simulations[simulationUUID || ''], [simulations, simulationUUID]);
    const currentSimulationContent = useMemo(
        () => simulationContent[simulationUUID || ''],
        [simulationContent, simulationUUID],
    );
    const currentSimulationTeams = useMemo(
        () => simulationTeams[simulationUUID] || {},
        [simulationTeams, simulationUUID],
    );
    const currentParticipants = useMemo(() => allParticipants[simulationUUID] || {}, [allParticipants, simulationUUID]);

    useEffect(() => {
        if (currentSimulation) {
            // asynchronously get all the content for a simulation
            // Main reason for this is because we need to be able to loop over getPrepreparedContents
            // for as long as needed to get all the items. This isn't done easily with the apollo hook
            const fetchAndSetSimulationContent = async () => {
                const content = await fetchSimulationContent(client, simulationUUID);
                setSimulationContent(simulationUUID, content);
            };

            fetchAndSetSimulationContent();
        }
    }, [currentSimulation]);

    // Participants and team assignments
    const { data: GetParticipantsData } = getParticipantsForSimulation.hook({
        simulationUUID: currentSimulation?.uuid,
    });
    useEffect(() => {
        if (GetParticipantsData?.getParticipantsForSimulation) {
            const incomingParticipantList = GetParticipantsData.getParticipantsForSimulation;
            const updatedParticipants = reduce(
                (acc, participant) => {
                    return { ...acc, [participant.id]: participant };
                },
                currentParticipants,
                incomingParticipantList,
            );
            setParticipants(currentSimulation.uuid, updatedParticipants);
        }
    }, [GetParticipantsData]);

    const { data: GetTeamAssignmentsData } = getTeamAssignments.hook({
        simulationUUID: currentSimulation?.uuid,
    });
    useEffect(() => {
        const data = GetTeamAssignmentsData?.getTeamAssignments;
        if (data) {
            const teamAssignments = decodeSimulationParticipantAssignments({
                ...data,
                assignments: JSON.parse(data.assignments),
            });
            setTeamAssignments(currentSimulation.uuid, teamAssignments.assignments);
        }
    }, [GetTeamAssignmentsData]);

    if (!currentSimulation) {
        return <FullPageError errorCode="404" message="Simulation could not be found" />;
    }

    if (!currentSimulationContent) {
        return <FullPageLoadingScreen />;
    }

    return (
        <AdminManageSimulationContext.Provider
            value={{
                currentSimulation,
                currentSimulationContent,
                currentSimulationTeams,
            }}
        >
            {children}
        </AdminManageSimulationContext.Provider>
    );
};
