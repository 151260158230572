import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { date } from 'io-ts-types/lib/date';
import optionalToUndefined from '../t/optionalToUndefined';
import { contains, last, omit, pipe, replace, split } from 'ramda';

export const ScratchPadCategory = t.union([t.literal('Statements'), t.literal('Strategy'), t.literal('Resources')]);
export type ScratchPadCategory = t.TypeOf<typeof ScratchPadCategory>;

export const ScratchPadDocument = t.type({
    uuid: t.string,
    simulationUUID: t.string,
    teamUUID: t.string,
    category: ScratchPadCategory,
    path: optionalToUndefined(t.string),
    createdAt: t.union([DateFromISOString, date]),
    createdBy: t.string,
    lastEditedBy: optionalToUndefined(t.string),
    notes: optionalToUndefined(t.string),
    name: t.string,
    currentEditor: optionalToUndefined(t.string),
});
export type ScratchPadDocument = t.TypeOf<typeof ScratchPadDocument>;

export const NewScratchPadDocument = t.type(
    omit(['uuid', 'createdAt', 'createdBy', 'lastEditedBy'], ScratchPadDocument.props),
);
export type NewScratchPadDocument = t.TypeOf<typeof NewScratchPadDocument>;

export const TEST_RICH_TEXT_SCRATCHPAD_DOCUMENT: ScratchPadDocument = {
    uuid: 'uuid',
    simulationUUID: 'simulationUUID',
    teamUUID: 'teamUUID',
    name: 'name',
    category: 'Strategy',
    path: undefined,
    notes: '<p>Test</p>',
    createdAt: new Date(),
    createdBy: 'admin',
    lastEditedBy: 'admin',
    currentEditor: undefined,
};

// This is intended to restrict the file types that a user can upload in an input
export const acceptedFileTypes = [
    'audio/*',
    '.csv',
    '.doc',
    '.docx',
    'image/*',
    '.pdf',
    '.txt',
    'video/*',
    '.xls',
    '.xlsx',
    '.xml',
    '.odt',
    '.ppt',
    '.pptx',
];

// This is itended to be used to check file type in the lambda should the front
// end not successfully prevent upload
export const acceptedMimeFileTypesCheck = [
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'text/plain',
    'text/csv',
    'video/mp2t',
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/x-msvideo',
    '',
];
export const getScratchPadDocumentUUIDFromSk = (sk: string): string => {
    const cleanedSk = pipe(replace('scratchPadDocument[', ''), replace(']', ''))(sk);
    if (contains('_', sk)) {
        return pipe<string, string[], string>(split('_'), last)(cleanedSk);
    }
    return cleanedSk;
};
