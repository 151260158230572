import { EmailItem, PrepreparedEmailItem, staffAllTeamIdentifierToDisplay } from 'polpeo-go-common/types/EmailItem';
import { ifElse, join, map, pipe } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import { richtextToString } from '../../utils/richtextToString';
import { CardSection } from '../bits/Card';
import { RichtextDisplay } from '../bits/RichtextDisplay';
import { Card } from './Card';
import { DateTime } from 'luxon';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { dateTimestampRelativeToSimStart } from '../../utils/dateTimestampRelativeToSimStart';
import { AdminStateContext } from '../WithAdminState/adminState';
import { AdminInSimulationStateContext } from '../WithAdminInSimulationState';
import { AdminManageSimulationContext } from '../WithAdminManageSimulationState';
import { getEmailItemTeamName } from '../../utils/getEmailItemTeamName';

const HalfHalfGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const DataField = styled.div`
    display: inline-block;
    max-width: 100%;
    overflow-wrap: anywhere;
`;

const makeEmailMessagePreview = pipe(richtextToString, (message) =>
    message.length > 200 ? `${message.substring(0, 200)}...` : message,
);

interface EmbeddedEmailItemProps {
    item: EmailItem | PrepreparedEmailItem;
    small?: boolean;
    useTimestampRelativeToSimulationStart?: Simulation;
}

export const EmbeddedEmailItem: FC<EmbeddedEmailItemProps> = ({
    item,
    small,
    useTimestampRelativeToSimulationStart,
}) => {
    const { participants: allParticipants, teams } = useContext(AdminStateContext);
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const { simulation } = useContext(AdminInSimulationStateContext);
    const participants = allParticipants[item.simulationUUID];

    const createdAtTimestamp = useMemo(() => {
        const createdAt = DateTime.fromJSDate('createdAt' in item ? item.createdAt : new Date());
        if (
            !useTimestampRelativeToSimulationStart ||
            !useTimestampRelativeToSimulationStart.startedAt ||
            !('createdAt' in item)
        ) {
            return createdAt.toRelative();
        }
        return dateTimestampRelativeToSimStart(useTimestampRelativeToSimulationStart, item.createdAt);
    }, [item, useTimestampRelativeToSimulationStart]);

    const displayNameForParticipant =
        'createdBy' in item && !!participants ? participants[item.createdBy.uuid]?.fullName : '';

    const emailSenderDetails = ifElse(
        (item) => item.persona,
        () => item.persona,
        () => displayNameForParticipant,
    )(item);

    const getParticipantDisplayName = (participantID: string) => {
        const participantUser = participants[participantID];
        if (participantUser) {
            return participantUser.fullName;
        }
    };

    const teamsForCurrentSimulation = currentSimulation ? teams[currentSimulation.uuid] : teams[simulation.uuid];

    const isDeleted = 'deleted' in item && item.deleted;
    const message = isDeleted ? 'This email has been deleted' : item.content.message;

    return (
        <Card cardWidth="100%">
            <CardSection>
                <HalfHalfGrid>
                    <DataField>
                        <b>To:</b>{' '}
                        {join(
                            ', ',
                            map(
                                (recipient) =>
                                    staffAllTeamIdentifierToDisplay(
                                        recipient,
                                        getEmailItemTeamName(teamsForCurrentSimulation, item) || 'All Teams',
                                    ) ||
                                    getParticipantDisplayName(recipient) ||
                                    recipient,
                                item.content.recipients,
                            ),
                        )}
                    </DataField>
                    <DataField>
                        <b>From:</b> {emailSenderDetails}
                    </DataField>
                </HalfHalfGrid>
            </CardSection>
            {!isDeleted && (
                <>
                    <CardSection>
                        <DataField>
                            <b>Subject:</b> {item.content.subject || '(no subject)'}
                        </DataField>
                    </CardSection>

                    <CardSection>
                        <b>Sent:</b> {createdAtTimestamp}
                    </CardSection>
                </>
            )}
            <CardSection>
                <DataField>{small ? makeEmailMessagePreview(message) : <RichtextDisplay value={message} />}</DataField>
            </CardSection>
        </Card>
    );
};
