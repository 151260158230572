import { EmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Team } from 'polpeo-go-common/types/Team';

export const getEmailItemTeamName = (teams: Record<string, Team>, item: EmailItem | PrepreparedEmailItem): string => {
    if ('teamUUID' in item) {
        const itemTeam = teams[item.teamUUID].name;
        return itemTeam;
    }
    return '';
};
