import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import { ScratchPadCategory } from 'polpeo-go-common/types/ScratchPadDocument';
import { includes, map, mapObjIndexed, reduce, reject, sortBy } from 'ramda';
import React, { FC, useContext, useMemo, useState } from 'react';
import { CardBody, CardSection } from '../../../bits/Card';
import { Display } from '../../../bits/Display';
import { Subtitle2 } from '../../../bits/Headers';
import { Collapsible } from '../../../patterns/Collapsible';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState';
import { AddInitialDocumentButton } from './AddInitialDocumentButton';
import { InitialScratchPadDocumentItem } from './InitialScratchPadDocumentItem';

const scratchpadCategories = ['Statements', 'Strategy', 'Resources'] as const;

interface InitialScratchPadDocumentListProps {
    disableManageDocuments?: boolean;
}

export const InitialScratchPadDocumentList: FC<InitialScratchPadDocumentListProps> = ({ disableManageDocuments }) => {
    const { currentSimulation: initialSimulation } = useContext(AdminManageSimulationContext);
    const { scratchPadDocuments: documents } = initialSimulation;
    const [expandedGroups, setExpandedGroups] = useState<Array<'Statements' | 'Strategy' | 'Resources'>>([]);

    const toggleGroupCollapsed = (id: ScratchPadCategory) => {
        const collapsed = includes(id, expandedGroups);
        if (collapsed) {
            setExpandedGroups(reject((x) => x === id, expandedGroups));
        } else {
            setExpandedGroups([...expandedGroups, id]);
        }
    };

    const categorisedDocuments = useMemo(() => {
        const groupedDocs = reduce(
            (acc, doc) => (acc[doc.category] ? { ...acc, [doc.category]: [...acc[doc.category], doc] } : acc),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { Statements: [], Strategy: [], Resources: [] } as Record<string, InitialScratchPadDocument[]>,
            documents || [],
        );
        return mapObjIndexed((docs) => sortBy((d) => d.name, docs), groupedDocs);
    }, [documents]);

    return (
        <>
            <CardBody>
                {map((category) => {
                    const documentsForThisCategory = categorisedDocuments[category] || [];
                    return (
                        <CardSection key={category}>
                            <Collapsible
                                title={`${category} (${documentsForThisCategory.length} ${
                                    documentsForThisCategory.length === 1 ? 'document' : 'documents'
                                })`}
                                toggleGroupCollapsed={() => toggleGroupCollapsed(category)}
                                open={!includes(category, expandedGroups)}
                            >
                                {map(
                                    (doc: InitialScratchPadDocument) => (
                                        <InitialScratchPadDocumentItem
                                            key={doc.uuid}
                                            document={doc}
                                            title={category}
                                            disableManageDocuments={disableManageDocuments}
                                        />
                                    ),
                                    documentsForThisCategory,
                                )}
                                {!documentsForThisCategory.length && (
                                    <Subtitle2>No documents in this section.</Subtitle2>
                                )}
                                {!disableManageDocuments && (
                                    <Display.VerticalWithSpacing horizontalAlign="start">
                                        <AddInitialDocumentButton category={category} />
                                    </Display.VerticalWithSpacing>
                                )}
                            </Collapsible>
                        </CardSection>
                    );
                }, scratchpadCategories)}
            </CardBody>
        </>
    );
};
