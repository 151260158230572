import { ContentInteractionSettings } from 'polpeo-go-common/types/PageTemplate';
import { map, toPairs } from 'ramda';
import React, { FC } from 'react';
import { Display } from '../../bits/Display';
import { NumberInput } from '../../bits/FormFields';
import styled from 'styled-components/macro';
import { InteractionsData } from 'polpeo-go-common/types/ContentItem';

const InteractionImage = styled.img`
    height: 20px;
    margin-bottom: 10px;
`;

const InteractionTitle = styled.div`
    height: 20px;
    margin-bottom: 10px;
`;

interface InteractionsFieldProps {
    value: InteractionsData;
    onChange: (interactions: InteractionsData) => void;
    interactionFields: ContentInteractionSettings;
}

export const InteractionsField: FC<InteractionsFieldProps> = ({ value, onChange, interactionFields }) => (
    <Display.HorizontalWithSpacing key="Interactions">
        {map(
            ([interactionKey, interactionSettings]) =>
                interactionSettings.enabled ? (
                    <div key={interactionKey}>
                        {!!interactionSettings.img && <InteractionImage src={interactionSettings.img} />}
                        {interactionSettings.displayName && (
                            <InteractionTitle>{interactionSettings.displayName}</InteractionTitle>
                        )}
                        <NumberInput
                            onChange={(e) =>
                                onChange({
                                    ...value,
                                    [interactionKey]: parseInt(e.target.value, 10),
                                })
                            }
                            value={value[interactionKey] || 0}
                        />
                    </div>
                ) : null,
            toPairs(interactionFields),
        )}
    </Display.HorizontalWithSpacing>
);
