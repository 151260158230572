import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { userCanCreateMoments } from 'polpeo-go-common/permissions/userCanCreateMoments';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { anyPass, reduce } from 'ramda';
import React, { FC, useContext, useRef, useState } from 'react';
import { grey4Colour } from '../../../themes/colours';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';
import { ParticipantUserContext } from '../../WithParticipantUser';
import { StaffUserContext } from '../../WithStaffUser';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { Icon } from '../../bits/Icon';
import { DeleteContentItemModal } from '../../patterns/ContentItem';
import { NewStaffContentItemModal } from '../../patterns/ContentItem/NewStaffContentItemModal';
import { ParticipantEditContentItemModal } from '../../patterns/ContentItem/ParticipantEditContentItemModal';
import { StaffEditContentItemModal } from '../../patterns/ContentItem/StaffEditContentItemModal';
import { MenuButton, MenuItem } from '../../patterns/MenuButton';
import { NewMomentModal } from '../../patterns/Moment';

interface ManageContentItemButtonProps {
    className?: string;
    item: ContentItem;
}
export const ManageContentItemButton: FC<ManageContentItemButtonProps> = ({ className, item }) => {
    const staffUser = useContext(StaffUserContext);
    const { participantUser } = useContext(ParticipantUserContext);
    const {
        simulation: adminSimulation,
        getLinkUrlForContentItem: adminGetLinkUrl,
        simulationContent: adminSimulationContent,
    } = useContext(AdminInSimulationStateContext);
    const {
        simulation: participantSimulation,
        getLinkUrlForContentItem: participantGetLinkUrl,
        pages: participantPages,
    } = useContext(ParticipantStateContext);
    const simulation = adminSimulation || participantSimulation || {};
    const pages =
        adminSimulationContent?.pages ||
        (participantPages && reduce((acc, page) => ({ ...acc, [page.uuid]: page }), {}, participantPages)) ||
        {};

    const [currentModal, setCurrentModal] = useState<'Edit' | 'Delete' | 'Create Moment' | 'Duplicate' | undefined>();
    const closeMenuRef = useRef<(() => void) | undefined>();

    const createRootContentPermission = pages[item.content.pageUUID]?.createRootContentPermission;
    const isItemCreatedByCurrentParticipant = !!participantUser && item.createdBy.uuid === participantUser.id;
    const userCanEditContent = anyPass([
        // Staff users can always edit any content
        () => !!staffUser,
        // Participant can only edit their own if they can also create content on the page
        () => createRootContentPermission === 'BOTH' && isItemCreatedByCurrentParticipant,
        () => createRootContentPermission === 'PARTICIPANTS' && isItemCreatedByCurrentParticipant,
    ])();

    const participantDeleteContentPermission = pages[item.content.pageUUID]?.participantDeleteContentPermission;
    const userCanDeleteContent = anyPass([
        // Staff users can delete any content
        () => !!staffUser,
        // Participants need permission to delete any content
        () => participantDeleteContentPermission === 'ALL',
        () => participantDeleteContentPermission === 'CREATED' && isItemCreatedByCurrentParticipant,
    ])();

    return (
        <>
            <MenuButton
                className={className}
                direction="right"
                closeMenuRef={closeMenuRef}
                buttonComponent={(props) => (
                    <IconButton
                        icon="accordianOpen"
                        fill={grey4Colour}
                        size={20}
                        onClick={(e) => {
                            if (props.onClick) {
                                props.onClick(e);
                            }
                        }}
                    />
                )}
            >
                {!!staffUser && userCan(PermissionKey.PARTICIPATE_IN_SIMULATIONS, staffUser) && (
                    <MenuItem onClick={() => setCurrentModal('Duplicate')}>
                        <Display.HorizontalWithSpacing>
                            <Icon icon="duplicate" size={18} />
                            Duplicate Item
                        </Display.HorizontalWithSpacing>
                    </MenuItem>
                )}
                {adminSimulation &&
                    userCanCreateMoments(adminSimulation, staffUser) &&
                    item.createdBy.type === 'PARTICIPANT' && (
                        <MenuItem
                            onClick={() => {
                                setCurrentModal('Create Moment');
                                if (closeMenuRef && closeMenuRef.current) {
                                    closeMenuRef.current();
                                }
                            }}
                        >
                            <Display.HorizontalWithSpacing>
                                <Icon icon="moment" size={18} />
                                Create Highlight
                            </Display.HorizontalWithSpacing>
                        </MenuItem>
                    )}
                {!simulation.completedAt && userCanEditContent && (
                    <MenuItem
                        onClick={() => {
                            setCurrentModal('Edit');
                            if (closeMenuRef && closeMenuRef.current) {
                                closeMenuRef.current();
                            }
                        }}
                    >
                        <Display.HorizontalWithSpacing>
                            <Icon icon="edit" size={18} />
                            Edit
                        </Display.HorizontalWithSpacing>
                    </MenuItem>
                )}
                {!simulation.completedAt && !item.deleted && userCanDeleteContent && (
                    <MenuItem
                        onClick={() => {
                            setCurrentModal('Delete');
                            if (closeMenuRef && closeMenuRef.current) {
                                closeMenuRef.current();
                            }
                        }}
                    >
                        <Display.HorizontalWithSpacing>
                            <Icon icon="delete" size={18} />
                            Delete
                        </Display.HorizontalWithSpacing>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        const { host } = window.location;
                        if (staffUser) {
                            navigator.clipboard.writeText(`${host}${adminGetLinkUrl(item)}`);
                        }
                        if (participantUser) {
                            navigator.clipboard.writeText(`${host}${participantGetLinkUrl(item)}`);
                        }

                        if (closeMenuRef && closeMenuRef.current) {
                            closeMenuRef.current();
                        }
                    }}
                >
                    <Display.HorizontalWithSpacing>
                        <Icon icon="copyLink" size={18} />
                        Copy Link
                    </Display.HorizontalWithSpacing>
                </MenuItem>
            </MenuButton>
            {currentModal === 'Create Moment' && (
                <NewMomentModal onCloseModal={() => setCurrentModal(undefined)} item={item} />
            )}
            {staffUser && currentModal === 'Edit' && (
                <StaffEditContentItemModal onCloseModal={() => setCurrentModal(undefined)} item={item} />
            )}
            {participantUser && currentModal === 'Edit' && (
                <ParticipantEditContentItemModal onCloseModal={() => setCurrentModal(undefined)} item={item} />
            )}
            {currentModal === 'Delete' && (
                <DeleteContentItemModal onCloseModal={() => setCurrentModal(undefined)} item={item} />
            )}
            {currentModal === 'Duplicate' && (
                <NewStaffContentItemModal
                    page={pages[item.content.pageUUID]}
                    onCloseModal={() => setCurrentModal(undefined)}
                    copyOf={item}
                />
            )}
        </>
    );
};
