import {
    ContentItem,
    decodeContentItem,
    decodePrepreparedContentItem,
    PrepreparedContentItem,
} from 'polpeo-go-common/types/ContentItem';
import {
    decodeEmailItem,
    decodePrepreparedEmailItem,
    EmailItem,
    PrepreparedEmailItem,
} from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Page } from 'polpeo-go-common/types/Page';
import { assocPath, fromPairs, map, reduce } from 'ramda';
import { SimulationContent } from '../components/WithAdminState/adminState';
import { Client } from '../graphql/client';
import { getContentItems } from '../graphql/contentItem';
import { getEmailItems } from '../graphql/emailItem';
import { getMoments } from '../graphql/moment';
import { getPagesAndPageOrder } from '../graphql/pages';
import { getPrepreparedContents } from '../graphql/prepreparedContent';
import { getPrepreparedEmails } from '../graphql/prepreparedEmail';
import { getTriggersAndRelatedData } from '../graphql/triggers';
import { decodeMoment } from './decodeMoment';
import { decodeTrigger } from './decodeTrigger';

export const fetchedPagesAndPageOrder = async (
    client: Client,
    simulationUUID: string,
): Promise<{ pages: Record<string, Page>; pageOrder: string[] }> => {
    const getPagesAndPageOrderResponse = await getPagesAndPageOrder.promise(client, {
        simulationUUID,
    });
    if (!getPagesAndPageOrderResponse.data) {
        throw new Error('No page data returned');
    }
    const { getPages: pages, getPageOrder: pageOrder } = getPagesAndPageOrderResponse.data;
    return {
        pages: fromPairs(map((page) => [page.uuid, { ...page, stats: JSON.parse(page.stats) }], pages)),
        pageOrder,
    };
};

export const fetchSimulationContent = async (client: Client, simulationUUID: string): Promise<SimulationContent> => {
    // Triggers
    const getTriggersAndRelatedDataResponse = await getTriggersAndRelatedData.promise(client, {
        simulationUUID,
    });
    if (!getTriggersAndRelatedDataResponse.data) {
        throw new Error('No trigger data returned');
    }
    const {
        getTriggers: triggers,
        getTriggerOrder: triggerOrder,
        getTriggerPrepreparedContentLinks: getTriggerPrepreparedContentLinksString,
    } = getTriggersAndRelatedDataResponse.data;

    // Pages
    const { pages, pageOrder } = await fetchedPagesAndPageOrder(client, simulationUUID);

    // PrepreparedContent
    let prepreparedContents: PrepreparedContentItem[] = [];
    let prepreparedContentsContinueFrom: undefined | string;

    do {
        const getPrepreparedContentsResponse = await getPrepreparedContents.promise(client, {
            simulationUUID,
            startFrom: prepreparedContentsContinueFrom,
        });
        if (!getPrepreparedContentsResponse.data) {
            prepreparedContentsContinueFrom = undefined;
            continue;
        }
        const cleanedContents = map(
            (item) =>
                decodePrepreparedContentItem({
                    ...item,
                    content: {
                        ...item.content,
                        data: JSON.parse(item.content.data),
                    },
                    released: item.released
                        ? {
                              ...item.released,
                              items: JSON.parse(item.released.items),
                          }
                        : undefined,
                }),
            getPrepreparedContentsResponse.data.getPrepreparedContents.prepreparedContents,
        );
        prepreparedContents = [...prepreparedContents, ...cleanedContents];
        prepreparedContentsContinueFrom = getPrepreparedContentsResponse.data.getPrepreparedContents.continueFrom;
    } while (prepreparedContentsContinueFrom);

    // Content Items
    let contentItems: ContentItem[] = [];
    let contentItemsContinueFrom: undefined | string;
    do {
        const getContentItemsResponse = await getContentItems.promise(client, {
            simulationUUID,
            startFrom: contentItemsContinueFrom,
        });
        if (!getContentItemsResponse.data) {
            contentItemsContinueFrom = undefined;
            continue;
        }
        const cleanedContents = map(
            (item) =>
                decodeContentItem({
                    ...item,
                    content: {
                        ...item.content,
                        data: JSON.parse(item.content.data),
                    },
                }),
            getContentItemsResponse.data.getContentItems.contentItems,
        );
        contentItems = [...contentItems, ...cleanedContents];
        contentItemsContinueFrom = getContentItemsResponse.data.getContentItems.continueFrom;
    } while (contentItemsContinueFrom);

    // PrepreparedEmails
    let prepreparedEmails: PrepreparedEmailItem[] = [];
    let prepreparedEmailsContinueFrom: undefined | string;

    do {
        const getPrepreparedEmailsResponse = await getPrepreparedEmails.promise(client, {
            simulationUUID,
            startFrom: prepreparedEmailsContinueFrom,
        });
        if (!getPrepreparedEmailsResponse.data) {
            prepreparedEmailsContinueFrom = undefined;
            continue;
        }
        const cleanedContents = map(
            (item) =>
                decodePrepreparedEmailItem({
                    ...item,
                    released: item.released
                        ? {
                              ...item.released,
                              items: JSON.parse(item.released.items),
                          }
                        : undefined,
                }),
            getPrepreparedEmailsResponse.data.getPrepreparedEmails.prepreparedEmails,
        );
        prepreparedEmails = [...prepreparedEmails, ...cleanedContents];
        prepreparedEmailsContinueFrom = getPrepreparedEmailsResponse.data.getPrepreparedEmails.continueFrom;
    } while (prepreparedEmailsContinueFrom);

    // Email Items
    let emailItems: EmailItem[] = [];
    let emailItemsContinueFrom: undefined | string;
    do {
        const getEmailItemsResponse = await getEmailItems.promise(client, {
            simulationUUID,
            startFrom: emailItemsContinueFrom,
        });
        if (!getEmailItemsResponse.data) {
            emailItemsContinueFrom = undefined;
            continue;
        }
        const cleanedEmails = map((item) => decodeEmailItem(item), getEmailItemsResponse.data.getEmailItems.emailItems);
        emailItems = [...emailItems, ...cleanedEmails];
        emailItemsContinueFrom = getEmailItemsResponse.data.getEmailItems.continueFrom;
    } while (emailItemsContinueFrom);

    // Moments
    let moments: Moment[] = [];
    let momentsContinueFrom: undefined | string;

    do {
        const getMomentsResponse = await getMoments.promise(client, {
            simulationUUID,
            startFrom: momentsContinueFrom,
        });
        if (!getMomentsResponse.data) {
            momentsContinueFrom = undefined;
            continue;
        }
        const cleanedMoments = map(
            (moment) =>
                decodeMoment({
                    ...moment,
                    contentItem: {
                        ...moment.contentItem,
                        content: {
                            ...moment.contentItem.content,
                            data: JSON.parse(moment.contentItem.content.data),
                        },
                    },
                }),
            getMomentsResponse.data.getMoments.moments,
        );
        moments = [...moments, ...cleanedMoments];
        momentsContinueFrom = getMomentsResponse.data.getMoments.continueFrom;
    } while (momentsContinueFrom);

    return {
        triggers: fromPairs(
            map(
                (trigger) => [
                    trigger.uuid,
                    decodeTrigger({
                        ...trigger,
                        pageDressingChanges: JSON.parse(trigger.pageDressingChanges),
                    }),
                ],
                triggers,
            ),
        ),
        triggerOrder,
        triggerPrepreparedContentLinks: JSON.parse(getTriggerPrepreparedContentLinksString),
        pages,
        pageOrder,
        prepreparedContents: fromPairs(map((item) => [item.uuid, item], prepreparedContents)),
        contentItems: reduce(
            (acc, item) => assocPath([item.teamUUID, item.uuid], item, acc),
            {} as Record<string, Record<string, ContentItem>>,
            contentItems,
        ),
        prepreparedEmails: fromPairs(map((email) => [email.uuid, email], prepreparedEmails)),
        emailItems: reduce(
            (acc, item) => assocPath([item.teamUUID, item.uuid], item, acc),
            {} as Record<string, Record<string, EmailItem>>,
            emailItems,
        ),
        moments: reduce(
            (acc, moment) => assocPath([moment.contentItem.teamUUID, moment.uuid], moment, acc),
            {} as Record<string, Record<string, Moment>>,
            moments,
        ),
    };
};
