import { reduce } from 'ramda';
import { MissingAncestorError } from './errors';

export function getAncestorsForItem<T extends { uuid: string; parentUUID: string | undefined }>(
    items: T[] | Record<string, T | undefined>,
    item: T,
    max?: number,
): T[] {
    if (!item.parentUUID) {
        return [];
    }

    const itemMap: Record<string, T | undefined> = Array.isArray(items)
        ? reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {} as Record<string, T>, items)
        : items;

    let ancestors: T[] = [];
    let currentChild: T | undefined = item;
    while (currentChild && (max ? ancestors.length < max : true)) {
        if (!currentChild?.parentUUID) {
            currentChild = undefined;
            continue;
        }
        const parent: T | undefined = itemMap[currentChild.parentUUID];
        if (!parent) {
            console.error(`ParentUUID specified for item '${currentChild.uuid}' but parent item could not be found`, {
                currentChild,
                ancestors,
            });
            throw new MissingAncestorError(
                `ParentUUID specified for item '${currentChild.uuid}' but parent item could not be found`,
            );
        }
        ancestors = [...ancestors, parent];
        currentChild = parent;
    }
    return ancestors;
}
