import gql from 'graphql-tag';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import wrapSubscription from '../wrapSubscription';

interface OnReleaseTriggerParams {
    simulationUUID: string;
}

interface OnReleaseTriggerData {
    onReleaseTrigger: {
        simulationUUID: string;
        trigger: Omit<Trigger, 'pageDressingChanges'> & {
            pageDressingChanges: string /* AWSJSON - Record<string, string> */;
        };
    };
}

const subscription = gql`
    subscription onReleaseTrigger($simulationUUID: String!) {
        onReleaseTrigger(simulationUUID: $simulationUUID) {
            simulationUUID
            trigger {
                uuid
                simulationUUID
                title
                isOptional
                simulatedTime
                participantPopupSettings {
                    enabled
                    text
                    prepreparedContentItemUUID
                }
                staffPopupSettings {
                    directions
                    guidelines
                    expectedActions
                }
                overviewChanges {
                    title
                    description
                    headerImage
                }
                pageDressingChanges
                releasedAt
            }
        }
    }
`;

export const onReleaseTrigger = wrapSubscription<OnReleaseTriggerParams, OnReleaseTriggerData>(subscription);
