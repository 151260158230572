import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapMutation from '../wrapMutation';

interface RevertSimulationParams {
    simulationUUID: string;
}

interface RevertSimulationData {
    revertSimulation: Simulation;
}

const mutation = gql`
    mutation revertSimulation($simulationUUID: String!) {
        revertSimulation(simulationUUID: $simulationUUID) {
            uuid
            name
            code
            createdAt
            createdBy
            scheduledFor
            lobbyOpenedAt
            startedAt
            completedAt
            archivedAt
            clientName
            isReady
            emailDomainWhitelist
            expectedNumberParticipants
            administratorUUIDs
            briefingDocuments {
                name
                createdAt
                path
                createdBy
            }
            briefingNotes {
                lastEditedAt
                lastEditedBy
                notes
            }
            showParticipantsMomentsGraph
            createMomentsPermission
        }
    }
`;

export const revertSimulation = wrapMutation<RevertSimulationParams, RevertSimulationData>(mutation);
