import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { find, values } from 'ramda';

export function findStaffWithUUID(
    staffUsers: Record<string, StaffUser | undefined>,
    uuid: string,
): StaffUser | undefined;
export function findStaffWithUUID(staffUsers: StaffUser[], uuid: string): StaffUser | undefined;
export function findStaffWithUUID(
    staffUsers: Record<string, StaffUser | undefined> | StaffUser[],
    uuid: string,
): StaffUser | undefined {
    const staffList = Array.isArray(staffUsers) ? staffUsers : values(staffUsers);
    return find((staff) => !!staff && staff.uuid === uuid, staffList);
}

export function findStaffWithEmail(
    staffUsers: Record<string, StaffUser | undefined>,
    uuid: string,
): StaffUser | undefined;
export function findStaffWithEmail(staffUsers: StaffUser[], uuid: string): StaffUser | undefined;
export function findStaffWithEmail(
    staffUsers: Record<string, StaffUser | undefined> | StaffUser[],
    email: string,
): StaffUser | undefined {
    const staffList = Array.isArray(staffUsers) ? staffUsers : values(staffUsers);
    return find((staff) => !!staff && staff.email === email, staffList);
}
