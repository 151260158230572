import {
    ApolloError,
    FetchResult,
    SubscriptionHookOptions,
    SubscriptionOptions,
    useSubscription,
} from '@apollo/client';
import { Observable } from '@apollo/client/utilities';
import { DocumentNode } from 'graphql';
import { Client } from './client';

type SubscriptionHookResults<D, P> = {
    variables?: P;
    loading: boolean;
    data?: D;
    error?: ApolloError;
};

type WrappedSubscriptionOptions<P> = Omit<SubscriptionOptions<P>, 'query'>;

interface WrappedSubscription<P, D> {
    observable: (client: Client, params: P) => Observable<FetchResult<D>>;
    hook: (options?: SubscriptionHookOptions<D, P> | undefined) => SubscriptionHookResults<D, P>;
}

const wrapSubscription = <P, D>(subscription: DocumentNode): WrappedSubscription<P, D> => {
    return {
        observable: (
            client: Client,
            params: P,
            options: WrappedSubscriptionOptions<P> = {},
        ): Observable<FetchResult<D>> => {
            return client.subscribe<D, P>({
                fetchPolicy: 'no-cache',
                ...options,
                query: subscription,
                variables: params,
            });
        },
        hook: (options: SubscriptionHookOptions<D, P> = {}): SubscriptionHookResults<D, P> => {
            return useSubscription(subscription, {
                fetchPolicy: 'no-cache',
                ...options,
            });
        },
    };
};

export default wrapSubscription;
