import { DateTime } from 'luxon';
import { userCan } from 'polpeo-go-common/permissions';
import { PermissionKey } from 'polpeo-go-common/permissions/types';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { always, ascend, cond, descend, equals, filter, length, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { editSimulation } from '../../../../graphql/simulations/editSimulation';
import { grey3Colour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Display } from '../../../bits/Display';
import { H1Heading, Subtitle1 } from '../../../bits/Headers';
import { Link } from '../../../bits/Link';
import { SimulationStatusLozenge } from '../../../patterns/SimulationStatusLozenge';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../WithStaffUser';
import { AdminTable } from '../../AdminTable';
import { DeleteSimulationButton } from './DeleteSimulationButton';
import { NewSimulationButton } from './NewSimulationButton';
import { RevertSimulationButton } from './RevertSimulationButton';

const SimulationsListHeader = styled.div`
    margin: 15px;
`;

const isScheduledSim = (sim: Simulation): boolean => !!sim.scheduledFor && !sim.completedAt && !sim.archivedAt;
const isUnscheduledSim = (sim: Simulation): boolean => !sim.scheduledFor && !sim.completedAt && !sim.archivedAt;
const isCompletedSim = (sim: Simulation): boolean => !!sim.completedAt && !sim.archivedAt;
const isArchivedSim = (sim: Simulation): boolean => !!sim.archivedAt;

export const Simulations: FC = () => {
    const staffUser = useContext(StaffUserContext);
    const { simulations: allSimulations, teams, setSimulation } = useContext(AdminStateContext);

    const [editSimulationMutation, { data: editSimulationData }] = editSimulation.hook();
    useEffect(() => {
        if (editSimulationData) {
            setSimulation(editSimulationData.editSimulation);
        }
    }, [editSimulationData]);

    const userCanCreateSimulations = userCan(PermissionKey.CREATE_SIMULATIONS, staffUser);
    const userCanWriteSimulationAndContent = userCan(
        PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT,
        staffUser,
    );
    const userCanAdministerSimulations = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    const groupedSimulations = useMemo(
        () => ({
            scheduled: filter(isScheduledSim, values(allSimulations)),
            unscheduled: filter(isUnscheduledSim, values(allSimulations)),
            completed: filter(isCompletedSim, values(allSimulations)),
            archived: filter(isArchivedSim, values(allSimulations)),
        }),
        [allSimulations],
    );

    return (
        <>
            <SimulationsListHeader>
                <Display.HorizontalWithSpacing verticalCenter>
                    <H1Heading>Simulations</H1Heading>
                    {userCanCreateSimulations && <NewSimulationButton />}
                </Display.HorizontalWithSpacing>
            </SimulationsListHeader>
            <AdminTable<Simulation>
                headers={cond<string, string[]>([
                    [
                        equals('scheduled'),
                        always([
                            'Name',
                            'Status',
                            'Client',
                            'Participants',
                            'Teams',
                            'Created Date',
                            'Scheduled Date',
                            ...(userCanWriteSimulationAndContent ? ['Archive'] : []),
                        ]),
                    ],
                    [
                        equals('unscheduled'),
                        always([
                            'Name',
                            'Status',
                            'Client',
                            'Participants',
                            'Teams',
                            'Created Date',
                            ...(userCanWriteSimulationAndContent ? ['Archive'] : []),
                        ]),
                    ],
                    [
                        equals('completed'),
                        always([
                            'Name',
                            'Status',
                            'Client',
                            'Participants',
                            'Teams',
                            'Created Date',
                            'Completed Date',
                            ...(userCanAdministerSimulations ? ['Revert'] : []),
                            ...(userCanWriteSimulationAndContent ? ['Archive'] : []),
                        ]),
                    ],
                    [
                        equals('archived'),
                        always([
                            'Name',
                            'Status',
                            'Client',
                            'Participants',
                            'Teams',
                            'Created Date',
                            'Archived Date',
                            ...(userCanWriteSimulationAndContent ? ['Unarchive'] : []),
                            ...(userCanWriteSimulationAndContent ? ['Delete'] : []),
                        ]),
                    ],
                ])}
                items={values(allSimulations)}
                filters={{
                    options: {
                        scheduled: `Scheduled (${length(groupedSimulations.scheduled)})`,
                        unscheduled: `Unscheduled (${length(groupedSimulations.unscheduled)})`,
                        completed: `Completed (${length(groupedSimulations.completed)})`,
                        archived: `Archived (${length(groupedSimulations.archived)})`,
                    },
                    default: 'scheduled',
                    func: (item, currentFilter) =>
                        cond<Simulation, boolean>([
                            [always(currentFilter === 'scheduled'), isScheduledSim],
                            [always(currentFilter === 'unscheduled'), isUnscheduledSim],
                            [always(currentFilter === 'completed'), isCompletedSim],
                            [always(currentFilter === 'archived'), isArchivedSim],
                        ])(item),
                    sortWith: (currentFilter) =>
                        cond<string, ((a: Simulation, b: Simulation) => number)[]>([
                            [equals('scheduled'), () => [ascend((sim) => sim.scheduledFor?.toISOString() || '')]],
                            [equals('unscheduled'), () => [descend((sim) => sim.createdAt?.toISOString() || '')]],
                            [equals('completed'), () => [descend((sim) => sim.completedAt?.toISOString() || '')]],
                            [equals('archived'), () => [descend((sim) => sim.archivedAt?.toISOString() || '')]],
                        ])(currentFilter),
                }}
                getItemKey={(sim) => sim.uuid}
                getItemCell={(sim, header) =>
                    cond([
                        [
                            () => header === 'Name',
                            () => <Link to={`/admin/simulation/${sim.uuid}/manage`}>{sim.name}</Link>,
                        ],
                        [() => header === 'Status', () => <SimulationStatusLozenge simulation={sim} />],
                        [() => header === 'Client', () => <Subtitle1>{sim.clientName}</Subtitle1>],
                        [() => header === 'Participants', () => sim.expectedNumberParticipants],
                        [() => header === 'Teams', () => values(teams[sim.uuid] || {}).length],
                        [
                            () => header === 'Scheduled Date',
                            () =>
                                sim.scheduledFor
                                    ? DateTime.fromJSDate(sim.scheduledFor).toFormat('EEE dd MMM y, HH:mm')
                                    : '',
                        ],
                        [
                            () => header === 'Created Date',
                            () =>
                                sim.createdAt ? DateTime.fromJSDate(sim.createdAt).toFormat('EEE dd MMM y, HH:mm') : '',
                        ],
                        [
                            () => header === 'Completed Date',
                            () =>
                                sim.completedAt
                                    ? DateTime.fromJSDate(sim.completedAt).toFormat('EEE dd MMM y, HH:mm')
                                    : '',
                        ],
                        [
                            () => header === 'Archived Date',
                            () =>
                                sim.archivedAt
                                    ? DateTime.fromJSDate(sim.archivedAt).toFormat('EEE dd MMM y, HH:mm')
                                    : '',
                        ],
                        [
                            () => header === 'Archive',
                            () => (
                                <IconButton
                                    icon="archive"
                                    fill={grey3Colour}
                                    onClick={() =>
                                        editSimulationMutation({
                                            variables: { simulation: { ...sim, archivedAt: new Date() } },
                                        })
                                    }
                                />
                            ),
                        ],
                        [
                            () => header === 'Unarchive',
                            () => (
                                <IconButton
                                    icon="unarchive"
                                    fill={grey3Colour}
                                    onClick={() =>
                                        editSimulationMutation({
                                            variables: { simulation: { ...sim, archivedAt: undefined } },
                                        })
                                    }
                                />
                            ),
                        ],
                        [() => header === 'Revert', () => <RevertSimulationButton simulation={sim} />],
                        [() => header === 'Delete', () => <DeleteSimulationButton simulation={sim} />],
                    ])()
                }
            />
        </>
    );
};
