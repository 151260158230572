import gql from 'graphql-tag';
import { GraphQlContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapSubscription from '../wrapSubscription';

interface OnEmailItemParams {
    simulationUUID: string;
}

interface OnEmailItemData {
    onEmailItem: GraphQlContentItem;
}

const subscription = gql`
    subscription onEmailItem($simulationUUID: String!) {
        onEmailItem(simulationUUID: $simulationUUID) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                recipients
                subject
                message
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
            deleted {
                at
                by {
                    uuid
                    type
                }
            }
        }
    }
`;

export const onEmailItem = wrapSubscription<OnEmailItemParams, OnEmailItemData>(subscription);
