import { css } from 'styled-components/macro';
import { destructiveColour, grey2Colour, grey4Colour, grey6Colour, primaryColour } from './colours';

export const screenReaderOnly = css`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const body1 = css`
    color: ${grey4Colour};
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 22px;
`;

export const body2 = css`
    color: ${grey4Colour};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.14px;
    line-height: 24px;
`;

export const h1Heading = css`
    color: ${grey6Colour};
    font-weight: 400;
    font-size: 23px;
    letter-spacing: 0.23px;
`;

export const h2Heading = css`
    color: ${grey6Colour};
    font-weight: 500;
    font-size: 21px;
    letter-spacing: 0.21px;
`;

export const h3Heading = css`
    color: ${grey6Colour};
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.16px;
`;

export const formLabel = css`
    color: ${grey4Colour};
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.07px;
`;

export interface DestructiveTextColorProps {
    destructive?: boolean;
}
export const destructiveTextColor = css<DestructiveTextColorProps>`
    color: ${({ destructive }) => (destructive ? destructiveColour : 'default')};
`;

export const richtextStyling = css`
    max-width: 100%;
    overflow-wrap: anywhere;

    strong,
    b {
        font-weight: 700;
    }

    p:not(:last-child),
    ul:not(:last-child),
    ol:not(:last-child) {
        margin-bottom: 16px;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        margin-left: 16px;
        max-width: 100%;
        overflow-wrap: anywhere;
    }

    a {
        color: ${primaryColour};
        cursor: pointer;
        max-width: 100%;
        overflow-wrap: anywhere;
    }

    blockquote {
        border-left: 4px solid ${grey2Colour};
        padding: 4px 8px;
        max-width: 100%;
        overflow-wrap: anywhere;
    }
`;
