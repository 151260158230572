import {
    CreateRootContentPermissionTypeEnum,
    Page,
    ParticipantDeleteContentPermissionTypeEnum,
} from 'polpeo-go-common/types/Page';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { assoc, assocPath, includes, map, toPairs, values, without } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { SecondaryButton } from '../../../../bits/Buttons';
import { Display } from '../../../../bits/Display';
import { Checkbox, Dropdown, OptionItem, TextInput } from '../../../../bits/FormFields';
import { FormGrid } from '../../../FormGrid';
import { PageDressingDropZone } from '../../../PageDressingDropZone';
import { pageTemplates } from '../../../PageTemplate/templates';
import { AddPageStatButton } from './AddPageStatButton';
import { EditPageStatsButton } from './EditPageStatsButton';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState';
import getTeamOptionItems from '../../../../../utils/getTeamOptionItems';

const templateOptions: OptionItem[] = [
    { value: '', label: '' },
    ...map((template) => ({ value: template.uuid, label: template.name }), values(pageTemplates)),
];

type PageFormFields = Omit<Page, 'uuid' | 'simulationUUID'>;
interface ManagePageFormProps {
    simulation: Simulation;
    currentPage: PageFormFields;
    disableTemplateSelection?: boolean;
    disableEdit?: boolean;
    onChange: (page: PageFormFields) => void;
    onSubmit?: (page: PageFormFields) => void;
    footer?: JSX.Element;
}
export const ManagePageForm: FC<ManagePageFormProps> = ({
    simulation,
    currentPage,
    disableTemplateSelection,
    disableEdit,
    onChange,
    onSubmit,
    footer,
}) => {
    const { currentSimulationTeams } = useContext(AdminManageSimulationContext);
    const template = pageTemplates[currentPage.templateUUID] as PageTemplate | undefined;
    const currentTeamUUIDs = currentPage.hiddenFromTeamUUIDs || [];

    const teamOptionItems = useMemo(
        () => getTeamOptionItems({ currentSimulationTeams, allowNone: true }),
        [currentSimulationTeams],
    );

    return (
        <FormGrid
            onSubmit={async () => {
                if (onSubmit) onSubmit(currentPage);
            }}
            fields={[
                [
                    'Name',
                    <TextInput
                        key="Name"
                        value={currentPage.name}
                        onChange={(e) => onChange(assoc('name', e.target.value, currentPage))}
                        disabled={disableEdit}
                    />,
                ],
                [
                    'Template',
                    <Dropdown
                        key="Template"
                        id="template"
                        onChange={(e) => onChange(assoc('templateUUID', e.target.value, currentPage))}
                        options={templateOptions}
                        value={currentPage.templateUUID}
                        disabled={disableEdit || disableTemplateSelection}
                    />,
                ],
                [
                    'Dressing',
                    <Display.VerticalWithSpacing key="Dressing" horizontalAlign="start">
                        <FormGrid
                            fields={[
                                [
                                    'Header',
                                    <>
                                        {currentPage.dressing.header && (
                                            <>
                                                {!disableEdit && (
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            onChange(
                                                                assocPath(
                                                                    ['dressing', 'header'],
                                                                    undefined,
                                                                    currentPage,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </SecondaryButton>
                                                )}
                                                <img src={currentPage.dressing.header} />
                                            </>
                                        )}
                                        {!disableEdit && (
                                            <PageDressingDropZone
                                                simulation={simulation}
                                                onUploaded={(path) =>
                                                    onChange(assocPath(['dressing', 'header'], path, currentPage))
                                                }
                                            />
                                        )}
                                    </>,
                                ],
                                [
                                    'Left Sidebar',
                                    <>
                                        {currentPage.dressing.leftSidebar && (
                                            <>
                                                {!disableEdit && (
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            onChange(
                                                                assocPath(
                                                                    ['dressing', 'leftSidebar'],
                                                                    undefined,
                                                                    currentPage,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </SecondaryButton>
                                                )}
                                                <img src={currentPage.dressing.leftSidebar} />
                                            </>
                                        )}
                                        {!disableEdit && (
                                            <PageDressingDropZone
                                                simulation={simulation}
                                                onUploaded={(path) =>
                                                    onChange(assocPath(['dressing', 'leftSidebar'], path, currentPage))
                                                }
                                            />
                                        )}
                                    </>,
                                ],
                                [
                                    'Right Sidebar',
                                    <>
                                        {currentPage.dressing.rightSidebar && (
                                            <>
                                                {!disableEdit && (
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            onChange(
                                                                assocPath(
                                                                    ['dressing', 'rightSidebar'],
                                                                    undefined,
                                                                    currentPage,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </SecondaryButton>
                                                )}
                                                <img src={currentPage.dressing.rightSidebar} />
                                            </>
                                        )}
                                        {!disableEdit && (
                                            <PageDressingDropZone
                                                simulation={simulation}
                                                onUploaded={(path) =>
                                                    onChange(assocPath(['dressing', 'rightSidebar'], path, currentPage))
                                                }
                                            />
                                        )}
                                    </>,
                                ],
                                [
                                    'Footer',
                                    <>
                                        {currentPage.dressing.footer && (
                                            <>
                                                {!disableEdit && (
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            onChange(
                                                                assocPath(
                                                                    ['dressing', 'footer'],
                                                                    undefined,
                                                                    currentPage,
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </SecondaryButton>
                                                )}
                                                <img src={currentPage.dressing.footer} />
                                            </>
                                        )}
                                        {!disableEdit && (
                                            <PageDressingDropZone
                                                simulation={simulation}
                                                onUploaded={(path) =>
                                                    onChange(assocPath(['dressing', 'footer'], path, currentPage))
                                                }
                                            />
                                        )}
                                    </>,
                                ],
                            ]}
                        />
                    </Display.VerticalWithSpacing>,
                ],
                ...(template?.listingPage.theme.page.stats || template?.detailsPage.theme.page.stats
                    ? [
                          [
                              'Starting Stats',
                              <Display.VerticalWithSpacing key="Stats" horizontalAlign="start">
                                  <FormGrid
                                      fields={map(
                                          ([statName, statValue]) => [
                                              statName,
                                              <span key={statName}>{statValue}</span>,
                                          ],
                                          toPairs(currentPage.stats),
                                      )}
                                  />
                                  <Display.HorizontalWithSpacing>
                                      <AddPageStatButton
                                          disabled={!!simulation.startedAt}
                                          page={currentPage}
                                          onChange={(page) => onChange({ ...currentPage, ...page })}
                                      />
                                      <EditPageStatsButton
                                          disabled={!!simulation.startedAt}
                                          page={currentPage}
                                          onChange={(page) => onChange({ ...currentPage, ...page })}
                                      />
                                  </Display.HorizontalWithSpacing>
                              </Display.VerticalWithSpacing>,
                          ] as const,
                      ]
                    : []),
                [
                    'Create Root Items Permission',
                    <Display.HorizontalWithSpacing key="createRoot" horizontalAlign="stretch">
                        <FormGrid
                            fields={[
                                [
                                    'Staff Users Only',
                                    <Checkbox
                                        key="Staff Users Only"
                                        id="staff"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.createRootContentPermission ===
                                            CreateRootContentPermissionTypeEnum.STAFF
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'createRootContentPermission',
                                                    CreateRootContentPermissionTypeEnum.STAFF,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                                [
                                    'Participant Users Only',
                                    <Checkbox
                                        key="Participant Users Only"
                                        id="participant"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.createRootContentPermission ===
                                            CreateRootContentPermissionTypeEnum.PARTICIPANTS
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'createRootContentPermission',
                                                    CreateRootContentPermissionTypeEnum.PARTICIPANTS,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                                [
                                    'Both',
                                    <Checkbox
                                        key="Both"
                                        id="both"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.createRootContentPermission ===
                                            CreateRootContentPermissionTypeEnum.BOTH
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'createRootContentPermission',
                                                    CreateRootContentPermissionTypeEnum.BOTH,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                                [
                                    'Admins Only',
                                    <Checkbox
                                        key="adminsOnly"
                                        id="adminsOnly"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.createRootContentPermission ===
                                            CreateRootContentPermissionTypeEnum.ADMINS_ONLY
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'createRootContentPermission',
                                                    CreateRootContentPermissionTypeEnum.ADMINS_ONLY,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                            ]}
                        />
                    </Display.HorizontalWithSpacing>,
                ],
                ...(template?.showItemsAsIndividualPages
                    ? [
                          [
                              'Show root items as individual pages',
                              <Display.HorizontalWithSpacing key="showItemsAsIndividualPages" horizontalAlign="start">
                                  <Checkbox
                                      id="showItemsAsIndividualPages"
                                      disabled={disableEdit}
                                      checked={!!currentPage?.showItemsAsIndividualPages}
                                      onChange={(e) =>
                                          onChange(assoc('showItemsAsIndividualPages', e.target.checked, currentPage))
                                      }
                                  />
                              </Display.HorizontalWithSpacing>,
                          ] as const,
                      ]
                    : []),
                [
                    'Participant Delete Content Permission',
                    <Display.HorizontalWithSpacing key="participantDeleteContent" horizontalAlign="stretch">
                        <FormGrid
                            fields={[
                                [
                                    'Delete All Content',
                                    <Checkbox
                                        key="Delete All Content Items"
                                        id="allContent"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.participantDeleteContentPermission ===
                                            ParticipantDeleteContentPermissionTypeEnum.ALL
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'participantDeleteContentPermission',
                                                    ParticipantDeleteContentPermissionTypeEnum.ALL,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                                [
                                    'Delete Content Created by User',
                                    <Checkbox
                                        key="Delete Content Created by User"
                                        id="userCreatedContent"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.participantDeleteContentPermission ===
                                            ParticipantDeleteContentPermissionTypeEnum.CREATED
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'participantDeleteContentPermission',
                                                    ParticipantDeleteContentPermissionTypeEnum.CREATED,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                                [
                                    'Cannot Delete Content',
                                    <Checkbox
                                        key="None"
                                        id="none"
                                        disabled={disableEdit}
                                        checked={
                                            currentPage?.participantDeleteContentPermission ===
                                            ParticipantDeleteContentPermissionTypeEnum.NONE
                                        }
                                        onChange={() =>
                                            onChange(
                                                assoc(
                                                    'participantDeleteContentPermission',
                                                    ParticipantDeleteContentPermissionTypeEnum.NONE,
                                                    currentPage,
                                                ),
                                            )
                                        }
                                    />,
                                ],
                            ]}
                        />
                    </Display.HorizontalWithSpacing>,
                ],
                [
                    'Hidden From Teams',
                    <Display.VerticalWithSpacing key="Team Assignment" gap={10} horizontalAlign="start">
                        {map(
                            (team) => (
                                <Checkbox
                                    id={team.value}
                                    key={team.value}
                                    checked={
                                        includes(team.value, currentTeamUUIDs) ||
                                        (team.value === 'NONE' && !currentTeamUUIDs.length)
                                    }
                                    onChange={(e) => {
                                        const nowChecked = e.target.checked;
                                        let teamUUIDs = [];
                                        if (nowChecked) {
                                            teamUUIDs = team.value === 'NONE' ? [] : [...currentTeamUUIDs, team.value];
                                        } else {
                                            teamUUIDs = without([team.value], currentTeamUUIDs);
                                        }
                                        onChange(assoc('hiddenFromTeamUUIDs', teamUUIDs, currentPage));
                                    }}
                                >
                                    {team.label}
                                </Checkbox>
                            ),
                            teamOptionItems,
                        )}
                    </Display.VerticalWithSpacing>,
                ],
            ]}
            footer={footer}
        />
    );
};
