import { Simulation } from 'polpeo-go-common/types/Simulation';
import { getSignedPutUrlForTeamOverviewUpload } from '../graphql/teams';
import { Client } from '../graphql/client';

export const uploadTeamOverviewImage = async (client: Client, simulation: Simulation, file: File): Promise<string> => {
    const putUrl = await getSignedPutUrlForTeamOverviewUpload.promise(client, {
        simulation: simulation,
        file: {
            name: file.name,
            type: file.type,
            size: undefined,
        },
    });
    if (!putUrl.data) {
        throw new Error('Could not create signed put url for image');
    }
    const putCVRequestOptions: RequestInit = {
        method: 'PUT',
        headers: { 'content-type': file.type },
        body: file,
    };
    await fetch(putUrl.data.getSignedPutUrlForTeamOverviewUpload.signedPutUrl, putCVRequestOptions);
    return putUrl.data.getSignedPutUrlForTeamOverviewUpload.path;
};
