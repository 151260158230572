import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import React, { FC, useContext, useState } from 'react';
import { IconButton } from '../../../../../../bits/Buttons/IconButton';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { PrepreparedContentFormModal } from '../../PrepreparedContentFormModal';

interface EditPrepreparedContentButtonProps {
    item: PrepreparedContentItem;
}
export const EditPrepreparedContentButton: FC<EditPrepreparedContentButtonProps> = ({ item }) => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <IconButton icon="edit" width={20} onClick={() => setShowModal(true)} />
            {showModal && (
                <PrepreparedContentFormModal
                    simulation={currentSimulation}
                    onCancel={() => setShowModal(false)}
                    onSaveComplete={() => setShowModal(false)}
                    item={item}
                />
            )}
        </>
    );
};
