import { NewContentItem, NewPrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types';

const checkRequiredField = (
    currentPrepreparedContent: NewPrepreparedContentItem | PrepreparedContentItem | NewContentItem,
    requirement: string,
) => !!currentPrepreparedContent?.content?.data?.[requirement];

export const checkRequiredFields = (
    requiredFields: (string | string[])[],
    currentPrepreparedContent: NewPrepreparedContentItem | PrepreparedContentItem | NewContentItem,
): { valid: boolean; missingFieldNames: (string | string[])[] } => {
    const missingFieldNames: (string | string[])[] = [];
    return {
        valid: requiredFields.reduce((acc: boolean, requirement: string | string[]) => {
            if (Array.isArray(requirement)) {
                const requiredInArray = requirement.reduce((acc, requirement) => {
                    return checkRequiredField(currentPrepreparedContent, requirement) ? acc + 1 : acc;
                }, 0);
                if (requiredInArray === 1) return true;
                else {
                    missingFieldNames.push(requirement);
                    return false;
                }
            } else {
                if (checkRequiredField(currentPrepreparedContent, requirement)) return true;
                else {
                    missingFieldNames.push(requirement);
                    return false;
                }
            }
        }, true),
        missingFieldNames,
    };
};
