import { UserRoleEnum } from 'polpeo-go-common/types/StaffUser';
import { OptionItem } from '../../../bits/FormFields';

export const roleOptions: OptionItem[] = [
    {
        value: UserRoleEnum.ADMIN,
        label: 'Admin',
    },
    {
        value: UserRoleEnum.CONTENT_CREATOR,
        label: 'Content Creator',
    },
    {
        value: UserRoleEnum.SIMULATION_ADMINISTRATOR,
        label: 'Simulation Admin',
    },
    {
        value: UserRoleEnum.ROLEPLAYER,
        label: 'Role Player',
    },
];
