import { last, includes, forEach, assocPath, values, find } from 'ramda';
import { pageTemplates } from '../components/templates/PageTemplate/templates';
import { getAncestorsForItem } from './getAncestorsForContentItem';
import { ContentItem, Page } from 'polpeo-go-common/types';
import { TeamNotifications } from './TeamNotifications';

export function setNotificationsForNewParticipantItems(
    currentContentItems: Record<string, ContentItem> | undefined,
    newContentItems: Record<string, ContentItem>,
    setNotifications: (value: React.SetStateAction<TeamNotifications>) => void,
    pages: Page[] | undefined,
): void {
    const newContentItemsList = values(newContentItems);
    const addedContentItems: string[] = [];
    const allContentItems = { ...currentContentItems, ...newContentItems };
    forEach((item) => {
        const page = find((p) => p.uuid === item.content.pageUUID, pages || []);
        if (!page) {
            return;
        }
        const template = pageTemplates[page.templateUUID];
        if (page.showItemsAsIndividualPages && template.showItemsAsIndividualPages) {
            const ancestors = getAncestorsForItem(allContentItems, item);
            const itemToNotifyFor = last(ancestors) || item;
            setNotifications(assocPath(['pages', itemToNotifyFor.uuid], true));
        } else if (!includes(page.uuid, addedContentItems)) {
            setNotifications(assocPath(['pages', page.uuid], true));
            addedContentItems.push(page.uuid);
        }
    }, newContentItemsList);
}
