import { NewPrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { cond, dissoc, T } from 'ramda';

export const makeInitialState = (
    sim: Simulation,
    options: {
        item?: PrepreparedContentItem;
        parent?: PrepreparedContentItem;
        copyOf?: PrepreparedContentItem;
    },
): NewPrepreparedContentItem | PrepreparedContentItem =>
    cond([
        [(opts) => !!opts.item, ({ item }): PrepreparedContentItem => ({ ...item })],
        [
            (opts) => !!opts.copyOf,
            ({ copyOf }): NewPrepreparedContentItem =>
                dissoc<PrepreparedContentItem, 'uuid'>('uuid', { ...copyOf, released: undefined }),
        ],
        [
            (opts) => !!opts.parent,
            ({ parent }): NewPrepreparedContentItem => ({
                type: 'PAGE_CONTENT',
                simulationUUID: parent.simulationUUID,
                parentUUID: parent.uuid,
                persona: '',
                content: {
                    pageUUID: parent.content.pageUUID || '',
                    interactions: { positive: 0, negative: 0, shares: 0 },
                    data: {},
                },
                released: undefined,
                teamUUIDs: parent.teamUUIDs,
            }),
        ],
        [
            T,
            (): NewPrepreparedContentItem => ({
                type: 'PAGE_CONTENT',
                simulationUUID: sim.uuid,
                parentUUID: undefined,
                persona: '',
                content: {
                    pageUUID: '',
                    interactions: { positive: 0, negative: 0, shares: 0 },
                    data: {},
                },
                released: undefined,
                teamUUIDs: [],
            }),
        ],
    ])(options);
