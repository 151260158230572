import { addIndex, assocPath, findIndex, fromPairs, map, remove, toPairs, uniq } from 'ramda';
import React, { FC, useState } from 'react';
import { destructiveColour } from '../../../themes/colours';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { TextInput } from '../../bits/FormFields';

const mapIndexed = addIndex<[string, string | number], JSX.Element>(map);

interface ManagePageStatsFormProps {
    onCancel: () => void;
    stats: Record<string, string | number>;
    onSave: (newStat: Record<string, string | number>) => void;
}
export const ManagePageStatsForm: FC<ManagePageStatsFormProps> = ({ onCancel, stats, onSave }) => {
    const [currentStats, setCurrentStats] = useState<Array<[string, string | number]>>(toPairs(stats));

    const keys = map(([key]) => key, currentStats);
    const hasEmptyKey = findIndex((key) => key === '', keys) >= 0;
    const hasDuplicateKey = keys.length !== uniq(keys).length;
    const canSave = !hasEmptyKey && !hasDuplicateKey;

    return (
        <Display.VerticalWithSpacing>
            {mapIndexed<Array<[string, string | number]>, Array<[string, string | number]>>(([key, value], index) => {
                return (
                    <Display.HorizontalWithSpacing key={index}>
                        <TextInput
                            value={key}
                            onChange={(e) => setCurrentStats(assocPath([index, 0], e.target.value, currentStats))}
                        />
                        <TextInput
                            value={value}
                            onChange={(e) => setCurrentStats(assocPath([index, 1], e.target.value, currentStats))}
                        />
                        <IconButton
                            icon="delete"
                            size={20}
                            fill={destructiveColour}
                            onClick={() => setCurrentStats(remove(index, 1, currentStats))}
                        />
                    </Display.HorizontalWithSpacing>
                );
            }, currentStats)}
            <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                <PrimaryButton
                    disabled={!canSave}
                    onClick={() => {
                        onSave(fromPairs(currentStats));
                    }}
                >
                    Save
                </PrimaryButton>
            </Display.HorizontalWithSpacing>
        </Display.VerticalWithSpacing>
    );
};
