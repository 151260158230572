import gql from 'graphql-tag';
import { GraphQlMoment } from 'polpeo-go-common/types/Moment';
import wrapQuery from '../wrapQuery';

interface GetMomentsParams {
    simulationUUID: string;
    startFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
}

interface GetMomentsData {
    getMoments: {
        moments: GraphQlMoment[];
        continueFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
    };
}

const query = gql`
    query getMoments($simulationUUID: String!, $startFrom: AWSJSON) {
        getMoments(simulationUUID: $simulationUUID, startFrom: $startFrom) {
            moments {
                uuid
                simulationUUID
                created {
                    at
                    by
                }
                contentItem {
                    type
                    uuid
                    simulationUUID
                    teamUUID
                    parentUUID
                    createdAt
                    persona
                    content {
                        pageUUID
                        interactions {
                            positive
                            negative
                            shares
                        }
                        data
                    }
                    prepreparedContentUUID
                    createdBy {
                        type
                        uuid
                    }
                    deleted {
                        at
                        by {
                            type
                            uuid
                        }
                    }
                }
                rating
                comments
            }
        }
    }
`;

export const getMoments = wrapQuery<GetMomentsParams, GetMomentsData>(query);
