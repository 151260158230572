import React, { FC, useContext, useEffect, useState } from 'react';
import { reduce } from 'ramda';
import { Page } from 'polpeo-go-common/types/Page';
import { deletePage } from '../../../../../graphql/pages';
import { destructiveColour } from '../../../../../themes/colours';
import WithClassname from '../../../../../utils/WithClassname';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Body2 } from '../../../../bits/Headers';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { DeleteModal } from '../../../DeleteModal';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { updateTriggerPrepreparedContentLinks } from '../../../../../graphql/triggers';

interface DeletePageButtonProps extends WithClassname {
    page: Page;
}
export const DeletePageButton: FC<DeletePageButtonProps> = ({ page }: DeletePageButtonProps) => {
    const { removePage, removePrepreparedContentItems } = useContext(AdminStateContext);
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);
    const [deletePageMutation, { data: deletePageData, loading: deletePageLoading }] = deletePage.hook();
    const [
        updateTriggerPrepreparedContentLinksMutation,
        { data: updateTriggerPrepreparedContentLinksData, loading: updateTriggerPrepreparedContentLinksLoading },
    ] = updateTriggerPrepreparedContentLinks.hook();
    const { setTriggerPrepreparedContentLinks } = useContext(AdminStateContext);

    useEffect(() => {
        const updatedTriggerLinks = updateTriggerPrepreparedContentLinksData?.updateTriggerPrepreparedContentLinks;
        if (deletePageData?.deletePage) {
            const prepreparedContentItemUUIDs = deletePageData.deletePage.prepreparedContentUUIDs;
            if (prepreparedContentItemUUIDs.length) {
                removePrepreparedContentItems(page.simulationUUID, prepreparedContentItemUUIDs);
                const UUIDsToUpdate = reduce(
                    (acc, id: string) => {
                        return { ...acc, [id]: '' };
                    },
                    {},
                    prepreparedContentItemUUIDs,
                );
                updateTriggerPrepreparedContentLinksMutation({
                    variables: {
                        simulationUUID: currentSimulation.uuid,
                        newLinks: JSON.stringify(UUIDsToUpdate),
                    },
                });
            }
        }

        if (updatedTriggerLinks && !updateTriggerPrepreparedContentLinksLoading) {
            setTriggerPrepreparedContentLinks(currentSimulation.uuid, JSON.parse(updatedTriggerLinks));
            removePage(page);
            setShowModal(false);
        }
    }, [deletePageData, updateTriggerPrepreparedContentLinksData, updateTriggerPrepreparedContentLinksLoading]);

    return (
        <>
            <IconButton icon="delete" size={20} fill={destructiveColour} onClick={() => setShowModal(true)} />
            {showModal && (
                <DeleteModal
                    header="Delete Page"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={
                        <>
                            <p>Are you sure you want to delete the page {`'${page.name}'`}? </p>
                            <p>
                                This will also delete all the preprepared content (and their descendants) linked to this
                                page.
                            </p>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() =>
                        deletePageMutation({ variables: { page: { ...page, stats: JSON.stringify(page.stats) } } })
                    }
                    showSpinner={deletePageLoading}
                />
            )}
        </>
    );
};
