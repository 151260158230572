import gql from 'graphql-tag';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import wrapMutation from '../wrapMutation';

interface DeleteScratchPadDocumentParams {
    scratchPadDocument: ScratchPadDocument;
}

interface DeleteScratchPadDocumentData {
    deleteScratchPadDocument: ScratchPadDocument;
}

const mutation = gql`
    mutation deleteScratchPadDocument($scratchPadDocument: ScratchPadDocumentInput!) {
        deleteScratchPadDocument(scratchPadDocument: $scratchPadDocument) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            currentEditor
        }
    }
`;

export const deleteScratchPadDocument =
    wrapMutation<DeleteScratchPadDocumentParams, DeleteScratchPadDocumentData>(mutation);
