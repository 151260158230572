import React from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey5Colour } from '../../../themes/colours';
import { Display } from '../../bits/Display';
import { WhiteLogo } from '../../patterns/Logo';
import { ParticipantUserMenu } from '../../patterns/ParticipantUserMenu';

const Header = styled.header`
    position: sticky;
    top: 0;
    z-index: 900;

    background: ${grey5Colour};
    padding: 10px 30px;
    border-bottom: 1px solid ${grey1Colour};
`;

export const ParticipantInSimulationHeader = (): React.ReactElement => {
    return (
        <>
            <Header>
                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                    <Display.HorizontalWithSpacing>
                        <WhiteLogo width={125} />
                    </Display.HorizontalWithSpacing>
                    <ParticipantUserMenu />
                </Display.HorizontalWithMaxSpaceBetween>
            </Header>
        </>
    );
};
