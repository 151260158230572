import { Simulation } from 'polpeo-go-common/types/Simulation';
import { always, cond, T } from 'ramda';
import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../bits/Icon';
import { Lozenge as BaseLozenge } from '../bits/Lozenge';

const readyStateBgColour = '#cceec7';
const draftStateBgColour = '#feefd7';
const liveStateBgColour = '#ffdcdb';
const completedStateBgColour = '#efefef';

const readyStateTextColour = '#0c2509';
const draftStateTextColour = '#653f01';
const liveStateTextColour = '#5b1717';
const completedStateTextColour = '#757575';

enum SimulationStatus {
    DRAFT = 'DRAFT',
    READY = 'READY',
    LIVE = 'LIVE',
    COMPLETED = 'COMPLETED',
}

interface SimulationStatusProps {
    status: string;
}
const Lozenge = styled(BaseLozenge)<SimulationStatusProps>`
    display: inline-flex;
    align-items: center;
    font-size: 11px;
    text-transform: uppercase;

    background-color: ${({ status }) =>
        cond([
            [(val) => val === SimulationStatus.DRAFT, always(draftStateBgColour)],
            [(val) => val === SimulationStatus.READY, always(readyStateBgColour)],
            [(val) => val === SimulationStatus.LIVE, always(liveStateBgColour)],
            [(val) => val === SimulationStatus.COMPLETED, always(completedStateBgColour)],
        ])(status)};
    color: ${({ status }) =>
        cond([
            [(val) => val === SimulationStatus.DRAFT, always(draftStateTextColour)],
            [(val) => val === SimulationStatus.READY, always(readyStateTextColour)],
            [(val) => val === SimulationStatus.LIVE, always(liveStateTextColour)],
            [(val) => val === SimulationStatus.COMPLETED, always(completedStateTextColour)],
        ])(status)};

    svg {
        margin-right: 6px;
    }
`;

interface SimulationStatusLozengeProps {
    simulation: Simulation;
}

export const SimulationStatusLozenge: React.FC<SimulationStatusLozengeProps> = ({ simulation }) => {
    return cond([
        [
            (sim) => sim.completedAt,
            always(
                <Lozenge status={SimulationStatus.COMPLETED}>
                    <Icon icon="tick" width={16} height={16} />
                    Completed
                </Lozenge>,
            ),
        ],
        [(sim) => sim.startedAt || sim.lobbyOpenedAt, always(<Lozenge status={SimulationStatus.LIVE}>Live</Lozenge>)],
        [(sim) => sim.isReady, always(<Lozenge status={SimulationStatus.READY}>Ready</Lozenge>)],
        [T, always(<Lozenge status={SimulationStatus.DRAFT}>Draft</Lozenge>)],
    ])(simulation);
};
