import { head, last, includes, reject, split, test } from 'ramda';

const stringToURL = (url: string): URL => {
    const cleanUrl = test(/^https?:\/\//g, url) ? url : `https://${url}`;
    const parsedUrl = new URL(cleanUrl);

    return parsedUrl;
};

export const getYoutubeVideoId = (url: string): string => {
    if (!includes('youtube.com', url) && !includes('youtu.be', url)) {
        return '';
    }
    try {
        const parsedUrl = stringToURL(url);
        if (includes('youtube.com/shorts', url)) {
            const path = reject((x) => !x, split('/', parsedUrl.pathname));
            const videoId = last(path);
            return videoId || '';
        }
        if (includes('youtu.be', url)) {
            const path = reject((x) => !x, split('/', parsedUrl.pathname));
            const videoId = head(path);
            return videoId || '';
        }
        const queryParams = new URLSearchParams(parsedUrl.searchParams || '');
        const videoId = queryParams.get('v');
        return videoId || '';
    } catch {
        return '';
    }
};

export const getVimeoVideoId = (url: string): string => {
    if (!includes('vimeo.com', url)) {
        return '';
    }
    try {
        const parsedUrl = stringToURL(url);
        const path = reject((x) => !x, split('/', parsedUrl.pathname));
        const videoId = head(path);
        return videoId || '';
    } catch {
        return '';
    }
};
