import { Creator } from 'polpeo-go-common/types/Creator';
import { ContentItemCreatorOptions } from 'polpeo-go-common/types/PageTemplate/ContentItemThemingOptions';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { BaseDiv } from './utils';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';

const UserDisplayNameContainer = styled(BaseDiv)`
    display: inline-block;
    white-space: pre-line;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
        font-weight: inherit;
        font-size: inherit;

        display: inline-block;
        white-space: pre-line;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

interface UserDisplayNameProps {
    creator: Creator;
    theme: ContentItemCreatorOptions;
    as?: 'span' | 'div';
}
export const UserDisplayName: FC<UserDisplayNameProps> = ({ as, creator, theme }) => {
    const { simulation } = useContext(AdminInSimulationStateContext);
    const { simulation: participantSimulation } = useContext(ParticipantStateContext);

    const clientName = simulation?.clientName || participantSimulation?.clientName;

    return (
        <UserDisplayNameContainer theme={theme.username} as={as}>
            <span>
                {creator.type === `PARTICIPANT` ? `${clientName}: ` : ''} {creator.fullName}
            </span>
        </UserDisplayNameContainer>
    );
};
