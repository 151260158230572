import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapMutation from '../wrapMutation';

interface OpenLobbyParams {
    simulationUUID: string;
}

interface OpenLobbyData {
    openLobby: Simulation;
}

const mutation = gql`
    mutation openLobby($simulationUUID: String!) {
        openLobby(simulationUUID: $simulationUUID) {
            uuid
            name
            code
            createdAt
            createdBy
            scheduledFor
            lobbyOpenedAt
            startedAt
            completedAt
            archivedAt
            clientName
            isReady
            emailDomainWhitelist
            expectedNumberParticipants
            administratorUUIDs
            briefingDocuments {
                name
                createdAt
                path
                createdBy
            }
            briefingNotes {
                lastEditedAt
                lastEditedBy
                notes
            }
            startingOverview {
                title
                description
                headerImage
            }
            showParticipantsMomentsGraph
            createMomentsPermission
        }
    }
`;

export const openLobby = wrapMutation<OpenLobbyParams, OpenLobbyData>(mutation);
