import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { all, equals, mapObjIndexed, omit, pick, pipe, values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { editSimulation } from '../../../../graphql/simulations/editSimulation';
import { grey4Colour } from '../../../../themes/colours';
import { PrimaryButton } from '../../../bits/Buttons';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Display } from '../../../bits/Display';
import { H1Heading, H2Heading } from '../../../bits/Headers';
import { Icon } from '../../../bits/Icon';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Card } from '../../../patterns/Card';
import { FullPageError } from '../../../patterns/FullPageError';
import { SimulationForm } from '../../../patterns/SimulationForm';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../WithStaffUser';
import { InitialScratchPadDocumentList } from '../ScratchPad/InitialScratchPadDocumentList';

const SimulationSetupPageContainer = styled(Display.VerticalWithSpacing)`
    padding: 22px;
`;

const SaveButton = styled(PrimaryButton)`
    font-size: 14px;
`;

const SaveSectionContainer = styled(Display.HorizontalWithMaxSpaceBetween)`
    p {
        margin-right: 10px;
        margin-left: 5px;
        color: ${grey4Colour};
    }
`;

export const SimulationSetupPage: FC = () => {
    const history = useHistory();
    const staffUser = useContext(StaffUserContext);
    const { setSimulation } = useContext(AdminStateContext);
    const { currentSimulation: initialSimulation } = useContext(AdminManageSimulationContext);
    const [currentSimulation, setCurrentSimulation] = useState<Simulation>(initialSimulation);
    const [showSaveMessage, setShowSaveMessage] = useState<boolean>(false);
    const [editSimulationMutation, { data: editSimulationData, loading: editSimulationLoading }] =
        editSimulation.hook();

    const requiredcurrentSimulationCompleted = pipe(
        pick(['name', 'clientName', 'emailDomainWhitelist']),
        (details) => values(details),
        all((value) => !!value),
    )(currentSimulation);

    useEffect(() => {
        if (editSimulationData?.editSimulation) {
            setSimulation(editSimulationData.editSimulation);
            setShowSaveMessage(true);
        }
    }, [editSimulationData]);

    const userCanWriteSimulationAndContent = userCan(
        PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT,
        staffUser,
    );

    const disableEditing = !!currentSimulation.completedAt;
    const disabledInputs = useMemo(() => {
        if (userCanWriteSimulationAndContent && disableEditing) return mapObjIndexed(() => true, currentSimulation);
        else if (!!currentSimulation.startedAt) {
            return mapObjIndexed((value, key) => key !== 'emailDomainWhitelist', currentSimulation);
        }
        return undefined;
    }, [currentSimulation]);

    const hasUnsavedChanges = !equals(
        omit(['scratchPadDocuments'], currentSimulation),
        omit(['scratchPadDocuments'], initialSimulation),
    );
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    if (!currentSimulation) {
        return <FullPageError errorCode="404" message="This simulation could not be found" />;
    }

    if (!userCanWriteSimulationAndContent) {
        return <FullPageError errorCode="401" message="Unauthorized" />;
    }

    return (
        <SimulationSetupPageContainer>
            <UnsavedPrompt />
            <Display.HorizontalWithSpacing verticalCenter gap={4}>
                <IconButton icon="back" onClick={() => history.goBack()} disabled={editSimulationLoading} />
                <H1Heading>Simulation Setup</H1Heading>
            </Display.HorizontalWithSpacing>
            <Display.HorizontalWithSpacing>
                <Card
                    header={
                        <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                            <H2Heading>Simulation Details</H2Heading>
                            {!disableEditing && (
                                <SaveSectionContainer verticalCenter>
                                    {showSaveMessage && (
                                        <>
                                            <Icon size={20} icon="tick" fill={'grey4Colour'} />
                                            <p>Saved</p>
                                        </>
                                    )}
                                    <SaveButton
                                        disabled={!requiredcurrentSimulationCompleted}
                                        onClick={() => {
                                            editSimulationMutation({
                                                variables: {
                                                    simulation: {
                                                        ...currentSimulation,
                                                        scratchPadDocuments: initialSimulation.scratchPadDocuments,
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        Save
                                    </SaveButton>
                                </SaveSectionContainer>
                            )}
                        </Display.HorizontalWithMaxSpaceBetween>
                    }
                    cardWidth={800}
                >
                    <Display.VerticalWithSpacing>
                        {editSimulationLoading && (
                            <Display.HorizontalCenterVerticalCenter>
                                <Spinner />
                            </Display.HorizontalCenterVerticalCenter>
                        )}
                        {!editSimulationLoading && (
                            <SimulationForm
                                currentSimulation={currentSimulation}
                                onChange={(fields) => {
                                    setShowSaveMessage(false);
                                    setCurrentSimulation({ ...currentSimulation, ...fields });
                                }}
                                uploadOptions={{ simulation: currentSimulation }}
                                disableInputs={disabledInputs}
                            />
                        )}
                    </Display.VerticalWithSpacing>
                </Card>
                <Display.VerticalWithSpacing>
                    <Card header="Default Scratchpad Documents">
                        <InitialScratchPadDocumentList disableManageDocuments={!!initialSimulation.startedAt} />
                    </Card>
                </Display.VerticalWithSpacing>
            </Display.HorizontalWithSpacing>
        </SimulationSetupPageContainer>
    );
};
