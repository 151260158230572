import { useApolloClient } from '@apollo/client';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { getStaffUser } from '../../graphql/staffusers/getStaffUser';
import WithChildren from '../../utils/WithChildren';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import AuthContext from '../WithAuth/AuthContext';

// No one should ever be accessing Auth before it's been initialised anyways so we shouldn't
// provide any default values (as there are no correct default values if Auth isn't initialised!!!)
export const StaffUserContext = createContext<StaffUser>(undefined as unknown as StaffUser);

export const WithStaffUser: FC<WithChildren> = ({ children }: WithChildren) => {
    const client = useApolloClient();
    const authContext = useContext(AuthContext);
    const [staffUser, setStaffUser] = useState<StaffUser>();

    useEffect(() => {
        if (authContext.currentAuthenticatedUser) {
            const { currentAuthenticatedUser } = authContext;
            if (currentAuthenticatedUser.attributes) {
                const fetchAndSetStaffUser = async () => {
                    const getStaffUserResponse = await getStaffUser.promise(client, {
                        // Due to weird scoping typescript doesn't think currentAuthenticatedUser.attributes
                        // exists at thispoint :(
                        staffUserUUID: currentAuthenticatedUser.attributes?.sub || '',
                    });
                    if (getStaffUserResponse.data) {
                        setStaffUser(getStaffUserResponse.data.getStaffUser);
                    } else {
                        throw new Error('No staffUser found');
                    }
                };

                fetchAndSetStaffUser();
            } else {
                throw new Error('currentAuthenticatedUser is missing attribures');
            }
        } else {
            setStaffUser(undefined);
        }
    }, [authContext]);

    if (!staffUser) {
        return <FullPageSplashLoadingScreen />;
    }

    return <StaffUserContext.Provider value={staffUser}>{children}</StaffUserContext.Provider>;
};
