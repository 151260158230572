import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import React, { FC } from 'react';
import { constructiveColour, destructiveColour } from '../../../themes/colours';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { TextArea } from '../../bits/FormFields';
import { FormGrid } from '../../templates/FormGrid';
import { EmbeddedContentItem } from '../../templates/PageTemplate/EmbeddedContentItem';

type MomentFormFields = Pick<Moment, 'rating' | 'comments'>;
type MomentFormProps = {
    currentMoment: MomentFormFields;
    item: ContentItem;
    onChange: (item: MomentFormFields) => void;
    onSubmit?: (item: MomentFormFields) => void;
    footer?: JSX.Element;
};

export const MomentForm: FC<MomentFormProps> = ({ currentMoment, item, onSubmit, onChange, footer }) => (
    <FormGrid
        onSubmit={async () => {
            if (onSubmit) onSubmit(currentMoment);
        }}
        fields={[
            ['Item', <EmbeddedContentItem key="Item Preview" item={item} />],
            [
                'Rate this highlight',
                <Display.HorizontalWithSpacing key="Rating">
                    <IconButton
                        icon="momentDown2"
                        size={40}
                        onClick={() =>
                            onChange({
                                ...currentMoment,
                                rating: currentMoment.rating !== 'Down2' ? 'Down2' : undefined,
                            })
                        }
                        fill={currentMoment.rating === 'Down2' ? destructiveColour : undefined}
                    />
                    <IconButton
                        icon="momentDown1"
                        size={40}
                        onClick={() =>
                            onChange({
                                ...currentMoment,
                                rating: currentMoment.rating !== 'Down1' ? 'Down1' : undefined,
                            })
                        }
                        fill={currentMoment.rating === 'Down1' ? destructiveColour : undefined}
                    />
                    <IconButton
                        icon="momentUp1"
                        size={40}
                        onClick={() =>
                            onChange({
                                ...currentMoment,
                                rating: currentMoment.rating !== 'Up1' ? 'Up1' : undefined,
                            })
                        }
                        fill={currentMoment.rating === 'Up1' ? constructiveColour : undefined}
                    />
                    <IconButton
                        icon="momentUp2"
                        size={40}
                        onClick={() =>
                            onChange({
                                ...currentMoment,
                                rating: currentMoment.rating !== 'Up2' ? 'Up2' : undefined,
                            })
                        }
                        fill={currentMoment.rating === 'Up2' ? constructiveColour : undefined}
                    />
                </Display.HorizontalWithSpacing>,
            ],
            [
                'Comments',
                <TextArea
                    key="Comments"
                    value={currentMoment.comments}
                    onChange={(e) => onChange({ ...currentMoment, comments: e.target.value })}
                />,
            ],
        ]}
        footer={footer}
    />
);
