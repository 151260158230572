import { length, values } from 'ramda';
import React, { FC, useContext } from 'react';
import { Vega } from 'react-vega';
import { AdminInSimulationStateContext } from '../../../../../WithAdminInSimulationState';

const spec = {
    width: 42,
    height: 42,
    data: [
        {
            name: 'table',
            values: [
                { id: 'participants', field: 40 },
                { id: 'missing', field: 6 },
            ],
            transform: [
                {
                    type: 'pie' as const,
                    field: 'field',
                },
            ],
        },
    ],
    scales: [
        {
            name: 'color',
            type: 'ordinal' as const,
            domain: { data: 'table', field: 'id' },
            range: ['#005670', '#99e7ff'],
        },
    ],
    marks: [
        {
            type: 'arc' as const,
            from: { data: 'table' },
            encode: {
                enter: {
                    fill: { scale: 'color', field: 'id' },
                    x: { signal: 'width / 2' },
                    y: { signal: 'height / 2' },
                },
                update: {
                    startAngle: { field: 'startAngle' },
                    endAngle: { field: 'endAngle' },
                    innerRadius: { value: 13 },
                    outerRadius: { signal: 'width / 2' },
                },
            },
        },
    ],
};

const makeParticipantDonutData = (participants: number, total: number) => ({
    table: [
        { id: 'participants', field: participants },
        { id: 'missing', field: total - participants },
    ],
});

export const ParticipantCountDonut: FC = () => {
    const { simulation, participants } = useContext(AdminInSimulationStateContext);
    const participantCount = length(values(participants));
    return (
        <Vega
            spec={spec}
            data={makeParticipantDonutData(participantCount, simulation.expectedNumberParticipants || participantCount)}
            actions={false}
        />
    );
};
