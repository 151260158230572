import { find, mergeDeepRight } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { getContentItemFields } from '../../../../utils/getContentItemFields';
import { FormGrid } from '../../../templates/FormGrid';
import { pageTemplates } from '../../../templates/PageTemplate/templates';
import { ParticipantStateContext } from '../../../WithParticipantState';
import { makeContentTabFormFields } from './makeContentTabFormFields';

interface ContentItemFormFields {
    parentUUID?: string;
    content: {
        pageUUID: string;
        interactions: {
            positive?: number;
            negative?: number;
            shares?: number;
        };
        data: Record<string, string>;
    };
    persona?: string;
}

interface ContentItemFormProps {
    currentContentItem: ContentItemFormFields;
    onChange: (item: ContentItemFormFields) => void;
    onSubmit?: (item: ContentItemFormFields) => void;
    footer?: JSX.Element;
}

export const ParticipantContentItemForm: FC<ContentItemFormProps> = ({
    currentContentItem,
    onSubmit,
    onChange,
    footer,
}) => {
    const { simulation, pages = [] } = useContext(ParticipantStateContext);

    const { currentFields } = useMemo(() => {
        const currentPage = find((p) => p.uuid === currentContentItem.content.pageUUID, pages);
        const currentTemplate = currentPage ? pageTemplates[currentPage.templateUUID] : undefined;
        const currentFields = currentTemplate ? getContentItemFields(currentTemplate, currentContentItem) : [];

        return { currentPage, currentTemplate, currentFields };
    }, [currentContentItem.content.pageUUID]);

    const onFieldsChange = (
        contentOrFunction: ContentItemFormFields | ((item: ContentItemFormFields) => ContentItemFormFields),
    ) => {
        if (typeof contentOrFunction === 'function') {
            onChange(contentOrFunction(currentContentItem));
        } else {
            onChange(mergeDeepRight(currentContentItem, contentOrFunction));
        }
    };

    return (
        <FormGrid
            onSubmit={async () => {
                if (onSubmit) onSubmit(currentContentItem);
            }}
            fields={makeContentTabFormFields(simulation, currentFields, currentContentItem, onFieldsChange)}
            footer={footer}
        />
    );
};
