import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { last, path, anyPass } from 'ramda';
import { css } from 'styled-components/macro';
import { MetadataRowPositioner } from './MetadataRowPositioner';
import { BaseThemingSnippetProps } from './utils';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { getAncestorsForItem } from '../../../utils/getAncestorsForContentItem';

export const LeftChatBubbleCssSnippet = css<BaseThemingSnippetProps>`
    border-width: ${({ theme }) => (path(['borders', 'top', 'width'], theme) ? '1px' : 0)};
    border-color: ${({ theme }) => path(['borders', 'top', 'color'], theme) || 'none'};
    border-style: ${({ theme }) => `${path(['borders', 'top', 'style'], theme) || 'solid'}`};
    border-radius: ${({ theme }) => (theme.borderRadius ? `${theme.borderRadius}px` : 0)};

    float: left;

    position: relative;
    :before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -18px;
        top: -1px;
        border: 18px solid;
        border-color: #dddddd transparent transparent transparent;
    }
    :after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -16px;
        top: 0px;
        border: 17px solid;
        border-color: ${({ theme: { bgColour } }) => bgColour || 'none'} transparent transparent transparent;
    }
`;
export const RightChatBubbleCssSnippet = css<BaseThemingSnippetProps>`
    border-width: ${({ theme }) => (path(['borders', 'top', 'width'], theme) ? '1px' : 0)};
    border-color: ${({ theme }) => path(['borders', 'top', 'color'], theme) || 'none'};
    border-style: ${({ theme }) => `${path(['borders', 'top', 'style'], theme) || 'solid'}`};
    border-radius: ${({ theme }) => (theme.borderRadius ? `${theme.borderRadius}px` : 0)};

    float: right;

    position: relative;
    :before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        right: -18px;
        top: -1px;
        border: 18px solid;
        border-color: ${({ theme }) => `${path(['borders', 'top', 'color'], theme) || '#000000'}`} transparent
            transparent transparent;
    }
    :after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        right: -16px;
        top: 0px;
        border: 17px solid;
        border-color: ${({ theme: { bgColour } }) => bgColour || 'none'} transparent transparent transparent;
    }

    ${MetadataRowPositioner} {
        flex-direction: row-reverse;
    }
`;

export const getChatPosition = (
    item: ContentItem,
    context: { items: ContentItem[]; participantUser?: ParticipantUser; staffUser?: StaffUser },
): 'RIGHT' | 'LEFT' => {
    const { staffUser, participantUser, items } = context;
    const rootItem = last(getAncestorsForItem(items, item)) || item;
    const isOp = anyPass([
        () => item.createdBy.type === 'PARTICIPANT' && item.createdBy.uuid === rootItem.createdBy.uuid,
        () => item.createdBy.type !== 'PARTICIPANT' && item.persona === rootItem.persona,
    ])();
    const chatOnRight = anyPass([
        () => !!participantUser && item.createdBy.type === 'PARTICIPANT' && item.createdBy.uuid === participantUser.id,
        () => !!staffUser && item.createdBy.type !== 'PARTICIPANT',
        () => !participantUser && !staffUser && isOp,
    ])();

    return chatOnRight ? 'RIGHT' : 'LEFT';
};
