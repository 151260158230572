import { Moment, MomentRating } from 'polpeo-go-common/types/Moment';
import { equals, map } from 'ramda';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/macro';
import { constructiveColour, destructiveColour, grey1Colour, grey3Colour } from '../../../../themes/colours';
import { TextButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Icon } from '../../../bits/Icon';
import { NotificationBanner } from '../../../bits/NotificationBanner';
import { Card } from '../../../patterns/Card';
import { EditMomentButton } from '../../../patterns/Moment';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../WithStaffUser';
import { ContentItemOptions } from '../../PageTemplate/ContentItem';
import { EmbeddedContentItem } from '../../PageTemplate/EmbeddedContentItem';
import { ItemPreviewButton } from '../../ContentItemPreviewButton';

const MomentAndContentItemGrid = styled.div`
    display: grid;
    grid-template-columns: 50fr 50fr;
    grid-template-rows: 1fr max-content;
    grid-template-areas:
        'contentItem momentDetails'
        'contentItem manageBar';

    column-gap: 20px;

    width: 100%;
`;
const ContentItemCell = styled.div`
    grid-area: contentItem;
`;
const MomentDetailsCell = styled(Display.VerticalWithSpacing)`
    grid-area: momentDetails;
    border-left: 1px solid ${grey1Colour};
    border-bottom: 1px solid ${grey1Colour};
    padding: 0 10px 10px 10px;
`;
const MomentManageBarCell = styled.div`
    grid-area: manageBar;
    border-left: 1px solid ${grey1Colour};
    padding: 10px 10px 0 10px;
`;

const MomentDetails = styled.div`
    display: flex;
    align-items: center;

    gap: 10px;

    * {
        flex: 0 0 auto;
    }
`;

const SubduedDescription = styled.i`
    color: ${grey3Colour};
`;

const InformationBar = styled.div`
    background: ${grey1Colour};
    margin-top: 10px;
    padding: 5px 15px;
`;

const ratingToIconMap = {
    [MomentRating.Down2]: <Icon icon={'momentDown2'} width={48} height={48} fill={destructiveColour} />,
    [MomentRating.Down1]: <Icon icon={'momentDown1'} width={33} height={33} fill={destructiveColour} />,
    [MomentRating.Up1]: <Icon icon={'momentUp1'} width={33} height={33} fill={constructiveColour} />,
    [MomentRating.Up2]: <Icon icon={'momentUp2'} width={48} height={48} fill={constructiveColour} />,
} as const;

interface MomentsListProps {
    moments: Moment[];
    contentItemOptions?: ContentItemOptions;
}
export const MomentsList: FC<MomentsListProps> = ({ moments, contentItemOptions }) => {
    const staffUser = useContext(StaffUserContext);
    const { staffUsers } = useContext(AdminStateContext);
    const {
        simulation,
        simulationContent: { contentItems },
    } = useContext(AdminInSimulationStateContext);

    return (
        <>
            <Display.VerticalWithSpacing>
                {!moments.length && <>No highlights found</>}
                {moments.length > 0 &&
                    map((moment) => {
                        const latestVersionOfContentItem =
                            contentItems[moment.contentItem.teamUUID][moment.contentItem.uuid];
                        const contentItemChanged =
                            !equals(latestVersionOfContentItem?.content, moment.contentItem.content) ||
                            !equals(latestVersionOfContentItem?.deleted, moment.contentItem.deleted);

                        const creator = staffUsers[moment.created.by];

                        return (
                            <Card key={moment.uuid}>
                                <MomentAndContentItemGrid>
                                    <ContentItemCell>
                                        <EmbeddedContentItem
                                            item={moment.contentItem}
                                            contentItemOptions={contentItemOptions}
                                        />
                                        {contentItemChanged && latestVersionOfContentItem && (
                                            <InformationBar>
                                                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                                                    <span>Content Item has changed since this moment was created</span>
                                                    <ItemPreviewButton
                                                        item={latestVersionOfContentItem}
                                                        buttonComponent={(props) => (
                                                            <TextButton
                                                                small
                                                                onClick={(e) => props.onClick && props.onClick(e)}
                                                            >
                                                                See latest version
                                                            </TextButton>
                                                        )}
                                                    />
                                                </Display.HorizontalWithMaxSpaceBetween>
                                            </InformationBar>
                                        )}
                                    </ContentItemCell>
                                    <MomentDetailsCell>
                                        <MomentDetails>
                                            {moment.rating && ratingToIconMap[moment.rating]}
                                            {!moment.rating && <SubduedDescription>No rating</SubduedDescription>}
                                            {moment.comments}
                                            {!moment.comments && <SubduedDescription>No comment</SubduedDescription>}
                                        </MomentDetails>
                                        {simulation.completedAt &&
                                            moment.created.at.toISOString() > simulation.completedAt.toISOString() && (
                                                <NotificationBanner>
                                                    This moment was created after the simulation was completed
                                                </NotificationBanner>
                                            )}
                                    </MomentDetailsCell>
                                    <MomentManageBarCell>
                                        <Display.RightAlign>
                                            {creator?.uuid === staffUser.uuid && <EditMomentButton moment={moment} />}
                                            {creator &&
                                                creator.uuid !== staffUser.uuid &&
                                                `Created by ${creator?.name}`}
                                            {!creator && 'Created by deleted user'}
                                        </Display.RightAlign>
                                    </MomentManageBarCell>
                                </MomentAndContentItemGrid>
                            </Card>
                        );
                    }, moments)}
            </Display.VerticalWithSpacing>
        </>
    );
};
