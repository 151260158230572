import React, { FC, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { TextInput } from '../../bits/FormFields';
import { FormGrid } from '../FormGrid';

interface NewPageStateFormProps {
    onCancel: () => void;
    stats: Record<string, string | number>;
    onSave: (newStat: Record<string, string | number>) => void;
}

export const NewPageStatForm: FC<NewPageStateFormProps> = ({ onCancel, stats, onSave }) => {
    const [statName, setStatName] = useState<string>('');
    const [statValue, setStatValue] = useState<string | number>('');

    const isEmptyKey = !statName;
    const isNewKey = stats[statName] === undefined;
    const canSave = isNewKey && !isEmptyKey;

    return (
        <Display.VerticalWithSpacing>
            <FormGrid
                fields={[
                    [
                        'Name',
                        <TextInput
                            key="Name"
                            value={statName}
                            onChange={(e) => {
                                setStatName(e.target.value);
                            }}
                        />,
                    ],
                    [
                        'Value',
                        <TextInput
                            key="Value"
                            value={statValue}
                            onChange={(e) => {
                                setStatValue(e.target.value);
                            }}
                        />,
                    ],
                ]}
                footer={
                    <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                        <PrimaryButton
                            disabled={!canSave}
                            onClick={() => {
                                onSave({ [statName]: statValue });
                            }}
                        >
                            Save
                        </PrimaryButton>
                    </Display.HorizontalWithSpacing>
                }
            />
        </Display.VerticalWithSpacing>
    );
};
