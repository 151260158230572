import {
    ALL_TEAM_PARTICIPANTS_IDENTIFIER,
    NewPrepreparedEmailItem,
    PrepreparedEmailItem,
} from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { cond, dissoc, T } from 'ramda';

export const makeInitialState = (
    sim: Simulation,
    options: {
        item?: PrepreparedEmailItem;
        parent?: PrepreparedEmailItem;
        copyOf?: PrepreparedEmailItem;
    },
): NewPrepreparedEmailItem | PrepreparedEmailItem =>
    cond([
        [(opts) => !!opts.item, ({ item }): PrepreparedEmailItem => ({ ...item })],
        [
            (opts) => !!opts.copyOf,
            ({ copyOf }): NewPrepreparedEmailItem =>
                dissoc<PrepreparedEmailItem, 'uuid'>('uuid', { ...copyOf, released: undefined }),
        ],
        [
            (opts) => !!opts.parent,
            ({ parent }): NewPrepreparedEmailItem => ({
                type: 'EMAIL',
                simulationUUID: parent.simulationUUID,
                parentUUID: parent.uuid,
                persona: '',
                content: {
                    recipients: [ALL_TEAM_PARTICIPANTS_IDENTIFIER],
                    subject: '',
                    message: '',
                },
                released: undefined,
            }),
        ],
        [
            T,
            (): NewPrepreparedEmailItem => ({
                type: 'EMAIL',
                simulationUUID: sim.uuid,
                parentUUID: undefined,
                persona: '',
                content: {
                    recipients: [ALL_TEAM_PARTICIPANTS_IDENTIFIER],
                    subject: '',
                    message: '',
                },
                released: undefined,
            }),
        ],
    ])(options);
