import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router';

export function useUnsavedWorkPrompt(hasUnsavedChanges: boolean): { unsavedPromptComponent: FC };
export function useUnsavedWorkPrompt(
    hasUnsavedChanges: boolean,
    func: () => void,
): { unsavedPromptComponent: FC; wrappedFunc: () => void };
export function useUnsavedWorkPrompt(
    hasUnsavedChanges: boolean,
    func?: () => void,
): { unsavedPromptComponent: FC; wrappedFunc?: () => void } {
    useEffect(() => {
        if (hasUnsavedChanges) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [hasUnsavedChanges]);

    if (!func) {
        return {
            // eslint-disable-next-line react/display-name
            unsavedPromptComponent: () => (
                <Prompt when={hasUnsavedChanges} message={() => 'Any unsaved changes will be lost'} />
            ),
        };
    }

    return {
        // eslint-disable-next-line react/display-name
        unsavedPromptComponent: () => (
            <Prompt when={hasUnsavedChanges} message={() => 'Any unsaved changes will be lost'} />
        ),
        wrappedFunc: () => {
            if (!hasUnsavedChanges) {
                func();
                return;
            }

            const confirmedProceed = window.confirm('Any unsaved changes will be lost');
            if (confirmedProceed) {
                func();
            }
        },
    };
}
