import { InitialScratchPadDocument } from 'polpeo-go-common/types/InitialScratchPadDocument';
import { ScratchPadCategory } from 'polpeo-go-common/types/ScratchPadDocument';
import React, { FC, useContext, useEffect, useState } from 'react';
import { createInitialScratchPadDocument, editInitialScratchPadDocument } from '../../../../graphql/scratchPad/';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { CardSection } from '../../../bits/Card';
import { Display } from '../../../bits/Display';
import { TextInput } from '../../../bits/FormFields';
import { Body1 } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Modal } from '../../../patterns/Modal';
import RichtextInput from '../../../patterns/RichtextInput';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { equals } from 'ramda';

interface InitialRichTextEditorModalProps {
    onCloseModal: () => void;
    category: ScratchPadCategory;
    documentToEdit?: InitialScratchPadDocument;
}

export const InitialRichTextDocumentModal: FC<InitialRichTextEditorModalProps> = ({
    onCloseModal,
    category,
    documentToEdit,
}) => {
    const { setSimulation } = useContext(AdminStateContext);
    const { currentSimulation } = useContext(AdminManageSimulationContext);

    const { name, notes } = documentToEdit || {};
    const simulationUUID = currentSimulation.uuid;

    const [title, setTitle] = useState(name || '');
    const [editorNotes, setEditorNotes] = useState(notes || '');
    const canSaveDocument = title.length > 0 && editorNotes.length > 0;

    const [
        createInitialScratchPadDocumentMutation,
        { data: createInitialScratchPadDocumentData, loading: createInitialScratchPadDocumentLoading },
    ] = createInitialScratchPadDocument.hook();

    const [
        editInitialScratchPadDocumentMutation,
        { data: editInitialScratchPadDocumentData, loading: editInitialScratchPadDocumentLoading },
    ] = editInitialScratchPadDocument.hook();

    useEffect(() => {
        const editedScratchPadDocumentData = editInitialScratchPadDocumentData?.editInitialScratchPadDocument;
        if (editedScratchPadDocumentData) {
            const { scratchPadDocuments } = currentSimulation;
            const currentDocs = scratchPadDocuments || [];

            const updatedDocs = currentDocs.map((doc) =>
                doc.uuid === editedScratchPadDocumentData.uuid ? editedScratchPadDocumentData : doc,
            );
            const simulationWithUpdatedScratchpad = { ...currentSimulation, scratchPadDocuments: updatedDocs };
            setSimulation(simulationWithUpdatedScratchpad);
            onCloseModal();
        }
        const newScratchPadDocumentData = createInitialScratchPadDocumentData?.createInitialScratchPadDocument;

        if (newScratchPadDocumentData) {
            const simulationWithUpdatedScratchpad = {
                ...currentSimulation,
                scratchPadDocuments: newScratchPadDocumentData,
            };
            setSimulation(simulationWithUpdatedScratchpad);
            onCloseModal();
        }
    }, [editInitialScratchPadDocumentData, createInitialScratchPadDocumentData]);

    const saveRichTextDocument = () => {
        if (documentToEdit) {
            const editedDocument = {
                ...documentToEdit,
                notes: editorNotes,
                name: title,
                simulationUUID,
            };
            editInitialScratchPadDocumentMutation({
                variables: {
                    scratchPadDocument: editedDocument,
                },
            });
        } else {
            const newDocument = {
                category: category,
                path: undefined,
                notes: editorNotes,
                name: title,
                simulationUUID,
            };

            createInitialScratchPadDocumentMutation({
                variables: {
                    scratchPadDocuments: [newDocument],
                },
            });
        }
    };
    const isDataLoading = createInitialScratchPadDocumentLoading || editInitialScratchPadDocumentLoading;

    const hasUnsavedChanges = !equals(documentToEdit || {}, {
        ...documentToEdit,
        name: title || '',
        notes: editorNotes || '',
    });
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal
                header={documentToEdit ? 'Edit Document' : 'New Document'}
                onModalClose={onModalCloseUnsavedPrompt}
                cardWidth={'80%'}
            >
                {isDataLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!isDataLoading && (
                    <>
                        <CardSection>
                            <TextInput
                                value={title}
                                placeholder="Document Name"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </CardSection>
                        <CardSection>
                            <RichtextInput
                                value={editorNotes}
                                imageUploadSimulation={currentSimulation}
                                onChange={(richtext) => setEditorNotes(richtext)}
                            />
                        </CardSection>
                        <CardSection>
                            <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                                <Body1>
                                    {documentToEdit && 'Saving changes will overwrite the work of your team mates'}
                                </Body1>
                                <Display.HorizontalWithSpacing gap={5}>
                                    <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                    <PrimaryButton disabled={!canSaveDocument} onClick={() => saveRichTextDocument()}>
                                        Save
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.HorizontalWithMaxSpaceBetween>
                        </CardSection>
                    </>
                )}
            </Modal>
        </>
    );
};
