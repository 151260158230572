import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { contains, equals } from 'ramda';
import React, { useContext, useRef } from 'react';
import { Display } from '../../bits/Display';
import { Icon } from '../../bits/Icon';
import { NavItem, NavSidebar } from '../../patterns/NavSidebar';
import { SideBarStateContext } from '../../WithSideBarState';
import { StaffUserContext } from '../../WithStaffUser';
import { ToolTips } from '../Tooltips';

export const AdminNav = (): React.ReactElement => {
    const staffUser = useContext(StaffUserContext);
    const { collapsed } = useContext(SideBarStateContext);
    const tooltipParent = useRef<HTMLDivElement>(null);

    const canListStaff = userCan(PermissionKey.LIST_STAFF_USERS, staffUser);

    return (
        <NavSidebar ref={tooltipParent} collapsed={collapsed}>
            <ToolTips parent={tooltipParent.current} />
            <Display.VerticalWithSpacing>
                <NavItem
                    to="/admin/simulations"
                    isActive={(match, location) =>
                        !!(
                            match ||
                            contains('/admin/simulation', location.pathname) ||
                            equals('/admin', location.pathname)
                        )
                    }
                    {...(collapsed ? { ['data-tooltip']: 'Simulations' } : {})}
                >
                    <Display.HorizontalWithSpacing gap={20} verticalCenter>
                        <Icon icon="simulation" /> <span>Simulations</span>
                    </Display.HorizontalWithSpacing>
                </NavItem>
                {canListStaff && (
                    <NavItem to="/admin/users" {...(collapsed ? { ['data-tooltip']: 'Users' } : {})}>
                        <Display.HorizontalWithSpacing gap={20} verticalCenter>
                            <Icon icon="user" /> <span>Users</span>
                        </Display.HorizontalWithSpacing>
                    </NavItem>
                )}
                <NavItem to="/admin/page-templates" {...(collapsed ? { ['data-tooltip']: 'Page Templates' } : {})}>
                    <Display.HorizontalWithSpacing gap={20} verticalCenter>
                        <Icon icon="page" /> <span>Page Templates</span>
                    </Display.HorizontalWithSpacing>
                </NavItem>
            </Display.VerticalWithSpacing>
        </NavSidebar>
    );
};
