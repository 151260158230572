import * as Sentry from '@sentry/react';
import React, { FC, useEffect, useState } from 'react';
import WithChildren from '../../utils/WithChildren';
import { MissingAncestorError } from '../../utils/errors';
import { PrimaryButton } from '../bits/Buttons';
import { FullPageError } from '../patterns/FullPageError';

// this need to be a child of Sentry.ErrorBoundary so that the thrown
// error can be caught by it
const EventListenerErrorHandler: FC<WithChildren> = ({ children }: WithChildren) => {
    const [eventError, setEventError] = useState<MissingAncestorError>();
    useEffect(() => {
        const errorHandler = (e: ErrorEvent) => {
            if (e.error) setEventError(e.error);
        };
        window.addEventListener('error', errorHandler);
        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);
    if (eventError) throw eventError;
    return <>{children}</>;
};

export const WithSentryError: FC<WithChildren> = ({ children }: WithChildren) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => {
                // caused by https://isotoma.atlassian.net/browse/POLP-723 this should be very rare
                // if this is showing up a lot in sentry another approach on fixing this may be needed
                if (error instanceof MissingAncestorError)
                    return (
                        <FullPageError message={error.message} errorCode="Something went wrong">
                            <PrimaryButton onClick={() => resetError()}>
                                Click here to reload the simulation
                            </PrimaryButton>
                        </FullPageError>
                    );
                return (
                    <FullPageError
                        errorCode=":("
                        message={'Something went wrong.\nPlease try again or contact an admin'}
                    >
                        <PrimaryButton onClick={() => resetError()}>Click here to reload the simulation</PrimaryButton>
                    </FullPageError>
                );
            }}
        >
            <EventListenerErrorHandler>{children}</EventListenerErrorHandler>
        </Sentry.ErrorBoundary>
    );
};
