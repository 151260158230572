import { useApolloClient } from '@apollo/client';
import { NewSimulation, Simulation } from 'polpeo-go-common/types/Simulation';
import { all, assocPath, dissocPath, equals, omit, pipe, values } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Client } from '../../../../graphql/client';
import { editSimulation } from '../../../../graphql/simulations';
import { createSimulation } from '../../../../graphql/simulations/createSimulation';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Spinner } from '../../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';
import { Card } from '../../../patterns/Card';
import { SimulationForm } from '../../../patterns/SimulationForm';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { doUploadTeamOverviewImages } from '../SimulationUpload/utils/createSimulationAndAssociatedData/doUploadImages';

const uploadOverviewImageAndUpdateSimulation = async (
    client: Client,
    simulation: Simulation,
    image: File,
): Promise<Simulation> => {
    const uploadedImages = await doUploadTeamOverviewImages(client, {
        simulation,
        images: { image },
    });
    const simWithHeaderImage = {
        ...simulation,
        startingOverview: {
            ...simulation.startingOverview,
            headerImage: uploadedImages['image'],
        },
    };
    const { data: editSimulationData } = await editSimulation.promise(client, {
        simulation: simWithHeaderImage,
    });
    const editedSimulation = editSimulationData?.editSimulation;
    if (!editedSimulation) {
        throw new Error('New simulation could not be updated with overview image');
    }

    return editedSimulation;
};

const PageContainer = styled.div`
    flex: 1 1 auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: start;
`;

export const NewSimulationFormPage: FC = () => {
    const history = useHistory();
    const client = useApolloClient();
    const { addSimulation } = useContext(AdminStateContext);

    const initialSimulation: NewSimulation = {
        name: '',
        clientName: '',
        emailDomainWhitelist: [],
        expectedNumberParticipants: undefined,
        scheduledFor: undefined,
        administratorUUIDs: [],
        startingOverview: {},
        showParticipantsMomentsGraph: true,
        createMomentsPermission: ['ADMIN', 'ROLEPLAYER'],
    };
    const [currentSimulation, setCurrentSimulation] = useState<NewSimulation>(initialSimulation);
    const [startingOverviewHeaderImage, setStartingOverviewHeaderImage] = useState<File>();
    // Manage our own load state instead of using the mutation because we also have to maybe upload
    // the starting team overview header image
    const [createSimulationLoading, setCreateSimulationLoading] = useState(false);

    const [createSimulationMutation, { data: createSimulationData }] = createSimulation.hook();
    useEffect(() => {
        if (createSimulationData?.createSimulation) {
            const newSimulation = createSimulationData.createSimulation;

            const processNewSimulation = async () => {
                if (!startingOverviewHeaderImage) {
                    addSimulation(newSimulation);
                } else {
                    const updatedNewSimulation = await uploadOverviewImageAndUpdateSimulation(
                        client,
                        newSimulation,
                        startingOverviewHeaderImage,
                    );
                    addSimulation(updatedNewSimulation);
                }
                setCreateSimulationLoading(false);
                history.push(`/admin/simulation/${newSimulation.uuid}/manage`);
            };
            processNewSimulation();
        }
    }, [createSimulationData]);

    const requiredSimulationFieldsCompleted = pipe(
        omit(['expectedNumberParticipants', 'scheduledFor', 'overview']),
        (details) => values(details),
        // Don't check for truthyness if it's a boolean otherwise the user can never choose "false"!!
        all((value) => !!value || typeof value === 'boolean'),
    )(currentSimulation);

    const hasUnsavedChanges = !equals(currentSimulation, initialSimulation) && !createSimulationData?.createSimulation;
    const { unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(hasUnsavedChanges);

    return (
        <PageContainer>
            <UnsavedPrompt />
            <Card header="New Simulation" cardWidth={800}>
                <Display.VerticalWithSpacing>
                    {createSimulationLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!createSimulationLoading && (
                        <SimulationForm
                            currentSimulation={currentSimulation}
                            onSubmit={async () => {
                                setCreateSimulationLoading(true);
                                await createSimulationMutation({
                                    variables: {
                                        simulation: dissocPath(['startingOverview', 'headerImage'], currentSimulation),
                                    },
                                });
                            }}
                            onChange={(fields) => setCurrentSimulation({ ...currentSimulation, ...fields })}
                            uploadOptions={{
                                onStartingImageUpload: async (image) => {
                                    // We can't actually upload the image without creating the simulation
                                    // but we do want to show the image preview so store the data url for now
                                    setStartingOverviewHeaderImage(image);
                                    const imageDataUrl = await new Promise((resolve) => {
                                        const fileReader = new FileReader();
                                        fileReader.onload = (e) => resolve(e.target?.result);
                                        fileReader.readAsDataURL(image);
                                    });
                                    setCurrentSimulation(assocPath(['startingOverview', 'headerImage'], imageDataUrl));
                                },
                            }}
                            footer={
                                <Display.RightAlign>
                                    <Display.HorizontalWithSpacing>
                                        <SecondaryButton
                                            type="button"
                                            onClick={() => history.push(`/admin/simulations`)}
                                        >
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit" disabled={!requiredSimulationFieldsCompleted}>
                                            Create
                                        </PrimaryButton>
                                    </Display.HorizontalWithSpacing>
                                </Display.RightAlign>
                            }
                        />
                    )}
                </Display.VerticalWithSpacing>
            </Card>
        </PageContainer>
    );
};
