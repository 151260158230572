import { useApolloClient } from '@apollo/client';
import { Team } from 'polpeo-go-common/types/Team';
import { assocPath, equals } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editTeam } from '../../graphql/teams';
import { uploadTeamOverviewImage } from '../../utils/uploadTeamOverviewImage';
import { PrimaryButton, SecondaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import { TextArea, TextInput } from '../bits/FormFields';
import { Spinner } from '../bits/Spinner';
import { useUnsavedWorkPrompt } from '../hooks/useUnsavedWorkPrompt';
import { DropZone } from '../patterns/DropZone';
import { Modal } from '../patterns/Modal';
import { AdminInSimulationStateContext } from '../WithAdminInSimulationState';
import { FormGrid } from './FormGrid';

interface EditTeamOverviewModalProps {
    team: Team;
    onModalClose: () => void;
}
export const EditTeamOverviewModal: FC<EditTeamOverviewModalProps> = ({ team, onModalClose }) => {
    const client = useApolloClient();
    const { simulation, setTeam } = useContext(AdminInSimulationStateContext);
    const [currentTeam, setCurrentTeam] = useState(team);
    const [startingOverviewImageUploading, setStartingOverviewImageUploading] = useState(false);

    const [editTeamMutation, { data: editTeamMutationData, loading: editTeamMutationLoading }] = editTeam.hook();
    useEffect(() => {
        if (editTeamMutationData?.editTeam) {
            const updatedTeam = editTeamMutationData?.editTeam;
            setTeam({ ...updatedTeam, pageStats: JSON.parse(updatedTeam.pageStats) });
            onModalClose();
        }
    }, [editTeamMutationData]);

    const hasUnsavedChanges = !equals(currentTeam, team);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onModalClose,
    );
    return (
        <>
            <UnsavedPrompt />
            <Modal header="Edit Team Overview" onModalClose={onModalCloseUnsavedPrompt}>
                {editTeamMutationLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editTeamMutationLoading && (
                    <FormGrid
                        fields={[
                            [
                                'Header Image',
                                <>
                                    {startingOverviewImageUploading && <Spinner />}
                                    {!startingOverviewImageUploading && (
                                        <>
                                            {currentTeam.overview?.headerImage && (
                                                <>
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            setCurrentTeam(
                                                                assocPath(['overview', 'headerImage'], undefined),
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </SecondaryButton>
                                                    <img src={currentTeam.overview?.headerImage} />
                                                </>
                                            )}
                                            <DropZone
                                                accept="image/*"
                                                onAcceptedFiles={async (files: File[]) => {
                                                    if (files.length === 1) {
                                                        setStartingOverviewImageUploading(true);
                                                        const imageFile = files[0];
                                                        const imagePath = await uploadTeamOverviewImage(
                                                            client,
                                                            simulation,
                                                            imageFile,
                                                        );
                                                        setCurrentTeam(
                                                            assocPath(['overview', 'headerImage'], imagePath),
                                                        );
                                                        setStartingOverviewImageUploading(false);
                                                    }
                                                }}
                                            >
                                                <p>Drag and drop your image here or click to select a image</p>
                                            </DropZone>
                                        </>
                                    )}
                                </>,
                            ],
                            [
                                'Title',
                                <>
                                    <TextInput
                                        value={currentTeam.overview?.title || ''}
                                        onChange={(e) => {
                                            setCurrentTeam(assocPath(['overview', 'title'], e.target.value));
                                        }}
                                    />
                                </>,
                            ],
                            [
                                'Description',
                                <>
                                    <TextArea
                                        value={currentTeam.overview?.description || ''}
                                        onChange={(e) => {
                                            setCurrentTeam(assocPath(['overview', 'description'], e.target.value));
                                        }}
                                    />
                                </>,
                            ],
                        ]}
                        footer={
                            <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    onClick={() => {
                                        editTeamMutation({
                                            variables: {
                                                team: {
                                                    ...currentTeam,
                                                    pageStats: JSON.stringify(currentTeam.pageStats),
                                                },
                                            },
                                        });
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        }
                    />
                )}
            </Modal>
        </>
    );
};
