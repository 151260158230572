import {
    decodePrepreparedEmailItem,
    NewPrepreparedEmailItem,
    PrepreparedEmailItem,
} from 'polpeo-go-common/types/EmailItem';
import { filter, flatten, isNil, map, reject } from 'ramda';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Client } from '../../../../../../graphql/client';
import { createPrepreparedEmail } from '../../../../../../graphql/prepreparedEmail';
import { UploadedPrepreparedEmail } from '../email/parseEmailsSheet';

const doCreatePrepreparedEmail = async (
    client: Client,
    item: NewPrepreparedEmailItem,
): Promise<PrepreparedEmailItem> => {
    const createEmailResponse = await createPrepreparedEmail.promise(client, {
        prepreparedEmail: item,
    });

    if (!createEmailResponse || !createEmailResponse.data) {
        throw new Error('Preprepared Email could not be created');
    }
    const createdPrepreparedEmail = createEmailResponse.data.createPrepreparedEmail;
    return decodePrepreparedEmailItem({
        ...createdPrepreparedEmail,
        released: createdPrepreparedEmail.released
            ? {
                  ...createdPrepreparedEmail.released,
                  items: JSON.parse(createdPrepreparedEmail.released.items),
              }
            : undefined,
    });
};
export const doCreatePrepreparedEmails = async (
    client: Client,
    context: {
        simulation: Simulation;
        uploadedContent: UploadedPrepreparedEmail[];
    },
    parent?: { uuid: string; tempId: string },
): Promise<{
    prepreparedEmailItems: PrepreparedEmailItem[];
    prepreparedEmailTriggerLinks: Record<string, string>;
    prepreparedEmailTempIdMap: Record<string, string>;
}> => {
    const { simulation, uploadedContent } = context;
    const allCreatePrepreparedEmail: PrepreparedEmailItem[] = [];
    let prepreparedEmailTriggerLinks: Record<string, string> = {};
    let prepreparedEmailTempIdMap: Record<string, string> = {};

    const items = filter(
        (item) => (parent ? item.tempParentId === parent.tempId : !item.tempParentId),
        uploadedContent,
    );

    await Promise.all(
        map(async (prepreparedEmailData) => {
            const prepreparedEmail = await doCreatePrepreparedEmail(client, {
                type: prepreparedEmailData.type,
                simulationUUID: simulation.uuid,
                parentUUID: parent ? parent.uuid : undefined,
                persona: prepreparedEmailData.persona,
                content: prepreparedEmailData.content,
                released: undefined,
            });
            allCreatePrepreparedEmail.push(prepreparedEmail);
            if (prepreparedEmailData.triggerTitle) {
                prepreparedEmailTriggerLinks = {
                    ...prepreparedEmailTriggerLinks,
                    [prepreparedEmail.uuid]: prepreparedEmailData.triggerTitle,
                };
            }
            if (prepreparedEmailData.tempId) {
                prepreparedEmailTempIdMap = {
                    ...prepreparedEmailTempIdMap,
                    [prepreparedEmailData.tempId]: prepreparedEmail.uuid,
                };
            }
            if (prepreparedEmailData.tempId) {
                const childrenResults = await doCreatePrepreparedEmails(client, context, {
                    uuid: prepreparedEmail.uuid,
                    tempId: prepreparedEmailData.tempId,
                });
                allCreatePrepreparedEmail.push(...childrenResults.prepreparedEmailItems);
                prepreparedEmailTriggerLinks = {
                    ...prepreparedEmailTriggerLinks,
                    ...childrenResults.prepreparedEmailTriggerLinks,
                };
                prepreparedEmailTempIdMap = {
                    ...prepreparedEmailTempIdMap,
                    ...childrenResults.prepreparedEmailTempIdMap,
                };
            }
        }, items),
    );

    return {
        prepreparedEmailItems: flatten(reject(isNil, allCreatePrepreparedEmail)),
        prepreparedEmailTriggerLinks,
        prepreparedEmailTempIdMap,
    };
};
