import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { MaxSubscriptionsReachedError } from '../utils/errors';
import { CONNECTION_CLOSED_ERROR, CONNECTION_MAX_SUBSCRIPTIONS_REACHED_ERROR } from './errorsMessages';

/* eslint-disable max-len */
/*
    Should be ApolloClient<NormalizedCacheObject>. Old apollo react hooks allowed passing of types:
    https://github.com/trojanowski/react-apollo-hooks/blob/0ba17aa8ccb70f858c05600bc023869ec4c332a7/src/ApolloContext.tsx#L22
    This library was absorbed into the main library which does not allow passing of type
    https://github.com/apollographql/apollo-client/blob/7993a778ef5a9e61bfee72b956f4c179fce691f4/src/react/hooks/useApolloClient.ts#L7
    hopefully this will be fixed at some point
*/
/* eslint-enable max-len */
// eslint-disable-next-line @typescript-eslint/ban-types
export type Client = ApolloClient<object>;

const createClientWithAuthLink = (graphQlEndpoint: string, authOptions: AuthOptions): Client => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
            );
        }
        if (networkError) {
            const networkErrors = networkError as unknown as { errors: { message: string }[] };
            const connectionClosed = networkErrors.errors.find((err) => err.message === CONNECTION_CLOSED_ERROR);
            const connectionMaxSubscriptionsReached = networkErrors.errors.find(
                (err) => err.message === CONNECTION_MAX_SUBSCRIPTIONS_REACHED_ERROR,
            );
            // caused by loss of internet or opening subscriptions while they are closing
            // https://isotoma.atlassian.net/browse/POLP-681 this can be fixed by the retry link
            if (connectionClosed) {
                console.warn(`[Network error]: ${connectionClosed}`, connectionClosed);
            }
            // the app has over 100 active subscriptions this should only occur if we are leaking
            // subscriptions retrying will not fix this
            else if (connectionMaxSubscriptionsReached) {
                Sentry.captureException(new MaxSubscriptionsReachedError(connectionMaxSubscriptionsReached.message));
                console.error(`[Network error]: ${networkError}`, networkError);
            } else {
                Sentry.captureException(new Error(networkErrors.errors[0].message));
                console.error(`[Network error]: ${networkError}`, networkError);
            }
        }
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 5000,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            // max: 5,
            retryIf: (error) => !!error,
        },
    });

    const httpLink = new HttpLink({
        uri: graphQlEndpoint,
    });

    const authLink = createAuthLink({
        url: graphQlEndpoint,
        region: 'eu-west-2',
        auth: authOptions,
    });
    const subscriptionLink = createSubscriptionHandshakeLink(
        {
            url: graphQlEndpoint,
            region: 'eu-west-2',
            auth: authOptions,
        },
        httpLink,
    );

    const link = ApolloLink.from([retryLink, errorLink, authLink, subscriptionLink]);

    return new ApolloClient({
        link: link,
        cache: new InMemoryCache({ addTypename: false }),
    });
};

export const createClientFromCredentials = (graphQlEndpoint: string): Client => {
    return createClientWithAuthLink(graphQlEndpoint, {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    });
};

export const createClientFromCognitoUser = (graphQlEndpoint: string): Client => {
    return createClientWithAuthLink(graphQlEndpoint, {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const session = cognitoUser.getSignInUserSession();
            if (!session) {
                throw new Error('No session found for cognito user');
            }
            return session.getIdToken().getJwtToken();
        },
    });
};
