import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { grey1Colour, primaryColour } from '../../themes/colours';
import { screenReaderOnly } from '../../themes/cssSnippets';
import { IconSvg } from '../bits/Icon/IconSvg';

export const NavItem = styled(NavLink)`
    background: #ffffff;
    border-radius: 5px;
    padding: 15px;

    &.active {
        background: ${primaryColour};
        color: #ffffff;

        ${IconSvg} {
            fill: #ffffff;
        }
    }
`;

export const NavDivider = styled.div`
    border-bottom: 1px solid ${grey1Colour};
`;
interface NavSidebarProps {
    collapsed?: boolean;
    width?: number;
}
export const NavSidebar = styled.nav<NavSidebarProps>`
    width: ${({ collapsed, width }) => (collapsed ? 95 : width || 300)}px;
    padding: 20px;
    border-right: 1px solid ${grey1Colour};
    flex: 0 0 auto;

    ${NavItem} span {
        display: inline-block;
        max-width: 100%;
        overflow-wrap: anywhere;
        ${({ collapsed }) => (collapsed ? screenReaderOnly : '')};
    }

    ${NavDivider} {
        margin: 0 -20px;
        width: ${({ collapsed, width }) => (collapsed ? 95 : width || 300)}px;
    }
`;
