import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../../../bits/Buttons';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { NewTriggerModal } from './NewTriggerModal';

export const NewTriggerButton: FC = () => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>New Trigger</SecondaryButton>
            {showModal && <NewTriggerModal onModalClose={() => setShowModal(false)} simulation={currentSimulation} />}
        </>
    );
};
