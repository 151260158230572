import { isRight } from 'fp-ts/lib/Either';
import { Trigger } from 'polpeo-go-common/types/Trigger';

// Triggers contain a date type which are passed to us as strings by graphql
// use 'decode' to actually turn them into Date objects!
export const decodeTrigger = (trigger: unknown): Trigger => {
    const maybeTrigger = Trigger.decode(trigger);
    if (isRight(maybeTrigger)) {
        return maybeTrigger.right;
    }
    throw new Error('Trigger was not the right shape');
};
