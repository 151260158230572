import { ScratchPadCategory } from 'polpeo-go-common/types/ScratchPadDocument';
import React, { FC, useState } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { Icon } from '../../../bits/Icon';
import { AddDocumentModal } from './AddDocumentModal';
import { RichTextDocumentFormModal } from './RichTextDocumentFormModal';

interface AddDocumentButtonProps {
    category: ScratchPadCategory;
}

export const AddDocumentButton: FC<AddDocumentButtonProps> = ({ category }) => {
    const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
    const [showRichTextDocumentEditorModal, setShowRichTextDocumentEditorModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowAddDocumentModal(true)} small>
                <Icon icon="add" size={15} />
                Add Document
            </SecondaryButton>
            {showAddDocumentModal && (
                <AddDocumentModal
                    onCloseModal={() => setShowAddDocumentModal(false)}
                    onNewRichtextScratchpadDocument={() => setShowRichTextDocumentEditorModal(true)}
                    category={category}
                />
            )}
            {showRichTextDocumentEditorModal && (
                <RichTextDocumentFormModal
                    onCloseModal={() => setShowRichTextDocumentEditorModal(false)}
                    category={category}
                />
            )}
        </>
    );
};
