import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { PasswordInput, TextInput } from '../../../bits/FormFields';
import { Body3, H2Heading, StyledErrorMessage } from '../../../bits/Headers';
import { Link } from '../../../bits/Link';
import { SplashContainer, SplashHeroCard } from '../../../bits/SplashContainer';
import { Logo } from '../../../patterns/Logo';
import AuthContext from '../../../WithAuth/AuthContext';

interface ConfirmNewPasswordFormProps {
    onResetPassword: (event: React.SyntheticEvent) => Promise<void>;
    setNewPassword: React.Dispatch<React.SetStateAction<string>>;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    newPassword: string;
    code: string;
    error?: string;
}
const ConfirmNewPasswordForm = ({
    onResetPassword,
    setNewPassword,
    setCode,
    newPassword,
    code,
    error,
}: ConfirmNewPasswordFormProps) => {
    return (
        <form onSubmit={onResetPassword}>
            <Display.VerticalWithSpacing>
                <Logo />
                <H2Heading>Password Reset</H2Heading>

                <TextInput
                    validationFailed={!!error}
                    placeholder="Verification code"
                    onChange={({ target }) => setCode(target.value)}
                    value={code}
                />
                <PasswordInput
                    autoComplete="new-password"
                    validationFailed={!!error}
                    placeholder="New Password"
                    onChange={({ target }) => setNewPassword(target.value)}
                    value={newPassword}
                />
                <Body3>Use 8 or more characters with a mix of upper and lowercase letters, numbers and symbols</Body3>

                {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                <Display.RightAlign>
                    <PrimaryButton type="submit" disabled={!code || !newPassword}>
                        Continue
                    </PrimaryButton>
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </form>
    );
};

interface ResetPasswordFormProps {
    onSendResetCode: (event: React.SyntheticEvent) => Promise<void>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    error?: string;
}
const ResetPasswordForm = ({ onSendResetCode, setEmail, email, error }: ResetPasswordFormProps) => {
    return (
        <form onSubmit={onSendResetCode}>
            <Display.VerticalWithSpacing>
                <Logo />
                <Display.VerticalWithSpacing gap={10}>
                    <H2Heading>Password Reset</H2Heading>
                    <b> This is only for Polpeo Staff Members </b>
                    <span>
                        Remembered your password? <Link to="/admin/login">Back to login</Link>
                    </span>
                </Display.VerticalWithSpacing>

                <TextInput
                    validationFailed={!!error}
                    placeholder="Email Address"
                    onChange={({ target }) => setEmail(target.value)}
                />
                {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
                <Display.RightAlign>
                    <PrimaryButton type="submit" disabled={!email}>
                        Continue
                    </PrimaryButton>
                </Display.RightAlign>
            </Display.VerticalWithSpacing>
        </form>
    );
};

export const ResetPassword = (): React.ReactElement => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [sentEmail, setSentEmail] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState<string>('');
    const history = useHistory();
    const { sendCode, resetPassword } = useContext(AuthContext);

    const onSendResetCode = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        // Send confirmation code to user's email
        try {
            await sendCode(email);
            setSentEmail(true);
            setError('');
        } catch (err) {
            setSentEmail(false);

            if (err.code === 'UserNotFoundException') {
                setError('Email address does not exist.');
            } else if (err.code === 'NotAuthorizedException') {
                setError(
                    'User have not logged in before. Try logging in using your temporary password or contact an admin.',
                );
            } else {
                setError(err.message);
            }
        }
    };

    const onResetPassword = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        // Confirm verification code and set new password
        try {
            await resetPassword(email, code, newPassword);
            history.push('/admin/login');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <SplashContainer>
            <SplashHeroCard>
                {sentEmail ? (
                    <ConfirmNewPasswordForm
                        onResetPassword={onResetPassword}
                        setNewPassword={setNewPassword}
                        setCode={setCode}
                        newPassword={newPassword}
                        code={code}
                        error={error}
                    />
                ) : (
                    <ResetPasswordForm
                        onSendResetCode={onSendResetCode}
                        setEmail={setEmail}
                        email={email}
                        error={error}
                    />
                )}
            </SplashHeroCard>
        </SplashContainer>
    );
};
