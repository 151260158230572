import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import { always, cond, T } from 'ramda';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { editParticipantUser } from '../../../../graphql/participantUsers';
import { SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Body3, H3Heading, H2Heading } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { Card } from '../../../patterns/Card';
import { Logo } from '../../../patterns/Logo';
import { ParticipantStateContext } from '../../../WithParticipantState/participantState';
import { ParticipantUserContext } from '../../../WithParticipantUser';
import { ParticipantDetailsForm } from './ParticipantDetailsForm';

export const ParticipantDetailsCard = styled(Card)`
    min-width: 165px;
    ${H3Heading}, ${Body3} {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

interface WaitingRoomProps {
    simulation: BasicSimulation;
    countDown?: number;
}
export const WaitingRoom: FC<WaitingRoomProps> = ({ simulation, countDown }: WaitingRoomProps): React.ReactElement => {
    const [editDetails, setEditDetails] = useState(false);
    const [editParticipantDetailsMutation] = editParticipantUser.hook();
    const { participantUser, setParticipantUser } = useContext(ParticipantUserContext);
    const { participantTeam, preliminaryParticipantTeam } = useContext(ParticipantStateContext);

    const updateParticipantUserDetails = (participantDetails: ParticipantUser) => {
        editParticipantDetailsMutation({
            variables: { simulationUUID: simulation.uuid, participant: participantDetails },
        });
        setParticipantUser(participantDetails);
        setEditDetails(false);
    };

    const teamNameToShowParticipant = preliminaryParticipantTeam?.name || '';

    return (
        <Display.VerticalWithSpacing horizontalAlign="center">
            <Logo width={125} />
            {editDetails ? (
                <ParticipantDetailsForm
                    simulationCode={simulation.code}
                    onSubmitParticipantDetails={updateParticipantUserDetails}
                    participant={participantUser}
                />
            ) : (
                <>
                    <H2Heading>
                        {cond([
                            [
                                () => !simulation.startedAt && !!preliminaryParticipantTeam,
                                always(`You're all ready! The simulation will start soon. 
                                You have been assigned to "${teamNameToShowParticipant}"`),
                            ],
                            [() => !simulation.startedAt, always(`You're all ready! The simulation will start soon.`)],
                            [
                                () => !!simulation.startedAt && countDown === 0,
                                always('The simulation will start in ...'),
                            ],
                            [
                                () => !!simulation.startedAt && !!countDown,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                always(`The simulation will start in ${Math.ceil(countDown! / 1000)} seconds...`),
                            ],
                            [
                                () => !!simulation.startedAt && !!preliminaryParticipantTeam,
                                always(
                                    `You're all ready! The simulation has already started.You have been assigned 
                                    to "${teamNameToShowParticipant}"`,
                                ),
                            ],
                            [
                                T,
                                always(
                                    `You're all ready! The simulation has already started. 
                                    Please make sure that a member of staff knows that you are here.`,
                                ),
                            ],
                        ])()}
                    </H2Heading>
                    {!!simulation.startedAt && countDown !== undefined && <Spinner />}
                    <ParticipantDetailsCard>
                        <Display.VerticalWithSpacing horizontalAlign="center" gap={10}>
                            <H3Heading>{participantUser.fullName}</H3Heading>
                            <Body3>{participantUser.email}</Body3>
                            <SecondaryButton
                                type="button"
                                disabled={!!simulation.startedAt && !!participantTeam}
                                onClick={() => {
                                    setEditDetails(true);
                                }}
                            >
                                Edit Details
                            </SecondaryButton>
                        </Display.VerticalWithSpacing>
                    </ParticipantDetailsCard>
                </>
            )}
        </Display.VerticalWithSpacing>
    );
};
