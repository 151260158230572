import { isRight } from 'polpeo-go-common/node_modules/fp-ts/lib/Either';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';

// Simulation contains a few date types which are passed to us as strings by graphql
// use 'decode' to actually turn them into Date objects!

export const decodeBasicSimulation = (sim: unknown): BasicSimulation => {
    const maybeSimulation = BasicSimulation.decode(sim);
    if (isRight(maybeSimulation)) {
        return maybeSimulation.right;
    }
    throw new Error('Simulation was not the right shape');
};
