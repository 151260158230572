import { map, omit } from 'ramda';
import React, { FC } from 'react';
import { Route, RouteProps, Switch } from 'react-router';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { grey1Colour, grey4Colour } from '../../themes/colours';
import { H7Heading } from '../bits/Headers';
import { Icon, IconOptions } from '../bits/Icon';
import { IconSvg } from '../bits/Icon/IconSvg';
import { NotificationContainer } from '../templates/NotificationContainer';

export const TabContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    max-width: 100%;
`;

const TabBarContainer = styled.div`
    width: 100%;
    background: #d9dde3;
    display: flex;
`;
const TabBar = styled.div`
    width: 100%;
    padding: 0 20px;
    display: flex;

    overflow-x: auto;
`;
const TabBarAppended = styled.div`
    width: auto;
    padding: 0 20px;
`;
const TabItem = styled(NavLink)`
    position: relative;
    background: transparent;
    margin: 5px 0 0;
    padding: 12px 35px;
    border-radius: 5px 5px 0 0;
    white-space: nowrap;
    z-index: 1;

    display: flex;
    gap: 20px;

    ${IconSvg} {
        flex: 0 0 auto;
    }

    max-width: 500px;

    :hover {
        background: ${grey1Colour};
    }

    :not(:hover):after {
        content: '';
        display: inline-block;
        height: 50%;
        right: -1px;
        bottom: 25%;
        position: absolute;
        width: 1px;
        background: ${grey4Colour};
        z-index: 0;
    }

    &.active {
        background: #ffffff;
    }

    &.active:before {
        content: '';
        display: inline-block;
        height: 50%;
        left: -10px;
        bottom: 0;
        position: absolute;
        width: 10px;
        background: transparent;
        border-radius: 0 0 5px 0;
        box-shadow: 5px 0 0 0 white;
    }

    &.active:after {
        content: '';
        display: inline-block;
        height: 50%;
        right: -10px;
        bottom: 0;
        position: absolute;
        width: 10px;
        background: transparent;
        border-radius: 0 0 0 5px;
        box-shadow: -5px 0 0 0 white;
    }
`;
const TabHeading = styled(H7Heading)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface TabItem {
    label: string;
    icon?: IconOptions;
    notification?: boolean;
    navLink: NavLinkProps;
    route: RouteProps;
}
interface TabMenuProps {
    tabs: TabItem[];
    appendedItems?: React.ReactElement;
}
export const TabMenu: FC<TabMenuProps> = ({ tabs, appendedItems }: TabMenuProps) => {
    return (
        <TabContentsContainer>
            <TabBarContainer>
                <TabBar>
                    {map<TabItem, React.ReactNode>(
                        (tab) => (
                            <TabItem key={tab.label} {...tab.navLink}>
                                {tab.icon && <Icon icon={tab.icon} size={24} />}
                                <NotificationContainer
                                    position={{ top: -4, right: -16 }}
                                    notification={tab.notification}
                                >
                                    <TabHeading>{tab.label}</TabHeading>
                                </NotificationContainer>
                            </TabItem>
                        ),
                        tabs,
                    )}
                </TabBar>
                {!!appendedItems && <TabBarAppended>{appendedItems}</TabBarAppended>}
            </TabBarContainer>
            <Switch>
                {map<TabItem, React.ReactNode>(
                    (tab) => (
                        <Route key={tab.label} {...omit(['children'], tab.route)}>
                            {tab.route.children}
                        </Route>
                    ),
                    tabs,
                )}
            </Switch>
        </TabContentsContainer>
    );
};
