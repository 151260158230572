import { decodePrepreparedContentItem, PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { forEach } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { releasePrepreparedContent } from '../../../../../../../graphql/prepreparedContent';
import { PrimaryButton, SecondaryButton } from '../../../../../../bits/Buttons';
import { Display } from '../../../../../../bits/Display';
import { Body2 } from '../../../../../../bits/Headers';
import { Spinner } from '../../../../../../bits/Spinner';
import { Modal } from '../../../../../../patterns/Modal';
import { AdminStateContext } from '../../../../../../WithAdminState/adminState';
import { EmbeddedContentItem } from '../../../../../PageTemplate/EmbeddedContentItem';

interface ReleasePrepreparedContentModalProps {
    item: PrepreparedContentItem;
    onCloseModal: () => void;
}
const ReleasePrepreparedContentModal: FC<ReleasePrepreparedContentModalProps> = ({ item, onCloseModal }) => {
    const { setPrepreparedContent, simulationContent } = useContext(AdminStateContext);
    const { prepreparedContents } = simulationContent[item.simulationUUID];

    const [
        releasePrepreparedContentMutation,
        { data: releasePrepreparedContentData, loading: releasePrepreparedContentsLoading },
    ] = releasePrepreparedContent.hook();
    useEffect(() => {
        if (releasePrepreparedContentData?.releasePrepreparedContent) {
            const updatedItems = releasePrepreparedContentData.releasePrepreparedContent.items;
            forEach((updatedItem) => {
                const originalItem = prepreparedContents[updatedItem.uuid];
                if (originalItem) {
                    setPrepreparedContent(
                        decodePrepreparedContentItem({
                            ...originalItem,
                            released: updatedItem.released
                                ? {
                                      ...updatedItem.released,
                                      items: JSON.parse(updatedItem.released.items),
                                  }
                                : undefined,
                        }),
                    );
                }
            }, updatedItems);
            onCloseModal();
        }
    }, [releasePrepreparedContentData]);

    return (
        <Modal onModalClose={onCloseModal} cardWidth={800} header="Release Content Item">
            {releasePrepreparedContentsLoading && (
                <Display.HorizontalCenterVerticalCenter>
                    <Spinner />
                </Display.HorizontalCenterVerticalCenter>
            )}
            {!releasePrepreparedContentsLoading && (
                <Display.VerticalWithSpacing>
                    <p>Are you sure you want to release the item: </p>
                    <EmbeddedContentItem item={item} />
                    <p>This will also release any parent content that has not been released</p>
                    <Body2 destructive>
                        <b>This action cannot be undone.</b>
                    </Body2>
                    <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                        <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
                        <PrimaryButton
                            onClick={() => {
                                releasePrepreparedContentMutation({
                                    variables: {
                                        prepreparedItem: {
                                            ...item,
                                            content: {
                                                ...item.content,
                                                data: JSON.stringify(item.content.data),
                                            },
                                            released: undefined,
                                        },
                                    },
                                });
                            }}
                        >
                            Release
                        </PrimaryButton>
                    </Display.HorizontalWithSpacing>
                </Display.VerticalWithSpacing>
            )}
        </Modal>
    );
};

interface ReleasePrepreparedContentButtonProps {
    item: PrepreparedContentItem;
}
export const ReleasePrepreparedContentButton: FC<ReleasePrepreparedContentButtonProps> = ({ item }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <PrimaryButton onClick={() => setShowModal(true)}>Release</PrimaryButton>
            {showModal && <ReleasePrepreparedContentModal item={item} onCloseModal={() => setShowModal(false)} />}
        </>
    );
};
