import gql from 'graphql-tag';
import { Page } from 'polpeo-go-common/types/Page';
import wrapSubscription from '../wrapSubscription';

interface OnWritePageParams {
    simulationUUID: string;
}

interface OnWritePageData {
    onWritePage: Omit<Page, 'stats'> & { stats: string /* AWSJSON - Record<string, string> */ };
}

const subscription = gql`
    subscription onWritePage($simulationUUID: String!) {
        onWritePage(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            templateUUID
            name
            stats
            dressing {
                header
                leftSidebar
                rightSidebar
                footer
            }
            createRootContentPermission
            showItemsAsIndividualPages
            participantDeleteContentPermission
            hiddenFromTeamUUIDs
        }
    }
`;

export const onWritePage = wrapSubscription<OnWritePageParams, OnWritePageData>(subscription);
