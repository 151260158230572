import styled from 'styled-components/macro';
import { primaryColour, grey2Colour } from '../../../themes/colours';
import { body1 } from '../../../themes/cssSnippets';

interface TextInputProps {
    validationFailed?: boolean;
    small?: boolean;
}
export const TextInput = styled.input.attrs(() => ({
    type: 'text',
}))<TextInputProps>`
    width: 100%;
    padding: ${({ small }) => (small ? '8px' : '20px 16px')};

    ${body1}

    border: 1px solid ${(props) => (props.validationFailed ? 'red' : grey2Colour)};
    border-radius: 5px;

    &:focus {
        border: 1px solid ${primaryColour};
    }
`;
