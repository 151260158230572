import { Moment } from 'polpeo-go-common/types/Moment';
import { equals, mergeDeepRight } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editMoment } from '../../../graphql/moment';
import { decodeMoment } from '../../../utils/decodeMoment';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { Modal } from '../Modal';
import { MomentForm } from './MomentForm';

interface EditMomentModalProps {
    moment: Moment;
    onCloseModal: () => void;
}
export const EditMomentModal: FC<EditMomentModalProps> = ({ moment, onCloseModal }) => {
    const { addMoments } = useContext(AdminInSimulationStateContext);
    const [currentMoment, setCurrentMoment] = useState<Moment>(moment);

    const [editMomentMutation, { data: editMomentData, loading: editMomentsLoading }] = editMoment.hook();
    useEffect(() => {
        if (editMomentData?.editMoment) {
            const editMoment = editMomentData?.editMoment;
            addMoments([
                decodeMoment({
                    ...editMoment,
                    contentItem: {
                        ...editMoment.contentItem,
                        content: {
                            ...editMoment.contentItem.content,
                            data: JSON.parse(editMoment.contentItem.content.data),
                        },
                    },
                }),
            ]);
            onCloseModal();
        }
    }, [editMomentData]);

    const canSave = currentMoment.rating || currentMoment.comments;

    const hasUnsavedChanges = !equals(currentMoment, moment);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={800} header="Edit Highlight">
                {editMomentsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editMomentsLoading && (
                    <MomentForm
                        currentMoment={currentMoment}
                        item={moment.contentItem}
                        onChange={(moment) => setCurrentMoment(mergeDeepRight(currentMoment, moment))}
                        footer={
                            <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    disabled={!canSave}
                                    onClick={() => {
                                        editMomentMutation({
                                            variables: {
                                                moment: {
                                                    ...currentMoment,
                                                    created: {
                                                        ...currentMoment.created,
                                                        at: currentMoment.created.at.toISOString(),
                                                    },
                                                    contentItem: {
                                                        ...currentMoment.contentItem,
                                                        type: 'PAGE_CONTENT',
                                                        createdAt: currentMoment.contentItem.createdAt.toISOString(),
                                                        content: {
                                                            ...currentMoment.contentItem.content,
                                                            data: JSON.stringify(
                                                                currentMoment.contentItem.content.data,
                                                            ),
                                                        },
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        }
                    />
                )}
            </Modal>
        </>
    );
};
