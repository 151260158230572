import { toUpper } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { isLobbyOpen } from '../../../../graphql/simulations/isLobbyOpen';
import { PrimaryButton } from '../../../bits/Buttons';
import { Body3 } from '../../../bits/Headers';
import { Display } from '../../../bits/Display';
import { TextInput } from '../../../bits/FormFields';
import { Link } from '../../../bits/Link';
import { Spinner } from '../../../bits/Spinner';
import { Logo } from '../../../patterns/Logo';

const ExampleSimulationCode = styled(Body3)`
    padding-left: 20px;
`;

interface CheckOpenLobbyFormProps {
    initialSimulationCode?: string;
    onSimulationLobbyOpen: (code: string) => void;
}
export const CheckOpenLobbyForm: FC<CheckOpenLobbyFormProps> = ({
    initialSimulationCode,
    onSimulationLobbyOpen,
}: CheckOpenLobbyFormProps): React.ReactElement => {
    const [simulationCode, setSimulationCode] = useState('');
    const [isLobbyOpenQuery, { data: isLobbyOpenData, loading: isLobbyOpenLoading, variables: isLobbyOpenVariables }] =
        isLobbyOpen.lazyHook({
            simulationCode,
        });

    useEffect(() => {
        if (initialSimulationCode) {
            isLobbyOpenQuery({ variables: { simulationCode: toUpper(initialSimulationCode) } });
            setSimulationCode(toUpper(initialSimulationCode));
        }
    }, [initialSimulationCode]);

    useEffect(() => {
        if (isLobbyOpenData?.isLobbyOpen && isLobbyOpenVariables?.simulationCode) {
            onSimulationLobbyOpen(isLobbyOpenVariables.simulationCode);
        }
    }, [isLobbyOpenData]);

    const showSpinner = isLobbyOpenLoading || (!isLobbyOpenLoading && isLobbyOpenData?.isLobbyOpen);
    const simulationNotOpen = !isLobbyOpenLoading && isLobbyOpenData?.isLobbyOpen === false;
    const canEnterCode = !isLobbyOpenLoading && !isLobbyOpenData?.isLobbyOpen;

    return (
        <Display.VerticalWithSpacing gap={40}>
            <Logo />
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    isLobbyOpenQuery({ variables: { simulationCode } });
                }}
            >
                <Display.VerticalWithSpacing>
                    {showSpinner && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {simulationNotOpen &&
                        isLobbyOpenVariables &&
                        `That code doesn't seem to be correct, please check it and try again`}
                    {canEnterCode && (
                        <>
                            <div>
                                <TextInput
                                    placeholder="Simulation Code"
                                    value={simulationCode}
                                    onChange={(e) => setSimulationCode(toUpper(e.target.value))}
                                />
                                <ExampleSimulationCode>e.g. RXEDCASD</ExampleSimulationCode>
                            </div>
                            <Display.HorizontalWithMaxSpaceBetween>
                                <Link to="/admin/login">Staff Login</Link>
                                <PrimaryButton type="submit">Join</PrimaryButton>
                            </Display.HorizontalWithMaxSpaceBetween>
                        </>
                    )}
                </Display.VerticalWithSpacing>
            </form>
        </Display.VerticalWithSpacing>
    );
};
