import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import { Display } from '../../../bits/Display';
import { CardButton } from '../../../patterns/Card';
import { Icon } from '../../../bits/Icon';
import styled from 'styled-components/macro';
import { H4Heading } from '../../../bits/Headers';
import { Modal } from '../../../patterns/Modal';
import { SecondaryButton } from '../../../bits/Buttons';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { useApolloClient } from '@apollo/client';
import { ScratchPadCategory, ScratchPadDocument, acceptedFileTypes } from 'polpeo-go-common/types/ScratchPadDocument';
import { createScratchPadDocument } from '../../../../graphql/scratchPad/createScratchPadDocument';
import { ParticipantStateContext } from '../../../WithParticipantState';
// eslint-disable-next-line max-len
import { getSignedPutUrlforScratchPadDocumentUpload } from '../../../../graphql/scratchPad/getSignedPutUrlforScratchPadDocumentUpload';
import { Spinner } from '../../../bits/Spinner';
import { decodeBasicSimulation } from '../../../../utils/decodeBasicSimulation';
import { difference, filter, findIndex, map, propEq } from 'ramda';

const CardLinkHeading = styled(H4Heading)`
    text-align: center;
`;

export const MultiLineError = styled.b`
    white-space: 'pre-line';
    overflow-wrap: anywhere;
`;

interface AddDocumentModalProps {
    onCloseModal: () => void;
    onNewRichtextScratchpadDocument: () => void;
    category: ScratchPadCategory;
}

export const AddDocumentModal: FC<AddDocumentModalProps> = ({
    onCloseModal,
    onNewRichtextScratchpadDocument,
    category,
}) => {
    const client = useApolloClient();
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const handleUploadFilesButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const {
        simulation: adminSimulation,
        setScratchPadDocuments: setAdminScratchPadDocuments,
        scratchPadDocuments: adminScratchPadDocuments,
        urlPathParams,
    } = useContext(AdminInSimulationStateContext);
    const {
        simulation: participantSimulation,
        participantTeam,
        scratchPadDocuments: participantScratchPadDocuments,
        setScratchPadDocuments: setParticipantScratchPadDocuments,
    } = useContext(ParticipantStateContext);

    const teamUUID = urlPathParams?.teamUUID || participantTeam?.uuid;

    if (!teamUUID) {
        throw new Error('Participant team could not be found');
    }

    const [isFileUploading, setIsFileUploading] = useState(false);
    const [fileUploadError, setFileUploadError] = useState<string>();
    const [duplicateFiles, setDuplicateFiles] = useState<File[]>([]);
    const [uniqueDocuments, setUniqueDocuments] = useState<File[]>([]);
    const simulation = adminSimulation ? decodeBasicSimulation(adminSimulation) : participantSimulation;
    const [scratchPadDocumentsForCurrentModal, setScratchPadDocumentsForCurrentModal] = useState<ScratchPadDocument[]>(
        [],
    );

    useEffect(() => {
        if (fileUploadError && !duplicateFiles.length && uniqueDocuments.length === 1) {
            setIsFileUploading(false);
        }
        if (!fileUploadError && duplicateFiles.length > 0 && !uniqueDocuments.length) {
            setIsFileUploading(false);
        }
        if (fileUploadError && duplicateFiles.length === 1 && uniqueDocuments.length === 1) {
            setIsFileUploading(false);
        }
    }, [fileUploadError]);

    useEffect(() => {
        const filterDocsByCurrentCategory = filter(
            (document: ScratchPadDocument) => document.category === category && document.teamUUID === teamUUID,
            adminScratchPadDocuments || participantScratchPadDocuments || [],
        );
        setScratchPadDocumentsForCurrentModal(filterDocsByCurrentCategory);
    }, [adminScratchPadDocuments, participantScratchPadDocuments]);
    const [createScratchPadDocumentMutation, { data: createScratchPadData }] = createScratchPadDocument.hook();

    // this doesn't work because the moadal closes after the first
    // upload and the subsciption handles the adding to the scratch pad
    useEffect(() => {
        if (createScratchPadData) {
            if (createScratchPadData?.createScratchPadDocument && adminScratchPadDocuments) {
                const updateDocs = [...adminScratchPadDocuments, createScratchPadData?.createScratchPadDocument];
                setAdminScratchPadDocuments(updateDocs);
            }
            if (createScratchPadData?.createScratchPadDocument && participantScratchPadDocuments) {
                const updateDocs = [...participantScratchPadDocuments, createScratchPadData?.createScratchPadDocument];
                setParticipantScratchPadDocuments(updateDocs);
            }
            setIsFileUploading(false);
            if (!duplicateFiles.length && !fileUploadError) {
                onCloseModal();
            }
        }
    }, [createScratchPadData]);

    const resetErrorsDuplicates = () => {
        setDuplicateFiles([]);
        setFileUploadError('');
    };

    const handleFileUpload = async (e: ChangeEvent) => {
        e.preventDefault();
        setIsFileUploading(true);
        const target = e.target as HTMLInputElement;
        const filesList = target.files as FileList;

        if (!filesList) {
            return;
        }

        const filesArray = Array.from(filesList);
        const duplicateArray = filter(
            (doc: File) => findIndex(propEq('name', `${doc.name}`))(scratchPadDocumentsForCurrentModal) >= 0,
            filesArray,
        );

        setDuplicateFiles(duplicateArray);

        const uniqueDocuments = difference(filesArray, duplicateArray);
        if (!uniqueDocuments.length) {
            setIsFileUploading(false);
        } else {
            setUniqueDocuments(uniqueDocuments);
        }

        for (const document of uniqueDocuments) {
            const fileToUpload = document;
            try {
                const putUrl = await getSignedPutUrlforScratchPadDocumentUpload.promise(client, {
                    simulation: simulation,
                    teamUUID: teamUUID,
                    file: {
                        name: fileToUpload.name,
                        type: fileToUpload.type,
                        size: fileToUpload.size,
                    },
                });
                if (putUrl && putUrl.data) {
                    const putCVRequestOptions: RequestInit = {
                        method: 'PUT',
                        headers: {
                            'content-type': fileToUpload.type,
                        },
                        body: fileToUpload,
                    };
                    await fetch(
                        putUrl.data.getSignedPutUrlforScratchPadDocumentUpload.signedPutUrl,
                        putCVRequestOptions,
                    );
                    const documentToSave = {
                        simulationUUID: simulation.uuid,
                        teamUUID: teamUUID,
                        name: fileToUpload.name,
                        path: putUrl.data.getSignedPutUrlforScratchPadDocumentUpload.path,
                        notes: undefined,
                        category: category,
                        currentEditor: undefined,
                    };
                    createScratchPadDocumentMutation({
                        variables: {
                            scratchPadDocument: documentToSave,
                        },
                    });
                }
            } catch (err) {
                if (err.message === 'File is not permitted as a scratchpad document') {
                    setFileUploadError(
                        // eslint-disable-next-line max-len
                        `File "${fileToUpload.name}", cannot be uploaded, as it is not permitted as a scratchpad document`,
                    );
                } else {
                    throw new Error('Failed to create a signed url for this document');
                }
            }
        }
    };

    const showOkButton = fileUploadError || duplicateFiles.length > 0;

    return (
        <>
            <Modal header="Add Document" onModalClose={onCloseModal}>
                <Display.VerticalWithSpacing>
                    {isFileUploading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {fileUploadError && (
                        <Display.VerticalWithSpacing gap={10}>
                            <MultiLineError>{fileUploadError}</MultiLineError>
                            <div>
                                This may be due to an incorrect file type, max file name/size being exceeded or special
                                character restrictions
                            </div>
                            <div>Please make any necessary changes before trying to upload the document.</div>
                        </Display.VerticalWithSpacing>
                    )}
                    {duplicateFiles.length > 0 && (
                        <Display.VerticalWithSpacing>
                            {duplicateFiles.length === 1 ? (
                                <Display.VerticalWithSpacing>
                                    <b>
                                        {/* eslint-disable-next-line max-len */}
                                        {`File '${duplicateFiles[0].name}' already exists in '${category}'`}
                                    </b>
                                    <p>And has not been uploaded</p>
                                    <p>Please choose a different file</p>
                                    <p>Non-duplicate files (if any) have been uploaded</p>
                                </Display.VerticalWithSpacing>
                            ) : (
                                duplicateFiles.length > 1 && (
                                    <Display.VerticalWithSpacing>
                                        <p>{`The following files already exist in '${category}':`}</p>
                                        {map((file) => {
                                            return (
                                                <div key={file.name}>
                                                    <b>{file.name}</b>
                                                </div>
                                            );
                                        }, duplicateFiles)}
                                        <p>and have not been uploaded.</p>
                                        <p>Non-duplicate files (if any) have been uploaded</p>
                                    </Display.VerticalWithSpacing>
                                )
                            )}
                        </Display.VerticalWithSpacing>
                    )}
                    {!isFileUploading && duplicateFiles.length === 0 && !fileUploadError && (
                        <Display.HorizontalWithSpacing>
                            <CardButton
                                onClick={(e) => {
                                    handleUploadFilesButtonClick(e);
                                }}
                                cardWidth={164}
                            >
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="upload" size={55} />
                                    <CardLinkHeading>Upload a document</CardLinkHeading>
                                    <input
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileUpload(e)}
                                        type="file"
                                        ref={hiddenFileInput}
                                        multiple
                                        accept={acceptedFileTypes.join(',')}
                                    />
                                </Display.VerticalWithSpacing>
                            </CardButton>
                            <CardButton
                                onClick={() => {
                                    onNewRichtextScratchpadDocument();
                                    onCloseModal();
                                }}
                                cardWidth={164}
                            >
                                <Display.VerticalWithSpacing horizontalAlign="center">
                                    <Icon icon="createNew" size={55} />
                                    <CardLinkHeading>Create a new document</CardLinkHeading>
                                </Display.VerticalWithSpacing>
                            </CardButton>
                        </Display.HorizontalWithSpacing>
                    )}
                    {!isFileUploading && duplicateFiles.length === 0 && !fileUploadError && (
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={onCloseModal}>Cancel</SecondaryButton>
                        </Display.HorizontalWithSpacing>
                    )}
                    {showOkButton && (
                        <Display.HorizontalWithSpacing horizontalAlign="end">
                            <SecondaryButton onClick={resetErrorsDuplicates}>Ok</SecondaryButton>
                        </Display.HorizontalWithSpacing>
                    )}
                </Display.VerticalWithSpacing>
            </Modal>
        </>
    );
};
