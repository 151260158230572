import React, { ChangeEvent, FC, useContext, useRef } from 'react';
import styled from 'styled-components/macro';
import { Icon } from '../../../../bits/Icon';
import { useApolloClient } from '@apollo/client';
import { Simulation, BriefingDocument } from '../../../../../../../common/types/Simulation';
// eslint-disable-next-line max-len
import { getSignedPutUrlForBriefingDocumentUpload } from '../../../../../graphql/simulations/getSignedPutUrlForBriefingDocumentUpload';
import { StaffUserContext } from '../../../../WithStaffUser';
import { SecondaryButton } from '../../../../bits/Buttons';

const UploadFilesButton = styled(SecondaryButton)`
    padding: 8px 15px;
`;

interface UploadDocumentsButtonProps {
    simulation: Simulation;
    onDocumentsUploaded: (documents: BriefingDocument[]) => void;
    onUploadStart: () => void;
}

const UploadDocumentsButton: FC<UploadDocumentsButtonProps> = ({ simulation, onDocumentsUploaded, onUploadStart }) => {
    const client = useApolloClient();
    const staffUser = useContext(StaffUserContext);
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleFileUpload = async (e: ChangeEvent) => {
        onUploadStart();
        e.preventDefault();
        const target = e.target as HTMLInputElement;
        const filesList = target.files;
        if (!filesList) {
            return;
        }
        const briefingDocuments: BriefingDocument[] = [];

        for (let i = 0; i < filesList.length; i++) {
            const fileToUpload: File = (target.files as FileList)[i];
            const putUrl = await getSignedPutUrlForBriefingDocumentUpload.promise(client, {
                simulation,
                file: {
                    name: fileToUpload.name,
                    type: fileToUpload.type,
                    size: undefined,
                },
            });
            if (!putUrl) {
                throw new Error('Failed to create a signed url for this document');
            } else if (!putUrl.data) {
                throw new Error('No data for this request');
            }
            const putCVRequestOptions: RequestInit = {
                method: 'PUT',
                headers: {
                    'content-type': fileToUpload.type,
                },
                body: fileToUpload,
            };
            await fetch(putUrl.data.getSignedPutUrlForBriefingDocumentUpload.signedPutUrl, putCVRequestOptions);
            const uploadedBriefingDocument = {
                name: fileToUpload.name,
                createdAt: new Date(),
                path: putUrl.data.getSignedPutUrlForBriefingDocumentUpload.path,
                createdBy: staffUser.uuid,
            };
            briefingDocuments.push(uploadedBriefingDocument);
        }

        onDocumentsUploaded(briefingDocuments);
    };

    const handleUploadFilesButtonClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    return (
        <UploadFilesButton onClick={() => handleUploadFilesButtonClick()}>
            <Icon icon="upload" size={14} />
            Upload Files
            <input
                style={{ display: 'none' }}
                onChange={(e) => handleFileUpload(e)}
                type="file"
                ref={hiddenFileInput}
                multiple
            />
        </UploadFilesButton>
    );
};

export default UploadDocumentsButton;
