import gql from 'graphql-tag';
import { GraphQlNewContentItem, GraphQlContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface CreateContentItemParams {
    contentItem: GraphQlNewContentItem;
}

interface CreateContentItemData {
    createContentItem: GraphQlContentItem;
}

const mutation = gql`
    mutation createContentItem($contentItem: NewContentItemInput!) {
        createContentItem(contentItem: $contentItem) {
            type
            uuid
            simulationUUID
            teamUUID
            parentUUID
            createdAt
            persona
            content {
                pageUUID
                interactions {
                    positive
                    negative
                    shares
                }
                data
            }
            prepreparedContentUUID
            createdBy {
                type
                uuid
            }
            deleted {
                at
                by {
                    type
                    uuid
                }
            }
        }
    }
`;

export const createContentItem = wrapMutation<CreateContentItemParams, CreateContentItemData>(mutation);
