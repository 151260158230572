import { always, cond, equals, toUpper } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { clearSimulationCodeCookie, getSimulationCodeFromCookie } from '../../../../utils/simulationCodeCookie';
import { SplashContainer, SplashHeroCard } from '../../../bits/SplashContainer';
import AuthContext from '../../../WithAuth/AuthContext';
import { CheckOpenLobbyForm } from './CheckOpenLobbyForm';
import { NewParticipantLobby } from './NewParticipantLobby';

enum JoinLobbyStep {
    CHECK_SIMULATION_OPEN = 'CheckSimulationOpen',
    GET_PARTICIPANT_DETAILS = 'GetParticipantDetails',
}

export const ParticipantJoinLobbyPage = (): React.ReactElement => {
    const history = useHistory();
    const location = useLocation<{ simulationCode?: string }>();
    const { currentAuthenticatedUser } = useContext(AuthContext);
    const [joinLobbyStep, setJoinLobbyStep] = useState<JoinLobbyStep>(JoinLobbyStep.CHECK_SIMULATION_OPEN);
    const [openLobbySimulationCode, setOpenLobbySimulationCode] = useState<string>('');

    useEffect(() => {
        if (location.state?.simulationCode) {
            setOpenLobbySimulationCode(toUpper(location.state?.simulationCode));
            history.replace(location.pathname, {});
        } else {
            const storedSimCode = getSimulationCodeFromCookie();
            if (storedSimCode) {
                clearSimulationCodeCookie();
                history.replace(`/simulation/${storedSimCode}`);
            }
        }
    }, [location.state?.simulationCode]);

    if (currentAuthenticatedUser) {
        return <Redirect to="/admin" />;
    }

    return (
        <SplashContainer>
            <SplashHeroCard>
                {cond<JoinLobbyStep, React.ReactNode>([
                    [
                        equals<JoinLobbyStep>(JoinLobbyStep.CHECK_SIMULATION_OPEN),
                        always(
                            <CheckOpenLobbyForm
                                initialSimulationCode={openLobbySimulationCode}
                                onSimulationLobbyOpen={(code) => {
                                    setOpenLobbySimulationCode(code);
                                    setJoinLobbyStep(JoinLobbyStep.GET_PARTICIPANT_DETAILS);
                                }}
                            />,
                        ),
                    ],
                    [
                        equals<JoinLobbyStep>(JoinLobbyStep.GET_PARTICIPANT_DETAILS),
                        always(
                            <NewParticipantLobby
                                simulationCode={openLobbySimulationCode}
                                onParticipantJoinedLobby={() => {
                                    history.push(`/simulation/${openLobbySimulationCode}`);
                                }}
                            />,
                        ),
                    ],
                ])(joinLobbyStep)}
            </SplashHeroCard>
        </SplashContainer>
    );
};
