import { useApolloClient } from '@apollo/client';
import { EditorContent } from '@tiptap/react';
import { BasicSimulation, Simulation } from 'polpeo-go-common/types/Simulation';
import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { grey2Colour, grey3Colour, primaryColour } from '../../../themes/colours';
import { body1, richtextStyling } from '../../../themes/cssSnippets';
import { IconButton } from '../../bits/Buttons/IconButton';
import { Display } from '../../bits/Display';
import { Spinner } from '../../bits/Spinner';
import usePolpeoRichtextEditor from '../../hooks/usePolpeoRichTextEditor';
import { uploadContentItemImage } from '../../templates/ContentItemImageDropZone';
import { HiddenFileInput } from '../HiddenFileInput';
import { Modal } from '../Modal';

export const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > div:nth-child(2) {
        height: 100%;
        flex: 1 1 auto;
        overflow: hidden;

        display: flex;
        flex-direction: column;
    }
    & .polpeo-richtext-input {
        width: 100%;
        padding: 20px 16px;
        resize: none;

        ${body1}
        max-width: 100%;
        overflow-wrap: anywhere;

        border: 1px solid ${grey2Colour};
        border-radius: 5px;

        &:focus {
            border: 1px solid ${primaryColour};
        }

        &[contenteditable='false'] {
            cursor: default;
            pointer-events: none;
            background-color: #f5f5f5;
        }

        ${richtextStyling}

        overflow-y: auto;

        flex: 1 1 200px;
    }
`;

interface RichtextInputProps {
    value: string;
    onChange: (richtext: string) => void;
    imageUploadSimulation: Simulation | BasicSimulation;
    disabled?: boolean;
}
const RichtextInput: FC<RichtextInputProps> = ({ value, onChange, imageUploadSimulation, disabled }) => {
    const client = useApolloClient();
    const [showImageUploadingModal, setShowImageUploadingModal] = useState(false);
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const editor = usePolpeoRichtextEditor({ content: value, onChange, editable: !disabled });

    return (
        <>
            <EditorContainer>
                <Display.HorizontalWithSpacing>
                    <div>
                        <IconButton
                            icon="formatBold"
                            fill={editor?.isActive('bold') ? primaryColour : grey3Colour}
                            onClick={() => editor?.chain().focus().toggleBold().run()}
                        />
                        <IconButton
                            icon="formatItalic"
                            onClick={() => editor?.chain().focus().toggleItalic().run()}
                            fill={editor?.isActive('italic') ? primaryColour : grey3Colour}
                        />
                        <IconButton
                            icon="formatUnderline"
                            onClick={() => editor?.chain().focus().toggleUnderline().run()}
                            fill={editor?.isActive('underline') ? primaryColour : grey3Colour}
                        />
                    </div>
                    <div>
                        <IconButton
                            icon="copyLink"
                            onClick={() => {
                                if (editor) {
                                    const { state } = editor;
                                    const { selection } = state;
                                    const href = state.doc.textBetween(selection.from, selection.to);
                                    editor
                                        ?.chain()
                                        .focus()
                                        .deleteSelection()
                                        .insertContent(`<custom-link href="${href}">${href}</custom-link>`)
                                        .run();
                                }
                            }}
                            fill={editor?.isActive('customLink') ? primaryColour : grey3Colour}
                        />
                        <IconButton
                            icon="video"
                            onClick={() => {
                                if (editor) {
                                    const { state } = editor;
                                    const { selection } = state;
                                    const href = state.doc.textBetween(selection.from, selection.to);
                                    editor
                                        ?.chain()
                                        .focus()
                                        .deleteSelection()
                                        .insertContent(`<embedded-video src="${href}"></embedded-video>`)
                                        .run();
                                }
                            }}
                            fill={grey3Colour}
                        />
                    </div>
                    <div>
                        <IconButton
                            icon="formatListBullet"
                            onClick={() => editor?.chain().focus().toggleBulletList().run()}
                            fill={editor?.isActive('bulletList') ? primaryColour : grey3Colour}
                        />
                        <IconButton
                            icon="formatListNumbered"
                            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                            fill={editor?.isActive('orderedList') ? primaryColour : grey3Colour}
                        />
                    </div>
                    <IconButton
                        icon="formatImage"
                        onClick={() => {
                            if (hiddenFileInput && hiddenFileInput.current) {
                                hiddenFileInput.current.click();
                            }
                        }}
                        fill={grey3Colour}
                    />
                    <HiddenFileInput
                        onChange={async (e) => {
                            const files = e.target.files;
                            if (files?.length === 1) {
                                setShowImageUploadingModal(true);
                                const imageFile = files[0];
                                const imagePath = await uploadContentItemImage(
                                    client,
                                    imageUploadSimulation,
                                    imageFile,
                                );
                                editor?.chain().focus().setImage({ src: imagePath }).run();
                                setShowImageUploadingModal(false);
                            }
                        }}
                        ref={hiddenFileInput}
                        accept={'image/*'}
                    />
                </Display.HorizontalWithSpacing>
                <EditorContent editor={editor} />
            </EditorContainer>
            {showImageUploadingModal && (
                <Modal header="Uploading Image">
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                </Modal>
            )}
        </>
    );
};

export default RichtextInput;
