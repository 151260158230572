import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { ContentSortOptions } from 'polpeo-go-common/types/PageTemplate';
import { ascend, cond, descend, head, sort, sortWith } from 'ramda';
import { getDescendentsForItem } from './getDescendentsForItem';

export const sortContentItems = (
    sortType: ContentSortOptions,
    itemsToSort: ContentItem[],
    allItems: ContentItem[],
): ContentItem[] => {
    const sortWithComparators = cond<ContentSortOptions, Array<(a: ContentItem, b: ContentItem) => number>>([
        [
            (sort) => sort === 'ItemCreatedAscending',
            () => [ascend((item: ContentItem) => item.createdAt.toISOString())],
        ],
        [
            (sort) => sort === 'ItemCreatedDescending',
            () => [descend((item: ContentItem) => item.createdAt.toISOString())],
        ],
        [
            (sort) => sort === 'DescendentCreatedAscending',
            () => [
                ascend((item: ContentItem) => {
                    const descendents = getDescendentsForItem(allItems || itemsToSort, item);
                    const sortedDescendents = sort(
                        descend((item: ContentItem) => item.createdAt.toISOString()),
                        descendents,
                    );

                    const firstDescendent = head(sortedDescendents);

                    return firstDescendent?.createdAt.toISOString() || item.createdAt.toISOString();
                }),
            ],
        ],
        [
            (sort) => sort === 'DescendentCreatedDescending',
            () => [
                descend((item: ContentItem) => {
                    const descendents = getDescendentsForItem(allItems || itemsToSort, item);
                    const sortedDescendents = sort(
                        descend((item: ContentItem) => item.createdAt.toISOString()),
                        descendents,
                    );

                    const firstDescendent = head(sortedDescendents);

                    return firstDescendent?.createdAt.toISOString() || item.createdAt.toISOString();
                }),
            ],
        ],
    ])(sortType);
    return sortWith(sortWithComparators, itemsToSort);
};
