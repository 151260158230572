import { isLeft } from 'fp-ts/lib/Either';
import React, { FC, useEffect, useState } from 'react';
import WithChildren from '../../utils/WithChildren';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import { Config, ConfigContext } from './config';

const getConfig = async (): Promise<Config> => {
    let configData: unknown;
    if (process.env.NODE_ENV === 'development') {
        configData = {
            graphQlEndpoint: process.env.REACT_APP_GRAPHQL_END_POINT || '',
            userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
            userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || '',
        };
    } else {
        configData = await (await fetch('/config.json')).json();
    }

    const maybeConfig = Config.decode(configData);
    if (isLeft(maybeConfig)) {
        throw new Error('Config was the wrong shape');
    }
    return maybeConfig.right;
};

export const WithConfig: FC<WithChildren> = ({ children }: WithChildren) => {
    const [config, setConfig] = useState<Config>();

    useEffect(() => {
        const fetchAndSetConfig = async () => {
            const newConfig = await getConfig();
            setConfig(newConfig);
        };

        fetchAndSetConfig();
    }, []);

    if (!config) {
        return <FullPageSplashLoadingScreen />;
    }

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
