import { Page } from 'polpeo-go-common/types/Page';
import { PageTemplate } from 'polpeo-go-common/types/PageTemplate';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { find } from 'ramda';
import { pageTemplates } from '../components/templates/PageTemplate/templates';

export function getContentItemFields<T extends { parentUUID?: string; content: { pageUUID: string } }>(
    page: Page,
    item: T,
): ContentItemField[];
export function getContentItemFields<T extends { parentUUID?: string; content: { pageUUID: string } }>(
    pages: Page[],
    item: T,
): ContentItemField[];
export function getContentItemFields<T extends { parentUUID?: string; content: { pageUUID: string } }>(
    template: PageTemplate,
    item: T,
): ContentItemField[];
export function getContentItemFields<T extends { parentUUID?: string; content: { pageUUID: string } }>(
    pageOrPagesOrTemplate: Page | Page[] | PageTemplate,
    item: T,
): ContentItemField[] {
    let template;
    if (Array.isArray(pageOrPagesOrTemplate)) {
        const page = find((p) => p.uuid === item.content.pageUUID, pageOrPagesOrTemplate);
        if (!page) {
            throw new Error('Page could not be found');
        }
        template = pageTemplates[page.templateUUID];
    } else if ('templateUUID' in pageOrPagesOrTemplate) {
        const page = pageOrPagesOrTemplate;
        template = pageTemplates[page.templateUUID];
    } else {
        template = pageOrPagesOrTemplate;
    }

    if (!template) {
        throw new Error('Template could not be found');
    }
    const isRootItem = !item.parentUUID;
    return isRootItem ? template.rootItemOptions.fields : template.childItemOptions.fields;
}
