import {
    difference,
    filter,
    includes,
    keys,
    length,
    map,
    reduce,
    replace,
    toLower,
    toPairs,
    trim,
    uniq,
    without,
} from 'ramda';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../themes/colours';
import { Display } from '../bits/Display';
import { TextInput } from '../bits/FormFields';
import { Icon } from '../bits/Icon';
import { IconSvg } from '../bits/Icon/IconSvg';
import { lozengeSnippet } from '../bits/Lozenge';

const MultiSearchContainer = styled.div.attrs(() => ({
    role: 'combobox',
}))`
    position: relative;

    ${Display.HorizontalWithSpacing} {
        padding-bottom: 10px;
    }
`;
const MultiSearchResultsMenu = styled.div.attrs(() => ({
    role: 'listbox',
    'aria-multiselectable': 'true',
}))`
    position: absolute;
    display: flex;
    margin-top: -4px;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 3px 6px 0 #00000029;
    z-index: 1;
`;
const MultiSearchResultsMenuItem = styled.div.attrs(() => ({
    role: 'option',
}))`
    padding: 15px;
    flex: 1 0 auto;
    text-align: left;
    cursor: pointer;

    :hover {
        background: ${grey1Colour};
    }
`;
const MultiSearchResultsMenuNoMatchItem = styled(MultiSearchResultsMenuItem)`
    cursor: inherit;

    :hover {
        background: none;
    }
`;

const SelectionLozenge = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e) => {
        if (e.target === e.currentTarget && (!type || type === 'button')) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))`
    ${lozengeSnippet}
    position: relative;

    padding-right: 30px;

    ${IconSvg} {
        position: absolute;
        top: 11px;
        right: 10px;
    }
`;

interface MultiSearchSelectionProps {
    options: Record<string, string>;
    value: string[];
    onChange: (newValues: string[]) => void;
    disableRemoveItem?: boolean;
    small?: boolean;
    createNewOptions?: boolean;
}
export const MultiSearchSelection: FC<MultiSearchSelectionProps> = ({
    options: originalOptions,
    value,
    onChange,
    disableRemoveItem,
    small,
    createNewOptions,
}: MultiSearchSelectionProps) => {
    const [searchValue, setSearchValue] = useState('');
    // Refs to elements to check against when closing the menu on mouse click
    const containerRef = useRef<HTMLDivElement>(null);
    const lozengeGridRef = useRef<HTMLDivElement>(null);

    const options = useMemo(
        () => {
            if (!createNewOptions) {
                return originalOptions;
            }
            const userCreatedOptions = difference(value, keys(originalOptions));
            return reduce(
                (acc, userCreatedOption) => ({ ...acc, [userCreatedOption]: userCreatedOption }),
                originalOptions,
                userCreatedOptions,
            );
        },
        createNewOptions ? [value, originalOptions] : [originalOptions],
    );

    const matchingOptions = !!searchValue
        ? filter(
              ([optionKey, optionValue]) =>
                  // If search term is not already selected
                  !includes(optionKey, value) &&
                  // If search term matches option display value
                  includes(toLower(searchValue), toLower(optionValue)),
              toPairs(originalOptions),
          )
        : [];

    useEffect(
        () => {
            const clearSearchValue = (e: MouseEvent) => {
                if (containerRef.current && lozengeGridRef) {
                    const targetIsContainer = containerRef.current === e.target;
                    const targetIsLozengeGrid = lozengeGridRef.current === e.target;
                    const targetIsOutsideContainer = !containerRef.current.contains(e.target as Node);
                    if (targetIsOutsideContainer || targetIsContainer || targetIsLozengeGrid) {
                        if (createNewOptions && searchValue) {
                            onChange(uniq([...value, trim(searchValue)]));
                        }
                        setSearchValue('');
                    }
                }
            };
            document.addEventListener('mousedown', clearSearchValue);
            return () => {
                document.removeEventListener('mousedown', clearSearchValue);
            };
        },
        createNewOptions ? [searchValue] : [],
    );

    useEffect(() => {
        setSearchValue(replace(',', '', searchValue));
    }, [searchValue]);

    return (
        <MultiSearchContainer aria-expanded={!!length(matchingOptions)} ref={containerRef}>
            {!!value.length && (
                <Display.HorizontalWithSpacing gap={10} ref={lozengeGridRef}>
                    {map(
                        (val) => (
                            <SelectionLozenge
                                key={val}
                                onClick={() => {
                                    !disableRemoveItem && onChange(without([val], value));
                                }}
                            >
                                {options[val]} {!disableRemoveItem && <Icon icon="delete" width={12} height={12} />}
                            </SelectionLozenge>
                        ),
                        value,
                    )}
                </Display.HorizontalWithSpacing>
            )}
            <TextInput
                aria-autocomplete="list"
                autoComplete="off"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                small={small}
                onKeyDown={(e) => {
                    if (!!searchValue && createNewOptions && (e.key === 'Enter' || e.key === ',')) {
                        onChange(uniq([...value, trim(searchValue)]));
                        setSearchValue('');
                    }
                }}
            />
            <MultiSearchResultsMenu>
                {!!length(matchingOptions) &&
                    map(
                        ([optionKey, optionValue]) => (
                            <MultiSearchResultsMenuItem
                                key={optionKey}
                                onClick={() => {
                                    onChange(uniq([...value, optionKey]));
                                    setSearchValue('');
                                }}
                            >
                                {optionValue}
                            </MultiSearchResultsMenuItem>
                        ),
                        matchingOptions,
                    )}
                {searchValue && !createNewOptions && !length(matchingOptions) && (
                    <MultiSearchResultsMenuNoMatchItem>No matches found</MultiSearchResultsMenuNoMatchItem>
                )}
            </MultiSearchResultsMenu>
        </MultiSearchContainer>
    );
};
