import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { StaffUser, UserRoleEnum } from 'polpeo-go-common/types/StaffUser';
import { filter, values } from 'ramda';

export function filterRoleplayers(staffUsers: Record<string, StaffUser | undefined>): StaffUser[];
export function filterRoleplayers(staffUsers: StaffUser[]): StaffUser[];
export function filterRoleplayers(staffUsers: Record<string, StaffUser | undefined> | StaffUser[]): StaffUser[] {
    const staffList = Array.isArray(staffUsers) ? staffUsers : values(staffUsers);
    return filter((staff) => !!staff && staff.role === UserRoleEnum.ROLEPLAYER, staffList) as StaffUser[];
}

export function filterAdministrators(staffUsers: Record<string, StaffUser | undefined>): StaffUser[];
export function filterAdministrators(staffUsers: StaffUser[]): StaffUser[];
export function filterAdministrators(staffUsers: Record<string, StaffUser | undefined> | StaffUser[]): StaffUser[] {
    const staffList = Array.isArray(staffUsers) ? staffUsers : values(staffUsers);
    return filter((staff) => !!staff && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staff), staffList) as StaffUser[];
}
