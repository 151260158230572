import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { endSimulation } from '../../../../graphql/simulations/endSimulation';
import { destructiveColour } from '../../../../themes/colours';
import { IconButton } from '../../../bits/Buttons/IconButton';
import { Display } from '../../../bits/Display';
import { Icon } from '../../../bits/Icon';
import { MenuButton, MenuItem } from '../../../patterns/MenuButton';
import { Modal } from '../../../patterns/Modal';
import { AdminInSimulationStateContext } from '../../../WithAdminInSimulationState';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../WithStaffUser';
import { StaffMomentsModal } from './StaffMomentsModal';
import { DestructiveButton, SecondaryButton } from '../../../bits/Buttons';
import { Body2 } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { NotificationContainer } from '../../NotificationContainer';
import { ReleasedTriggersModal } from './ReleasedTriggersModal';
import { userCanCreateMoments } from 'polpeo-go-common/permissions/userCanCreateMoments';

const LongMenuItem = styled(MenuItem)`
    min-width: 285px;
`;

const DestructiveLongMenuItem = styled(MenuItem)`
    min-width: 285px;
    color: ${destructiveColour};
`;

export const ManageSimulationMenuButton: FC = () => {
    const history = useHistory();
    const staffUser = useContext(StaffUserContext);
    const { setSimulation } = useContext(AdminStateContext);
    const {
        simulation: currentSimulation,
        setShowManageTeamsPage,
        notifications: { teamManagement: teamManagementNotification },
    } = useContext(AdminInSimulationStateContext);
    const closeMenuRef = useRef<(() => void) | undefined>();
    const [openMomentsModal, setOpenMomentsModal] = useState(false);
    const [openTriggersModal, setOpenTriggersModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);

    const [endSimulationMutation, { data: endSimulationData, loading: endSimulationLoading }] = endSimulation.hook();

    useEffect(() => {
        if (endSimulationData?.endSimulation.uuid === currentSimulation.uuid) {
            setSimulation(endSimulationData?.endSimulation);
        }
    }, [endSimulationData]);

    const canAdministerSimulation = userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    return (
        <>
            <MenuButton
                direction="right"
                closeMenuRef={closeMenuRef}
                buttonComponent={(props: unknown) => (
                    <NotificationContainer notification={teamManagementNotification}>
                        <IconButton icon="moreVert" {...props} />
                    </NotificationContainer>
                )}
            >
                {canAdministerSimulation && (
                    <LongMenuItem onClick={() => setShowManageTeamsPage(true)}>
                        <Display.HorizontalWithSpacing gap={10}>
                            <NotificationContainer notification={teamManagementNotification}>
                                <Icon icon="manageTeam" />
                            </NotificationContainer>
                            Team Management
                        </Display.HorizontalWithSpacing>
                    </LongMenuItem>
                )}
                <LongMenuItem
                    onClick={() => {
                        history.push(`/admin/simulation/${currentSimulation.uuid}/manage`);
                    }}
                >
                    <Display.HorizontalWithSpacing gap={10}>
                        <Icon icon="manageSimulation" />
                        Manage Simulation
                    </Display.HorizontalWithSpacing>
                </LongMenuItem>
                {userCanCreateMoments(currentSimulation, staffUser) && (
                    <LongMenuItem
                        onClick={() => {
                            setOpenMomentsModal(true);
                            if (closeMenuRef && closeMenuRef.current) {
                                closeMenuRef.current();
                            }
                        }}
                    >
                        <Display.HorizontalWithSpacing gap={10}>
                            <Icon icon="moment" />
                            Highlights Overview
                        </Display.HorizontalWithSpacing>
                    </LongMenuItem>
                )}
                <LongMenuItem
                    onClick={() => {
                        setOpenTriggersModal(true);
                        if (closeMenuRef && closeMenuRef.current) {
                            closeMenuRef.current();
                        }
                    }}
                >
                    <Display.HorizontalWithSpacing gap={10}>
                        <Icon icon="trigger" />
                        All Released Triggers
                    </Display.HorizontalWithSpacing>
                </LongMenuItem>
                {canAdministerSimulation && (
                    <DestructiveLongMenuItem onClick={() => setShowWarningModal(true)}>
                        <Display.HorizontalWithSpacing gap={10}>
                            <Icon icon="endSimulation" fill="currentColor" />
                            End Simulation
                        </Display.HorizontalWithSpacing>
                    </DestructiveLongMenuItem>
                )}
            </MenuButton>
            {showWarningModal && (
                <Modal
                    header={'End Simulation'}
                    onModalClose={() => {
                        if (!endSimulationLoading) {
                            setShowWarningModal(false);
                        }
                    }}
                    cardWidth={520}
                >
                    {endSimulationLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!endSimulationLoading && (
                        <Display.VerticalWithSpacing>
                            <p>
                                Are you sure that you want to end this simulation: <b>{currentSimulation.name}?</b>
                            </p>
                            <div>This will end the simulation for all users.</div>
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                            <Display.HorizontalWithSpacing horizontalAlign="end">
                                <SecondaryButton
                                    onClick={() => {
                                        if (!endSimulationLoading) {
                                            setShowWarningModal(false);
                                        }
                                    }}
                                >
                                    Cancel
                                </SecondaryButton>
                                <DestructiveButton
                                    onClick={() =>
                                        endSimulationMutation({ variables: { simulationUUID: currentSimulation.uuid } })
                                    }
                                >
                                    End Simulation
                                </DestructiveButton>
                            </Display.HorizontalWithSpacing>
                        </Display.VerticalWithSpacing>
                    )}
                </Modal>
            )}
            {openMomentsModal && <StaffMomentsModal onModalClose={() => setOpenMomentsModal(false)} />}
            {openTriggersModal && <ReleasedTriggersModal onModalClose={() => setOpenTriggersModal(false)} />}
        </>
    );
};
