import { useApolloClient } from '@apollo/client';
import { ContentItem, decodeContentItem } from 'polpeo-go-common/types/ContentItem';
import { ALL_TEAM_PARTICIPANTS_IDENTIFIER, EmailItem, decodeEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { Page } from 'polpeo-go-common/types/Page';
import { SimulationParticipantAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import {
    any,
    assoc,
    assocPath,
    find,
    findIndex,
    isEmpty,
    last,
    map,
    propEq,
    reduce,
    remove,
    update,
    values,
} from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { onWriteContentItem } from '../../graphql/contentItem';
import { onEmailItem } from '../../graphql/emailItem';
import { onMoment } from '../../graphql/moment';
import { PrepreparedItemReleasedMeta, onReleasePrepreparedItem } from '../../graphql/onReleasePrepreparedItem';
import { getPagesAndPageOrder } from '../../graphql/pages';
import { onWritePage } from '../../graphql/pages/onWritePage';
import { getParticipantsForSimulation, getTeamForParticipant } from '../../graphql/participantUsers';
import {
    getScratchPadDocuments,
    onDeleteScratchPadDocument,
    onWriteScratchPadDocument,
} from '../../graphql/scratchPad';
import {
    getOpenSimulationFromCode,
    getTeamAssignments,
    onEditTeamAssignments,
    onEndSimulation,
    onLockTeams,
    onStartSimulation,
} from '../../graphql/simulations';
import { onEditTeam } from '../../graphql/teams';
import { getTriggers, onReleaseTrigger } from '../../graphql/triggers';
import { TeamNotifications } from '../../utils/TeamNotifications';
import WithChildren from '../../utils/WithChildren';
import { decodeBasicSimulation } from '../../utils/decodeBasicSimulation';
import { decodeMoment } from '../../utils/decodeMoment';
import { decodeTrigger } from '../../utils/decodeTrigger';
import { fetchContentItems } from '../../utils/fetchContentItems';
import { fetchEmailItems } from '../../utils/fetchEmailItems';
import { fetchMoments } from '../../utils/fetchMoments';
import { getAncestorsForItem } from '../../utils/getAncestorsForContentItem';
import { getCreatorForItem } from '../../utils/getCreatorForItem';
import { getCurrentTrigger } from '../../utils/getCurrentTrigger';
import { setNotificationsForNewParticipantItems } from '../../utils/setNotificationsForNewParticipantItems';
import { ParticipantUserContext } from '../WithParticipantUser';
import { FullPageSplashLoadingScreen } from '../patterns/FullPageSplashLoadingScreen';
import { ParticipantStateContext } from './participantState';

export * from './participantState';

export const WithParticipantState: FC<WithChildren> = ({ children }: WithChildren) => {
    const client = useApolloClient();
    const locationParams = useParams<{ simulationCode: string }>();
    const { participantUser } = useContext(ParticipantUserContext);
    const [simulation, _setSimulation] = useState<BasicSimulation>();
    const [isSidebarCollapsed, _setIsSidebarCollapsed] = useState(false);
    const [participants, setParticipants] = useState<Record<string, ParticipantUser>>();
    const [participantTeamAssignments, setParticipantTeamAssignments] = useState<SimulationParticipantAssignments>();
    const [participantTeam, setParticipantTeam] = useState<Team>();
    const [triggers, _setTriggers] = useState<Trigger[]>();
    const [pages, _setPages] = useState<Page[]>();
    const [contentItems, setContentItems] = useState<Record<string, ContentItem>>();
    const [emailItems, setEmailItems] = useState<Record<string, EmailItem>>();
    const [moments, setMoments] = useState<Record<string, Moment>>();
    const [scratchPadDocuments, setScratchPadDocuments] = useState<ScratchPadDocument[]>();
    const [notifications, setNotifications] = useState<TeamNotifications>({ pages: {} });
    const [preliminaryParticipantTeam, setPreliminaryParticipantTeam] = useState<Team>();

    // Utils
    const setSimulation = (sim: BasicSimulation) => {
        if (sim.uuid === simulation?.uuid) {
            _setSimulation(decodeBasicSimulation(sim));
        }
    };
    const addContentItems = (newContentItems: ContentItem[] | Record<string, ContentItem>) => {
        const newContentItemsMap = reduce(
            (acc, contentData) =>
                contentData.teamUUID === participantTeam?.uuid
                    ? {
                          ...acc,
                          [contentData.uuid]:
                              // don't redecode if we think it's already decoded
                              typeof contentData.createdAt === 'string' ? decodeContentItem(contentData) : contentData,
                      }
                    : acc,
            {} as Record<string, ContentItem>,
            Array.isArray(newContentItems) ? newContentItems : values(newContentItems),
        );
        setContentItems((currentContentItems) => ({ ...currentContentItems, ...newContentItemsMap }));
    };
    const addEmailItems = (emailItems: EmailItem[] | Record<string, EmailItem>) => {
        const newEmailItems = reduce(
            (acc, emailData) =>
                emailData.teamUUID === participantTeam?.uuid
                    ? {
                          ...acc,
                          [emailData.uuid]:
                              // don't redecode if we think it's already decoded
                              typeof emailData.createdAt === 'string' ? decodeEmailItem(emailData) : emailData,
                      }
                    : acc,
            {} as Record<string, EmailItem>,
            Array.isArray(emailItems) ? emailItems : values(emailItems),
        );
        setEmailItems((currentEmailItems) => ({ ...currentEmailItems, ...newEmailItems }));
    };
    const currentTrigger = getCurrentTrigger(triggers || []);
    const getLinkUrlForContentItem = (item: ContentItem) => {
        const rootItem = contentItems ? last(getAncestorsForItem(contentItems, item)) : undefined;
        const simulationCode = simulation?.code;
        const participantUrl = `/simulation/${simulationCode}/${item.content.pageUUID}/${rootItem?.uuid || item.uuid}${
            rootItem ? '#' + item.uuid : ''
        }`;
        return participantUrl;
    };
    const fetchAndProcessItems = async (
        newerThan?: string | Date,
        options?: { dontFetchContentItems?: boolean; dontFetchEmailItems?: boolean },
    ) => {
        const currentContentItems = contentItems || {};
        const currentEmailItems = emailItems || {};
        if (!simulation) {
            return;
        }
        const newContentItems = options?.dontFetchContentItems
            ? {}
            : await fetchContentItems(client, simulation.uuid, newerThan);
        const newEmailItems = options?.dontFetchEmailItems
            ? {}
            : await fetchEmailItems(client, simulation.uuid, newerThan);

        if (!isEmpty(newContentItems)) {
            setContentItems({ ...currentContentItems, ...newContentItems });

            const newContentItemsList = values(newContentItems);
            const hasUnknownCreator = any(
                (newItem) => !getCreatorForItem(newItem, { participants: participants || {} }),
                newContentItemsList,
            );
            if (hasUnknownCreator) {
                refetchGetParticipantData();
            }
            setNotificationsForNewParticipantItems(currentContentItems, newContentItems, setNotifications, pages);
        }
        if (!isEmpty(newEmailItems)) {
            setEmailItems({ ...currentEmailItems, ...newEmailItems });
            setNotifications(assocPath(['emails'], true));
        }
    };

    // Getting Participant Team
    const { data: GetTeamForParticipantData, refetch: refetchGetTeamForParticipantData } = getTeamForParticipant.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation },
    );
    const haveAttemptedToGetParticipantTeam = !!GetTeamForParticipantData;
    useEffect(() => {
        const team = GetTeamForParticipantData?.getTeamForParticipant.team;
        const isTeamLockedOn = GetTeamForParticipantData?.getTeamForParticipant.lockedOn;
        if (team && isTeamLockedOn) {
            setParticipantTeam({ ...team, pageStats: JSON.parse(team.pageStats) });
            setPreliminaryParticipantTeam(undefined);
        } else if (team && !isTeamLockedOn) {
            setParticipantTeam(undefined);
            setPreliminaryParticipantTeam({ ...team, pageStats: JSON.parse(team.pageStats) });
        } else {
            setParticipantTeam(undefined);
            setPreliminaryParticipantTeam(undefined);
        }
    }, [GetTeamForParticipantData]);

    // Getting Participants
    const { data: GetParticipantsData, refetch: refetchGetParticipantData } = getParticipantsForSimulation.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation },
    );
    useEffect(() => {
        if (
            GetParticipantsData?.getParticipantsForSimulation &&
            haveAttemptedToGetParticipantTeam &&
            participantTeamAssignments
        ) {
            const incomingParticipantList = GetParticipantsData.getParticipantsForSimulation;
            const updatedParticipants = reduce<ParticipantUser, Record<string, ParticipantUser>>(
                (acc, participant) => {
                    const assignment = participantTeamAssignments?.assignments[participant.id];
                    if (assignment && assignment.teamUUID === participantTeam?.uuid) {
                        return { ...acc, [participant.id]: participant };
                    }
                    return acc;
                },
                participants || {},
                incomingParticipantList,
            );
            setParticipants(updatedParticipants);
        }
    }, [GetParticipantsData, haveAttemptedToGetParticipantTeam, participantTeamAssignments, participantTeam]);

    // Getting team assignments for Participants
    const { data: GetTeamAssignmentsData, refetch: refetchGetTeamAssignmentsData } = getTeamAssignments.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation },
    );
    useEffect(() => {
        if (GetTeamAssignmentsData?.getTeamAssignments) {
            const data = GetTeamAssignmentsData?.getTeamAssignments;
            if (data) {
                const parsedData = { ...data, assignments: JSON.parse(data.assignments) };
                setParticipantTeamAssignments(parsedData);
            } else {
                setParticipantTeamAssignments({ simulationUUID: simulation?.uuid || '', assignments: {} });
            }
        }
    }, [GetTeamAssignmentsData]);

    const { data: OnEditTeamAssignmentsData } = onEditTeamAssignments.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        const data = OnEditTeamAssignmentsData?.onEditTeamAssignments;
        if (data) {
            const parsedData = { ...data, assignments: JSON.parse(data.assignments) };
            setParticipantTeamAssignments(parsedData);
            refetchGetTeamForParticipantData();
        }
    }, [OnEditTeamAssignmentsData]);

    // Get Open Simulation
    const { data: GetOpenSimulationFromCodeData } = getOpenSimulationFromCode.hook({
        simulationCode: locationParams.simulationCode,
    });
    useEffect(() => {
        if (GetOpenSimulationFromCodeData?.getOpenSimulationFromCode) {
            _setSimulation(decodeBasicSimulation(GetOpenSimulationFromCodeData.getOpenSimulationFromCode));
        } else {
            _setSimulation(undefined);
        }
    }, [GetOpenSimulationFromCodeData]);

    // When participant teams are locked
    const { data: onLockTeamsData } = onLockTeams.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        const data = onLockTeamsData?.onLockTeams;
        if (data) {
            refetchGetParticipantData();
            refetchGetTeamAssignmentsData();
            refetchGetTeamForParticipantData();
        }
    }, [onLockTeamsData]);

    // When simulation starts
    const { data: OnStartSimulationData } = onStartSimulation.hook({
        variables: { uuid: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (OnStartSimulationData && simulation) {
            refetchGetTeamAssignmentsData();
            refetchGetTeamForParticipantData();
            const { startedAt } = OnStartSimulationData.onStartSimulation;
            setSimulation({ ...simulation, startedAt });
        }
    }, [OnStartSimulationData]);

    // When simulation ends
    const { data: onEndSimulationData } = onEndSimulation.hook({
        variables: { uuid: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (onEndSimulationData && simulation) {
            const { completedAt } = onEndSimulationData.onEndSimulation;
            setSimulation({ ...simulation, completedAt });
        }
    }, [onEndSimulationData]);

    // When trigger released
    const { data: OnReleaseTriggerData } = onReleaseTrigger.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && participantTeam && OnReleaseTriggerData?.onReleaseTrigger) {
            const releasedTrigger = OnReleaseTriggerData.onReleaseTrigger.trigger;
            const processReleasedTriggerAndContentItems = async () => {
                await fetchAndProcessItems(releasedTrigger.releasedAt);
                setParticipantTeam({
                    ...participantTeam,
                    overview: {
                        title: releasedTrigger.overviewChanges?.title || participantTeam?.overview?.title,
                        description:
                            releasedTrigger.overviewChanges?.description || participantTeam?.overview?.description,
                        headerImage:
                            releasedTrigger.overviewChanges?.headerImage || participantTeam?.overview?.headerImage,
                    },
                });

                _setTriggers([
                    ...(triggers || []),
                    decodeTrigger({
                        ...releasedTrigger,
                        pageDressingChanges: JSON.parse(releasedTrigger.pageDressingChanges),
                    }),
                ]);
            };

            processReleasedTriggerAndContentItems();
        }
    }, [OnReleaseTriggerData]);

    // When preprepared item released
    const { data: OnReleasePrepreparedItemData } = onReleasePrepreparedItem.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && participantTeam && OnReleasePrepreparedItemData?.onReleasePrepreparedItem) {
            // Don't just look at the item we released because parents of the item might have been released before it!
            const earliestReleasedItem = reduce(
                (acc, item) => {
                    const itemReleasedAt = item.released?.at || '0';
                    const accReleasedAt = acc.released?.at || '0';
                    return itemReleasedAt >= accReleasedAt ? acc : item;
                },
                OnReleasePrepreparedItemData.onReleasePrepreparedItem.item as PrepreparedItemReleasedMeta,
                OnReleasePrepreparedItemData.onReleasePrepreparedItem.items,
            );
            const processReleasedContentItems = async () => {
                const fetchContentItems = earliestReleasedItem.type === 'PAGE_CONTENT';
                const fetchEmailItems = earliestReleasedItem.type === 'EMAIL';

                await fetchAndProcessItems(earliestReleasedItem.released?.at, {
                    dontFetchContentItems: !fetchContentItems,
                    dontFetchEmailItems: !fetchEmailItems,
                });
            };

            processReleasedContentItems();
        }
    }, [OnReleasePrepreparedItemData]);

    // When content item created
    const { data: OnWriteContentItemData } = onWriteContentItem.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && OnWriteContentItemData?.onWriteContentItem) {
            const data = OnWriteContentItemData.onWriteContentItem;
            const incomingContentItem = decodeContentItem({
                ...data,
                content: { ...data.content, data: JSON.parse(data.content.data) },
            });
            if (incomingContentItem.teamUUID === participantTeam?.uuid) {
                addContentItems([incomingContentItem]);
                setNotificationsForNewParticipantItems(
                    contentItems,
                    { [incomingContentItem.uuid]: incomingContentItem },
                    setNotifications,
                    pages,
                );
            }
        }
    }, [OnWriteContentItemData]);

    // When email item created
    const { data: OnEmailItemData } = onEmailItem.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && OnEmailItemData?.onEmailItem) {
            const data = OnEmailItemData.onEmailItem;
            const incomingEmailItem = decodeEmailItem(data);
            addEmailItems([incomingEmailItem]);
            if (incomingEmailItem.teamUUID === participantTeam?.uuid) {
                const isParticipantUserIntendedRecipient = find(
                    (recipient) => recipient === ALL_TEAM_PARTICIPANTS_IDENTIFIER || participantUser.id === recipient,
                    incomingEmailItem.content.recipients,
                );
                if (isParticipantUserIntendedRecipient) {
                    setNotifications(assocPath(['emails'], true));
                }
            }
        }
    }, [OnEmailItemData]);

    // When moment created
    const { data: OnMomentData } = onMoment.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && OnMomentData?.onMoment) {
            const data = OnMomentData.onMoment;
            const incomingMoment = decodeMoment({
                ...data,
                contentItem: {
                    ...data.contentItem,
                    content: {
                        ...data.contentItem.content,
                        data: JSON.parse(data.contentItem.content.data),
                    },
                },
            });
            if (incomingMoment.contentItem.teamUUID === participantTeam?.uuid) {
                setMoments(assoc(incomingMoment.uuid, incomingMoment));
            }
        }
    }, [OnMomentData]);

    // When scratchpad document created/edited
    const { data: onWriteScratchPadDocumentData } = onWriteScratchPadDocument.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (onWriteScratchPadDocumentData?.onWriteScratchPadDocument) {
            const scratchPadDocumentData = onWriteScratchPadDocumentData.onWriteScratchPadDocument;
            if (scratchPadDocumentData.teamUUID === participantTeam?.uuid) {
                const existingDocIndex = findIndex(
                    propEq('uuid', scratchPadDocumentData.uuid),
                    scratchPadDocuments || [],
                );
                if (existingDocIndex >= 0) {
                    const newArr = update(existingDocIndex, scratchPadDocumentData, scratchPadDocuments || []);
                    setScratchPadDocuments(newArr);
                } else {
                    setScratchPadDocuments([...(scratchPadDocuments || []), scratchPadDocumentData]);
                }
                setNotifications(assocPath(['scratchpad'], true));
            }
        }
    }, [onWriteScratchPadDocumentData]);

    // When scratchpadDocument deleted
    const { data: onDeleteScratchPadDocumentData } = onDeleteScratchPadDocument.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (onDeleteScratchPadDocumentData?.onDeleteScratchPadDocument) {
            const scratchPadDocumentData = onDeleteScratchPadDocumentData.onDeleteScratchPadDocument;
            if (scratchPadDocumentData.teamUUID === participantTeam?.uuid && scratchPadDocuments) {
                const existingDocIndex = findIndex(propEq('uuid', scratchPadDocumentData.uuid), scratchPadDocuments);
                if (existingDocIndex >= 0) {
                    const newArr = remove(existingDocIndex, 1, scratchPadDocuments);
                    setScratchPadDocuments(newArr);
                    setNotifications(assocPath(['scratchpad'], true));
                }
            }
        }
    }, [onDeleteScratchPadDocumentData]);

    // When team overview updated
    const { data: OnEditTeamData } = onEditTeam.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (simulation && OnEditTeamData?.onEditTeam) {
            const incomingTeam = OnEditTeamData.onEditTeam;
            if (incomingTeam.uuid === participantTeam?.uuid) {
                setParticipantTeam({ ...incomingTeam, pageStats: JSON.parse(incomingTeam.pageStats) });
            }
        }
    }, [OnEditTeamData]);

    // Get initial triggers, pages and scratchpad documents
    const { data: GetTriggersData } = getTriggers.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation || !participantTeam },
    );
    const { data: GetScratchPadDocumentsData } = getScratchPadDocuments.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation || !participantTeam?.uuid },
    );
    useEffect(() => {
        if (GetTriggersData?.getTriggers && GetScratchPadDocumentsData?.getScratchPadDocuments) {
            const fetchedTriggers = map(
                (trigger) =>
                    decodeTrigger({ ...trigger, pageDressingChanges: JSON.parse(trigger.pageDressingChanges) }),
                GetTriggersData.getTriggers,
            );
            _setTriggers(fetchedTriggers);

            const fechedScratchpadDocs = GetScratchPadDocumentsData.getScratchPadDocuments;
            setScratchPadDocuments(fechedScratchpadDocs);
        }
    }, [GetTriggersData, GetScratchPadDocumentsData]);

    // Get initial pages
    const { data: GetPagesAndPageOrderData, refetch: refetchPagesAndPageOrder } = getPagesAndPageOrder.hook(
        { simulationUUID: simulation?.uuid || '' },
        { skip: !simulation || !participantTeam },
    );
    useEffect(() => {
        if (GetPagesAndPageOrderData?.getPages && GetPagesAndPageOrderData.getPageOrder) {
            const fetchedPages = map((pageUUID) => {
                const page = find((p) => p.uuid === pageUUID, GetPagesAndPageOrderData.getPages);
                if (!page) {
                    throw new Error(`Page could not be found for uuid '${pageUUID}'`);
                }
                return { ...page, stats: JSON.parse(page.stats) };
            }, GetPagesAndPageOrderData.getPageOrder);
            _setPages(fetchedPages);
        }
    }, [GetPagesAndPageOrderData]);

    // when new page created
    const { data: OnWritePageData } = onWritePage.hook({
        variables: { simulationUUID: simulation?.uuid || '' },
        skip: !simulation,
    });
    useEffect(() => {
        if (OnWritePageData?.onWritePage) {
            refetchPagesAndPageOrder();
        }
    }, [OnWritePageData]);

    // Get initial content items, email items and moments
    // There might be alot of these so we use a seperate function to continuously
    // loop over results until we reach the end of what the backend will give us
    useEffect(() => {
        if (simulation && participantTeam) {
            const getAllInitialContentItems = async () => {
                const contentItems = await fetchContentItems(client, simulation.uuid);
                setContentItems(contentItems);
            };
            getAllInitialContentItems();

            const getAllInitialEmailItems = async () => {
                const emailItems = await fetchEmailItems(client, simulation.uuid);
                setEmailItems(emailItems);
            };
            getAllInitialEmailItems();

            const getAllInitialMoments = async () => {
                const moments = await fetchMoments(client, simulation.uuid);
                setMoments(moments);
            };
            getAllInitialMoments();
        }
    }, [simulation, participantTeam]);

    if (!simulation || !haveAttemptedToGetParticipantTeam || !participants) {
        return <FullPageSplashLoadingScreen />;
    }

    return (
        <ParticipantStateContext.Provider
            value={{
                simulation,
                setSimulation,
                sidebar: { collapsed: isSidebarCollapsed, toggle: () => _setIsSidebarCollapsed(!isSidebarCollapsed) },
                participantTeam,
                preliminaryParticipantTeam,
                participants,
                triggers,
                currentTrigger,
                pages,
                contentItems,
                addContentItems,
                emailItems,
                addEmailItems,
                getLinkUrlForContentItem,
                moments,
                scratchPadDocuments,
                setScratchPadDocuments,

                notifications,
                setNotifications,
            }}
        >
            {children}
        </ParticipantStateContext.Provider>
    );
};
