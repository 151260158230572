import { reduceWhile, values } from 'ramda';

export function getChildrenForItem<T extends { uuid: string; parentUUID?: string }>(
    items: T[] | Record<string, T | undefined>,
    item: T,
    max?: number,
): T[] {
    const itemList = Array.isArray(items) ? items : (values(items) as T[]);
    return reduceWhile(
        (acc) => (max ? acc.length <= max : true),
        (acc, maybeChildItem) => (maybeChildItem.parentUUID === item.uuid ? [...acc, maybeChildItem] : acc),
        [] as T[],
        itemList,
    );
}
