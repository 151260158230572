import { DateTime } from 'luxon';
import { find, includes, last, split } from 'ramda';

const SIMULATION_CODE_COOKIE_KEY = 'polpeo_simulation_participant_code';

export const setSimulationCodeCookie = (code: string): void => {
    document.cookie = `${SIMULATION_CODE_COOKIE_KEY}=${code};path=/;expires=${DateTime.now()
        .plus({ days: 1 })
        .toJSDate()
        .toUTCString()}`;
};

export const getSimulationCodeFromCookie = (): string => {
    const cookies = document.cookie;
    if (includes(SIMULATION_CODE_COOKIE_KEY, cookies)) {
        const codeCookieKeyPairValue = find(
            (cookieKeyPairValue) => includes(SIMULATION_CODE_COOKIE_KEY, cookieKeyPairValue),
            split(';', cookies),
        );
        const code = last(split('=', codeCookieKeyPairValue || ''));
        return code || '';
    }
    return '';
};

export const clearSimulationCodeCookie = (): void => {
    document.cookie = `${SIMULATION_CODE_COOKIE_KEY}=;path=/;`;
};
