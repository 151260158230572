import { ContentItem, decodeContentItem } from 'polpeo-go-common/types/ContentItem';
import { find, values } from 'ramda';
import React, { FC, useContext, useEffect } from 'react';
import { deleteContentItem } from '../../../graphql/contentItem';
import { Body2 } from '../../bits/Headers';
import { DeleteModal } from '../../templates/DeleteModal';
import { EmbeddedContentItem } from '../../templates/PageTemplate/EmbeddedContentItem';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { AdminInSimulationStateContext } from '../../WithAdminInSimulationState';
import { ParticipantStateContext } from '../../WithParticipantState';

interface DeleteContentItemModalProps {
    item: ContentItem;
    onCloseModal: () => void;
}
export const DeleteContentItemModal: FC<DeleteContentItemModalProps> = ({ item, onCloseModal }) => {
    const adminState = useContext(AdminInSimulationStateContext);
    const participantState = useContext(ParticipantStateContext);

    const pages = participantState.pages || values(adminState?.simulationContent?.pages || {}) || [];

    const [deleteContentItemMutation, { data: deleteContentItemData, loading: deleteContentItemsLoading }] =
        deleteContentItem.hook();
    useEffect(() => {
        if (deleteContentItemData?.deleteContentItem) {
            const addContentItems = adminState.addContentItems || participantState.addContentItems;

            if (!addContentItems) {
                throw new Error(`Could not fund function addContentItems for staff or participant state`);
            }
            addContentItems([
                decodeContentItem({
                    ...deleteContentItemData?.deleteContentItem,
                    content: {
                        ...deleteContentItemData?.deleteContentItem.content,
                        data: JSON.parse(deleteContentItemData?.deleteContentItem.content.data),
                    },
                }),
            ]);
            onCloseModal();
        }
    }, [deleteContentItemData]);

    const page = find((p) => p.uuid === item.content.pageUUID, pages);
    if (!page) {
        throw new Error(`Page could not be found to delete content item '${item.uuid}'`);
    }
    const template = pageTemplates[page.templateUUID];

    return (
        <DeleteModal
            header={
                !item.parentUUID
                    ? `Delete ${template.rootItemOptions.name}`
                    : `Delete ${template.childItemOptions.name}`
            }
            onModalClose={onCloseModal}
            confirmationText={
                <>
                    Are you sure you want to delete this item?
                    <EmbeddedContentItem item={item} />
                    <Body2 destructive>
                        <b>This action cannot be undone.</b>
                    </Body2>
                </>
            }
            onConfirmDelete={() =>
                deleteContentItemMutation({
                    variables: {
                        contentItem: {
                            ...item,
                            createdAt: item.createdAt.toISOString(),
                            content: {
                                ...item.content,
                                data: JSON.stringify(item.content.data),
                            },
                        },
                    },
                })
            }
            showSpinner={deleteContentItemsLoading}
        />
    );
};
