import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Page } from 'polpeo-go-common/types/Page';
import { BaseThemingOptions } from 'polpeo-go-common/types/PageTemplate/BaseThemingOptions';
import { Team } from 'polpeo-go-common/types/Team';
import { map, toPairs } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { Display } from '../../../../bits/Display';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { PageStats as PageStatsContainer } from '../PageDressing';
import { AddPageStatButton } from './AddPageStatButton';
import { EditPageStatsButton } from './EditPageStatsButton';

interface PageStatsProps {
    page: Page;
    team: Team;
    theme?: BaseThemingOptions;
}
export const PageStats: FC<PageStatsProps> = ({ page, team, theme }) => {
    const staffUser = useContext(StaffUserContext);
    const { simulation } = useContext(AdminInSimulationStateContext);

    const canEditPageStats =
        !!staffUser && !simulation.completedAt && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    const stats = useMemo(() => toPairs(team.pageStats?.[page.uuid] || {}), [page, team]);

    if (!stats.length && !canEditPageStats) {
        return <></>;
    }
    return (
        <PageStatsContainer theme={theme}>
            <Display.VerticalWithSpacing gap={5}>
                {map(
                    ([key, value]) => (
                        <Display.HorizontalWithSpacing gap={10}>
                            <b>{key}</b> {value}
                        </Display.HorizontalWithSpacing>
                    ),
                    stats,
                )}
                {canEditPageStats && team && (
                    <Display.HorizontalWithSpacing>
                        <AddPageStatButton page={page} team={team} />
                        <EditPageStatsButton page={page} team={team} />
                    </Display.HorizontalWithSpacing>
                )}
            </Display.VerticalWithSpacing>
        </PageStatsContainer>
    );
};
