import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { Team } from 'polpeo-go-common/types/Team';
import { ascend, filter, keys, map, reduce, sort, values } from 'ramda';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { formLabel } from '../../../../../themes/cssSnippets';
import { makeStaffLastLoginLabel } from '../../../../../utils/makeStaffLastLoginLabel';
import { SecondaryButton } from '../../../../bits/Buttons';
import { CardSection } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { H1Heading, H3Heading } from '../../../../bits/Headers';
import { Card } from '../../../../patterns/Card';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { EditSimulationAdministratorsButton } from './EditSimulationAdministratorsButton';

const SectionHeader = styled.span`
    ${formLabel}
    text-transform: uppercase;
`;

export const SimulationOverviewCard: FC = () => {
    const history = useHistory();
    const staffUser = useContext(StaffUserContext);
    const { staffUsers } = useContext(AdminStateContext);
    const { currentSimulation, currentSimulationTeams } = useContext(AdminManageSimulationContext);

    const userCanEditSimulationAdministrators =
        !currentSimulation.completedAt && userCan(PermissionKey.EDIT_SIMULATION_STAFF, staffUser);
    const onEditTeamsPage = history.location.pathname === `/admin/simulation/${currentSimulation.uuid}/manage/teams`;

    const sortedAdminUsers = sort(
        ascend((administratorUUID) => staffUsers[administratorUUID]?.name),
        currentSimulation.administratorUUIDs,
    );

    const sortedTeams = sort(
        ascend((team: Team) => team.name),
        values(currentSimulationTeams),
    );

    const roleplayerTeamMap = reduce(
        (acc: { [index: string]: Team[] }, userUUID: string) => {
            const filteredUUIDs = filter((team) => {
                const { roleplayerUUIDs } = team;
                return roleplayerUUIDs.includes(userUUID);
            }, sortedTeams);

            if (filteredUUIDs.length > 0) {
                acc[userUUID] = filteredUUIDs;
            }
            return acc;
        },
        {},
        keys(staffUsers),
    );

    const sortedPlayerTeamMapKeys = sort(
        ascend((roleplayerUUID) => staffUsers[roleplayerUUID]?.name),
        keys(roleplayerTeamMap),
    );

    return (
        <Card header="Simulation Details">
            <CardSection>
                <SectionHeader>Simulation Code</SectionHeader>
                <H1Heading>{currentSimulation.code}</H1Heading>
            </CardSection>
            <CardSection>
                <Display.VerticalWithSpacing gap={12} horizontalAlign="start">
                    <SectionHeader>Simulation Administrators</SectionHeader>
                    {map((administratorUUID) => {
                        const administrator = staffUsers[administratorUUID];
                        if (!administrator) {
                            return <></>;
                        }
                        return <H3Heading key={administratorUUID}>{administrator.name}</H3Heading>;
                    }, sortedAdminUsers)}
                    {userCanEditSimulationAdministrators && <EditSimulationAdministratorsButton />}
                </Display.VerticalWithSpacing>
            </CardSection>
            <CardSection>
                <Display.VerticalWithSpacing gap={12} horizontalAlign="start">
                    <SectionHeader>Roleplayers</SectionHeader>
                    {map((roleplayerUUID) => {
                        const roleplayer = staffUsers[roleplayerUUID];
                        if (!roleplayer) {
                            return <></>;
                        }
                        return (
                            <div key={roleplayerUUID}>
                                <Display.HorizontalWithSpacing gap={12}>
                                    <H3Heading>{roleplayer.name}</H3Heading>
                                    <Display.VerticalWithSpacing gap={10}>
                                        {map((team) => {
                                            return <div key={team.uuid}>{team.name}</div>;
                                        }, roleplayerTeamMap[roleplayerUUID])}
                                    </Display.VerticalWithSpacing>
                                </Display.HorizontalWithSpacing>
                                <Display.HorizontalWithSpacing gap={12}>
                                    <span>Last login:</span>
                                    <span>
                                        {roleplayer.lastLogin ? makeStaffLastLoginLabel(roleplayer.lastLogin) : ''}
                                    </span>
                                </Display.HorizontalWithSpacing>
                            </div>
                        );
                    }, sortedPlayerTeamMapKeys)}
                    {!onEditTeamsPage && userCanEditSimulationAdministrators && (
                        <SecondaryButton
                            onClick={() => history.push(`/admin/simulation/${currentSimulation.uuid}/manage/teams`)}
                        >
                            Edit Team Assignments
                        </SecondaryButton>
                    )}
                </Display.VerticalWithSpacing>
            </CardSection>
        </Card>
    );
};
