import { ContentItem, decodeContentItem } from 'polpeo-go-common/types/ContentItem';
import { all, equals, find, mergeDeepRight, values } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { editContentItem } from '../../../graphql/contentItem';
import { checkRequiredFields } from '../../../utils/checkRequiredFields';
import { getContentItemRequiredFields } from '../../../utils/getContentItemRequiredFields';
import { ParticipantStateContext } from '../../WithParticipantState';
import { PrimaryButton, SecondaryButton } from '../../bits/Buttons';
import { Display } from '../../bits/Display';
import { H2Heading } from '../../bits/Headers';
import { Spinner } from '../../bits/Spinner';
import { useUnsavedWorkPrompt } from '../../hooks/useUnsavedWorkPrompt';
import { pageTemplates } from '../../templates/PageTemplate/templates';
import { Modal } from '../Modal';
import { ParticipantContentItemForm } from './ParticipantContentItemForm';

interface ParticipantEditContentItemModalProps {
    item: ContentItem;
    onCloseModal: () => void;
}

export const ParticipantEditContentItemModal: FC<ParticipantEditContentItemModalProps> = ({ item, onCloseModal }) => {
    const [currentContentItem, setCurrentContentItem] = useState<ContentItem>(item);
    const { addContentItems, pages } = useContext(ParticipantStateContext);
    const [editContentItemMutation, { data: editContentItemData, loading: editContentItemsLoading }] =
        editContentItem.hook();

    useEffect(() => {
        if (editContentItemData?.editContentItem) {
            addContentItems([
                decodeContentItem({
                    ...editContentItemData?.editContentItem,
                    content: {
                        ...editContentItemData?.editContentItem.content,
                        data: JSON.parse(editContentItemData?.editContentItem.content.data),
                    },
                }),
            ]);
            onCloseModal();
        }
    }, [editContentItemData]);

    const pageUUID = item.content.pageUUID;
    const page = find((page) => page.uuid === pageUUID, pages || []);
    const template = page && pageTemplates[page.templateUUID];

    const requiredFields = template ? getContentItemRequiredFields(template, currentContentItem) : [];
    const isAllContentDataEmpty = all((value) => !value, values(currentContentItem.content.data));

    const canSave =
        (item.createdBy.type === 'PARTICIPANT' && !currentContentItem.persona && !isAllContentDataEmpty) ||
        (item.createdBy.type !== 'PARTICIPANT' &&
            currentContentItem.persona &&
            checkRequiredFields(requiredFields, currentContentItem).valid &&
            !isAllContentDataEmpty);

    const hasUnsavedChanges = !equals(currentContentItem, item);
    const { wrappedFunc: onModalCloseUnsavedPrompt, unsavedPromptComponent: UnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        onCloseModal,
    );

    return (
        <>
            <UnsavedPrompt />
            <Modal onModalClose={onModalCloseUnsavedPrompt} cardWidth={800}>
                <H2Heading>{`Edit ${
                    !item.parentUUID ? template?.rootItemOptions.name : template?.childItemOptions.name
                }`}</H2Heading>
                {editContentItemsLoading && (
                    <Display.HorizontalCenterVerticalCenter>
                        <Spinner />
                    </Display.HorizontalCenterVerticalCenter>
                )}
                {!editContentItemsLoading && (
                    <ParticipantContentItemForm
                        currentContentItem={currentContentItem}
                        onChange={(fields) => setCurrentContentItem((oldValues) => mergeDeepRight(oldValues, fields))}
                        footer={
                            <Display.HorizontalWithSpacing horizontalAlign="end" verticalCenter>
                                <SecondaryButton onClick={onModalCloseUnsavedPrompt}>Cancel</SecondaryButton>
                                <PrimaryButton
                                    disabled={!canSave}
                                    onClick={() => {
                                        editContentItemMutation({
                                            variables: {
                                                contentItem: {
                                                    ...currentContentItem,
                                                    createdAt: item.createdAt.toISOString(),
                                                    content: {
                                                        ...currentContentItem.content,
                                                        data: JSON.stringify(currentContentItem.content.data),
                                                    },
                                                },
                                            },
                                        });
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        }
                    />
                )}
            </Modal>
        </>
    );
};
