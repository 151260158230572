import { Team } from 'polpeo-go-common/types/Team';
import { assocPath, filter } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FloatingActionButton } from '../../../../patterns/FAB/FloatingActionButton';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { ScratchpadFABMenu } from '../../ScratchPad/ScratchpadFABMenu';
import { useFabButtonPositioner } from '../../../../hooks/useFabButtonPositioner';

interface TeamScratchpadFABProps {
    team: Team;
    disableManageDocuments?: boolean;
}
export const TeamScratchpadFAB: FC<TeamScratchpadFABProps> = ({ team, disableManageDocuments }) => {
    const { scratchPadDocuments = [], notifications, setNotifications } = useContext(AdminInSimulationStateContext);

    const [openMenu, setOpenMenu] = useState(false);
    const scratchpadNotification = notifications[team.uuid]?.scratchpad;

    const documentsForTeam = useMemo(
        () => filter((doc) => doc.teamUUID === team.uuid, scratchPadDocuments),
        [team, scratchPadDocuments],
    );

    const { rightPosition, bottomPosition, fabButtonRef } = useFabButtonPositioner();

    useEffect(() => {
        if (scratchpadNotification && openMenu) {
            setNotifications(assocPath([team.uuid, 'scratchpad'], false));
        }
    }, [scratchpadNotification, openMenu]);

    return (
        <>
            <FloatingActionButton
                ref={fabButtonRef}
                icon="scratchPad"
                onClick={() => setOpenMenu(!openMenu)}
                notification={scratchpadNotification}
            />
            {openMenu && (
                <ScratchpadFABMenu
                    onClose={() => setOpenMenu(false)}
                    documents={documentsForTeam}
                    menuPosition={{ right: rightPosition }}
                    tailPosition={{ bottom: bottomPosition }}
                    disableManageDocuments={disableManageDocuments}
                />
            )}
        </>
    );
};
