import { Team } from 'polpeo-go-common/types/Team';
import React, { FC, useContext, useEffect, useState } from 'react';
import { deleteTeam } from '../../../../../graphql/teams/deleteTeam';
import { destructiveColour } from '../../../../../themes/colours';
import WithClassname from '../../../../../utils/WithClassname';
import { DestructiveButton, SecondaryButton } from '../../../../bits/Buttons';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Display } from '../../../../bits/Display';
import { Spinner } from '../../../../bits/Spinner';
import { Modal } from '../../../../patterns/Modal';
import { AdminStateContext } from '../../../../WithAdminState/adminState';

interface DeleteSimulationTeamButtonProps extends WithClassname {
    team: Team;
}
export const DeleteSimulationTeamButton: FC<DeleteSimulationTeamButtonProps> = ({
    className,
    team,
}: DeleteSimulationTeamButtonProps) => {
    const { removeTeam } = useContext(AdminStateContext);
    const [showModal, setShowModal] = useState(false);
    const [deleteTeamMutation, { data: deleteTeamData, loading: deleteTeamLoading }] = deleteTeam.hook();

    useEffect(() => {
        if (deleteTeamData?.deleteTeam && deleteTeamData?.deleteTeam === team.uuid) {
            removeTeam(team);
            setShowModal(false);
        }
    }, [deleteTeamData]);

    return (
        <>
            <IconButton
                className={className}
                icon="delete"
                width={20}
                height={20}
                fill={destructiveColour}
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
            />
            {showModal && (
                <Modal header="Delete Team" onModalClose={() => setShowModal(false)}>
                    {deleteTeamLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!deleteTeamLoading && (
                        <Display.VerticalWithSpacing>
                            Are you sure you want to delete the team {`'${team.name}'`}?
                            <Display.HorizontalWithSpacing horizontalAlign="end">
                                <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
                                <DestructiveButton
                                    onClick={() =>
                                        deleteTeamMutation({
                                            variables: {
                                                team: { ...team, pageStats: JSON.stringify(team.pageStats) },
                                            },
                                        })
                                    }
                                >
                                    Delete
                                </DestructiveButton>
                            </Display.HorizontalWithSpacing>
                        </Display.VerticalWithSpacing>
                    )}
                </Modal>
            )}
        </>
    );
};
