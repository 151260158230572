import gql from 'graphql-tag';
import { GraphQlContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapQuery from '../wrapQuery';

interface GetContentItemsParams {
    simulationUUID: string;
    newerThan?: Date | string;
    startFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
}

interface GetContentItemsData {
    getContentItems: {
        contentItems: GraphQlContentItem[];
        continueFrom?: string; // AWSJSON - DynamoDB LastEvaluatedKey
    };
}

const query = gql`
    query getContentItems($simulationUUID: String!, $newerThan: String, $startFrom: AWSJSON) {
        getContentItems(simulationUUID: $simulationUUID, newerThan: $newerThan, startFrom: $startFrom) {
            contentItems {
                type
                uuid
                simulationUUID
                teamUUID
                parentUUID
                createdAt
                persona
                content {
                    pageUUID
                    interactions {
                        positive
                        negative
                        shares
                    }
                    data
                }
                prepreparedContentUUID
                createdBy {
                    type
                    uuid
                }
                deleted {
                    at
                    by {
                        type
                        uuid
                    }
                }
            }
            continueFrom
        }
    }
`;

export const getContentItems = wrapQuery<GetContentItemsParams, GetContentItemsData>(query);
