import { PrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import React, { FC, useContext, useState } from 'react';
import { SecondaryButton } from '../../../../../../bits/Buttons';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { PrepreparedContentFormModal } from '../../PrepreparedContentFormModal';

interface NewPrepreparedContentButtonProps {
    label?: string;
    parent?: PrepreparedContentItem;
}
export const NewPrepreparedContentButton: FC<NewPrepreparedContentButtonProps> = ({ label, parent }) => {
    const { currentSimulation } = useContext(AdminManageSimulationContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <SecondaryButton onClick={() => setShowModal(true)}>
                {label || 'Add Preprepared Content Item'}
            </SecondaryButton>
            {showModal && (
                <PrepreparedContentFormModal
                    simulation={currentSimulation}
                    onCancel={() => setShowModal(false)}
                    onSaveComplete={() => setShowModal(false)}
                    parent={parent}
                />
            )}
        </>
    );
};
