/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { assocPath, pipe } from 'ramda';
import { grey2Colour } from '../../../../../themes/colours';
import logo from './logo.png';
import image from './image.png';

const basePageTemplate: PageTemplate = {
    uuid: '',
    name: '',
    category: 'Webpage',
    icon: logo,
    rootItemOptions: {
        name: 'Page',
        requiredFields: ['page'],
        fields: [{ name: 'page', type: ContentItemFieldType.RICHTEXT }],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childItemOptions: {
        name: 'Comment',
        pluralName: 'Comments',
        requiredFields: ['comment'],
        fields: [{ name: 'comment', type: ContentItemFieldType.LONG_TEXT }],
        interactions: {
            positive: { enabled: true, img: undefined, displayName: 'like' },
            negative: { enabled: true, img: undefined, displayName: 'dislike' },
            shares: { enabled: false },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 1 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.LIST },
        childrenSettings: { location: 'append', max: undefined },
        sortBy: 'DescendentCreatedDescending',
        rootContentFieldOrder: ['page'],
        childContentFieldOrder: ['meta', 'comment', 'interactions'],
        detailsLinkFields: [],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                leftSidebar: { margin: { right: 10 } },
                main: { width: 800 },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 800,
            },
            rootItem: {
                avatar: undefined,
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
            childrenItemsContainer: { verticalGap: 4, margin: { top: 18 } },
            childItemContainer: {},
            childItem: {
                borders: {
                    top: { width: 1, color: grey2Colour },
                    right: { width: 1, color: grey2Colour },
                    bottom: { width: 1, color: grey2Colour },
                    left: { width: 1, color: grey2Colour },
                },
                padding: {
                    top: 8,
                    right: 8,
                    bottom: 8,
                    left: 8,
                },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                timestamp: 'inline',
                meta: {},
                interactions: { alignSelf: 'end', horizontalGap: 50, margin: { top: 8 } },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: { location: 'append' },
        sortBy: 'DescendentCreatedDescending',
        rootContentFieldOrder: ['page'],
        childContentFieldOrder: ['meta', 'comment', 'interactions'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                leftSidebar: { margin: { right: 10 } },
                main: { width: 800 },
                rightSidebar: { margin: { left: 10 } },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 800,
            },
            rootItem: {
                avatar: undefined,
                padding: {
                    top: 10,
                    right: 10,
                    bottom: 10,
                    left: 10,
                },
                timestamp: 'inline',
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
            childrenItemsContainer: { verticalGap: 4, margin: { top: 18 } },
            childItemContainer: {},
            childItem: {
                borders: {
                    top: { width: 1, color: grey2Colour },
                    right: { width: 1, color: grey2Colour },
                    bottom: { width: 1, color: grey2Colour },
                    left: { width: 1, color: grey2Colour },
                },
                padding: {
                    top: 8,
                    right: 8,
                    bottom: 8,
                    left: 8,
                },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                timestamp: 'inline',
                meta: {},
                interactions: { alignSelf: 'end', horizontalGap: 50, margin: { top: 8 } },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {},
            },
        },
    },
};

export const pageTemplateWithChildren: PageTemplate = {
    ...basePageTemplate,
    uuid: 'aaba9fbb-8ac6-4765-9a44-5b935ebf87dc',
    name: 'Webpage with Comments',
};
export const pageTemplateWithoutChildren: PageTemplate = pipe(
    assocPath(['uuid'], 'fc4ae3a0-8876-4cd7-b765-c8b7af5590fb'),
    assocPath(['name'], 'Webpage without Comments'),
    assocPath(['childItemOptions', 'interactions'], {
        positive: { enabled: false },
        negative: { enabled: false },
        shares: { enabled: false },
    }),
    assocPath(['listingPage', 'childrenSettings'], {}),
    assocPath(['detailsPage', 'childrenSettings'], {}),
)(basePageTemplate) as PageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                page: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque maximus erat nibh, quis tempus ipsum accumsan at. Duis a felis ipsum. Quisque porttitor at nisi vel maximus. Nulla volutpat eros vitae maximus molestie. Duis vitae purus eget mauris sodales fringilla. Aliquam et erat lacus. Morbi ut semper nisi. Pellentesque fermentum malesuada venenatis. Aenean blandit, nulla scelerisque cursus lacinia, orci massa tincidunt elit, ut mollis lacus nulla sit amet est.</p><p>Aenean elementum turpis sit amet blandit maximus. Nulla convallis nulla eu commodo mattis. Integer aliquam sollicitudin ex vel consequat. Etiam mollis faucibus erat sed ornare. Mauris cursus eu eros ac egestas. Vestibulum non arcu ullamcorper, feugiat ex in, ultricies sem. Aenean feugiat lectus at volutpat vestibulum. Aliquam tempus lacus et sagittis mattis. Integer lorem nisi, ultrices quis lorem eu, accumsan sollicitudin odio. In at purus eget elit faucibus accumsan. Fusce felis lacus, sagittis id felis et, interdum gravida metus.</p><p>Nam maximus tellus ex, pellentesque eleifend dui porta quis. Ut volutpat metus sed est auctor, quis pulvinar lorem auctor. Sed ultricies nisi justo, eu tincidunt nulla sagittis et. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam rutrum viverra magna, a faucibus leo aliquam vel. Phasellus et nulla sollicitudin, fringilla dolor in, tempus purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut quis elit a massa interdum bibendum. Suspendisse quam ante, feugiat quis volutpat a, sagittis ac sapien.</p><img src="${image}">`,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment: 'Hi! How are you?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment: "I'm doing well thankyou! The weather's been wonderful lately hasn't it?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidc',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment: "Oh yes! I've enjoyed going out for walks with the dog and she's really been loving it too!",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidd',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment: 'Are you still up for a coffee later this week?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuide',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 52 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment:
                    "Yes! I've been hankering after a good frappucinno for awhile! Wanna meet up Sunday afternoon?",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidf',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ minutes: 31 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment:
                    'Ahhh sorry I just realised I have plans on Sunday afternoon. Would Saturday morning be doable?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidg',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ seconds: 29 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: 'd66e59e2-974c-4a30-838d-d68ef1a60a51',
            interactions: {},
            data: {
                comment: 'Yes, Saturday morning is good for me :D See you then!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
