import styled from 'styled-components/macro';
import { grey3Colour, grey4Colour, grey6Colour } from '../../themes/colours';
import {
    body1,
    body2,
    destructiveTextColor,
    DestructiveTextColorProps,
    formLabel,
    h1Heading,
    h2Heading,
    h3Heading,
} from '../../themes/cssSnippets';

interface WithColor {
    color?: string;
}
interface WithWeight {
    weight?: 400 | 500 | 600 | 700;
}

export const H1Heading = styled.h1`
    ${h1Heading}
    max-width: 100%;
    overflow-wrap: anywhere;
`;

export const H2Heading = styled.h2`
    ${h2Heading}
    max-width: 100%;
    overflow-wrap: anywhere;
`;

export const H3Heading = styled.h3`
    ${h3Heading}
    max-width: 100%;
    overflow-wrap: anywhere;
`;

export const H4Heading = styled.h4`
    color: ${grey4Colour};
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.18px;

    max-width: 100%;
    overflow-wrap: anywhere;
`;

export const H5Heading = styled.h5`
    color: ${grey6Colour};
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.26px;
`;

export const H6Heading = styled.h6`
    color: ${grey6Colour};
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1.44px;
`;

export const H7Heading = styled.h6`
    color: ${grey6Colour};
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1.62px;
`;

export const Subtitle1 = styled.span<WithColor & WithWeight>`
    color: ${({ color }) => color || grey3Colour};
    font-weight: ${({ weight }) => weight || '500'};
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 0.51px;
`;
export const Subtitle1Small = styled(Subtitle1)`
    font-size: 8px;
    letter-spacing: 0.21px;
`;

export const Subtitle2 = styled.span`
    ${formLabel}
`;

export const Body1 = styled.span<DestructiveTextColorProps>`
    ${body1}
    white-space: pre-line;
    display: inline-block;
    max-width: 100%;
    overflow-wrap: anywhere;

    ${destructiveTextColor}
`;

export const Body2 = styled.span<DestructiveTextColorProps>`
    ${body2}
    white-space: pre-line;

    ${destructiveTextColor}
`;

export const Body3 = styled.span<DestructiveTextColorProps & WithColor & WithWeight>`
    color: ${({ color }) => color || grey4Colour};
    font-weight: ${({ weight }) => weight || '400'};
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 16px;

    ${destructiveTextColor}
`;

export const StyledErrorMessage = styled(Body2).attrs((props) => ({ ...props, destructive: true }))``;
