import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { assocPath } from 'ramda';
import React, { FC } from 'react';
import { Display } from '../../../../../bits/Display';
import { Checkbox, TextInput } from '../../../../../bits/FormFields';
import { Body2 } from '../../../../../bits/Headers';
import { Card } from '../../../../../patterns/Card';
import { EmbeddedEmailItem } from '../../../../../patterns/EmbeddedEmailItem';
import RichtextInput from '../../../../../patterns/RichtextInput';
import { SimulationContent } from '../../../../../WithAdminState/adminState';
import { FormGrid } from '../../../../FormGrid';
import { EmbeddedContentItem } from '../../../../PageTemplate/EmbeddedContentItem';
import { PrepreparedContentPickerButton } from './PrepreparedContentPickerButton';
import { transformPreparedContentItemUUID } from '../../../../../../utils/transformPreparedContentItemUUID';
import { IconButton } from '../../../../../bits/Buttons/IconButton';

interface EditTriggerSetupProps {
    trigger: Trigger;
    simulation: Simulation;
    simulationContent: SimulationContent;
    onChange: (triggerOrFunc: Trigger | ((trigger: Trigger) => Trigger)) => void;
    disableInputs: boolean;
}
export const EditTriggerSetup: FC<EditTriggerSetupProps> = ({
    simulation,
    trigger,
    simulationContent,
    onChange,
    disableInputs,
}) => {
    const { prepreparedContents, prepreparedEmails } = simulationContent;
    const participantPopupItemUUIDs = transformPreparedContentItemUUID(
        trigger.participantPopupSettings.prepreparedContentItemUUID,
    );
    const participantPopupItems = participantPopupItemUUIDs.map(
        (uuid) => prepreparedContents[uuid] || prepreparedEmails[uuid],
    );

    return (
        <>
            <Card header="Setup">
                <FormGrid
                    fields={[
                        [
                            'Trigger Title',
                            <Display.VerticalWithSpacing gap={8} key="Trigger Title">
                                <TextInput
                                    value={trigger.title || ''}
                                    onChange={(e) => onChange(assocPath(['title'], e.target.value))}
                                    disabled={disableInputs}
                                />
                                <Body2>Shown in the pop up to participants</Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Main storyline',
                            <Display.VerticalWithSpacing gap={8} key="Main storyline">
                                <Checkbox
                                    id="optional"
                                    checked={!trigger.isOptional}
                                    onChange={(e) => onChange(assocPath(['isOptional'], !e.target.checked))}
                                    disabled={disableInputs}
                                />
                                <Body2>
                                    Denotes whether the trigger is included in the main simulation or is optional
                                </Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Simulated time',
                            <TextInput
                                key="time"
                                value={trigger.simulatedTime || ''}
                                onChange={(e) => onChange(assocPath(['simulatedTime'], e.target.value))}
                                disabled={disableInputs}
                            />,
                        ],
                    ]}
                />
            </Card>
            <Card header="Participant Settings">
                <FormGrid
                    fields={[
                        [
                            'Pop up',
                            <Display.VerticalWithSpacing gap={8} key="Pop up">
                                <Checkbox
                                    id="popup"
                                    checked={trigger.participantPopupSettings.enabled}
                                    onChange={(e) => {
                                        onChange(
                                            assocPath(['participantPopupSettings'], {
                                                ...(!e.target.checked
                                                    ? {
                                                          enabled: e.target.checked,
                                                          text: '',
                                                          prepreparedContentItemUUID: undefined,
                                                      }
                                                    : {
                                                          ...trigger.participantPopupSettings,
                                                          enabled: e.target.checked,
                                                      }),
                                            }),
                                        );
                                    }}
                                    disabled={disableInputs}
                                />
                                <Body2>
                                    When enabled, participants will be notified of the trigger being released via a pop
                                    up on screen
                                </Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Alert text',
                            <Display.VerticalWithSpacing gap={8} key="Alert text">
                                <RichtextInput
                                    value={trigger.participantPopupSettings.text || ''}
                                    onChange={(richtext) =>
                                        onChange(assocPath(['participantPopupSettings', 'text'], richtext))
                                    }
                                    imageUploadSimulation={simulation}
                                    disabled={disableInputs || !trigger.participantPopupSettings.enabled}
                                />
                                <Body2>Shown in the pop up under the Trigger title to participants </Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Pop up link',
                            <Display.VerticalWithSpacing gap={16} key="Pop up link">
                                {!!participantPopupItems.length &&
                                    participantPopupItems.map((item) => (
                                        <Display.HorizontalWithMaxSpaceBetween key={item?.uuid}>
                                            {item?.type === 'PAGE_CONTENT' && (
                                                <EmbeddedContentItem
                                                    contentItemOptions={{ showAssignedTeams: true }}
                                                    item={item}
                                                />
                                            )}
                                            {item?.type === 'EMAIL' && <EmbeddedEmailItem item={item} />}
                                            <IconButton
                                                icon="delete"
                                                onClick={() =>
                                                    onChange(
                                                        assocPath(
                                                            ['participantPopupSettings', 'prepreparedContentItemUUID'],
                                                            participantPopupItemUUIDs.filter(
                                                                (uuid) => uuid !== item?.uuid,
                                                            ),
                                                        ),
                                                    )
                                                }
                                                disabled={disableInputs}
                                            />
                                        </Display.HorizontalWithMaxSpaceBetween>
                                    ))}

                                <Display.HorizontalWithSpacing>
                                    <PrepreparedContentPickerButton
                                        simulation={simulation}
                                        onPrepreparedItemSelected={(item) =>
                                            onChange(
                                                assocPath(
                                                    ['participantPopupSettings', 'prepreparedContentItemUUID'],
                                                    [...participantPopupItemUUIDs, item.uuid],
                                                ),
                                            )
                                        }
                                        defaultOpenTriggerGroups={[trigger.uuid]}
                                        disabled={disableInputs || !trigger.participantPopupSettings.enabled}
                                        selectedItemUUIDs={participantPopupItemUUIDs}
                                    />
                                </Display.HorizontalWithSpacing>
                                <Body2>
                                    Links to a specific content item that participants should be aware of or be
                                    interacting with
                                </Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                    ]}
                />
            </Card>

            <Card header="Roleplayer Settings">
                <FormGrid
                    fields={[
                        [
                            'Alert that goes to participants',
                            <Display.VerticalWithSpacing gap={8} key="Roleplayer directions">
                                <RichtextInput
                                    value={trigger.staffPopupSettings.directions || ''}
                                    onChange={(richtext) =>
                                        onChange(assocPath(['staffPopupSettings', 'directions'], richtext))
                                    }
                                    imageUploadSimulation={simulation}
                                    disabled={disableInputs}
                                />
                                <Body2>Shown to Roleplayers only</Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Guidance for role players',
                            <Display.VerticalWithSpacing gap={8} key="Content guidelines">
                                <RichtextInput
                                    value={trigger.staffPopupSettings.guidelines || ''}
                                    onChange={(richtext) =>
                                        onChange(assocPath(['staffPopupSettings', 'guidelines'], richtext))
                                    }
                                    imageUploadSimulation={simulation}
                                    disabled={disableInputs}
                                />
                                <Body2>Shown to Roleplayers only - Context that will help create content</Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                        [
                            'Expected action by participants',
                            <Display.VerticalWithSpacing gap={8} key="Expected Actions">
                                <RichtextInput
                                    value={trigger.staffPopupSettings.expectedActions || ''}
                                    onChange={(richtext) =>
                                        onChange(assocPath(['staffPopupSettings', 'expectedActions'], richtext))
                                    }
                                    imageUploadSimulation={simulation}
                                    disabled={disableInputs}
                                />
                                <Body2>Shown to Roleplayers only</Body2>
                            </Display.VerticalWithSpacing>,
                        ],
                    ]}
                />
            </Card>
        </>
    );
};
