import { decode } from 'html-entities';
import { pipe, replace } from 'ramda';

export const richtextToString = pipe(
    replace(/<custom-link href="/g, ''),
    replace(/"><\/custom-link>/g, ''),
    replace(/<\/p>/g, '\n'),
    replace(/<\/?(.+?)>/g, ''),
    decode,
);
