import gql from 'graphql-tag';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import wrapSubscription from '../wrapSubscription';

interface OnWriteScratchPadDocumentParams {
    simulationUUID: string;
}

interface OnWriteScratchPadDocumentData {
    onWriteScratchPadDocument: ScratchPadDocument;
}

const subscription = gql`
    subscription onWriteScratchPadDocument($simulationUUID: String!) {
        onWriteScratchPadDocument(simulationUUID: $simulationUUID) {
            uuid
            simulationUUID
            teamUUID
            category
            path
            createdAt
            createdBy
            lastEditedBy
            notes
            name
            currentEditor
        }
    }
`;

export const onWriteScratchPadDocument =
    wrapSubscription<OnWriteScratchPadDocumentParams, OnWriteScratchPadDocumentData>(subscription);
