import {
    decodePrepreparedContentItem,
    NewPrepreparedContentItem,
    PrepreparedContentItem,
} from 'polpeo-go-common/types/ContentItem';
import { Page } from 'polpeo-go-common/types/Page';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { filter, find, flatten, isNil, map, reduce, reject } from 'ramda';
import { Client } from '../../../../../../graphql/client';
import { createPrepreparedContent } from '../../../../../../graphql/prepreparedContent';
import { pageTemplates } from '../../../../PageTemplate/templates';
import { UploadedPrepreparedContent } from '../prepreparedContent/parsePrepreparedContentsSheet';

const uploadedPrepreparedContentToNewPrepreparedContent = (
    context: { simulation: Simulation; pages: Page[] },
    item: UploadedPrepreparedContent,
    imageMap: Record<string, string>,
): NewPrepreparedContentItem => {
    const { simulation, pages } = context;
    const page = find((p) => p.name === item.content.pageTitle, pages);
    if (!page) {
        throw new Error('page could not be found');
    }

    const template = pageTemplates[page.templateUUID];
    if (!template) {
        throw new Error('template could not be found');
    }
    const rootItemImageFields = reduce(
        (acc, fieldDef) => (fieldDef.type === 'IMAGE' ? [...acc, fieldDef] : [...acc]),
        [] as ContentItemField[],
        template.rootItemOptions.fields,
    );
    const childItemImageFields = reduce(
        (acc, fieldDef) => (fieldDef.type === 'IMAGE' ? [...acc, fieldDef] : acc),
        [] as ContentItemField[],
        template.childItemOptions.fields,
    );
    const pageTemplateImageFields = item.tempParentId ? childItemImageFields : rootItemImageFields;
    const contentDataWithImages = reduce(
        (acc, field) => {
            const fieldImageValue = acc[field.name];
            if (!fieldImageValue) {
                return acc;
            }
            const foundImage = imageMap[fieldImageValue];
            if (!foundImage) {
                throw new Error('Image could not be found in image map');
            }

            return { ...acc, [field.name]: foundImage };
        },
        item.content.data,
        pageTemplateImageFields,
    );

    const newPreparedContent: NewPrepreparedContentItem = {
        type: item.type,
        simulationUUID: simulation.uuid,
        persona: item.persona,
        parentUUID: undefined,
        content: {
            pageUUID: page.uuid,
            interactions: item.content.interactions,
            data: contentDataWithImages,
        },
        released: undefined,
        teamUUIDs: [],
    };
    return newPreparedContent;
};
const doCreatePrepreparedContent = async (
    client: Client,
    item: NewPrepreparedContentItem,
): Promise<PrepreparedContentItem> => {
    const createContentResponse = await createPrepreparedContent.promise(client, {
        prepreparedContent: {
            ...item,
            content: { ...item.content, data: JSON.stringify(item.content.data) },
        },
    });

    if (!createContentResponse || !createContentResponse.data) {
        throw new Error('Preprepared Content could not be created');
    }
    const createdPrepreparedContent = createContentResponse.data.createPrepreparedContent;
    return decodePrepreparedContentItem({
        ...createdPrepreparedContent,
        content: {
            ...createdPrepreparedContent.content,
            data: JSON.parse(createdPrepreparedContent.content.data),
        },
        released: createdPrepreparedContent.released
            ? {
                  ...createdPrepreparedContent.released,
                  items: JSON.parse(createdPrepreparedContent.released.items),
              }
            : undefined,
    });
};
export const doCreatePrepreparedContents = async (
    client: Client,
    context: {
        simulation: Simulation;
        pages: Page[];
        uploadedContent: UploadedPrepreparedContent[];
        uploadedImages: Record<string, string>;
    },
    parent?: { uuid: string; tempId: string },
): Promise<{
    prepreparedContentItems: PrepreparedContentItem[];
    prepreparedContentTriggerLinks: Record<string, string>;
    prepreparedContentTempIdMap: Record<string, string>;
}> => {
    const { simulation, pages, uploadedContent } = context;
    const allCreatePrepreparedContent: PrepreparedContentItem[] = [];
    let prepreparedContentTriggerLinks: Record<string, string> = {};
    let prepreparedContentTempIdMap: Record<string, string> = {};

    const items = filter(
        (item) => (parent ? item.tempParentId === parent.tempId : !item.tempParentId),
        uploadedContent,
    );

    await Promise.all(
        map(async (prepreparedContentData) => {
            const newPreparedContent = uploadedPrepreparedContentToNewPrepreparedContent(
                { simulation, pages },
                prepreparedContentData,
                context.uploadedImages,
            );
            const prepreparedContent = await doCreatePrepreparedContent(client, {
                ...newPreparedContent,
                parentUUID: parent ? parent.uuid : undefined,
            });
            allCreatePrepreparedContent.push(prepreparedContent);
            if (prepreparedContentData.triggerTitle) {
                prepreparedContentTriggerLinks = {
                    ...prepreparedContentTriggerLinks,
                    [prepreparedContent.uuid]: prepreparedContentData.triggerTitle,
                };
            }
            if (prepreparedContentData.tempId) {
                prepreparedContentTempIdMap = {
                    ...prepreparedContentTempIdMap,
                    [prepreparedContentData.tempId]: prepreparedContent.uuid,
                };
                const childrenResults = await doCreatePrepreparedContents(client, context, {
                    uuid: prepreparedContent.uuid,
                    tempId: prepreparedContentData.tempId,
                });
                allCreatePrepreparedContent.push(...childrenResults.prepreparedContentItems);
                prepreparedContentTriggerLinks = {
                    ...prepreparedContentTriggerLinks,
                    ...childrenResults.prepreparedContentTriggerLinks,
                };
                prepreparedContentTempIdMap = {
                    ...prepreparedContentTempIdMap,
                    ...childrenResults.prepreparedContentTempIdMap,
                };
            }
        }, items),
    );

    return {
        prepreparedContentItems: flatten(reject(isNil, allCreatePrepreparedContent)),
        prepreparedContentTriggerLinks,
        prepreparedContentTempIdMap,
    };
};
