import { Page } from 'polpeo-go-common/types/Page';
import { PageThemingOptions } from 'polpeo-go-common/types/PageTemplate/PageThemingOptions';
import { Team } from 'polpeo-go-common/types/Team';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { NonInteractiveComponentHelpModal } from '../../../patterns/ContentItem/NonInteractiveComponentHelpModal';
import { ParticipantStateContext } from '../../../WithParticipantState';
import { pageTemplates } from '../templates';
import { BaseDiv } from '../utils';
import { Main } from './Main';
import { Footer, Header, LeftSidebar, RightSidebar } from './PageDressing';
import { PageStats } from './PageStats';

const PageGrid = styled(BaseDiv)`
    flex: 1 1 auto;

    display: grid;
    grid-template-rows: max-content max-content 10fr max-content;
    grid-template-columns: minmax(100px, 1fr) max-content minmax(100px, 1fr);
    grid-template-areas:
        'header header header'
        'fullWidthHeader fullWidthHeader fullWidthHeader'
        'leftSidebar main rightSidebar'
        'footer footer footer';
`;

const MainPageDressingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
        max-width: 100%;
        width: auto;
        object-fit: scale-down;
    }
`;

const FullWidthHeader = styled(BaseDiv)`
    grid-area: fullWidthHeader;
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
        max-width: 100%;
        width: auto;
        object-fit: scale-down;
    }
`;
interface PageContainerProps {
    pageTheme: PageThemingOptions;
    page?: Page; // optional because this does not exist for template previews
    trigger?: Trigger;
    team?: Team; // optional because this does not exist for template previews
}
export const PageContainer: FC<PageContainerProps> = ({ pageTheme, page, trigger, team, children }) => {
    const [topOffsetForSticky, setTopOffsetForSticky] = useState(0);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const headerElementRef = useRef<HTMLAnchorElement>(null);

    const { teamUUID } = useParams<{ teamUUID?: string }>();
    const { simulation: participantSimulation } = useContext(ParticipantStateContext);

    const location = useLocation();
    const disableListingPage =
        !page ||
        (!!page?.showItemsAsIndividualPages &&
            !!pageTemplates[page.templateUUID].showItemsAsIndividualPages?.pageTitleField);
    const listingPageUrl = useMemo(() => {
        if (disableListingPage) {
            return location.pathname;
        }
        return participantSimulation
            ? `/simulation/${participantSimulation.code}/${page?.uuid}`
            : `/admin/simulation/${page?.simulationUUID}/team/${teamUUID}/${page?.uuid}`;
    }, [page]);

    useEffect(() => {
        if ((pageTheme.leftSidebar?.sticky || pageTheme.rightSidebar?.sticky) && headerElementRef.current) {
            const margins = (pageTheme.header?.margin?.top || 0) && (pageTheme.header?.margin?.bottom || 0);
            /* 80 to match the main sticky header bars */
            const totalHeight = (pageTheme.header?.sticky ? headerElementRef.current.clientHeight : 0) + margins + 80;
            setTopOffsetForSticky(totalHeight);
        }
    }, []);

    const headerImage = pageTheme.header?.img;
    const headerDressing = trigger?.pageDressingChanges[page?.uuid || '']?.header || page?.dressing.header;
    const overrideHeaderImage = pageTheme.pageDressingAsPageImages?.header;
    const fullWidthHeaderDressing = pageTheme?.fullWidthHeaderDressing;

    const leftSidebarImage = pageTheme.leftSidebar?.img;
    const leftSidebarDressing =
        trigger?.pageDressingChanges[page?.uuid || '']?.leftSidebar || page?.dressing.leftSidebar;
    const overrideLeftSidebarImage = pageTheme.pageDressingAsPageImages?.leftSidebar;

    const rightSidebarImage = pageTheme.rightSidebar?.img;
    const rightSidebarDressing =
        trigger?.pageDressingChanges[page?.uuid || '']?.rightSidebar || page?.dressing.rightSidebar;
    const overrideRightSidebarImage = pageTheme.pageDressingAsPageImages?.rightSidebar;

    const footerImage = pageTheme.footer?.img;
    const footerDressing = trigger?.pageDressingChanges[page?.uuid || '']?.footer || page?.dressing.footer;
    const overrideFooterImage = pageTheme.pageDressingAsPageImages?.footer;

    const statPosition = pageTheme?.stats?.position;

    return (
        <PageGrid theme={pageTheme}>
            {(!!pageTheme.header || headerDressing) && (
                <Header to={listingPageUrl} ref={headerElementRef} theme={pageTheme.header || {}}>
                    {!overrideHeaderImage && headerImage && (
                        <img src={headerImage} onClick={() => setShowHelpModal(disableListingPage)} />
                    )}
                    {overrideHeaderImage && headerDressing && (
                        <img src={headerDressing} onClick={() => setShowHelpModal(disableListingPage)} />
                    )}
                    {page && team && statPosition === 'header' && (
                        <PageStats page={page} team={team} theme={pageTheme.stats} />
                    )}
                </Header>
            )}
            {fullWidthHeaderDressing && (
                <FullWidthHeader theme={fullWidthHeaderDressing || {}}>
                    <BaseDiv onClick={() => setShowHelpModal(true)} as="img" src={headerDressing} />
                </FullWidthHeader>
            )}
            {(!!pageTheme.leftSidebar || leftSidebarDressing) && (
                <LeftSidebar top={topOffsetForSticky} theme={pageTheme.leftSidebar || {}}>
                    {page && team && statPosition === 'leftSidebar' && (
                        <PageStats team={team} page={page} theme={pageTheme.stats} />
                    )}
                    {!overrideLeftSidebarImage && leftSidebarImage && <img src={leftSidebarImage} />}
                    {leftSidebarDressing && (
                        <BaseDiv
                            onClick={() => setShowHelpModal(true)}
                            as="img"
                            src={leftSidebarDressing}
                            theme={pageTheme.leftSidebarDressing || {}}
                        />
                    )}
                </LeftSidebar>
            )}
            <Main theme={pageTheme.main}>
                {!overrideHeaderImage && headerDressing && !fullWidthHeaderDressing && (
                    <MainPageDressingContainer>
                        <BaseDiv
                            onClick={() => setShowHelpModal(true)}
                            as="img"
                            src={headerDressing}
                            theme={pageTheme.headerDressing || {}}
                        />
                    </MainPageDressingContainer>
                )}
                {page && team && statPosition === 'main' && (
                    <PageStats page={page} team={team} theme={pageTheme.stats} />
                )}
                {children}
            </Main>
            {(!!pageTheme.rightSidebar || rightSidebarDressing) && (
                <RightSidebar top={topOffsetForSticky} theme={pageTheme.rightSidebar || {}}>
                    {page && team && statPosition === 'rightSidebar' && (
                        <PageStats page={page} team={team} theme={pageTheme.stats} />
                    )}
                    {!overrideRightSidebarImage && rightSidebarImage && <img src={rightSidebarImage} />}
                    {rightSidebarDressing && (
                        <BaseDiv
                            onClick={() => setShowHelpModal(true)}
                            as="img"
                            src={rightSidebarDressing}
                            theme={pageTheme.rightSidebarDressing || {}}
                        />
                    )}
                </RightSidebar>
            )}
            {(!!pageTheme.footer || footerDressing) && (
                <Footer theme={pageTheme.footer || {}}>
                    {page && team && statPosition === 'footer' && (
                        <PageStats page={page} team={team} theme={pageTheme.stats} />
                    )}
                    {!overrideFooterImage && footerImage && <img src={footerImage} />}
                    {footerDressing && (
                        <BaseDiv
                            onClick={() => setShowHelpModal(true)}
                            as="img"
                            src={footerDressing}
                            theme={pageTheme.footerDressing || {}}
                        />
                    )}
                </Footer>
            )}
            {showHelpModal && <NonInteractiveComponentHelpModal onModalClose={() => setShowHelpModal(false)} />}
        </PageGrid>
    );
};
