import React, { FC, useContext, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import AuthContext from '../WithAuth/AuthContext';
import { ParticipantStateContext } from '../WithParticipantState';
import { ParticipantUserContext } from '../WithParticipantUser';
import { Modal } from './Modal';
import { UserMenu } from './UserMenu';
import { notificationColour } from '../../themes/colours';
import { MenuItem } from './MenuButton';
import styled from 'styled-components/macro';

const NotificationMenuItem = styled(MenuItem)`
    color: ${notificationColour};
`;

export const ParticipantUserMenu: FC = () => {
    const { participantUser } = useContext(ParticipantUserContext);
    const { participantTeam } = useContext(ParticipantStateContext);
    const { signOutUnauthenticatedUser } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <UserMenu name={participantUser.fullName} teamName={participantTeam?.name}>
                <NotificationMenuItem onClick={() => setShowModal(true)}>Exit Simulation</NotificationMenuItem>
            </UserMenu>
            {showModal && (
                <Modal header="Exit Simulation" onModalClose={() => setShowModal(false)}>
                    <Display.VerticalWithSpacing>
                        <div>Are you sure you want to exit this simulation?</div>
                        <Display.RightAlign>
                            <Display.HorizontalWithSpacing>
                                <SecondaryButton onClick={() => setShowModal(false)}>
                                    Go back to simulation
                                </SecondaryButton>
                                <PrimaryButton onClick={() => signOutUnauthenticatedUser()}>
                                    Exit Simulation
                                </PrimaryButton>
                            </Display.HorizontalWithSpacing>
                        </Display.RightAlign>
                    </Display.VerticalWithSpacing>
                </Modal>
            )}
        </>
    );
};
