import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { map, sortBy, toLower } from 'ramda';
import React, { FC, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components/macro';
import { grey1Colour, primaryColour } from '../../../../../themes/colours';
import { Display } from '../../../../bits/Display';
import { DraggableParticipantCard } from './DraggableParticipantCard';

interface TeamListProps {
    highlight?: boolean;
}
const TeamList = styled(Display.VerticalWithSpacing).attrs(() => ({ gap: 5 }))<TeamListProps>`
    background: ${grey1Colour};
    height: 100%;
    padding: 5px;

    align-items: start;
    align-content: start;

    outline: ${({ highlight }) => (highlight ? `solid 2px ${primaryColour}` : 'none')};
`;

interface DroppableTeamListProps {
    participants: ParticipantUser[];
    selectedParticipants: ParticipantUser[];
    updateTeamAssignment: (participants: ParticipantUser[]) => void;
    toggleParticipantSelection: (participant: ParticipantUser) => void;
    setSelectedParticipants: (participants: ParticipantUser[]) => void;
}
export const DroppableTeamList: FC<DroppableTeamListProps> = ({
    participants,
    selectedParticipants,
    updateTeamAssignment,
    toggleParticipantSelection,
    setSelectedParticipants,
}: DroppableTeamListProps) => {
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: 'PARTICIPANT_CARD',
            drop: (droppedParticipants: ParticipantUser[]) => {
                updateTeamAssignment(droppedParticipants);
                setSelectedParticipants([]);
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
            }),
        }),
        [participants],
    );
    const sortedParticipants = useMemo(
        () => sortBy((participant) => toLower(participant.fullName), participants),
        [participants],
    );

    return (
        <>
            <TeamList ref={drop} highlight={isOver}>
                {map(
                    (participant) => (
                        <DraggableParticipantCard
                            key={participant.id}
                            participant={participant}
                            toggleParticipantSelection={toggleParticipantSelection}
                            selectedParticipants={selectedParticipants}
                            setSelectedParticipants={setSelectedParticipants}
                        />
                    ),
                    sortedParticipants,
                )}
            </TeamList>
        </>
    );
};
