import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { Moment } from 'polpeo-go-common/types/Moment';
import { ParticipantTeamAssignments } from 'polpeo-go-common/types/ParticipantAssignments';
import { ParticipantUser } from 'polpeo-go-common/types/ParticipantUser';
import { PathParameters } from 'polpeo-go-common/types/PathParameters';
import { ScratchPadDocument } from 'polpeo-go-common/types/ScratchPadDocument';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Team } from 'polpeo-go-common/types/Team';
import { ReleasedTrigger, Trigger } from 'polpeo-go-common/types/Trigger';
import { createContext, SetStateAction } from 'react';
import { TeamNotifications } from '../../utils/TeamNotifications';
import { SimulationContent } from '../WithAdminState/adminState';

type StaffNotifications = {
    teamManagement?: boolean;
} & Record<string, TeamNotifications>;

type ActivityFilterValue = 'Participants' | 'Staff';

export interface AdminInSimulationState {
    simulation: Simulation;
    setSimulation: (simulation: Simulation) => void;
    simulationContent: SimulationContent;
    currentTrigger?: ReleasedTrigger;
    updateTrigger: (trigger: Trigger) => void;
    addContentItems: (contentItems: ContentItem[] | Record<string, ContentItem>) => void;
    addEmailItems: (emailItems: EmailItem[] | Record<string, EmailItem>) => void;
    getLinkUrlForContentItem: (item: ContentItem) => string;
    getLinkUrlForEmailItem: (item: EmailItem) => string;
    addMoments: (moments: Moment[] | Record<string, Moment>) => void;
    teamsForCurrentStaffUser: Record<string, Team>;
    currentSimulationTeams: Record<string, Team>;
    team?: Team;
    teamParticipants: ParticipantUser[];
    setTeam: (team: Team) => void;
    participants: Record<string, ParticipantUser>;
    teamAssignments: ParticipantTeamAssignments;
    setTeamAssignments: (teamAssignments: ParticipantTeamAssignments) => void;
    urlPathParams: PathParameters;
    scratchPadDocuments?: ScratchPadDocument[];
    setScratchPadDocuments: (scratchPadDocuments: ScratchPadDocument[]) => void;

    showManageTeamsPage: boolean;
    setShowManageTeamsPage: (value: boolean) => void;

    activityFilters: Array<ActivityFilterValue>;
    setActivityFilters: (value: Array<ActivityFilterValue>) => void;

    notifications: StaffNotifications;
    setNotifications: React.Dispatch<SetStateAction<StaffNotifications>>;
}

// No one should ever be accessing AdminState before it's been initialised anyways so we shouldn't
// provide any default values
export const AdminInSimulationStateContext = createContext<AdminInSimulationState>({} as AdminInSimulationState);
