import { ContentItem, decodeContentItem } from 'polpeo-go-common/types/ContentItem';
import { fromPairs, map } from 'ramda';
import { Client } from '../graphql/client';
import { getContentItems } from '../graphql/contentItem';

export const fetchContentItems = async (
    client: Client,
    simulationUUID: string,
    newerThan?: Date | string,
): Promise<Record<string, ContentItem>> => {
    let contentItems: ContentItem[] = [];
    let contentItemsContinueFrom: undefined | string;

    do {
        const getContentItemsResponse = await getContentItems.promise(client, {
            simulationUUID: simulationUUID,
            newerThan: newerThan,
            startFrom: contentItemsContinueFrom,
        });
        if (!getContentItemsResponse.data) {
            return {};
        }
        const cleanedContents = map(
            (item) =>
                decodeContentItem({
                    ...item,
                    content: {
                        ...item.content,
                        data: JSON.parse(item.content.data),
                    },
                }),
            getContentItemsResponse.data.getContentItems.contentItems,
        );
        contentItems = [...contentItems, ...cleanedContents];
        contentItemsContinueFrom = getContentItemsResponse.data.getContentItems.continueFrom;
    } while (contentItemsContinueFrom);
    return fromPairs(map((contentItem) => [contentItem.uuid, contentItem], contentItems));
};
