import { Trigger } from 'polpeo-go-common/types/Trigger';
import { find, join, map, reduce, reduced } from 'ramda';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey5Colour, grey6Colour } from '../../themes/colours';
import { SecondaryButton } from '../bits/Buttons';
import { IconButton } from '../bits/Buttons/IconButton';
import { Display } from '../bits/Display';
import { Body3, Subtitle1Small } from '../bits/Headers';
import { Icon } from '../bits/Icon';
import { IconSvg } from '../bits/Icon/IconSvg';
import { Link } from '../bits/Link';
import { RichtextDisplayOverflowStyling } from '../bits/RichtextDisplay';
import { AdminInSimulationStateContext } from '../WithAdminInSimulationState';
import { EditTriggerModal } from './pages/ManageSimulation/Triggers/EditTrigger/EditTriggerModal';
import { ReleaseTriggerHeroButton } from './ReleaseTriggerHeroButton';

const Playbar = styled.div`
    height: 61px;
    background: #ffffff;

    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: 1fr 1fr max-content;
    border-top: 1px solid ${grey1Colour};
`;

const CurrentTriggerCountContainer = styled(Display.VerticalWithSpacing).attrs(() => ({
    gap: 0,
    horizontalAlign: 'center',
}))`
    background: ${grey5Colour};
    height: 100%;
    min-width: 60px;
    padding: 8px;

    color: ${grey1Colour};
`;
const CurrentTriggerCountNumber = styled.span`
    font-size: 27px;
    color: #ffffff;
`;
const TriggerDetailsContainer = styled(Display.VerticalWithSpacing)`
    ${Body3} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const UpcomingTriggerContainer = styled(Display.HorizontalWithSpacing)`
    background: ${grey1Colour};
    border-radius: 9px;
    padding: 4px 8px;
    margin: 5px;
`;

const ManageTriggersButton = styled(SecondaryButton)`
    width: 54px;
    font-size: 8px;
    line-height: 8px;
    letter-spacing: 0.63px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid;

    margin: 5px;

    ${IconSvg}:first-child {
        margin: 0;
    }
`;

export const SimulationPlaybar: FC = () => {
    const {
        simulation,
        simulationContent: { triggers, triggerOrder },
        currentTrigger,
    } = useContext(AdminInSimulationStateContext);
    const [showTriggerDetails, setShowTriggerDetails] = useState<Trigger | undefined>(undefined);

    const sortedTriggers = map((triggerUUID) => triggers[triggerUUID], triggerOrder);
    const currentTriggerNumber = reduce(
        (acc, trigger) => {
            if (currentTrigger?.uuid === trigger.uuid) {
                return reduced(acc + 1);
            }
            if (!trigger.isOptional) {
                return acc + 1;
            }
            return acc;
        },
        0,
        sortedTriggers,
    );
    const nextTrigger = find((trigger) => !trigger.releasedAt && !trigger.isOptional, sortedTriggers);

    return (
        <>
            <Playbar>
                <Display.HorizontalWithSpacing gap={4} verticalCenter>
                    <CurrentTriggerCountContainer gap={0} horizontalAlign="center">
                        <Subtitle1Small color={grey1Colour}>Trigger</Subtitle1Small>
                        <CurrentTriggerCountNumber>
                            {!currentTrigger && '-'}
                            {currentTrigger && currentTrigger.isOptional && '?'}
                            {currentTrigger && !currentTrigger.isOptional && currentTriggerNumber}
                        </CurrentTriggerCountNumber>
                    </CurrentTriggerCountContainer>
                    {currentTrigger && (
                        <IconButton
                            icon="info"
                            onClick={() => {
                                setShowTriggerDetails(currentTrigger);
                            }}
                        />
                    )}
                    <TriggerDetailsContainer gap={0}>
                        {currentTrigger ? (
                            <>
                                <Subtitle1Small>Current Trigger</Subtitle1Small>
                                <Body3 color={grey6Colour} weight={500}>
                                    {currentTrigger.title}
                                </Body3>
                                <RichtextDisplayOverflowStyling
                                    value={join(' - ', [
                                        ...(currentTrigger.simulatedTime ? [currentTrigger.simulatedTime] : []),
                                        ...(currentTrigger.participantPopupSettings.text
                                            ? [currentTrigger.participantPopupSettings.text]
                                            : []),
                                    ])}
                                />
                            </>
                        ) : (
                            <Body3>No released trigger</Body3>
                        )}
                    </TriggerDetailsContainer>
                </Display.HorizontalWithSpacing>
                <UpcomingTriggerContainer gap={4} verticalCenter>
                    {nextTrigger ? (
                        <>
                            <ReleaseTriggerHeroButton trigger={nextTrigger} />
                            <IconButton
                                icon="info"
                                onClick={() => {
                                    setShowTriggerDetails(nextTrigger);
                                }}
                            />
                            <TriggerDetailsContainer gap={0}>
                                <Subtitle1Small>Upcoming Trigger</Subtitle1Small>
                                <Body3 color={grey6Colour} weight={500}>
                                    {nextTrigger.title}
                                </Body3>
                                <RichtextDisplayOverflowStyling
                                    value={join(' - ', [
                                        ...(nextTrigger.simulatedTime ? [nextTrigger.simulatedTime] : []),
                                        ...(nextTrigger.participantPopupSettings.text
                                            ? [nextTrigger.participantPopupSettings.text]
                                            : []),
                                    ])}
                                />
                            </TriggerDetailsContainer>
                        </>
                    ) : (
                        <Body3>No upcoming trigger found</Body3>
                    )}
                </UpcomingTriggerContainer>
                <ManageTriggersButton small>
                    <Link to={`/admin/simulation/${simulation.uuid}/manage/triggers`}>
                        <Icon icon="trigger" size={18} /> Manage Triggers
                    </Link>
                </ManageTriggersButton>
            </Playbar>
            {showTriggerDetails && (
                <EditTriggerModal trigger={showTriggerDetails} onClose={() => setShowTriggerDetails(undefined)} />
            )}
        </>
    );
};
