import React, { FC, useContext } from 'react';
import { PrimaryButton } from '../bits/Buttons';
import { Display } from '../bits/Display';
import { Modal } from './Modal';
import { AdminInSimulationStateContext } from '../WithAdminInSimulationState';
import { Icon } from '../bits/Icon';
import { H2Heading, H3Heading } from '../bits/Headers';
import { RichtextDisplay } from '../bits/RichtextDisplay';

interface AdminTriggerModalProps {
    onCloseModal: () => void;
}

export const AdminTriggerModal: FC<AdminTriggerModalProps> = ({ onCloseModal }) => {
    const { currentTrigger } = useContext(AdminInSimulationStateContext);
    const staffPopupSettings = currentTrigger?.staffPopupSettings;

    return (
        <Modal onModalClose={() => onCloseModal()} cardWidth={600}>
            <Display.VerticalWithSpacing>
                <Display.HorizontalWithSpacing>
                    <Display.HorizontalCenterVerticalCenter>
                        <Icon icon="attention" size={50} />
                    </Display.HorizontalCenterVerticalCenter>
                    <Display.VerticalWithSpacing gap={10}>
                        <H2Heading>{currentTrigger?.title}</H2Heading>
                        {currentTrigger?.simulatedTime && <H3Heading>{currentTrigger.simulatedTime}</H3Heading>}
                        {staffPopupSettings?.directions && (
                            <div>
                                <H3Heading>Alert that goes to participants</H3Heading>
                                <RichtextDisplay value={staffPopupSettings.directions || 'None'} />
                            </div>
                        )}
                        {staffPopupSettings?.guidelines && (
                            <div>
                                <H3Heading>Guidance for role players</H3Heading>
                                <RichtextDisplay value={staffPopupSettings.guidelines || 'None'} />
                            </div>
                        )}
                        {staffPopupSettings?.expectedActions && (
                            <div>
                                <H3Heading>Expected action by participants</H3Heading>
                                <RichtextDisplay value={staffPopupSettings.expectedActions || 'None'} />
                            </div>
                        )}
                    </Display.VerticalWithSpacing>
                </Display.HorizontalWithSpacing>
                <Display.HorizontalWithSpacing horizontalAlign="end">
                    <PrimaryButton onClick={() => onCloseModal()}>Close</PrimaryButton>
                </Display.HorizontalWithSpacing>
            </Display.VerticalWithSpacing>
        </Modal>
    );
};
