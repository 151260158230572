/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { grey1Colour, grey2Colour, grey3Colour } from '../../../../../themes/colours';
import logo from './logo.png';

const pageTemplate: PageTemplate = {
    uuid: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
    name: 'Review',
    category: 'Review Site',
    icon: logo,
    rootItemOptions: {
        name: 'Review',
        requiredFields: ['review'],
        fields: [
            { name: 'review', type: ContentItemFieldType.LONG_TEXT },
            { name: 'video', type: ContentItemFieldType.VIDEO },
        ],
        interactions: {
            positive: {
                enabled: true,
                displayName: 'useful',
                img: undefined,
            },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childItemOptions: {
        name: 'Reply',
        pluralName: 'Replies',
        requiredFields: ['reply'],
        fields: [{ name: 'reply', type: ContentItemFieldType.LONG_TEXT }],
        interactions: {
            positive: { enabled: false },
            negative: { enabled: false },
            shares: { enabled: false },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 1 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
            max: undefined,
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'video', 'review', 'interactions'],
        childContentFieldOrder: ['meta', 'reply'],
        detailsLinkFields: [],
        theme: {
            page: {
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                headerDressing: {
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                main: { width: 650 },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                borders: { right: { width: 1, color: grey2Colour }, left: { width: 1, color: grey2Colour } },
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                verticalGap: 16,
            },
            rootItem: {
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                borders: {
                    top: { width: 1, color: grey2Colour },
                    right: { width: 1, color: grey2Colour },
                    bottom: { width: 1, color: grey2Colour },
                    left: { width: 1, color: grey2Colour },
                },
                bgColour: '#ffffff',
                avatar: { position: 'inline' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: { alignSelf: 'end', font: { weight: 'BOLD', color: grey3Colour } },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    review: {
                        borders: {
                            top: { width: 1, color: grey2Colour },
                            bottom: { width: 1, color: grey2Colour },
                        },
                        margin: { top: 8, bottom: 8 },
                        padding: { top: 16, bottom: 16 },
                    },
                },
            },
            childrenItemsContainer: {
                bgColour: grey1Colour,
                verticalGap: 8,
                padding: {
                    top: 10,
                    left: 10,
                    bottom: 10,
                    right: 10,
                },
                margin: {
                    top: 10,
                },
            },
            childItemContainer: {},
            childItem: {
                margin: {
                    top: 16,
                },
                avatar: undefined,
                timestamp: 'inline',
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    reply: {
                        borders: {
                            top: { width: 1, color: grey2Colour },
                        },
                        padding: { top: 4 },
                    },
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'review', 'interactions'],
        childContentFieldOrder: ['meta', 'reply'],
        theme: {
            page: {
                header: {
                    margin: { bottom: 20 },
                    layout: 'center',
                    sticky: true,
                },
                headerDressing: {
                    margin: { bottom: 20 },
                },
                leftSidebar: {
                    margin: { right: 10 },
                    sticky: true,
                },
                main: { width: 650 },
                rightSidebar: {
                    margin: { left: 10 },
                    sticky: true,
                },
                footer: {
                    margin: { top: 20 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                borders: { right: { width: 1, color: grey2Colour }, left: { width: 1, color: grey2Colour } },
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                verticalGap: 16,
            },
            rootItem: {
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                borders: {
                    top: { width: 1, color: grey2Colour },
                    right: { width: 1, color: grey2Colour },
                    bottom: { width: 1, color: grey2Colour },
                    left: { width: 1, color: grey2Colour },
                },
                bgColour: '#ffffff',
                avatar: { position: 'inline' },
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: { alignSelf: 'end', font: { weight: 'BOLD', color: grey3Colour } },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    review: {
                        borders: {
                            top: { width: 1, color: grey2Colour },
                            bottom: { width: 1, color: grey2Colour },
                        },
                        margin: { top: 8, bottom: 8 },
                        padding: { top: 16, bottom: 16 },
                    },
                },
            },
            childrenItemsContainer: {
                bgColour: grey1Colour,
                verticalGap: 8,
                padding: {
                    top: 10,
                    left: 10,
                    bottom: 10,
                    right: 10,
                },
                margin: {
                    top: 10,
                },
            },
            childItemContainer: {},
            childItem: {
                avatar: undefined,
                timestamp: 'inline',
                creator: {
                    username: {},
                },
                meta: {},
                interactions: {},
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                    padding: {
                        top: 10,
                        left: 10,
                        bottom: 10,
                        right: 10,
                    },
                    margin: {
                        top: 5,
                        bottom: 5,
                    },
                },
                fields: {
                    reply: {
                        borders: {
                            top: { width: 1, color: grey2Colour },
                        },
                        padding: { top: 4 },
                    },
                },
            },
        },
    },
};

export default pageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
            interactions: {
                positive: 1566,
                negative: 201,
            },
            data: {
                review: 'WTF! I’m so scared about this #TanzaniaBreach!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
            interactions: {
                positive: 456,
                negative: 23,
            },
            data: {
                reply: "Me too! It's scary",
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
            interactions: {
                positive: 25,
                negative: 235,
            },
            data: {
                reply: 'Who cares!?',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
            interactions: {
                positive: 457,
                negative: 568,
            },
            data: {
                review: 'I don’t care about the Tanzania breach, the government knows everything about me anyway!',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2a',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mike' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid2',
        content: {
            pageUUID: '7eecd3c6-ae57-4df6-a2cd-7bb055ba4991',
            interactions: {
                positive: 13,
                negative: 56,
            },
            data: {
                reply: 'You should be a lot more concerned, this is a private company that holds the information and have dropped the ball on our privacy.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
