import { ReleasedTrigger, Trigger } from 'polpeo-go-common/types/Trigger';
import { reduce, values } from 'ramda';

export const getCurrentTrigger = (triggers: Record<string, Trigger> | Trigger[]): undefined | ReleasedTrigger =>
    reduce(
        (acc, trigger) => {
            if (!trigger.releasedAt) {
                return acc;
            }

            if (!acc) {
                return trigger as ReleasedTrigger;
            }

            if (acc.releasedAt.toISOString() < trigger.releasedAt.toISOString()) {
                return trigger as ReleasedTrigger;
            }

            return acc;
        },
        undefined as undefined | ReleasedTrigger,
        Array.isArray(triggers) ? triggers : values(triggers),
    );
