import { filter, map, sortWith, toPairs } from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { grey1Colour, grey2Colour, primaryColour } from '../../themes/colours';
import { h3Heading } from '../../themes/cssSnippets';
import { Display } from '../bits/Display';
import { Lozenge } from '../bits/Lozenge';

const Table = styled.table`
    text-align: left;
    width: 100%;

    tr {
        padding: 15px;
        border-bottom: 1px solid ${grey1Colour};
    }

    th {
        ${h3Heading}
        padding: 15px;
    }

    td {
        ${h3Heading}
        padding: 20px 15px;
    }
`;

const FilterContainer = styled(Display.HorizontalWithSpacing)`
    margin: 5px 15px;
`;

interface FilterLozengeProps {
    active?: boolean;
}
const FilterLozenge = styled(Lozenge)<FilterLozengeProps>`
    background: ${({ active }) => (active ? primaryColour : grey2Colour)};
    color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
`;

interface AdminTableProps<ItemType> {
    headers: string[] | ((currentFilter: string) => string[]);
    items: ItemType[];
    getItemKey: (x: ItemType) => string;
    getItemCell: (x: ItemType, header: string) => JSX.Element;
    filters?: {
        options: Record<string, string>;
        func: (x: ItemType, currentFilter: string) => boolean;
        default?: string;
        sortWith?: (currentFilter: string) => ((a: ItemType, b: ItemType) => number)[];
    };
}

export const AdminTable: <T>(props: AdminTableProps<T>) => JSX.Element = ({
    headers,
    items,
    getItemKey,
    getItemCell,
    filters,
}) => {
    const [filterValue, setFilterValue] = useState<string>(filters?.default || '');

    const itemList = filters ? filter((item) => filters.func(item, filterValue), items) : items;
    const sortWithFunc = filters?.sortWith;
    const sortedItems = sortWithFunc ? sortWith(sortWithFunc(filterValue), itemList) : items;

    const headerList = typeof headers === 'function' ? headers(filterValue) : headers;

    return (
        <>
            {!!filters && (
                <FilterContainer>
                    {map(
                        ([key, value]) => (
                            <FilterLozenge
                                key={key}
                                as="button"
                                active={filterValue === key}
                                onClick={() => setFilterValue(key)}
                            >
                                {value}
                            </FilterLozenge>
                        ),
                        toPairs(filters.options),
                    )}
                </FilterContainer>
            )}
            <Table>
                <thead>
                    <tr>
                        {map(
                            (header) => (
                                <th key={header}>{header}</th>
                            ),
                            headerList,
                        )}
                    </tr>
                </thead>
                <tbody>
                    {map(
                        (item) => (
                            <tr key={getItemKey(item)}>
                                {map(
                                    (header) => (
                                        <td key={`${getItemKey(item)}_${header}`}>{getItemCell(item, header)}</td>
                                    ),
                                    headerList,
                                )}
                            </tr>
                        ),
                        sortedItems,
                    )}
                </tbody>
            </Table>
        </>
    );
};
