import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import React, { FC, useContext } from 'react';
import { Card } from '../../../../../../patterns/Card';
import { EmbeddedEmailItem } from '../../../../../../patterns/EmbeddedEmailItem';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { StaffUserContext } from '../../../../../../WithStaffUser';
import {
    ManagePrepreparedContentCardFooter,
    ManagePrepreparedContentCardGrid,
    ManagePrepreparedContentCardMain,
    ManagePrepreparedContentCardToolbar,
} from '../utils';
import { NotificationBanner } from '../../../../../../bits/NotificationBanner';
import { DeletePrepreparedEmailButton } from './DeletePrepreparedEmailButton';
import { DuplicatePrepreparedEmailButton } from './DuplicatePrepreparedEmailButton';
import { EditPrepreparedEmailButton } from './EditPrepreparedEmailButton';
import { ReleasePrepreparedEmailButton } from './ReleasePrepreparedEmailButton';

export * from './DeletePrepreparedEmailButton';
export * from './DuplicatePrepreparedEmailButton';
export * from './EditPrepreparedEmailButton';
export * from './NewPrepreparedEmailButton';

interface ManagePrepreparedEmailCardProps {
    item: PrepreparedEmailItem;
}
export const ManagePrepreparedEmailCard: FC<ManagePrepreparedEmailCardProps> = ({ item }) => {
    const staffUser = useContext(StaffUserContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedEmails } = currentSimulationContent;

    const parent = prepreparedEmails[item.parentUUID || ''];

    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);
    const userCanAdministerSimulation =
        !currentSimulation.completedAt && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);

    return (
        <Card>
            <ManagePrepreparedContentCardGrid>
                {userCanWriteSimulationAndContent && (
                    <ManagePrepreparedContentCardToolbar>
                        <EditPrepreparedEmailButton item={item} />
                        <DuplicatePrepreparedEmailButton copyOf={item} />
                        <DeletePrepreparedEmailButton item={item} />
                    </ManagePrepreparedContentCardToolbar>
                )}
                <ManagePrepreparedContentCardMain>
                    {parent && (
                        <NotificationBanner>
                            Replying to <b>{parent.persona}</b>
                        </NotificationBanner>
                    )}
                    <EmbeddedEmailItem item={item} />
                </ManagePrepreparedContentCardMain>
                <ManagePrepreparedContentCardFooter>
                    {item.released && <span>This item was released at: {item.released.at.toISOString()}</span>}
                    {!currentSimulation.completedAt && !item.released && userCanAdministerSimulation && (
                        <ReleasePrepreparedEmailButton item={item} />
                    )}
                </ManagePrepreparedContentCardFooter>
            </ManagePrepreparedContentCardGrid>
        </Card>
    );
};
