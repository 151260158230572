import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { Team } from 'polpeo-go-common/types/Team';
import { filter, find, values } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FullPageError } from '../../../../patterns/FullPageError';
import { AdminInSimulationStateContext } from '../../../../WithAdminInSimulationState';
import { ContentItemOptions } from '../../../PageTemplate/ContentItem';
import { ContentListPage } from '../../../PageTemplate/ContentListPage';
import { pageTemplates } from '../../../PageTemplate/templates';

interface TeamPageContentListProps {
    team: Team;
    contentItemOptions?: ContentItemOptions;
    disableCreateContentItems?: boolean;
}
export const TeamPageContentList: FC<TeamPageContentListProps> = ({
    team,
    contentItemOptions,
    disableCreateContentItems,
}) => {
    const { pageUUID } = useParams<{ pageUUID: string }>();
    const { simulationContent, currentTrigger } = useContext(AdminInSimulationStateContext);

    const pages = values(simulationContent.pages || {});
    const contentItems = values(simulationContent.contentItems[team.uuid]) as ContentItem[];

    const page = find((p) => p.uuid === pageUUID, pages);

    const items = useMemo(() => {
        return page ? filter((item) => item.content.pageUUID === page.uuid, contentItems) : null;
    }, [page, contentItems]);

    if (!page || !items) {
        return <FullPageError errorCode="404" message="This page could not be found" />;
    }

    const template = pageTemplates[page.templateUUID];

    return (
        <ContentListPage
            template={template}
            items={items}
            page={page}
            team={team}
            trigger={currentTrigger}
            contentItemOptions={contentItemOptions}
            disableCreateContentItems={disableCreateContentItems}
        />
    );
};
