import { Trigger } from 'polpeo-go-common/types/Trigger';
import React, { FC, useContext } from 'react';
import { Modal } from '../../../../../patterns/Modal';
import { AdminStateContext } from '../../../../../WithAdminState/adminState';
import { EditTriggerForm } from './EditTriggerForm';

interface EditTriggerModalProps {
    trigger: Trigger;
    disableInputs?: boolean;
    onClose: () => void;
}
export const EditTriggerModal: FC<EditTriggerModalProps> = ({
    trigger,
    disableInputs: overrideDisableInputs,
    onClose,
}) => {
    const { setTrigger } = useContext(AdminStateContext);
    const disableInputs = !!overrideDisableInputs || !!trigger.releasedAt;

    return (
        <Modal cardWidth={900} onModalClose={onClose}>
            <EditTriggerForm
                trigger={trigger}
                disableInputs={disableInputs}
                onBackButton={onClose}
                onTriggerSaved={(savedTrigger) => {
                    setTrigger(savedTrigger);
                    onClose();
                }}
            />
        </Modal>
    );
};
