import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { flatten, head, isNil, map, reduce, reject, split, tail, trim } from 'ramda';
import XLSX from 'xlsx';
import { findStaffWithEmail } from '../../../../../../utils/findStaff';
import getErrorsForTeamsSheet from './getErrorsForTeamsSheet';

export interface UploadedTeam {
    name: string;
    roleplayerUUIDs: string[];
}
interface ParseTeamsSuccessfulResults {
    teams: UploadedTeam[];
    errors: undefined;
}
interface ParseTeamsUnsuccessfulResults {
    teams: undefined;
    errors: string[];
}
type ParseTeamsResults = ParseTeamsSuccessfulResults | ParseTeamsUnsuccessfulResults;

const TeamsUpload = t.array(
    t.exact(
        t.type({
            name: t.string,
            roleplayerEmails: t.array(t.string),
        }),
    ),
);
export const parseTeamsSheet = (sheet: XLSX.WorkSheet, staffUsers: StaffUser[]): ParseTeamsResults => {
    const sheetRows = XLSX.utils.sheet_to_json<string[]>(sheet, { header: 1 });
    const sheetData = reduce<string[], Record<string, unknown>[]>(
        (acc, row) => {
            const teamName = head(row);
            if (!teamName) {
                return acc;
            }
            const rowWithoutEmptyCells = reject(isNil, row);
            const roleplayerEmailCells = map(trim, tail(rowWithoutEmptyCells));
            const roleplayerEmails = map(trim, flatten(map((v) => split(',', v), roleplayerEmailCells)));
            return [...acc, { name: teamName, roleplayerEmails: roleplayerEmails }];
        },
        [],
        sheetRows,
    );
    const maybeTeams = TeamsUpload.decode(sheetData);

    // check for data shape
    if (isLeft(maybeTeams)) {
        console.error('sheet was not the correct team shape', sheetData);
        return {
            teams: undefined,
            errors: getErrorsForTeamsSheet(sheetData, staffUsers),
        };
    }
    const teams = maybeTeams.right;

    // Extra validation
    const errors = getErrorsForTeamsSheet(teams, staffUsers);
    if (errors.length) {
        return {
            teams: undefined,
            errors: errors,
        };
    }

    const teamWithRoleplayerUUIDs = reduce<{ name: string; roleplayerEmails: string[] }, UploadedTeam[]>(
        (acc, team) => [
            ...acc,
            {
                name: team.name,
                roleplayerUUIDs: map((email) => {
                    const staff = findStaffWithEmail(staffUsers, email);
                    if (staff) {
                        return staff.uuid;
                    }
                    throw new Error(`Staff user with email '${email}' could not be found during spreadsheet parsing`);
                }, team.roleplayerEmails),
            },
        ],
        [],
        teams,
    );
    return {
        teams: teamWithRoleplayerUUIDs,
        errors: undefined,
    };
};
