import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import { filter, map, reverse, sortBy, values } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import WithChildren from '../../../../../utils/WithChildren';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { SecondaryButton } from '../../../../bits/Buttons';
import { CardSection } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { H3Heading } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { RichtextDisplay } from '../../../../bits/RichtextDisplay';
import { Card } from '../../../../patterns/Card';
import { MomentsGraph } from '../../../../patterns/MomentsGraph';
import { EmbeddedContentItem } from '../../../PageTemplate/EmbeddedContentItem';
import { TeamOverviewCard } from '../../../TeamOverviewCard';
import { ContentItem } from 'polpeo-go-common/types';
import filterContentItemsForHiddenPages from '../../../../../utils/filterContentItemsForHiddenPages';

const ParticipantSimulationOverviewPageContainer = styled.div`
    flex: 1 1 auto;
    margin: 20px;
`;

const OverviewColumns = styled(Display.HorizontalWithSpacing)`
    grid-template-columns: minmax(100px, 750px) 430px;
    align-items: start;
`;

const TriggerDetailsColumns = styled.div`
    display: flex;
    gap: 20px;

    & > *:first-child {
        flex: 0 0 auto;
    }
    & > * {
        flex: 1 1 auto;
    }
`;

interface OverviewProps extends WithChildren {
    simulation: BasicSimulation;
}

const Overview: FC<OverviewProps> = ({ children, simulation }) => {
    const { showParticipantsMomentsGraph } = simulation;
    if (showParticipantsMomentsGraph) {
        return <OverviewColumns>{children}</OverviewColumns>;
    } else {
        return <Display.HorizontalWithSpacing columns="minmax(100px, 80%)">{children}</Display.HorizontalWithSpacing>;
    }
};

export const ParticipantSimulationOverview = (): React.ReactElement => {
    const history = useHistory();
    const {
        triggers = [],
        moments: momentsMap,
        participantTeam,
        contentItems = {},
        getLinkUrlForContentItem,
        simulation,
        pages,
    } = useContext(ParticipantStateContext);

    if (!participantTeam) {
        throw new Error('Participant team could not be found');
    }

    const filteredContentItems = filterContentItemsForHiddenPages({
        contentItems: values(contentItems),
        pages,
        teamUUID: participantTeam.uuid,
    });

    const triggersToList = useMemo(() => {
        const triggersWithParticipantPopup = filter((trigger) => trigger.participantPopupSettings.enabled, triggers);
        const orderedTriggers = sortBy(
            (trigger) => (trigger.releasedAt ? trigger.releasedAt.toISOString() : '0'),
            triggersWithParticipantPopup,
        );
        return reverse(orderedTriggers);
    }, [triggers]);

    const moments = useMemo(() => {
        return values(momentsMap || {});
    }, [momentsMap]);

    // Don't just check that the overview item is empty because I want to know the actual values are
    // also not empty!
    const hasOverview =
        participantTeam.overview?.title ||
        participantTeam.overview?.description ||
        participantTeam.overview?.headerImage;

    return (
        <ParticipantSimulationOverviewPageContainer>
            <Overview simulation={simulation}>
                <Display.VerticalWithSpacing>
                    {hasOverview && <TeamOverviewCard team={participantTeam} />}
                    {!!triggersToList.length && (
                        <Card>
                            {map((trigger) => {
                                const prepreparedContentItemUUID =
                                    trigger.participantPopupSettings.prepreparedContentItemUUID;

                                const contentItemsToDisplay = prepreparedContentItemUUID
                                    ? filter(
                                          (item) =>
                                              prepreparedContentItemUUID.includes(item.prepreparedContentUUID || ''),
                                          filteredContentItems,
                                      )
                                    : undefined;

                                const navigateToDisplayedContentItem = (item: ContentItem) => {
                                    if (item) {
                                        const contentItemLink = getLinkUrlForContentItem(item);
                                        history.push(contentItemLink);
                                    }
                                };
                                return (
                                    <CardSection>
                                        <TriggerDetailsColumns key={trigger.uuid}>
                                            <Icon icon="attention" size={45} />
                                            <Display.VerticalWithSpacing gap={7} verticalAlign="center">
                                                <H3Heading>
                                                    {trigger.title}
                                                    {trigger.simulatedTime && ` - ${trigger.simulatedTime}`}
                                                </H3Heading>
                                                {trigger.participantPopupSettings.text && (
                                                    <RichtextDisplay value={trigger?.participantPopupSettings.text} />
                                                )}
                                                {contentItemsToDisplay && (
                                                    <Display.VerticalWithSpacing>
                                                        {contentItemsToDisplay.map((item) => (
                                                            <Display.VerticalWithSpacing
                                                                key={item.prepreparedContentUUID}
                                                            >
                                                                <Card minCardWidth={150}>
                                                                    <EmbeddedContentItem
                                                                        key="Item Preview"
                                                                        item={item}
                                                                        mode="DETAILS"
                                                                    />
                                                                </Card>
                                                                <Display.HorizontalWithSpacing horizontalAlign="end">
                                                                    <SecondaryButton
                                                                        onClick={() =>
                                                                            navigateToDisplayedContentItem(item)
                                                                        }
                                                                    >
                                                                        View Post
                                                                    </SecondaryButton>
                                                                </Display.HorizontalWithSpacing>
                                                            </Display.VerticalWithSpacing>
                                                        ))}
                                                    </Display.VerticalWithSpacing>
                                                )}
                                            </Display.VerticalWithSpacing>
                                        </TriggerDetailsColumns>
                                    </CardSection>
                                );
                            }, triggersToList)}
                        </Card>
                    )}
                </Display.VerticalWithSpacing>
                {simulation.showParticipantsMomentsGraph && (
                    <Card cardWidth={430}>
                        <MomentsGraph moments={moments} />
                    </Card>
                )}
            </Overview>
        </ParticipantSimulationOverviewPageContainer>
    );
};
