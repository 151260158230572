/* eslint-disable max-len */
import { DateTime } from 'luxon';
import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import {
    ContentItemsDetailsPageLayoutType,
    ContentItemsListingPageLayoutType,
    PageTemplate,
} from 'polpeo-go-common/types/PageTemplate';
import { ContentItemFieldType } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { grey1Colour, grey3Colour } from '../../../../../themes/colours';
import videoThumbnail from './videoThumbnail.png';
import logo from './logo.png';
import positiveSocial from './positive-social.svg';
import { assocPath, pipe } from 'ramda';

const basePageTemplate: PageTemplate = {
    uuid: '',
    name: ' ',
    category: 'Social Media',
    icon: logo,

    rootItemOptions: {
        name: 'Post',
        requiredFields: [],
        fields: [],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: { enabled: false },
            shares: {
                enabled: true,
                displayName: 'shares',
                img: undefined,
            },
        },
    },
    childItemOptions: {
        name: 'Reply',
        pluralName: 'Replies',
        requiredFields: [],
        fields: [],
        interactions: {
            positive: {
                enabled: true,
                displayName: undefined,
                img: positiveSocial,
            },
            negative: { enabled: false },
            shares: {
                enabled: true,
                displayName: 'shares',
                img: undefined,
            },
        },
    },
    childrenSettings: { enabled: true, maxDepth: 10 },
    listingPage: {
        layout: { type: ContentItemsListingPageLayoutType.LIST },
        childrenSettings: {
            location: 'inline',
            max: 100,
        },
        sortBy: 'ItemCreatedDescending',
        rootContentFieldOrder: ['meta', 'post', 'image', 'video', 'interactions'],
        childContentFieldOrder: ['meta', 'post', 'video', 'image', 'interactions'],
        detailsLinkFields: ['meta'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: false,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 0 },
                    layout: 'center',
                },
                leftSidebar: {
                    margin: { right: 10 },
                },
                main: {
                    padding: { top: 16, bottom: 16 },
                },
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 0 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 600,
                verticalGap: 0,
                borders: {
                    top: { width: 1, color: grey1Colour },
                    right: { width: 1, color: grey1Colour },
                    left: { width: 1, color: grey1Colour },
                },
            },
            rootItem: {
                borders: { bottom: { width: 1, color: grey1Colour } },
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {
                    font: {
                        color: grey3Colour,
                    },
                    margin: { bottom: 0 },
                },
                interactions: {
                    layout: 'space-between',
                    margin: { top: 8 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    post: { margin: { bottom: 8 } },
                    image: { margin: { bottom: 8 }, borderRadius: 10 },
                    video: { width: 518, margin: { bottom: 8 }, borderRadius: 10 },
                },
                showChildItemsCount: {
                    position: 'inline with interactions',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                },
            },
            childrenItemsContainer: {
                borders: { top: { width: 1, color: '#dddddd' } },
                margin: { top: 15 },
                padding: { top: 15 },
                verticalGap: 12,
            },
            childItemContainer: {},
            childItem: {
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {},
                interactions: {
                    horizontalGap: 10,
                    iconHeight: 12,
                    padding: { top: 4, left: 0, bottom: 0, right: 0 },
                    margin: { bottom: 10 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    post: {},
                },
            },
        },
    },
    detailsPage: {
        layout: { type: ContentItemsDetailsPageLayoutType.LIST },
        childrenSettings: {
            location: 'append',
        },
        sortBy: 'ItemCreatedAscending',
        rootContentFieldOrder: ['meta', 'post', 'image', 'video', 'interactions'],
        childContentFieldOrder: ['meta', 'post', 'image', 'video', 'interactions'],
        theme: {
            page: {
                pageDressingAsPageImages: {
                    header: true,
                    leftSidebar: true,
                    rightSidebar: true,
                    footer: true,
                },
                bgColour: '#ffffff',
                header: {
                    margin: { bottom: 0 },
                    layout: 'center',
                },
                leftSidebar: {
                    margin: { right: 10 },
                },
                main: {},
                rightSidebar: {
                    margin: { left: 10 },
                },
                footer: {
                    margin: { top: 0 },
                    layout: 'center',
                },
            },
            rootItemsContainer: {
                width: 600,
                verticalGap: 0,
                borders: {
                    top: { width: 1, color: grey1Colour },
                    right: { width: 1, color: grey1Colour },
                    left: { width: 1, color: grey1Colour },
                },
            },
            rootItem: {
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                borders: { bottom: { width: 1, color: grey1Colour } },
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {
                    font: {
                        color: grey3Colour,
                    },
                    margin: { bottom: 0 },
                },
                interactions: {
                    layout: 'space-between',
                    margin: { top: 8 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    post: { margin: { bottom: 8 } },
                    image: { margin: { bottom: 8 }, borderRadius: 10 },
                    video: { width: 518, margin: { bottom: 8 }, borderRadius: 10 },
                },
                showChildItemsCount: {
                    position: 'inline with interactions',
                    font: {
                        size: 'SMALL',
                        weight: 'BOLD',
                    },
                },
            },
            childrenItemsContainer: {
                verticalGap: 0,
                borders: {
                    right: { width: 5, color: grey1Colour },
                    bottom: { width: 1, color: grey1Colour },
                    left: { width: 5, color: grey1Colour },
                    top: { width: 5, color: grey1Colour },
                },
            },
            childItemContainer: {
                width: 580,
                borders: {
                    bottom: { width: 1, color: grey1Colour },
                },
            },
            childItem: {
                padding: { top: 16, right: 16, bottom: 16, left: 16 },
                avatar: { position: 'gutter' },
                timestamp: 'inline',
                creator: {
                    username: {
                        font: { weight: 'BOLD' },
                    },
                },
                meta: {
                    font: {
                        color: grey3Colour,
                    },
                    margin: { bottom: 0 },
                },
                interactions: {
                    horizontalGap: 150,
                    margin: { top: 8 },
                },
                deletedPlaceholder: {
                    bgColour: '#eeeeee',
                    borderRadius: 10,
                    borders: {
                        top: { width: 1, color: '#dddddd' },
                        left: { width: 1, color: '#dddddd' },
                        bottom: { width: 1, color: '#dddddd' },
                        right: { width: 1, color: '#dddddd' },
                    },
                },
                fields: {
                    post: { margin: { bottom: 8 } },
                    image: { margin: { bottom: 8 }, borderRadius: 10 },
                    video: { width: 518, margin: { bottom: 8 }, borderRadius: 10 },
                },
            },
        },
    },
};

const sharedItemOptionsWithoutMaxLength = [
    { name: 'post', type: ContentItemFieldType.LONG_TEXT },
    { name: 'video', type: ContentItemFieldType.VIDEO },
    { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
];

export const pageTemplateWithoutMaxLength: PageTemplate = pipe(
    assocPath(['uuid'], '5c3c7e2c-769d-4a9d-ab9b-bf7e96bc4e67'),
    assocPath(['name'], 'Microblog without character limit'),
    assocPath(['rootItemOptions', 'fields'], sharedItemOptionsWithoutMaxLength),
    assocPath(['childItemOptions', 'fields'], sharedItemOptionsWithoutMaxLength),
)(basePageTemplate) as PageTemplate;

const sharedItemOptionsMaxLength = [
    { name: 'post', type: ContentItemFieldType.LONG_TEXT, options: { maxLength: 280 } },
    { name: 'video', type: ContentItemFieldType.VIDEO },
    { name: 'image', displayName: 'images and gifs', type: ContentItemFieldType.IMAGE },
];

export const pageTemplateWithMaxLength: PageTemplate = pipe(
    assocPath(['uuid'], 'd4146128-447f-4f69-8c06-2c02f1237750'),
    assocPath(['name'], 'Microblog with 280 character limit'),
    assocPath(['rootItemOptions', 'fields'], sharedItemOptionsMaxLength),
    assocPath(['childItemOptions', 'fields'], sharedItemOptionsMaxLength),
)(basePageTemplate) as PageTemplate;

export const dummyContentItems: ContentItem[] = [
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ hours: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                post: 'Lorem ipsum dolor sit amet',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                image: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuida',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'marie' },
        createdAt: DateTime.now().minus({ hours: 2 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 93,
                negative: 72,
            },
            data: {
                post: 'Morbi venenatis turpis mi, non fermentum sapien condimentum ultricies',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuid',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 21,
                negative: 3,
            },
            data: {
                post: 'Phasellus a odio feugiat, tempor turpis et, fermentum neque',
                video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidba',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 39,
                negative: 19,
            },
            data: {
                post: 'Vestibulum eleifend eget est in fringilla',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbb',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidb',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 45,
                negative: 23,
            },
            data: {
                post: 'Suspendisse vehicula facilisis enim, ut ultrices erat tempus sit amet. Donec aliquam tristique risus, facilisis finibus quam elementum vel.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuidbaa',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 1 }).toJSDate(),
        parentUUID: 'uuidba',
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {
                positive: 20,
                negative: 26,
            },
            data: {
                post: 'Vivamus aliquam mi at fringilla sagittis. In vehicula lorem eget lorem pretium ultrices. Nam tellus dolor, convallis ut semper a, aliquet ac tortor. Cras auctor vel ligula eu consequat. Vivamus venenatis convallis nulla, ut porta nisi vestibulum nec. Nunc nisl nisi, pulvinar in imperdiet sit amet, hendrerit et eros.',
                image: videoThumbnail,
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid2',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'donald' },
        createdAt: DateTime.now().minus({ hours: 12 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                post: 'Bupa dup dup dop',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid3',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                post: 'In vehicula lorem eget lorem pretium ultrices.',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid4',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 3, hours: 11 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                post: 'In hac habitasse platea dictumst',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
    {
        type: 'PAGE_CONTENT',
        uuid: 'uuid5',
        simulationUUID: '',
        teamUUID: '',
        createdBy: { type: 'PARTICIPANT' as const, uuid: 'mary' },
        createdAt: DateTime.now().minus({ days: 4 }).toJSDate(),
        parentUUID: undefined,
        content: {
            pageUUID: '854d376c-327a-4d1f-8119-e68c817374d7',
            interactions: {},
            data: {
                post: 'Sed tincidunt dapibus odio vel pellentesque',
            },
        },
        persona: undefined,
        prepreparedContentUUID: undefined,
        deleted: undefined,
    },
];
