import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import React, { FC, useEffect, useState } from 'react';
import { SecondaryButton } from '../../../bits/Buttons';
import { DeleteModal } from '../../DeleteModal';
import styled from 'styled-components/macro';
import { Display } from '../../../bits/Display';
import { resetStaffUserMfa } from '../../../../graphql/staffusers/resetStaffUserMfa';

const ResetButton = styled(SecondaryButton)`
    display: inline;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.9px;
    line-height: 16px;
    padding: 0px 10px 0 10px;
    border: 1px solid;
`;

interface ResetStaffUserMFAButtonProps {
    staffUser: StaffUser;
    setError: (error: string) => void;
}
export const ResetStaffUserMfaButton: FC<ResetStaffUserMFAButtonProps> = ({ staffUser, setError }) => {
    const [showModal, setShowModal] = useState(false);

    const [
        ResetStaffUserMfaMutation,
        { data: resetStaffUserMfaData, loading: resetStaffUserMfaLoading, error: resetStaffUserMfaError },
    ] = resetStaffUserMfa.hook();

    useEffect(() => {
        if (resetStaffUserMfaData && !resetStaffUserMfaError) {
            setShowModal(false);
        }
        if (resetStaffUserMfaError) {
            setError('There was an error when resetting the users MFA');
        }
    }, [resetStaffUserMfaData, resetStaffUserMfaError]);

    return (
        <>
            <Display.VerticalWithSpacing gap={10} horizontalAlign="start">
                <ResetButton
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                    }}
                >
                    Reset MFA
                </ResetButton>
                {resetStaffUserMfaData && !resetStaffUserMfaLoading && <div>The user&apos;s MFA has been reset</div>}
            </Display.VerticalWithSpacing>
            {showModal && (
                <DeleteModal
                    header="Reset MFA"
                    deleteCTA="Reset"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={`Are you sure you want to reset the MFA for "${staffUser.name}"?`}
                    onConfirmDelete={() =>
                        ResetStaffUserMfaMutation({
                            variables: { staffUserUUID: staffUser.uuid },
                        })
                    }
                    showSpinner={resetStaffUserMfaLoading}
                />
            )}
        </>
    );
};
