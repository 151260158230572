import { Team } from 'polpeo-go-common/types/Team';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { SecondaryButton } from '../bits/Buttons';
import { CardSection } from '../bits/Card';
import { Display } from '../bits/Display';
import { Body1, H2Heading } from '../bits/Headers';
import { Card, CardProps } from '../patterns/Card';
import { EditTeamOverviewModal } from './EditTeamOverviewModal';

const HeroCardSection = styled(CardSection)`
    padding: 0;

    display: flex;
    justify-content: center;

    overflow: hidden;
    img {
        width: auto;
        max-height: 750px;
    }
`;

interface TeamOverviewCardProps {
    team: Team;
    card?: CardProps;
    canEdit?: boolean;
}
export const TeamOverviewCard: FC<TeamOverviewCardProps> = ({ team, card, canEdit }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Card {...card}>
            {team.overview?.headerImage && (
                <HeroCardSection>
                    <img src={team.overview?.headerImage} />
                </HeroCardSection>
            )}
            {(team.overview?.title || team.overview?.description) && (
                <CardSection>
                    {team.overview?.title && <H2Heading>{team.overview?.title}</H2Heading>}
                    {team.overview?.description && <Body1>{team.overview?.description}</Body1>}
                </CardSection>
            )}
            {canEdit && (
                <>
                    <CardSection>
                        <Display.RightAlign>
                            <SecondaryButton small onClick={() => setShowModal(true)}>
                                Edit
                            </SecondaryButton>
                        </Display.RightAlign>
                    </CardSection>
                    {showModal && <EditTeamOverviewModal onModalClose={() => setShowModal(false)} team={team} />}
                </>
            )}
        </Card>
    );
};
