import { DateTime } from 'luxon';
import { PermissionKey, userCan } from 'polpeo-go-common/permissions';
import { decodePrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import { decodePrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { Trigger } from 'polpeo-go-common/types/Trigger';
import { forEach, insert, map, pluck, remove } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { putTriggerOrder } from '../../../../../graphql/triggers/putTriggerOrder';
import { releaseTrigger } from '../../../../../graphql/triggers/releaseTrigger';
import { grey1Colour, grey2Colour, grey4Colour, grey6Colour } from '../../../../../themes/colours';
import { h1Heading, h3Heading } from '../../../../../themes/cssSnippets';
import { dateTimestampRelativeToSimStart } from '../../../../../utils/dateTimestampRelativeToSimStart';
import { PrimaryButton, SecondaryButton } from '../../../../bits/Buttons';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { Display } from '../../../../bits/Display';
import { Body2, H2Heading, StyledErrorMessage } from '../../../../bits/Headers';
import { Icon } from '../../../../bits/Icon';
import { Lozenge } from '../../../../bits/Lozenge';
import { RichtextDisplayOverflowStyling } from '../../../../bits/RichtextDisplay';
import { Spinner } from '../../../../bits/Spinner';
import { Modal } from '../../../../patterns/Modal';
import { AdminManageSimulationContext } from '../../../../WithAdminManageSimulationState/adminManageSimulationState';
import { AdminStateContext } from '../../../../WithAdminState/adminState';
import { StaffUserContext } from '../../../../WithStaffUser';
import { DeleteTriggerButton } from './DeleteTriggerButton';
import { NewTriggerButton } from './NewTriggerButton';

const Container = styled(Display.VerticalWithSpacing).attrs(() => ({ horizontalAlign: 'start' }))`
    flex: 1 1 auto;
`;

const TriggerList = styled.ol`
    width: 100%;

    display: grid;
    grid-auto-flow: row;
    row-gap: 10px;
`;

interface TriggerRowContainerProps {
    indent?: boolean;
}
const TriggerRowContainer = styled(Display.HorizontalWithSpacing)<TriggerRowContainerProps>`
    background: ${grey1Colour};
    grid-template-columns: 150px 1fr;
    margin-left: ${({ indent }) => (indent ? '10px' : '0')};
`;

const TriggerRowLabel = styled.div`
    ${h1Heading}
    margin: 20px 15px;
`;

const TriggerDetailsCard = styled(Display.VerticalWithSpacing).attrs(() => ({ gap: 0, verticalAlign: 'center' }))`
    background: #ffffff;
    padding: 7px 20px;
    margin: 7px;
`;

const TriggerDetailsRow = styled(Display.HorizontalWithSpacing).attrs(() => ({ verticalCenter: true }))`
    grid-template-columns: 450px 1fr max-content;

    ${h3Heading}
    font-weight: 400;

    div:first-child {
        grid-template-columns: auto max-content max-content;

        span,
        a {
            white-space: nowrap;
        }
        a {
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
        }
    }

    div:nth-child(2) {
        color: ${grey4Colour};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

interface TriggerStatusLozengeProp {
    trigger: Trigger;
    simulation: Simulation;
}
const TriggerStatusLozenge: FC<TriggerStatusLozengeProp> = ({ trigger, simulation }) => {
    if (!trigger.releasedAt) {
        return <div />;
    }

    return (
        <Lozenge small color={grey4Colour}>
            {`TRIGGER RELEASED: ${
                !simulation.completedAt
                    ? DateTime.fromJSDate(trigger.releasedAt).toFormat('HH:mm')
                    : dateTimestampRelativeToSimStart(simulation, trigger.releasedAt)
            }`}
        </Lozenge>
    );
};

interface TriggerRowProps {
    label: string;
    trigger: Trigger;
    incTriggerOrder?: (trigger: Trigger) => void;
    decTriggerOrder?: (trigger: Trigger) => void;
}
const TriggerRow: FC<TriggerRowProps> = ({ label, trigger, incTriggerOrder, decTriggerOrder }) => {
    const staffUser = useContext(StaffUserContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedContents, prepreparedEmails } = currentSimulationContent;
    const { setTrigger, setPrepreparedContent, setPrepreparedEmail } = useContext(AdminStateContext);
    const [releaseTriggerMutation, { data: ReleaseTriggerData, loading: ReleaseTriggerLoading }] =
        releaseTrigger.hook();
    const [showTriggerWarningModal, setShowTriggerWarningModal] = useState(false);

    useEffect(() => {
        if (ReleaseTriggerData?.releaseTrigger) {
            const updatedPrepreparedItems = ReleaseTriggerData.releaseTrigger.items;
            forEach(
                (prepreparedItem) => {
                    if (prepreparedItem.type === 'PAGE_CONTENT') {
                        const originalItem = prepreparedContents[prepreparedItem.uuid];
                        if (originalItem) {
                            setPrepreparedContent(
                                decodePrepreparedContentItem({
                                    ...originalItem,
                                    released: prepreparedItem.released
                                        ? {
                                              ...prepreparedItem.released,
                                              items: JSON.parse(prepreparedItem.released.items),
                                          }
                                        : undefined,
                                }),
                            );
                        }
                    } else {
                        const originalItem = prepreparedEmails[prepreparedItem.uuid];
                        if (originalItem) {
                            setPrepreparedEmail(
                                decodePrepreparedEmailItem({
                                    ...originalItem,
                                    released: prepreparedItem.released
                                        ? {
                                              ...prepreparedItem.released,
                                              items: JSON.parse(prepreparedItem.released.items),
                                          }
                                        : undefined,
                                }),
                            );
                        }
                    }
                },

                updatedPrepreparedItems,
            );

            const updatedTrigger = ReleaseTriggerData.releaseTrigger.trigger;
            setTrigger({
                ...updatedTrigger,
                pageDressingChanges: JSON.parse(updatedTrigger.pageDressingChanges),
            });
            setShowTriggerWarningModal(false);
        }
    }, [ReleaseTriggerData]);

    const canReleaseTriggers =
        !currentSimulation.completedAt && userCan(PermissionKey.ADMINISTER_SIMULATIONS, staffUser);
    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);
    return (
        <TriggerRowContainer indent={trigger.isOptional}>
            {showTriggerWarningModal && (
                <Modal header="Release Trigger" cardWidth={500}>
                    {ReleaseTriggerLoading && (
                        <Display.HorizontalCenterVerticalCenter>
                            <Spinner />
                        </Display.HorizontalCenterVerticalCenter>
                    )}
                    {!ReleaseTriggerLoading && (
                        <Display.VerticalWithSpacing>
                            <p>
                                You are about to release trigger: <b>{trigger.title}</b>.
                            </p>
                            <Body2 destructive>
                                <b>
                                    Leaving or refreshing the page before this action has been completed will prevent
                                    the trigger from updating correctly!
                                </b>
                            </Body2>
                            <Display.RightAlign>
                                <Display.HorizontalWithSpacing>
                                    <SecondaryButton onClick={() => setShowTriggerWarningModal(false)}>
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton
                                        onClick={() =>
                                            releaseTriggerMutation({
                                                variables: {
                                                    trigger: {
                                                        ...trigger,
                                                        pageDressingChanges: JSON.stringify(
                                                            trigger.pageDressingChanges,
                                                        ),
                                                    },
                                                },
                                            })
                                        }
                                    >
                                        Ok
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.RightAlign>
                        </Display.VerticalWithSpacing>
                    )}
                </Modal>
            )}
            <TriggerRowLabel>{label}</TriggerRowLabel>
            <TriggerDetailsCard>
                <TriggerDetailsRow>
                    <Display.HorizontalWithSpacing gap={10}>
                        <Link to={`/admin/simulation/${trigger.simulationUUID}/manage/triggers/edit/${trigger.uuid}`}>
                            {trigger.title}
                        </Link>
                        {trigger.simulatedTime && <span>({trigger.simulatedTime})</span>}
                        <Display.HorizontalWithSpacing gap={0}>
                            {trigger.participantPopupSettings.prepreparedContentItemUUID && (
                                <Icon icon="content" size={20} />
                            )}
                            {trigger.participantPopupSettings.enabled && <Icon icon="popup" size={20} />}
                        </Display.HorizontalWithSpacing>
                    </Display.HorizontalWithSpacing>
                    <RichtextDisplayOverflowStyling value={trigger.participantPopupSettings.text || ''} />
                    {userCanWriteSimulationAndContent && (
                        <Display.HorizontalWithSpacing gap={0}>
                            <IconButton
                                icon="upArrow"
                                disabled={!incTriggerOrder}
                                fill={!incTriggerOrder ? grey2Colour : grey6Colour}
                                onClick={() => {
                                    if (incTriggerOrder) {
                                        incTriggerOrder(trigger);
                                    }
                                }}
                            />
                            <IconButton
                                icon="downArrow"
                                disabled={!decTriggerOrder}
                                fill={!decTriggerOrder ? grey2Colour : grey6Colour}
                                onClick={() => {
                                    if (decTriggerOrder) {
                                        decTriggerOrder(trigger);
                                    }
                                }}
                            />
                            {!trigger.releasedAt && <DeleteTriggerButton trigger={trigger} />}
                        </Display.HorizontalWithSpacing>
                    )}
                </TriggerDetailsRow>
                <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                    <TriggerStatusLozenge trigger={trigger} simulation={currentSimulation} />
                    {canReleaseTriggers && !trigger.releasedAt && (
                        <PrimaryButton
                            small
                            disabled={ReleaseTriggerLoading}
                            onClick={() => setShowTriggerWarningModal(true)}
                        >
                            <span>Release this Trigger</span>
                            {ReleaseTriggerLoading && <Spinner size={20} />}
                        </PrimaryButton>
                    )}
                </Display.HorizontalWithMaxSpaceBetween>
            </TriggerDetailsCard>
        </TriggerRowContainer>
    );
};

const makeTriggerRows = (triggers: Trigger[], updateTriggerOrder: (trigger: Trigger, position: number) => void) => {
    const incTriggerOrder = (trigger: Trigger) => {
        const newTriggerIndex = triggers.findIndex((item) => item.uuid == trigger.uuid) - 1;
        updateTriggerOrder(trigger, newTriggerIndex);
    };
    const decTriggerOrder = (trigger: Trigger) => {
        const newTriggerIndex = triggers.findIndex((item) => item.uuid == trigger.uuid) + 1;
        updateTriggerOrder(trigger, newTriggerIndex);
    };

    let triggerCounter = 0;
    let labelCounter = 0;
    return map((trigger) => {
        triggerCounter += 1;
        labelCounter += trigger.isOptional ? 0 : 1;
        return (
            <li key={trigger.uuid}>
                <TriggerRow
                    label={trigger.isOptional ? 'Optional' : `Trigger ${labelCounter}`}
                    trigger={trigger}
                    incTriggerOrder={triggerCounter > 1 ? incTriggerOrder : undefined}
                    decTriggerOrder={triggerCounter < triggers.length ? decTriggerOrder : undefined}
                />
            </li>
        );
    }, triggers);
};

export const TriggersListPage: FC = () => {
    const staffUser = useContext(StaffUserContext);
    const { setTriggerOrder } = useContext(AdminStateContext);
    const { currentSimulation, currentSimulationContent } = useContext(AdminManageSimulationContext);

    const orderedTriggers = useMemo(
        () => map((uuid) => currentSimulationContent.triggers[uuid], currentSimulationContent.triggerOrder || []),
        [currentSimulationContent.triggers, currentSimulationContent.triggerOrder],
    );
    const [
        putTriggerOrderMutation,
        { data: putTriggerOrderData, error: putTriggerOrderError, loading: putTriggerOrderLoading },
    ] = putTriggerOrder.hook();

    useEffect(() => {
        if (putTriggerOrderData?.putTriggerOrder) {
            setTriggerOrder(currentSimulation.uuid, putTriggerOrderData.putTriggerOrder);
        }
    }, [putTriggerOrderData]);

    const updateTriggerOrder = (trigger: Trigger, position: number) => {
        const triggerIndex = orderedTriggers.findIndex((item) => item.uuid == trigger.uuid);
        const updatedTriggerOrder = insert(position, trigger, remove(triggerIndex, 1, orderedTriggers));

        putTriggerOrderMutation({
            variables: { simulationUUID: currentSimulation.uuid, triggerOrder: pluck('uuid', updatedTriggerOrder) },
        });
    };

    const userCanWriteSimulationAndContent =
        !currentSimulation.completedAt && userCan(PermissionKey.WRITE_SIMULATIONS_AND_PREPREPARED_CONTENT, staffUser);

    return (
        <Container>
            <Display.HorizontalWithSpacing verticalCenter>
                <H2Heading>Triggers</H2Heading>
                {userCanWriteSimulationAndContent && <NewTriggerButton />}
                {putTriggerOrderLoading && <Spinner height={20} width={20} />}
            </Display.HorizontalWithSpacing>
            {putTriggerOrderError && <StyledErrorMessage>Error occured during trigger reorder</StyledErrorMessage>}
            <TriggerList>{makeTriggerRows(orderedTriggers, updateTriggerOrder)}</TriggerList>
        </Container>
    );
};
