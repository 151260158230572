import { BaseThemingOptions } from 'polpeo-go-common/types/PageTemplate/BaseThemingOptions';
import { always, cond, path, pathEq, T } from 'ramda';
import { css } from 'styled-components/macro';

export interface BaseThemingSnippetProps {
    theme: BaseThemingOptions;
}
export const baseThemingSnippet = css<BaseThemingSnippetProps>`
    width: ${({ theme }) => (theme.width ? `${theme.width}px` : '')};
    ${({ theme }) => (theme.width && !theme.maxWidth ? 'flex: 0 0 auto;' : '')}
    ${({ theme }) => (theme.maxWidth ? `max-width: ${path(['maxWidth'], theme)}px;` : '')}
    height: ${({ theme }) => (theme.height ? `${theme.height}px` : '')};
    background: ${({ theme: { bgColour } }) => bgColour || 'none'};

    padding: ${({ theme: { padding } }) =>
        padding ? `${padding.top || 0}px ${padding.right || 0}px ${padding.bottom || 0}px ${padding.left || 0}px` : 0};
    margin: ${({ theme: { margin } }) =>
        margin ? `${margin.top || 0}px ${margin.right || 0}px ${margin.bottom || 0}px ${margin.left || 0}px` : 0};

    color: ${({ theme }) => theme.font?.color || 'currentColor'};
    font-size: ${({ theme }) =>
        cond<BaseThemingOptions, string>([
            [pathEq(['font', 'size'], 'SMALL'), always('12px')],
            [pathEq(['font', 'size'], 'MEDIUM'), always('14px')],
            [pathEq(['font', 'size'], 'LARGE'), always('18px')],
            [pathEq(['font', 'size'], 'EXTRA_LARGE'), always('26px')],
            [T, always('inherit')],
        ])(theme)};
    font-weight: ${({ theme }) =>
        cond<BaseThemingOptions, string>([
            [pathEq(['font', 'weight'], 'NORMAL'), always('400')],
            [pathEq(['font', 'weight'], 'BOLD'), always('700')],
            [T, always('inherit')],
        ])(theme)};

    box-shadow: ${({ theme }) => (theme.shadow ? '0 3px 6px 0 #00000029' : 'none')};
    border-radius: ${({ theme }) => (theme.borderRadius ? `${theme.borderRadius}px` : 0)};

    border-top-width: ${({ theme }) => `${path(['borders', 'top', 'width'], theme) || 0}px`};
    border-top-style: ${({ theme }) => `${path(['borders', 'top', 'style'], theme) || 'solid'}`};
    border-top-color: ${({ theme }) => `${path(['borders', 'top', 'color'], theme) || '#000000'}`};

    border-right-width: ${({ theme }) => `${path(['borders', 'right', 'width'], theme) || 0}px`};
    border-right-style: ${({ theme }) => `${path(['borders', 'right', 'style'], theme) || 'solid'}`};
    border-right-color: ${({ theme }) => `${path(['borders', 'right', 'color'], theme) || '#000000'}`};

    border-bottom-width: ${({ theme }) => `${path(['borders', 'bottom', 'width'], theme) || 0}px`};
    border-bottom-style: ${({ theme }) => `${path(['borders', 'bottom', 'style'], theme) || 'solid'}`};
    border-bottom-color: ${({ theme }) => `${path(['borders', 'bottom', 'color'], theme) || '#000000'}`};

    border-left-width: ${({ theme }) => `${path(['borders', 'left', 'width'], theme) || 0}px`};
    border-left-style: ${({ theme }) => `${path(['borders', 'left', 'style'], theme) || 'solid'}`};
    border-left-color: ${({ theme }) => `${path(['borders', 'left', 'color'], theme) || '#000000'}`};

    ${({ theme }) => (theme.alignSelf ? `align-self: ${path(['alignSelf'], theme)};` : '')}
    ${({ theme }) => (theme.objectFit ? `object-fit: ${path(['objectFit'], theme)};` : '')}
`;
