import { EmailItem } from 'polpeo-go-common/types/EmailItem';
import { assoc } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FloatingActionButton } from '../../../../patterns/FAB/FloatingActionButton';
import { ParticipantStateContext } from '../../../../WithParticipantState';
import { ParticipantUserContext } from '../../../../WithParticipantUser';
import { EmailFABMenu } from '../../../Email';
import { useThreadBuilder } from '../../../../hooks/useThreadBuilder';
import { useFabButtonPositioner } from '../../../../hooks/useFabButtonPositioner';
import { IconButton } from '../../../../bits/Buttons/IconButton';
import { CardHeader } from '../../../../bits/Card';
import { Display } from '../../../../bits/Display';
import { H2Heading } from '../../../../bits/Headers';
import { NewEmailItemButton } from '../../../Email/NewEmailItemButton';

export const ParticipantEmailFAB: FC = () => {
    const { participantUser } = useContext(ParticipantUserContext);
    const {
        emailItems: emailItemsMap = {},
        notifications: { emails: emailNotification },
        setNotifications,
    } = useContext(ParticipantStateContext);

    const [openMenu, setOpenMenu] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState<EmailItem | undefined>();
    const { threads, emailUUIDToThreadMap, inboxEmails, outboxEmails } = useThreadBuilder(
        emailItemsMap,
        participantUser.id,
    );

    const { rightPosition, bottomPosition, fabButtonRef } = useFabButtonPositioner();

    useEffect(() => {
        if (emailNotification && openMenu) {
            setNotifications(assoc('emails', false));
        }
    }, [emailNotification, openMenu]);

    useEffect(() => {
        if (!openMenu) {
            setSelectedEmail(undefined);
        }
    }, [openMenu]);

    return (
        <>
            <FloatingActionButton
                ref={fabButtonRef}
                icon="email"
                onClick={() => setOpenMenu(!openMenu)}
                notification={emailNotification}
            />
            {openMenu && (
                <EmailFABMenu
                    onClose={() => setOpenMenu(false)}
                    threads={threads}
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                    emailUUIDToThreadMap={emailUUIDToThreadMap}
                    inboxEmails={inboxEmails}
                    outboxEmails={outboxEmails}
                    menuPosition={{ right: rightPosition }}
                    tailPosition={{ bottom: bottomPosition }}
                >
                    <CardHeader>
                        <Display.HorizontalWithMaxSpaceBetween verticalCenter>
                            <H2Heading>Email</H2Heading>
                            <Display.HorizontalWithSpacing gap={5}>
                                <NewEmailItemButton />
                                <IconButton icon="close" onClick={() => setOpenMenu(false)} />
                            </Display.HorizontalWithSpacing>
                        </Display.HorizontalWithMaxSpaceBetween>
                    </CardHeader>
                </EmailFABMenu>
            )}
        </>
    );
};
