import { PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { values } from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { deletePrepreparedEmail } from '../../../../../../../graphql/prepreparedEmail/deletePrepreparedEmail';
import { updateTriggerPrepreparedContentLinks } from '../../../../../../../graphql/triggers';
import { destructiveColour } from '../../../../../../../themes/colours';
import { IconButton } from '../../../../../../bits/Buttons/IconButton';
import { Body2 } from '../../../../../../bits/Headers';
import { EmbeddedEmailItem } from '../../../../../../patterns/EmbeddedEmailItem';
import { AdminManageSimulationContext } from '../../../../../../WithAdminManageSimulationState';
import { AdminStateContext } from '../../../../../../WithAdminState/adminState';
import { DeleteModal } from '../../../../../DeleteModal';
import { getDescendentsForItem } from '../../../../../../../utils/getDescendentsForItem';

interface DeletePrepreparedEmailButtonProps {
    item: PrepreparedEmailItem;
}
export const DeletePrepreparedEmailButton: FC<DeletePrepreparedEmailButtonProps> = ({ item }) => {
    const [showModal, setShowModal] = useState(false);
    const { removePrepreparedEmailItems, setTriggerPrepreparedContentLinks } = useContext(AdminStateContext);
    const { currentSimulationContent } = useContext(AdminManageSimulationContext);
    const { prepreparedEmails } = currentSimulationContent;
    const descendants = useMemo(
        () =>
            showModal
                ? getDescendentsForItem(values(prepreparedEmails as Record<string, PrepreparedEmailItem>), item)
                : [],
        [showModal, prepreparedEmails],
    );

    const [
        deletePrepreparedEmailMutation,
        { data: deletePrepreparedEmailData, loading: deletePrepreparedEmailLoading },
    ] = deletePrepreparedEmail.hook();

    const [
        updateTriggerPrepreparedContentLinksMutation,
        { data: updateTriggerPrepreparedContentLinksData, loading: updateTriggerPrepreparedContentLinksLoading },
    ] = updateTriggerPrepreparedContentLinks.hook();

    useEffect(() => {
        const deletedItemAndDescendentUUIDs = deletePrepreparedEmailData?.deletePrepreparedEmail;
        const updatedTriggerLinks = updateTriggerPrepreparedContentLinksData?.updateTriggerPrepreparedContentLinks;

        // Deleted Item but not yet updated trigger links
        if (deletedItemAndDescendentUUIDs && !updatedTriggerLinks && !updateTriggerPrepreparedContentLinksLoading) {
            updateTriggerPrepreparedContentLinksMutation({
                variables: {
                    simulationUUID: item.simulationUUID,
                    newLinks: JSON.stringify({ [item.uuid]: '' }),
                },
            });
        }

        // Deleted Item and trigger links updated
        if (deletedItemAndDescendentUUIDs && updatedTriggerLinks) {
            removePrepreparedEmailItems(item.simulationUUID, deletedItemAndDescendentUUIDs);
            setTriggerPrepreparedContentLinks(item.simulationUUID, JSON.parse(updatedTriggerLinks));
            setShowModal(false);
        }
    }, [
        deletePrepreparedEmailData,
        updateTriggerPrepreparedContentLinksData,
        updateTriggerPrepreparedContentLinksLoading,
    ]);

    return (
        <>
            <IconButton icon="delete" width={20} fill={destructiveColour} onClick={() => setShowModal(true)} />
            {showModal && (
                <DeleteModal
                    header="Delete Preprepared Email"
                    onModalClose={() => setShowModal(false)}
                    confirmationText={
                        <>
                            Are you sure you want to delete this preprepared email item?
                            <EmbeddedEmailItem item={item} />
                            {!!descendants.length && (
                                <Body2 destructive>
                                    This email item has {descendants.length} descendants. All descendants items will
                                    also be deleted.
                                </Body2>
                            )}
                            {!!item.released && (
                                <Body2 destructive>
                                    This email item was released at {item.released.at.toISOString()}. The preprepared
                                    item can be deleted but any &quot;spawned&quot; children will not be deleted
                                </Body2>
                            )}
                            <Body2 destructive>
                                <b>This action cannot be undone.</b>
                            </Body2>
                        </>
                    }
                    onConfirmDelete={() =>
                        deletePrepreparedEmailMutation({
                            variables: {
                                prepreparedEmail: {
                                    ...item,
                                    released: item.released
                                        ? {
                                              ...item.released,
                                              at: item.released.at.toISOString(),
                                              items: JSON.stringify(item.released.items),
                                          }
                                        : undefined,
                                },
                            },
                        })
                    }
                    showSpinner={deletePrepreparedEmailLoading || updateTriggerPrepreparedContentLinksLoading}
                />
            )}
        </>
    );
};
