import { NewPrepreparedEmailItem, PrepreparedEmailItem } from 'polpeo-go-common/types/EmailItem';
import { assocPath } from 'ramda';
import React from 'react';
import { Dropdown, OptionItem, TextInput } from '../../../../../bits/FormFields';
import { StyledErrorMessage } from '../../../../../bits/Headers';
import { FormGridField } from '../../../../FormGrid';

export function makeSetupTabFormFields<T extends NewPrepreparedEmailItem | PrepreparedEmailItem>(
    triggerOptionItems: OptionItem[],
    currentPrepreparedEmail: T,
    setCurrentPrepreparedEmail: (value: T | ((item: T) => T)) => void,
    currentLinkedToTrigger: string,
    setCurrentLinkedToTrigger: (triggerUUID: string) => void,
    errors: Record<string, string>,
): FormGridField[] {
    return [
        [
            'Trigger',
            <Dropdown
                key="Email Trigger"
                id="Email Trigger"
                options={triggerOptionItems}
                onChange={(e) => setCurrentLinkedToTrigger(e.target.value)}
                value={currentLinkedToTrigger}
            />,
        ],
        [
            'Persona',
            <>
                <TextInput
                    key="Persona"
                    onChange={(e) => setCurrentPrepreparedEmail(assocPath(['persona'], e.target.value))}
                    value={currentPrepreparedEmail.persona}
                />
                {errors['persona'] && <StyledErrorMessage>{errors['persona']}</StyledErrorMessage>}
            </>,
        ],
    ];
}
