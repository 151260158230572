import { values } from 'ramda';
import React, { FC, useContext } from 'react';
import { TabItem, TabMenu } from '../../../patterns/TabMenu';
import { AdminManageSimulationContext } from '../../../WithAdminManageSimulationState/adminManageSimulationState';
import { SimulationOverviewPage } from './Overview';
import { ManagePagesPage } from './Pages';
import { ManagePrepreparedContentPage } from './PrepreparedContent';
import { SimulationDetailsBanner } from './SimulationDetailsBanner';
import { SimulationTeamPage } from './Teams';
import { ManageTriggersPage } from './Triggers';

export const ManageSimulationPage: FC = () => {
    const { currentSimulation, currentSimulationTeams } = useContext(AdminManageSimulationContext);

    const tabs: TabItem[] = [
        {
            label: 'Overview',
            icon: 'overviewDetail',
            navLink: { to: `/admin/simulation/${currentSimulation.uuid}/manage`, exact: true },
            route: {
                path: '/admin/simulation/:simulationUUID/manage',
                exact: true,
                children: <SimulationOverviewPage />,
            },
        },
        {
            label: 'Teams',
            icon: 'team',
            navLink: { to: `/admin/simulation/${currentSimulation.uuid}/manage/teams` },
            route: {
                path: '/admin/simulation/:simulationUUID/manage/teams',
                children: <SimulationTeamPage simulation={currentSimulation} teams={values(currentSimulationTeams)} />,
            },
        },
        {
            label: 'Triggers',
            icon: 'trigger',
            navLink: { to: `/admin/simulation/${currentSimulation.uuid}/manage/triggers` },
            route: {
                path: '/admin/simulation/:simulationUUID/manage/triggers',
                children: <ManageTriggersPage />,
            },
        },
        {
            label: 'Pages',
            icon: 'simulationPage',
            navLink: { to: `/admin/simulation/${currentSimulation.uuid}/manage/pages` },
            route: {
                path: '/admin/simulation/:simulationUUID/manage/pages',
                children: <ManagePagesPage />,
            },
        },
        {
            label: 'Preprepared Content',
            icon: 'content',
            navLink: { to: `/admin/simulation/${currentSimulation.uuid}/manage/content` },
            route: {
                path: '/admin/simulation/:simulationUUID/manage/content',
                children: <ManagePrepreparedContentPage />,
            },
        },
    ];

    return (
        <>
            <SimulationDetailsBanner simulation={currentSimulation} />
            <TabMenu key={currentSimulation.uuid} tabs={tabs} />
        </>
    );
};
