import { StaffUser } from 'polpeo-go-common/types/StaffUser';
import { all, equals, pick, pipe, values } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { editStaffUser } from '../../../../graphql/staffusers/editStaffUser';
import { resendStaffUserTempPassword } from '../../../../graphql/staffusers/resendStaffUserTempPassword';
import { grey1Colour } from '../../../../themes/colours';
import { PrimaryButton, SecondaryButton } from '../../../bits/Buttons';
import { Display } from '../../../bits/Display';
import { Dropdown, TextArea, TextInput } from '../../../bits/FormFields';
import { Body3, H2Heading, StyledErrorMessage } from '../../../bits/Headers';
import { Spinner } from '../../../bits/Spinner';
import { AdminStateContext } from '../../../WithAdminState/adminState';
import { FormGrid } from '../../FormGrid';
import { roleOptions } from './roleOptions';
import { skillOptions } from './skillOptions';
import { DeleteStaffUserButton } from './DeleteStaffUserButton';
import { ResetStaffUserMfaButton } from './ResetStaffUserMfaButton';
import { makeStaffLastLoginLabel } from '../../../../utils/makeStaffLastLoginLabel';
import { useUnsavedWorkPrompt } from '../../../hooks/useUnsavedWorkPrompt';

const ResendTemporaryPasswordButton = styled(SecondaryButton)`
    display: inline;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.9px;
    line-height: 16px;
    padding: 0px 10px 0 10px;
    border: 1px solid;
`;

const Pane = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${grey1Colour};
    flex: 0 0 auto;
`;

const PaneHeader = styled(H2Heading)`
    padding: 14px;
    border-bottom: 1px solid ${grey1Colour};
`;

const PaneContents = styled.div`
    position: fixed;
`;

interface StaffUserDetailsPaneProps {
    initialStaffUser: StaffUser;
    closePane: () => void;
}
export const StaffUserDetailsPane = ({
    initialStaffUser,
    closePane,
}: StaffUserDetailsPaneProps): React.ReactElement => {
    const { staffUsers, setStaffUsers } = useContext(AdminStateContext);
    const [staffUser, setStaffUser] = useState<StaffUser>(initialStaffUser);
    const [error, setError] = useState<string>('');

    const [
        ResendStaffUserTempPasswordMutation,
        { data: ResendStaffUserTempPasswordData, loading: ResendStaffUserTempPasswordLoading },
    ] = resendStaffUserTempPassword.hook();
    const [editStaffUserMutation, { data: editStaffUserData, error: editStaffUserError }] = editStaffUser.hook();

    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        name: string,
    ) => {
        const { value } = event.target;
        setStaffUser({ ...staffUser, [name]: value });
    };

    const requiredNameFieldCompleted = pipe(
        pick(['name']),
        (details) => values(details),
        all((value) => !!value),
    )(staffUser);

    useEffect(() => {
        if (editStaffUserData?.editStaffUser) {
            setStaffUsers({
                ...(staffUsers as Record<string, StaffUser>),
                [editStaffUserData?.editStaffUser.uuid]: editStaffUserData?.editStaffUser,
            });
            closePane();
        }
        if (editStaffUserError) {
            setError('There was an error when editing the staffUser');
        }
    }, [editStaffUserData, editStaffUserError]);

    const hasUnsavedChanges = !equals(staffUser, initialStaffUser) && !editStaffUserData?.editStaffUser;
    const { unsavedPromptComponent: UnsavedPrompt, wrappedFunc: closePaneUnsavedPrompt } = useUnsavedWorkPrompt(
        hasUnsavedChanges,
        closePane,
    );

    return (
        <Pane>
            <UnsavedPrompt />
            <PaneContents>
                <PaneHeader>{staffUser.name}</PaneHeader>
                <FormGrid
                    onSubmit={() => editStaffUserMutation({ variables: { staffUser: staffUser } })}
                    fields={[
                        [
                            'Name',
                            <TextInput
                                key="Full Name"
                                placeholder="Full Name"
                                id="name"
                                onChange={(event) => handleFieldChange(event, 'name')}
                                value={staffUser.name}
                            />,
                        ],
                        [
                            'Email',
                            <TextInput
                                key="Email"
                                placeholder="Email address"
                                id="email"
                                value={staffUser.email}
                                disabled
                            />,
                        ],
                        [
                            'Role',
                            <Dropdown
                                key="Role"
                                id="role"
                                onChange={(event) => handleFieldChange(event, 'role')}
                                value={staffUser.role}
                                options={roleOptions}
                            />,
                        ],
                        [
                            'Skill Level',
                            <Dropdown
                                key="Skill Level"
                                id="skillLevel"
                                onChange={(event) => handleFieldChange(event, 'skillLevel')}
                                value={staffUser.skillLevel}
                                options={skillOptions}
                            />,
                        ],
                        [
                            'Last Login',
                            <>
                                {staffUser.lastLogin ? (
                                    <Body3>{makeStaffLastLoginLabel(staffUser.lastLogin)}</Body3>
                                ) : (
                                    <Display.VerticalWithSpacing gap={10} horizontalAlign="start">
                                        <Body3 destructive={!staffUser.lastLogin}>Never</Body3>
                                        {ResendStaffUserTempPasswordLoading && <Spinner width={12} height={12} />}
                                        <ResendTemporaryPasswordButton
                                            type="button"
                                            onClick={() => {
                                                ResendStaffUserTempPasswordMutation({
                                                    variables: { staffUserUUID: staffUser.uuid },
                                                });
                                            }}
                                        >
                                            Resend Temp Password
                                        </ResendTemporaryPasswordButton>
                                        {ResendStaffUserTempPasswordData && !ResendStaffUserTempPasswordLoading && (
                                            <div>A new temporary password has been sent</div>
                                        )}
                                    </Display.VerticalWithSpacing>
                                )}
                            </>,
                        ],
                        [
                            'Reset MFA',
                            <>
                                <ResetStaffUserMfaButton staffUser={staffUser} setError={setError} />
                            </>,
                        ],
                        [
                            'Notes',
                            <TextArea
                                key="Notes"
                                placeholder="Notes"
                                id="notes"
                                onChange={(event) => handleFieldChange(event, 'notes')}
                                value={staffUser.notes}
                            />,
                        ],
                    ]}
                    footer={
                        <>
                            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}

                            <Display.HorizontalWithMaxSpaceBetween>
                                <DeleteStaffUserButton
                                    staffUser={staffUser}
                                    closePane={closePane}
                                    setError={setError}
                                />
                                <Display.HorizontalWithSpacing horizontalAlign={'end'}>
                                    <SecondaryButton
                                        onClick={() => {
                                            closePaneUnsavedPrompt();
                                            setError('');
                                        }}
                                    >
                                        Cancel
                                    </SecondaryButton>
                                    <PrimaryButton type="submit" disabled={!requiredNameFieldCompleted}>
                                        Save
                                    </PrimaryButton>
                                </Display.HorizontalWithSpacing>
                            </Display.HorizontalWithMaxSpaceBetween>
                        </>
                    }
                />
            </PaneContents>
        </Pane>
    );
};
