import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapSubscription from '../wrapSubscription';

interface OnStartSimulationParams {
    uuid: string;
}

interface OnStartSimulationData {
    onStartSimulation: Pick<Simulation, 'uuid' | 'startedAt'>;
}

const subscription = gql`
    subscription onStartSimulation($uuid: String!) {
        onStartSimulation(uuid: $uuid) {
            uuid
            startedAt
        }
    }
`;

export const onStartSimulation = wrapSubscription<OnStartSimulationParams, OnStartSimulationData>(subscription);
