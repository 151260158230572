import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { grey1Colour } from '../../themes/colours';

export const TAIL_WIDTH = 15;
export const TAIL_HEIGHT = 20;
interface ToolTipBubbleProps {
    tooltipPosition?: { top?: number; left?: number };
}
const ToolTipBubble = styled.div<ToolTipBubbleProps>`
    position: fixed;
    z-index: 800;

    top: ${({ tooltipPosition: { top } = {} }) => top || 0}px;
    left: ${({ tooltipPosition: { left } = {} }) => left || 0}px;

    background: #ffffff;
    border: 1px solid ${grey1Colour};
    border-radius: 5px;
    padding: 8px;
`;
interface TooltipProps {
    parent?: Element | null;
}
export const ToolTips: FC<TooltipProps> = ({ parent }) => {
    const [showTooltipFor, setShowTooltipFor] = useState<HTMLElement>();
    const [tooltipPosition, setTooltipPosition] = useState<{ top?: number; left?: number }>();
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const elementsWithTooltips = (parent || document).querySelectorAll<HTMLElement>('[data-tooltip]');

        const showTooltip = (e: Event) => {
            // e.target is actually typed as EventTarget but I know in this case it is
            // always a HTMLElement so I am "overriding" the type here. See:
            // https://stackoverflow.com/questions/28900077/why-is-event-target-not-element-in-typescript
            // for more info
            setShowTooltipFor(e.target as HTMLElement);
            setTooltipPosition(undefined);
        };
        const hideTooltip = () => {
            setShowTooltipFor(undefined);
            setTooltipPosition(undefined);
        };

        elementsWithTooltips.forEach((element) => {
            element.addEventListener('mouseenter', showTooltip);
            element.addEventListener('mouseleave', hideTooltip);
        });

        return () => {
            elementsWithTooltips.forEach((element) => {
                element.removeEventListener('mouseenter', showTooltip);
                element.removeEventListener('mouseleave', hideTooltip);
            });
        };
    });

    useEffect(() => {
        if (showTooltipFor) {
            const elementBounds = showTooltipFor.getBoundingClientRect();
            const toolTipBounds = tooltipRef.current ? tooltipRef.current.getBoundingClientRect() : { height: 0 };
            setTooltipPosition({
                top: elementBounds.top + elementBounds.height / 2 - toolTipBounds.height / 2,
                left: elementBounds.left + elementBounds.width,
            });
        }
    }, [showTooltipFor, tooltipRef.current]);

    if (!showTooltipFor || !showTooltipFor.dataset.tooltip) {
        return null;
    }

    const root = document.getElementById('root');
    if (!root) {
        // We shouldn't ever get here but just incase we do lets throw an error
        throw new Error('HTML Element with id "root" could not be found');
    }
    const tooltipValue = showTooltipFor.dataset.tooltip;
    const Component = (
        <ToolTipBubble ref={tooltipRef} tooltipPosition={tooltipPosition}>
            {tooltipValue}
        </ToolTipBubble>
    );

    return ReactDOM.createPortal(Component, root);
};
