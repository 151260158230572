import { DateTime } from 'luxon';
import { BasicSimulation } from 'polpeo-go-common/types/Simulation';
import { cond } from 'ramda';
import React, { useContext, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { clearSimulationCodeCookie, setSimulationCodeCookie } from '../../../../utils/simulationCodeCookie';
import { SplashContainer, SplashHeroCard } from '../../../bits/SplashContainer';
import { useCountdown } from '../../../hooks/UseCountdown';
import { FullPageSplashLoadingScreen } from '../../../patterns/FullPageSplashLoadingScreen';
import AuthContext from '../../../WithAuth/AuthContext';
import { ParticipantStateContext } from '../../../WithParticipantState';
import { SimulationCompleted } from './SimulationCompleted';
import { ParticipantSimulationInProgress } from './SimulationInProgress';
import { WaitingRoom } from './WaitingRoom';

export const ParticipantSimulation = (): React.ReactElement => {
    const { currentAuthenticatedUser } = useContext(AuthContext);
    const { simulation, participantTeam, triggers, pages, contentItems, emailItems, scratchPadDocuments } =
        useContext(ParticipantStateContext);
    const prevSimulation = useRef<BasicSimulation>(simulation);
    const [countDown, startCountDown] = useCountdown({});

    useEffect(() => {
        if (!simulation.completedAt) {
            setSimulationCodeCookie(simulation.code);
        } else {
            clearSimulationCodeCookie();
        }
    }, []);

    useEffect(() => {
        if (
            prevSimulation.current &&
            simulation.startedAt &&
            simulation.startedAt !== prevSimulation.current?.startedAt
        ) {
            startCountDown({ toDate: simulation.startedAt, updateInterval: 1000 });
        }
        prevSimulation.current = simulation;
    }, [simulation]);

    if (currentAuthenticatedUser) {
        return <Redirect to="/admin" />;
    }

    const simulationStarting =
        !!simulation.startedAt && DateTime.fromJSDate(simulation.startedAt).diffNow('millisecond').milliseconds >= 0;
    const simulationStarted =
        !!simulation.startedAt && DateTime.fromJSDate(simulation.startedAt).diffNow('millisecond').milliseconds < 0;
    const simulationDataLoaded = triggers && pages && contentItems && emailItems && scratchPadDocuments;
    const simulationCompleted = !!simulation.completedAt;

    return (
        <>
            {cond<BasicSimulation, React.ReactNode>([
                [
                    // Simulation has ended
                    () => simulationCompleted,
                    () => (
                        <SplashContainer>
                            <SplashHeroCard>
                                <SimulationCompleted />
                            </SplashHeroCard>
                        </SplashContainer>
                    ),
                ],
                [
                    // Simulation has not started yet
                    () => !simulationStarting && !simulationStarted,
                    () => (
                        <SplashContainer>
                            <SplashHeroCard>
                                <WaitingRoom simulation={simulation} />
                            </SplashHeroCard>
                        </SplashContainer>
                    ),
                ],
                [
                    // Simulation is starting soon so we want to show the countdown!
                    () => simulationStarting && !simulationStarted,
                    () => (
                        <SplashContainer>
                            <SplashHeroCard>
                                <WaitingRoom simulation={simulation} countDown={countDown || 0} />
                            </SplashHeroCard>
                        </SplashContainer>
                    ),
                ],
                [
                    // Simulation has already started and the user has no team (they joined late)
                    () => simulationStarted && !participantTeam,
                    () => (
                        <SplashContainer>
                            <SplashHeroCard>
                                <WaitingRoom simulation={simulation} />
                            </SplashHeroCard>
                        </SplashContainer>
                    ),
                ],
                [
                    // Simulation has started and the user has a team but simulation data has not loaded yet
                    () => simulationStarted && !!participantTeam && !simulationDataLoaded,
                    () => <FullPageSplashLoadingScreen />,
                ],
                // Simulation has started and the user has a team and simulation data is loaded
                [() => !!participantTeam && simulationStarted, () => <ParticipantSimulationInProgress />],
            ])(simulation)}
        </>
    );
};
