import { find, includes, map, omit } from 'ramda';
import React, { FC } from 'react';
import { NavLink, NavLinkProps, Route, RouteProps, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { grey1Colour, grey2Colour, primaryColour } from '../../themes/colours';
import { body1 } from '../../themes/cssSnippets';
import WithClassname from '../../utils/WithClassname';
import { FormGridContainer } from './FormGrid';

const SlimTabContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${grey2Colour};

    ${FormGridContainer} & {
        width: 100%;
        margin: 16px -16px -16px -16px;
        border-bottom: none;
    }
`;
interface SlimTabItemProps {
    active?: boolean;
}
const SlimTabItem = styled.button.attrs(({ type, onClick }) => ({
    type: type || 'button',
    onClick: (e) => {
        if (!type || type === 'button') {
            e.preventDefault();
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    },
}))<SlimTabItemProps>`
    ${body1}
    font-weight: 500;
    padding: 8px 20px;

    border-bottom: ${({ active }) => (active ? `2px solid ${primaryColour}` : 'none')};
    ${({ active }) => (active ? `color: ${primaryColour};` : '')}
    ${({ disabled }) => (disabled ? `color: ${grey1Colour};` : '')}
`;

export type SlimTabOption = [string, JSX.Element | undefined];
interface SlimTabsProps extends WithClassname {
    tabs: SlimTabOption[];
    activeTab: string;
    onChangeTab: (tab: string) => void;
    disabledTabs?: string[];
}
export const SlimTabs: FC<SlimTabsProps> = ({ tabs, activeTab, onChangeTab, disabledTabs = [], className }) => {
    return (
        <>
            <SlimTabContainer className={className}>
                {map(
                    ([tabName]) => (
                        <SlimTabItem
                            key={tabName}
                            active={activeTab === tabName}
                            onClick={() => onChangeTab(tabName)}
                            disabled={includes(tabName, disabledTabs)}
                        >
                            {tabName}
                        </SlimTabItem>
                    ),
                    tabs,
                )}
            </SlimTabContainer>
            {find(([tabName]) => tabName === activeTab, tabs)?.[1]}
        </>
    );
};

const SlimTabNavItem = styled(NavLink)`
    ${body1}
    font-weight: 500;
    padding: 8px 20px;

    &.active {
        border-bottom: 2px solid ${primaryColour};
    }
`;
interface SlimTabsNavOption {
    label: string;
    navLink: NavLinkProps;
    route: RouteProps;
}
interface SlimTabsNavProps extends WithClassname {
    tabs: SlimTabsNavOption[];
}
export const SlimTabNav: FC<SlimTabsNavProps> = ({ tabs, className }) => {
    return (
        <>
            <SlimTabContainer className={className}>
                {map<SlimTabsNavOption, React.ReactNode>(
                    (tab) => (
                        <SlimTabNavItem key={tab.label} {...tab.navLink}>
                            {tab.label}
                        </SlimTabNavItem>
                    ),
                    tabs,
                )}
            </SlimTabContainer>
            <Switch>
                {map<SlimTabsNavOption, React.ReactNode>(
                    (tab) => (
                        <Route key={tab.label} {...omit(['children'], tab.route)}>
                            {tab.route.children}
                        </Route>
                    ),
                    tabs,
                )}
            </Switch>
        </>
    );
};
