import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { MenuButton } from './MenuButton';
import { UserCard } from './UserCard';

const ProfileMenuButton = styled.button.attrs(({ onClick }) => ({
    ['aria-haspopup']: true,
    ['aria-expanded']: false,
    onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
            onClick(e);
        }
    },
}))`
    max-width: 200px;

    & span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

interface UserMenuProps {
    name: string;
    teamName?: string;
}
export const UserMenu: FC<UserMenuProps> = ({ name, teamName, children }) => (
    <MenuButton
        buttonComponent={(props: unknown) => (
            <ProfileMenuButton {...props}>
                <UserCard name={name} teamName={teamName} />
            </ProfileMenuButton>
        )}
    >
        {children}
    </MenuButton>
);
