import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapMutation from '../wrapMutation';

interface DeleteSimulationParams {
    simulation: Simulation;
}

interface DeleteSimulationData {
    deleteSimulation: string;
}

const mutation = gql`
    mutation deleteSimulation($simulation: SimulationInput!) {
        deleteSimulation(simulation: $simulation)
    }
`;

export const deleteSimulation = wrapMutation<DeleteSimulationParams, DeleteSimulationData>(mutation);
