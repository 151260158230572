import React from 'react';
import styled from 'styled-components/macro';
import { grey3Colour, grey6Colour } from '../../../../themes/colours';
import { Display } from '../../../bits/Display';
import { Body2, Body3, H3Heading } from '../../../bits/Headers';
import { Icon } from '../../../bits/Icon';
import { Logo } from '../../../patterns/Logo';

const SimulationCompletedText = styled(Body2)`
    color: ${grey6Colour};
`;

const SimulationCompletedHelperText = styled(Body3)`
    color: ${grey3Colour};
`;

export const SimulationCompleted = (): React.ReactElement => {
    return (
        <Display.VerticalWithSpacing horizontalAlign="center">
            <Logo width={125} />
            <H3Heading>Simulation Complete!</H3Heading>
            <Icon icon="tickOutline" height={60} width={60} fill="#2dc339" />
            <SimulationCompletedText>The simulation has finished, thanks for taking part!</SimulationCompletedText>
            <SimulationCompletedHelperText>
                If you think you should still be in a simulation, please contact an admin
            </SimulationCompletedHelperText>
        </Display.VerticalWithSpacing>
    );
};
