import gql from 'graphql-tag';
import { GraphQlPrepreparedContentItem } from 'polpeo-go-common/types/ContentItem';
import wrapMutation from '../wrapMutation';

interface EditPrepreparedContentParams {
    prepreparedContent: GraphQlPrepreparedContentItem;
}

interface EditPrepreparedContentData {
    editPrepreparedContent: GraphQlPrepreparedContentItem;
}

const mutation = gql`
    mutation editPrepreparedContent($prepreparedContent: PrepreparedContentInput!) {
        editPrepreparedContent(prepreparedContent: $prepreparedContent) {
            type
            uuid
            simulationUUID
            parentUUID
            persona
            content {
                pageUUID
                interactions {
                    positive
                    negative
                    shares
                }
                data
            }
            released {
                at
                items
            }
            teamUUIDs
        }
    }
`;

export const editPrepreparedContent = wrapMutation<EditPrepreparedContentParams, EditPrepreparedContentData>(mutation);
