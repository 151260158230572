import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageError } from './components/patterns/FullPageError';
import { AdminHeader } from './components/templates/AdminHeader';
import { AdminInSimulationHeader } from './components/templates/AdminInSimulationHeader';
import { AdminMain } from './components/templates/AdminMain';
import { AdminNav } from './components/templates/AdminNav';
import { PageTemplatePreviewPage } from './components/templates/pages/Admin/PageTemplatePreview';
import { PageTemplatesListPage } from './components/templates/pages/Admin/PageTemplatesList';
import { ResetPassword } from './components/templates/pages/ForgotPassword';
import { ManageSimulationPage } from './components/templates/pages/ManageSimulation';
import { SimulationSetupPage } from './components/templates/pages/ManageSimulation/SimulationSetupPage';
import { NewSimulationFormPage } from './components/templates/pages/NewSimulationForm';
import { ParticipantJoinLobbyPage } from './components/templates/pages/ParticipantJoinLobby';
import { ParticipantSimulation } from './components/templates/pages/ParticipantSimulation';
import { Simulations } from './components/templates/pages/Simulations';
import { SimulationUploadPage } from './components/templates/pages/SimulationUpload';
import { StaffSignIn } from './components/templates/pages/StaffSignIn';
import { StaffUserSimulationPage } from './components/templates/pages/StaffUserSimulation';
import { Users } from './components/templates/pages/Users';
import ParticipantRoute from './components/utils/ParticipantRoute';
import StaffRoute from './components/utils/StaffRoute';
import { WithAdminInSimulationState } from './components/WithAdminInSimulationState';
import { WithAdminManageSimulationState } from './components/WithAdminManageSimulationState';
import { WithAdminState } from './components/WithAdminState';
import { WithParticipantState } from './components/WithParticipantState';
import { WithParticipantUser } from './components/WithParticipantUser';
import { WithSideBarState } from './components/WithSideBarState';
import { WithStaffUser } from './components/WithStaffUser';
import { StaffAppContentContainer } from './StaffAppContentContainer';
import { WithSentryError } from './components/WithSentryError';

const SIM_UUID_PATH_PARAM =
    ':simulationUUID([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89AB][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})';

export const App: FC = () => (
    <WithSentryError>
        <Switch>
            {/* Unknown user type */}
            <Route exact path="/" component={ParticipantJoinLobbyPage} />
            <Route exact path="/simulation" component={() => <Redirect to="/" />} />

            {/* Participants */}
            <ParticipantRoute
                path={[
                    '/simulation/:simulationCode/:pageUUID/:itemUUID',
                    '/simulation/:simulationCode/:pageUUID',
                    '/simulation/:simulationCode',
                ]}
            >
                <Switch>
                    <WithParticipantUser>
                        <WithParticipantState>
                            <Route
                                path={[
                                    '/simulation/:simulationCode/:pageUUID/:itemUUID',
                                    '/simulation/:simulationCode/:pageUUID',
                                    '/simulation/:simulationCode',
                                ]}
                            >
                                <ParticipantSimulation />
                            </Route>
                        </WithParticipantState>
                    </WithParticipantUser>
                </Switch>
            </ParticipantRoute>

            {/* Staff */}
            <Route path="/admin/login" component={StaffSignIn} />
            <Route path="/admin/reset-password" component={ResetPassword} />
            <StaffRoute path="/admin">
                <WithStaffUser>
                    <WithAdminState>
                        <WithSideBarState>
                            <Switch>
                                <Route
                                    path={[
                                        `/admin/simulation/${SIM_UUID_PATH_PARAM}`,
                                        `/admin/simulation/${SIM_UUID_PATH_PARAM}/team/:teamUUID`,
                                        `/admin/simulation/${SIM_UUID_PATH_PARAM}/team/:teamUUID/:pageUUID`,
                                        `/admin/simulation/${SIM_UUID_PATH_PARAM}/team/:teamUUID/:pageUUID/:itemUUID`,
                                    ]}
                                    exact
                                >
                                    <WithAdminInSimulationState>
                                        <AdminInSimulationHeader />
                                        <StaffAppContentContainer>
                                            <StaffUserSimulationPage />
                                        </StaffAppContentContainer>
                                    </WithAdminInSimulationState>
                                </Route>
                                <Route path="/admin">
                                    <AdminHeader />
                                    <StaffAppContentContainer>
                                        <AdminNav />
                                        <AdminMain>
                                            <Switch>
                                                <Route path="/admin/users" component={Users} />
                                                <Route
                                                    path={`/admin/page-templates`}
                                                    component={PageTemplatesListPage}
                                                    exact
                                                />
                                                <Route
                                                    path={`/admin/page-templates/:templateUUID/:type`}
                                                    component={PageTemplatePreviewPage}
                                                />
                                                <Route
                                                    path="/admin/simulation/upload"
                                                    component={SimulationUploadPage}
                                                />
                                                <Route path="/admin/simulation/new" component={NewSimulationFormPage} />
                                                <Route path={`/admin/simulation/${SIM_UUID_PATH_PARAM}/*`}>
                                                    <WithAdminManageSimulationState>
                                                        <Route
                                                            path={`/admin/simulation/${SIM_UUID_PATH_PARAM}/manage`}
                                                            component={ManageSimulationPage}
                                                        />
                                                        <Route
                                                            path={`/admin/simulation/${SIM_UUID_PATH_PARAM}/edit`}
                                                            component={SimulationSetupPage}
                                                        />
                                                    </WithAdminManageSimulationState>
                                                </Route>
                                                <Route
                                                    path={['/admin', '/admin/simulations']}
                                                    component={Simulations}
                                                />
                                            </Switch>
                                        </AdminMain>
                                    </StaffAppContentContainer>
                                </Route>
                            </Switch>
                        </WithSideBarState>
                    </WithAdminState>
                </WithStaffUser>
            </StaffRoute>

            <Route path={'*'}>
                <FullPageError errorCode="404" message="This page could not be found" />
            </Route>
        </Switch>
    </WithSentryError>
);
