import styled from 'styled-components/macro';

interface MetadataRowPositionerProps {
    alignment?: 'flex-end';
}
export const MetadataRowPositioner = styled.div<MetadataRowPositionerProps>`
    display: flex;
    align-items: ${({ alignment }) => (alignment ? alignment : 'flex-start')};
    flex: ${({ alignment }) => (alignment ? '1 1 auto' : '0 1 auto')};
`;
