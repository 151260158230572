import { ContentItem } from 'polpeo-go-common/types/ContentItem';
import { ContentItemField } from 'polpeo-go-common/types/PageTemplate/ContentItemField';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import { assocPath, cond, map } from 'ramda';
import React from 'react';
import { TextArea, TextInput } from '../../../bits/FormFields';
import { VideoEmbedInput } from '../../../bits/FormFields/VideoEmbedInput';
import { ContentItemImageInput } from '../../../templates/ContentItemImageDropZone';
import { FormGridField } from '../../../templates/FormGrid';
import RichtextInput from '../../RichtextInput';

type MakeContentTabFormFieldsBaseItem = Pick<ContentItem, 'content'> & Partial<Pick<ContentItem, 'deleted'>>;

export function makeContentTabFormFields<T extends MakeContentTabFormFieldsBaseItem>(
    simulation: Simulation,
    fields: ContentItemField[],
    currentContentItem: T,
    setCurrentContentItem: (value: T | ((item: T) => T)) => void,
): FormGridField[] {
    return map(
        (field) => [
            field.displayName || field.name,
            cond([
                [
                    (fieldType) => fieldType === 'TEXT',
                    () => (
                        <TextInput
                            key={field.name}
                            maxLength={
                                field.options && 'maxLength' in field.options ? field.options.maxLength : undefined
                            }
                            onChange={(e) =>
                                setCurrentContentItem(
                                    assocPath(['content', 'data', field.name], e.target.value, currentContentItem),
                                )
                            }
                            value={currentContentItem.content.data[field.name] || ''}
                            disabled={!!currentContentItem.deleted}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'LONG_TEXT',
                    () => (
                        <TextArea
                            key={field.name}
                            maxLength={
                                field.options && 'maxLength' in field.options ? field.options.maxLength : undefined
                            }
                            onChange={(e) =>
                                setCurrentContentItem(
                                    assocPath(['content', 'data', field.name], e.target.value, currentContentItem),
                                )
                            }
                            value={currentContentItem.content.data[field.name] || ''}
                            disabled={!!currentContentItem.deleted}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'IMAGE',
                    () => (
                        <ContentItemImageInput
                            key={field.name}
                            simulation={simulation}
                            value={currentContentItem.content.data[field.name] || ''}
                            onChange={(imagePath) =>
                                setCurrentContentItem(assocPath(['content', 'data', field.name], imagePath))
                            }
                            disabled={!!currentContentItem.deleted}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'RICHTEXT',
                    () => (
                        <RichtextInput
                            key={field.name}
                            value={currentContentItem.content.data[field.name] || ''}
                            imageUploadSimulation={simulation}
                            onChange={(richtext) =>
                                setCurrentContentItem(assocPath(['content', 'data', field.name], richtext))
                            }
                            disabled={!!currentContentItem.deleted}
                        />
                    ),
                ],
                [
                    (fieldType) => fieldType === 'VIDEO',
                    () => (
                        <VideoEmbedInput
                            key={field.name}
                            onChange={(e) =>
                                setCurrentContentItem(
                                    assocPath(['content', 'data', field.name], e.target.value, currentContentItem),
                                )
                            }
                            value={currentContentItem.content.data[field.name] || ''}
                        />
                    ),
                ],
            ])(field.type),
        ],
        fields,
    );
}
