import { SkillLevelEnum } from 'polpeo-go-common/types/StaffUser';
import { OptionItem } from '../../../bits/FormFields';

export const skillOptions: OptionItem[] = [
    {
        value: SkillLevelEnum.COMPLEX,
        label: 'Complex',
    },
    {
        value: SkillLevelEnum.STANDARD,
        label: 'Standard',
    },
];
