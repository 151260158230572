import { useEffect, useState } from 'react';

interface UseCountdownRequiredProps {
    toDate: Date;
    updateInterval: number;
}
type UseCountdownProps = Record<string, never> | UseCountdownRequiredProps;
export const useCountdown = (props: UseCountdownProps): [number, (p: UseCountdownRequiredProps) => void] => {
    const [countToDate, setCountToDate] = useState<Date | undefined>(props.toDate || undefined);
    const [timerInterval, setTimerInterval] = useState<number>(props.updateInterval);
    const [value, setValue] = useState<number>(countToDate ? countToDate.getTime() - new Date().getTime() : 0);

    const init: (p: UseCountdownRequiredProps) => void = (initProps: UseCountdownRequiredProps) => {
        setCountToDate(initProps.toDate);
        setTimerInterval(initProps.updateInterval);
        setValue(initProps.toDate.getTime() - new Date().getTime());
    };

    useEffect(() => {
        if (countToDate && timerInterval && value > 0) {
            const timer = setTimeout(() => setValue(countToDate.getTime() - new Date().getTime()), timerInterval);

            return () => clearTimeout(timer);
        } else {
            setValue(0);
        }
    }, [value]);

    return [value, init];
};
