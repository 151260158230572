import styled, { CSSProp } from 'styled-components/macro';
import { baseThemingSnippet, BaseThemingSnippetProps } from './baseThemingSnippet';

export interface BaseDivProps extends BaseThemingSnippetProps {
    extraCSS?: CSSProp;
}
export const BaseDiv = styled.div<BaseDivProps>`
    ${baseThemingSnippet}

    ${({ extraCSS }) => extraCSS || ''}

    &:after {
        content: '';
        display: block;
        clear: both;
    }
`;
