import gql from 'graphql-tag';
import { Simulation } from 'polpeo-go-common/types/Simulation';
import wrapSubscription from '../wrapSubscription';

interface OnEndSimulationParams {
    uuid: string;
}

interface OnEndSimulationData {
    onEndSimulation: Pick<Simulation, 'uuid' | 'completedAt'>;
}

const subscription = gql`
    subscription onEndSimulation($uuid: String!) {
        onEndSimulation(uuid: $uuid) {
            uuid
            completedAt
        }
    }
`;

export const onEndSimulation = wrapSubscription<OnEndSimulationParams, OnEndSimulationData>(subscription);
